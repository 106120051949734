import {
    AchPaymentModelDto,
    ChildSupportPaymentModelDto,
    ChildSupportRecipientModelDto,
} from '@treasury/api/bo';
import { AchChildSupportRecipient } from './ach-child-support-recipient.entity';
import { AchPayment } from './ach-payment.entity';
import { defaultAchChildSupportPaymentModelDto } from './data/ach.data';

export class AchChildSupportPayment extends AchPayment<
    ChildSupportPaymentModelDto & AchPaymentModelDto
> {
    public createDefault(): this {
        return new AchChildSupportPayment(defaultAchChildSupportPaymentModelDto) as this;
    }

    public get transactionId() {
        return this.dto.transactionId ?? '';
    }

    public get name() {
        return this.dto.name ?? '';
    }

    public get status() {
        return this.dto.status ?? '';
    }

    public get companyName() {
        return this.dto.achCompanyName ?? '';
    }

    public get secCode() {
        return this.dto.secCode ?? '';
    }

    public get restrictPayment() {
        return this.dto.restricted;
    }

    public get cspRecipients() {
        if (!this.dto.recipients) return [];
        return this.dto.recipients.map(
            (recipient: ChildSupportRecipientModelDto) => new AchChildSupportRecipient(recipient)
        );
    }

    public get frequencySummary() {
        return this.dto.frequency!.summary ?? '';
    }

    public get effectiveDate() {
        return this.dto.frequency!.effectiveDate ?? '0000-00-00';
    }
}
