import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('achCompaniesService', achCompaniesService);

achCompaniesService.$inject = ['$resource'];

function achCompaniesService($resource) {
    const resourceUrl = `${getBackOfficeAPIPath()}achCompanies/`;

    return {
        getAchCompanies,
        getAchCompaniesDeduped,
    };

    function getAchCompanies(name) {
        return $resource(`${resourceUrl}?companyName=:name`, { name }).query().$promise;
    }

    function getAchCompaniesDeduped(companyId, name) {
        return $resource(`${resourceUrl}?companyId=:companyId&companyName=:name`, {
            companyId,
            name,
        }).query().$promise;
    }
}
