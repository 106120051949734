/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { FiAgileticsSsoProductModelDto, FiAgileticsSsoProductResponseModelDto } from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class AgileticsSsoProductFeatureConfigurationsClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags ProductFeatureConfigurations
     * @name ProductFeatureConfigurationsGetAgileticsSsoProductFeature
     * @summary Provides the endpoint to get the Agiletics Web Escrow product feature configuration for the financial institution.
     * @request GET:/agileticsSsoProductFeatureConfigurations/{id}
     */
    productFeatureConfigurationsGetAgileticsSsoProductFeature = (
        id: number,
        params: RequestParams = {}
    ) =>
        this.http.request<FiAgileticsSsoProductModelDto, any>({
            path: `/agileticsSsoProductFeatureConfigurations/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ProductFeatureConfigurations
     * @name ProductFeatureConfigurationsSaveAgileticsSsoProductFeature
     * @summary Provides the endpoint to save the agiletics sso product feature configuration for the financial institution.
     * @request POST:/agileticsSsoProductFeatureConfigurations
     */
    productFeatureConfigurationsSaveAgileticsSsoProductFeature = (
        fiAgileticsSsoProductModel: FiAgileticsSsoProductModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<FiAgileticsSsoProductResponseModelDto, any>({
            path: `/agileticsSsoProductFeatureConfigurations`,
            method: 'POST',
            body: fiAgileticsSsoProductModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
