angular.module('backOffice').controller('ManageFiRolesController', manageFiRolesController);

manageFiRolesController.$inject = [
    '$scope',
    '$modal',
    'roleDetails',
    '$modalInstance',
    '$window',
    'toaster',
    'modalService',
    'fiPermissionService',
    'rolesService',
    'canDelete',
    'canEdit',
    'entitlementsService',
];

function manageFiRolesController(
    $scope,
    $modal,
    roleDetails,
    $modalInstance,
    $window,
    toaster,
    modalService,
    fiPermissionService,
    rolesService,
    canDelete,
    canEdit,
    entitlementsService
) {
    // selected Role
    $scope.selectedRole = roleDetails;
    $scope.canDelete = canDelete;
    $scope.canEdit = canEdit;
    $scope.selectedEntitlementListPristine = {};
    $scope.entitlementGroups = [];
    $scope.entitlements = [];
    $scope.selectedEntitlements = {};
    $scope.requiredEntitlement = requiredEntitlement;
    $scope.getTitle = getTitle;
    $scope.cancel = cancel;
    $scope.save = save;
    $scope.delete = confirmDelete;
    $scope.checkPrerequisites = checkPrerequisites;

    function init() {
        getPermissions();
        getEntitlementGroups();
    }

    function loadEntitlements() {
        angular.forEach($scope.entitlementGroups, entitlementGroup => {
            entitlementGroup.entitlements.forEach(entitlement => {
                $scope.entitlements.push(entitlement);
            });
        });
    }

    function loadSelectedEntitlementList() {
        $scope.entitlements.forEach(entitlement => {
            $scope.selectedEntitlements[entitlement.name] = false;
        });
        // set selected entitlements to true
        $scope.selectedRole.permissions.forEach(entitlement => {
            $scope.selectedEntitlements[entitlement.name] = true;
        });
        $scope.selectedEntitlementListPristine = angular.copy($scope.selectedEntitlements);
    }

    function getTitle() {
        if ($scope.selectedRole.id > -1) {
            if (entitlementsService.hasEntitlement('Edit User Role')) {
                return 'Edit Role';
            }
            return 'View Role';
        }
        return 'Add Role';
    }

    function requiredEntitlement() {
        if ($scope.selectedRole.id > -1) {
            return 'Edit User Role';
        }
        return 'Add User Role';
    }

    function setFormDirty() {
        $scope.form.$dirty = true;
    }

    function deltaPurge(target, source) {
        angular.forEach(source, entitlement => {
            let index = 0;
            let found = false;
            angular.forEach(target, entitlementToFind => {
                if (entitlement.name == entitlementToFind.name) {
                    found = true;
                    entitlementToFind = angular.copy(entitlement);
                }
                if (found !== true) {
                    index++;
                }
            });
            if (found) {
                // since isDeleted records are part of the source that are supressed,
                // dont remove it from target.
                if (entitlement.isDeleted === null || entitlement.isDeleted === false) {
                    target.splice(index, 1);
                }
            }
        });
    }

    function getPermissions() {
        fiPermissionService.getPermissions().then(response => {
            $scope.availableEntitlementList = response;

            // we clear out entitlements in the available entitlement list if its already in the selected entitlement list
            deltaPurge($scope.availableEntitlementList, $scope.selectedEntitlementList);
        });
    }

    function getEntitlementGroups() {
        entitlementsService
            .getEntitlementGroups()
            .then(response => {
                $scope.entitlementGroups = response;
            })
            .then(() => {
                loadEntitlements();
                loadSelectedEntitlementList();
            });
    }

    init();

    function cancel() {
        $modalInstance.dismiss();
    }

    function save() {
        const validationResults = validateRole();

        if (validationResults.validationErrors.length > 0) {
            const modalOptions = {
                actionButtonText: 'Save',
                closeButtonText: 'Cancel',
                secondaryButtonClass: 'btn-link',
                headerText: 'Add Required Entitlements',
                bodyText: `${validationResults.validationErrors}To add these entitlements, please select Save.`,
                submit(result) {
                    addMissingEntitlements(validationResults.missingEntitlements);
                    saveRole();
                    $modalInstance.close(result);
                },
            };
            var $modalInstance = modalService.showModal({}, modalOptions);
        } else {
            saveRole();
        }
    }

    function saveRole() {
        if ($scope.selectedRole.id > -1) {
            const modalOptions = {
                bodyText: 'Are you sure you want to save changes to the current Role?',
                submit(result) {
                    updateRole();
                    $modalInstance.close(result);
                },
            };
            var $modalInstance = modalService.showModal({}, modalOptions);
        } else {
            createRole();
        }
    }

    function deleteRole() {
        rolesService.deleteRole($scope.selectedRole.id).then(response => {
            toaster.delete('FI Role');
            $modalInstance.close(response);
        });
    }

    function confirmDelete() {
        const modalOptions = {
            bodyText: 'Are you sure you want to delete current role?',
            submit(result) {
                deleteRole();
                $modalInstance.close(result);
            },
            headerText: 'Confirm Delete Role',
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    // we watch for form.$dirty, this is used to alert the end user that they changed something in the form.
    $scope.$watch('form.$dirty', isDirty => {
        $modalInstance.isDirty = isDirty;
    });

    function createRole() {
        let changedPermissions = [];

        changedPermissions = applyEntitlementsToRole();

        $scope.selectedRole.permissions = changedPermissions;

        rolesService.saveRole($scope.selectedRole).then(response => {
            toaster.save('FI Role');
            $modalInstance.close(response);
        });
    }

    function applyEntitlementsToRole() {
        const changedPermissions = [];
        $scope.entitlements.forEach(entitlement => {
            if (
                $scope.selectedEntitlements[entitlement.name] !==
                $scope.selectedEntitlementListPristine[entitlement.name]
            ) {
                let found = false;
                $scope.selectedRole.permissions.forEach(permission => {
                    if (permission.name === entitlement.name) {
                        if (!$scope.selectedEntitlements[entitlement.name]) {
                            permission.isDeleted = true;
                            changedPermissions.push(permission);
                        }
                        found = true;
                    }
                });
                if (!found) {
                    // Add entitlement
                    changedPermissions.push({
                        id: entitlement.claimId,
                        name: entitlement.name,
                        description: '',
                        isDeleted: false,
                    });
                }
            }
        });
        return changedPermissions;
    }

    function updateRole() {
        let changedPermissions = [];

        changedPermissions = applyEntitlementsToRole();

        $scope.selectedRole.permissions = changedPermissions;
        rolesService.updateRole($scope.selectedRole).then(response => {
            toaster.save('FI Role');
            $modalInstance.close(response);
        });
    }

    function validateRole() {
        const withPrerequisites = $scope.entitlements.filter(e => e.prerequisiteClaims.length > 0);
        const missingEntitlements = [];
        let validationErrors = '';
        withPrerequisites.forEach(entitlement => {
            if ($scope.selectedEntitlements[entitlement.name]) {
                entitlement.prerequisiteClaims.forEach(prerequisiteClaim => {
                    const prequisiteClaim = $scope.entitlements.filter(
                        e => e.claimId === prerequisiteClaim
                    )[0];
                    if (!$scope.selectedEntitlements[prequisiteClaim.name]) {
                        missingEntitlements.push(prequisiteClaim);
                        validationErrors += `Warning: Entitlements "${prequisiteClaim.name}" are required for addition of "${entitlement.name}.  `;
                    }
                });
            }
        });
        return { validationErrors, missingEntitlements };
    }

    function addMissingEntitlements(missingEntitlements) {
        missingEntitlements.forEach(entitlement => {
            $scope.selectedEntitlements[entitlement.name] = true;
        });
    }

    function checkPrerequisites(entitlement) {
        if ($scope.selectedEntitlements[entitlement.name]) {
            checkPrerequisitesForAddedEntitlement(entitlement);
        } else {
            checkPrerequisitesForRemovedEntitlement(entitlement);
        }
    }

    function checkPrerequisitesForRemovedEntitlement(entitlement) {
        const dependentEntitlements = [];
        $scope.entitlements.forEach(dependentEntitlement => {
            dependentEntitlement.prerequisiteClaims.forEach(prerequisiteClaimId => {
                if (prerequisiteClaimId == entitlement.claimId) {
                    const prerequisiteClaim = $scope.entitlements.filter(
                        e => e.claimId === prerequisiteClaimId
                    )[0];
                    if ($scope.selectedEntitlements[dependentEntitlement.name]) {
                        dependentEntitlements.push(dependentEntitlement);
                    }
                }
            });
        });

        if (dependentEntitlements.length > 0) {
            const $modalInstance = $modal.open({
                template: require('../views/prerequisiteConfirmation.html'),
                controller: 'PrerequisiteConfirmationController',
                backdrop: 'static',
                resolve: {
                    entitlement() {
                        return entitlement;
                    },
                    relatedEntitlementList() {
                        return dependentEntitlements;
                    },
                    isAdd() {
                        return false;
                    },
                },
            });

            $modalInstance.result.then(
                result => {
                    dependentEntitlements.forEach(dependentEntitlement => {
                        $scope.selectedEntitlements[dependentEntitlement.name] = false;
                    });
                },
                () => {
                    $scope.selectedEntitlements[entitlement.name] = true;
                    $modalInstance.dismiss();
                }
            );
        }
    }
    function checkPrerequisitesForAddedEntitlement(entitlement) {
        const missingPrerequisites = [];

        entitlement.prerequisiteClaims.forEach(prerequisiteClaimId => {
            const prerequisiteClaim = $scope.entitlements.filter(
                e => e.claimId === prerequisiteClaimId
            )[0];
            if (!$scope.selectedEntitlements[prerequisiteClaim.name]) {
                missingPrerequisites.push(prerequisiteClaim);
            }
        });

        if (missingPrerequisites.length > 0) {
            const $modalInstance = $modal.open({
                template: require('../views/prerequisiteConfirmation.html'),
                controller: 'PrerequisiteConfirmationController',
                backdrop: 'static',
                resolve: {
                    entitlement() {
                        return entitlement;
                    },
                    relatedEntitlementList() {
                        return missingPrerequisites;
                    },
                    isAdd() {
                        return true;
                    },
                },
            });

            $modalInstance.result.then(
                result => {
                    missingPrerequisites.forEach(missingPrerequisite => {
                        $scope.selectedEntitlements[missingPrerequisite.name] = true;
                    });
                },
                () => {
                    $scope.selectedEntitlements[entitlement.name] = false;
                    $modalInstance.dismiss();
                }
            );
        }
    }
}
