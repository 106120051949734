angular.module('backOffice').controller('FiUserListController', fiUserListController);

fiUserListController.$inject = ['$scope', '$modal', 'usersService', 'entitlementsService'];

function fiUserListController($scope, $modal, usersService, entitlementsService) {
    $scope.canManageUsers = canManageUsers;
    $scope.canViewUsers = canViewUsers;
    $scope.canResetPassword = canResetPassword;
    $scope.changeUserStatus = changeUserStatus;
    $scope.filterUsers = filterUsers;
    $scope.ipSettings = ipSettings;
    $scope.joinRoles = joinRoles;
    $scope.manageUser = manageUser;
    $scope.resetPassword = resetPassword;
    $scope.showAuthenticationModal = showAuthenticationModal;
    $scope.canShowAuthenticationSettingsModal = canShowAuthenticationSettingsModal;
    $scope.showUserActionMenu = showUserActionMenu;
    $scope.showAddUserButton = showAddUserButton;
    $scope.searchText = '';
    $scope.backOfficeSsoEnabled = false;

    function loadUsers() {
        usersService.getUsers().then(response => {
            $scope.userList = response.users;
            $scope.isTokenMfaEnabled = response.isTokenMfaEnabled;
            $scope.backOfficeSsoEnabled = response.backOfficeSsoEnabled;
            filterUsers();
        });
    }

    function init() {
        loadUsers();
    }

    function joinRoles(user) {
        if (user.roles) {
            return user.roles
                .map(role => role.name)
                .slice(0, 10)
                .join(', ');
        }
    }

    function changeUserStatus(user) {
        const modalInstance = $modal.open({
            template: require('../views/manageUserStatus.html'),
            size: 'md',
            controller: 'ManageUserStatusController',
            backdrop: 'static',
            resolve: {
                userDetails() {
                    return user;
                },
            },
        });
        modalInstance.result.then(selectedUserStatus => {
            user.status = selectedUserStatus.status;
            user.comments = selectedUserStatus.comments;
        });
    }

    function resetPassword(user) {
        const modalInstance = $modal.open({
            template: require('../../../shared/resetPassword/views/resetPassword.html'),
            size: 'md',
            controller: 'ResetPassWordController',
            backdrop: 'static',
            resolve: {
                userDetails() {
                    return user;
                },
                companyId() {
                    return null;
                },
            },
        });
    }

    function filterUsers() {
        $scope.filteredList = $scope.userList.filter(
            user =>
                user.alias.toUpperCase().indexOf($scope.searchText.toUpperCase()) > -1 ||
                user.userName.toUpperCase().indexOf($scope.searchText.toUpperCase()) > -1
        );
    }

    function ipSettings(user) {
        const modalInstance = $modal.open({
            template: require('../views/ipSettingsModal.html'),
            size: 'md',
            controller: 'IpSettingsModalController',
            backdrop: 'static',
        });
    }

    function manageUser(userToEdit, canEdit) {
        const modalInstance = $modal.open({
            template: require('../views/manageFiUser.html'),
            size: 'md',
            controller: 'ManageFiUserController',
            backdrop: 'static',
            resolve: {
                userDetails() {
                    return angular.copy(userToEdit);
                },
                permissions() {
                    return {
                        canDelete: $scope.hasEntitlement('Edit FI User'),
                        canUnlock: $scope.hasEntitlement('Edit FI User'),
                        canEdit,
                        backOfficeSsoEnabled: $scope.backOfficeSsoEnabled,
                    };
                },
            },
        });
        modalInstance.result.then(user => {
            init();
        });
    }

    function canManageUsers() {
        return entitlementsService.hasEntitlement('Edit FI User');
    }
    function canViewUsers() {
        return (
            entitlementsService.hasAnyEntitlement('Add FI User', 'View FI User', 'Switch FI') &&
            !canManageUsers()
        );
    }
    function canResetPassword(user) {
        return (
            entitlementsService.hasEntitlement('Reset FI Password') &&
            user.status === 'Active' &&
            !user.isLocked
        );
    }

    function showAuthenticationModal(user) {
        usersService.getUserLoginTokenDetail(user.id).then(userTokenDetails => {
            userTokenDetails.userId = user.id;
            $modal
                .open({
                    template: require('../views/tokenMfaSettings.html'),
                    controller: 'TokenMfaSettingsController',
                    backdrop: 'static',
                    resolve: {
                        userTokenDetails() {
                            return userTokenDetails;
                        },
                    },
                })
                .result.then(changes => {
                    if (changes.unlocked) {
                        user.isTokenMfaLocked = false;
                    }
                });
        });
    }

    function canShowAuthenticationSettingsModal() {
        return $scope.isTokenMfaEnabled && entitlementsService.hasEntitlement('Edit FI User');
    }

    function showUserActionMenu() {
        return entitlementsService.hasAnyEntitlement(
            'Edit FI User',
            'Add FI User',
            'View FI User',
            'Reset FI Password',
            'Switch FI'
        );
    }

    function showAddUserButton() {
        return entitlementsService.hasAnyEntitlement('Add FI User', 'Switch FI');
    }

    init();
}
