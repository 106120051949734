import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('qboSettingsService', qboSettingsService);

qboSettingsService.$inject = ['$http', '$q'];

function qboSettingsService($http, $q) {
    const resourceUrlBase = `${getBackOfficeAPIPath()}qbosettings/`;

    const service = {
        resourceUrlBase,
        getQboSettings,
        setQboSettings,
    };

    return service;

    function getQboSettings() {
        const deferred = $q.defer();
        $http.get(resourceUrlBase).then(
            response => {
                deferred.resolve(response);
            },
            err => {
                deferred.reject(err);
            }
        );
        return deferred.promise;
    }

    function setQboSettings(settings) {
        const deferred = $q.defer();
        $http.post(resourceUrlBase, settings).then(
            response => {
                deferred.resolve(response);
            },
            err => {
                deferred.reject(err);
            }
        );
        return deferred.promise;
    }
}
