angular.module('backOffice').controller('ManageLinkController', manageLinkController);

manageLinkController.$inject = [
    '$scope',
    '$modalInstance',
    'toaster',
    'selectedLink',
    'modalService',
    'resourcePanelConfigurationService',
    'entitlementsService',
];

function manageLinkController(
    $scope,
    $modalInstance,
    toaster,
    selectedLink,
    modalService,
    resourcePanelConfigurationService,
    entitlementsService
) {
    // selected Link
    $scope.selectedLink = selectedLink;

    $scope.messageTypeList = ['None', 'Warning', 'Information', 'Alert'];

    // this will dismiss the window.
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    $scope.onFileSelect = function ($files) {
        if ($files.length > 0) {
            $files[0].convertToBase64(base64 => {
                const result = base64.split(',');
                $scope.selectedLink.logo = result[1];
                $scope.$digest();
            });
        }
    };

    $scope.requiredEntitlement = requiredEntitlement;
    $scope.getTitle = getTitle;

    init();

    function getTitle() {
        if ($scope.selectedLink.id > -1) {
            if (entitlementsService.hasEntitlement('Edit Resources Panel Configuration')) {
                return 'Edit Resource Link';
            }
            return 'View Resource Link';
        }
        return 'Add Resource Link';
    }

    function requiredEntitlement() {
        if ($scope.selectedLink.id > -1) {
            return 'Edit Resources Panel Configuration';
        }
        return 'Resources Panel Configuration Add Link';
    }
    function init() {
        if ($scope.selectedLink.id === -1) {
            $scope.selectedLink.status = 'Inactive';
        }
    }

    $scope.save = function () {
        $scope.selectedLink.link =
            $scope.selectedLink.link.indexOf('://') === -1
                ? `http://${$scope.selectedLink.link}`
                : $scope.selectedLink.link;

        if ($scope.selectedLink.id > -1) {
            updateLink();
        } else {
            createLink();
        }
    };

    function deleteLink() {
        resourcePanelConfigurationService.deleteLink($scope.selectedLink.id).then(response => {
            response.isDeleted = true;
            toaster.delete('Resource Link');
            $modalInstance.close(response);
        });
    }

    $scope.delete = function () {
        const modalOptions = {
            bodyText: 'Are you sure you want to delete the Link?',
            submit(result) {
                deleteLink();
                $modalInstance.close(result);
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    };

    // used to set the form instance.
    $scope.setForm = function (form) {
        $scope.form = form;
    };

    // we watch for form.$dirty, this is used to alert the end user that they changed something in the form.
    $scope.$watch('form.$dirty', isDirty => {
        $modalInstance.isDirty = isDirty;
    });

    // create user
    function createLink() {
        resourcePanelConfigurationService
            .saveResourcePanelConfiguration($scope.selectedLink)
            .then(response => {
                toaster.save('Resource Link');
                $modalInstance.close(response);
            });
    }

    // update user
    function updateLink() {
        resourcePanelConfigurationService
            .updateResourcePanelConfiguration($scope.selectedLink)
            .then(response => {
                toaster.save('Resource Link');
                $modalInstance.close(response);
            });
    }
}
