/* global withCredentials */
angular
    .module('backOffice')
    .config([
        '$httpProvider',
        '$locationProvider',
        function ($httpProvider, $locationProvider) {
            $locationProvider.html5Mode(true);
            $httpProvider.interceptors.push('authInterceptorService');
            $httpProvider.defaults.withCredentials = true;
            $httpProvider.interceptors.push('spinnerService');
        },
    ])
    .config([
        '$provide',
        function ($provide) {
            $provide.decorator('toaster', [
                '$delegate',
                function ($delegate) {
                    $delegate.save = function (entityType) {
                        this.pop('success', 'Saved', `${entityType} saved successfully!`);
                    };

                    $delegate.delete = function (entityType) {
                        this.pop('success', 'Deleted', `${entityType} successfully deleted!`);
                    };

                    $delegate.success = function (message) {
                        this.pop('success', message);
                    };

                    $delegate.error = function (message) {
                        this.pop('error', message);
                    };

                    $delegate.send = function (entityType) {
                        this.pop('success', 'Sent', `${entityType} sent successfully!`);
                    };

                    return $delegate;
                },
            ]);
        },
    ]);
