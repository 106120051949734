angular.module('backOffice').directive('sidebarContainer', () => ({
    restrict: 'A',
    replace: true,
    transclude: true,
    scope: {
        controller: '=',
        collapsible: '=',
    },
    template: require('./sidebarContainerTemplate.html'),
    controller: sidebarContainerController,
}));

sidebarContainerController.$inject = ['$scope'];

function sidebarContainerController($scope) {
    $scope.panels = [];
    $scope.collapsed = false;

    this.addPanel = addPanel;

    function addPanel(panelScope) {
        $scope.panels.push(panelScope);
    }

    $scope.controller = {
        isCollapsed,
        toggleCollapse,
    };

    function toggleCollapse() {
        $scope.collapsed = !$scope.collapsed;
    }

    function isCollapsed() {
        return $scope.collapsed;
    }
}
