/* eslint-disable import/extensions */
import { TmHttpClient } from '@treasury/core/http';

export default class InstitutionPositivePayRequests {
    static async getConfiguration() {
        return (await TmHttpClient.getInstance()).request(
            `positivePayInstitutionConfiguration/allOptions`,
            {}
        );
    }

    static async saveConfiguration(apiValues) {
        return (await TmHttpClient.getInstance()).request(
            `positivePayInstitutionConfiguration/options`,
            {
                method: 'POST',
                body: apiValues,
            }
        );
    }

    static async getCheckExceptionReturnReasons() {
        return (await TmHttpClient.getInstance()).request(`InstitutionCheckExceptionReturnReason`, {
            method: 'GET',
        });
    }

    static async saveCheckExceptionReturnReasons(apiValues) {
        return (await TmHttpClient.getInstance()).request(`InstitutionCheckExceptionReturnReason`, {
            method: 'POST',
            body: apiValues,
        });
    }
}
