/* eslint-disable import/extensions */
import { TmHttpClient, TmHttpResponseType } from '@treasury/core/http';

export default class UserRequests {
    static async changeUserStatus(userPayload, newStatus) {
        const statusId = newStatus === 'Active' ? 0 : 1;
        return (await TmHttpClient.getInstance()).request(
            `users/${userPayload.id}/changeStatus/${statusId}`,
            {
                method: 'POST',
                responseType: TmHttpResponseType.Text,
            }
        );
    }

    static async deleteUser(userId) {
        return (await TmHttpClient.getInstance()).request(`users/${userId}`, {
            method: 'DELETE',
            responseType: TmHttpResponseType.Text,
        });
    }

    static async forgotPassword(userPayload) {
        return (await TmHttpClient.getInstance()).request('users/forgotpassword', {
            body: userPayload,
            method: 'POST',
        });
    }

    static async getActiveUsers() {
        return (await TmHttpClient.getInstance()).request('users/activeusers', {
            method: 'GET',
        });
    }

    static async getCurrentUserTimeAccess() {
        return (await TmHttpClient.getInstance()).request(`users/usertimeaccess`, {
            method: 'GET',
        });
    }

    static async getNewUserTimeAccess() {
        return (await TmHttpClient.getInstance()).request('users/newusertimeaccess', {
            method: 'GET',
        });
    }

    static async getUser(userId) {
        return (await TmHttpClient.getInstance()).request(`users/${userId}`, {
            method: 'GET',
        });
    }

    static async getUserLoginTokenDetail(userId) {
        return (await TmHttpClient.getInstance()).request(`users/logintokendetail/${userId}`, {
            method: 'GET',
        });
    }

    static async getUsers() {
        return (await TmHttpClient.getInstance()).request(`users`, {
            method: 'GET',
        });
    }

    static async getAllUsers() {
        return (await TmHttpClient.getInstance()).request(`users/allusers`, {
            method: 'GET',
        });
    }

    static async getUsersByCompanyIds(companyIds) {
        return (await TmHttpClient.getInstance()).request(
            `users/channel?companyIds=${companyIds}`,
            {
                method: 'GET',
                maxAgeInSeconds: 60000,
            }
        );
    }

    static async manualResetPassword(userId, passwordPayload) {
        return (await TmHttpClient.getInstance()).request(`users/${userId}/ManualResetPassword`, {
            body: { key: passwordPayload.tempPassword, value: passwordPayload.tempPassword },
            method: 'POST',
        });
    }

    static async resetPassword(userId) {
        return (await TmHttpClient.getInstance()).request(`users/${userId}/resetPassword`, {
            method: 'POST',
        });
    }

    static async resetSecureToken(userId) {
        return (await TmHttpClient.getInstance()).request(`users/ResetSecureToken/${userId}`, {
            method: 'POST',
        });
    }

    static async saveUser(userPayload) {
        return (await TmHttpClient.getInstance()).request(`users`, {
            body: userPayload,
            method: 'POST',
        });
    }

    static async unlock(userId) {
        return (await TmHttpClient.getInstance()).request(`users/${userId}/unlock`, {
            method: 'PUT',
        });
    }

    static async unlockSecureToken(userId, recollectToken) {
        return (await TmHttpClient.getInstance()).request(
            `users/UnlockSecureToken/${userId}/${recollectToken}`,
            {
                method: 'POST',
            }
        );
    }

    static async updateUser(userPayload) {
        return (await TmHttpClient.getInstance()).request(`users/${userPayload.id}`, {
            body: userPayload,
            method: 'PUT',
        });
    }

    static async saveUserActivity(userActivityType, activityMessage, relatedId, activityDate) {
        return (await TmHttpClient.getInstance()).request(`users/saveuseractivity`, {
            method: 'POST',
            body: { userActivityType, activityMessage, relatedId, activityDate },
        });
    }
}
