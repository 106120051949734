angular
    .module('backOffice')
    .controller('messageCenterResolveModalController', messageCenterResolveModalController);

messageCenterResolveModalController.$inject = [
    '$scope',
    '$modalInstance',
    'message',
    'messagingCenterService',
];

function messageCenterResolveModalController(
    $scope,
    $modalInstance,
    message,
    messagingCenterService
) {
    $scope.justThis = true;

    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    $scope.save = function () {
        resolveMessages();
    };

    function resolveMessages() {
        messagingCenterService
            .update({
                messageId: message.id,
                actionTypeId: 1,
                statusTypeId: 3,
                updateConversation: !$scope.justThis,
            })
            .then(() => {
                message.status = 'Resolved';
                $modalInstance.close({
                    message,
                    refresh: !$scope.justThis,
                });
            });
    }
}
