import { getBackOfficeAPIPath } from '@treasury/core/http';

angular
    .module('backOffice')
    .factory('resourcePanelConfigurationService', resourcePanelConfigurationService);

resourcePanelConfigurationService.$inject = ['$resource'];

function resourcePanelConfigurationService($resource) {
    const resourceUrl = `${getBackOfficeAPIPath()}resourcecenterconfigurations/`;
    return {
        getResourcePanelConfigurations,
        changeResourcePanelConfigurationSequence,
        deleteLink,
        updateResourcePanelConfiguration,
        saveResourcePanelConfiguration,
    };

    function saveResourcePanelConfiguration(resourcePanelConfiguration) {
        return $resource(resourceUrl).save(resourcePanelConfiguration).$promise;
    }

    function updateResourcePanelConfiguration(resourcePanelConfiguration) {
        const resource = $resource(
            `${resourceUrl}:id`,
            { id: resourcePanelConfiguration.id },
            { update: { method: 'PUT' } }
        );
        return resource.update(resourcePanelConfiguration).$promise;
    }

    function deleteLink(id) {
        return $resource(`${resourceUrl}:id`, { id }).delete().$promise;
    }

    function changeResourcePanelConfigurationSequence(id, direction) {
        return $resource(`${resourceUrl}resequence/?id=:id&direction=:direction`, {
            id,
            direction,
        }).query().$promise;
    }

    function getResourcePanelConfigurations() {
        return $resource(resourceUrl).query().$promise;
    }
}
