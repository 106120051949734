import { getBackOfficeAPIPath } from '@treasury/core/http';

angular
    .module('backOffice')
    .factory('contentManagementConfigurationService', contentManagementConfigurationService);

contentManagementConfigurationService.$inject = ['$resource'];

function contentManagementConfigurationService($resource) {
    const resourceUrl = `${getBackOfficeAPIPath()}contentManagementConfiguration/`;

    return {
        get,
        save,
    };

    function get() {
        return $resource(resourceUrl, {}, { get: { method: 'GET', isArray: false } }).get()
            .$promise;
    }

    function save(model) {
        return $resource(resourceUrl).save(model).$promise;
    }
}
