angular.module('backOffice').directive('boFile', boFileUpload);

function boFileUpload() {
    const directive = {
        restrict: 'E',
        require: 'ngModel',
        scope: {},
        bindToController: true,
        template: require('./fileDirTemplate.html'),
        link,
        controller: FileController,
        controllerAs: '$ctrl',
    };

    function FileController() {
        const ctrl = this;

        ctrl.selectedFiles = [];
        ctrl.getFileNameText = getFileNameText;

        function getFileNameText() {
            const { selectedFiles } = ctrl;

            if (selectedFiles && selectedFiles.length > 0) {
                return selectedFiles.length === 1
                    ? selectedFiles[0].name
                    : `${selectedFiles.length} files selected`;
            }

            return 'No files selected';
        }
    }

    function link(scope, element, attributes, ngModel) {
        const fileElement = angular.element(element[0].querySelector('.bo-file-input'));

        scope.$watch(() => ngModel.$modelValue, onModelChange);
        fileElement.on('change', onFileSelectionChange);

        function onFileSelectionChange(event) {
            let { files } = event.target;

            if (files && files.length === 0) {
                files = undefined;
            }

            scope.$ctrl.selectedFiles = files;
            ngModel.$setViewValue(files);
        }

        function onModelChange(newValue, originalValue) {
            if (newValue !== originalValue && !newValue) {
                fileElement.val(null);
            }
        }
    }

    return directive;
}
