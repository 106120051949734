import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('companiesService', companiesService);

companiesService.$inject = ['$resource', '$http', '$q', 'companyTypes', 'expirationCache'];

function companiesService($resource, $http, $q, companyTypes, expirationCache) {
    const resourceUrl = `${getBackOfficeAPIPath()}companies/`;
    return {
        searchCompanies,
        getNotOnboardedCompanies: searchNotOnboardedCompanies,
        saveAssociatedCompany,
        getCompany,
        getNonOnboardedCompany,
        onboardCompany,
        changeCompanyStatus,
        updateCompanyId,
        getCompanyLimits,
        saveCompanyLimits,
        getCompanyAccounts,
        getCompanyProductAccounts,
        getCompanyAccountsWithValidCore,
        getCompanyProducts,
        getDepositAccounts,
        addAccountsToCompany: addAccountToCompany,
        changeProductStatus,
        changeAccountStatus,
        changeUserStatus,
        addProductToCompany,
        getProductsAndAccounts,
        updateProductsAndAccounts,
        getUsers,
        getContacts,
        saveContact,
        updateContact,
        deleteContact,
        updateProductFeature,
        saveProductFeature,
        getProductPermissions,
        saveProduct,
        getAccountPermissions,
        saveAccount,
        getApprovalSettings,
        getSuperUsers,
        getAssociatedCompanies,
        updateApprovalSettings,
        getAccountSettings,
        updateAccountSettings,
        updateAuthenticationSettings,
        suspendUserAuthentication,
        reenableUserAuthentication,
        getIpAddressSettings,
        updateIpAddressSettings,
        getCompaniesByFI,
        getEnrolledUsers,
        getAvailableProductsForCompany,
        getPositivePayProductFeature,
        getIntuitOfxId,
        generateIntuitOfxIdForCompany,
        getAvailableRemoteDepositProductTypes,
        addRemoteDepositProduct,
        editRemoteDepositProduct,
        getActiveCompanyAccounts,
        getCachedCompanies,
        getCompanyName,
        getQfxEnabledFlag,
        saveQfxCompanyFlag,
    };

    function updateIpAddressSettings(id, settings) {
        const resource = $resource(
            `${resourceUrl}:id/ipAddressSettings`,
            { id },
            { update: { method: 'PUT' } }
        );
        return resource.update(settings).$promise;
    }

    function getIpAddressSettings(id) {
        return $resource(`${resourceUrl}/:id/ipAddressSettings`, { id }).get().$promise;
    }

    function getSuperUsers(companyId) {
        return $resource(`${resourceUrl}superusers/:id`, { id: companyId }).query().$promise;
    }

    //Save Permissions for specific Account
    function saveAccount(id, accountMessage) {
        const resource = $resource(
            `${resourceUrl}:id/accounts/:actionId`,
            { id, actionId: accountMessage.id },
            { update: { method: 'PUT' } }
        );
        return resource.update(accountMessage).$promise;
    }

    function updateProductFeature(id, productFeature) {
        const resource = $resource(
            `${resourceUrl}:id/productfeatures/:actionId`,
            { id, actionId: productFeature.id },
            { update: { method: 'PUT' } }
        );
        return resource.update(productFeature).$promise;
    }

    function saveProductFeature(id, productFeature) {
        return $resource(`${resourceUrl}:id/productfeatures/`, { id }).save(productFeature)
            .$promise;
    }

    //Search Companies for the specified criteria
    function searchCompanies(searchType, searchValue) {
        return $resource(resourceUrl, { searchType, searchValue }).query().$promise;
    }

    //Search Not Onboarded Companies for the specified criteria
    function searchNotOnboardedCompanies(searchType, searchValue) {
        return $resource(`${resourceUrl}notonboardedcompanies`, {
            searchType,
            searchValue,
        }).query().$promise;
    }

    //Associate Company
    function saveAssociatedCompany(id, cifNumber) {
        return $resource(`${resourceUrl}:id/associatedcompanies`, { id }).save({
            value: cifNumber,
        }).$promise;
    }

    //Change Company Status
    function changeCompanyStatus(companyDetails) {
        const resource = $resource(
            `${resourceUrl}:id/status/`,
            { id: companyDetails.id },
            { update: { method: 'PUT' } }
        );
        return resource.update(companyDetails).$promise;
    }

    //On board Company
    function onboardCompany(cifNumber, companyName, companyUniqueId, taxNumber) {
        const resource = $resource(`${resourceUrl}onboard`);
        return resource.save({
            Name: companyName,
            CompanyId: companyUniqueId,
            CifNumber: cifNumber,
            TaxNumber: taxNumber,
        }).$promise;
    }

    //Get Company Detail by ID
    function getCompany(id, companyType) {
        if (!companyType) {
            companyType = companyTypes.Parent;
        }
        return $resource(`${resourceUrl}:id/GetOnboardedCompany/:companyType`, {
            id,
            companyType,
        }).get().$promise;
    }

    //Get Company Detail by CIF Number
    function getNonOnboardedCompany(cifNumber, companyType) {
        if (!companyType) {
            companyType = companyTypes.Parent;
        }
        return $resource(`${resourceUrl}GetNonOnboardedCompany/:companyType`, {
            companyType,
        }).save({ value: cifNumber }).$promise;
    }

    //Add Product to the specified Company Id
    function addProductToCompany(id, productMessage) {
        return $resource(`${resourceUrl}:id/productfeatures/`, { id }).save(productMessage)
            .$promise;
    }

    //Add Account to the specified Company Id
    function addAccountToCompany(id, addAccountsMessage, activate) {
        const deferred = $q.defer();
        const { promise } = deferred;
        $http({
            method: 'post',
            url: `${resourceUrl + id}/accounts/`,
            data: addAccountsMessage,
            params: { activate },
        }).then(response => {
            clearCompanyAccountsCache(id);
            deferred.resolve(response.data);
        });
        return promise;
    }

    //Change account status to the passed value
    function changeAccountStatus(id, accountId, status) {
        return $resource(`${resourceUrl}:id/accountstatus/:accountId/:status`, {
            id,
            accountId,
            status,
        }).save().$promise;
    }

    //Change user status to the passed value
    function changeUserStatus(id, userId, status) {
        return $resource(`${resourceUrl}:id/users/:actionId/changestatus/?status=:status`, {
            id,
            actionId: userId,
            status,
        }).get().$promise;
    }

    //Change product status to the passed value
    function changeProductStatus(id, productId, status) {
        return $resource(`${resourceUrl}:id/productstatus/:actionId/?status=:status`, {
            id,
            actionId: productId,
            status,
        }).save().$promise;
    }

    //Get Products for the specified Company Id
    function getCompanyProducts(id) {
        return $resource(`${resourceUrl}:id/productFeatures`, { id }).query().$promise;
    }

    //Get Deposit Accounts for the specified Company Id
    function getDepositAccounts(id) {
        return $resource(`${resourceUrl}:id/depositAccounts`, { id }).query().$promise;
    }

    //Get  Permissions for specific Product Feature
    function getProductPermissions(id, productId) {
        return $resource(`${resourceUrl}:id/productFeatures/:actionId`, {
            id,
            actionId: productId,
        }).get().$promise;
    }

    function getPositivePayProductFeature(id, productId) {
        return $resource(`${resourceUrl}:id/positivePayProduct/:productId`, {
            id,
            productId,
        }).get().$promise;
    }

    //Get  Permissions for specific Product Feature
    function getAccountPermissions(id, accountId) {
        return $resource(`${resourceUrl}:id/accounts/:actionId`, {
            id,
            actionId: accountId,
        }).get().$promise;
    }

    //Save Permissions for specific Product Feature
    function saveProduct(id, productPermissionsMessage) {
        const resource = $resource(
            `${resourceUrl}:id/productFeatures/:actionId`,
            { id, actionId: productPermissionsMessage.id },
            { update: { method: 'PUT' } }
        );
        return resource.update(productPermissionsMessage).$promise;
    }

    function getCachedCompanies(companyId) {
        return expirationCache.getCache().get(getCompanyAccountsUrl(companyId));
    }

    function clearCompanyAccountsCache(companyId) {
        const cache = expirationCache.getCache();
        const cacheKey = getCompanyAccountsUrl(companyId);

        if (cache.get(cacheKey) !== undefined) {
            cache.remove(cacheKey);
        }
    }

    function getCompanyAccounts(companyId) {
        return $http({
            method: 'get',
            url: getCompanyAccountsUrl(companyId),
            responseType: 'json',
            cache: expirationCache.getCache(),
        }).then(response => angular.copy(response.data));
    }

    function getCompanyProductAccounts(companyId, analysisTransactionTypeId) {
        return $resource(
            `${resourceUrl}:companyId/getAccountsForAnalysisTransactionType/:analysisTransactionTypId`,
            {
                companyId: parseInt(companyId),
                analysisTransactionTypId: analysisTransactionTypeId,
            },
            { get: { method: 'GET', isArray: true } }
        ).get().$promise;
    }

    function getActiveCompanyAccounts(companyId) {
        const inactiveCoreStatuses = ['Dormant', 'Closed', 'Escheat'];
        return getCompanyAccounts(companyId).then(companyAccounts =>
            companyAccounts.filter(
                companyAccount =>
                    companyAccount.status === 'Active' &&
                    inactiveCoreStatuses.indexOf(companyAccount.account.coreStatus) === -1
            )
        );
    }

    //GetAccounts for the specified Company Id which are valid Core Accounts also
    function getCompanyAccountsWithValidCore(id, getValidCoreAccounts) {
        return $resource(`${resourceUrl}:id/accounts/:getValidCoreAccounts`, {
            id,
            getValidCoreAccounts,
        }).query().$promise;
    }

    //Save Limit for the specified Company Id
    function saveCompanyLimits(id, companyLimitsMessage) {
        if (companyLimitsMessage.id !== null && companyLimitsMessage.id > 0) {
            const resource = $resource(
                `${resourceUrl}:id/limits/:actionId`,
                { id, actionId: companyLimitsMessage.id },
                { update: { method: 'PUT' } }
            );
            return resource.update(companyLimitsMessage).$promise;
        }
        return $resource(`${resourceUrl}:id/limits/`, { id }).save(companyLimitsMessage).$promise;
    }

    //Get Limit for the specified Company Id
    function getCompanyLimits(id) {
        return $resource(`${resourceUrl}:id/limits`, { id }).get().$promise;
    }

    //Update the Company Id attribute
    function updateCompanyId(companyMessage) {
        return $resource(`${resourceUrl}:id/UniqueId/:companyId`, {
            id: companyMessage.id,
            companyId: companyMessage.companyId,
        }).save().$promise;
    }

    //Update Accounts & Products mapping
    function updateProductsAndAccounts(id, productsAndAccountsMessage) {
        const resource = $resource(
            `${resourceUrl}:id/accountmapping/:id`,
            { id },
            { update: { method: 'PUT' } }
        );
        return resource.update(productsAndAccountsMessage).$promise;
    }

    //Get Accounts & Product Mapping Information
    function getProductsAndAccounts(id) {
        return $resource(`${resourceUrl}:id/accountmapping/`, { id }).get().$promise;
    }

    //Get Users for the specified Company Id
    function getUsers(id, onlyEnrolled) {
        return $resource(`${resourceUrl}:id/:onlyEnrolled/users`, {
            id,
            onlyEnrolled: onlyEnrolled || false,
        }).query().$promise;
    }

    //Delete the contact
    function deleteContact(companyId, contactId) {
        return $resource(`${resourceUrl}:id/contacts/:actionId`, {
            id: companyId,
            actionId: contactId,
        }).delete().$promise;
    }
    //updates the status of

    //Add new Contact
    function saveContact(companyId, contactMessage) {
        return $resource(`${resourceUrl}:id/contacts/`, { id: companyId }).save(contactMessage)
            .$promise;
    }

    //Update Contact
    function updateContact(companyId, contactMessage) {
        const resource = $resource(
            `${resourceUrl}:id/contacts/:actionId`,
            { id: companyId, actionId: contactMessage.id },
            { update: { method: 'PUT' } }
        );
        return resource.update(contactMessage).$promise;
    }

    //Get Contacts for the specified Company Id
    function getContacts(id) {
        return $resource(`${resourceUrl}:id/contacts`, { id }).query().$promise;
    }

    //Get The Associted Companies
    function getAssociatedCompanies(companyId) {
        return $resource(`${resourceUrl}:id/associatedcompanies`, { id: companyId }).query()
            .$promise;
    }

    function getApprovalSettings(companyId) {
        return $resource(`${resourceUrl}:id/approvalSettings`, { id: companyId }).get().$promise;
    }

    function updateApprovalSettings(companyId, approvalSettings) {
        const resource = $resource(
            `${resourceUrl}:id/approvalSettings/:approvalSettingsId`,
            { id: companyId, approvalSettingsId: approvalSettings.id },
            { update: { method: 'PUT' } }
        );
        return resource.update(approvalSettings).$promise;
    }

    //get account settings
    function getAccountSettings(id) {
        return $resource(`${resourceUrl}:id/userAccountSettings`, { id }).get().$promise;
    }

    //updates account settings
    function updateAccountSettings(id, settings) {
        const resource = $resource(
            `${resourceUrl}:id/userAccountSettings`,
            { id },
            { update: { method: 'PUT' } }
        );
        return resource.update(settings).$promise;
    }
    //  updates authentication settings
    function updateAuthenticationSettings(id, settings) {
        const resource = $resource(
            `${resourceUrl}:id/authenticationSettings`,
            { id },
            { update: { method: 'PUT' } }
        );
        return resource.update(settings).$promise;
    }

    function suspendUserAuthentication(userId, companyId, challengeSuspensionDuration) {
        const resource = $resource(
            `${resourceUrl}:id/users/:userId/suspendAuthentication`,
            {
                userId,
                id: companyId,
                challengeSuspensionDuration,
            },
            { update: { method: 'PUT', isArray: false } }
        );
        return resource.update().$promise;
    }

    function reenableUserAuthentication(userId, companyId) {
        const resource = $resource(
            `${resourceUrl}:id/users/:userId/reenableUserAuthentication`,
            { userId, id: companyId },
            { update: { method: 'PUT' } }
        );
        return resource.update().$promise;
    }

    function getCompaniesByFI() {
        return $resource(`${resourceUrl}getCompaniesByFi`).query().$promise;
    }

    function getEnrolledUsers(id) {
        return $resource(`${resourceUrl}:id/true/users`, { id }).query().$promise;
    }
    function getAvailableProductsForCompany(companyId) {
        return $resource(`${resourceUrl}:companyId/getAvailableProducts`, {
            companyId,
        }).query().$promise;
    }

    function getAvailableRemoteDepositProductTypes() {
        return $resource(`${resourceUrl}GetAvailableRemoteDepositProductTypes`).query().$promise;
    }

    function addRemoteDepositProduct(companyId, product) {
        return $resource(`${resourceUrl}:companyId/AddRemoteDepositProduct`, {
            companyId,
        }).save(product).$promise;
    }

    function editRemoteDepositProduct(companyId, product) {
        return $resource(
            `${resourceUrl}:companyId/EditRemoteDepositProduct`,
            {
                companyId,
            },
            {
                update: { method: 'PUT' },
            }
        ).update(product).$promise;
    }

    function getIntuitOfxId(companyId) {
        return $resource(`${resourceUrl}:companyId/getIntuitOfxId`, { companyId }).get().$promise;
    }

    function generateIntuitOfxIdForCompany(companyId) {
        return $resource(`${resourceUrl}:companyId/generateIntuitOfxIdForCompany`, {
            companyId,
        }).save().$promise;
    }

    function getCompanyName(companyId) {
        const getCompanyNameUrl = `${resourceUrl}:companyId/name`;
        const parameters = {
            companyId,
        };
        return $resource(getCompanyNameUrl, parameters).get().$promise;
    }

    function getCompanyAccountsUrl(companyId) {
        return `${resourceUrl + companyId}/accounts`;
    }

    function getQfxEnabledFlag(companyId) {
        return $resource(`${resourceUrl}:companyId/getQfxEnabledFlag`, {
            companyId,
        }).get().$promise;
    }

    function saveQfxCompanyFlag(newFlag, companyId) {
        return $resource(`${resourceUrl}:companyId/saveQfxCompanyFlag`, {
            newFlag,
            companyId,
        }).save().$promise;
    }
}
