angular.module('backOffice').controller('ContactInfoController', contactInfoController);

contactInfoController.$inject = ['$scope', 'companiesService', '$modal'];

function contactInfoController($scope, companiesService, $modal) {
    $scope.contactList = [];

    $scope.$watch('companyStatus.status', newValue => {
        if (newValue !== null && newValue !== undefined && newValue !== 'Not Onboarded') {
            $scope.loadContacts();
        }
    });

    $scope.loadContacts = function () {
        companiesService.getContacts($scope.companyDetails.id).then(response => {
            $scope.contactList = response;
        });
    };

    $scope.addContact = function () {
        const modalInstance = $modal.open({
            template: require('../views/manageContactModalView.html'),
            size: 'md',
            controller: 'ManageContactController',
            backdrop: 'static',
            resolve: {
                selectedContact() {
                    return { id: -1 };
                },
                existingContacts() {
                    return angular.copy($scope.contactList);
                },
                companyId() {
                    return $scope.companyDetails.id;
                },
                canEdit() {
                    return true;
                },
                canDelete() {
                    return false;
                },
            },
        });
        modalInstance.result.then(createdContact => {
            $scope.contactList.push(createdContact);
        });
    };

    $scope.manageContact = function (contact, index, canEdit) {
        const modalInstance = $modal.open({
            template: require('../views/manageContactModalView.html'),
            size: 'md',
            controller: 'ManageContactController',
            backdrop: 'static',
            resolve: {
                selectedContact() {
                    return angular.copy(contact);
                },
                existingContacts() {
                    return angular.copy($scope.contactList);
                },
                companyId() {
                    return $scope.companyDetails.id;
                },
                canDelete() {
                    return canEdit;
                },
                canEdit() {
                    return canEdit;
                },
            },
        });
        modalInstance.result.then(updatedContact => {
            if (
                updatedContact.isDeleted !== undefined &&
                updatedContact.isDeleted !== null &&
                updatedContact.isDeleted
            ) {
                $scope.contactList.splice(index, 1);
            } else {
                $scope.contactList.splice(index, 1, updatedContact);
            }
        });
    };
}
