angular.module('backOffice').controller('ManageContactController', manageContactController);

manageContactController.$inject = [
    '$scope',
    '$modalInstance',
    'selectedContact',
    'usersService',
    'existingContacts',
    'companiesService',
    'companyId',
    'modalService',
    'canDelete',
    'canEdit',
];

function manageContactController(
    $scope,
    $modalInstance,
    selectedContact,
    usersService,
    existingContacts,
    companiesService,
    companyId,
    modalService,
    canDelete,
    canEdit
) {
    $scope.selectedContact = selectedContact;
    $scope.canEdit = canEdit;
    $scope.canDelete = canDelete;
    $scope.existingContacts = existingContacts;
    $scope.companyId = companyId;

    // temporary
    if (selectedContact.id === undefined || selectedContact.id === null) {
        selectedContact.id = 1;
    }

    // this will dismiss the window.
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    $scope.save = function () {
        if ($scope.selectedContact.id == -1) {
            saveContact();
        } else {
            updateContact();
        }
    };

    $scope.delete = function () {
        const modalOptions = {
            bodyText: 'Are you sure you want to delete this Contact?',
            submit(result) {
                deleteContact();
                $modalInstance1.close(result);
            },
        };
        var $modalInstance1 = modalService.showModal({}, modalOptions);
    };

    function deleteContact() {
        companiesService.deleteContact($scope.companyId, $scope.selectedContact.id).then(() => {
            $scope.selectedContact.isDeleted = true;
            $modalInstance.close($scope.selectedContact);
        });
    }

    function saveContact() {
        companiesService.saveContact($scope.companyId, $scope.selectedContact).then(contact => {
            $modalInstance.close(contact);
        });
    }

    function updateContact() {
        companiesService.updateContact($scope.companyId, $scope.selectedContact).then(contact => {
            $modalInstance.close(contact);
        });
    }

    // used to set the form instance.
    $scope.setForm = function (form) {
        $scope.form = form;
    };

    // we watch for form.$dirty, this is used to alert the end user that they changed something in the form.
    $scope.$watch('form.$dirty', isDirty => {
        $modalInstance.isDirty = isDirty;
    });

    function loadAvailableUsers() {
        companiesService.getUsers($scope.companyId).then(response => {
            // copy the available users to a new list
            const newList = angular.copy(response);
            angular.forEach($scope.existingContacts, contact => {
                angular.forEach(newList, user => {
                    // checking if contact is already existed
                    if (user.userName === contact.user.userName) {
                        newList.splice(newList.indexOf(user), 1);
                    }
                });
            });

            if (selectedContact.id === -1) {
                const promptObj = {
                    userName: 'Please select user',
                };
                newList.splice(0, 0, promptObj);
                $scope.selectedContact.user = promptObj;
            }
            $scope.availableUserList = newList;
        });
    }

    function init() {
        loadAvailableUsers();
    }

    init();
}
