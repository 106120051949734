angular.module('backOffice').controller('UnlockTokenMfaConfirmation', unlockTokenMfaConfirmation);

unlockTokenMfaConfirmation.$inject = ['$scope'];

function unlockTokenMfaConfirmation($scope) {
    $scope.shouldResetToken = false;

    $scope.unlockSecureToken = unlockSecureToken;
    $scope.cancelUnlockToken = cancelUnlockToken;

    function unlockSecureToken() {
        $scope.$close(confirmationResponse(true, $scope.shouldResetToken));
    }

    function cancelUnlockToken() {
        $scope.$close(confirmationResponse(false, false));
    }

    function confirmationResponse(unlockToken, resetToken) {
        return {
            unlockToken,
            resetToken,
        };
    }
}
