angular
    .module('backOffice')
    .controller('ShowCompanyCommentsController', showCompanyCommentsController);

showCompanyCommentsController.$inject = ['$scope', 'comments', '$modalInstance'];

function showCompanyCommentsController($scope, comments, $modalInstance) {
    // comments
    $scope.comments = comments;

    // this will dismiss the window.
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };
}
