/* eslint-disable import/extensions */
import { TmHttpClient } from '@treasury/core/http';

const baseEndpoint = 'AccountRecon/CompanyProductConfiguration';

export class CompanyAccountReconConfiguration {
    static async getAccountReconEligibleAccounts(companyId) {
        const endpoint = `${baseEndpoint}/${companyId}`;
        const http = await TmHttpClient.getInstance();

        return http.request(endpoint, { method: 'GET' });
    }

    static async saveEligibleAccounts(eligibleAccounts) {
        const endpoint = `${baseEndpoint}`;
        const http = await TmHttpClient.getInstance();

        return http.request(endpoint, { method: 'POST', body: eligibleAccounts });
    }
}
