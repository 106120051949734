angular
    .module('backOffice')
    .controller('SyncTransactionCodeController', syncTransactionCodeController);

syncTransactionCodeController.$inject = [
    '$scope',
    '$modalInstance',
    'transactionCodesService',
    'toaster',
];

function syncTransactionCodeController($scope, $modalInstance, transactionCodesService, toaster) {
    $scope.isLoaded = false;
    $scope.transactionCodeList = [];

    // this will dismiss the window.
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    init();

    function init() {
        transactionCodesService.getTransactionCodeSyncDetails().then(response => {
            $scope.transactionCodeList = response;
            $scope.isLoaded = true;
        });
    }

    $scope.save = function () {
        transactionCodesService.syncTransactionCodes().then(response => {
            toaster.save('Sync Transaction Codes');
            $modalInstance.close();
        });
    };

    // used to set the form instance.
    $scope.setForm = function (form) {
        $scope.form = form;
    };
}
