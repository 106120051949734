angular.module('backOffice').factory('resolvePropertyService', () =>
    // Given a string to describe a property on an object (can be delimited with . for sub-properties),
    // this service will resolve the value on said property.
    ({
        resolveProperty(obj, path, defValue) {
            if (angular.isUndefined(path) || path == null) {
                return obj;
            }
            const rv = path.split('.').reduce((o, p) => o && o[p], obj);
            return rv || defValue;
        },
    })
);
