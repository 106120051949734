angular.module('backOffice').directive('differenceFlag', () => ({
    restrict: 'A',
    replace: true,
    scope: {
        label: '@',
        newValue: '=',
        currentValue: '=',
        currentDisplayValue: '=',
    },
    template: require('./differenceFlagTemplate.html'),
    controller: differenceFlagController,
}));

differenceFlagController.$inject = ['$scope'];

function differenceFlagController($scope) {
    $scope.isDifferent = isDifferent;

    init();

    function init() {
        if (!$scope.label) {
            $scope.label = 'value';
        }

        if ($scope.currentValue && $scope.currentValue !== '') {
            $scope.message = `Current ${$scope.label}: ${
                $scope.currentDisplayValue || $scope.currentValue
            }`;
        } else {
            $scope.message = 'No current value.';
        }
    }

    function isDifferent() {
        return $scope.newValue !== $scope.currentValue;
    }
}
