import { exists, toTitleCase } from '@treasury/utils/functions';
import { html } from 'lit';

function setCityStateZip(city: string, state: string, zip: string) {
    let cityStateZip = '';
    if (exists(city)) cityStateZip = `${toTitleCase(city)}, `;
    if (exists(state)) cityStateZip += `${state} `;
    if (exists(zip)) cityStateZip += zip;
    return cityStateZip;
}

function setStreetAddress(line1: string, line2: string, line3: string) {
    let address = '';
    if (exists(line1)) address = `${line1}`;
    if (exists(line2)) address += `, ${line2}`;
    if (exists(line3)) address += `, ${line3}`;
    if (address.startsWith(', ')) {
        address.slice(2);
    }
    address = toTitleCase(address);
    return address;
}

export function renderReadOnlyAddress(address: {
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
}) {
    const { addressLine1, addressLine2, addressLine3, city, state, postalCode, country } = address;
    const streetAddress = setStreetAddress(addressLine1, addressLine2, addressLine3);
    const cityStateZip = setCityStateZip(city, state, postalCode);
    const countryTitleCased: string = toTitleCase(country);

    return html`
        <div>${streetAddress}</div>
        <div>${cityStateZip}</div>
        <div>${countryTitleCased}</div>
    `;
}
