import '@treasury/omega/components/omega-select.js';

angular
    .module('backOffice')
    .controller('ConfigureInformationReportingController', ConfigureInformationReportingController);

ConfigureInformationReportingController.$inject = [
    '$scope',
    'configurationService',
    'modalService',
    '$modal',
    'toaster',
    'qboSettingsService',
    'regexConstants',
    '$stateParams',
    'baiConfigurationService',
    'ofxSettingsService',
    'jhaSupportService',
    'entitlementsService',
];

function ConfigureInformationReportingController(
    $scope,
    configurationService,
    modalService,
    $modal,
    toaster,
    qboSettingsService,
    regexConstants,
    $stateParams,
    baiConfigurationService,
    ofxSettingsService,
    jhaSupportService,
    entitlementsService
) {
    $scope.productFeatureId = $stateParams.productFeatureId;
    $scope.productFeatureStatus = $stateParams.productFeatureStatus;
    $scope.canEdit = $stateParams.canEdit;
    $scope.productFeature = {};
    $scope.groupedBaiCodes = null;
    $scope.selectedTransactionCodes = {};
    $scope.titleCollapsed = [];
    $scope.baiCodeTitles = {};
    $scope.reportSupportsFormat = reportSupportsFormat;
    $scope.canSelectReportFormat = canSelectReportFormat;
    $scope.getColumnEnabled = getColumnEnabled;
    $scope.toggleColumn = toggleColumn;
    $scope.toggleRow = toggleRow;
    $scope.toggleAll = toggleAll;
    $scope.isToggleFormatDisabled = isToggleFormatDisabled;
    $scope.setDirty = setDirty;
    $scope.setForm = setForm;
    $scope.alphaNumericPattern = regexConstants.AlphaNumericRegex;
    $scope.selectTab = selectTab;
    $scope.isTabSelected = isTabSelected;
    $scope.changeCollapseState = changeCollapseState;
    $scope.selectTransactionCodes = selectTransactionCodes;
    $scope.cancel = cancel;
    $scope.saveProductFeatureConfiguration = saveProductFeatureConfiguration;
    $scope.isSaveReportingConfigurationDisabled = isSaveReportingConfigurationDisabled;
    $scope.isSaveQboSettingsDisabled = isSaveQboSettingsDisabled;
    $scope.saveQboSettings = saveQboSettings;
    $scope.isSaveBaiDisabled = isSaveBaiDisabled;
    $scope.saveBaiConfiguration = saveBaiConfiguration;
    $scope.displayNoResultsFoundMessage = displayNoResultsFoundMessage;
    $scope.showBaiCodesForGroup = showBaiCodesForGroup;
    $scope.dataIsValid = dataIsValid;
    $scope.toggleOfxEnabledForFI = toggleOfxEnabledForFI;
    $scope.confirmDisableOfx = confirmDisableOfx;
    $scope.hasEntitlement = hasEntitlement;
    $scope.allowedToViewReportingConfiguration = allowedToViewReportingConfiguration;
    $scope.allowedToViewBAI2Configuration = allowedToViewBAI2Configuration;
    $scope.qfxfiId = '';
    $scope.transactionCodeHashFunction = item => item.id;
    $scope.updateBaiCodeTransactionCodes = updateBaiCodeTransactionCodes;

    function init() {
        loadProductFeature();
        loadOfxSettings();
        checkQfxProductStatusForFi();
        if (allowedToViewReportingConfiguration()) {
            selectTab('Reporting Configuration');
        } else if (allowedToViewBAI2Configuration()) {
            selectTab('BAI2 Configuration');
        } else {
            selectTab('');
        }
    }

    function hasEntitlement(entitlement) {
        return entitlementsService.hasEntitlement(entitlement);
    }

    function allowedToViewReportingConfiguration() {
        return entitlementsService.hasAnyEntitlement(
            'Edit Product Feature Configuration',
            'View Product Feature Configuration',
            'Switch FI'
        );
    }

    function allowedToViewBAI2Configuration() {
        return entitlementsService.hasAnyEntitlement('Manage BAI2 Configuration', 'Switch FI');
    }

    // used to set the form instance.
    function setForm(form) {
        $scope.form = form;
    }

    function setDirty() {
        $scope.form.$dirty = true;
    }

    function showBaiCodesForGroup(index) {
        return $scope.groupedBaiCodes[index].length > 0 && !$scope.titleCollapsed[index];
    }

    function displayNoResultsFoundMessage(index) {
        return $scope.groupedBaiCodes[index].length === 0;
    }

    function dataIsValid() {
        let reports;
        let isValid;

        isValid = true;
        reports = $scope.productFeature.reportParameters;
        angular.forEach(reports, report => {
            if (report.hasDownload) {
                if (!report.hasCsv && !report.hasPdf && !report.hasBai) {
                    isValid = false;
                }
            }
        });

        return isValid;
    }

    function selectTab(tabName) {
        $scope.selectedTab = tabName;
        reloadTab(tabName);
    }

    function isTabSelected(tabName) {
        return tabName === $scope.selectedTab;
    }

    function cancel(selectedTab) {
        reloadTab(selectedTab);
    }

    function reloadTab(selectedTab) {
        if (selectedTab === 'Reporting Configuration') {
            loadProductFeature();
        } else if (selectedTab === 'BAI2 Configuration') {
            loadBaiCodes();
        } else if (selectedTab === 'QBO Configuration') {
            loadQboSettings();
        }
    }

    function selectTransactionCodes(baiCode) {
        if (!baiCode.isSelected) {
            baiCode.selectedTransactionCodes = null;
            angular.forEach(baiCode.transactionCodeList, transactionCode => {
                transactionCode.isChecked = false;
            });
        }
    }

    function saveProductFeatureSettings() {
        configurationService
            .saveProductFeature($scope.productFeatureId, $scope.productFeature)
            .then(response => {
                toaster.save('Reporting Configuration');
            });
    }

    function saveProductFeatureConfiguration() {
        // do not allow to proceed if hasDownload but has not selected an export type.
        const reportsHasExportFormats = !$scope.productFeature.reportParameters.some(
            report =>
                report.hasDownload &&
                reportSupportsFormat(report, 'CSV') &&
                !report.hasCsv &&
                reportSupportsFormat(report, 'PDF') &&
                !report.hasPdf &&
                reportSupportsFormat(report, 'BAI') &&
                !report.hasBai
        );

        if (reportsHasExportFormats || $scope.productFeatureStatus !== 'Active') {
            saveProductFeatureSettings();
        } else {
            modalService.showModal(
                {},
                {
                    alertType: 'Error',
                    isAlert: true,
                    summaryText:
                        'Export formats must be selected for all reports with export enabled.',
                }
            );
        }
    }

    function isSaveReportingConfigurationDisabled() {
        return !$scope.form.$dirty || !$scope.dataIsValid() || $scope.canEdit === false;
    }

    function isSaveQboSettingsDisabled() {
        return !$scope.qboForm.$dirty || $scope.qboForm.$invalid || $scope.canEdit === false;
    }

    function saveQboSettings() {
        qboSettingsService.setQboSettings({ intuitBankId: $scope.intuitBankId }).then(response => {
            toaster.save('QBO Configuration');
        });
    }

    function isSaveBaiDisabled() {
        return !$scope.baiForm.$dirty && $scope.hasEntitlement('Manage BAI2 Configuration');
    }

    function saveBaiConfiguration() {
        const transCodesToSave = [];
        angular.forEach($scope.groupedBaiCodes, baicodes => {
            angular.forEach(baicodes, baicode => {
                angular.forEach(baicode.selectedTransactionCodes, transactioncode => {
                    transactioncode.baiCode = baicode.baiCode;
                    transCodesToSave.push(transactioncode);
                });
            });
        });
        if (validateTransactionCodesForSave()) {
            baiConfigurationService
                .saveBaiConfiguration({ selectedTransactionCodes: transCodesToSave })
                .then(response => {
                    loadProductFeature();
                    toaster.save('BAI2 Configuration');
                });
        }
    }

    function validateTransactionCodesForSave() {
        const duplicateText = createDuplicateError($scope.groupedBaiCodes, $scope.baiCodeTitles);
        if (duplicateText.length > 0) {
            $modal.open({
                template: require('../views/duplicateTransactionCodeErrorModalView.html'),
                size: 'md',
                controller: 'DuplicateTransactionCodeErrorModalController',
                backdrop: 'static',
                resolve: {
                    errors() {
                        return duplicateText;
                    },
                },
            });
            return false;
        }
        return true;
    }

    function createDuplicateError(baiCodes, transactionCodeTitles) {
        const buckets = {};
        Object.keys(baiCodes).forEach(key =>
            baiCodes[key].forEach(keyItem => {
                keyItem.selectedTransactionCodes.forEach(item => {
                    buckets[item.code] = buckets[item.code] || [];
                    buckets[item.code].push(
                        `${transactionCodeTitles[key].description} : ${keyItem.description}`
                    );
                });
            })
        );
        const errors = [];
        Object.keys(buckets)
            .map(code => ({
                code,
                instances: buckets[code],
            }))
            .filter(filteredItem => filteredItem.instances.length > 1)
            .forEach(filteredItem => {
                errors.push(
                    `Transaction Code ${filteredItem.code}  (${filteredItem.instances.join(', ')})`
                );
            });
        return errors;
    }

    function changeCollapseState(index) {
        $scope.titleCollapsed[index] = !$scope.titleCollapsed[index];
    }

    function loadProductFeature() {
        configurationService.getProductFeatureDetails($scope.productFeatureId).then(response => {
            $scope.productFeature = response;
        });
    }

    function loadQboSettings() {
        qboSettingsService.getQboSettings().then(response => {
            if (response) {
                $scope.intuitBankId = response.data.intuitBankId;
            }
        });
    }

    function updateBaiCodeTransactionCodes(event, code) {
        code.selectedTransactionCodes = event.target.value;
        $scope.baiForm.$dirty = true;
    }

    function loadBaiCodes() {
        baiConfigurationService.getBaiConfiguration().then(response => {
            const { baiCodeTypes } = response;
            $scope.baiCodeTitles = response.baiCodeTitles;
            $scope.selectedTransactionCodes = response.selectedTransactionCodes;
            $scope.transactionCodeList = response.transactionCodeList.map(code => ({
                text: code.label,
                value: code,
            }));

            let index = 0;
            let index2 = 1;
            $scope.groupedBaiCodes = {};

            angular.forEach($scope.baiCodeTitles, baiCodeTitle => {
                $scope.groupedBaiCodes[index] = {};
                $scope.groupedBaiCodes[index] = baiCodeTypes.filter(
                    currentBaiCode => currentBaiCode.baiCodeTitleId === index2
                );
                $scope.titleCollapsed[index] = true;

                index++;
                index2++;
            });
            $scope.titleCollapsed[0] = false;
            $scope.baiForm.$setPristine();
        });
    }

    function areAllEnabled() {
        let enabled = true;

        $scope.productFeature.reportParameters.forEach(report => {
            enabled = enabled && getRowEnabled(report);
        });

        return enabled;
    }

    function setAllEnabled(enabled) {
        $scope.productFeature.reportParameters.forEach(report => {
            setRowEnabled(report, enabled);
        });
    }

    function toggleAll() {
        const newValue = !areAllEnabled();

        setAllEnabled(newValue);
    }

    function isToggleFormatDisabled(formatName) {
        return (
            !isFormatConfigured(formatName) ||
            $scope.productFeature.reportParameters.some(item => !item.hasDownload)
        );
    }

    function getRowEnabled(report) {
        if (isReportFormatEnabled(report, 'BAI')) {
            return (
                report.hasDownload &&
                (!reportSupportsFormat(report, 'CSV') || isReportFormatEnabled(report, 'CSV')) &&
                (!reportSupportsFormat(report, 'PDF') || isReportFormatEnabled(report, 'PDF')) &&
                (!reportSupportsFormat(report, 'BAI') || isReportFormatEnabled(report, 'BAI'))
            );
        }
        return (
            report.hasDownload &&
            (!reportSupportsFormat(report, 'CSV') || isReportFormatEnabled(report, 'CSV')) &&
            (!reportSupportsFormat(report, 'PDF') || isReportFormatEnabled(report, 'PDF'))
        );
    }

    function setRowEnabled(report, enabled) {
        report.hasDownload = enabled;
        setReportFormatEnabled(report, 'CSV', enabled);
        setReportFormatEnabled(report, 'PDF', enabled);
        setReportFormatEnabled(report, 'BAI', enabled);
    }

    function toggleRow(report) {
        const newValue = !getRowEnabled(report);

        setRowEnabled(report, newValue);
    }

    function toggleColumn(columnName) {
        const newValue = !getColumnEnabled(columnName);

        setColumnEnabled(columnName, newValue);
    }

    function getColumnEnabled(columnName) {
        const propertyName = getReportPropertyName(columnName);

        let enabled = true;

        if ($scope.productFeature.reportParameters == null) {
            return false;
        }

        if (columnName === 'Download') {
            $scope.productFeature.reportParameters.forEach(report => {
                if (!report[propertyName]) {
                    enabled = false;
                }
            });
        } else {
            $scope.productFeature.reportParameters.forEach(report => {
                if (
                    reportSupportsFormat(report, columnName) &&
                    !isReportFormatEnabled(report, columnName)
                ) {
                    enabled = false;
                }
            });
        }

        return enabled;
    }

    function setColumnEnabled(columnName, enabled) {
        const propertyName = getReportPropertyName(columnName);

        if (columnName === 'Download') {
            $scope.productFeature.reportParameters.forEach(report => {
                report[propertyName] = enabled;
            });

            setDirty();
        } else {
            $scope.productFeature.reportParameters.forEach(report => {
                setReportFormatEnabled(report, columnName, enabled);
            });
        }
    }

    function reportSupportsFormat(report, formatName) {
        if (formatName === 'BAI') {
            return report.supportsBai;
        }

        return true;
    }

    function canSelectReportFormat(report, formatName) {
        return (
            report.hasDownload &&
            isFormatConfigured(formatName) &&
            reportSupportsFormat(report, formatName)
        );
    }

    function isFormatConfigured(formatName) {
        if ($scope.productFeature.exportFormats == null) {
            return false;
        }

        const format = $scope.productFeature.exportFormats.filter(
            currentFormat => currentFormat.name.indexOf(formatName) >= 0
        )[0];

        if (format != null) {
            return format.isConfigured;
        }

        return true;
    }

    function setReportFormatEnabled(report, formatName, enabled) {
        if (isReportFormatEnabled(report, formatName) === enabled) {
            return;
        }

        // If the value can't be changed through the UI we should just leave it the
        // way it is. This is mostly for the toggles.
        if (!isFormatConfigured(formatName) || !reportSupportsFormat(report, formatName)) {
            return;
        }

        report[getReportPropertyName(formatName)] = enabled;

        setDirty();
    }

    function isReportFormatEnabled(report, formatName) {
        return report[getReportPropertyName(formatName)];
    }

    function getReportPropertyName(column) {
        return `has${column.toUpperCase()[0]}${column.toLowerCase().substring(1)}`;
    }

    function loadOfxSettings() {
        $scope.selectedInstitution = jhaSupportService.getUserFiId();
        ofxSettingsService.getOfxEnabledFlagForFI($scope.selectedInstitution).then(response => {
            if (response && response.success) {
                $scope.ofxIntegrationEnabled = response.ofxReady;
                $scope.ofxEnabledForFI = response.isOfxEnabled;
            }
        });
    }

    function toggleOfxEnabledForFI() {
        const newVal = !$scope.ofxEnabledForFI;
        ofxSettingsService
            .updateOfxEnabledFlagForFI($scope.selectedInstitution, newVal)
            .then(response => {
                if (response && response.success) {
                    $scope.ofxEnabledForFI = response.isOfxEnabled;
                }
            });
    }

    function confirmDisableOfx() {
        const newVal = !$scope.ofxEnabledForFI;

        if (!newVal) {
            const modalOptions = {
                headerText: '',
                bodyText:
                    'Changing this parameter to Inactive will also set all OFX enabled companies to Inactive.  If changed back to Active all companies will remain Inactive until individually set to Active.',
                actionButtonText: 'Ok',
                closeButtonText: 'Cancel',
                submit() {
                    $modalInstance.close();
                    toggleOfxEnabledForFI();
                },
            };
            var $modalInstance = modalService.showModal({}, modalOptions);
        } else {
            toggleOfxEnabledForFI();
        }
    }

    function checkQfxProductStatusForFi() {
        configurationService.checkQfxProductStatusForFi().then(response => {
            $scope.qfxfiId = response.fiId;
            if (response.enabledFlag === 'Y') {
                $scope.qfxInsertedForFi = true;
                $scope.qfxEnabledForFI = true;
            } else if (response.enabledFlag === 'N') {
                $scope.qfxInsertedForFi = true;
                $scope.qfxEnabledForFI = false;
            } else if (response.enabledFlag === '' || response.enabledFlag === null) {
                $scope.qfxInsertedForFi = false;
                $scope.qfxEnabledForFI = false;
            }
        });
    }

    init();
}
