import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import moment from 'moment';

angular
    .module('backOffice')
    .controller('authenticationSettingsController', authenticationSettingsController);

authenticationSettingsController.$inject = [
    '$scope',
    '$modal',
    '$window',
    'challengeSettings',
    'companyId',
    'companiesService',
    '$modalInstance',
    'modalService',
    'toaster',
    'companyUsersService',
    'entitlementsService',
];

function authenticationSettingsController(
    $scope,
    $modal,
    $window,
    challengeSettings,
    companyId,
    companiesService,
    $modalInstance,
    modalService,
    toaster,
    companyUsersService,
    entitlementsService
) {
    $scope.challengeSettings = challengeSettings;
    $scope.companyId = companyId;
    $scope.hourOptions = null;

    $scope.close = close;
    $scope.suspend = suspend;
    $scope.reenable = reenable;
    $scope.setForm = setForm;
    $scope.resetUser = resetUser;
    $scope.unlockUser = unlockUser;
    $scope.authenticationMethods = {
        disabled: 0,
        oob: 1,
        secureToken: 2,
    };
    $scope.userStatuses = {
        unregistered: 0,
        registered: 1,
        suspended: 2,
        locked: 3,
    };
    $scope.methodIsOob = methodIsOob;
    // TODO / FEATURE: Remove the following line when the new OOB feature is no longer dark
    $scope.isOobPinUsed = isOobPinUsed;
    $scope.methodIsSecureToken = methodIsSecureToken;
    $scope.methodIsDisabled = methodIsDisabled;
    $scope.formatPhoneNumber = formatPhoneNumber;
    $scope.getAuthenticationMethod = getAuthenticationMethod;
    $scope.getAbbreviatedAuthenticationMethod = getAbbreviatedAuthenticationMethod;
    $scope.getAuthenticationSuspendedText = getAuthenticationSuspendedText;
    $scope.isAuthenticationSuspended = isAuthenticationSuspended;
    $scope.openVipManager = openVipManager;
    $scope.selectModel = {};
    $scope.isUisEnabled = false;
    $scope.popOverText = '';

    init();

    function setUserStatus(status) {
        $scope.challengeSettings.userStatusId = status;
        switch (status) {
            case $scope.userStatuses.unregistered:
                $scope.challengeSettings.userStatus = 'Unregistered';
                break;
            case $scope.userStatuses.registered:
                $scope.challengeSettings.userStatus = 'Registered';
                break;
            case $scope.userStatuses.suspended:
                $scope.challengeSettings.userStatus = 'Suspended';
                break;
            case $scope.userStatuses.locked:
                $scope.challengeSettings.userStatus = 'Locked';
                break;
            default:
                $scope.challengeSettings.userStatus = '';
                break;
        }
    }

    async function init() {
        $scope.isUisEnabled = await FeatureFlagService.isEnabled(Feature.UisEnabled);
        $scope.popOverText = $scope.isUisEnabled
            ? "Selecting Reset TM Authentication will delete the user's current credential registration that is used for TM risk point activities. The system will request that the user register again at their next login. (This registration is specific to activities performed while within the Treasury application.)"
            : "Selecting Reset will delete this user's current credential registration. The system will request that the user register again at the next login";
        $scope.hourOptions = [
            { label: '15 minutes', value: 1 },
            { label: '1 hour', value: 2 },
            { label: '3 hours', value: 3 },
            { label: '6 hours', value: 4 },
            { label: '12 hours', value: 5 },
            { label: '24 hours', value: 6 },
        ];
        $scope.selectModel.suspendDuration = $scope.hourOptions[0];
    }

    function methodIsOob() {
        return (
            $scope.challengeSettings &&
            $scope.challengeSettings.authenticationMethodId === $scope.authenticationMethods.oob
        );
    }

    // TODO / FEATURE: Remove the following method when the new OOB feature is no longer dark
    function isOobPinUsed() {
        const newOobFeatureFlagOn = entitlementsService.hasEntitlement(
            'Feature.Authentication.EnsOobValidatePerFi'
        );
        return methodIsOob() && !newOobFeatureFlagOn;
    }

    function methodIsSecureToken() {
        return (
            $scope.challengeSettings &&
            $scope.challengeSettings.authenticationMethodId ===
                $scope.authenticationMethods.secureToken
        );
    }

    function methodIsDisabled() {
        return (
            !$scope.challengeSettings ||
            $scope.challengeSettings.authenticationMethodId ===
                $scope.authenticationMethods.disabled
        );
    }

    function formatPhoneNumber(value) {
        if (value) {
            return `${value.substr(0, 3)}-${value.substr(3, 3)}-${value.substr(6, 4)}`;
        }
        return '';
    }

    function openVipManager() {
        $window.open('https://manager.vip.symantec.com/vipmgr/signin.v', '_blank');
    }

    function unlockUser() {
        const modalInstance = $modal.open({
            template: require('../views/unlockUserChallengeModalView.html'),
            size: 'md',
            controller: 'UnlockUserChallengeController',
            backdrop: 'static',
            resolve: {
                authenticationMethod: getAuthenticationMethod,
            },
        });
        modalInstance.result.then(recollect => {
            companyUsersService
                .unlockUserChallenge($scope.companyId, $scope.challengeSettings.userId, recollect)
                .then(() => {
                    toaster.save('User');
                    if (recollect) {
                        setUserStatus($scope.userStatuses.unregistered);
                    } else {
                        setUserStatus($scope.userStatuses.registered);
                    }
                });
        });
    }

    function resetUser() {
        const { companyId } = $scope;
        const { userId } = $scope.challengeSettings;

        const modalInstance = $modal.open({
            template: require('../views/recollectUserInformationModalView.html'),
            size: 'md',
            controller: 'ReCollectUserChallengeInformationController',
            backdrop: 'static',
            resolve: {
                userId() {
                    return userId;
                },
                companyId() {
                    return companyId;
                },
            },
        });
        modalInstance.result.then(() => {
            // The dialog changes the user challenge details so we need to get them again to
            // reflect those changes on this view.
            companyUsersService.getUserChallengeDetail(companyId, userId).then(response => {
                $scope.challengeSettings = response;
                toaster.save('User');
            });
        });
    }

    function getAuthenticationMethod() {
        if (methodIsOob()) {
            return 'Out-of-Band';
        }
        if (methodIsSecureToken()) {
            return 'Secure Token';
        }
        return '';
    }

    function getAbbreviatedAuthenticationMethod() {
        if (methodIsOob()) {
            return 'OOB';
        }
        if (methodIsSecureToken()) {
            return 'Token';
        }
        return '';
    }

    function reenable() {
        const modalInstance = $modal.open({
            template: require('../views/reenableUserAuthenticationModal.html'),
            size: 'md',
            controller: 'ReenableUserAuthenticationController',
            backdrop: 'static',
            resolve: {
                challengeSettings() {
                    return $scope.challengeSettings;
                },
                companyId() {
                    return $scope.companyId;
                },
                authenticationMethod: getAuthenticationMethod,
            },
        });

        modalInstance.result.then(() => {
            $scope.challengeSettings.utcSuspendEndTime = null;
            $scope.challengeSettings.localSuspendEndTime = null;
            setUserStatus($scope.userStatuses.registered);
        });
    }

    function suspend() {
        const modalDefaults = { backdrop: 'static' };

        const modalOptions = {
            closeButtonText: 'Cancel',
            actionButtonText: 'Suspend',
            secondaryButtonClass: 'btn-link',
            headerText: 'Suspend User',
            bodyMessages: [],
            summaryText: '',
            actionCode: '',
            bodyTitle: '',
            isAlert: false,
            alertType: 'Success',
            bodyText: `Are you sure you want to suspend ${getAuthenticationMethod().toLowerCase()} authentication for this user?`,
            submit(result) {
                modalInstance.close(result);
            },
        };

        modalDefaults.controller = [
            '$scope',
            '$modalInstance',
            function ($scope, $modalInstance) {
                $scope.modalOptions = modalOptions;
                $scope.modalOptions.ok = function (result) {
                    $modalInstance.close(result);
                };
                $scope.modalOptions.close = function () {
                    $modalInstance.dismiss();
                };
            },
        ];

        var modalInstance = modalService.showModal(modalDefaults, modalOptions);

        return modalInstance.result.then(() => {
            companiesService
                .suspendUserAuthentication(
                    $scope.challengeSettings.userId,
                    $scope.companyId,
                    $scope.selectModel.suspendDuration.value
                )
                .then(response => {
                    $scope.challengeSettings.utcSuspendEndTime = response.utcSuspendEndTime;
                    $scope.challengeSettings.localSuspendEndTime = response.localSuspendEndTime;
                    setUserStatus($scope.userStatuses.suspended);
                    if ($scope.selectModel.suspendDuration.value === 1) {
                        toaster.success('Authentication suspended for 15 minutes.');
                    } else if ($scope.selectModel.suspendDuration.value === 2) {
                        toaster.success('Authentication suspended for 1 hour.');
                    } else {
                        toaster.success(
                            `Authentication suspended for ${$scope.selectModel.suspendDuration.label} hours.`
                        );
                    }
                });
        });
    }

    function getAuthenticationSuspendedText() {
        if (!$scope.challengeSettings.localSuspendEndTime) {
            return '';
        }
        return `Authentication is suspended for this user until ${moment(
            $scope.challengeSettings.localSuspendEndTime
        )
            .calendar()
            .toLowerCase()}.`;
    }

    // TODO, Further enhancement needed: When this is converted, use FI time instead of machine time for this, to prevent Time Zone issues.
    function isAuthenticationSuspended() {
        const savedEndTime = $scope.challengeSettings.utcSuspendEndTime;
        if (!savedEndTime) return false;
        const suspendTime = savedEndTime.endsWith('Z') ? `${savedEndTime}` : `${savedEndTime}Z`;
        return new Date(suspendTime).getTime() > Date.now();
    }

    function close() {
        $modalInstance.close();
    }

    function setForm(form) {
        $scope.form = form;
    }
}
