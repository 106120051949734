angular
    .module('backOffice')
    .controller('ConfigureBDIeStatementsController', configureBDIeStatementsController);

configureBDIeStatementsController.$inject = [
    '$scope',
    '$modalInstance',
    'modalService',
    'configurationService',
    'productFeatureId',
    'productFeatureStatus',
    'canEdit',
    'toaster',
];

function configureBDIeStatementsController(
    $scope,
    $modalInstance,
    modalService,
    configurationService,
    productFeatureId,
    productFeatureStatus,
    canEdit,
    toaster
) {
    $scope.productFeatureId = productFeatureId;
    $scope.productFeatureStatus = productFeatureStatus;
    $scope.canEdit = canEdit;

    $scope.productFeature = {};

    $scope.setForm = setForm;
    $scope.save = save;
    $scope.cancel = cancel;

    function setForm(form) {
        $scope.form = form;
    }

    function save() {
        $scope.productFeature.status = productFeatureStatus;
        console.log($scope.productFeature);
        console.log($scope.productFeatureId);
        configurationService
            .saveProductFeature($scope.productFeatureId, $scope.productFeature)
            .then(response => {
                toaster.save('BDI eStatements Configuration');
                $modalInstance.close();
            });
    }

    function cancel() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel the changes?',
                submit(result) {
                    cancelModalInstance.dismiss();
                    $modalInstance.dismiss();
                },
            };
            var cancelModalInstance = modalService.showModal({}, modalOptions);
        } else {
            $modalInstance.dismiss();
        }
    }

    function init() {
        $scope.productFeature = {
            productDisplayName: '',
            noAccountMessage: '',
            accountType: '',
            url: '',
        };

        loadProductFeature();
    }

    function loadProductFeature() {
        configurationService.getProductFeatureDetails($scope.productFeatureId).then(response => {
            $scope.productFeature = response;
        });
    }

    init();
}
