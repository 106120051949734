
import {
    AchFederalTaxPaymentAmountDto,
    AchFederalTaxPaymentDto,
    AchPaymentModelDto,
    AchStateTaxPaymentAmountDto,
    AchStateTaxPaymentDto
} from '@treasury/api/bo';
import { AchPayment } from './ach-payment.entity';
import { defaultAchStateTaxPaymentModelDto } from './data/ach.data';

export interface TaxAmountDescription {
    amount: number;
    description?: string;
};

export class AchTaxPayment extends AchPayment<AchStateTaxPaymentDto & AchFederalTaxPaymentDto & AchPaymentModelDto> {
    public createDefault(): this {
        return new AchTaxPayment(defaultAchStateTaxPaymentModelDto) as this;
    }

    public get taxPaymentType() {
        switch(this.dto.achPaymentTypeId) {
            case 2:
                return 'State';
            case 3:
                return 'Federal';
        }
    }

    public get isState() {
        return this.dto.achPaymentTypeId === 2;
    }

    public get isFederal() {
        return this.dto.achPaymentTypeId === 3;
    }

    public get state() {
        return this.dto.state ? this.dto.state.stateName : '';
    }

    public get taxCode() {
        return this.dto.taxCode ?? '';
    }

    public get taxPayerId() {
        return this.dto.taxPayerId ?? '';
    }

    public get amounts():TaxAmountDescription[] {
        let amounts:TaxAmountDescription[] = [];
        switch(this.dto.achPaymentTypeId) {
            case 2:
                if(!this.dto.achStateTaxPaymentAmounts) break;
                amounts = (this.dto.achStateTaxPaymentAmounts as AchStateTaxPaymentAmountDto[]).map(amount => <TaxAmountDescription>{
                    amount: amount.amount,
                    description: (amount.achStateAmountType && amount.achStateAmountType.description) ? amount.achStateAmountType.description : undefined,
                });
                break;
            case 3:
                if(!this.dto.achFederalTaxPaymentAmounts) break;
                amounts = (this.dto.achStateTaxPaymentAmounts as AchFederalTaxPaymentAmountDto[]).map(amount => <TaxAmountDescription>{
                    amount: amount.amount,
                    description: (amount.achFederalAmountType && amount.achFederalAmountType.description) ? amount.achFederalAmountType.description : undefined,
                });
                break;
        }
        return amounts;
    }

    public get recipientName() {
        return this.dto.recipientName ?? '';
    }

    public get recipientId() {
        return this.dto.recipientIdNumber ?? '';
    }

    public get recipientRoutingNumber() {
        return this.dto.recipientRoutingNumber ?? '';
    }

    public get recipientAccountNumber() {
        return this.dto.recipientAccountNumber ?? '';
    }

    public get recipientAccountType() {
        return this.dto.recipientAccountType ? this.dto.recipientAccountType.name : '';
    }
}
