import { AchPaymentModelDto, AchRecipientModelDto } from '@treasury/api/bo';
import { AchPayment, AchRecipient } from '@treasury/domain/backoffice/types/ach';
import { Record as FdlRecord, Recordset } from '@treasury/FDL';
import '@treasury/omega/components/omega-field';
import '@treasury/omega/components/omega-filter-bar';
import '@treasury/omega/components/omega-table';
import { css, html, LitElement, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import {
    AchRecipientFilters,
    getAchRecipientColumns,
} from '../../data/ach-payment-field-definitions';
import '../ach-recipient-addenda-dialog';
import { AchRecipientAddendaDialog } from '../ach-recipient-addenda-dialog';

@customElement('ach-payment-detail')
export class AchPaymentDetail extends LitElement {
    @property()
    private paymentRecord!: FdlRecord<AchPayment<AchPaymentModelDto>>;

    @property()
    private recipientRecordset!: Recordset<AchRecipient<AchRecipientModelDto>>;

    @state()
    private selectedRecipientRecord?: FdlRecord<AchRecipient<AchRecipientModelDto>>;

    private readonly recipientLabels = {
        singular: 'recipient',
        plural: 'recipients',
    };

    openAddendaDialog(recipientRecord: FdlRecord<AchRecipient<AchRecipientModelDto>>) {
        this.selectedRecipientRecord = recipientRecord;
        AchRecipientAddendaDialog.open(this);
    }

    renderPaymentDetail() {
        if (!this.paymentRecord) return nothing;
        return html` <h3 class="payment-name">
                ${this.paymentRecord.values.name}
                <small class="recipient-count"
                    >(${this.recipientRecordset.totalCount}
                    Recipient${this.recipientRecordset.totalCount > 1 ? 's' : ''})</small
                >
            </h3>
            <div class="details-body omega-flex-form">
                <div class="form-column col-1">
                    <omega-field field="status" .record=${this.paymentRecord}></omega-field>
                    <omega-field field="companyName" .record=${this.paymentRecord}></omega-field>
                    <omega-field field="companyId" .record=${this.paymentRecord}></omega-field>
                    <omega-field
                        field="entryDescription"
                        .record=${this.paymentRecord}
                    ></omega-field>
                    <omega-field
                        field="discretionaryData"
                        .record=${this.paymentRecord}
                    ></omega-field>
                </div>

                <div class="form-column col-2">
                    <omega-field field="secCode" .record=${this.paymentRecord}></omega-field>
                    <omega-field field="debitAmount" .record=${this.paymentRecord}></omega-field>
                    <omega-field field="creditAmount" .record=${this.paymentRecord}></omega-field>
                    <omega-field field="effectiveDate" .record=${this.paymentRecord}></omega-field>
                    <omega-field
                        field="frequencySummary"
                        .record=${this.paymentRecord}
                    ></omega-field>
                    <omega-field field="offsetAccount" .record=${this.paymentRecord}></omega-field>
                </div>
                <div class="form-column col-3">
                    <omega-field no-align field="audit" .record=${this.paymentRecord}></omega-field>
                </div>
            </div>`;
    }

    renderRecipientsTable() {
        if (!this.recipientRecordset) return nothing;
        return html`<div class="payment-recipients">
            <omega-filter-bar
                .itemLabel=${this.recipientLabels}
                .recordset=${this.recipientRecordset}
                .filters=${AchRecipientFilters}
            >
            </omega-filter-bar>
            <omega-table
                .itemLabel=${this.recipientLabels}
                .recordset=${this.recipientRecordset}
                .columnDefinitions=${getAchRecipientColumns(this)}
                @action=${(e: CustomEvent) => {
                    if (e.detail.action === 'openAddendaDialog')
                        this.openAddendaDialog(e.detail.record);
                }}
            ></omega-table>
        </div>`;
    }

    renderRecipientAddendaDialog() {
        return html`<ach-recipient-addenda-dialog
            .record=${this.selectedRecipientRecord}
        ></ach-recipient-addenda-dialog>`;
    }

    render() {
        return [
            this.renderPaymentDetail(),
            this.renderRecipientsTable(),
            this.renderRecipientAddendaDialog(),
        ];
    }

    static get styles() {
        return [
            css`
                :host omega-field {
                    --omega-field-control-margin-bottom: 4px;
                }
                .payment-name {
                    margin: 10px 0 0 20px;
                    color: #505050;
                }
                .payment-recipients {
                    margin: 15px;
                    padding-bottom: 15px;
                    border-top: 1px solid #ccc;
                }
                .payment-recipients omega-table {
                    border: 1px solid #ccc;
                }
                .details-body {
                    --omega-label-font-weight: 700;
                    margin-left: 14px;
                }
                .recipient-count {
                    font-weight: normal;
                }
                .omega-flex-form {
                    display: flex;
                }
                .form-column {
                    flex: 1 1 0;
                    margin: 10px;
                }
                .col-1 {
                    --omega-field-label-max-width: 170px;
                }
                .col-2 {
                    --omega-field-label-max-width: 150px;
                }
                .col-3 {
                    --omega-field-label-max-width: 60px;
                }
                .form-column:not(:last-of-type) {
                    padding-right: 10px;
                    border-right: 1px solid #d3d6d9;
                }
                .omega-flex-form omega-field {
                    --omega-field-control-width: 120px;
                    margin: 5px 10px;
                }
            `,
        ];
    }
}
