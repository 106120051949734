angular
    .module('backOffice')
    .controller('messageCenterSearchModalController', messageCenterSearchModalController);

messageCenterSearchModalController.$inject = [
    '$scope',
    '$modalInstance',
    'companiesService',
    'usersService',
    'cachedSearch',
];

function messageCenterSearchModalController(
    $scope,
    $modalInstance,
    companiesService,
    usersService,
    cachedSearch
) {
    $scope.getCompanyUsers = getCompanyUsers;
    $scope.searchTerms = {};
    $scope.reset = reset;
    $scope.statuses = ['New', 'Open', 'Resolved'];

    init();

    function init() {
        if (cachedSearch.lists) {
            $scope.companies = cachedSearch.lists.companies;
            $scope.fromUsers = cachedSearch.lists.fromUsers;
            $scope.fiUsers = cachedSearch.lists.fiUsers;
        } else {
            getCompanies();
            getFiUsers();
        }
        if (cachedSearch.terms) {
            $scope.searchTerms = cachedSearch.terms;
        } else {
            reset();
        }
    }

    function reset() {
        $scope.searchTerms = {
            company: null,
            status: null,
            from: null,
            assignedTo: null,
            messageId: '',
            dateFrom: '',
            dateTo: '',
            type: 'all',
        };
    }

    function getCompanies() {
        companiesService.getCompaniesByFI().then(response => {
            $scope.companies = response;
        });
    }

    function getCompanyUsers() {
        if ($scope.searchTerms.company) {
            companiesService.getUsers($scope.searchTerms.company.id, true).then(response => {
                $scope.fromUsers = response;
            });
        } else {
            $scope.searchTerms.from = null;
            $scope.fromUsers = [];
        }
    }

    function getFiUsers() {
        usersService.getUsers().then(response => {
            $scope.fiUsers = response;
        });
    }

    // this will dismiss the window.
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    $scope.search = function () {
        $scope.searchTerms.companyId = $scope.searchTerms.company
            ? $scope.searchTerms.company.id
            : null;
        $scope.searchTerms.fromUserId = $scope.searchTerms.from ? $scope.searchTerms.from.id : null;
        $scope.searchTerms.assignedToUserId = $scope.searchTerms.assignedTo
            ? $scope.searchTerms.assignedTo.id
            : null;

        switch ($scope.searchTerms.status) {
            case 'New':
                $scope.searchTerms.statusId = 1;
                break;
            case 'Open':
                $scope.searchTerms.statusId = 2;
                break;
            case 'Resolved':
                $scope.searchTerms.statusId = 3;
                break;
            default:
                $scope.searchTerms.statusId = 0;
                break;
        }

        switch ($scope.searchTerms.type) {
            case 'all':
                $scope.searchTerms.messageTypeId = 1;
                break;
            case 'inbox':
                $scope.searchTerms.messageTypeId = 2;
                break;
            case 'sent':
                $scope.searchTerms.messageTypeId = 3;
                break;
            case 'archived':
                $scope.searchTerms.messageTypeId = 4;
                break;
            default:
                $scope.searchTerms.messageTypeId = 0;
                break;
        }

        $modalInstance.close({
            terms: $scope.searchTerms,
            lists: {
                companies: $scope.companies,
                fromUsers: $scope.fromUsers,
                fiUsers: $scope.fiUsers,
            },
        });
    };
}
