angular
    .module('backOffice')
    .controller('ReceivablesUserSettingsController', receivablesUserSettingsController);

receivablesUserSettingsController.$inject = [
    '$scope',
    '$window',
    'toaster',
    'companyUsersService',
    'regexConstants',
    'remoteDepositTypes',
];

function receivablesUserSettingsController(
    $scope,
    $window,
    toaster,
    companyUsersService,
    regexConstants,
    remoteDepositTypes
) {
    $scope.userReceivablesSettings = {};
    $scope.userReceivablesSettingsPristine = null;
    $scope.getRdcSsoClaimIndex = getRdcSsoClaimIndex;
    $scope.alphaNumericRegex = regexConstants.AlphaNumericRegex;

    $scope.onChangeUserSsoAccess = onChangeUserSsoAccess;
    $scope.userGrantedAccess = userGrantedAccess;
    $scope.setForm = setForm;
    $scope.reset = reset;
    $scope.save = save;
    $scope.getUserIdLabel = getUserIdLabel;
    $scope.shouldCollectAccessKey = shouldCollectAccessKey;
    $scope.getUserIdPattern = getUserIdPattern;

    function init() {
        loadUserReceivablesSettings();
    }

    function getUserIdPattern() {
        if (
            $scope.userReceivablesSettings.remoteDepositProductTypeId === remoteDepositTypes.WAUSAU
        ) {
            return regexConstants.AlphaNumericRegex;
        }

        return null;
    }

    function onChangeUserSsoAccess() {
        const hasAccess =
            $scope.userReceivablesSettings.userProductClaims[getRdcSsoClaimIndex()]
                .hasPendingUserClaim;
        if (!hasAccess) {
            // reset Login Id to original state as changes to login id are only allowed while access is granted
            $scope.userReceivablesSettings.pendingSsoLoginId = $window.angular.copy(
                $scope.userReceivablesSettingsPristine.ssoLoginId
            );
            $scope.userReceivablesSettings.pendingSsoAccessKey = $window.angular.copy(
                $scope.userReceivablesSettingsPristine.ssoAccessKey
            );
        }
    }

    function userGrantedAccess() {
        return (
            $scope.userReceivablesSettings.userProductClaims &&
            $scope.userReceivablesSettings.userProductClaims[getRdcSsoClaimIndex()]
                .hasPendingUserClaim
        );
    }

    function setForm(form) {
        $scope.form = form;
    }

    function save() {
        companyUsersService
            .updateUserReceivablesSettings(
                $scope.companyId,
                $scope.id,
                $scope.userReceivablesSettings
            )
            .then(response => {
                $scope.userReceivablesSettingsPristine = angular.copy(
                    $scope.userReceivablesSettings
                );
                $scope.$parent.notifySave();
                $scope.form.$setPristine();
                toaster.save('Receivables Settings');
            });
    }

    function reset() {
        $scope.userReceivablesSettings = angular.copy($scope.userReceivablesSettingsPristine);
        $scope.form.$setPristine();
    }

    function getUserIdLabel() {
        if (
            $scope.userReceivablesSettings.remoteDepositProductTypeId === remoteDepositTypes.WAUSAU
        ) {
            return 'User ID';
        }

        return 'Username';
    }

    function shouldCollectAccessKey() {
        return (
            $scope.userReceivablesSettings.remoteDepositProductTypeId === remoteDepositTypes.WAUSAU
        );
    }

    // Private
    function loadUserReceivablesSettings() {
        companyUsersService
            .getUserReceivablesSettings($scope.companyId, $scope.id)
            .then(response => {
                $scope.userReceivablesSettings = response;
                $scope.userReceivablesSettingsPristine = angular.copy(
                    $scope.userReceivablesSettings
                );
            });
    }

    function getRdcSsoClaimIndex() {
        if (
            $scope.userReceivablesSettings &&
            $scope.userReceivablesSettings.userProductClaims &&
            $scope.userReceivablesSettings.userProductClaims.length > 0
        ) {
            for (
                let index = 0;
                index < $scope.userReceivablesSettings.userProductClaims.length;
                index++
            ) {
                if ($scope.userReceivablesSettings.userProductClaims[index].claimId === 135) {
                    return index;
                }
            }
        }
    }

    init();
}
