angular
    .module('backOffice')
    .controller('aaConfigSaveCancelController', aaConfigSaveCancelController);

aaConfigSaveCancelController.$inject = ['$scope', '$modalInstance'];

function aaConfigSaveCancelController($scope, $modalInstance) {
    $scope.ok = function () {
        $modalInstance.close('ok');
    };
}
