import InstitutionPositivePayRequests from '../../requests/institution-configuration/institution-positive-pay-requests.js';

const optionsMap = {
    WorkCheckExceptions: 'PositivePay.WorkCheckExceptions',
    AllowIssuedItemActivityReview: 'PositivePay.AllowIssuedItemActivityReview',
    AllowCorrectionRequest: 'PositivePay.CheckException.AllowCorrectionRequest',
    AllowReturnReason: 'PositivePay.CheckException.AllowReturnReason',
    ShowAchExceptions: 'PositivePay.ShowAchExceptions',
    WorkAchExceptions: 'PositivePay.WorkAchExceptions',
    AllowManageAchFilters: 'PositivePay.ACHFilterManagement.AllowManageAchFilters',
    AchFilterApprovers: 'PositivePay.ACHFilterManagement.ApproversRequired',
};
export default class InstitutionPositivePayServices {
    static async saveInstitutionConfiguration(
        record,
        hasReturnReasonsFeatureFlag,
        hasAchFilterManagementFeatureFlag
    ) {
        delete record.values.ShowAndWorkAchException;
        if (!hasReturnReasonsFeatureFlag) {
            delete record.values.AllowReturnReason;
            delete record.values.AllowCorrectionRequest;
        }
        if (!hasAchFilterManagementFeatureFlag) {
            delete record.values.AllowManageAchFilters;
            delete record.values.AchFilterApprovers;
        }
        const options = Object.entries(record.values).map(([Name, Value]) => {
            const optionName = optionsMap[Name];
            let optionValue = Value;
            if (typeof Value === 'boolean') {
                optionValue = Value ? '1' : '0';
            }
            return { Name: optionName, Value: optionValue };
        });
        return InstitutionPositivePayRequests.saveConfiguration(options);
    }

    static async saveInstitutionReturnReasons(returnReasons) {
        return InstitutionPositivePayRequests.saveCheckExceptionReturnReasons(
            returnReasons.filter(reason => reason.description.trim())
        );
    }
}
