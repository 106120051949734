angular
    .module('backOffice')
    .controller('SsoPosPayUserSettingsController', ssoPosPayUserSettingsController);

ssoPosPayUserSettingsController.$inject = [
    '$scope',
    '$window',
    'toaster',
    'companyUsersService',
    'regexConstants',
];

function ssoPosPayUserSettingsController(
    $scope,
    $window,
    toaster,
    companyUsersService,
    regexConstants
) {
    $scope.userSsoSettings = null;
    $scope.userSsoSettingsPristine = null;
    $scope.getCentrixClaimIndex = getCentrixClaimIndex;
    $scope.alphaNumericRegex = regexConstants.AlphaNumericRegex;

    $scope.onChangeUserSsoAccess = onChangeUserSsoAccess;
    $scope.setForm = setForm;
    $scope.reset = reset;
    $scope.save = save;

    function init() {
        loadUserSsoSettings();
    }

    function onChangeUserSsoAccess() {
        const hasAccess =
            $scope.userSsoSettings.userProductClaims[getCentrixClaimIndex()].hasPendingUserClaim;
        if (!hasAccess) {
            // reset Sso Login Id to original state as changes to login id are only allowed while access is granted
            $scope.userSsoSettings.pendingSsoLoginId = $window.angular.copy(
                $scope.userSsoSettingsPristine.ssoLoginId
            );
        }
    }

    function setForm(form) {
        $scope.form = form;
    }

    function save() {
        companyUsersService
            .updateUserSsoPosPaySettings($scope.companyId, $scope.id, $scope.userSsoSettings)
            .then(response => {
                $scope.userSsoSettingsPristine = angular.copy($scope.userSsoSettings);
                $scope.$parent.notifySave();
                $scope.form.$setPristine();
                toaster.save('Positive Pay Settings');
            });
    }

    function reset() {
        $scope.userSsoSettings = angular.copy($scope.userSsoSettingsPristine);
        $scope.form.$setPristine();
    }

    // Private
    function loadUserSsoSettings() {
        companyUsersService.getUserSsoPosPaySettings($scope.companyId, $scope.id).then(response => {
            $scope.userSsoSettings = response;
            $scope.userSsoSettingsPristine = angular.copy($scope.userSsoSettings);
        });
    }

    function getCentrixClaimIndex() {
        if (
            $scope.userSsoSettings &&
            $scope.userSsoSettings.userProductClaims &&
            $scope.userSsoSettings.userProductClaims.length > 0
        ) {
            for (let index = 0; index < $scope.userSsoSettings.userProductClaims.length; index++) {
                if ($scope.userSsoSettings.userProductClaims[index].claimId === 134) {
                    return index;
                }
            }
        }
    }

    init();
}
