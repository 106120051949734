angular
    .module('backOffice')
    .controller('CreateSecurityQuestionsController', createSecurityQuestionsController);

createSecurityQuestionsController.$inject = [
    '$scope',
    '$state',
    'pagePushService',
    'accountService',
    'loginStateService',
];

function createSecurityQuestionsController(
    $scope,
    $state,
    pagePushService,
    accountService,
    loginStateService
) {
    $scope.confirmSecurityQuestionsState = false;

    $scope.user = {
        challenges: [],
    };

    $scope.getQuestionValue = function (securityQuestionId) {
        let result = '';

        $scope.user.availableQuestions.forEach(securityQuestion => {
            if (securityQuestion.id === securityQuestionId) {
                result = securityQuestion.text;
            }
        });
        return result;
    };

    $scope.confirmSecurityQuestions = function () {
        $scope.confirmSecurityQuestionsState = true;
        setLabels();
    };

    $scope.editSecurityQuestions = function () {
        $scope.confirmSecurityQuestionsState = false;
        setLabels();
    };

    $scope.message = '';

    $scope.reset = function () {
        init();
        $scope.form.$setPristine();
    };

    $scope.resetAnswer = function (question) {
        if (question.id === null) {
            question.answer = null;
        }
    };

    function setLabels() {
        if ($scope.confirmSecurityQuestionsState === false) {
            $scope.headerText = 'Security Questions';
        } else {
            $scope.headerText = 'Confirm Security Questions';
        }
    }

    $scope.processSecurityQuestions = function () {
        accountService.saveSecurityQuestions($scope.user.challenges).then(response => {
            if (response !== false) {
                if (loginStateService.nextState()) {
                } else {
                    $scope.message = 'Unable to proceed. Please contact your administrator.';
                }
            }
        });
    };

    $scope.initialize = function (form) {
        $scope.form = form;
    };

    $scope.areSecurityQuestionsAnswered = function () {
        let result = true;
        if ($scope.user !== null) {
            $scope.user.challenges.forEach(challenge => {
                if (challenge.id === null) result = false;
            });
        }
        return result;
    };

    $scope.determineAvailableQuestions = function (availableQuestions, questionIndex) {
        let otherQuestions = [];
        questionIndex *= 10;
        otherQuestions = availableQuestions.slice(questionIndex, questionIndex + 10);
        return otherQuestions;
    };

    function init() {
        if (!loginStateService.validateCurrentState()) {
            return;
        }

        $scope.user = pagePushService.getUser();
        if ($scope.user === null || $scope.user === undefined) {
            $state.go('login');
            return;
        }
        $scope.securityQuestions = shuffleArray($scope.user.credential.data);
        function shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                const temp = array[i];
                array[i] = array[j];
                array[j] = temp;
            }
            return array;
        }

        $scope.user.availableQuestions = angular.copy($scope.securityQuestions);

        if ($scope.user.availableQuestions[0].id !== -1) {
            $scope.user.challenges = [];
            for (let step = 1; step <= $scope.user.credential.numberOfSecurityQuestions; step++) {
                $scope.user.challenges.push({ id: null, answer: null });
            }
        } else {
            $scope.user.challenges.forEach(challenge => {
                challenge.id = null;
                challenge.answer = null;
            });
        }

        setLabels();
    }

    init();
}
