/* eslint-disable sonarjs/cognitive-complexity */
import { TmHttpClient } from '@treasury/core/http';
import { CheckExceptionsRequests } from '@treasury/domain/backoffice/requests/reports/check-exception-requests.js';
import camelToTitleCase from '@treasury/domain/utilities/camel-to-title-case';
import kebabToCamelCase from '@treasury/domain/utilities/kebab-to-camel-case';
import titleToCamelCase from '@treasury/domain/utilities/title-to-camel-case';
import '@treasury/omega/components/omega-button.js';
import { CheckImageColumn } from '@treasury/omega/components/table';
import {
    attachmentlink,
    audithistory,
    auditinfo,
    changedValueType,
    checkImageLink as checkimagelink,
    comment,
    currency,
    currencyNoSymbol,
    decimal,
    decisionHistory as decisionhistory,
    hideIfEmptyDate,
    historylink,
    int,
    multiSelect,
    multiSelectAll,
    radio,
    select,
    selectAmount,
    selectDate,
    selectDateAny,
    string,
    text,
    total
} from '@treasury/policy/backoffice-report';
import { count, date, dateTime } from '@treasury/policy/primitives';
import { html } from 'lit';

const DATE_PERIODS = {
    Today: 'today',
    SpecificDate: 'specific',
    DateRange: 'range',
    'Week-To-Date': 'week-to-date',
    'Month-To-Date': 'month-to-date',
    'Year-To-Date': 'year-to-date',
};

const DATE_TYPES = ['datePeriod',
'datePeriodDate',
'datePeriodEnd',
'datePeriodStart',
'issuedDate',
'createdDate',
'originalEffectiveDate',
'effectiveDate',
'receivedDate',];

const reportFilterFieldTypes = {
    string,
    int,
    decimal,
    currency,
    currencyNoSymbol,
    date,
    dateTime,
    audithistory,
    auditinfo,
    historylink,
    hideIfEmptyDate,
    changedValueType,
    count,
    total,
    multiSelect,
    multiSelectAll,
    select,
    radio,
    selectAmount,
    selectDateAny,
    selectDate,
    text,
    decisionhistory,
    comment,
    checkimagelink,
    attachmentlink,
};
const reportFieldTypes = reportFilterFieldTypes;

export default class ReportFactory2 {
    constructor(metaData, columns, filters, custom, context) {
        this.metaData = metaData;
        this.columnData = columns;
        this.filterData = filters;
        this.customFilterData = custom;
        this.context = context;
    }

    get metaData() {
        return this._metaData;
    }

    set metaData(value) {
        this._metaData = value;
    }

    get filterData() {
        return this._filterData;
    }

    set filterData(value) {
        this._filterData = value;
    }

    get autostart() {
        return this.filterData?.runDefaultSearch || this.metaData?.isCustomReport;
    }

    get hasCascadingFilter() {
        return this.filterData?.filters.some(filter => filter.triggerCascadeFilter);
    }

    get cascadingFilterField() {
        const cascadingFilter = this.filterData?.filters.find(
            filter => filter.triggerCascadeFilter
        );
        return cascadingFilter?.model;
    }

    get reportColumns() {
        return this.buildColumns(this.columnData);
    }

    get reportFilters() {
        return this.buildFilters(this.filterData, this.customFilterData);
    }

    get reportFields() {
        return this.buildFields(this.columnData);
    }

    get reportDetailFunction() {
        return this.buildDetailFunction(this.columnData);
    }

    getColumnField(column) {
        return column.columnName.charAt(0).toLowerCase() + column.columnName.slice(1);
    }

    getDetailField(detailField) {
        return detailField.fieldName.charAt(0).toLowerCase() + detailField.fieldName.slice(1);
    }

    isCommandColumn(column) {
        // this was a poor extension of the model that forces us to check for transaction Id's that
        // hopefully always relate to an action triggered by an id on the record
        const commandDisplayTypes = ['historylink', 'attachmentlink', 'comment'];
        if (commandDisplayTypes.includes(column.displayType.toLowerCase())) return true;
        if (column.displayType.toLowerCase() === 'checkimagelink') return false;
        return column.columnName.toLowerCase() === 'transactionid' && column.detailsAccessible;
    }

    isTooltipColumn(column) {
        if (column.displayType === 'auditinfo') return true;
        if (column.displayType.toLowerCase() === 'checkimagelink') return false;
        // The transaction id check is the result of a bad model to determine
        // when to render an icon / tooltip column
        // Ideally we'll work with services to improve the model and remove this branch
        return column.detailsAccessible && column.columnName.toLowerCase() !== 'transactionid';
    }

    isCheckImageColumn(column) {
        return column.displayType.toLowerCase() === 'checkimagelink';
    }

    getColumnType(column) {
        if (this.isTooltipColumn(column)) {
            return 'tooltip';
        }
        if (this.isCommandColumn(column)) {
            return 'command';
        }
        if (this.isCheckImageColumn(column)) {
            return CheckImageColumn;
        }
        return undefined;
    }

    getColumnAction(column) {
        if (column.displayType.toLowerCase() === 'historylink') {
            return 'showWireHistory';
        }
        if (column.displayType.toLowerCase() === 'attachmentlink') {
            return 'downloadAttachment';
        }
        if (column.displayType.toLowerCase() === 'comment') {
            return 'showComment';
        }
        if (this.isCommandColumn(column) && column.columnName.toLowerCase() === 'transactionid') {
            return 'routeToWireDetail';
        }
        return undefined;
    }

    getColumnLabel(column) {
        return column.displayName ? column.displayName : column.description;
    }

    isColumnWithSummary(column) {
        return (
            column.displayType.toLowerCase() === 'currency' ||
            column.displayType.toLowerCase() === 'decimal'
        );
    }

    buildColumns(cols) {
        const visibleColumns = cols;
        return visibleColumns
            .filter(column => this.getColumnFieldType(column))
            .map(column => ({
                field: this.getColumnField(column),
                label: this.getColumnLabel(column),
                sortable: column.isSortable,
                order: column.displayOrder,
                summary: this.isColumnWithSummary(column),
                name: column.columnName,
                type: this.getColumnType(column),
                action: this.getColumnAction(column),
                direction: this.getColumnType(column) === 'tooltip' ? 'left' : undefined,
                fetchCheckImages: CheckExceptionsRequests.fetchCheckImages,
            }))
            .sort((a, b) => a.displayOrder - b.displayOrder);
    }

    buildDetailField(fieldName, record) {
        return html`<omega-field
            field=${titleToCamelCase(fieldName)}
            .record=${record}
        ></omega-field>`;
    }

    buildDetailFunction(cols) {
        const filterResults = cols.filter(
            column => column.detailColumnConfiguration.detailFields.length > 0
        );
        const detailColumn = filterResults[0];
        if (detailColumn) {
            return (record, close) => {
                const fieldDetails = detailColumn.detailColumnConfiguration.detailFields
                    .sort((fieldA, fieldB) => fieldA.displayOrder - fieldB.displayOrder)
                    .map(field => this.buildDetailField(field.fieldName, record));
                const maxHeight = Math.ceil(fieldDetails.length / 5) * 60;
                const parser = new DOMParser();
                const detailHeader = parser.parseFromString(
                    detailColumn.detailColumnConfiguration.detailHeader,
                    'text/html'
                );
                return html`<style>
                        .omega-flex-form {
                            display: flex;
                            flex-wrap: wrap;
                            flex-direction: column;
                            max-height: ${maxHeight}px;
                            --omega-button-min-width: 30px;
                        }

                        .omega-flex-form > * {
                            flex: 1 1 40px;
                            max-width: 300px;
                        }

                        omega-field label.label {
                            color: #aaa;
                        }

                        omega-download-bar {
                            border-bottom: 1px solid #dde7ef;
                        }

                        omega-button {
                            --omega-button-min-width: 30px;
                        }

                        .title {
                            padding-top: 0px;
                            margin-top: 0px;
                            margin-right: 10px;
                            font-weight: 600;
                            font-size: 14px;
                            color: #333333;
                        }

                        .form-column {
                            flex: 1 1 0;
                            margin: 10px;
                        }

                        .form-column:not(:last-of-type) {
                            padding-right: 10px;
                            border-right: 1px solid #d3d6d9;
                        }
                        .omega-flex-form omega-field {
                            --omega-field-control-width: 120px;
                            margin: 10px;
                        }
                    </style>
                    <omega-download-bar
                        .pageTitle=${detailHeader.body}
                        .actions=${this.buildDetailActions(
                            detailColumn.detailColumnConfiguration.detailActions
                        )}
                        .downloadOptions=${this.buildDetailDownloadOptions(
                            detailColumn.detailColumnConfiguration.detailActions
                        )}
                        @download=${e => {
                            this.context.handleDetailAction(
                                detailColumn.detailColumnConfiguration.detailActions,
                                record,
                                e.detail.downloadType
                            );
                        }}
                        @print=${() =>
                            this.context.handleDetailAction(
                                detailColumn.detailColumnConfiguration.detailActions,
                                record,
                                'Print Detail'
                            )}
                        ><div slot="right">
                            <omega-button
                                type="icon"
                                icon="close"
                                @click=${close}
                            ></omega-button></div
                    ></omega-download-bar>
                    <div class="omega-flex-form">${fieldDetails}</div>`;
            };
        }
        return undefined;
    }

    buildDetailActions(detailColumnConfigurationActions) {
        const actions = [];
        if (
            detailColumnConfigurationActions?.some(
                action => action.icon.toLowerCase() === 'download'
            )
        ) {
            actions.push('download');
        }
        if (
            detailColumnConfigurationActions?.some(action => action.icon.toLowerCase() === 'print')
        ) {
            actions.push('print');
        }
        return actions;
    }

    buildDetailDownloadOptions(detailColumnConfigurationActions) {
        const downloadOptions = detailColumnConfigurationActions?.filter(
            action => action.icon.toLowerCase() === 'download'
        );
        return downloadOptions.map(option => option.message);
    }

    buildFields(cols) {
        this.fields = {};
        cols.forEach(column => {
            const field = this.getColumnField(column);
            if (this.getColumnFieldType(column)) {
                this.fields[field] = this.getColumnFieldType(column)
                    .with.label(column.displayName)
                    .thatIs.sortable(column.isSortable)
                    .thatIs.readOnly();

                if (column.displayType.toLowerCase() === 'comment') {
                    this.fields[field] = this.fields[field].thatHas.compareFunction((a, b) => 0);
                }
            }
            if (column.detailColumnConfiguration?.detailFields) {
                column.detailColumnConfiguration.detailFields.forEach(detailColumn => {
                    const detailField = this.getDetailField(detailColumn);
                    if (this.getColumnFieldType(detailColumn)) {
                        this.fields[detailField] = this.getColumnFieldType(detailColumn)
                            .with.label(detailColumn.label)
                            .thatIs.sortable(detailColumn.isSortable)
                            .thatIs.readOnly();
                    }
                });
            }
        });

        return this.fields;
    }

    buildFilters(filterResponse, customReportFilters) {
        this.customFilterData = customReportFilters;
        if (!this.filterData) return [];
        return this.filterData.filters
            .map(filter => ({
                ...filter,
                field: filter.model,
                fieldType: this.getFilterFieldType(filter)
                    .with.label(filter.label)
                    .thatHas.options(this.getOptions(filter))
                    .thatIs.requiredWhen(record => this.isFilterRequired(filter, record))
                    .and.placeholder(this.buildPlaceholder(filter))
                    .thatIs.segmented()
                    .and.disabledWhen(record => this.disableCascadingFilters(filter, record))
                    .thatHas.tabs(this.buildTabs(filter))
                    .and.hashFunction(item => item.id),
                value: this.getFieldValue(filter),
                inline: this.isRadio(filter),
            }))
            .sort((a, b) => a.order - b.order);
    }

    buildTabs(filter) {
        if (filter.toggleOptions.length === 0) return null;
        return filter.toggleOptions.map(option => ({ label: option.name, id: option.name }));
    }

    buildDownloadOptions(response) {
        // need to keep name and downloadType properties to use in download request
        return response.map(option => option.name);
    }

    buildPlaceholder(filter) {
        if (this.isSelect(filter)) return `Select`;
        return ``;
    }

    // *** TODO: create interpreter to translate proper field type with validation, etc. here ***//
    getColumnFieldType(column) {
        const fieldType = titleToCamelCase(column.displayType);
        return reportFieldTypes[fieldType];
    }

    getFilterFieldType(filter) {
        const filterType = kebabToCamelCase(filter.type);
        return reportFilterFieldTypes[filterType];
    }
    //* ******/

    getFieldValue(filter) {
        if (this.customFilterData && this.customFilterData[filter.model]) {
            return this.mapCustomFilterValuesToFilters(filter);
        }
        if (this.isRadio(filter)) return null;
        if (this.isSelect(filter)) return [];
        if (this.isAmountRange(filter)) {
            return [filter.filterOptions[0].id, '', ''];
        }
        if (this.isDateFilter(filter)) return this.getDateFilterDefaultValue(filter);
        return filter.filterOptions;
    }

    getDateFilterDefaultValue(filter) {
        if (filter.model === 'issuedDate') {
            return 'any-date';
        }
        if (filter.model === 'originalEffectiveDate') {
            return 'any-date';
        }
        if (filter.model === 'effectiveDate') {
            return '';
        }
        return 'today';
    }

    mapCustomFilterValuesToFilters(filter) {
        if (this.isAmountFilter(filter)) {
            return this.mapAmountToFilterField(filter);
        }
        if (this.isDateFilter(filter)) {
            return this.mapDateToFilterField(filter);
        }
        if (this.isMultiSelect(filter)) {
            return this.mapMultiSelectToFilterField(filter);
        }
        if (this.isSelect(filter) || this.isRadio(filter)) {
            return this.mapSelectOrRadioToFilterField(filter);
        }
        return this.customFilterData[filter.model];
    }

    isAmountFilter(filter) {
        return ['amount', 'amountEnd', 'amountSpecific', 'amountStart'].includes(filter.model);
    }

    isDateFilter(filter) {
        return DATE_TYPES.includes(filter.model);
    }

    mapAmountToFilterField(filter) {
        if (['amountEnd', 'amountSpecific', 'amountStart'].includes(filter)) return null;
        if (this.customFilterData.amountSpecific)
            return [this.customFilterData.amount, this.customFilterData.amountSpecific];
        return [
            this.customFilterData.amount,
            this.customFilterData.amountStart,
            this.customFilterData.amountEnd,
        ];
    }

    mapDateToFilterField(filter) {
        const key = DATE_TYPES.find(k => k === filter.model);
        if ([`${key}Date`, `${key}End`, `${key}Start`].includes(filter)) return null;
        if (this.customFilterData[`${key}Date`]) return this.customFilterData[`${key}Date`];
        if (this.customFilterData[`${key}Start`])
            return {
                dates: [this.customFilterData[`${key}Start`], this.customFilterData[`${key}End`]],
                id: 'range',
            };
        return DATE_PERIODS[this.customFilterData[`${key}`]];
    }

    mapSelectOrRadioToFilterField(filter) {
        const customFilterValue = this.customFilterData[filter.model];
        if (!customFilterValue) return null;
        return customFilterValue;
    }

    mapMultiSelectToFilterField(filter) {
        return this.customFilterData[filter.model];
    }

    hasDatePeriodSelection(record) {
        return record.getField('datePeriod');
    }

    isFilterRequired(filter, record) {
        /**
         * outlier check we should roll into the report model.
         * update 'required' property to be 'requiredWhen' and either true, false, or the filter model (e.g. 'achDateField')
         */
        if (filter.model === 'achDateField') return this.hasDatePeriodSelection(record);
        return !!filter.required;
    }

    hideSelectAll(filter) {
        return filter.type === 'multi-select';
    }

    filterHasFetchOptions(filter) {
        return filter.parameters && filter.endpoint;
    }

    getOptions(filter) {
        if (this.isRadio(filter) || this.isAmountRange(filter)) {
            return { data: filter.filterOptions, text: 'value', value: 'id' };
        }
        if (!this.isSelect(filter)) return null;
        const fields = filter.parameters.map(p => p?.localSource);
        return {
            fetch: async record => {
                if (filter.filterOptions.length) return Promise.resolve(filter.filterOptions);
                if (!this.filterHasFetchOptions(filter)) return Promise.resolve([]);
                const parameters = this.buildParameters(filter, record);
                const hasNeededParameters = filter.parameters.length && parameters;

                if (hasNeededParameters || filter.parameters.length === 0) {
                    const http = await TmHttpClient.getInstance();
                    const options = await http.request(filter.endpoint.substring(1), {
                        method: 'POST',
                        body: { ...parameters },
                    });
                    if (Array.isArray(options)) return options;
                    return Object.values(options)[0];
                }
                return Promise.resolve([]);
            },
            text: dto => dto.value ?? dto.name,
            value: dto => dto,
            fields,
            hideSelectAll: this.hideSelectAll(filter),
        };
    }

    hasTruthyValue(record, parameter) {
        const value = record.getField(parameter.localSource);
        if (Array.isArray(value)) return value.length > 0;
        return !!value;
    }

    buildParameters(filter, record) {
        const { parameters } = filter;
        if (!parameters.length) return undefined;
        const hasParameterValues = parameters.every(p => this.hasTruthyValue(record, p));
        if (!hasParameterValues) return undefined;
        const params = {};
        parameters.forEach(p => {
            params[p.name] = record.getField(p.localSource).map(v => v.id);
        });
        return params;
    }

    disableCascadingFilters(filter, record) {
        if (!filter.parameters.length) return false;
        return !filter.parameters.every(p => this.hasTruthyValue(record, p));
    }

    summaryFields(summary) {
        const fields = {};
        // eslint-disable-next-line no-param-reassign
        delete summary.rows;
        Object.keys(summary).forEach(summaryField => {
            fields[summaryField] = reportFieldTypes[this.summaryFieldType(summaryField)].with
                .label(this.summaryFieldLabel(summaryField))
                .thatIs.readOnly();
        });
        return fields;
    }

    summaryFieldType(field) {
        return camelToTitleCase(field).split(' ').pop().toLowerCase();
    }

    summaryFieldLabel(field) {
        const fieldAsArray = camelToTitleCase(field).split(' ');
        return fieldAsArray.join(' ');
    }

    summaryValues(summary) {
        const values = { ...summary };
        delete values.rows;
        return values;
    }

    isMultiSelect(filter) {
        return filter.type === 'multi-select' || filter.type === 'multi-select-all';
    }

    isSelect(filter) {
        return (
            filter.type === 'multi-select' ||
            filter.type === 'select' ||
            filter.type === 'multi-select-all'
        );
    }

    isDatePicker(filter) {
        return filter.type === 'select-date-any' || filter.type === 'select-date';
    }

    isRadio(filter) {
        return filter.type === 'radio';
    }

    isAmountRange(filter) {
        return filter.type === 'select-amount';
    }
}
