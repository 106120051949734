angular
    .module('backOffice')
    .controller('RegisterSecureTokenController', RegisterSecureTokenController);

RegisterSecureTokenController.$inject = [
    '$state',
    'secureTokenService',
    'loginStatus',
    'loginStateService',
];

function RegisterSecureTokenController($state, secureTokenService, loginStatus, loginStateService) {
    const vm = this;
    const maxEntryFailures = 5;
    let entryFailures = 0;
    const messageType = Object.freeze({
        ERROR: 1,
        WARNING: 2,
        SUCCESS: 3,
    });

    vm.reasonableEntryFailuresExceeded = false;
    vm.unregisteredCredential = {
        credentialId: '',
        token: '',
        pin: '',
        reEnterPin: '',
    };
    vm.isRegistrationComplete = false;

    vm.message = null;
    vm.messageType = null;

    vm.registerToken = registerToken;
    vm.returnToLogin = returnToLogin;
    vm.continueToNextState = continueToNextState;

    function registerToken() {
        if (vm.unregisteredCredential.pin !== vm.unregisteredCredential.reEnterPin) {
            displayMessage('Your entries for your PIN do not match. Please try again.');
        } else {
            completeRegistration();
        }
    }

    function continueToNextState() {
        loginStateService.nextState();
    }

    function returnToLogin() {
        $state.go('login');
    }

    function completeRegistration() {
        clearMessage();

        const credential = {
            credentialId: vm.unregisteredCredential.credentialId,
            token: vm.unregisteredCredential.token,
            pin: vm.unregisteredCredential.pin,
        };

        secureTokenService.register(credential).then(response => {
            if (response.status === loginStatus.ALLOW) {
                vm.reasonableEntryFailuresExceeded = false;
                vm.isRegistrationComplete = true;

                displayMessage(
                    'You have successfully registered your Secure Token.',
                    messageType.SUCCESS
                );
            } else {
                entryFailures++;

                if (entryFailures > maxEntryFailures) {
                    vm.reasonableEntryFailuresExceeded = true;
                    displayMessage(
                        'Unable to verify your identity. If you need assistance, contact your administrator.',
                        messageType.WARNING
                    );
                } else {
                    displayMessage('There was an error in your entry. Please try again.');
                }

                vm.unregisteredCredential.token = '';
                vm.unregisteredCredential.pin = '';
                vm.unregisteredCredential.reEnterPin = '';

                vm.registerTokenForm.$setPristine(true);
            }
        });
    }

    function displayMessage(message, type) {
        vm.message = message;

        if (type) {
            vm.messageType = type;
        } else {
            vm.messageType = messageType.ERROR;
        }
    }

    function clearMessage() {
        vm.message = null;
        vm.messageType = null;
    }

    (function () {
        // init
        if (!loginStateService.validateCurrentState()) {
            return;
        }

        secureTokenService.getSecureTokenConfiguration().then(tokenConfig => {
            vm.unregisteredCredential.credentialId = tokenConfig.credentialId;
        });
    })();
}
