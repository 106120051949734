import { LitElement, html, css, nothing } from 'lit';
import '@treasury/omega/components/omega-dialog.js';
import '@treasury/omega/components/omega-table';

class ReportHistoryDialog extends LitElement {
    static get properties() {
        return {
            recordset: Object,
            columns: Array,
            open: Boolean,
            title: String,
        };
    }

    constructor() {
        super();
        this.open = false;
    }

    renderTable() {
        if (!this.recordset) return nothing;
        return html`<omega-table
            slot="content"
            .recordset=${this.recordset}
            .columnDefinitions=${this.columns}
        ></omega-table>`;
    }

    render() {
        return html`<omega-dialog
            .open=${this.open}
            @close=${() => {
                this.open = false;
                this.dispatchEvent(new CustomEvent('close'));
            }}
            .dialogTitle=${this.title}
            >${this.renderTable()}
        </omega-dialog>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
        `;
    }
}

customElements.define('report-history-dialog', ReportHistoryDialog);
