import { AmountRangeFormatters } from '../../../shared/utilities/amount-range-formatters.js';
import { DateFormatters } from '../../../shared/utilities/date-formatters.js';

function formatAmountRange(amount) {
    let startAmount = null;
    let endAmount = null;
    if (amount) {
        const rangeType = AmountRangeFormatters.getRangeType(amount);
        if (rangeType === 'AmountRange') {
            const amountRange = AmountRangeFormatters.getAmountStartAndEnd(amount);
            startAmount = amountRange.start;
            endAmount = amountRange.end;
        }
        if (rangeType === 'SpecificAmount') {
            const specificAmount = AmountRangeFormatters.getSpecificAmount(amount);
            startAmount = specificAmount;
            endAmount = specificAmount;
        }
        if (startAmount == null && endAmount == null) return null;
        return {
            startAmount,
            endAmount,
        };
    }
    return null;
}

function formatDateRange(date) {
    const parsedDate = DateFormatters.parseDate(date);
    if (!parsedDate.value || parsedDate.value?.id === 'no-date-selected') return null;
    if (typeof parsedDate.value === 'object') {
        return {
            startDate: parsedDate.value.start,
            endDate: parsedDate.value.end,
        };
    }
    return {
        startDate: parsedDate.value,
        endDate: parsedDate.value,
    };
}

export default class CheckExceptionMappings {
    static mapParams(params) {
        return {
            accountUniqueIds: params.accountNumber,
            checkNumber: params.checkNumber || '',
            decisionByUserUniqueIds: params.lastDecisionTakenBy,
            decisionStatus:
                params.decisionStatus.includes('Pay') && params.decisionStatus.includes('Return')
                    ? 'both'
                    : params.decisionStatus[0],
            issuedAmount: formatAmountRange(params.issuedAmount),
            issuedDate: formatDateRange(params.issuedDate),
            postedAmount: formatAmountRange(params.postedAmount),
            postedDate: formatDateRange(params.postedDate),
            payee: params.issuedPayee,
            exceptionReasons: params.exceptionReason,
        };
    }
}
