angular.module('backOffice').controller('ReportsController', ReportsController);

ReportsController.$inject = ['$stateParams', 'reportsService', '$q'];

function ReportsController($stateParams, reportsService, $q) {
    // Private variables
    const vm = this;
    let report;
    const promises = [];

    // public variables
    vm.reportType = $stateParams.type;
    vm.reportId = $stateParams.id;
    vm.filterCollapsed = false;
    vm.hasResults = false;
    vm.hasTooManyResults = false;
    vm.sorts = [];
    vm.showNoResultsMessage = false;
    vm.hasNotRun = true;

    vm.pageSize = 25;
    vm.pageNumber = 1;
    vm.pageMaxSize = 500;
    vm.isPaged = false;

    vm.filter = null;
    vm.downloadFilter = {
        reportFilter: vm.filter,
    };

    // public methods
    vm.reportGenerate = reportGenerate;
    vm.onSorted = onSorted;
    vm.onPaged = onPaged;

    function onPaged(pageNumber, pageSize) {
        vm.pageNumber = pageNumber;
        vm.pageSize = pageSize;

        updateReportData();
    }

    function onSorted(sorts) {
        vm.sorts = sorts;

        updateReportData();
    }

    function reportGenerate(filter) {
        vm.hasNotRun = false;
        vm.filter = filter;
        vm.filter.name = vm.reportTitle;
        vm.downloadFilter.reportFilter = filter;
        vm.pageNumber = 1;

        // This ensures that all promises needed for generating the report are
        // completed before querying report data. This is required because
        // the report filter has logic that tells the report controller to
        // generate a report before the report controller is finished loading
        // its initial async calls.
        if (promises.length > 0) {
            $q.all(promises)
                .then(() => {
                    promises.splice(0);
                    updateReportData();
                })
                .catch(() => {
                    promises.splice(0);
                    updateReportData();
                });
        } else {
            updateReportData();
        }
    }

    // private methods

    function updateReportData() {
        vm.filter.pageSize = vm.pageSize;
        vm.filter.pageNumber = vm.pageNumber;
        vm.filter.orderBys = vm.sorts;

        const promise = reportsService.getReportData(vm.filter).then(response => {
            vm.reportData = response.data;
            vm.totalCount = response.totalCount;
            vm.isPaged = response.isPaged;
            vm.pageMaxSize = response.maxPageSize;

            vm.hasTooManyResults = !response.isPaged && response.totalCount > 500;

            if (response.totalCount > 0) {
                vm.hasResults = true;
                vm.filterCollapsed = true;
            } else {
                vm.hasResults = false;
                vm.filterCollapsed = false;
                vm.showNoResultsMessage = true;
            }

            if (response.reportTitle) {
                vm.reportTitle = response.reportTitle;
            }
        });

        promises.push(promise);
    }

    function getColumnModels() {
        const promise = reportsService.getReportColumnModels(vm.reportType).then(response => {
            vm.columnModels = response;

            setDefaultSorts();

            if (vm.sorts.length === 0) {
                angular.copy(vm.defaultSorts, vm.sorts);
            }
        });

        promises.push(promise);
    }

    function setDefaultSorts() {
        if (vm.columnModels && vm.columnModels.length > 0) {
            vm.defaultSorts = [];

            let sortableColumn = vm.columnModels[0]; // default to first column

            // find first sortable column. if there is one there, use that as the default
            let found = false;
            for (let i = 0; i < vm.columnModels.length && !found; i++) {
                if (vm.columnModels[i].isSortable) {
                    found = true;
                    sortableColumn = vm.columnModels[i];
                }
            }

            const sortProperty = {
                name: sortableColumn.columnName,
                descending: false,
                sortOrders: [true, null],
            };

            vm.defaultSorts.push(sortProperty);
        }
    }

    function loadReport() {
        if ($stateParams.report) {
            setReport($stateParams.report);
        } else {
            const promise = reportsService.getReport(vm.reportId).then(response => {
                setReport(response);
            });

            promises.push(promise);
        }

        reportsService.getDownloadPageByReportType(vm.reportType).then(downloadPage => {
            vm.downloadPage = downloadPage;
        });
    }

    function setReport(updatedReport) {
        report = updatedReport;

        // The title is set to something more accurate when the report data is retrieved. We only
        // want to set it to the report title when it is better than nothing. Usually on initial load.
        if (!vm.reportTitle) {
            vm.reportTitle = report.name;
        }
    }

    (function () {
        // init
        const baseViewPath = 'app/reports/views/';

        if (vm.reportType === 'wireRecon' || vm.reportType === 'achRecon') {
            vm.reportViewPath = `${baseViewPath + vm.reportType}.html`;
        } else {
            vm.reportViewPath = `${baseViewPath}genericReport.html`;
        }

        loadReport();

        getColumnModels();
    })();
}
