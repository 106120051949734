// cspell:ignore ratesheet
import FxWireRequest from '@treasury/domain/backoffice/requests/institution-configuration/fx-wire-request';

angular
    .module('backOffice')
    .controller('ConfigureWireTransferCorpayController', configureWireTransferCorpayController);

configureWireTransferCorpayController.$inject = [
    '$scope',
    '$modalInstance',
    'modalService',
    'toaster',
    'canEdit',
];

function configureWireTransferCorpayController(
    $scope,
    $modalInstance,
    modalService,
    toaster,
    canEdit
) {
    $scope.corpayKey = '';
    $scope.corpaySecretKey = {};
    $scope.canEdit = canEdit;
    $scope.setForm = setForm;

    $scope.cancel = cancel;
    $scope.save = save;
    $scope.clearKey = clearKey;
    $scope.readOnlyKey = '';

    $scope.isActive = false;

    function setForm(form) {
        $scope.form = form;
    }

    async function save() {
        const response = await FxWireRequest.saveCorpayProductFeatureConfigurations(
            $scope.corpaySecretKey.sharedSecretKey
        );
        toaster.save('Corpay Shared Secret Key');
        $modalInstance.close();
    }

    function cancel() {
        $modalInstance.dismiss();
    }

    function clearKey() {
        $scope.corpaySecretKey.sharedSecretKey = '';
        $scope.isActive = true;
    }

    async function getCorpaySharedKey() {
        $scope.corpayKey = await FxWireRequest.getCorpayProductFeatureConfigurations()
            .then(response => {
                $scope.corpaySecretKey = response;
                $scope.readOnlyKey = $scope.corpaySecretKey.sharedSecretKey?.length
                    ? '***********'
                    : 'No Shared Key';
                $scope.$digest();
            })
            .catch(() => {
                modalService.showModal(
                    {},
                    {
                        alertType: 'Error',
                        isAlert: true,
                        summaryText:
                            'An error has occurred when attempting to log you into Corpay. Please try again or contact Corpay to access their site directly.',
                    }
                );
            });
    }

    function init() {
        $scope.corpaySecretKey = {
            sharedSecretKey: '',
        };

        getCorpaySharedKey();
    }

    init();
}
