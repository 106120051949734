import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('transactionCodeGroupsService', transactionCodeGroupsService);

transactionCodeGroupsService.$inject = ['$resource', '$http', '$q'];

function transactionCodeGroupsService($resource, $http, $q) {
    const resourceUrl = `${getBackOfficeAPIPath()}transactioncodegroups/`;

    return {
        getTransactionCodeGroups,
        saveTransactionCodeGroup,
        updateTransactionCodeGroup,
        deleteTransactionCode,
    };

    function deleteTransactionCode(id) {
        return $resource(`${resourceUrl}:id`, { id }).delete().$promise;
    }

    function getTransactionCodeGroups() {
        return $resource(resourceUrl).query().$promise;
    }

    function updateTransactionCodeGroup(id, transactionCodeGroupPayload) {
        const resource = $resource(`${resourceUrl}:id`, { id }, { update: { method: 'PUT' } });
        return resource.update(transactionCodeGroupPayload).$promise;
    }

    function saveTransactionCodeGroup(transactionCodeGroupPayload) {
        return $resource(resourceUrl).save(transactionCodeGroupPayload).$promise;
    }
}
