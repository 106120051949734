import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import { TmApiError } from '@treasury/domain/shared';
import { Recordset } from '@treasury/FDL';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-filter-bar.js';
import '@treasury/omega/components/omega-table.js';
import { ActionDetail } from '@treasury/omega/layouts/omega-report/omega-report.types';
import { AlertMixin } from '@treasury/omega/mixins';
import { OmegaAlertConfig } from '@treasury/omega/services/omega-alert';
import { css, html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import CompanySearchClient from '../clients/company-search-client';
import { columns, fields } from '../data/company-search';

const AlertingListeningElement = AlertMixin(ListeningElementMixin(LitElement));

class CompanySearch extends AlertingListeningElement {
    constructor() {
        super();
        this.client = new CompanySearchClient();
        this.recordset = new Recordset(fields, this.client.fetchCompanies);
        this.recordset.setInitialPageSize(25);
        this.actions = {
            routeToCompanyConfiguration: (companyRecord: any) => {
                const { id, cifNumber } = companyRecord.values;
                if (id) {
                    this.navService.navigate('company', { id });
                } else {
                    this.navService.navigate('company', { cifNumber });
                }
            },
        };
    }

    @InjectProperty()
    private declare readonly navService: NavigationService;

    @property({ type: Boolean })
    hidden = false;

    @property({ type: Object })
    private client: any;

    @property({ type: Recordset })
    private recordset: any;

    @property({ type: Array })
    private filters: Array<[]> = [];

    @property({ type: Boolean })
    private loading = false;

    @property({ type: Object })
    alert: OmegaAlertConfig = {
        visible: false,
        message: '',
        code: '',
        time: '',
        title: '',
        type: 'success',
        posture: 'polite',
        actions: html``,
    };

    @property({ type: Boolean })
    private tableLoading = false;

    @property({ type: Object })
    private actions: any;

    async firstUpdated(): Promise<void> {
        this.loading = true;
        this.listenTo(this.recordset, 'loading', ({ detail }: any) => {
            const { loading } = detail;
            this.tableLoading = loading;
        });
        try {
            await this.recordset.requestHardUpdate();
        } catch (e) {
            this.alert = {
                ...this.alert,
                visible: true,
                type: 'error',
                message: e instanceof Error ? e.message : 'An error occurred.',
                code: e instanceof TmApiError ? e.errorCode : '',
            };
        } finally {
            this.loading = false;
        }
    }

    private handleActions({ action, record, rowIndex }: ActionDetail) {
        this.actions[action](record, rowIndex);
    }

    render() {
        if (!this.recordset || this.hidden) return nothing;
        return html` ${this.renderAlert()}
            <omega-filter-bar
                .filters=${[]}
                .recordset=${this.recordset}
                @change=${({ detail }: CustomEvent) => {
                    this.filters = detail;
                }}
            ></omega-filter-bar>
            <omega-table
                .recordset=${this.recordset}
                .columnDefinitions=${columns}
                .loading=${this.tableLoading}
                .filters=${this.filters}
                @action=${(e: CustomEvent<ActionDetail>) => this.handleActions(e.detail)}
            ></omega-table>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                margin: 10px;
            }
        `;
    }
}

customElements.define('company-search', CompanySearch);
