angular
    .module('backOffice')
    .controller('messageCenterAssignModalController', messageCenterAssignModalController);

messageCenterAssignModalController.$inject = [
    '$scope',
    '$modalInstance',
    'message',
    'messagingCenterService',
    'usersService',
    'modalService',
    'uiMessages',
];

function messageCenterAssignModalController(
    $scope,
    $modalInstance,
    message,
    messagingCenterService,
    usersService,
    modalService,
    uiMessages
) {
    $scope.message = message;
    $scope.selectedUser = {
        object: null,
    };

    $scope.cancel = function () {
        if ($scope.form.$dirty) {
            var confirmCancel = modalService.showModal(
                {},
                {
                    closeButtonText: 'Continue Editing',
                    actionButtonText: 'OK',
                    headerText: 'Cancel',
                    bodyText: uiMessages.CancelAssignUser,
                    submit() {
                        confirmCancel.close();
                        $modalInstance.dismiss();
                    },
                }
            );
        } else {
            $modalInstance.dismiss();
        }
    };

    $scope.assign = function () {
        messagingCenterService
            .update({
                messageId: message.id,
                actionTypeId: 3,
                assignedUserId: $scope.selectedUser.object.id,
            })
            .then(() => {
                message.assignedTo = $scope.selectedUser.object.userName;
                $modalInstance.close(message);
            });
    };

    function init() {
        getFiUsers();
    }

    function getFiUsers() {
        usersService.getActiveUsers().then(response => {
            $scope.fiUsers = response;
            $scope.selectedUser.object = $scope.fiUsers.reduce(
                (acc, val) => (val.userName === message.assignedTo ? val : acc),
                null
            );
        });
    }

    $scope.setForm = function (form) {
        $scope.form = form;
    };

    init();
}
