import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('reportsService', reportsService);

reportsService.$inject = ['$resource', '$http'];

function reportsService($resource, $http) {
    const resourceUrl = `${getBackOfficeAPIPath()}reports/`;

    return {
        getReports,
        getReport,
        getReportFilter,
        getReportCustomFilter,
        updateReportCustomFilter,
        getReportData,
        getReportColumnModels,
        favorite,
        deleteCustomReport,
        updateSequence,
        getWireHistory,
        getUserActivity,
        getDownloadPageByReportType,
    };

    function getDownloadPageByReportType(reportType) {
        const url = `${resourceUrl}GetDownloadPageByReportType`;

        return $http
            .get(url, {
                params: {
                    reportType,
                },
            })
            .then(response => response.data);
    }

    function getReports() {
        return $resource(resourceUrl).query().$promise;
    }

    function getReport(reportId) {
        return $resource(`${resourceUrl}report/:id`, { id: reportId }).get().$promise;
    }

    function getReportFilter(type) {
        return $resource(`${resourceUrl}reportFilter`, { type }).get().$promise;
    }

    function getReportCustomFilter(type, id) {
        return $resource(`${resourceUrl}reportCustomFilter`, { type, id }).get().$promise;
    }

    function updateReportCustomFilter(filter, config) {
        return $http.post(`${resourceUrl}reportCustomFilter`, filter, config);
    }

    function getReportData(filter) {
        return $resource(resourceUrl, null, { report: { method: 'POST' } }).report(filter).$promise;
    }

    function getReportColumnModels(type) {
        return $resource(
            `${resourceUrl}reportColumnModels`,
            {},
            {
                getReportColumnModels: { method: 'GET', params: { type }, isArray: true },
            }
        ).getReportColumnModels().$promise;
    }

    function favorite(reportId, isFavorite) {
        return $resource(`${resourceUrl}favorite/:id?value=:value`, {
            id: reportId,
            value: isFavorite,
        }).get().$promise;
    }

    function deleteCustomReport(reportId) {
        return $resource(`${resourceUrl}deletecustomreport/:id`, { id: reportId }).get().$promise;
    }

    function updateSequence(sequence) {
        return $resource(`${resourceUrl}updatesequence`).save(sequence).$promise;
    }

    function getWireHistory(wireId) {
        return $resource(`${resourceUrl}getwirehistory/:id`, { id: wireId }).query().$promise;
    }

    function getUserActivity(filter) {
        return $resource(`${resourceUrl}getUserActivity`, null, {
            report: { method: 'POST' },
        }).report(filter).$promise;
    }
}
