angular.module('backOffice').controller('ConfigureAgileticsWebEscrow', configureAgileticsWebEscrow);

configureAgileticsWebEscrow.$inject = [
    '$scope',
    '$modalInstance',
    'modalService',
    'configurationService',
    'productFeatureId',
    'canEdit',
    'toaster',
];

function configureAgileticsWebEscrow(
    $scope,
    $modalInstance,
    modalService,
    configurationService,
    productFeatureId,
    canEdit,
    toaster
) {
    $scope.productFeatureId = productFeatureId;
    $scope.productFeature = {};
    $scope.canEdit = canEdit;

    $scope.save = save;
    $scope.cancel = cancel;

    function init() {
        $scope.productFeature = {};

        loadProductFeature();
    }

    function save() {
        configurationService.saveAgileticsWebEscrowDetails($scope.productFeature).then(response => {
            toaster.save('Agiletics Web Escrow');
            $modalInstance.close();
        });
    }

    function cancel() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel the changes?',
                submit(result) {
                    cancelModalInstance.dismiss();
                    $modalInstance.dismiss();
                },
            };
            var cancelModalInstance = modalService.showModal({}, modalOptions);
        } else {
            $modalInstance.dismiss();
        }
    }

    function loadProductFeature() {
        configurationService
            .getAgileticsWebEscrowDetails($scope.productFeatureId)
            .then(response => {
                $scope.productFeature = {
                    ...response,
                    productName: response.productName ?? 'Agiletics Web Escrow',
                };
            });
    }

    init();
}
