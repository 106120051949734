import { LitElement, html, css } from 'lit';
import { AchFilterExceptionsReportServices } from '@treasury/domain/backoffice/requests/reports/ach-filter-exceptions.js';
import '@treasury/omega/components/omega-tooltip.js';
import '@treasury/policy/lib/formatters/dateTime.formatter.js';

class ACHFilterDetailRow extends LitElement {
    static get properties() {
        return {
            record: Object,
            closeFunction: Object,
            detailRow: Object,
            auditLog: Object,
        };
    }

    constructor() {
        super();
        this.auditLog = null;
    }

    firstUpdated() {
        this.renderAuditLog();
    }

    formatAccountSettings() {
        return `${this.record.print('accountCredits')}/${this.record.print('accountDebits')}`;
    }

    async renderAuditLog() {
        try {
            const results = await AchFilterExceptionsReportServices.fetchAuditRecords({
                AchFilterUniqueIds: [this.record.getField('achFilterUniqueId')],
            });
            // TODO: The stubbed API results are returning all audit logs for all ach filter ids, not the requested one. So for now I'm just grabbing the first element in the result array. We likely will need to revisit and update this logic once the API is complete on the backend.
            const logs = results[0].auditEvents.map(event => {
                const actionDate = event.actionDate
                    ? new Date(event.actionDate)
                          .toLocaleDateString('en-US', {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                          })
                          .concat(':')
                    : '';
                return html`<li>${actionDate} ${event.actionUsername}: ${event.action}</li>`;
            });
            this.auditLog = html`<ul class="audit-logs">
                ${logs}
            </ul>`;
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        return html`
            <div class="detail-row-container">
                <div class="detail-row-header">
                    <div>
                        <span class="detail-row-title">Filter Exception Detail</span>
                        <label class="detail-row-label">Account</label>
                        <span class="detail-row-value">${this.record.print('accountNumber')}</span>
                        <label class="detail-row-label">Account Filter Settings</label>
                        <span class="detail-row-value">${this.formatAccountSettings()} </span>
                        <omega-tooltip
                            icon="info-circle"
                            message="This is the account’s initial filter settings which is set by the Financial institution and cannot be edited"
                        ></omega-tooltip>
                    </div>
                    <div>
                        <omega-button
                            type="icon"
                            icon="close"
                            @click=${this.closeFunction}
                        ></omega-button>
                    </div>
                </div>
                <div style="display: flex; justify-content: space-between; align-items: center;">
                    <div
                        style="margin: 20px 20px 0px 20px; display: flex; flex-direction: column; justify-content: space-between; align-items: flex-start;"
                    >
                        <span class="detail-row-value">${this.record.print('achCompanyName')}</span>
                        <label class="detail-row-label under">ACH Company Name</label>
                        <span class="detail-row-value">${this.record.print('achCompanyId')}</span>
                        <label class="detail-row-label under">Company ID</label>
                    </div>
                    <div
                        style="margin: 20px 20px 0px 20px; display: flex; flex-direction: column; justify-content: space-between; align-items: flex-start;"
                    >
                        <span class="detail-row-value"
                            >${this.record.print('standardEntryClassCode')}</span
                        >
                        <label class="detail-row-label under">SEC Code</label>
                        <span class="detail-row-value">${this.record.print('toAmount')}</span>
                        <label class="detail-row-label under">Amount</label>
                        <span class="detail-row-value"
                            >${this.record.print('transactionType')}</span
                        >
                        <label class="detail-row-label under">Transaction Type</label>
                    </div>
                    <div
                        style="margin: 20px 20px 0px 20px; display: flex; flex-direction: column; justify-content: space-between; align-items: flex-start;"
                    >
                        <span class="detail-row-value"
                            >${this.record.print('expirationDate') || 'None'}</span
                        >
                        <label class="detail-row-label under">Expiration Date</label>
                        <span class="detail-row-value">${this.record.print('detailStatus')}</span>
                        <label class="detail-row-label under">Status</label>
                    </div>
                    <div
                        style="padding-right: 21px; display: flex; flex-direction: column; justify-content: space-between; align-items: flex-start;"
                    >
                        <label
                            class="detail-row-label"
                            style="margin-left: 0px; margin-bottom: 5px;"
                            >Audit</label
                        >
                        <div
                            style="font-size: 11px; overflow-x: wrap; overflow-y: auto; width: 269px; height: 90px; padding: 5px; border: 1px solid #dfdfdf; background-color: #fcfcfc"
                        >
                            ${this.auditLog}
                        </div>
                    </div>
                </div>
            </div>
        `;
    }

    static get styles() {
        return css`
            div.detail-row-container {
                padding: 0px;
                margin-top: 0px;
            }
            div.detail-row-header {
                height: 30px;
                padding: 10px 17px 20px 17px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #dde7ef;
            }
            ul.audit-logs {
                list-style-type: none;
                padding: 0;
                margin: 0;
            }
            .detail-header {
                height: 30px;
                padding: 10px 17px 20px 17px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #dde7ef;
            }
            .detail-content {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .detail-content-column {
                flex: 1;
                padding: 10px;
                min-height: 150px;
            }
            .detail-content-column:not(:last-child) {
                padding-right: 10px;
                border-right: 1px solid #dde7ef;
            }

            .detail-row-title {
                padding-top: 0px;
                margin-top: 0px;
                margin-right: 10px;
                font-weight: 600;
                font-size: 14px;
                color: #333333;
            }
            .detail-row-label {
                font-size: 13px;
                color: #787878;
                margin-left: 15px;
                margin-right: 5px;
                flex: 0;
                white-space: nowrap;
                min-width: 140px;
            }
            .detail-row-label.align-center {
                display: flex;
                align-items: center;
            }

            .detail-row-value {
                flex: 1;
            }

            .detail {
                text-align: right;
            }

            .under {
                margin-left: 0px;
                padding-bottom: 15px;
            }

            .detail-row-buttons {
                align-self: flex-end;
            }
        `;
    }
}

export default ACHFilterDetailRow;
window.customElements.define('ach-filter-detail-row', ACHFilterDetailRow);
