import { FrequencyModelDto } from '@treasury/api/channel';
import { format } from 'date-fns';

export const formatDay = (day = 0) => {
    switch (day) {
        case 1:
        case 21:
        case 31:
            return `${day}st`;
        case 2:
        case 22:
            return `${day}nd`;
        case 3:
        case 23:
            return `${day}rd`;
        default:
            return `${day}th`;
    }
};

export const getStartAndEnd = (frequency: FrequencyModelDto) => {
    if (frequency.endOn) {
        return `from ${format(new Date(frequency.startOn || ''), 'MM/dd/yyyy')} to ${format(
            new Date(frequency.endOn),
            'MM/dd/yyyy'
        )}`;
    }
    return `starting on ${format(new Date(frequency.startOn || ''), 'MM/dd/yyyy')}`;
};

export const getDaysOfMonth = (frequency: FrequencyModelDto, twoDays = false) => {
    const day1 = frequency.repeatOnDay1;
    const day2 = frequency.repeatOnDay2;

    if (twoDays) {
        if (frequency.repeatOnLastBusinessDay) {
            return `the ${formatDay(day1)} and the last business day`;
        }
        return `the ${formatDay(day1)} and ${formatDay(day2)}`;
    }

    if (frequency.repeatOnLastBusinessDay) {
        return 'the last business day';
    }
    return `the ${formatDay(day1)}`;
};

export const getDayForAnnual = (date: string) => {
    const tempDate = new Date(date);
    return format(tempDate, 'MM/dd');
};

export const getDayForADate = (date: string) => formatDay(Number(format(new Date(date), 'd')));
