angular
    .module('backOffice')
    .controller('ManageAchCompanyStatusController', manageAchCompanyStatusController);

manageAchCompanyStatusController.$inject = [
    '$scope',
    'achService',
    'companyDetails',
    '$modalInstance',
    'toaster',
];

function manageAchCompanyStatusController(
    $scope,
    achService,
    companyDetails,
    $modalInstance,
    toaster
) {
    // selected Company
    $scope.selectedCompanyStatus = companyDetails;

    // this will dismiss the window.
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    $scope.save = function () {
        updateCompanyStatus();
    };

    // used to set the form instance.
    $scope.setForm = function (form) {
        $scope.form = form;
    };

    // we watch for form.$dirty, this is used to alert the end user that they changed something in the form.
    $scope.$watch('form.$dirty', isDirty => {
        $modalInstance.isDirty = isDirty;
    });

    // update company
    function updateCompanyStatus() {
        // change status
        const newStatus = $scope.selectedCompanyStatus.isActive !== true;

        // append comment reason
        const d = new Date();
        if ($scope.selectedCompanyStatus.comments === null) {
            $scope.selectedCompanyStatus.comments = '';
        }

        $scope.selectedCompanyStatus.status = newStatus;

        companiesService.changeCompanyStatus($scope.selectedCompanyStatus).then(response => {
            toaster.save('Company Status');
            $modalInstance.close(response);
        });
    }
}
