angular
    .module('backOffice')
    .controller('MessagingCenterConfigurationController', messagingCenterConfigurationController);

messagingCenterConfigurationController.$inject = [
    '$scope',
    'toaster',
    'messagingCenterService',
    'regexConstants',
];

function messagingCenterConfigurationController(
    $scope,
    toaster,
    messagingCenterService,
    regexConstants
) {
    $scope.archiveOptions = [
        { label: '3 months', value: 2 },
        { label: '6 months', value: 3 },
        { label: '9 months', value: 4 },
        { label: '12 months', value: 5 },
        { label: 'Do not archive', value: 1 },
    ];
    $scope.configuration = { archive: 'DoNotArchive' };
    $scope.form = null;
    $scope.pristine = null;
    $scope.reset = reset;
    $scope.save = save;
    $scope.setForm = setForm;

    $scope.emailPattern = regexConstants.EmailRegex;

    function init() {
        messagingCenterService.get().then(response => {
            $scope.configuration = angular.copy(response);
            $scope.pristine = angular.copy(response);
        });
    }

    init();

    function reset() {
        $scope.configuration = angular.copy($scope.pristine);
        $scope.form.$setPristine();
    }

    function setForm(form) {
        $scope.form = form;
    }

    function save() {
        messagingCenterService.save($scope.configuration).then(response => {
            $scope.pristine = angular.copy(response);
            reset();
            toaster.success('Changes saved successfully.');
        });
    }
}
