/* eslint-disable import/extensions */
import { TmHttpClient } from '@treasury/core/http';

const endpoint = 'AccountRecon/InstitutionProductConfiguration';

export class FiAccountReconConfiguration {
    static async getConfigurationSettings() {
        const http = await TmHttpClient.getInstance();
        return http.request(endpoint, { method: 'GET' });
    }

    static async saveConfigurationSettings(settings) {
        if (!settings) return false;

        const http = await TmHttpClient.getInstance();
        return http.request(endpoint, { method: 'POST', body: settings });
    }
}
