import { Injectable } from '@jack-henry/frontend-utils/di';
import { WirePaymentDetailRequests } from '../../requests/reports/wire-payment-detail-requests';

@Injectable()
export class WirePaymentDetailService {
    // eslint-disable-next-line no-useless-constructor
    constructor(private readonly requests: WirePaymentDetailRequests) {}

    async updateExternallyProcessedFXWire(recordValues: object) {
        return this.requests.updateExternallyProcessedFXWire(recordValues);
    }
}
