angular.module('backOffice').directive('attributeList', () => ({
    restrict: 'A',
    replace: true,
    transclude: true,
    scope: {},
    template: require('./attributeListTemplate.html'),
    link($scope, $el, $attrs) {
        $scope.$watch(
            () => $el[0].childNodes.length,
            (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    let children;
                    let largestWidth;
                    children = $($el).children();
                    largestWidth = 0;

                    // Determine largest width.
                    window.angular.forEach(children, child => {
                        if ($(child.childElementCount)[0] != 1) {
                            const width = $(child).children().first()[0].scrollWidth;
                            largestWidth = Math.max(width, largestWidth);
                        }
                    });

                    // Apply largest width.
                    window.angular.forEach(children, child => {
                        if ($(child.childElementCount)[0] != 1) {
                            $(child).children().first().css('width', `${largestWidth}px`);
                        }
                    });
                }
            }
        );
    },
    controller: attributeListController,
}));

attributeListController.$inject = ['$scope', '$element', '$timeout'];

function attributeListController($scope, $element, $timeout) {
    init();

    function init() {
        $timeout(() => {
            resize();
        });
    }

    function resize() {
        let children;
        let largestWidth;
        children = $($element).children();
        largestWidth = 0;

        // Determine largest width.
        window.angular.forEach(children, child => {
            if ($(child.childElementCount)[0] != 1) {
                const childElement = $(child).children().first()[0];
                const width = childElement === undefined ? 0 : childElement.scrollWidth;
                largestWidth = Math.max(width, largestWidth);
            }
        });

        // Apply largest width.
        window.angular.forEach(children, child => {
            if ($(child.childElementCount)[0] != 1) {
                $(child).children().first().css('width', `${largestWidth}px`);
            }
        });
    }
}
