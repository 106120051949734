angular.module('backOffice').controller('TokenMfaSettingsController', tokenMfaSettingsController);

tokenMfaSettingsController.$inject = ['$scope', 'usersService', '$modal', 'userTokenDetails'];

function tokenMfaSettingsController($scope, usersService, $modal, userTokenDetails) {
    $scope.showUnlockOptions = showUnlockOptions;
    $scope.getUserLoginTokenStatus = getUserLoginTokenStatus;
    $scope.resetUser = resetUser;
    $scope.unlockUser = unlockUser;
    $scope.canTreasuryUnlockUser = canTreasuryUnlockUser;
    $scope.showReset = showReset;
    $scope.close = close;

    let unlocked = false;
    const userLoginTokenStatuses = {
        unregistered: 0,
        registered: 1,
        locked: 2,
    };
    const symantecCredentialLockedStatus = 4;

    function getUserLoginTokenStatus() {
        switch ($scope.userLoginTokenStatus) {
            case userLoginTokenStatuses.unregistered:
                return 'Unregistered';
            case userLoginTokenStatuses.registered:
                return 'Registered';
            case userLoginTokenStatuses.locked:
                return 'Locked';
        }

        return 'Unknown';
    }

    function resetUser() {
        $modal
            .open({
                template: require('../views/resetTokenMfaConfirmation.html'),
                controller: 'ResetTokenMfaConfirmation',
            })
            .result.then(result => {
                if (result === 'reset') {
                    usersService.resetSecureToken(userTokenDetails.userId).then(() => {
                        $scope.userLoginTokenStatus = userLoginTokenStatuses.unregistered;
                        unlocked = true;
                    });
                }
            });
    }

    function unlockUser() {
        $modal
            .open({
                template: require('../views/unlockTokenMfaConfirmation.html'),
                controller: 'UnlockTokenMfaConfirmation',
            })
            .result.then(unlockTokenConfirmationResult => {
                if (unlockTokenConfirmationResult.unlockToken) {
                    usersService
                        .unlockSecureToken(
                            userTokenDetails.userId,
                            unlockTokenConfirmationResult.resetToken
                        )
                        .then(() => {
                            if (unlockTokenConfirmationResult.resetToken) {
                                $scope.userLoginTokenStatus = userLoginTokenStatuses.unregistered;
                            } else {
                                $scope.userLoginTokenStatus = userLoginTokenStatuses.registered;
                            }
                            unlocked = true;
                        });
                }
            });
    }

    function showUnlockOptions() {
        return $scope.userLoginTokenStatus === userLoginTokenStatuses.locked;
    }

    function canTreasuryUnlockUser() {
        return $scope.credentialStatus !== symantecCredentialLockedStatus;
    }

    function showReset() {
        return $scope.userLoginTokenStatus !== userLoginTokenStatuses.unregistered;
    }

    function init() {
        $scope.credentialStatus = userTokenDetails.credentialStatus;
        $scope.tokenVendorUserId = userTokenDetails.tokenVendorUserId;

        if ($scope.credentialStatus === symantecCredentialLockedStatus) {
            $scope.userLoginTokenStatus = userLoginTokenStatuses.locked;
        } else {
            $scope.userLoginTokenStatus = userTokenDetails.userLoginTokenStatus;
        }
    }

    function close() {
        $scope.$close({ unlocked });
    }

    init();
}
