angular.module('backOffice').controller('AccountInfoController', accountInfoController);

accountInfoController.$inject = [
    '$scope',
    'companiesService',
    '$filter',
    '$modal',
    'modalService',
    'toaster',
    'entitlementsService',
    'spinnerService',
    'expirationCache',
];

function accountInfoController(
    $scope,
    companiesService,
    $filter,
    $modal,
    modalService,
    toaster,
    entitlementsService,
    spinnerService,
    expirationCache
) {
    $scope.accountList = null;
    $scope.accountListFiltered = [];
    $scope.accountSearch = null;

    $scope.$watch('accountSearch', filterAccountList);
    $scope.$watch('companyStatus.status', onCompanyStatusChange);

    $scope.loadAccounts = loadAccounts;
    $scope.changeAccountStatus = changeAccountStatus;
    $scope.addAccounts = addAccounts;
    $scope.accountSettings = accountSettings;
    $scope.edit = edit;
    $scope.manageTransfers = manageTransfers;
    $scope.assignProductsAndAccounts = assignProductsAndAccounts;
    $scope.orderByFunction = orderByFunction;
    $scope.transfersEnabled = transfersEnabled;
    $scope.isAddAccountDisabled = isAddAccountDisabled;
    $scope.tableController = {};
    $scope.refresh = refresh;
    $scope.canChangeAccountStatus = canChangeAccountStatus;

    init();

    function init() {
        expirationCache.getCache().destroy();
        // set default sort order
        $scope.orderByField = 'number';
        $scope.reverseSort = false;

        spinnerService.configureGroup(
            'accountInfo',
            [`/companies/${$scope.companyDetails.id}/accounts`],
            []
        );
    }

    function loadAccounts(id) {
        spinnerService.startGroupRequest('accountInfo');
        companiesService
            .getCompanyAccounts(id)
            .then(
                response => {
                    $scope.accountList = response;
                    filterAccountList();
                },
                () => {
                    $scope.accountList = [];
                }
            )
            .finally(() => {
                spinnerService.stopGroupRequest('accountInfo');
            });
    }

    function changeAccountStatus(account) {
        const modalOptions = {
            bodyText: 'Are you sure you want to change the account status?',
            submit(result) {
                // change status
                const newStatus = account.status === 'Inactive' ? 'Active' : 'Inactive';
                companiesService
                    .changeAccountStatus($scope.companyDetails.id, account.id, newStatus)
                    .then(response => {
                        account.status = response.status;
                        updateCachedAccountStatus(account.id, newStatus);
                        toaster.save('Account Status');
                    });
                $modalInstance.close(result);
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    function updateCachedAccountStatus(accountId, newStatus) {
        const cachedAccounts = companiesService.getCachedCompanies($scope.companyDetails.id);
        if (cachedAccounts === undefined) {
            return;
        }

        for (let iAccount = 0; iAccount < cachedAccounts.length; ++iAccount) {
            const account = cachedAccounts[iAccount];
            if (account.id === accountId) {
                account.status = newStatus;
                break;
            }
        }
    }

    function accountSettings() {
        $modal.open({
            template: require('../views/companyAccountSettingsModalTemplate.html'),
            size: 'md',
            controller: 'AccountSettingsController',
            backdrop: 'static',
            resolve: {
                companyId() {
                    return $scope.companyDetails.id;
                },
            },
        });
    }

    function addAccounts() {
        const modalInstance = $modal.open({
            template: require('../views/addAccountsModalView.html'),
            size: 'lg',
            controller: 'AddAccountsController',
            backdrop: 'static',
            resolve: {
                companyId() {
                    return $scope.companyDetails.id;
                },
                companyName() {
                    return $scope.companyDetails.name;
                },
                cifNumber() {
                    return $scope.companyDetails.cifNumber;
                },
            },
        });
        modalInstance.result.then(() => {
            $scope.loadAccounts($scope.companyDetails.id);
            toaster.save('Account');
        });
    }

    function isAddAccountDisabled() {
        return (
            $scope.companyStatus.status === 'Not Onboarded' ||
            !entitlementsService.hasAnyEntitlement('Add Accounts', 'Switch FI')
        );
    }

    function edit(account, canEdit) {
        // stores index to use to update record in collection
        const index = $scope.accountList.indexOf(account);
        const modalInstance = $modal.open({
            template: require('../views/editAccountModalView.html'),
            size: 'lg',
            controller: 'EditAccountController',
            backdrop: 'static',
            resolve: {
                selectedAccount() {
                    return angular.copy(account);
                },
                productList() {
                    return $scope.productList;
                },
                companyId() {
                    return $scope.companyDetails.id;
                },
                canEdit() {
                    return canEdit;
                },
            },
        });
        modalInstance.result.then(updatedAccount => {
            $scope.accountList.splice(index, 1, updatedAccount);

            toaster.save('Account');
        });
    }

    function manageTransfers(account) {
        $modal.open({
            template: require('../views/manageTransfersModalView.html'),
            size: 'md',
            controller: 'ManageTransfersController',
            backdrop: 'static',
            resolve: {
                account() {
                    return account;
                },
            },
        });
    }

    function assignProductsAndAccounts() {
        const modalInstance = $modal.open({
            template: require('../../shared/assignProductsAccounts/views/assignProductsAccountsModalView.html'),
            size: 'lg',
            controller: 'AssignProductsAccountsController',
            backdrop: 'static',
            resolve: {
                entityType() {
                    return 'Products';
                },
                companyId() {
                    return $scope.companyDetails.id;
                },
                cifNumber() {
                    return $scope.companyDetails.cifNumber;
                },
            },
        });
        modalInstance.result.then(() => {
            toaster.save('Assign Products');
        });
    }

    function orderByFunction(account) {
        if ($scope.orderByField == 'number') return parseFloat(account[$scope.orderByField]);
        if ($scope.orderByField == 'coreStatus') return account.account.coreStatus;

        return account[$scope.orderByField];
    }

    //
    // Events

    function refresh() {
        expirationCache.getCache().destroy();
        $scope.accountList = null;
        loadAccounts($scope.companyDetails.id);
    }

    function onCompanyStatusChange(newValue) {
        if (newValue && newValue !== 'Not Onboarded') {
            $scope.loadAccounts($scope.companyDetails.id);
        }
    }

    //
    // Private

    function filterAccountList() {
        $scope.accountListFiltered = $filter('filter')($scope.accountList, $scope.accountSearch);
    }

    function transfersEnabled(account) {
        return account.productPermissions.reduce(
            (acc, val) => val.productName === 'Manage Transfers' || acc,
            false
        );
    }

    function canChangeAccountStatus() {
        return entitlementsService.hasAnyEntitlement('Edit Account', 'Add Accounts');
    }
}
