import FeatureFlagRequests from '../../requests/feature-flags/feature-flag-requests.js';

export class FeatureFlagAdministrationService {
    static async fetchFeatureFlags(...args) {
        const body = args[0];
        const { items, totalCount } = await FeatureFlagRequests.fetchFeatureFlags(body);
        return {
            data: items,
            totalCount,
        };
    }

    static async getInstitutions() {
        return FeatureFlagRequests.getInstitutions();
    }

    static async addFeatureFlag(...args) {
        const body = args[0];
        return FeatureFlagRequests.addFeatureFlag(body);
    }

    static async updateFeatureFlag(...args) {
        const body = args[0];
        return FeatureFlagRequests.updateFeatureFlag(body);
    }

    static async deleteFeatureFlag(id) {
        return FeatureFlagRequests.deleteFeatureFlag(id);
    }
}
