angular.module('backOffice').controller('ManageAchCompanyController', manageAchCompanyController);

manageAchCompanyController.$inject = [
    '$scope',
    'achCompanies',
    'userAchCompanies',
    '$modalInstance',
    '$stateParams',
    '$modal',
    '$filter',
];

function manageAchCompanyController(
    $scope,
    achCompanies,
    userAchCompanies,
    $modalInstance,
    $stateParams,
    $modal,
    $filter
) {
    $scope.companyCifNumber = $stateParams.cifNumber;
    $scope.userId = $stateParams.id;
    $scope.achCompanies = achCompanies;
    $scope.userAchCompanies = userAchCompanies;
    $scope.searchText = '';
    $scope.selectAll = {
        value: false,
    };
    $scope.getSecCodesString = getSecCodesString;

    $scope.search = search;
    $scope.selectOrDeselectAll = selectOrDeselectAll;
    $scope.selectCompany = selectCompany;
    $scope.cancel = cancel;
    $scope.submit = submit;

    function init() {
        angular.forEach($scope.achCompanies, company => {
            angular.forEach($scope.userAchCompanies, userCompany => {
                if (userCompany.id === company.id) {
                    company.isSelected = true;
                }
            });
            company.concatSecCodes = getSecCodesString(company);
        });
        $scope.filteredAchCompanies = $scope.achCompanies.slice();
    }

    function search(name) {
        $scope.searchText = name || '';
        $scope.filteredAchCompanies = $filter('filter')($scope.achCompanies, $scope.searchText);
    }

    function submit() {
        $scope.returnAchCompanies = [];
        angular.forEach($scope.achCompanies, company => {
            if (company.isSelected) {
                $scope.returnAchCompanies.push({
                    id: company.id,
                    companyName: company.companyName,
                });
            }
        });
        $modalInstance.close($scope.returnAchCompanies);
    }

    function cancel() {
        $modalInstance.dismiss();
    }

    function selectOrDeselectAll(selectAll) {
        $scope.selectAll.value = selectAll;
        angular.forEach($scope.filteredAchCompanies, item => {
            item.isSelected = $scope.selectAll.value;
        });
    }

    function selectCompany() {
        let allSelected = true;
        angular.forEach($scope.filteredAchCompanies, company => {
            if (!company.isSelected) {
                allSelected = false;
            }
        });
        $scope.selectAll.value = allSelected;
    }

    function getSecCodesString(achCompany) {
        return achCompany.secCodes.map(elem => elem.code).join(', ');
    }

    init();
}
