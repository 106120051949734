angular.module('backOffice').controller('IpSettingsModalController', ipSettingsModalController);

ipSettingsModalController.$inject = ['$scope', 'IpSettingsService', '$modalInstance', 'toaster'];

function ipSettingsModalController($scope, IpSettingsService, $modalInstance, toaster) {
    // $scope.addIpAddress = addIpAddress;
    // $scope.deleteIpAddress = deleteIpAddress;
    $scope.save = save;
    $scope.cancel = cancel;

    init();

    function init() {
        IpSettingsService.getFiIpAddresses().then(response => {
            $scope.fiIpAddresses = response;
            // $scope.copyIpAddresses = $scope.fiIpAdresses.ipAddresses;
        });
    }

    function cancel() {
        $modalInstance.dismiss();
    }

    function save() {
        IpSettingsService.updateIpAddresses($scope.fiIpAddresses).then(response => {
            toaster.save('FI IP Address Restrict Settings');
            $modalInstance.close();
        });
    }
}
