angular
    .module('backOffice')
    .controller('PrerequisiteConfirmationController', prerequisiteConfirmationController);

prerequisiteConfirmationController.$inject = [
    '$scope',
    'entitlement',
    'relatedEntitlementList',
    'isAdd',
    '$modalInstance',
];

function prerequisiteConfirmationController(
    $scope,
    entitlement,
    relatedEntitlementList,
    isAdd,
    $modalInstance
) {
    $scope.entitlement = entitlement;
    $scope.relatedEntitlementList = relatedEntitlementList;
    $scope.isAdd = function () {
        return isAdd;
    };
    $scope.cancel = cancel;
    $scope.confirm = confirm;

    function cancel() {
        $modalInstance.dismiss();
    }

    function confirm() {
        $modalInstance.close();
    }
}
