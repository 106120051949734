/* eslint-disable import/extensions */
import { TmHttpClient } from '@treasury/core/http';

const baseEndpoint = 'companies/';

/**
 * Static class grouping API calls for Back Office Company features.
 * Many of the requests from `companiesSrvc.js` (Angular service) will be migrated here.
 */
class CompanyRequests {
    static async fetchCompanies() {
        return (await TmHttpClient.getInstance()).request(
            `companies?searchType=Company+Name&searchValue=`,
            {
                method: 'GET',
            }
        );
    }

    static async fetchNotOnboardedCompanies(parameters) {
        return (await TmHttpClient.getInstance()).request(
            `companies/notonboardedcompanies?searchType=${parameters.searchType}&searchValue=${parameters.searchValue}`,
            {
                method: 'GET',
            }
        );
    }

    static async getCompanyName(companyId) {
        return (await TmHttpClient.getInstance()).request(`${baseEndpoint}${companyId}/name`, {
            method: 'GET',
            maxAgeInSeconds: 6000,
        });
    }

    static async getCompanyGuid(companyId) {
        return (await TmHttpClient.getInstance()).request(`company/GetGuidUniqueId/${companyId}`, {
            method: 'GET',
            maxAgeInSeconds: 6000,
        });
    }
}

export default CompanyRequests;
