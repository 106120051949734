angular.module('backOffice').controller('SaveReportModalController', SaveReportModalController);

SaveReportModalController.$inject = [
    '$scope',
    '$modalInstance',
    'customFilter',
    'isExistingFilter',
    'reportsService',
];

function SaveReportModalController(
    $scope,
    $modalInstance,
    customFilter,
    isExistingFilter,
    reportsService
) {
    // scope
    $scope.customFilter = customFilter;
    $scope.isExistingFilter = isExistingFilter;

    // scope functions
    $scope.setForm = setForm;
    $scope.cancel = cancel;
    $scope.saveFilter = saveFilter;

    // scope functions imple
    function setForm(form) {
        $scope.form = form;
    }

    function cancel() {
        $modalInstance.dismiss();
    }

    function saveFilter() {
        $modalInstance.close($scope.customFilter);
    }
}
