angular.module('backOffice').filter(
    'orderByReport',
    () =>
        // This filter is meant for the BoReportModel returned by the web api
        // It works by firstly sorting reports with a sequence order in ascending/descending order
        // and then sorts any objects without a sequence using the report name in ascending/descending order
        function (reports, selector, desc) {
            function getSelectorOrder(report) {
                let order;
                if (!!report.sequences && report.sequences.length > 0) {
                    const sequence = report.sequences.filter(
                        sequence => sequence.selector === selector
                    );
                    if (!!sequence && sequence.length === 1) {
                        order = sequence[0].order;
                    }
                }
                return order;
            }

            if (!angular.isArray(reports)) {
                return reports;
            }

            // Using angular.copy(array) was causing issues with watchCollections
            // So emulated line 19335 of angular.js (1.4.4)
            const sorted = reports.map(item => item);

            if (!angular.isString(selector)) {
                return sorted;
            }

            sorted.sort((a, b) => {
                const orderA = getSelectorOrder(a);
                const orderB = getSelectorOrder(b);

                // SLewis@jackhenry.com: Someone please let me know if this can be simplified
                if (desc) {
                    if ($.isNumeric(orderA) && !$.isNumeric(orderB)) {
                        return -1;
                    }
                    if (!$.isNumeric(orderA) && $.isNumeric(orderB)) {
                        return 1;
                    }
                    if ($.isNumeric(orderA) && $.isNumeric(orderB)) {
                        return orderA > orderB ? -1 : orderA < orderB ? 1 : 0;
                    }
                    return b.name.localeCompare(a.name, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    });
                }
                if ($.isNumeric(orderA) && !$.isNumeric(orderB)) {
                    return -1;
                }
                if (!$.isNumeric(orderA) && $.isNumeric(orderB)) {
                    return 1;
                }
                if ($.isNumeric(orderA) && $.isNumeric(orderB)) {
                    return orderA > orderB ? 1 : orderA < orderB ? -1 : 0;
                }
                return a.name.localeCompare(b.name, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            });
            return sorted;
        }
);
