import { string, money, date } from '@treasury/policy/primitives';

export const wireHistoryColumns = [
    {
        label: 'Company Name',
        field: 'companyName',
    },
    {
        label: 'Transaction Id',
        field: 'transactionId',
    },
    {
        label: 'Wire Company',
        field: 'wireCompany',
    },
    {
        label: 'Debit Account',
        field: 'debitAccount',
    },
    {
        label: 'Amount',
        field: 'amount',
    },
    {
        label: 'Frequency',
        field: 'frequency',
    },
    {
        label: 'Value Date',
        field: 'valueDate',
    },
    {
        label: 'Beneficiary Name',
        field: 'beneficiaryName',
    },
    {
        label: 'Beneficiary Account',
        field: 'beneficiaryAccount',
    },
    {
        label: 'Purpose',
        field: 'purpose',
    },
    {
        label: 'Status',
        field: 'status',
    },
    {
        label: 'Changed By',
        field: 'changedBy',
    },
    {
        label: 'Changed Date',
        field: 'changedDate',
    },
];

export const wireHistoryFields = {
    companyName: string.thatIs.readOnly(),
    transactionId: string.thatIs.readOnly(),
    wireCompany: string.thatIs.readOnly(),
    debitAccount: string.thatIs.readOnly(),
    amount: money.thatIs.readOnly(),
    frequency: string.thatIs.readOnly(),
    valueDate: date.thatIs.readOnly(),
    beneficiaryName: string.thatIs.readOnly(),
    beneficiaryAccount: string.thatIs.readOnly(),
    purpose: string.thatIs.readOnly(),
    status: string.thatIs.readOnly(),
    changedBy: string.thatIs.readOnly(),
    changedDate: date.thatIs.readOnly(),
};
