/* eslint-disable import/extensions */
import { TmHttpClient } from '@treasury/core/http';

export default class FxWireRequest {
    static async getCorpayProductFeatureConfigurations() {
        return (await TmHttpClient.getInstance()).request(`corpayProductFeatureConfigurations`);
    }

    static async saveCorpayProductFeatureConfigurations(sharedSecretKey: string) {
        return (await TmHttpClient.getInstance()).request(`corpayProductFeatureConfigurations`, {
            method: 'POST',
            body: {
                SharedSecretKey: sharedSecretKey,
            },
        });
    }
}
