angular.module('backOffice').controller('ChangePasswordController', changePasswordController);

changePasswordController.$inject = [
    '$scope',
    'accountService',
    'pagePushService',
    'loginStateService',
    'userCredentialStatus',
];

function changePasswordController(
    $scope,
    accountService,
    pagePushService,
    loginStateService,
    userCredentialStatus
) {
    $scope.passwordObj = {
        password: null,
        confirmPassword: null,
    };

    $scope.message = '';

    $scope.changePassword = changePassword;
    function changePassword() {
        accountService.updatePassword($scope.passwordObj).then(response => {
            if (loginStateService.nextState()) {
                return;
            }

            $scope.message = accountService.checkForError({
                statusCode: response.userCredentialStatus,
                message: response.message,
            });

            if (!$scope.message) {
                $scope.message = 'Unable to proceed. Please contact your administrator.';
            }
        });
    }

    $scope.reset = function () {
        $scope.passwordObj.password = null;
        $scope.passwordObj.confirmPassword = null;
        $scope.form.$setPristine();
    };

    $scope.initialize = function (form) {
        $scope.form = form;
    };

    function init() {
        if (!loginStateService.validateCurrentState()) {
            return;
        }

        $scope.reason =
            loginStateService.getUserCredentialStatus() === userCredentialStatus.EXPIRED
                ? 'expired'
                : 'temp';

        const user = pagePushService.getUser();
        $scope.passwordObj.loginId = user.loginId;
        $scope.passwordObj.fiId = user.fiId;
        accountService.getPasswordRequirements(user.fiId).then(response => {
            $scope.passwordRequirementsList = response;
        });
    }

    init();
}
