import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('jhaSupportService', jhaSupportService);

jhaSupportService.$inject = ['$resource', '$window'];

function jhaSupportService($resource, $window) {
    const resourceUrlBase = `${getBackOfficeAPIPath()}jhasupport/`;

    const JhaSupport = $resource(
        resourceUrlBase,
        {},
        {
            getInstitutions: {
                method: 'GET',
                isArray: true,
                url: `${resourceUrlBase}institutions`,
            },
            impersonateInstitution: {
                method: 'POST',
                url: `${resourceUrlBase}impersonateInstitution/:newInstitutionId`,
                // $resource interprets a string response as an array of characters,
                // wrapping it in an object to avoid that.
                transformResponse(data) {
                    return { fiId: JSON.parse(data) };
                },
            },
        }
    );

    const service = {
        getInstitutions,
        setUserFiId,
        getUserFiId,
        impersonateInstitution,
    };

    return service;

    function getInstitutions() {
        return JhaSupport.getInstitutions().$promise;
    }

    function setUserFiId(userFiId) {
        return $window.sessionStorage.setItem('userFiId', userFiId);
    }

    function getUserFiId() {
        return $window.sessionStorage.getItem('userFiId');
    }

    function impersonateInstitution(newInstitutionId) {
        return JhaSupport.impersonateInstitution({ newInstitutionId }, null).$promise.then(
            response => {
                setUserFiId(response.fiId);
            }
        );
    }
}
