angular.module('backOffice').controller('ManageSecCodesController', manageSecCodesController);

manageSecCodesController.$inject = [
    '$scope',
    'configurationService',
    'selectedSecCodes',
    '$modalInstance',
    'SecCodesService',
    'achService',
    'productFeatureId',
    'allowInternationalAchPayments',
];

function manageSecCodesController(
    $scope,
    configurationService,
    selectedSecCodes,
    $modalInstance,
    SecCodesService,
    achService,
    productFeatureId,
    allowInternationalAchPayments
) {
    $scope.selectedSecCodes = selectedSecCodes;
    $scope.copySelectedSecCodes = angular.copy($scope.selectedSecCodes);
    $scope.allSecCodes = [];
    $scope.obj = { selectAll: false };
    $scope.productFeatureId = productFeatureId;

    $scope.save = save;
    $scope.selectOrDeselectAllSecCodes = selectOrDeselectAllSecCodes;
    $scope.updateList = updateList;
    $scope.cancel = cancel;
    $scope.checkAtleastOneSelected = checkAtleastOneSelected;
    $scope.getAllowedEntitlement = getAllowedEntitlement;
    $scope.allowInternationalAchPayments = allowInternationalAchPayments;

    const isCompanyProduct = $scope.productFeatureId != null;

    init();

    function init() {
        if (isCompanyProduct) {
            // Get SEC codes for a specific product feature ID
            achService.getSecCodesForProduct($scope.productFeatureId).then(onSecCodes);
        } else {
            // Get all SEC codes
            SecCodesService.getAllSecCodes().then(onSecCodes);
        }

        function onSecCodes(response) {
            $scope.allSecCodes = response;
            angular.forEach($scope.allSecCodes, secCode => {
                angular.forEach($scope.selectedSecCodes, item => {
                    if (secCode.code === item.code) {
                        secCode.isSelected = true;
                    }
                });
            });
            updateList();
        }
    }

    function getAllowedEntitlement() {
        if (isCompanyProduct) {
            return 'Edit Product Feature';
        }
        return 'Edit Product Feature Configuration';
    }
    function selectOrDeselectAllSecCodes() {
        angular.forEach($scope.allSecCodes, item => {
            if (!$scope.allowInternationalAchPayments && item.code === 'IAT') {
                return;
            }
            item.isSelected = $scope.obj.selectAll;
        });
    }

    function cancel() {
        $scope.selectedSecCodes = angular.copy($scope.copySelectedSecCodes);
        $modalInstance.dismiss();
    }

    function save() {
        $scope.selectedSecCodes = [];
        angular.forEach($scope.allSecCodes, item => {
            if (item.isSelected) {
                $scope.selectedSecCodes.push(item);
            }
        });
        $modalInstance.close($scope.selectedSecCodes);
    }

    function checkAtleastOneSelected() {
        let atleastOneSelected = true;
        angular.forEach($scope.allSecCodes, secCode => {
            if (secCode.isSelected) {
                atleastOneSelected = false;
            }
        });
        return atleastOneSelected;
    }

    function updateList() {
        let allSelected = true;
        angular.forEach($scope.allSecCodes, secCode => {
            if (!secCode.isSelected) {
                allSelected = false;
            }
        });
        $scope.obj.selectAll = allSelected;
    }
}
