/* eslint-disable lit-a11y/anchor-is-valid */
import { DiContainer } from '@jack-henry/frontend-utils/di';
import { Recordset } from '@treasury/FDL';
import { NavigationService } from '@treasury/core/navigation';
import CompanyRequests from '@treasury/domain/backoffice/requests/companies/company-requests.js';
import { CompanyAccountReconConfiguration } from '@treasury/domain/backoffice/requests/company-configuration/company-account-recon-configuration.js';
import { TmApiError } from '@treasury/domain/shared';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-table.js';
import { Events } from '@treasury/omega/models/events.js';
import { boolean, string } from '@treasury/policy/primitives';
import { LitElement, css, html, nothing } from 'lit';
import { mix } from 'mixwith';
import '../../components/blocking-loader.js';
import BackOfficeAlertMixin from '../../mix-ins/back-office-alert-mixin.js';

// usage: <company-account-reconciliation-reporting-configuration></company-account-reconciliation-reporting-configuration>
class CompanyAccountReconciliationReportingConfiguration extends mix(LitElement).with(
    BackOfficeAlertMixin,
    ListeningElementMixin
) {
    static get properties() {
        return {
            loading: { type: Boolean },
            companyId: { type: String },
            action: { type: String },
            dataChanged: { type: Boolean, attribute: false },
            companyName: { type: String, attribute: false },
            alert: Object,
        };
    }

    constructor() {
        super();
        this.autostart = true;
        this.loading = true;

        this.featureDisplayName = null;
        this.configurationSettings = null;
        this.accountsEligibility = null;
        this.dataChanged = false;
        this.chevronLeft = '\u2039'; // TODO: extract unicode literals to a constants class

        // declare the table meta-data
        this.fields = {
            number: string.thatIs.readOnly(),
            cifNumber: string.thatIs.readOnly(),
            type: string.thatIs.readOnly(),
            isEnabled: boolean.as.tag('omega-toggle'),
        };
        this.columns = [
            {
                field: 'number',
                label: 'Account Number',
            },
            {
                field: 'cifNumber',
                label: 'CIF Number',
            },
            {
                field: 'type',
                label: 'Account Type',
            },
            {
                field: 'isEnabled',
                label: 'Add to Company',
                type: 'checkbox',
            },
        ];
        this.rowsPerPage = 25;
        this.actions = [];
    }

    async firstUpdated() {
        this.companyName = await this.getCompanyName(this.companyId);

        if (!this.featureDisplayName) {
            this.actionVerb = this.action === 'add' ? 'Add' : 'Edit';
            this.featureDisplayName = `Account Reconciliation Reporting`;
        }
        this.initData();
    }

    async goBack() {
        const navService = (await DiContainer.getInstance()).get(NavigationService);
        navService.navigate('company', { id: this.companyId });
    }

    async save() {
        this.loading = true;
        try {
            await CompanyAccountReconConfiguration.saveEligibleAccounts(this.accountsEligibility);
            this.alert = {
                ...this.alert,
                type: 'success',
                visible: true,
                message: `Accounts successfully Saved.`,
                code: '',
                time: '',
            };
        } catch (e) {
            this.alert = {
                ...this.alert,
                message:
                    e instanceof Error
                        ? e.message
                        : 'An error occurred. Accounts could not be saved.',
                type: 'error',
                visible: true,
                code: e instanceof TmApiError ? e.errorCode : undefined,
                time: e instanceof TmApiError ? e.timestamp : undefined,
            };
        } finally {
            this.loading = false;
        }

        if (this.action === 'add') {
            this.goBack();
        } else {
            this.dataChanged = false;
            this.loading = false;
        }
    }

    async initData() {
        this.loading = true;
        this.dataChanged = false;
        let count = 1;
        try {
            this.accountsEligibility =
                await CompanyAccountReconConfiguration.getAccountReconEligibleAccounts(
                    this.companyId
                );
            count++;
            this.recordSet = new Recordset(this.fields, () => this.accountsEligibility.accounts);
            await this.recordSet.requestUpdate();
            count++;
            this.listenTo(this.recordSet, Events.UPDATED, () => this.onRecordSetUpdated());
            count++;
        } catch (e) {
            const message = e instanceof Error ? e.message : 'An unknown error occurred.';
            this.alert = {
                ...this.alert,
                visible: true,
                type: 'error',
                message: `error in initData(), line count ${count}: ${message}`,
                code: e instanceof TmApiError ? e.errorCode : undefined,
            };
        } finally {
            this.loading = false;
        }
    }

    onRecordSetUpdated() {
        this.dataChanged = true;
    }

    async getCompanyName(companyId) {
        let result;
        try {
            result = await CompanyRequests.getCompanyName(companyId);
            return result.name;
        } catch (e) {
            const message = e instanceof Error ? e.message : 'An unknown error occurred.';
            this.alert = {
                ...this.alert,
                type: 'error',
                visible: true,
                message: `Could not get company name: ${message}`,
                code: e instanceof TmApiError ? e.errorCode : undefined,
            };

            return undefined;
        }
    }

    hasNoData() {
        return !this.accountsEligibility || this.accountsEligibility.length <= 0;
    }

    handleActions({ action, record, rowIndex }) {
        this.actions[action](record, rowIndex);
    }

    setAlert(e, caller) {
        this.alert = {
            ...e.detail.error,
            message: `Error in ${caller}(): ${e.detail.error.message}`,
            type: 'error',
            visible: true,
        };
    }

    renderBreadCrumb() {
        if (this.loading) return nothing;
        return html`<div id="back-link">
            <a href="javascript:void(0)" @click="${this.goBack}">
                ${this.chevronLeft} ${this.companyName} Dashboard
            </a>
        </div>`;
    }

    renderBody() {
        if (this.hasNoData()) {
            return html`<div id="no-data-indicator">No accounts found for this company.</div>`;
        }
        return html`<div>${this.renderTableData()}</div>`;
    }

    renderTableData() {
        return html`
            <omega-table
                .autostart=${this.autostart}
                .recordset=${this.recordSet}
                .columnDefinitions=${this.columns}
                .rowsPerPage=${this.rowsPerPage}
                .params=${this.params}
                @action=${e => this.handleActions(e.detail)}
                @error=${e => this.setAlert(e, 'renderTableData')}
            >
            </omega-table>
        `;
    }

    renderFooter() {
        return html`
            <omega-button type="primary" @click="${this.save}">Save </omega-button>
            <omega-button type="secondary" @click="${this.goBack}">Cancel</omega-button>
        `;
    }

    renderDetail() {
        if (this.loading) {
            return nothing;
        }
        return html`
            <div id="detail-panel">
                <div id="detail-panel-contents">
                    <div id="detail-panel-header">
                        <div id="detail-panel-header-title">
                            Account Reconciliation Eligible Accounts
                        </div>
                    </div>
                    <div id="detail-panel-body">${this.renderBody()}</div>
                    <div id="detail-panel-footer">
                        <div id="detail-panel-footer-contents">${this.renderFooter()}</div>
                    </div>
                </div>
            </div>
        `;
    }

    renderBlockingLoader() {
        if (!this.loading) return nothing;
        return html`<blocking-loader></blocking-loader>`;
    }

    render() {
        return html`
            ${this.renderBlockingLoader()}
            <div id="company-configuration-view">
                <div id="view-header">
                    ${this.renderBreadCrumb()}
                    <div id="configuration-title">
                        ${this.actionVerb} ${this.featureDisplayName}
                    </div>
                </div>
                ${this.renderAlert()}${this.renderDetail()}
            </div>
        `;
    }
    // TODO: Add color for --omega-button-link (same as --omega-button-primary-box-shadow)
    // etc.
    // Need an omega-breadcrumb component for the back link.
    // TODO: need an omega-no-data-banner component

    static get styles() {
        return css`
            :host {
                display: block;
            }
            #company-configuration-view {
                background-color: #ecf2f6;
                min-width: 992px !important;
                display: block;
                padding: 15px;
                margin-right: auto;
                margin-left: auto;
            }
            #view-header {
                flex: 0 0 auto;
                font-weight: 400;
                text-align: start;
                margin-top: 0 !important;
            }
            #back-link {
                display: block;
                line-height: 14px;
                margin-bottom: 7px;
            }
            #configuration-title {
                font-size: 27px;
                color: var(--omega-text-header);
                line-height: 50px;
            }
            #detail-panel {
                box-sizing: border-box;
                border: 1px solid #c7c7c7;
                border-radius: 2px;
                box-shadow: 0 2px 4px 0 rgb(209 209 209 / 50%);
                color: var(--omega-text-default);
                background-color: var(--omega-white);
            }
            #detail-panel-contents {
                padding: 15px;
            }
            #detail-panel-header {
                color: var(--omega-text-header);
                border-bottom: 1px solid #ddd;
            }
            #detail-panel-header-title {
                font-size: 21px;
                margin-bottom: 15px;
            }
            #detail-panel-body {
                margin-top: 15px;
                margin-bottom: 15px;
            }
            #detail-panel-footer {
                border-top: 1px solid #ddd;
            }
            #detail-panel-footer-contents {
                margin-top: 15px;
            }
            #no-data-indicator {
                text-align: center;
                padding-top: 48px;
                padding-bottom: 48px;
                padding-left: 60px;
                padding-right: 60px;
                border-radius: 6px;
                background-color: #eee;
                font-size: 24px;
            }
        `;
    }
}

customElements.define(
    'company-account-reconciliation-reporting-configuration',
    CompanyAccountReconciliationReportingConfiguration
);
export default CompanyAccountReconciliationReportingConfiguration;
