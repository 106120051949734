angular.module('backOffice').controller('UserSettingsController', userSettingsController);

userSettingsController.$inject = [
    '$scope',
    'companyId',
    'companiesService',
    '$modalInstance',
    'toaster',
];

function userSettingsController($scope, companyId, companiesService, $modalInstance, toaster) {
    $scope.companyId = companyId;
    $scope.save = save;
    $scope.saveIpSettings = saveIpSettings;
    $scope.setIpForm = setIpForm;
    $scope.setUserApprovalForm = setUserApprovalForm;
    $scope.channelApproveChannel = channelApproveChannel;
    $scope.backOfficeApproveBackOffice = backOfficeApproveBackOffice;
    $scope.channelApproveBackOffice = channelApproveBackOffice;
    $scope.cancel = cancel;
    $scope.isDirty = false;
    init();

    function init() {
        companiesService.getApprovalSettings($scope.companyId).then(response => {
            $scope.approvalSettings = response;
        });
        getCompaniesIpAddressSettings();
    }

    function save() {
        companiesService
            .updateApprovalSettings($scope.companyId, $scope.approvalSettings)
            .then(response => {
                $scope.approvalSettings = response;
                toaster.save('Company Approval Settings');
                $scope.userApprovalSettingsForm.$setPristine();
            });
    }

    function saveIpSettings() {
        companiesService
            .updateIpAddressSettings($scope.companyId, $scope.ipAddresses)
            .then(response => {
                $scope.ipAddresses = response;
                toaster.save('Company IP Access Settings');
                $scope.ipAddressForm.$setPristine();
            });
    }

    function setIpForm(form) {
        $scope.ipAddressForm = form;
    }

    function setUserApprovalForm(form) {
        $scope.userApprovalSettingsForm = form;
    }

    function cancel() {
        $modalInstance.close();
    }

    function getCompaniesIpAddressSettings() {
        companiesService.getIpAddressSettings($scope.companyId).then(response => {
            $scope.ipAddresses = response;
        });
    }

    function channelApproveChannel() {
        $scope.approvalSettings.channelApproveChannel =
            !$scope.approvalSettings.channelApproveChannel;
        $scope.isDirty = true;
    }

    function backOfficeApproveBackOffice() {
        $scope.approvalSettings.backOfficeApproveBackOffice =
            !$scope.approvalSettings.backOfficeApproveBackOffice;
        $scope.isDirty = true;
    }

    function channelApproveBackOffice() {
        $scope.approvalSettings.channelApproveBackOffice =
            !$scope.approvalSettings.channelApproveBackOffice;
        $scope.isDirty = true;
    }
}
