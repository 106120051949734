angular
    .module('backOffice')
    .controller(
        'DuplicateTransactionCodeErrorModalController',
        duplicateTransactionCodeErrorModalController
    );

duplicateTransactionCodeErrorModalController.$inject = ['$scope', '$modalInstance', 'errors'];

function duplicateTransactionCodeErrorModalController($scope, $modalInstance, errors) {
    $scope.errors = errors;

    // this will dismiss the window.
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    function init() {}

    init();
}
