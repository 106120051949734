angular
    .module('backOffice')
    .controller('messageCenterViewModalController', messageCenterViewModalController);

messageCenterViewModalController.$inject = [
    '$scope',
    '$modalInstance',
    'modalService',
    'messagingCenterService',
    'messageId',
    'FileSaver',
    '$timeout',
    'uiMessages',
    'entitlementsService',
];

function messageCenterViewModalController(
    $scope,
    $modalInstance,
    modalService,
    messagingCenterService,
    messageId,
    FileSaver,
    $timeout,
    uiMessages,
    entitlementsService
) {
    $scope.messageThread = null;
    $scope.composeReply = false;
    $scope.modalHeader = 'View Message';
    $scope.createSaveMessageFF = entitlementsService.hasEntitlement(
        'Feature.MessageCenter.CreateSaveMessage'
    );

    function init() {
        messagingCenterService.getMessageThread(messageId).then(response => {
            $scope.messageThread = response;
            for (let i = 1; i < $scope.messageThread.length; i++) {
                $scope.messageThread[i].isOpen = false;
            }
            if ($scope.messageThread.length > 1) {
                $scope.modalHeader = `${$scope.messageThread[0].messageId} Re: ${$scope.messageThread[0].subject}`;
            } else {
                $scope.modalHeader = `${$scope.messageThread[0].messageId} ${$scope.messageThread[0].subject}`;
            }
        });
    }

    $scope.formatChannelUser = function (userName, companyName) {
        return `${userName}, ${companyName}`;
    };

    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    $scope.print = function () {
        window.print();
    };

    function getMimeType(extension) {
        switch (extension.toLowerCase()) {
            case 'pdf':
                return 'application/pdf';
            case 'csv':
                return 'text/csv';
            case 'doc':
                return 'application/msword';
            case 'docx':
                return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            case 'gif':
                return 'image/gif';
            case 'jfif':
            case 'jif':
            case 'jpe':
            case 'jpg':
            case 'jpeg':
                return 'image/jpg';
            case 'png':
                return 'image/png';
            case 'txt':
                return 'text/plain';
            case 'xls':
                return 'application/vnd.ms-excel';
            case 'xlsx':
                return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            default:
                return 'application/octet-stream';
        }
    }

    $scope.downloadAttachment = function (attachment) {
        const { id, fileIdentifier, name } = attachment;
        let attachmentId = fileIdentifier;
        let oldEndpoint = false;
        if (fileIdentifier === undefined || fileIdentifier === null) {
            attachmentId = id;
            oldEndpoint = true;
        }

        messagingCenterService.downloadAttachment(attachmentId, oldEndpoint).then(response => {
            if (response) {
                const blob = new Blob([response.data], {
                    type: getMimeType(name.split('.').pop()),
                });
                FileSaver.saveAs(blob, name);
            }
        });
    };

    $scope.reply = function () {
        $scope.replyMessage = {
            files: [],
        };
        if ($scope.messageThread.length >= 2) {
            $scope.modalHeader = `Re: ${$scope.messageThread[0].messageId} Re: ${$scope.messageThread[0].subject}`;
        } else {
            $scope.modalHeader = `Re: ${$scope.messageThread[0].messageId} ${$scope.messageThread[0].subject}`;
        }
        $scope.composeReply = true;
    };

    $scope.cancelReply = function () {
        $scope.modalHeader = 'View Message';
        $scope.composeReply = false;
        $scope.sliding = true;
        if ($scope.messageThread.length > 1) {
            $scope.modalHeader = `${$scope.messageThread[0].messageId} Re: ${$scope.messageThread[0].subject}`;
        } else {
            $scope.modalHeader = `${$scope.messageThread[0].messageId} ${$scope.messageThread[0].subject}`;
        }
        $timeout(() => {
            $scope.sliding = false;
        }, 350);
    };

    $scope.sendReply = function () {
        $scope.replyMessage.companyId = $scope.messageThread[0].companyId;
        $scope.replyMessage.categoryId = $scope.messageThread[0].categoryId;
        $scope.replyMessage.parentMessageId = $scope.messageThread[0].id;

        if ($scope.createSaveMessageFF && $scope.messageThread[0].sentMessage) {
            $scope.replyMessage.userId = $scope.messageThread[0].toUserId;
        } else {
            $scope.replyMessage.userId = $scope.messageThread[0].fromUserId;
        }

        messagingCenterService.saveMessage($scope.replyMessage).then(response => {
            $scope.replyMessage = response;
            $modalInstance.close($scope.replyMessage);
        });
    };

    $scope.allowedExtensions = $scope.createSaveMessageFF
        ? [
              '.csv',
              '.doc',
              '.docx',
              '.gif',
              '.jfif',
              '.jif',
              '.jpg',
              '.jpeg',
              '.jpe',
              '.pdf',
              '.png',
              '.txt',
              '.xls',
              '.xlsx',
          ]
        : ['.csv', '.doc', '.docx', '.gif', '.jpg', '.jpeg', '.pdf', '.png', '.txt'];

    $scope.validationOptions = {
        maxFileSize: 3145728,
        allowedExtensions: $scope.allowedExtensions,
    };

    $scope.onSelectFile = function (e) {
        if (e.files) {
            angular.forEach(e.files, file => {
                if (
                    $.inArray(
                        file.extension.toLowerCase(),
                        $scope.validationOptions.allowedExtensions
                    ) === -1
                ) {
                    modalService.showModal(
                        {},
                        {
                            bodyTitle: 'File Error',
                            alertType: 'Error',
                            isAlert: true,
                            summaryText: uiMessages.FileWrongExtension,
                        }
                    );
                    e.preventDefault();
                }
                // File size is not available in all browsers
                else if (file.size > $scope.validationOptions.maxFileSize) {
                    modalService.showModal(
                        {},
                        {
                            bodyTitle: 'File Error',
                            alertType: 'Error',
                            isAlert: true,
                            summaryText: uiMessages.DocExceedsMaxSize,
                        }
                    );
                    e.preventDefault();
                }
            });

            $scope.form.$setDirty();
            angular.forEach(e.files, file => {
                $scope.replyMessage.files.push({ uid: file.uid, rawFile: file.rawFile });
            });
            $scope.$apply();
        }
    };

    $scope.onRemoveFile = function (e) {
        if (e.files) {
            $scope.form.$setDirty();
            angular.forEach(e.files, file => {
                $scope.replyMessage.files = $scope.replyMessage.files.filter(
                    el => el.uid !== file.uid
                );
            });
            $scope.$apply();
        }
    };

    $scope.setForm = function (form) {
        $scope.form = form;
    };

    init();
}
