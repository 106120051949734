angular.module('backOffice').controller('ProductFeaturesController', productFeaturesController);

productFeaturesController.$inject = [
    '$scope',
    'companiesService',
    '$state',
    '$modal',
    'modalService',
    'toaster',
    'entitlementsService',
    'spinnerService',
];

function productFeaturesController(
    $scope,
    companiesService,
    $state,
    $modal,
    modalService,
    toaster,
    entitlementsService,
    spinnerService
) {
    $scope.productList = null;
    $scope.availableProducts = [];
    $scope.refresh = refresh;

    init();

    $scope.$watch('companyStatus.status', newValue => {
        if (newValue !== null && newValue !== undefined && newValue !== 'Not Onboarded') {
            loadProducts();
        }
    });

    function loadProducts() {
        spinnerService.startGroupRequest('productFeatures');
        companiesService
            .getCompanyProducts($scope.companyDetails.id)
            .then(
                response => {
                    $scope.productList = response;
                },
                response => {
                    $scope.productList = [];
                }
            )
            .finally(() => {
                spinnerService.stopGroupRequest('productFeatures');
            });
        loadAvailableProducts();
    }

    function loadAvailableProducts() {
        spinnerService.startGroupRequest('getAvailableProducts');
        companiesService.getAvailableProductsForCompany($scope.companyDetails.id).then(response => {
            spinnerService.stopGroupRequest('getAvailableProducts');
            $scope.availableProducts = response;
        });
    }

    function init() {
        spinnerService.configureGroup(
            'productFeatures',
            [`/companies/${$scope.companyDetails.id}/productFeatures`],
            []
        );
        spinnerService.configureGroup(
            'getAvailableProducts',
            [`/companies/${$scope.companyDetails.id}/getAvailableProducts`],
            []
        );
    }

    function refresh() {
        $scope.productList = null;
        loadProducts();
    }

    $scope.addProduct = function () {
        // copy the available products array into the modal $scope
        const $modalScope = $scope.$new(true);
        $modalScope.availableProducts = $scope.availableProducts.slice(0);

        const modalInstance = $modal.open({
            template: require('../views/addProductModalView.html'),
            size: 'md',
            controller: 'AddProductController',
            scope: $modalScope,
            backdrop: 'static',
            resolve: {
                companyId() {
                    return $scope.companyDetails.id;
                },
            },
        });
        modalInstance.result.then(addedProduct => {
            $scope.productList.push(addedProduct.responseProduct);
            const productIndex = $scope.availableProducts
                .map(prd => prd.name)
                .indexOf(addedProduct.selectedProduct.name);
            if (productIndex !== -1) {
                $scope.availableProducts.splice(productIndex, 1);
            }
            toaster.save('Product');
        });
    };

    $scope.changeProductStatus = function (product) {
        let message;
        let modalOptions;
        let actionButtonText;
        let closeButtonText;

        message = 'Are you sure you want to change the product feature status?';
        actionButtonText = 'Yes';
        closeButtonText = 'No';

        modalOptions = {
            bodyText: message,
            actionButtonText,
            closeButtonText,
            submit(result) {
                // change status
                const newStatus = product.status === 'Inactive' ? 'Active' : 'Inactive';
                product.status = newStatus;
                companiesService
                    .changeProductStatus($scope.companyDetails.id, product.id, newStatus)
                    .then(response => {
                        product = response;
                        toaster.save('Product Status');
                    });
                $modalInstance.close(result);
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    };

    $scope.edit = function (product, canEdit) {
        if (product.type === 'WireTransferProductFeature') {
            $state.go('company-wire-transfer', {
                id: $scope.companyDetails.id,
                type: 'wire-transfer',
                productFeatureId: product.id,
            });
        } else if (product.type === 'WireTransferDliProductFeature') {
            $state.go('company-wire-transfer', {
                id: $scope.companyDetails.id,
                productFeatureId: product.id,
                type: 'company-wire-transfer-dli',
            });
        } else if (product.type === 'AccountRecon') {
            $state.go('company-account-recon', {
                companyId: $scope.companyDetails.id,
                action: 'edit',
            });
        } else if (product.type === 'ArpProductFeature') {
            $state.go('company-arp', {
                id: $scope.companyDetails.id,
                productFeatureId: product.id,
                type: 'company-arp',
            });
        } else if (product.type === 'InternalTransferProduct') {
            $modal.open({
                template: require('../views/customizeInternalTransfer.html'),
                size: 'md',
                controller: 'CustomizeInternalTransferController',
                backdrop: 'static',
                resolve: {
                    selectedProductId() {
                        return product.id;
                    },
                    companyId() {
                        return $scope.companyDetails.id;
                    },
                },
            });
        } else if (product.type === 'ACH') {
            $state.go('company-ach', {
                id: $scope.companyDetails.id,
                type: 'ach',
                productFeatureId: product.id,
            });
        } else if (product.type === 'StopPayment') {
            $state.go('company-stop-payment', {
                id: $scope.companyDetails.id,
                type: 'stop-payment',
                productFeatureId: product.id,
            });
        } else if (product.type === 'BillPayProductFeature') {
            $state.go('company-bill-pay', {
                id: $scope.companyDetails.id,
                productFeatureId: product.id,
            });
        } else if (product.type === 'ElectronicDocumentsProductFeature') {
            $state.go('company-electronic-documents', {
                id: $scope.companyDetails.id,
                productFeatureId: product.id,
            });
        } else if (product.type === 'RemoteDepositCaptureSsoProductFeature') {
            $state.go('company-remote-deposit-capture', {
                id: $scope.companyDetails.id,
                productFeatureId: product.id,
            });
        } else if (product.type === 'WausauStatements') {
            $state.go('company-wausau-estatements', {
                id: $scope.companyDetails.id,
                productFeatureId: product.id,
            });
        } else {
            const index = $scope.productList.indexOf(product);
            const modalInstance = $modal.open({
                template: require('../views/editProductModalView.html'),
                size: 'md',
                controller: 'EditProductController',
                backdrop: 'static',
                resolve: {
                    selectedProductId() {
                        return product.id;
                    },
                    companyId() {
                        return $scope.companyDetails.id;
                    },
                    canEdit() {
                        return canEdit;
                    },
                },
            });
        }
    };

    $scope.assignProductsAndAccounts = function () {
        const modalInstance = $modal.open({
            template: require('../../shared/assignProductsAccounts/views/assignProductsAccountsModalView.html'),
            size: 'lg',
            controller: 'AssignProductsAccountsController',
            backdrop: 'static',
            resolve: {
                entityType() {
                    return 'Accounts';
                },
                companyId() {
                    return $scope.companyDetails.id;
                },
                cifNumber() {
                    return $scope.companyDetails.cifNumber;
                },
            },
        });
        modalInstance.result.then(() => {
            toaster.save('Assign Accounts');
        });
    };

    $scope.canChangeStatus = canChangeStatus;

    function canChangeStatus() {
        return entitlementsService.hasAnyEntitlement('Edit Product Feature', 'Add Product Feature');
    }
}
