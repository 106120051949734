angular.module('backOffice').directive('viewOnlyException', [
    'entitlementsService',
    function (entitlementsService) {
        return {
            scope: {
                allowedEntitlements: '@',
            },
            link(scope, element) {
                function checkEntitlement() {
                    const entitlements = scope.allowedEntitlements.split(',');
                    let isEntitled = false;
                    entitlements.forEach(entitlement => {
                        if (entitlementsService.hasEntitlement(entitlement)) {
                            isEntitled = true;
                        }
                    });
                    if (isEntitled) {
                        element.removeClass('support-disabled');
                    } else {
                        element.addClass('support-disabled');
                    }
                }

                checkEntitlement();

                scope.$on('entitlementsSet', checkEntitlement);
            },
        };
    },
]);
