angular
    .module('backOffice')
    .controller('AssignProductsAccountsController', assignProductsAccountsController);

assignProductsAccountsController.$inject = [
    '$scope',
    '$filter',
    'companiesService',
    '$modalInstance',
    'entityType',
    'companyId',
    'cifNumber',
];

function assignProductsAccountsController(
    $scope,
    $filter,
    companiesService,
    $modalInstance,
    entityType,
    companyId,
    cifNumber
) {
    // Stores entityType for display purposes.
    $scope.entityType = entityType;
    $scope.companyId = companyId;
    $scope.cifNumber = cifNumber;

    // stores the object structure for both rows and columns.
    $scope.assignProductsAndAccountsList = [];
    $scope.assignProductsAndAccountsSearchText = '';
    $scope.checkAll = false;
    $scope.toggle = false;
    $scope.orderByField = null;
    $scope.reverseSort = false;

    $scope.filterProductAccounts = function (array, searchTerm) {
        // If no array is given, exit.
        if (!array) {
            return;
        }

        if (!searchTerm) {
            return array;
        }
        const term = searchTerm.toLowerCase();
        return array.filter(item => {
            const termInId = item.account.number.toLowerCase().indexOf(term) > -1;
            const termInName = item.account.type.toLowerCase().indexOf(term) > -1;
            return termInId || termInName;
        });
    };

    // this will dismiss the window.
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    $scope.save = function () {
        assignProductsAndAccounts();
    };

    // used to set the form instance.
    $scope.setForm = function (form) {
        $scope.form = form;
    };

    $scope.setDirty = function () {
        $scope.form.$dirty = true;
    };

    // we watch for form.$dirty, this is used to alert the end user that they changed something in the form.
    $scope.$watch('form.$dirty', isDirty => {
        $modalInstance.isDirty = isDirty;
    });

    // used for custom sorting.
    // needed because the product sorting is a child object of the row being sorted.
    $scope.customSortOrder = function (account) {
        if ($scope.orderByField === 'number') {
            return parseFloat(account.account.account.number);
        }
        if ($scope.orderByField === 'cifNumber') {
            return account.account.cifNumber;
        }
        if ($scope.orderByField === 'type') {
            return account.account.type;
        }
        if ($scope.orderByField === 'coreStatus') {
            return account.account.account.coreStatus;
        }
        return `${account.productFeatures[$scope.orderByField].isSelected}`;
    };

    $scope.$watch('assignProductsAndAccountsSearchText', newValue => {
        $scope.toggle = false;
    });

    $scope.selelectAllProductsForAllAccounts = function () {
        // iterate through all rows
        angular.forEach(
            $scope.filterProductAccounts(
                $scope.assignProductsAndAccountsList.accountProducts,
                $scope.assignProductsAndAccountsSearchText
            ),
            rowItem => {
                if (rowItem.isVisible === true) {
                    // iterate through all products and set toggle
                    angular.forEach(rowItem.productFeatures, product => {
                        if (
                            $scope.toggle === false ||
                            rowItem.account.account.coreStatus === 'Active'
                        ) {
                            product.isSelected = $scope.toggle;
                        }
                    });
                }
            }
        );

        // finally set columns isChecked to toggle
        angular.forEach($scope.assignProductsAndAccountsList.products, column => {
            column.isChecked = $scope.toggle;
        });
        verifyIsAllChecked();
    };

    $scope.productSelected = function (item) {
        angular.forEach($scope.assignProductsAndAccountsList, rowItem => {
            angular.forEach(rowItem, product => {
                // checks whether the name of the product feature selected and the header are equal
                if (product.name === item.productFeature.name) {
                    product.isChecked = checkingHeader(item);
                }
            });
        });
        verifyIsAllChecked();
    };

    // function to make sure the product feature header to select or deselect
    function checkingHeader(item) {
        let headerChecked = true;
        angular.forEach($scope.assignProductsAndAccountsList.accountProducts, rowItem => {
            angular.forEach(rowItem.productFeatures, product => {
                if (product.productFeature.name == item.productFeature.name)
                    if (product.isSelected === false) {
                        headerChecked = false;
                    }
            });
        });
        return headerChecked;
    }

    $scope.selectOrDeselectAllProductsOfType = function (column) {
        // iterate through all rows and set the current product type selected to the column.isChecked value.
        angular.forEach(
            $scope.filterProductAccounts(
                $scope.assignProductsAndAccountsList.accountProducts,
                $scope.assignProductsAndAccountsSearchText
            ),
            rowItem => {
                if (rowItem.isVisible === true) {
                    // iterate through all products until we find the right product type
                    angular.forEach(rowItem.productFeatures, product => {
                        if (
                            product.productFeature.name === column.name &&
                            (rowItem.account.account.coreStatus === 'Active' ||
                                (rowItem.account.account.coreStatus !== 'Active' &&
                                    product.isSelected !== null &&
                                    product.isSelected !== undefined &&
                                    product.isSelected === true &&
                                    column.isChecked === false))
                        ) {
                            product.isSelected = column.isChecked;
                        }
                    });
                }
            }
        );
        verifyIsAllChecked();
    };

    $scope.selectAllProductsForAccount = function (account) {
        // have to check if all products are already selected, in that case we set isToggled to false so that it un-checks.
        let isAllChecked = true;
        angular.forEach(account.productFeatures, product => {
            if (!product.isSelected) {
                isAllChecked = false;
            }
        });
        if (isAllChecked) {
            account.isToggled = false;
        } else {
            account.isToggled = !account.isToggled;
        }

        angular.forEach(account.productFeatures, product => {
            if (account.account.account.coreStatus !== 'Active') {
                product.isSelected = false;
            } else {
                product.isSelected = account.isToggled;
            }
        });
        verifyIsAllChecked();
    };

    function assignProductsAndAccounts() {
        companiesService
            .updateProductsAndAccounts($scope.companyId, $scope.assignProductsAndAccountsList)
            .then(() => {
                $modalInstance.close();
            });
    }

    function loadProductsAndAccounts() {
        companiesService.getProductsAndAccounts($scope.companyId).then(response => {
            $scope.assignProductsAndAccountsList = response;
            // Perform an initial sort on the list so that the secondary sort will not be random.
            $scope.assignProductsAndAccountsList.accountProducts = $filter('orderBy')(
                $scope.assignProductsAndAccountsList.accountProducts,
                $scope.orderByFunction,
                $scope.reverseSort
            );
            angular.forEach(
                $scope.assignProductsAndAccountsList.accountProducts,
                accountProduct => {
                    accountProduct.isVisible = true;
                }
            );
            $scope.dropdownChanged();
        });
    }

    $scope.dropdownChanged = function () {
        angular.forEach($scope.assignProductsAndAccountsList.accountProducts, accountProduct => {
            if ($scope.selectedCifNumber === 'Select All') {
                accountProduct.isVisible = true;
            } else if (accountProduct.account.cifNumber === $scope.selectedCifNumber) {
                accountProduct.isVisible = true;
            } else {
                accountProduct.isVisible = false;
            }
        });
        verifyIsAllChecked();
    };

    function verifyIsAllChecked() {
        angular.forEach($scope.assignProductsAndAccountsList.products, product => {
            let isAllProductsChecked = true;
            angular.forEach(
                $scope.assignProductsAndAccountsList.accountProducts,
                accountProduct => {
                    if (accountProduct.isVisible) {
                        angular.forEach(accountProduct.productFeatures, productFeature => {
                            if (
                                product.id === productFeature.productFeature.id &&
                                accountProduct.account.account.coreStatus === 'Active'
                            ) {
                                if (
                                    productFeature.isSelected === null ||
                                    productFeature.isSelected === undefined ||
                                    productFeature.isSelected === false
                                ) {
                                    isAllProductsChecked = false;
                                }
                            }
                        });
                    }
                }
            );
            product.isChecked = isAllProductsChecked;
        });
    }

    function init() {
        // Get Associated Companies
        companiesService.getAssociatedCompanies(companyId).then(response => {
            $scope.cifNumbers = [];
            $scope.cifNumbers.push('Select All');
            $scope.cifNumbers.push($scope.cifNumber);
            angular.forEach(response, item => {
                $scope.cifNumbers.push(item.cifNumber);
            });

            // TEMPORARY WHILE BACKEND BUILDS OUT ASSOCIATED COMPANIES
            // $scope.selectedCifNumber = $scope.cifNumber;
            $scope.selectedCifNumber = 'Select All';
            loadProductsAndAccounts();
        });

        // set default sort order
        $scope.orderByField = 'number';
        $scope.reverseSort = false;
    }

    init();
}
