angular.module('backOffice').directive('nonBlockingSpinner', [
    function () {
        return {
            restrict: 'E',
            scope: {
                loadingText: '@',
                loadingHeight: '@',
            },
            template: require('./nonBlockingSpinner.html'),
            controller: nonBlockingSpinnerController,
        };
    },
]);

nonBlockingSpinnerController.$inject = ['$scope'];

function nonBlockingSpinnerController($scope) {
    init();

    function init() {
        if (!$scope.loadingText) {
            $scope.loadingText = 'Loading';
        }
        if (!$scope.loadingHeight) {
            $scope.loadingHeight = 'auto';
        }
    }
}
