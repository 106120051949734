import { ReportsService } from '@treasury/domain/backoffice/services/reports/reports-service';
import EntitlementsService from '@treasury/domain/backoffice/services/entitlements/entitlements-service';

export default class ReportDashboardClient {
    async getReports() {
        return ReportsService.getReports();
    }

    async deleteReport(...args) {
        return ReportsService.deleteReport(...args);
    }

    async favoriteReport(...args) {
        return ReportsService.favoriteReport(...args);
    }

    async hasEntitlement(...args) {
        return EntitlementsService.hasEntitlement(...args);
    }
}
