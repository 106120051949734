import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('accountsService', accountsService);

accountsService.$inject = ['$resource', '$q'];

function accountsService($resource, $q) {
    return {
        getAvailableAccounts,
        getToAccountPermissions,
        updateToAccountPermissions,
        getAccountTypes,
        searchUnrelatedAccounts,
    };

    function getAvailableAccounts(companyId, includeHoldingCompanies, accountType) {
        return $resource(`${getBackOfficeAPIPath()}accounts/:companyId`, {
            companyId,
            includeHoldingCompanies,
            accountType,
        }).query().$promise;
    }

    function getToAccountPermissions(fromAccountId) {
        return $resource(`${getBackOfficeAPIPath()}accounts/:id/toAccountPermissions`, {
            id: fromAccountId,
        }).query().$promise;
    }

    function updateToAccountPermissions(fromAccountId, message) {
        return $resource(
            `${getBackOfficeAPIPath()}accounts/:id/toAccountPermissions`,
            { id: fromAccountId },
            { update: { method: 'PUT', isArray: true } }
        ).update(message).$promise;
    }

    function getAccountTypes() {
        const deferred = $q.defer();
        deferred.resolve(['Checking', 'Loan', 'Savings', 'Time Deposit']);
        return deferred.promise;
    }

    function searchUnrelatedAccounts(companyId, searchModel) {
        return $resource(
            `${getBackOfficeAPIPath()}accounts/:companyId/searchunrelatedaccounts`,
            { companyId },
            { search: { method: 'POST' } }
        ).search(searchModel).$promise;
    }
}
