angular.module('backOffice').controller('ResetTokenMfaConfirmation', resetTokenMfaConfirmation);

resetTokenMfaConfirmation.$inject = ['$scope'];

function resetTokenMfaConfirmation($scope) {
    $scope.cancel = cancel;
    $scope.reset = reset;

    function cancel() {
        $scope.$close('cancel');
    }

    function reset() {
        $scope.$close('reset');
    }
}
