angular.module('backOffice').controller('ConfigureBillPayController', ConfigureBillPayController);

ConfigureBillPayController.$inject = [
    '$scope',
    '$modalInstance',
    'modalService',
    'configurationService',
    'productFeatureId',
    'canEdit',
    'toaster',
];

function ConfigureBillPayController(
    $scope,
    $modalInstance,
    modalService,
    configurationService,
    productFeatureId,
    canEdit,
    toaster
) {
    // scope
    $scope.productFeatureId = productFeatureId;
    $scope.canEdit = canEdit;
    $scope.productFeature = {};

    // scope functions
    $scope.save = save;
    $scope.cancel = cancel;
    $scope.toggleAllowIPayBusinessBillPayForMobile = toggleAllowIPayBusinessBillPayForMobile;

    // init
    function init() {
        $scope.productFeature = {
            billPayServiceName: '',
            billPayServiceUrl: '',
        };

        loadProductFeature();
    }

    function save() {
        configurationService
            .saveProductFeature($scope.productFeatureId, $scope.productFeature)
            .then(response => {
                toaster.save('Bill Pay Configuration');
                $modalInstance.close();
            });
    }

    function cancel() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel the changes?',
                submit(result) {
                    cancelModalInstance.dismiss();
                    $modalInstance.dismiss();
                },
            };
            var cancelModalInstance = modalService.showModal({}, modalOptions);
        } else {
            $modalInstance.dismiss();
        }
    }

    function toggleAllowIPayBusinessBillPayForMobile() {
        $scope.productFeature.allowIPayBusinessBillPayForMobile =
            !$scope.productFeature.allowIPayBusinessBillPayForMobile;
        $scope.form.$setDirty();
    }

    // private functions
    function loadProductFeature() {
        configurationService.getProductFeatureDetails($scope.productFeatureId).then(response => {
            $scope.productFeature = response;
        });
    }

    init();
}
