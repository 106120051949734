angular.module('backOffice').controller('WireHistoryController', wireHistoryController);

wireHistoryController.$inject = ['$scope', 'wireId', '$modalInstance', 'reportsService'];

function wireHistoryController($scope, wireId, $modalInstance, reportsService) {
    $scope.rows = [];
    $scope.wireId = wireId;

    // this will dismiss the window.
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    (function () {
        // init
        reportsService.getWireHistory($scope.wireId).then(response => {
            $scope.rows = response;
        });
    })();
}
