import { html } from 'lit';
import { map } from 'lit/directives/map.js';

interface ResponseDetailCollection {
        responseCode: number;
        responseMessage: string;
};

const renderMessage = (i: string) => html`${i}<br />`;

export const handleApiError = (responseDetailCollection: ResponseDetailCollection[], title: string) => {
    if(!Array.isArray(responseDetailCollection)) return false;
    const messages = responseDetailCollection.map(
        (error) => `${error.responseCode} - ${error.responseMessage}`
    );
    return {
        message: html`${map(messages, renderMessage)}`,
        type: 'error',
        title,
        visible: true,
        posture: 'default',
    };
};