import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('downloadService', downloadService);

downloadService.$inject = ['$http', 'FileSaver'];

function downloadService($http, FileSaver) {
    const resourceUrl = `${getBackOfficeAPIPath()}download/`;

    return {
        download,
        getDownloadOptions,
        downloadAsFile,
    };

    function getDownloadOptions(downloadPage) {
        const url = `${resourceUrl}GetDownloadOptions`;

        return $http
            .get(url, {
                params: {
                    downloadPage,
                },
            })
            .then(response => response.data);
    }

    function download(options) {
        return $http
            .post(resourceUrl, options, {
                responseType: 'arraybuffer',
            })
            .then(response => ({
                content: response.data,
                fileName: getFileName(response),
                mimeType: getMimeType(response),
            }));
    }

    function downloadAsFile(url) {
        return $http({
            url,
            method: 'GET',
            responseType: 'arrayBuffer',
        }).then(response => {
            const blob = new Blob([response.data], { type: getMimeType(response) });

            FileSaver.saveAs(blob, getFileName(response));
        });
    }

    function getMimeType(response) {
        let mimeType = response.headers('Content-Type');

        if (!mimeType) {
            mimeType = 'application/octet-stream';
        }

        return mimeType;
    }

    function getFileName(response) {
        const disposition = response.headers('Content-Disposition');

        if (!disposition) {
            return 'download';
        }

        let fileName = getHeaderValue(disposition, 'filename');

        // Strip surrounding quotes otherwise they get replaced with underscores
        if (fileName.charAt(0) === '"') {
            fileName = fileName.substring(1);
        }

        if (fileName.charAt(fileName.length - 1) === '"') {
            fileName = fileName.substring(0, fileName.length - 1);
        }

        return fileName;
    }

    function getHeaderValue(header, key) {
        const items = header.split('; ');

        for (let i = 0; i < items.length; i++) {
            const item = items[i];

            const index = item.indexOf('=');
            if (index > 0 && index !== item.length - 1) {
                const itemKey = item.substring(0, index);

                if (itemKey === key) {
                    return item.substring(index + 1, item.length);
                }
            }
        }

        return null;
    }
}
