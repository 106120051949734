'user strict';

angular.module('backOffice').directive('lockedUser', () => ({
    scope: {
        isLocked: '=',
        isAuthenticationLocked: '=',
        isMfaLocked: '=',
        isTokenMfaLocked: '=',
        authenticationMethodId: '=',
    },
    template: require('./lockedUserTemplate.html'),
    controller: lockedUserController,
}));

lockedUserController.$inject = ['$scope'];

function lockedUserController($scope) {
    $scope.showLockedIcon = showLockedIcon;

    switch ($scope.authenticationMethodId) {
        case 1:
            $scope.authenticationMethod = 'Out-of-Band';
            break;
        case 2:
            $scope.authenticationMethod = 'Secure Token';
            break;
        default:
            $scope.authenticationMethod = '';
            break;
    }

    function showLockedIcon() {
        return (
            $scope.isLocked ||
            $scope.isAuthenticationLocked ||
            $scope.isMfaLocked ||
            $scope.isTokenMfaLocked
        );
    }
}
