angular
    .module('backOffice')
    .controller('AuthenticateSecureTokenController', AuthenticateSecureTokenController);

AuthenticateSecureTokenController.$inject = [
    '$state',
    'secureTokenService',
    'loginStatus',
    'loginStateService',
    'secureTokenStatus',
];

function AuthenticateSecureTokenController(
    $state,
    secureTokenService,
    loginStatus,
    loginStateService,
    secureTokenStatus
) {
    const vm = this;

    vm.isTokenLocked = false;
    vm.message = null;
    vm.passwordAndPin = null;

    vm.verify = verify;
    vm.returnToLogin = returnToLogin;

    function verify() {
        secureTokenService.authenticate(vm.passwordAndPin).then(response => {
            if (response.status === loginStatus.ALLOW && loginStateService.nextState()) {
                return;
            }

            if (
                response.status === loginStatus.SECURE_TOKEN_LOCKED ||
                response.status === loginStatus.SECURE_TOKEN_DISABLED
            ) {
                vm.isTokenLocked = true;
            }

            vm.passwordAndPin = null;
            vm.message = response.message;
            vm.form.$setPristine(true);
        });
    }

    function returnToLogin() {
        $state.go('login');
    }

    (function () {
        if (!loginStateService.validateCurrentState()) {
            return;
        }

        const status = loginStateService.getSecureTokenStatus();

        if (status === secureTokenStatus.LOCKED) {
            vm.isTokenLocked = true;
            vm.message = 'Unable to verify your identity. Please contact your administrator.';
        }
    })();
}
