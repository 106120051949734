import { EntitlementsService } from '@treasury/domain/backoffice/services';
import { ReportsService } from '@treasury/domain/backoffice/services/reports/reports-service';
import { UsersService } from '@treasury/domain/backoffice/services/users/users-service.ts';
import ReportFactory from '../data/report-factory.js';

const reportFactory = new ReportFactory();

export default class ReportClient {
    constructor() {
        this.redesignedReports = ['achNotificationOfChange', 'achReturn', 'transferPaymentReport'];
    }

    async hasEntitlement(entitlement) {
        return EntitlementsService.hasEntitlement(entitlement);
    }

    async getReportColumns(...args) {
        return ReportsService.getReportColumns(...args);
    }

    async useRedesignFilterModel(id) {
        return this.redesignedReports.includes(id);
    }

    async getReportFilters(...args) {
        let response;
        /**
         * 2 is the id for the transfer report temporary test of the new filter contract
         */
        if (this.redesignedReports.includes(args[0])) {
            response = await ReportsService.getCascadingReportFilters(args[1]);
        } else {
            response = await ReportsService.getReportFilters(...args);
        }
        if (this.metaData.isCustomReport) {
            const customFilters = await this.getCustomReportFilters(...args);
            return [response, customFilters];
        }
        return [response, null];
    }

    async getCustomReportFilters(...args) {
        return ReportsService.getCustomReportFilters(...args);
    }

    async getDownloadType(...args) {
        return ReportsService.getDownloadType(...args);
    }

    async getDownloadOptions(...args) {
        this.downloadOptionsResponse = await ReportsService.getDownloadOptions(...args);
        return reportFactory.buildDownloadOptions(this.downloadOptionsResponse);
    }

    async getReportMetaData(...args) {
        this.metaData = await ReportsService.getReportMetaData(...args);
        return this.metaData;
    }

    async runReport(...args) {
        return ReportsService.runReport(...args);
    }

    async saveReport(...args) {
        return ReportsService.saveReport(...args);
    }

    async saveUserActivity(...args) {
        return UsersService.saveUserActivity(...args);
    }

    async downloadReport(...args) {
        return ReportsService.downloadReport(...args);
    }

    async downloadAttachment(...args) {
        return ReportsService.downloadAttachment(...args);
    }

    async getWireHistory(...args) {
        return ReportsService.getWireHistory(...args);
    }

    async getWireDetails(...args) {
        return ReportsService.getWireDetails(...args);
    }

    async saveWireDetails(...args) {
        return ReportsService.saveWireDetails(...args);
    }

    async downloadWireDetails(...args) {
        return ReportsService.downloadWireDetails(...args);
    }
}
