import moment from 'moment';

angular.module('backOffice').controller('ViewExchangeRatesController', viewExchangeRatesController);

viewExchangeRatesController.$inject = [
    '$scope',
    '$filter',
    '$modalInstance',
    'effectiveDate',
    'rates',
    'utilityService',
];

function viewExchangeRatesController(
    $scope,
    $filter,
    $modalInstance,
    effectiveDate,
    rates,
    utilityService
) {
    $scope.rates = $filter('orderBy')(rates, 'countryName');
    $scope.close = close;
    $scope.filter = filter;
    $scope.filteredRates = $scope.rates;
    $scope.effectiveDate = moment(effectiveDate).format('MMM DD, YYYY');

    function close() {
        $modalInstance.dismiss();
    }

    function filter(filterText) {
        if (filterText) {
            $scope.filteredRates = $scope.rates.filter(
                rate =>
                    utilityService.containsText(rate.countryName, filterText) ||
                    utilityService.containsText(rate.countryCode, filterText) ||
                    utilityService.containsText(rate.baseCurrency, filterText) ||
                    utilityService.containsText(rate.exchangeCurrency, filterText) ||
                    utilityService.containsText(rate.rate, filterText)
            );
        } else {
            $scope.filteredRates = $scope.rates;
        }
    }
}
