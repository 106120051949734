import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('secureTokenService', secureTokenService);

secureTokenService.$inject = ['$http'];

function secureTokenService($http) {
    const resourceUrl = `${getBackOfficeAPIPath()}account/`;

    const service = {
        getSecureTokenConfiguration,
        authenticate,
        register,
    };

    return service;

    function getSecureTokenConfiguration() {
        const url = `${resourceUrl}securetokencredentials`;

        return $http.get(url).then(response => response.data);
    }

    function register(tokenConfiguration) {
        const url = `${resourceUrl}registersecuretoken`;

        return $http.post(url, tokenConfiguration).then(response => response.data);
    }

    function authenticate(oneTimePasswordAndPin) {
        const url = `${resourceUrl}authenticatesecuretoken`;

        return $http.post(url, { oneTimePasswordAndPin }).then(response => response.data);
    }
}
