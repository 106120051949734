angular
    .module('backOffice')
    .controller('WireTransferDliUserLimitsController', wireTransferDliUserLimitsController);

wireTransferDliUserLimitsController.$inject = [
    '$scope',
    '$filter',
    'toaster',
    'companyUsersService',
];

function wireTransferDliUserLimitsController($scope, $filter, toaster, companyUsersService) {
    $scope.isSuperUser = $scope.$parent.userHeader.isSuperUser;
    $scope.searchText = '';
    $scope.filteredAccountsLimits = {};
    $scope.userLimits = {};
    $scope.userLimitsPristine = {};

    $scope.reset = reset;
    $scope.search = search;
    $scope.save = save;
    $scope.setForm = setForm;
    $scope.sumCoreAccountDailyLimit = 0;

    function init() {
        loadUserLimits();
    }

    function save() {
        $scope.userLimits.ProductType = 'WireTransferDLI';
        companyUsersService
            .updateProductLimit($scope.companyId, $scope.id, $scope.userLimits)
            .then(response => {
                $scope.userLimitsPristine = angular.copy($scope.userLimits);
                $scope.$parent.notifySave();
                $scope.form.$setPristine();
                toaster.save('Fx Wire User Limits');
            });
    }

    function reset() {
        $scope.userLimits = angular.copy($scope.userLimitsPristine);
        search('');
        $scope.form.$setPristine();
    }

    function setForm(form) {
        $scope.form = form;
    }

    // Events
    function search(text) {
        $scope.searchText = text || '';
        if ($scope.userLimits !== null) {
            $scope.filteredAccountsLimits = $filter('filter')(
                $scope.userLimits.userAccountLimits,
                $scope.searchText
            );
        }
    }

    // Private
    function loadUserLimits() {
        companyUsersService
            .getProductLimit($scope.companyId, $scope.id, 'WireTransferDLI')
            .then(response => {
                $scope.userLimits = response;
                $scope.userLimitsPristine = angular.copy($scope.userLimits);
                sumCoreAccountDailyLimit();
                search('');
            });
    }

    $scope.isError = function (column, error) {
        return !!error;
    };

    $scope.getMinimum = function (amounts) {
        if (!Array.isArray(amounts)) {
            return amounts;
        }
        return Math.min.apply(null, amounts);
    };

    function sumCoreAccountDailyLimit() {
        if (
            !!$scope.userLimits &&
            !!$scope.userLimits.userAccountLimits &&
            $scope.userLimits.userAccountLimits.length > 0
        ) {
            $scope.sumCoreAccountDailyLimit = $scope.userLimits.userAccountLimits
                .map(limit => limit.userAccount.account.dailyLimit)
                .reduce((curr, prev) => curr + prev);
        }
    }

    $scope.sumUserPendingAccountDailyLimit = function () {
        if (
            !!$scope.userLimits &&
            !!$scope.userLimits.userAccountLimits &&
            $scope.userLimits.userAccountLimits.length > 0
        ) {
            return $scope.userLimits.userAccountLimits
                .map(limit => limit.pendingUserAccountLimit.dailyLimit)
                .reduce((curr, prev) => curr + prev);
        }
        return 0;
    };

    $scope.getLimitForTotalDailyLimit = function () {
        if ($scope.isSuperUser) {
            return $scope.userLimits.productDailyLimit;
        }
        return $scope.getMinimum(
            $scope.sumUserPendingAccountDailyLimit(),
            $scope.userLimits.productDailyLimit
        );
    };

    init();
}
