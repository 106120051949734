import moment from 'moment';

angular.module('backOffice').factory('utilityService', utilityService);

utilityService.$inject = ['$q'];

function utilityService($q) {
    var dateFormat = dateFormat;

    return {
        getCurrentDay,
        getNextBusinessDay,
        getMinimum,
        containsText,
    };

    function getCurrentDay() {
        const deferred = $q.defer();
        const { promise } = deferred;
        const currentDate = moment().format(dateFormat);
        deferred.resolve(currentDate);
        return promise;
    }

    function getNextBusinessDay(holidays, disableToday) {
        const deferred = $q.defer();
        const { promise } = deferred;
        let currentDate = disableToday
            ? moment().add(1, 'd').format(dateFormat)
            : moment().format(dateFormat);
        while (
            holidays &&
            (holidays.reduce(
                (acc, val) => moment(val).format(dateFormat) === currentDate || acc,
                false
            ) ||
                moment(currentDate).day() === 0 ||
                moment(currentDate).day() === 6)
        ) {
            currentDate = moment(currentDate).add(1, 'd').format(dateFormat);
        }
        deferred.resolve(currentDate);
        return promise;
    }

    function getMinimum(amounts) {
        if (!Array.isArray(amounts)) {
            return amounts;
        }
        return Math.min.apply(null, amounts);
    }

    function containsText(target, search) {
        return target.toString().toUpperCase().indexOf(search.toUpperCase()) >= 0;
    }
}
