angular.module('backOffice').controller('CopyUserModalController', copyUserModalController);

copyUserModalController.$inject = [
    '$scope',
    'companyUsersService',
    '$modalInstance',
    '$filter',
    'companyId',
];

function copyUserModalController($scope, companyUsersService, $modalInstance, $filter, companyId) {
    $scope.searchText = '';
    $scope.filterList = filterList;
    $scope.copyUser = copyUser;
    $scope.userList = [];
    $scope.filteredUserList = [];
    $scope.cancel = cancel;
    $scope.companyId = companyId;
    $scope.helpText =
        "Selecting a user will duplicate all of their access, entitlements and limits to your new user's account. This includes IP, Time Access and daily limits per account.";

    function cancel() {
        $modalInstance.dismiss();
    }

    function copyUser(user) {
        $modalInstance.close(user);
    }

    function filterList(searchText) {
        $scope.searchText = searchText || '';
        $scope.filteredUserList = $filter('filter')($scope.userList, filterRows);
    }

    function filterRows(row) {
        if (
            !$scope.searchText ||
            $scope.searchText.trim() === '' ||
            !$scope.userList ||
            $scope.userList.length <= 0
        ) {
            return true;
        }

        return (
            (row.userId &&
                row.userId.toLowerCase().indexOf($scope.searchText.toLowerCase()) !== -1) ||
            (row.userName &&
                row.userName.toLowerCase().indexOf($scope.searchText.toLowerCase()) !== -1) ||
            (row.roles && row.roles.toLowerCase().indexOf($scope.searchText.toLowerCase()) !== -1)
        );
    }

    function loadUsers() {
        companyUsersService
            .searchUsers(
                {
                    activeUsersOnly: true,
                    enrolledUsersOnly: true,
                    includeRoles: true,
                },
                $scope.companyId
            )
            .then(response => {
                $scope.userList = response.map(user => {
                    user.roles = [];
                    if (user.isAdmin) {
                        user.roles.push('Admin');
                    }
                    if (user.isSuperUser) {
                        user.roles.push('Super User');
                    }
                    user.roles = user.roles.join(', ');
                    return user;
                });
                $scope.userList = $filter('orderBy')($scope.userList, 'userId');
                filterList('');
            });
    }

    (function () {
        // init
        loadUsers();
    })();
}
