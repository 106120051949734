angular
    .module('backOffice')
    .controller('ManageTransactionCodeGroupsController', manageTransactionCodeGroupsController);

manageTransactionCodeGroupsController.$inject = [
    '$scope',
    '$modalInstance',
    'transactionCodeGroupsService',
    'transactionCodeList',
    'modalService',
    'toaster',
];

function manageTransactionCodeGroupsController(
    $scope,
    $modalInstance,
    transactionCodeGroupsService,
    transactionCodeList,
    modalService,
    toaster
) {
    $scope.groupInEdit = null;
    $scope.transactionCodeList = transactionCodeList;
    $scope.transactionCodeGroupsList = [];
    $scope.scroll = { notInEditMode: true };

    // this will dismiss the window.
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    // we watch for form.$dirty, this is used to alert the end user that they changed something in the form.
    $scope.$watch('form.$dirty', isDirty => {
        $modalInstance.isDirty = isDirty;
    });

    // used to set the form instance.
    $scope.setForm = function (form) {
        $scope.form = form;
    };

    $scope.add = function () {
        $scope.transactionCodeGroupsList.push({ id: -1, isReadOnly: false, inEditMode: true });
        $scope.scroll.notInEditMode = false;
        if (
            $scope.transactionCodeGroupsList.length > 0 &&
            $scope.transactionCodeGroupsList[$scope.transactionCodeGroupsList.length - 1].id === -1
        ) {
        } else {
            $scope.transactionCodeGroupsList.push({
                id: -1,
                isReadOnly: false,
                inEditMode: true,
            });
        }
    };

    init();

    function init() {
        loadTransactionCodeGroups();
    }

    $scope.cancelEdit = function (item) {
        $scope.collapseOthers();
        if (item.id === -1) {
            $scope.transactionCodeGroupsList.splice(
                $scope.transactionCodeGroupsList.indexOf(item),
                1
            );
        } else {
            item.inEditMode = false;
            item.name = $scope.groupInEdit.name;
        }
        $scope.scroll.notInEditMode = true;
        $scope.form.$setPristine();
    };

    function loadTransactionCodeGroups() {
        transactionCodeGroupsService.getTransactionCodeGroups().then(response => {
            $scope.transactionCodeGroupsList = response;
        });
    }

    $scope.save = function (item) {
        if (item.id === -1) {
            saveGroup(item);
        } else {
            updateGroup(item);
        }
        $scope.scroll.notInEditMode = true;
        $scope.form.$setPristine();
    };

    function saveGroup(item) {
        transactionCodeGroupsService.saveTransactionCodeGroup(item).then(response => {
            toaster.save('Transaction Code Group');
            item.inEditMode = false;
            item.id = response.id;
            $scope.scroll.notInEditMode = true;
        });
    }

    function updateGroup(item) {
        transactionCodeGroupsService.updateTransactionCodeGroup(item.id, item).then(response => {
            toaster.save('Transaction Code Group');
            item.inEditMode = false;
        });
    }

    $scope.closeSuccess = function () {
        $modalInstance.close();
    };

    $scope.delete = function (group) {
        const transactionCodes = getGroupTransactionCodes(group);

        let transactionCodesText = 'NONE';

        if (transactionCodes.length > 0) {
            transactionCodesText = transactionCodes.join(', ');
        }

        const text = `This group currently has the following transaction codes assigned to it. (${transactionCodesText}) Are you sure you want to delete this group? `;
        const modalOptions = {
            bodyText: text,
            submit(result) {
                transactionCodeGroupsService.deleteTransactionCode(group.id).then(response => {
                    toaster.delete('Transaction Code Group');
                    $scope.transactionCodeGroupsList.splice(
                        $scope.transactionCodeGroupsList.indexOf(group),
                        1
                    );
                    $modalInstance.close(result);
                });
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    };

    $scope.collapseOthers = function () {
        angular.forEach($scope.transactionCodeGroupsList, item => {
            item.inEditMode = false;
        });
    };

    $scope.edit = function (group) {
        $scope.scroll.notInEditMode = false;
        $scope.collapseOthers();
        $scope.groupInEdit = angular.copy(group);
        group.inEditMode = true;
    };

    function getGroupTransactionCodes(group) {
        const transactionCodes = [];

        angular.forEach($scope.transactionCodeList, transactionCode => {
            let hasGroup = false;

            angular.forEach(transactionCode.groups, currentGroup => {
                if (currentGroup.id === group.id) {
                    hasGroup = true;
                }
            });

            if (hasGroup === true) {
                transactionCodes.push(transactionCode.code);
            }
        });

        return transactionCodes;
    }
}
