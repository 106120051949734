angular.module('backOffice').directive('ngPhone', () => ({
    link(scope, element, attrs, ngModel) {
        $(element).keydown(event => {
            const character = event.key;
            if (
                !(character >= '0' && character <= '9') &&
                character !== 'Backspace' &&
                character !== 'Tab' &&
                character !== 'ArrowLeft' &&
                character !== 'ArrowRight'
            ) {
                event.preventDefault();
            }
        });
    },
}));
