import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('checkImageService', checkImageService);

checkImageService.$inject = ['$resource'];

function checkImageService($resource) {
    const resourceUrl = `${getBackOfficeAPIPath()}checkimages/`;

    return {
        getDepositSet,
        getCheckImage,
    };

    function getDepositSet(depositImageNumber) {
        return $resource(`${resourceUrl}deposit`, {
            depositImageNumber,
        }).query().$promise;
    }

    function getCheckImage(checkImageNumber) {
        return $resource(
            `${resourceUrl}check`,
            { checkImageNumber },
            { get: { method: 'GET', isArray: false } }
        ).get().$promise;
    }
}
