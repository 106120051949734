import { LitElement, html, css } from 'lit';
import '@treasury/omega/components/progress/omega-progress.js';

class BlockingLoader extends LitElement {
    static get properties() {
        return {};
    }

    render() {
        return html` <omega-progress card class="light-loader"></omega-progress> `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: var(--omega-dialog-overlay);
                opacity: 0;
                height: 100%;
                width: 100%;
                display: block;
                opacity: 1;
                visibility: visible;
                z-index: var(--omega-loader-z-index, 99);
            }
            .light-loader {
                color: #fff;
                width: 50px;
                height: 50px;
                border-width: 5px;
                margin-top: 35%;
            }
        `;
    }
}

customElements.define('blocking-loader', BlockingLoader);
