angular
    .module('backOffice')
    .controller('CustomizeRemoteDepositCaptureController', CustomizeRemoteDepositCaptureController);

CustomizeRemoteDepositCaptureController.$inject = [
    '$state',
    '$stateParams',
    'modalService',
    'companiesService',
    'toaster',
    'companyTypes',
    'remoteDepositTypes',
    'configurationService',
];

function CustomizeRemoteDepositCaptureController(
    $state,
    $stateParams,
    modalService,
    companiesService,
    toaster,
    companyTypes,
    remoteDepositTypes,
    configurationService
) {
    const vm = this;
    const companyId = $stateParams.id;

    let { productFeatureId } = $stateParams;
    let isNew = productFeatureId <= 0;

    vm.goBack = goBack;
    vm.save = save;
    vm.getRemoteDepositTypeTemplateName = getRemoteDepositTypeTemplateName;
    vm.isSaveDisabled = isSaveDisabled;
    vm.requiredEntitlements = requiredEntitlements;

    function init() {
        loadCompany();
        loadProductFeature();
        loadRemoteDepositOptions();
    }

    function requiredEntitlements() {
        return $stateParams.productFeatureId === ''
            ? 'Add Product Feature'
            : 'Edit Product Feature';
    }

    function getRemoteDepositTypeTemplateName() {
        if (remoteDepositTypes.properties.hasOwnProperty(vm.remoteDepositType)) {
            return `${remoteDepositTypes.properties[vm.remoteDepositType].name.toLowerCase()}.html`;
        }

        return null;
    }

    function goBack() {
        if (vm.form.$dirty) {
            let instance;
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel the changes you have made?',
                submit() {
                    instance.dismiss();
                    $state.go('company', { id: companyId });
                },
            };

            instance = modalService.showModal({}, modalOptions);
        } else {
            $state.go('company', { id: companyId });
        }
    }

    function save() {
        const product = {
            type: 'RemoteDeposits',
            ssoCustomerId: vm.customerId,
            remoteDepositProductTypeId: vm.remoteDepositType,
        };

        let promise;

        if (isNew) {
            promise = companiesService
                .addRemoteDepositProduct(companyId, product)
                .then(response => {
                    productFeatureId = response.id;
                    isNew = false;
                });
        } else {
            product.id = productFeatureId;

            promise = companiesService.editRemoteDepositProduct(companyId, product);
        }

        promise.then(() => {
            toaster.save('Remote Deposit Capture Configuration');
            vm.form.$setPristine();
        });
    }

    function isSaveDisabled() {
        return vm.form.$invalid || !vm.form.$dirty;
    }

    function loadProductFeature() {
        if (isNew) {
            configurationService
                .getProductFeatureDetailsByName('RemoteDeposits')
                .then(institutionProduct => {
                    setDefaultRemoteDepositType(institutionProduct);
                });
        } else {
            return companiesService
                .getProductPermissions(companyId, productFeatureId)
                .then(product => {
                    vm.remoteDepositType = product.remoteDepositProductTypeId;
                    vm.customerId = product.ssoCustomerId;
                });
        }
    }

    function loadCompany() {
        return companiesService.getCompanyName(companyId, companyTypes.Parent).then(response => {
            vm.company = response;
        });
    }

    function loadRemoteDepositOptions() {
        return companiesService.getAvailableRemoteDepositProductTypes().then(productTypes => {
            vm.availableRemoteDepositProductTypeIds = productTypes;

            initializeRemoteDepositOptions();
        });
    }

    function initializeRemoteDepositOptions() {
        vm.remoteDepositOptions = [];

        if (
            !vm.availableRemoteDepositProductTypeIds ||
            vm.availableRemoteDepositProductTypeIds.length === 0
        ) {
            return;
        }

        for (let index = 0; index < vm.availableRemoteDepositProductTypeIds.length; index++) {
            const type = vm.availableRemoteDepositProductTypeIds[index];
            const properties = remoteDepositTypes.properties[type];

            if (properties) {
                vm.remoteDepositOptions.push(properties);
            }
        }

        vm.remoteDepositOptions.sort((a, b) => a.order - b.order);
    }

    function setDefaultRemoteDepositType(institutionProduct) {
        if (!institutionProduct.ssoLogins) {
            return;
        }

        const activeSsoLogins = institutionProduct.ssoLogins.filter(
            login => login.isActive === true
        );

        if (activeSsoLogins.length !== 1) {
            return;
        }

        const ssoLogin = activeSsoLogins[0];

        Object.values(remoteDepositTypes.properties).forEach(property => {
            if (property.ssoLoginSystemId === ssoLogin.ssoLoginSystemId) {
                vm.remoteDepositType = property.value;

                vm.form.$setDirty(true);
            }
        });
    }

    init();
}
