import moment from 'moment';

angular
    .module('backOffice')
    .controller('ReenableUserAuthenticationController', reenableUserAuthenticationController);

reenableUserAuthenticationController.$inject = [
    '$scope',
    'challengeSettings',
    'companyId',
    'companiesService',
    '$modalInstance',
    'toaster',
    'authenticationMethod',
];

function reenableUserAuthenticationController(
    $scope,
    challengeSettings,
    companyId,
    companiesService,
    $modalInstance,
    toaster,
    authenticationMethod
) {
    $scope.authenticationMethod = authenticationMethod;
    $scope.challengeSettings = challengeSettings;
    $scope.cancel = cancel;
    $scope.getAuthenticationSuspendedText = getAuthenticationSuspendedText;
    $scope.reenable = reenable;
    $scope.setForm = setForm;

    init();

    function init() {}

    function reenable() {
        companiesService
            .reenableUserAuthentication($scope.challengeSettings.userId, companyId)
            .then(() => {
                toaster.success(`${authenticationMethod} restored.`);
                $modalInstance.close();
            });
    }

    function cancel() {
        $modalInstance.dismiss();
    }

    function setForm(form) {
        $scope.form = form;
    }

    function getAuthenticationSuspendedText() {
        if (!$scope.challengeSettings.suspendEndTime) {
            return '';
        }
        return `Authentication is suspended for this user until ${moment(
            $scope.challengeSettings.suspendEndTime
        )
            .calendar()
            .toLowerCase()}.`;
    }
}
