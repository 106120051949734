angular.module('backOffice').controller('AddProductController', addProductController);

addProductController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'companiesService',
    'configurationService',
    '$modalInstance',
    'companyId',
    'entitlementsService',
    'modalService',
    'toaster',
    'entitlementsService',
];

function addProductController(
    $scope,
    $state,
    $stateParams,
    companiesService,
    configurationService,
    $modalInstance,
    companyId,
    entitlementsService,
    modalService,
    toaster
) {
    const selectPrompt = { name: 'Please Select Product Feature', id: 0, type: 'undefined' }; // const
    const CompanyId = $stateParams.id;
    $scope.productFeatureId = $stateParams.productFeatureId;
    $scope.thresholdOptions = {
        useWireLimitThresholds: false,
    };

    $scope.allowedEntitlement = 'Add Product Feature';
    $scope.toggleCbox = { checked: false };
    $scope.selectedProduct = {};
    $scope.toggleFromAccountType = toggleFromAccountType;
    $scope.toggleAccount = toggleAccount;
    $scope.companyId = companyId;
    $scope.showHeaderAndButtons = false;
    $scope.approvalList = [{ name: 'None' }, { name: '1' }, { name: '2' }, { name: '3' }];
    $scope.authenticationTypeList = [{ name: 'None' }, { name: 'Password' }, { name: 'Token' }];
    $scope.accounts = [
        {
            id: 1,
            name: 'Checking',
            selectAll: false,
        },
        {
            id: 2,
            name: 'Savings',
            selectAll: false,
        },
        {
            id: 3,
            name: 'Club',
            selectAll: false,
        },
        {
            id: 4,
            name: 'Time Deposit',
            selectAll: false,
        },
        {
            id: 5,
            name: 'Loans',
            selectAll: false,
        },
    ];

    $scope.filteredAccounts = [];
    $scope.toggleColumn = toggleColumn;
    $scope.addAccountToCompany = addAccountToCompany;
    $scope.accountAccessIsCollapsed = true;
    $scope.toggleAllowLoanPaymentPastDue = toggleAllowLoanPaymentPastDue;
    $scope.getQfxEnabledForCompanyFlag = getQfxEnabledForCompanyFlag;
    $scope.selectTab = selectTab;
    $scope.isTabSelected = isTabSelected;
    $scope.reloadTab = reloadTab;
    $scope.allowedToViewReportingConfiguration = allowedToViewReportingConfiguration;
    $scope.confirmAndToggleQfxForCompany = confirmAndToggleQfxForCompany;

    $scope.$watch('selectedProduct.name', onNewProductToAddNameChange);

    $scope.company = null;

    // this will dismiss the window.
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    $scope.save = function () {
        addProductToCompany();
    };

    function addProductToCompany() {
        if ($scope.selectedProduct.name === 'Transfer')
            $scope.selectedProduct.type = 'InternalTransferProduct';
        else if ($scope.selectedProduct.name === 'Reporting')
            $scope.selectedProduct.type = 'InformationReportingProductFeature';
        else if ($scope.selectedProduct.name === 'BDI eStatements')
            $scope.selectedProduct.type = 'BDIeStatements';
        else if ($scope.selectedProduct.name === 'Agiletics Web Escrow')
            $scope.selectedProduct.type = 'AgileticsSso';

        $scope.selectedProduct.status = 'Inactive';

        const selectedProduct = $scope.availableProductList.filter(
            x => x.name === $scope.selectedProduct.name
        )[0];
        if (selectedProduct) {
            $scope.selectedProduct.isConfigurable = selectedProduct.isCompanyProductConfigurable;
        }

        if ($scope.filteredAccounts) {
            $scope.selectedProduct.accountIds = $scope.filteredAccounts
                .filter(item => item.addToCompany)
                .map(item => item.id);
        }

        companiesService
            .addProductToCompany($scope.companyId, $scope.selectedProduct)
            .then(response => {
                $modalInstance.close({
                    responseProduct: response,
                    selectedProduct: $scope.selectedProduct,
                });
            });

        if ($scope.qfxEnabledForFi && $scope.selectedProduct.name === 'Reporting') {
            const newStatus = $scope.qfxEnabledForCompany ? 'Y' : 'N';
            companiesService.saveQfxCompanyFlag(newStatus, $scope.companyId).then(response => {
                toaster.save('QFX Option');
            });
        }
    }

    function toggleColumn(value) {
        angular.forEach($scope.filteredAccounts, account => {
            account.addToCompany = value;
        });
    }

    function toggleAllowLoanPaymentPastDue() {
        $scope.selectedProduct.allowLoanPaymentPastDue =
            !$scope.selectedProduct.allowLoanPaymentPastDue;
        $scope.form.$setDirty();
    }

    function addAccountToCompany() {
        if ($scope.filteredAccounts.every(element => element.addToCompany === true)) {
            $scope.toggleCbox.checked = true;
        } else {
            $scope.toggleCbox.checked = false;
        }
    }

    function setAvailableProducts(products) {
        products.unshift(selectPrompt);
        $scope.availableProductList = products;
        $scope.selectedProduct.name = selectPrompt.name;
        loadAccountTypes();
        loadAccountsFeature();
    }

    $scope.loadProductsToAdd = function () {
        companiesService.getAvailableProductsForCompany($scope.companyId).then(response => {
            setAvailableProducts(response);
        });
    };

    // used to set the form instance.
    $scope.setForm = function (form) {
        $scope.form = form;
    };

    // we watch for form.$dirty, this is used to alert the end user that they changed something in the form.
    $scope.$watch('form.$dirty', isDirty => {
        $modalInstance.isDirty = isDirty;
    });

    function toggleAccount(account) {
        angular.forEach($scope.selectedProduct.accountPermissions, permission => {
            angular.forEach(permission.fromAccountTypes, fromAccount => {
                if (fromAccount.fromAccountType === account.name) {
                    fromAccount.isAllowed = account.selectAll;
                }
            });
        });
    }

    function toggleFromAccountType(type, account) {
        let count = 0;
        angular.forEach($scope.selectedProduct.accountPermissions, permission => {
            angular.forEach(permission.fromAccountTypes, fromAccount => {
                if (fromAccount.fromAccountType === type && fromAccount.isAllowed) {
                    count += 1;
                }
            });
        });
        angular.forEach($scope.accounts, _account => {
            if (_account.name === type) {
                if (count == 5) {
                    _account.selectAll = true;
                } else {
                    _account.selectAll = false;
                }
            }
        });
    }

    function loadAccountTypes() {
        configurationService.getProductFeatureDetailsByName('internalTransfer').then(response => {
            $scope.selectedProduct.accountPermissions = response.accountPermissions;
            $scope.selectedProduct.allowLoanPaymentPastDue = response.allowLoanPaymentPastDue;

            const selectAll = [true, true, true, true, true];
            for (var i = 0; i < $scope.selectedProduct.accountPermissions.length; i++) {
                const permission = $scope.selectedProduct.accountPermissions[i];
                for (let j = 0; j < permission.fromAccountTypes.length; j++) {
                    const fromAccount = permission.fromAccountTypes[j];
                    if (!fromAccount.isAllowed) {
                        selectAll[j] = false;
                    }
                }
            }
            for (var i = 0; i < $scope.selectedProduct.accountPermissions.length; i++) {
                $scope.accounts[i].selectAll = selectAll[i];
            }
        });
    }

    function isACHFilterEnabled() {
        return entitlementsService.hasAnyEntitlement('Feature.PositivePay.ACHFilterManagement');
    }

    function loadAccountsFeature() {
        companiesService.getActiveCompanyAccounts($scope.companyId).then(response => {
            $scope.filteredAccounts = response.map(companyAccount => companyAccount.account);
        });
    }

    function loadAccounts(product) {
        companiesService.getActiveCompanyAccounts($scope.companyId).then(response => {
            if (!response) return;

            const companyAccounts = response.map(companyAccount => companyAccount.account);

            if (product.accounts) {
                companyAccounts.forEach(companyAccount => {
                    companyAccount.addToCompany = product.accounts.some(
                        productAccount => productAccount.id === companyAccount.id
                    );
                });
            }

            $scope.filteredAccounts = companyAccounts;
        });
    }

    function getQfxEnabledForCompanyFlag() {
        companiesService.getQfxEnabledFlag($scope.companyId).then(response => {
            $scope.qfxEnabledForCompany = response.enabledFlag;
        });
    }

    function checkQfxProductStatusForFi() {
        configurationService.checkQfxProductStatusForFi().then(response => {
            if (response.enabledFlag === 'Y') {
                $scope.qfxEnabledForFi = true;
            }
        });
    }

    function confirmAndToggleQfxForCompany() {
        let message;
        let modalOptions;
        let actionButtonText;
        let closeButtonText;
        message = 'Are you sure you want to change the product feature status?';
        actionButtonText = 'Yes';
        closeButtonText = 'No';

        modalOptions = {
            bodyText: message,
            actionButtonText,
            closeButtonText,
            submit(result) {
                $modalInstance.close(result);
                $scope.qfxEnabledForCompany = !$scope.qfxEnabledForCompany;
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    function getProductEntitlements() {
        companiesService
            .getProductPermissions($scope.companyId, $scope.selectedProductId)
            .then(response => {
                $scope.selectedProduct = response;
                setDefaultValues();
                if ($scope.selectedProduct.type === 'InformationReportingProductFeature') {
                    loadAccounts($scope.selectedProduct);
                }
            });
    }

    function setDefaultValues() {
        if ($scope.selectedProduct.type === 'InternalTransferProduct') {
            if (
                $scope.selectedProduct.freeTransaction === null ||
                $scope.selectedProduct.freeTransaction === undefined
            ) {
                $scope.selectedProduct.freeTransaction = 0;
            }
            if (
                $scope.selectedProduct.feePerTransaction === null ||
                $scope.selectedProduct.feePerTransaction === undefined
            ) {
                $scope.selectedProduct.feePerTransaction = '0.00';
            }
            if (
                $scope.selectedProduct.monthlyFee === null ||
                $scope.selectedProduct.monthlyFee === undefined
            ) {
                $scope.selectedProduct.monthlyFee = '0.00';
            }
            if (
                $scope.selectedProduct.dailyLimit === null ||
                $scope.selectedProduct.dailyLimit === undefined
            ) {
                $scope.selectedProduct.dailyLimit = '0.00';
            }
        }
    }

    function selectTab(tabName) {
        $scope.selectedTab = tabName;
        reloadTab(tabName);
    }

    function isTabSelected(tabName) {
        return tabName === $scope.selectedTab;
    }

    function reloadTab(selectedTab) {
        if (selectedTab === 'Reporting Eligible Accounts') {
            loadAccounts($scope.selectedProduct);
        } else if (selectedTab === 'QFX Configuration') {
            getQfxEnabledForCompanyFlag();
        }
    }

    function allowedToViewReportingConfiguration() {
        return entitlementsService.hasAnyEntitlement('Edit Product Feature Configuration');
    }

    function loadWiresProductFeature() {
        configurationService.getProductFeatureDetailsByName('WireTransferDLI').then(response => {
            $scope.productFeature = response;
            $scope.thresholdOptions.useWireLimitThresholds = !!(
                !!$scope.productFeature.paymentApprovalType &&
                $scope.productFeature.paymentApprovalType === 'Threshold'
            );
            $scope.productFeature.id = 0;
            $scope.productFeature.type = 'WireTransferDLI';
        });
    }

    function init() {
        if ($scope.availableProductList == undefined) {
            $scope.loadProductsToAdd();
        } else {
            setAvailableProducts($scope.availableProductList);
        }
        getProductEntitlements();
        checkQfxProductStatusForFi();
        getQfxEnabledForCompanyFlag();
        selectTab('Reporting Eligible Accounts');

        companiesService.getCompanyName(CompanyId).then(response => {
            $scope.company = response;
            loadWiresProductFeature();
        });
    }

    init();
    //
    // Events
    function onNewProductToAddNameChange(value) {
        if (!value) return;

        const selectedProduct = $scope.availableProductList.filter(x => x.name === value)[0];
        if (selectedProduct && selectedProduct.isCompanyProductConfigurable === false) {
            // As product is not configurable, do not want to continue to a product configuration view
            $scope.form.$setPristine();
            return;
        }

        // eslint-disable-next-line default-case
        switch (value) {
            case 'Wire':
                $modalInstance.dismiss();
                $state.go('company-wire-transfer', {
                    id: $scope.companyId,
                    type: 'wire-transfer',
                    productFeatureId: '',
                });
                break;
            case 'Wire Transfer DLI':
            case 'Foreign Currency Wire':
                $scope.productFeature.paymentApprovalType = !$scope.thresholdOptions
                    .useWireLimitThresholds
                    ? 'Overall'
                    : 'Threshold';
                $modalInstance.dismiss();
                companiesService
                    .saveProductFeature(CompanyId, $scope.productFeature)
                    .then(response => {
                        $scope.productFeature.wireCompanies = [];
                        $scope.productFeature.id = response.id;
                    });
                $state.reload();
                break;
            case 'Wire - DirectLine International':
                $modalInstance.dismiss();
                $state.go('company-wire-transfer', {
                    id: $scope.companyId,
                    type: 'company-wire-transfer-dli',
                    productFeatureId: '',
                });
                break;
            case 'Positive Pay':
                $modalInstance.dismiss();
                $state.go('company-arp', {
                    id: $scope.companyId,
                    type: 'company-arp',
                    productFeatureId: '',
                });
                break;
            case 'ACH':
                $modalInstance.dismiss();
                $state.go('company-ach', {
                    id: $scope.companyId,
                    type: 'company-ach',
                    productFeatureId: '',
                });
                break;
            case 'Stop Payment':
                $modalInstance.dismiss();
                $state.go('company-stop-payment', {
                    id: $scope.companyId,
                    type: 'stop-payment',
                    productFeatureId: '',
                });
                break;
            case 'Bill Pay':
                $modalInstance.dismiss();
                $state.go('company-bill-pay', {
                    id: $scope.companyId,
                    productFeatureId: '',
                });
                break;
            case 'Electronic Documents':
                $modalInstance.dismiss();
                $state.go('company-electronic-documents', {
                    id: $scope.companyId,
                    type: 'electronic-documents',
                    productFeatureId: '',
                });
                break;
            case 'Remote Deposit Capture':
                $modalInstance.dismiss();
                $state.go('company-remote-deposit-capture', {
                    id: $scope.companyId,
                    productFeatureId: '',
                });
                break;
            case 'Wausau eStatements':
                $modalInstance.dismiss();
                $state.go('company-wausau-estatements', {
                    id: $scope.companyId,
                    type: 'wausau-estatements',
                    productFeatureId: '',
                });
                break;
            case 'Account Reconciliation Reporting':
                $modalInstance.dismiss();
                $state.go('company-account-recon', {
                    companyId: $scope.companyId,
                    action: 'add',
                });
                break;
        }
    }
}
