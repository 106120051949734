import { DateFormatters } from '../../../shared/utilities/date-formatters.js';
import camelToTitleCase from '../../../utilities/camel-to-title-case.js';

const DATE_PERIODS = {
    today: 'Today',
    specific: 'SpecificDate',
    range: 'DateRange',
    'week-to-date': 'Week-To-Date',
    'month-to-date': 'Month-To-Date',
    'year-to-date': 'Year-To-Date',
};

const DYNAMIC_DATE_PERIODS = [
    'today',
    'week-to-date',
    'month-to-date',
    'year-to-date',
    'Today',
    'Week-To-Date',
    'Month-To-Date',
    'Year-To-Date',
];

export const mapStatus = (status, key) => {
    if (!status) return null;
    if (Array.isArray(status)) return { [key]: status.map(s => s) };
    return {
        [key]: status.id,
    };
};

export const mapAmount = (
    amount,
    keys = { type: 'amount', specific: 'amountSpecific', start: 'amountStart', end: 'amountEnd' }
) => {
    if (!amount) return null;
    const [type, start, end] = amount;
    const isRange = type.toLowerCase() === 'amountrange';
    const startAndEndAreZero = Number(start) === 0 && Number(end) === 0;
    const specificAmountIsZero = !isRange && Number(start) === 0;
    if (startAndEndAreZero || specificAmountIsZero) {
        return {
            [keys.type]: null,
        };
    }
    if (isRange) {
        return {
            [keys.type]: type,
            [keys.start]: Number(start),
            [keys.end]: Number(end),
        };
    }
    return {
        [keys.type]: type,
        [keys.specific]: Number(start),
    };
};

const getDatePeriod = dates => (dates.value.start ? DATE_PERIODS.range : DATE_PERIODS.specific);

const isDynamicDatePeriod = period => DYNAMIC_DATE_PERIODS.includes(period);

const getDateValue = (dates, period, key) => {
    if (isDynamicDatePeriod(period.id)) return null;
    return dates.value[key] ?? dates.value;
};

const ignoredDateSelection = (period, dates) =>
    Array.isArray(period) || !period || !dates || period === 'no-date-selected';

const isDateRangeSelection = dates => dates.value.start;

const getDatePeriodValue = (period, dates) => DATE_PERIODS[period.id] ?? getDatePeriod(dates);

const mapDatePeriod = (period, key) => {
    const dates = DateFormatters.parseDate(period);
    if (period === 'any-date' && key === 'originalEffectiveDate') {
        return {
            [key]: null,
            [`${key}Date`]: null,
        };
    }
    if (ignoredDateSelection(period, dates))
        return {
            [key]: null,
        };
    if (isDynamicDatePeriod(dates.value))
        return {
            [key]: DATE_PERIODS[dates.value],
        };

    if (isDateRangeSelection(dates)) {
        return {
            [key]: getDatePeriodValue(period, dates),
            [`${key}Start`]: getDateValue(dates, period, 'start'),
            [`${key}End`]: getDateValue(dates, period, 'end'),
        };
    }
    return {
        [key]: getDatePeriodValue(period, dates),
        [`${key}Date`]: getDateValue(dates, period),
    };
};

const mapAchDateType = dateType => {
    if (Array.isArray(dateType) || !dateType) return { achDateField: null };
    return {
        achDateField: dateType.id,
    };
};

const mapDateType = (parameters, customValues) => ({
    dateType: parameters?.dateType ?? customValues?.dateType,
});

const mapCheckNumber = (checkNumber) => ({    
    checkNumber: checkNumber !== "" ? checkNumber : [],
});

export const mapParametersForRequest = (parameters, customValues) => ({
    ...parameters,
    ...mapCheckNumber(parameters.checkNumber),
    ...mapDatePeriod(parameters.datePeriod, 'datePeriod'),
    ...mapDatePeriod(parameters.createdDate, 'createdDate'),
    ...mapDatePeriod(parameters.issuedDate, 'issuedDate'),
    ...mapDatePeriod(parameters.effectiveDate, 'effectiveDate'),
    ...mapDatePeriod(parameters.receivedDate, 'receivedDate'),
    ...mapDatePeriod(parameters.originalEffectiveDate, 'originalEffectiveDate'),
    ...mapDateType(parameters, customValues),
    ...mapAmount(parameters.amount),
    ...mapAmount(parameters.issuedAmount, {
        type: 'issuedAmount',
        specific: 'issuedAmountSpecific',
        start: 'issuedAmountStart',
        end: 'issuedAmountEnd',
    }),
    ...mapAmount(parameters.postedAmount, {
        type: 'postedAmount',
        specific: 'postedAmountSpecific',
        start: 'postedAmountStart',
        end: 'postedAmountEnd',
    }),
    ...mapStatus(parameters.status, 'status'),
    ...mapStatus(parameters.decisionStatus, 'decisionStatus'),
    ...mapAchDateType(parameters.achDateField),
});

export const buildSortColumns = cols =>
    cols.map(col => ({
        name: camelToTitleCase(col.field).replace(/\s/g, ''),
        descending: col.sort !== 'ascending',
        sortOrders: [true, null],
    }));

export const mapReportToRequest = (parameters, meta, columns, custom) => {
    const firstSortableColumn = columns.find(col => col.isSortable);
    return {
        pageNumber: parameters.page,
        pageSize: parameters.pageSize,
        orderBys: parameters.sort?.length
            ? buildSortColumns(parameters.sort)
            : [
                  {
                      name: firstSortableColumn?.columnName ?? columns[0].name,
                      descending: false,
                      sortOrders: [true, null],
                  },
              ],
        startIndex: parameters.startIndex,
        ...mapParametersForRequest(parameters.parameters, custom),
        reportType: meta.type,
        name: meta.name,
        reportId: meta.reportId ?? meta.id ?? 0,
    };
};

export const mapResponseToReport = response => ({
    ...response,
    data: response.data.rows ?? response.data,
    summary: Array.isArray(response.data) ? null : response.data,
});

export const mapReportToCustomReportRequest = ({ filter, name }, recordset, columns, meta) => {
    const firstSortableColumn = columns.find(col => col.isSortable);
    let reportId = 0;  // Create new custom report
    if(meta.isCustomReport) {  // Adding non-zero ID updates existing report
        reportId = meta.id
    }
    return {
        ...mapParametersForRequest(filter),
        name,
        orderBys: recordset.sort?.length
            ? buildSortColumns(columns)
            : [
                  {
                      name: firstSortableColumn?.columnName,
                      descending: false,
                      sortOrders: [true, null],
                  },
              ],
        pageNumber: recordset.pageNumber,
        pageSize: recordset.pageSize,
        reportType: meta.type,
        reportId,
    };
};
