import moment from 'moment';

const validSubmit = [
    '$parse',
    function ($parse) {
        return {
            compile() {
                return {
                    post(scope, element, iAttrs) {
                        const form = element.controller('form');
                        form.$submitted = false;
                        const fn = $parse(iAttrs.validSubmit);
                        element.on('submit', event => {
                            scope.$apply(() => {
                                element.addClass('ng-submitted');
                                form.$submitted = true;
                                fn(scope, { $event: event });
                            });
                        });
                    },
                };
            },
        };
    },
];

File.prototype.convertToBase64 = function (callback) {
    const FR = new FileReader();
    FR.onload = function (e) {
        callback(e.target.result);
    };
    FR.readAsDataURL(this);
};

const isValidDate = function (dateStr) {
    if (dateStr === undefined || dateStr === null) return false;
    const dateTime = Date.parse(dateStr);

    if (isNaN(dateTime)) {
        return false;
    }
    return true;
};

const getDateDifference = function (fromDate, toDate) {
    return Date.parse(toDate) - Date.parse(fromDate);
};

const isValidDateRange = function (fromDate, toDate) {
    if (fromDate === '' || toDate === '') return true;
    if (isValidDate(fromDate) === false) {
        return false;
    }
    if (isValidDate(toDate) === true) {
        const days = getDateDifference(fromDate, toDate);
        if (days < 0) {
            return false;
        }
    }
    return true;
};

// Date comparison - Checks if the date is lower than the passed model
angular.module('backOffice').directive('dateLowerThan', [
    '$filter',
    function ($filter) {
        return {
            require: 'ngModel',
            link(scope, elm, attrs, ctrl) {
                const validateDateRange = function (inputValue) {
                    const fromDate = $filter('date')(inputValue, 'short');
                    const toDate = $filter('date')(attrs.dateLowerThan, 'short');
                    const isValid = isValidDateRange(fromDate, toDate);
                    ctrl.$setValidity('dateLowerThan', isValid);
                    return inputValue;
                };

                ctrl.$parsers.unshift(validateDateRange);
                ctrl.$formatters.push(validateDateRange);
                attrs.$observe('dateLowerThan', () => {
                    validateDateRange(ctrl.$viewValue);
                });
            },
        };
    },
]);

// Date comparison - Checks if the date is greater than the passed model
angular.module('backOffice').directive('dateGreaterThan', [
    '$filter',
    function ($filter) {
        return {
            require: 'ngModel',
            link(scope, elm, attrs, ctrl) {
                const validateDateRange = function (inputValue) {
                    const fromDate = $filter('date')(attrs.dateGreaterThan, 'short');
                    const toDate = $filter('date')(inputValue, 'short');
                    const isValid = isValidDateRange(fromDate, toDate);
                    ctrl.$setValidity('dateGreaterThan', isValid);
                    return inputValue;
                };

                ctrl.$parsers.unshift(validateDateRange);
                ctrl.$formatters.push(validateDateRange);
                attrs.$observe('dateGreaterThan', () => {
                    validateDateRange(ctrl.$viewValue);
                });
            },
        };
    },
]);

// Date validation - Checks if the date is valid.
angular.module('backOffice').directive('isValidDate', [
    '$filter',
    function () {
        return {
            require: 'ngModel',
            link(scope, elm, attrs, ctrl) {
                const validateDate = function (inputValue) {
                    let isValid = true;
                    if (inputValue !== null && inputValue !== undefined && inputValue !== '') {
                        try {
                            let split;
                            if (inputValue.indexOf('_') > -1) {
                                isValid = false;
                            } else if (inputValue.indexOf('-') > -1) {
                                split = inputValue.split('-');
                                if (
                                    split.length < 3 ||
                                    (split.length == 3 &&
                                        (split[2].length === 0 ||
                                            split[0].length === 0 ||
                                            split[1].length === 0))
                                ) {
                                    isValid = false;
                                } else if (split[0].length == 4)
                                    isValid = moment(inputValue, 'YYYY-MM-DD').isValid();
                                else isValid = moment(inputValue, 'MM-DD-YYYY').isValid();
                            } else if (inputValue.indexOf('/') > -1) {
                                split = inputValue.split('/');
                                if (
                                    split.length < 3 ||
                                    (split.length == 3 &&
                                        (split[2].length === 0 ||
                                            split[0].length === 0 ||
                                            split[1].length === 0))
                                ) {
                                    isValid = false;
                                } else if (split[0].length == 4)
                                    isValid = moment(inputValue, 'YYYY-MM-DD').isValid();
                                else isValid = moment(inputValue, 'MM-DD-YYYY').isValid();
                            } else {
                                isValid = false;
                            }
                        } catch (err) {
                            isValid = false;
                        }
                    }
                    ctrl.$setValidity('isValidDate', isValid);
                    return inputValue;
                };
                ctrl.$parsers.unshift(validateDate);
                ctrl.$formatters.push(validateDate);
            },
        };
    },
]);

angular.module('backOffice').directive('validSubmit', validSubmit);

angular.module('backOffice').directive('syncFocus', () => ({
    restrict: 'A',
    scope: {
        focusValue: '=syncFocus',
    },
    link($scope, $element) {
        $scope.$watch('focusValue', (currentValue, previousValue) => {
            if (currentValue === true && !previousValue) {
                $element[0].focus();
            } else if (currentValue === false && previousValue) {
                $element[0].blur();
            }
        });
    },
}));

angular.module('backOffice').directive('onEnterKey', () =>
    // don't prefix custom directive with ng-
    ({
        restrict: 'A',
        scope: {
            action: '&onEnterKey',
        },
        link(scope, element, attrs) {
            element.on('keydown keypress', event => {
                if (attrs && event.which === 13) {
                    scope.$apply(scope.action);
                    event.preventDefault();
                }
            });
        },
    })
);

angular.module('backOffice').directive('numericInput', () => ({
    restrict: 'A',
    link(scope, element, attrs) {
        element.numeric();
    },
}));

angular.module('backOffice').directive('ignoreDirty', () => ({
    restrict: 'A',
    require: 'ngModel',
    link(scope, element, attrs, ctrl) {
        angular.element(element).addClass('ignore-dirty');
        ctrl.$setPristine = function () {};
        ctrl.$pristine = false;
    },
}));

angular.module('backOffice').directive('lessThanOrEqual', () => ({
    require: 'ngModel',
    link($scope, $element, $attrs, ctrl) {
        const validate = function (modelValue) {
            const originalModelValue = modelValue;
            if (typeof modelValue === 'string') {
                modelValue = Number(modelValue.replace(/[^0-9-\.]+/g, ''));
            }
            let comparisonModel = $attrs.lessThanOrEqual;
            if (typeof comparisonModel === 'string') {
                comparisonModel = Number(comparisonModel.replace(/[^0-9-\.]+/g, ''));
            }
            // It's valid if model is <= the model we're comparing against
            if (isNaN(modelValue)) {
                ctrl.$setValidity('lessThanOrEqual', true);
            } else {
                ctrl.$setValidity('lessThanOrEqual', modelValue <= comparisonModel);
            }
            return originalModelValue;
        };
        ctrl.$parsers.push(validate);
        ctrl.$formatters.push(validate);

        $attrs.$observe('lessThanOrEqual', () =>
            // Whenever the comparison model changes we'll re-validate
            validate(ctrl.$modelValue)
        );
    },
}));

angular.module('backOffice').directive('greaterThan', () => ({
    require: 'ngModel',
    link($scope, $element, $attrs, ctrl) {
        const validate = function (modelValue) {
            const originalModelValue = modelValue;
            if (typeof modelValue === 'string') {
                modelValue = Number(modelValue.replace(/[^0-9-\.]+/g, ''));
            }
            let comparisonModel = $attrs.greaterThan;
            if (typeof comparisonModel === 'string') {
                comparisonModel = Number(comparisonModel.replace(/[^0-9-\.]+/g, ''));
            }
            // It's valid if model is > the model we're comparing against
            if (isNaN(modelValue)) {
                ctrl.$setValidity('greaterThan', true);
            } else {
                ctrl.$setValidity('greaterThan', modelValue > comparisonModel);
            }
            return originalModelValue;
        };
        ctrl.$parsers.push(validate);
        ctrl.$formatters.push(validate);

        $attrs.$observe('greaterThan', () =>
            // Whenever the comparison model changes we'll re-validate
            validate(ctrl.$modelValue)
        );
    },
}));

angular.module('backOffice').directive('greaterThanIfEnabled', () => ({
    require: 'ngModel',
    link($scope, $element, $attrs, ctrl) {
        const validate = function (modelValue) {
            const originalModelValue = modelValue;
            if (typeof modelValue === 'string') {
                modelValue = Number(modelValue.replace(/[^0-9-\.]+/g, ''));
            }
            let comparisonModel = $attrs.greaterThanIfEnabled;
            if (typeof comparisonModel === 'string') {
                comparisonModel = Number(comparisonModel.replace(/[^0-9-\.]+/g, ''));
            }

            const { disabled } = $attrs;
            // It's valid if model is > the model we're comparing against
            // or if disabled
            if (isNaN(modelValue) || disabled) {
                ctrl.$setValidity('greaterThanIfEnabled', true);
            } else {
                ctrl.$setValidity('greaterThanIfEnabled', modelValue > comparisonModel);
            }
            return originalModelValue;
        };
        ctrl.$parsers.push(validate);
        ctrl.$formatters.push(validate);

        $attrs.$observe('greaterThan', () =>
            // Whenever the comparison model changes we'll re-validate
            validate(ctrl.$modelValue)
        );

        $attrs.$observe('disabled', () =>
            // Whenever the disabled attribute changes we'll re-validate
            validate(ctrl.$modelValue)
        );
    },
}));

angular.module('backOffice').directive('autoTabTo', [
    function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link(scope, el, attrs, ngmodel) {
                el.bind('keyup', function (e) {
                    if (
                        !e.shiftKey &&
                        e.keyCode !== 16 &&
                        e.keyCode !== 9 &&
                        this.value.length === this.maxLength
                    ) {
                        if (ngmodel.$valid) {
                            const element = document.getElementById(attrs.autoTabTo);
                            if (element) {
                                element.setSelectionRange(0, 9999);
                                element.focus();
                            }
                        }
                    }
                });
            },
        };
    },
]);

angular.module('backOffice').directive('formatDate', () => ({
    require: 'ngModel',
    link(scope, element, attrs, ngModel) {
        element.kendoMaskedTextBox({
            mask: '00/00/0000',
        });

        element.removeClass('k-textbox');
        element.attr('placeholder', 'mm/dd/yyyy');

        element.on('blur', () => {
            const dt = ngModel.$modelValue;
            if (dt && dt.length > 0) {
                const formattedDate = moment(new Date(dt)).format('MM/DD/YYYY');
                if (formattedDate !== 'Invalid date') {
                    ngModel.$setValidity('isValidDateFormat', true);
                } else {
                    ngModel.$setValidity('isValidDateFormat', false);
                }
            } else {
                ngModel.$setValidity('isValidDateFormat', true);
            }
        });
    },
}));

angular.module('backOffice').directive('resizableDialog', () => ({
    restrict: 'AC',
    scope: {
        minHeight: '=',
        minWidth: '=',
    },
    link($scope, element) {
        // var modal = $(element).closest('.modal');
        // if (!!modal) {
        //    setModalMaxHeight(modal);
        //    modal.on('show.bs.modal',
        //        function() {
        //            $(this).show();
        //            setModalMaxHeight(this);
        //        });
        // }
        // $(window).resize(function () {
        //    if ($('.modal.in').length != 0) {
        //        setModalMaxHeight($('.modal.in'));
        //    }
        // });
        /// /var modalContent = $(element).closest('.modal-content');
        // if (!!modalContent) {
        //    // jQueryUI functions
        //    if (!$scope.minHeight) {
        //        $scope.minHeight = 250;
        //    }
        //    if (!$scope.minWidth) {
        //        $scope.minWidth = 250;
        //    }
        //    modalContent.resizable({alsoResize: '.modal-dialog', minHeight: $scope.minHeight, minWidth: $scope.minWidth});
        //    modalContent.draggable({ handle: ".modal-header" });
        // }
        // var modalBody = $(element).closest('.modal-body');
        // if (!!modalBody) {
        //    modalBody.css({
        //        'max-height': '100%'
        //    });
        // }
        // var modal = $(element).closest('.modal');
        // if (!!modal) {
        //    modal.css({
        //        'overflow-y': 'hidden'
        //    });
        // }
    },
}));

function setModalMaxHeight(element) {
    const $element = $(element);
    const $content = $element.find('.modal-content');
    const borderWidth = $content.outerHeight() - $content.innerHeight();
    const dialogMargin = $(window).width() < 768 ? 20 : 60;
    const contentHeight = $(window).height() - (dialogMargin + borderWidth);
    const headerHeight = $element.find('.modal-header').outerHeight() || 0;
    const footerHeight = $element.find('.modal-footer').outerHeight() || 0;
    const maxHeight = contentHeight - (headerHeight + footerHeight);

    $content.css({
        overflow: 'hidden',
    });

    $element.find('.modal-body').css({
        'max-height': maxHeight,
        'overflow-y': 'auto',
    });
}

angular.module('backOffice').directive('infiniteScroll', () => ({
    restrict: 'A',
    link(scope, element, attrs) {
        element.bind('scroll', () => {
            if (element[0].scrollTop + element[0].offsetHeight >= element[0].scrollHeight) {
                scope.$apply(attrs.infiniteScroll);
            }
        });
    },
}));
