angular
    .module('backOffice')
    .controller('CustomizeWireTransferController', customizeWireTransferController);

customizeWireTransferController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'companiesService',
    'modalService',
    'toaster',
    '$modal',
    'wireService',
    'configurationService',
    'entitlementsService',
];

function customizeWireTransferController(
    $scope,
    $state,
    $stateParams,
    companiesService,
    modalService,
    toaster,
    $modal,
    wireService,
    configurationService,
    entitlementsService
) {
    $scope.approvalOptions = [
        { label: 'None', id: 0 },
        { label: '1', id: 1 },
        { label: '2', id: 2 },
        { label: '3', id: 3 },
    ];
    const CompanyId = $stateParams.id;

    $scope.authenticationOptions = ['None', 'Password', 'Token'];
    $scope.maxFutureDaysOptions = [5, 10, 15, 30, 60, 90, 120, 180, 366];
    $scope.canEdit = false;
    $scope.productType = $stateParams.type;
    $scope.productFeatureId = $stateParams.productFeatureId;

    $scope.company = null;
    $scope.type = $stateParams.type;
    $scope.setForm = setForm;

    $scope.addWireCompany = addWireCompany;
    $scope.manageWireCompanyAccounts = manageWireCompanyAccounts;
    $scope.goBack = goBack;
    $scope.save = save;
    $scope.toggleInternational = toggleInternational;
    $scope.toggleDliInternationalUsd = toggleDliInternationalUsd;
    $scope.checkProductDailyLimit = checkProductDailyLimit;
    $scope.fieldLimits = { maximumAmount: '9999999999999.99' };
    $scope.endAmountIncrement = 10000;
    $scope.useInternationalUsd = useInternationalUsd;
    $scope.useInternationalDli = useInternationalDli;
    $scope.haveWireLimitThresholds = haveWireLimitThresholds;
    $scope.canAddAnotherThreshold = canAddAnotherThreshold;
    $scope.deleteThreshold = deleteThreshold;
    $scope.isOnlyOneRow = isOnlyOneRow;
    $scope.isLastRow = isLastRow;
    $scope.checkWireLimitThresholds = checkWireLimitThresholds;
    $scope.getAvailableApprovalCounts = getAvailableApprovalCounts;
    $scope.addThreshold = addThreshold;
    $scope.getPreviousEndAmount = getPreviousEndAmount;
    $scope.getMaximum = getMaximum;
    $scope.thresholdOptions = {
        useWireLimitThresholds: false,
    };
    $scope.isSaveEventDisabled = isSaveEventDisabled;
    $scope.togglePurpose = function () {
        $scope.productFeature.requirePurpose = !$scope.productFeature.requirePurpose;
    };
    $scope.toggleLimitFutureDated = toggleLimitFutureDated;
    $scope.toggleAllowWireFileUpload = toggleAllowWireFileUpload;
    $scope.toggleAccountBalanceValidation = toggleAccountBalanceValidation;
    $scope.checkWireSelection = checkWireSelection;
    $scope.selectionExceeded = {
        wireExist: false,
    };
    $scope.requiredEntitlements = requiredEntitlements;
    $scope.pageTitle = '';
    if ($stateParams.productFeatureId == '') {
        $scope.productFeatureId = 0;
    } else if (
        $stateParams.productFeatureId !== undefined ||
        $stateParams.productFeatureId !== null
    ) {
        $scope.productFeatureId = $stateParams.productFeatureId;
    }

    init();

    function init() {
        companiesService.getCompanyName(CompanyId).then(response => {
            $scope.company = response;
            loadProductFeature();
        });

        if ($scope.productFeatureId === 0) {
            $scope.pageTitle =
                $scope.type === 'wire-transfer' ? 'Add Wire' : 'Add Foreign Currency Wire';
        } else if ($scope.hasEntitlement('Edit Product Feature')) {
            $scope.pageTitle =
                $scope.type === 'wire-transfer' ? 'Edit Wire' : 'Foreign Currency Wire';
        } else {
            $scope.pageTitle =
                $scope.type === 'wire-transfer' ? 'View Wire' : 'View Foreign Currency Wire';
        }
    }

    function isSaveEventDisabled() {
        if (!isWireProductAlreadyAdded()) return false;
        return $scope.form.$dirty === false || $scope.form.$invalid === true;
    }

    function isWireProductAlreadyAdded() {
        if ($scope.productFeatureId > 0) return true;
        return false;
    }

    function requiredEntitlements() {
        return $scope.productFeatureId === 0 ? 'Add Product Feature' : 'Edit Product Feature';
    }

    function useInternationalUsd() {
        return $scope.type === 'wire-transfer' && $scope.productFeature.showInternationalUsd;
    }

    function useInternationalDli() {
        return (
            $scope.type === 'company-wire-transfer-dli' &&
            $scope.productFeature.showInternationalUsd
        );
    }

    function toggleAccountBalanceValidation() {
        $scope.productFeature.validateAccountBalance =
            !$scope.productFeature.validateAccountBalance;
        $scope.form.$setDirty();
    }

    function getPreviousEndAmount(index) {
        if (index !== 0) {
            $scope.productFeature.paymentThresholdApprovals[index].startAmount =
                $scope.productFeature.paymentThresholdApprovals[index - 1].endAmount;
            return $scope.productFeature.paymentThresholdApprovals[index - 1].endAmount;
        }
        $scope.productFeature.paymentThresholdApprovals[index].startAmount = 0.0;
        return 0.0;
    }

    function getAvailableApprovalCounts(index, threshold) {
        // SLewis@jackhenry.com: Seems like there should be a more elegant way to do this
        const nextLowestApprovalCount =
            index === 0
                ? threshold.approvalCount
                : $scope.productFeature.paymentThresholdApprovals[index - 1].approvalCount;
        const nextHighestApprovalCount =
            index === $scope.productFeature.paymentThresholdApprovals.length - 1
                ? threshold.approvalCount
                : $scope.productFeature.paymentThresholdApprovals[index + 1].approvalCount;
        return $scope.approvalOptions.filter(approvalOption => {
            if (approvalOption.id === threshold.approvalCount) {
                return true;
            }
            if (
                approvalOption.id < nextLowestApprovalCount &&
                threshold.approvalCount <= nextLowestApprovalCount
            ) {
                return true;
            }
            if (
                approvalOption.id > nextLowestApprovalCount &&
                approvalOption.id < nextHighestApprovalCount
            ) {
                return true;
            }
            if (
                approvalOption.id > nextHighestApprovalCount &&
                threshold.approvalCount >= nextHighestApprovalCount
            ) {
                return true;
            }
            return false;
        });
    }

    function checkWireLimitThresholds() {
        if (
            !$scope.productFeature.paymentThresholdApprovals ||
            $scope.productFeature.paymentThresholdApprovals.length <= 0
        ) {
            $scope.productFeature.paymentThresholdApprovals = [
                {
                    startAmount: 0.0,
                    endAmount: 10000,
                    approvalCount: 0,
                },
                {
                    startAmount: 10000,
                    endAmount: null,
                    approvalCount: 1,
                },
            ];
        }
    }

    function haveWireLimitThresholds() {
        if ($scope.productFeature) {
            if (
                !!$scope.productFeature.paymentThresholdApprovals &&
                $scope.productFeature.paymentThresholdApprovals.length > 0
            ) {
                return true;
            }
        }
        return false;
    }

    function canAddAnotherThreshold(index, item) {
        if (isLastRow(index)) {
            if (item.approvalCount !== 3) {
                return true;
            }
        }
        return false;
    }

    function isLastRow(index) {
        return $scope.productFeature.paymentThresholdApprovals.length - 1 === index;
    }

    function addThreshold() {
        const previousEndAmount =
            $scope.productFeature.paymentThresholdApprovals[
                $scope.productFeature.paymentThresholdApprovals.length - 1
            ].endAmount;
        const previousStartAmount =
            $scope.productFeature.paymentThresholdApprovals[
                $scope.productFeature.paymentThresholdApprovals.length - 1
            ].startAmount;
        if (previousEndAmount) {
            $scope.productFeature.paymentThresholdApprovals[
                $scope.productFeature.paymentThresholdApprovals.length - 1
            ].endAmount += $scope.endAmountIncrement;
        } else {
            $scope.productFeature.paymentThresholdApprovals[
                $scope.productFeature.paymentThresholdApprovals.length - 1
            ].endAmount = previousStartAmount + $scope.endAmountIncrement;
        }
        $scope.productFeature.paymentThresholdApprovals.push({
            approvalCount:
                $scope.productFeature.paymentThresholdApprovals[
                    $scope.productFeature.paymentThresholdApprovals.length - 1
                ].approvalCount + 1,
            startAmount:
                $scope.productFeature.paymentThresholdApprovals[
                    $scope.productFeature.paymentThresholdApprovals.length - 1
                ].endAmount,
            endAmount: null,
        });
    }

    function getMaximum(index) {
        if (index === 0) {
            return 0;
        }
        return $scope.productFeature.paymentThresholdApprovals[index - 1].endAmount;
    }

    function deleteThreshold(index) {
        $scope.productFeature.paymentThresholdApprovals.splice(index, 1);
        $scope.productFeature.paymentThresholdApprovals[
            $scope.productFeature.paymentThresholdApprovals.length - 1
        ].endAmount = null;
    }

    function isOnlyOneRow() {
        return $scope.productFeature.paymentThresholdApprovals.length === 1;
    }

    function checkProductDailyLimit(blurred) {
        if (!$scope.wireCompanies || $scope.wireCompanies.length <= 0) {
            return;
        }
        if (blurred) {
            $scope.confirmFieldBlurred = true;
        }
        $scope.form.dailyLimit.$setValidity(
            'lessThanEqualTo',
            $scope.productFeature.dailyLimit <= $scope.maxProductDailyLimit ||
                !$scope.confirmFieldBlurred
        );
    }

    $scope.calculateMaximumProductDailyLimit = function () {
        let maximum = 0;
        if (!!$scope.wireCompanies && $scope.wireCompanies.length > 0) {
            maximum = $scope.wireCompanies
                .map(wireCompany => wireCompany.dailyLimit)
                .reduce((curr, prev) => Number(curr) + Number(prev));
        }
        return maximum;
    };

    function getCompanies() {
        wireService
            .getCompanies($scope.productFeature.id, $scope.type === 'company-wire-transfer-dli')
            .then(response => {
                $scope.wireCompanies = response;
                $scope.calculateMaximumProductDailyLimit();
            });
    }

    function addWireCompany() {
        if (
            $scope.type === 'company-wire-transfer-dli' &&
            $scope.productFeature.useNonCoreInternationalWireCompanies === true
        ) {
            openCreateEditNonCoreWireCompanyModal();
        } else {
            openAddWireCompanyModal();
        }
    }

    function openAddWireCompanyModal() {
        const modalInstance = $modal.open({
            template: require('../views/addWireCompanyModalView.html'),
            size: 'ms',
            controller: 'AddWireCompanyController',
            backdrop: 'static',
            resolve: {
                productFeature() {
                    return $scope.productFeature;
                },
                parentCompanyId() {
                    return CompanyId;
                },
                isDli() {
                    return $scope.type === 'company-wire-transfer-dli';
                },
            },
        });
        modalInstance.result.then(response => {
            if (response) {
                getCompanies();
            }
        });
    }

    function openCreateEditNonCoreWireCompanyModal(wireCompany) {
        const modalInstance = $modal.open({
            template: require('../views/nonCoreInternationalWireCompanyView.html'),
            size: wireCompany ? 'lg' : 'md',
            controller: 'NonCoreInternationalWireCompanyController',
            backdrop: 'static',
            resolve: {
                productFeature() {
                    return $scope.productFeature;
                },
                wireCompanyId() {
                    return wireCompany ? wireCompany.id : null;
                },
            },
        });
        modalInstance.result.then(response => {
            if (response) {
                getCompanies();
                toaster.save('Wire Company');
            }
        });
    }

    function toggleInternational() {
        const isEnablingIntlUsd = !$scope.productFeature.isInternational;
        if (isEnablingIntlUsd) {
            const modalOptions = {
                alertType: 'Error',
                isAlert: false,
                actionButtonText: 'Confirm',
                closeButtonText: 'Cancel',
                bodyText:
                    'The International USD configuration can only be active on one product. Setting this configuration to Active will change the International USD configuration within the Foreign Currency Wire product to Inactive.',
                submit(result) {
                    $scope.productFeature.isInternational = true;
                    modal.close(result);
                },
            };
            var modal = modalService.showModal({}, modalOptions);
        } else {
            $scope.productFeature.isInternational = false;
        }
    }

    function toggleDliInternationalUsd() {
        const isEnablingIntlUsd = !$scope.productFeature.internationalUsd;
        if (isEnablingIntlUsd) {
            const modalOptions = {
                alertType: 'Error',
                isAlert: false,
                actionButtonText: 'Confirm',
                closeButtonText: 'Cancel',
                bodyText:
                    'The International USD configuration can only be active on one product. Setting this configuration to Active will change the International USD configuration within the Wire product to Inactive.',
                submit(result) {
                    $scope.productFeature.internationalUsd = true;
                    modal.close(result);
                },
            };
            var modal = modalService.showModal({}, modalOptions);
        } else {
            $scope.productFeature.internationalUsd = false;
        }
    }

    function setDefaultValues() {
        if (isNaN($scope.productFeature.dailyLimit)) $scope.productFeature.dailyLimit = 0.0;
    }

    function save() {
        $scope.form.$setPristine();
        $scope.productFeature.paymentApprovalType = !$scope.thresholdOptions.useWireLimitThresholds
            ? 'Overall'
            : 'Threshold';

        if ($scope.productFeature.id !== 0) {
            setDefaultValues();
            companiesService
                .updateProductFeature(CompanyId, $scope.productFeature)
                .then(response => {
                    toaster.save('Wire Product Feature');
                });
        } else if ($scope.productFeature.id === 0 && $scope.type === 'wire-transfer') {
            companiesService.saveProductFeature(CompanyId, $scope.productFeature).then(response => {
                $scope.productFeature.wireCompanies = [];
                $scope.productFeature.id = response.id;
            });
        } else if ($scope.productFeature.id === 0 && $scope.type === 'company-wire-transfer-dli') {
            setDefaultValues();
            companiesService.saveProductFeature(CompanyId, $scope.productFeature).then(response => {
                $scope.productFeature.wireCompanies = [];
                $scope.productFeature.id = response.id;
            });
        }
    }

    function setForm(form) {
        $scope.form = form;
    }

    function goBack() {
        if ($scope.form.$dirty) {
            let modal;
            const modalOptions = {
                bodyText:
                    'You have changes on the page, are you sure you would like to leave the page without saving?',
                submit(result) {
                    $state.go('company', { id: CompanyId });
                    modal.close(result);
                },
            };
            modal = modalService.showModal({}, modalOptions);
        } else {
            $state.go('company', { id: CompanyId });
        }
    }

    //
    // Private

    function manageWireCompanyAccounts(wireCompany) {
        if (
            $scope.type === 'company-wire-transfer-dli' &&
            $scope.productFeature.useNonCoreInternationalWireCompanies
        ) {
            openCreateEditNonCoreWireCompanyModal(wireCompany);
        } else {
            openEditCoreWireCompanyAccountsModal(wireCompany);
        }
    }

    function openEditCoreWireCompanyAccountsModal(wireCompany) {
        $modal.open({
            template: require('../views/manageWireCompanyAccountsModalView.html'),
            size: 'lg',
            controller: 'ManageWireCompanyAccountsController',
            backdrop: 'static',
            resolve: {
                wireCompany() {
                    return wireCompany;
                },
                productFeature() {
                    return $scope.productFeature;
                },
                type() {
                    return 'Edit';
                },
                parentCompanyId() {
                    return CompanyId;
                },
                isDli() {
                    return $scope.type === 'company-wire-transfer-dli';
                },
            },
        });
    }

    function loadProductFeature() {
        if ($scope.productFeatureId !== 0) {
            companiesService
                .getProductPermissions(CompanyId, $scope.productFeatureId)
                .then(response => {
                    $scope.productFeature = response;
                    $scope.limitFutureDated = !!$scope.productFeature.maximumFutureDays;
                    $scope.thresholdOptions.useWireLimitThresholds = !!(
                        !!$scope.productFeature.paymentApprovalType &&
                        $scope.productFeature.paymentApprovalType === 'Threshold'
                    );
                    if ($scope.productFeatureId !== 0) {
                        getCompanies();
                    }
                });
        } else if ($scope.productFeatureId === 0 && $scope.type === 'wire-transfer') {
            configurationService.getProductFeatureDetailsByName('WireTransfer').then(response => {
                $scope.productFeature = response;
                $scope.limitFutureDated = !!$scope.productFeature.maximumFutureDays;
                $scope.thresholdOptions.useWireLimitThresholds = !!(
                    !!$scope.productFeature.paymentApprovalType &&
                    $scope.productFeature.paymentApprovalType === 'Threshold'
                );
                $scope.productFeature.id = 0;
                $scope.productFeature.type = 'WireTransfer';
            });
        } else if ($scope.productFeatureId === 0 && $scope.type === 'company-wire-transfer-dli') {
            configurationService
                .getProductFeatureDetailsByName('WireTransferDLI')
                .then(response => {
                    $scope.productFeature = response;
                    $scope.thresholdOptions.useWireLimitThresholds = !!(
                        !!$scope.productFeature.paymentApprovalType &&
                        $scope.productFeature.paymentApprovalType === 'Threshold'
                    );
                    $scope.productFeature.id = 0;
                    $scope.productFeature.type = 'WireTransferDLI';
                });
        }
    }

    function toggleLimitFutureDated() {
        $scope.productFeature.limitFutureDateEnabled =
            !$scope.productFeature.limitFutureDateEnabled;
        $scope.productFeature.maximumFutureDays = $scope.productFeature.limitFutureDateEnabled
            ? 5
            : undefined;
    }

    function toggleAllowWireFileUpload() {
        $scope.productFeature.allowWireFileUpload = !$scope.productFeature.allowWireFileUpload;
    }

    function checkWireSelection() {
        if ($scope.productFeature.farthestWireExistInDays > 0) {
            if (
                $scope.productFeature.maximumFutureDays >
                $scope.productFeature.farthestWireExistInDays
            ) {
                $scope.selectionExceeded.wireExist = false;
            } else {
                $scope.selectionExceeded.wireExist = true;
            }
        }
    }
}
