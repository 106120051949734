angular
    .module('backOffice')
    .controller('ChangeAuthenticationMethodModalController', changeAuthenticationMethodController);

changeAuthenticationMethodController.$inject = ['$scope', '$modalInstance'];

function changeAuthenticationMethodController($scope, $modalInstance) {
    $scope.cancel = function () {
        $modalInstance.close('cancel');
    };
    $scope.confirm = function () {
        $modalInstance.close('confirm');
    };
}
