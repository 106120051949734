angular.module('backOffice').controller('EditProductController', editProductController);

editProductController.$inject = [
    '$scope',
    'companiesService',
    '$modalInstance',
    'selectedProductId',
    'companyId',
    'toaster',
    'canEdit',
    'ofxSettingsService',
    'jhaSupportService',
    'accountService',
    'modalService',
    'entitlementsService',
    'configurationService',
];

function editProductController(
    $scope,
    companiesService,
    $modalInstance,
    selectedProductId,
    companyId,
    toaster,
    canEdit,
    ofxSettingsService,
    jhaSupportService,
    accountService,
    modalService,
    entitlementsService,
    configurationService
) {
    $scope.selectedProduct = {};
    $scope.canEdit = canEdit;
    $scope.selectedProductId = selectedProductId;
    $scope.companyId = companyId;
    $scope.maxDisplayUsers = 10;
    $scope.approvalList = [{ name: 'None' }, { name: '1' }, { name: '2' }, { name: '3' }];
    $scope.authenticationTypeList = [{ name: 'None' }, { name: 'Password' }, { name: 'Token' }];

    $scope.filteredAccounts = {};
    $scope.toggleColumn = toggleColumn;
    $scope.addAccountToCompany = addAccountToCompany;
    $scope.accountAccessIsCollapsed = true;
    $scope.toggleCbox = { checked: false };
    $scope.action = '';

    // this will dismiss the window.
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    // this will update the company
    $scope.save = function () {
        editProductToCompany();
    };

    $scope.generateOfxId = generateIntuitOfxId;
    $scope.intuitIdExists = doesIntuitIdExist;
    $scope.toggleOfxUserLevelAccess = toggleOfxUserLevelAccess;
    $scope.confirmAndToggleOfxForCompany = confirmAndToggleOfxForCompany;
    $scope.confirmAndToggleQfxForCompany = confirmAndToggleQfxForCompany;
    $scope.getQfxEnabledForCompanyFlag = getQfxEnabledForCompanyFlag;
    $scope.productFeature = {};
    $scope.selectTab = selectTab;
    $scope.isTabSelected = isTabSelected;
    $scope.reloadTab = reloadTab;
    $scope.allowedToViewReportingConfiguration = allowedToViewReportingConfiguration;

    $scope.loginUser = '';

    function editProductToCompany() {
        if ($scope.filteredAccounts) {
            $scope.selectedProduct.accountIds = $scope.filteredAccounts
                .filter(item => item.addToCompany)
                .map(item => item.id);
        }
        $scope.selectedProduct.accounts = $scope.selectedProduct.accounts.map(item => {
            item.coreStatus = 'Active';
            return item;
        });
        // save account
        companiesService
            .updateProductFeature($scope.companyId, $scope.selectedProduct)
            .then(response => {
                $modalInstance.close(response);
                toaster.save('Product Feature');
            });
    }

    function toggleColumn(value) {
        angular.forEach($scope.filteredAccounts, account => {
            account.addToCompany = value;
        });
    }

    function addAccountToCompany() {
        if ($scope.filteredAccounts.every(element => element.addToCompany === true)) {
            $scope.toggleCbox.checked = true;
        } else {
            $scope.toggleCbox.checked = false;
        }
    }

    // used to set the form instance.
    $scope.setForm = function (form) {
        $scope.form = form;
    };

    $scope.setDirty = function () {
        $scope.form.$dirty = true;
    };

    // we watch for form.$dirty, this is used to alert the end user that they changed something in the form.
    $scope.$watch('form.$dirty', isDirty => {
        $modalInstance.isDirty = isDirty;
    });

    function getProductEntitlements() {
        companiesService
            .getProductPermissions($scope.companyId, $scope.selectedProductId)
            .then(response => {
                $scope.selectedProduct = response;
                setDefaultValues();
                if ($scope.selectedProduct.type === 'InformationReportingProductFeature') {
                    loadAccounts($scope.selectedProduct);
                }
            });
    }

    function setDefaultValues() {
        if ($scope.selectedProduct.type === 'InternalTransferProduct') {
            if (
                $scope.selectedProduct.freeTransaction === null ||
                $scope.selectedProduct.freeTransaction === undefined
            ) {
                $scope.selectedProduct.freeTransaction = 0;
            }
            if (
                $scope.selectedProduct.feePerTransaction === null ||
                $scope.selectedProduct.feePerTransaction === undefined
            ) {
                $scope.selectedProduct.feePerTransaction = '0.00';
            }
            if (
                $scope.selectedProduct.monthlyFee === null ||
                $scope.selectedProduct.monthlyFee === undefined
            ) {
                $scope.selectedProduct.monthlyFee = '0.00';
            }
            if (
                $scope.selectedProduct.dailyLimit === null ||
                $scope.selectedProduct.dailyLimit === undefined
            ) {
                $scope.selectedProduct.dailyLimit = '0.00';
            }
        }
    }

    function init() {
        getProductEntitlements();
        getLoggedInUser();
        selectTab('Reporting Eligible Accounts');
        getOfxEnabledForFiFlag();
        checkQfxProductStatusForFi();
        $scope.action = entitlementsService.hasEntitlement('Edit Product Feature')
            ? 'Edit'
            : 'View';
    }

    function selectTab(tabName) {
        if (!isTabSelected(tabName)) {
            $scope.selectedTab = tabName;
            reloadTab(tabName);
        }
    }

    function isTabSelected(tabName) {
        return tabName === $scope.selectedTab;
    }

    function reloadTab(selectedTab) {
        if (selectedTab === 'Reporting Eligible Accounts') {
            loadAccounts($scope.selectedProduct);
        } else if (selectedTab === 'OFX Configuration') {
            getOfxEnabledForFiFlag();
        } else if (selectedTab === 'QFX Configuration') {
            getQfxEnabledForCompanyFlag();
        }
    }

    function getOfxEnabledForFiFlag() {
        $scope.selectedInstitution = jhaSupportService.getUserFiId();
        ofxSettingsService.getOfxEnabledFlagForFI($scope.selectedInstitution).then(response => {
            $scope.ofxEnabledForFI = response.isOfxEnabled;
            $scope.ofxUserLevelAccess = response.userLevelAccessEnabled;
            if ($scope.ofxEnabledForFI) {
                getOfxEnabledForCompanyFlag();
            }
        });
    }

    function getOfxEnabledForCompanyFlag() {
        $scope.selectedInstitution = jhaSupportService.getUserFiId();
        ofxSettingsService
            .getOfxEnabledFlagForCompany($scope.selectedInstitution, $scope.companyId)
            .then(response => {
                $scope.ofxEnabledForCompany = response.isOfxEnabled;
                $scope.ofxUserLevelAccess = response.userLevelAccessEnabled;
                getIntuitOfxIdForCompany();
            });
    }

    function loadAccounts(product) {
        companiesService.getActiveCompanyAccounts($scope.companyId).then(response => {
            if (!response) return;

            const companyAccounts = response.map(companyAccount => companyAccount.account);

            if (product.accounts) {
                companyAccounts.forEach(companyAccount => {
                    companyAccount.addToCompany = product.accounts.some(
                        productAccount => productAccount.id === companyAccount.id
                    );
                });
            }

            $scope.filteredAccounts = companyAccounts;

            if (product.accounts.length === companyAccounts.length) {
                $scope.toggleCbox.checked = true;
            }
        });
    }

    function doesIntuitIdExist() {
        return $scope.intuitOfxId != null;
    }

    function getIntuitOfxIdForCompany() {
        companiesService.getIntuitOfxId($scope.companyId).then(response => {
            if (response) {
                $scope.intuitOfxId = response.intuitOfxId;
            }
        });
    }

    function generateIntuitOfxId() {
        /* eslint-disable sonarjs/no-identical-functions */
        companiesService.generateIntuitOfxIdForCompany($scope.companyId).then(response => {
            if (response) {
                $scope.intuitOfxId = response.intuitOfxId;
            }
        });
    }

    function toggleOfxUserLevelAccess() {
        const ofxAccess = !$scope.ofxUserLevelAccess;
        updateOfxUserLevelAccessForFI(ofxAccess);
    }

    function updateOfxUserLevelAccessForFI(ofxUserLevelAccessFlag) {
        ofxSettingsService
            .updateOfxUserLevelAccessForFI($scope.selectedInstitution, ofxUserLevelAccessFlag)
            .then(response => {
                if (response && response.success) {
                    $scope.ofxUserLevelAccess = ofxUserLevelAccessFlag;
                }
            });
    }

    function confirmAndToggleOfxForCompany() {
        const newVal = !$scope.ofxEnabledForCompany;

        if (!newVal) {
            const modalOptions = {
                headerText: '',
                bodyText:
                    'Changing this parameter to Inactive will also set all OFX enabled users to Inactive',
                actionButtonText: 'Ok',
                closeButtonText: 'Cancel',
                submit() {
                    $modalInstance.close();
                    toggleOfxEnabledForCompany(newVal);
                },
            };
            var $modalInstance = modalService.showModal({}, modalOptions);
        } else {
            toggleOfxEnabledForCompany(newVal);
        }
    }

    function toggleOfxEnabledForCompany(newVal) {
        ofxSettingsService
            .updateOfxEnabledFlagForCompany(
                $scope.selectedInstitution,
                $scope.companyId,
                newVal,
                $scope.ofxUserLevelAccess,
                $scope.intuitOfxId,
                $scope.loginUser
            )
            .then(response => {
                if (response && response.success) {
                    $scope.ofxEnabledForCompany = response.isOfxEnabled;
                    $scope.ofxUserLevelAccess = response.userLevelAccessEnabled;
                }
            });
    }

    function getLoggedInUser() {
        accountService.recentLogins().then(response => {
            $scope.loginUser = response.userName;
        });
    }
    function checkQfxProductStatusForFi() {
        configurationService.checkQfxProductStatusForFi().then(response => {
            if (response.enabledFlag === 'Y') {
                $scope.qfxEnabledForFi = true;
            }
        });
    }

    function getQfxEnabledForCompanyFlag() {
        companiesService.getQfxEnabledFlag($scope.companyId).then(response => {
            $scope.qfxEnabledForCompany = response.enabledFlag;
        });
    }

    function confirmAndToggleQfxForCompany() {
        let message;
        let modalOptions;
        let actionButtonText;
        let closeButtonText;
        message = 'Are you sure you want to change the product feature status?';
        actionButtonText = 'Yes';
        closeButtonText = 'No';

        modalOptions = {
            bodyText: message,
            actionButtonText,
            closeButtonText,
            submit(result) {
                const newStatus = $scope.qfxEnabledForCompany ? 'N' : 'Y';
                companiesService.saveQfxCompanyFlag(newStatus, $scope.companyId).then(response => {
                    $scope.qfxEnabledForCompany = !$scope.qfxEnabledForCompany;
                    toaster.save('QFX Option');
                });
                $modalInstance.close(result);
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    function allowedToViewReportingConfiguration() {
        return entitlementsService.hasAnyEntitlement('Edit Product Feature Configuration');
    }

    init();
}
