import { AchChildSupportPayment } from '@treasury/domain/backoffice/types/ach';
import { AchChildSupportRecipient } from '@treasury/domain/backoffice/types/ach/ach-child-support-recipient.entity';
import { Record as FdlRecord, Recordset } from '@treasury/FDL';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-field';
import '@treasury/omega/components/omega-table';
import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import {
    AchRecipientFilters,
    getAchChildSupportRecipientColumns,
} from '../../data/ach-payment-field-definitions';

@customElement('ach-child-support-payment-detail')
export class AchChildSupportPaymentDetail extends ListeningElementMixin(LitElement) {
    @property()
    private paymentRecord!: FdlRecord<AchChildSupportPayment>;

    @property()
    private recipientRecordset!: Recordset<AchChildSupportRecipient>;

    private readonly recipientLabels = {
        singular: 'recipient',
        plural: 'recipients',
    };

    firstUpdated() {
        this.listenTo(this.recipientRecordset, 'page-changed', () => {
            this.recipientRecordset.allRecords.forEach(record => {
                record.isExpanded = true; // eslint-disable-line no-param-reassign
            });
        });

        this.recipientRecordset.requestUpdate();
    }

    renderPaymentDetail() {
        if (!this.paymentRecord) return nothing;
        return html` <h3 class="payment-name">
                ${this.paymentRecord.values.name}
                <small class="recipient-count"
                    >(${this.recipientRecordset.totalCount}
                    Recipient${this.recipientRecordset.totalCount > 1 ? 's' : ''})</small
                >
            </h3>
            <div class="details-body omega-flex-form">
                <div class="form-column col-1">
                    <omega-field field="status" .record=${this.paymentRecord}></omega-field>
                    <omega-field field="companyName" .record=${this.paymentRecord}></omega-field>
                    <omega-field field="secCode" .record=${this.paymentRecord}></omega-field>
                    <omega-field
                        field="entryDescription"
                        .record=${this.paymentRecord}
                    ></omega-field>
                    <omega-field
                        field="discretionaryData"
                        .record=${this.paymentRecord}
                    ></omega-field>
                </div>

                <div class="form-column col-2">
                    <omega-field field="debitAmount" .record=${this.paymentRecord}></omega-field>
                    <omega-field field="creditAmount" .record=${this.paymentRecord}></omega-field>
                    <omega-field
                        field="frequencySummary"
                        .record=${this.paymentRecord}
                    ></omega-field>
                    <omega-field field="effectiveDate" .record=${this.paymentRecord}></omega-field>
                    <omega-field field="offsetAccount" .record=${this.paymentRecord}></omega-field>
                    <omega-field
                        field="restrictPayment"
                        .record=${this.paymentRecord}
                    ></omega-field>
                </div>
                <div class="form-column col-3">
                    <omega-field no-align field="audit" .record=${this.paymentRecord}></omega-field>
                </div>
            </div>`;
    }

    renderRecipientDetailRow(record: FdlRecord<AchChildSupportRecipient>) {
        return html`
            <style>
                .omega-flex-form {
                    display: flex;
                }

                .form-column {
                    flex: 1 1 0;
                    margin: 10px;
                }

                .form-column:not(:last-of-type) {
                    padding-right: 10px;
                    border-right: 1px solid #d3d6d9;
                }
                .omega-flex-form omega-field {
                    --omega-field-control-width: 120px;
                    margin: 10px;
                }
            </style>
            <div class="omega-flex-form">
                <div class="form-column">
                    <omega-field field="nonCustodialParentSsn" .record=${record}></omega-field>
                    <omega-field
                        field="nonCustodialParentFirstName"
                        .record=${record}
                    ></omega-field>
                    <omega-field field="nonCustodialParentLastName" .record=${record}></omega-field>
                </div>
                <div class="form-column">
                    <omega-field field="caseIdentifier" .record=${record}></omega-field>
                    <omega-field field="fipsCode" .record=${record}></omega-field>
                </div>
                <div class="form-column">
                    <omega-field field="payDate" .record=${record}></omega-field>
                    <omega-field field="employmentTermination" .record=${record}></omega-field>
                    <omega-field field="medicalSupport" .record=${record}></omega-field>
                </div>
            </div>
        `;
    }

    renderRecipientsTable() {
        if (!this.recipientRecordset) return nothing;
        return html`<div class="payment-recipients">
            <omega-filter-bar
                .itemLabel=${this.recipientLabels}
                .recordset=${this.recipientRecordset}
                .filters=${AchRecipientFilters}
            >
            </omega-filter-bar
            ><omega-table
                .itemLabel=${{
                    singular: 'recipient',
                    plural: 'recipients',
                }}
                .detailRowTemplateFunction=${(record: FdlRecord<AchChildSupportRecipient>) =>
                    this.renderRecipientDetailRow(record)}
                .recordset=${this.recipientRecordset}
                .columnDefinitions=${getAchChildSupportRecipientColumns(this)}
            ></omega-table>
        </div>`;
    }

    render() {
        return [this.renderPaymentDetail(), this.renderRecipientsTable()];
    }

    static get styles() {
        return [
            css`
                .payment-name {
                    margin: 10px 0 0 20px;
                    color: #505050;
                }

                .payment-recipients {
                    margin: 15px;
                    padding-bottom: 15px;
                    border-top: 1px solid #ccc;
                }
                .payment-recipients omega-table {
                    border: 1px solid #ccc;
                }
                .details-body {
                    --omega-label-font-weight: 700;
                    margin-left: 14px;
                }
                .omega-flex-form {
                    display: flex;
                }
                .form-column {
                    flex: 1 1 0;
                    margin: 10px;
                }
                .col-1 {
                    --omega-field-label-max-width: 170px;
                }
                .col-2 {
                    --omega-field-label-max-width: 130px;
                }
                .col-3 {
                    --omega-field-label-max-width: 60px;
                }
                .form-column:not(:last-of-type) {
                    padding-right: 10px;
                    border-right: 1px solid #d3d6d9;
                }
                .omega-flex-form omega-field {
                    --omega-field-control-width: 120px;
                    margin: 5px 10px;
                }
                omega-table {
                    --omega-table-detail-row-border: none;
                }
            `,
        ];
    }
}
