import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('IpSettingsService', ipSettingsService);

ipSettingsService.$inject = ['$resource'];

function ipSettingsService($resource) {
    const resourceUrl = `${getBackOfficeAPIPath()}ipSettings/`;

    return {
        getFiIpAddresses,
        updateIpAddresses,
    };

    function getFiIpAddresses() {
        return $resource(resourceUrl).get().$promise;
    }
    function updateIpAddresses(ipAddresses) {
        const resource = $resource(
            `${resourceUrl}:id`,
            { id: ipAddresses.id },
            { update: { method: 'PUT' } }
        );
        return resource.update(ipAddresses).$promise;
    }
}
