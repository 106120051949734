angular
    .module('backOffice')
    .controller(
        'ConfigureRemoteDepositCaptureSsoController',
        ConfigureRemoteDepositCaptureSsoController
    );

ConfigureRemoteDepositCaptureSsoController.$inject = [
    'institutionProductTypeId',
    '$modalInstance',
    'configurationService',
    'toaster',
];

function ConfigureRemoteDepositCaptureSsoController(
    institutionProductTypeId,
    $modalInstance,
    configurationService,
    toaster
) {
    const vm = this;

    vm.cancel = cancel;
    vm.ssoLogins = [];
    vm.save = save;

    init();

    function cancel() {
        $modalInstance.dismiss();
    }

    function save() {
        const ssoConfigurations = [];
        vm.ssoLogins.forEach(ssoLogin => {
            ssoConfigurations.push({
                institutionSsoProductId: ssoLogin.institutionSsoProductId,
                isActive: ssoLogin.isActive,
            });
        });

        configurationService
            .updateRemoteDepositProduct({
                remoteDepositCaptureSsoConfigurations: ssoConfigurations,
            })
            .then(() => {
                toaster.save('Remote Deposit Capture');
                $modalInstance.dismiss();
            });
    }

    //
    // Private

    function init() {
        loadProductFeature();
    }

    function loadProductFeature() {
        configurationService.getProductFeatureDetails(institutionProductTypeId).then(response => {
            vm.ssoLogins = response.ssoLogins;
        });
    }
}
