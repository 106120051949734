import { getBackOfficeAPIPath } from '@treasury/core/http';

angular
    .module('backOffice')
    .factory('informationCenterConfigurationService', informationCenterConfigurationService);

informationCenterConfigurationService.$inject = ['$resource'];

function informationCenterConfigurationService($resource) {
    const resourceUrl = `${getBackOfficeAPIPath()}informationcenterconfigurations/`;
    return {
        getInformationCenterConfigurations,
        changeInformationCenterConfigurationSequence,
        deleteMessage,
        updateInformationCenterConfiguration,
        saveInformationCenterConfiguration,
    };

    function saveInformationCenterConfiguration(informationCenterConfiguration) {
        return $resource(resourceUrl).save(informationCenterConfiguration).$promise;
    }

    function updateInformationCenterConfiguration(informationCenterConfiguration) {
        const resource = $resource(
            `${resourceUrl}:id`,
            { id: informationCenterConfiguration.id },
            { update: { method: 'PUT' } }
        );
        return resource.update(informationCenterConfiguration).$promise;
    }

    function deleteMessage(id) {
        return $resource(`${resourceUrl}:id`, { id }).delete().$promise;
    }

    function changeInformationCenterConfigurationSequence(id, direction) {
        return $resource(`${resourceUrl}resequence/?id=:id&direction=:direction`, {
            id,
            direction,
        }).query().$promise;
    }

    function getInformationCenterConfigurations() {
        return $resource(resourceUrl).query().$promise;
    }
}
