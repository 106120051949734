import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('companyUsersService', companyUsersService);

companyUsersService.$inject = ['$resource', '$log'];

function companyUsersService($resource, $log) {
    const resourceUrl = `${getBackOfficeAPIPath()}companyuser/:id/users/`;
    return {
        searchUsers,
        getUserHeader,
        getUserProductFeatureSummary,
        submitUser,
        discardChanges,
        unlock,
        approve,
        reject,
        changeUserActiveStatus,
        deleteUser,
        manualResetPassword,
        resetPassword,
        getUserInformation,
        getUserChallengeDetail,
        saveUserInformation,
        getUserAccountAccess,
        updateUserAccountAccess,
        getProductLimit,
        getAchLimits,
        getProductEntitlements,
        getAchEntitlements,
        updateProductLimit,
        updateAchLimits,
        updateProductEntitlements,
        updateAchEntitlements,
        unlockUserChallenge,
        reCollectUserChallengeInformation,
        updateIpRetrictions,
        getIpRestrictions,
        getUserTimeAccess,
        saveUserTimeAccess,
        getUserSsoPosPaySettings,
        updateUserSsoPosPaySettings,
        getUserReceivablesSettings,
        updateUserReceivablesSettings,
        buildUpdateProductEntitlementsRequest,
    };

    function searchUsers(filter, companyId) {
        filter.companyId = companyId;
        return $resource(
            `${resourceUrl}search`,
            { id: companyId },
            { search: { method: 'POST', isArray: true } }
        ).search(filter).$promise;
    }

    function getUserHeader(companyId, userId) {
        return $resource(`${resourceUrl}:userId/header`, { id: companyId, userId }).get().$promise;
    }

    function getUserProductFeatureSummary(companyId, userId) {
        return $resource(`${resourceUrl}:userId/productfeaturesummary`, {
            id: companyId,
            userId,
        }).get().$promise;
    }

    function submitUser(companyId, userId) {
        return $resource(
            `${resourceUrl}:userId/submit`,
            { id: companyId, userId },
            { update: { method: 'PUT' } }
        ).update().$promise;
    }

    function discardChanges(companyId, userId, comments) {
        return $resource(
            `${resourceUrl}:userId/discard`,
            { id: companyId, userId, comments },
            { update: { method: 'PUT' } }
        ).update().$promise;
    }

    function unlock(companyId, userId) {
        return $resource(
            `${resourceUrl}:userId/unlock`,
            { id: companyId, userId },
            { update: { method: 'PUT' } }
        ).update().$promise;
    }

    function approve(companyId, userId, comments) {
        return $resource(
            `${resourceUrl}:userId/approve`,
            { id: companyId, userId, comments },
            { update: { method: 'PUT' } }
        ).update().$promise;
    }

    function reject(companyId, userId, comments) {
        return $resource(
            `${resourceUrl}:userId/reject`,
            { id: companyId, userId, comments },
            { update: { method: 'PUT' } }
        ).update().$promise;
    }

    function resetPassword(companyId, userId) {
        return $resource(`${resourceUrl}:userId/resetPassword`, {
            id: companyId,
            userId,
        }).save().$promise;
    }

    function manualResetPassword(companyId, userId, resetPasswordPayload) {
        return $resource(`${resourceUrl}:userId/manualResetPassword`, {
            id: companyId,
            userId,
        }).save({
            key: resetPasswordPayload.tempPassword,
            value: resetPasswordPayload.tempPassword,
        }).$promise;
    }

    function deleteUser(companyId, userId) {
        return $resource(`${resourceUrl}:userId`, { id: companyId, userId }).delete().$promise;
    }

    function getUserInformation(companyId, userId) {
        return $resource(`${resourceUrl}:userId/userinformation`, {
            id: companyId,
            userId,
        }).get().$promise;
    }

    function getUserChallengeDetail(companyId, userId) {
        return $resource(`${resourceUrl}:userId/userchallengedetail`, {
            id: companyId,
            userId,
        }).get().$promise;
    }

    function saveUserInformation(companyId, userId, user) {
        return $resource(`${resourceUrl}:userId/userinformation`, {
            id: companyId,
            userId,
        }).save(user).$promise;
    }

    function getUserAccountAccess(companyId, userId) {
        return $resource(`${resourceUrl}:userId/accountaccess`, {
            id: companyId,
            userId,
        }).get().$promise;
    }

    function updateUserAccountAccess(companyId, userId, userAccountAccess) {
        return $resource(
            `${resourceUrl}:userId/accountaccess`,
            { id: companyId, userId },
            { update: { method: 'PUT' } }
        ).update(userAccountAccess).$promise;
    }

    function getProductLimit(companyId, userId, productFeatureType) {
        return $resource(`${resourceUrl}:userId/productlimits`, {
            id: companyId,
            userId,
            productFeatureType,
        }).get().$promise;
    }

    function getAchLimits(companyId, userId) {
        return $resource(`${resourceUrl}:userId/achlimits`, { id: companyId, userId }).get()
            .$promise;
    }

    function getProductEntitlements(companyId, userId, productFeatureType) {
        return $resource(`${resourceUrl}:userId/productentitlements`, {
            id: companyId,
            userId,
            productFeatureType,
        }).get().$promise;
    }

    function getAchEntitlements(companyId, userId) {
        return $resource(`${resourceUrl}:userId/achproductentitlements`, {
            id: companyId,
            userId,
        }).get().$promise;
    }

    function updateProductLimit(companyId, userId, productFeatureConfig) {
        return $resource(
            `${resourceUrl}:userId/productlimits`,
            { id: companyId, userId },
            { update: { method: 'PUT' } }
        ).update(productFeatureConfig).$promise;
    }

    function updateAchLimits(companyId, userId, limits) {
        return $resource(
            `${resourceUrl}:userId/achlimits`,
            { id: companyId, userId },
            { update: { method: 'PUT' } }
        ).update(limits).$promise;
    }

    function buildUpdateProductEntitlementsRequest(entitlements, entitlementsPristine) {
        const requestBody = {
            addedUserClaims: [],
            addedUserClaimAccounts: {},
            removedUserClaims: [],
            removedUserClaimAccounts: {},
        };

        appendUserClaimsToProductEntitlementsRequest(
            requestBody,
            entitlements.userProductClaims,
            entitlementsPristine.userProductClaims
        );

        appendAccountClaimsToProductEntitlementsRequest(
            requestBody,
            entitlements.accounts,
            entitlementsPristine.accounts
        );

        return requestBody;
    }

    function updateProductEntitlements(companyId, userId, productFeatureConfig) {
        return $resource(
            `${resourceUrl}:userId/productentitlements`,
            { id: companyId, userId },
            { update: { method: 'PUT' } }
        ).update(productFeatureConfig).$promise;
    }

    function updateAchEntitlements(companyId, userId, productFeatureConfig) {
        return $resource(
            `${resourceUrl}:userId/achproductentitlements`,
            { id: companyId, userId },
            { update: { method: 'PUT' } }
        ).update(productFeatureConfig).$promise;
    }

    function changeUserActiveStatus(companyId, userId, isActive, comments) {
        const resource = $resource(
            `${resourceUrl}:id/setActive?isActive=:isActive&comments=:comments`,
            { id: companyId, userId, isActive, comments },
            { update: { method: 'PUT' } }
        );
        return resource.update().$promise;
    }

    function unlockUserChallenge(companyId, userId, recollect) {
        const resource = $resource(
            `${resourceUrl}:userId/unlockUserChallenge`,
            { id: companyId, userId, recollect },
            { update: { method: 'PUT' } }
        );
        return resource.update(userId).$promise;
    }

    function reCollectUserChallengeInformation(companyId, userId) {
        const resource = $resource(
            `${resourceUrl}:userId/reCollectUserChallenge`,
            { id: companyId, userId },
            { update: { method: 'PUT' } }
        );
        return resource.update(userId).$promise;
    }

    function getIpRestrictions(companyId, userId) {
        return $resource(`${resourceUrl}:userId/ipRestrictions`, {
            id: companyId,
            userId,
        }).get().$promise;
    }

    function updateIpRetrictions(companyId, userId, restricted, ipRestrictions) {
        return $resource(
            `${resourceUrl}:userId/ipRestrictions`,
            { id: companyId, userId, restricted },
            { update: { method: 'PUT' } }
        ).update(ipRestrictions).$promise;
    }

    function getUserTimeAccess(companyId, userId) {
        return $resource(`${resourceUrl}:userId/usertimeaccess`, {
            id: companyId,
            userId,
        }).get().$promise;
    }

    function saveUserTimeAccess(companyId, userId, userTimeAccess) {
        return $resource(`${resourceUrl}:userId/usertimeaccess`, {
            id: companyId,
            userId,
        }).save(userTimeAccess).$promise;
    }

    function getUserSsoPosPaySettings(companyId, userId) {
        return $resource(`${resourceUrl}:userId/ssopospaysettings`, {
            id: companyId,
            userId,
        }).get().$promise;
    }

    function updateUserSsoPosPaySettings(companyId, userId, userSsoPosPaySettings) {
        return $resource(
            `${resourceUrl}:userId/ssopospaysettings`,
            { id: companyId, userId },
            { update: { method: 'PUT' } }
        ).update(userSsoPosPaySettings).$promise;
    }

    function getUserReceivablesSettings(companyId, userId) {
        return $resource(`${resourceUrl}:userId/receivablessettings`, {
            id: companyId,
            userId,
        }).get().$promise;
    }

    function updateUserReceivablesSettings(companyId, userId, userReceivablesSettings) {
        return $resource(
            `${resourceUrl}:userId/receivablessettings`,
            { id: companyId, userId },
            { update: { method: 'PUT' } }
        ).update(userReceivablesSettings).$promise;
    }

    function appendUserClaimsToProductEntitlementsRequest(
        requestBody,
        userProductClaims,
        userProductClaimsPristine
    ) {
        if (userProductClaims == null || userProductClaimsPristine == null) {
            return;
        }

        for (let claimIndex = 0; claimIndex < userProductClaims.length; ++claimIndex) {
            const userProductClaim = userProductClaims[claimIndex];
            const userProductClaimPristine = userProductClaimsPristine[claimIndex];

            if (userProductClaim.claimId !== userProductClaimPristine.claimId) {
                $log.info('userProductClaim', userProductClaim);
                $log.info('userProductClaimPristine', userProductClaimPristine);
                throw new Error('ClaimId does not match pristine ClaimId');
            }

            if (
                userProductClaim.hasPendingUserClaim &&
                !userProductClaimPristine.hasPendingUserClaim
            ) {
                requestBody.addedUserClaims.push(userProductClaim.claimId);
            } else if (
                !userProductClaim.hasPendingUserClaim &&
                userProductClaimPristine.hasPendingUserClaim
            ) {
                requestBody.removedUserClaims.push(userProductClaim.claimId);
            }
        }
    }

    function appendAccountClaimsToProductEntitlementsRequest(
        requestBody,
        accounts,
        accountsPristine
    ) {
        if (accounts == null || accountsPristine == null) {
            return;
        }

        for (let accountIndex = 0; accountIndex < accounts.length; ++accountIndex) {
            const account = accounts[accountIndex];
            const pristineAccount = accountsPristine[accountIndex];

            for (let claimIndex = 0; claimIndex < account.userAccountClaims.length; ++claimIndex) {
                const accountClaim = account.userAccountClaims[claimIndex];
                const pristineAccountClaim = pristineAccount.userAccountClaims[claimIndex];

                if (accountClaim.claimId !== pristineAccountClaim.claimId) {
                    $log.info('accountClaim', accountClaim);
                    $log.info('pristineAccountClaim', pristineAccountClaim);
                    throw new Error('ClaimIds do not match');
                }

                if (accountClaim.hasPendingUserClaim && !pristineAccountClaim.hasPendingUserClaim) {
                    if (requestBody.addedUserClaimAccounts[accountClaim.claimId] === undefined) {
                        requestBody.addedUserClaimAccounts[accountClaim.claimId] = [];
                    }
                    requestBody.addedUserClaimAccounts[accountClaim.claimId].push(account.id);
                } else if (
                    !accountClaim.hasPendingUserClaim &&
                    pristineAccountClaim.hasPendingUserClaim
                ) {
                    if (requestBody.removedUserClaimAccounts[accountClaim.claimId] === undefined) {
                        requestBody.removedUserClaimAccounts[accountClaim.claimId] = [];
                    }
                    requestBody.removedUserClaimAccounts[accountClaim.claimId].push(account.id);
                }
            }
        }
    }
}
