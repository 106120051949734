import { Injectable } from '@jack-henry/frontend-utils/di';
import {
    AchChildSupportPaymentsClient,
    AchFederalTaxPaymentDto,
    AchPaymentModelDto,
    AchPaymentsClient,
    AchStateTaxPaymentDto,
    ChildSupportPaymentModelDto,
} from '@treasury/api/bo';
import {
    GetAchPaymentResponseDto,
    GetChildSupportPaymentResponseDto,
} from '@treasury/api/bo/generated/data-contracts';
import { downloadBlob } from '../../../utilities/file-handling';
import { AchChildSupportPayment, AchPayment, AchTaxPayment } from '../../types/ach/';

type achDownloadType = 'pdf' | 'csv' | 'nacha';

@Injectable()
export class AchPaymentsService {
    constructor(
        private readonly achPaymentsClient: AchPaymentsClient,
        private readonly achChildSupportPaymentsClient: AchChildSupportPaymentsClient
    ) {}

    public async getRegularPaymentById(id: string) {
        const dto = (await this.achPaymentsClient.achPaymentGet(id)).data;
        if (dto.errorMessage) {
            return new Error(dto.errorMessage);
        }
        if (!dto.achPayment) {
            return new Error('No ACH Payment found');
        }
        return new AchPayment(dto.achPayment);
    }

    public async getChildSupportPaymentById(id: string) {
        const dto = (await this.achChildSupportPaymentsClient.achChildSupportPaymentGet(id)).data;
        if (dto.errorMessage) {
            return new Error(dto.errorMessage);
        }
        if (!dto.childSupportPayment) {
            return new Error('No ACH Child Support Payment found');
        }
        return new AchChildSupportPayment(
            dto.childSupportPayment as ChildSupportPaymentModelDto & AchPaymentModelDto
        );
    }

    public async downloadAchPaymentDetails(id: string, outputType: achDownloadType) {
        const response = await this.achPaymentsClient.achPaymentDownloadAchPayment(id, {
            outputType,
        });
        const { content, fileName, mimeType } = response.data;
        if (!content) {
            throw new Error('No file content');
        }

        const extension = outputType === 'nacha' ? 'txt' : outputType;
        const filename = `${fileName}.${extension}`;

        if (outputType === 'pdf') {
            const linkSource = `data:application/pdf;base64,${content}`;
            const downloadLink = document.createElement('a');
            downloadLink.href = linkSource;
            downloadLink.download = fileName as string;
            downloadLink.click();
            return;
        }
        const binaryString = window.atob(content);
        const blob = new Blob([binaryString], { type: mimeType });
        return downloadBlob(blob, filename);
    }
}
