import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('stopPaymentService', stopPaymentService);

stopPaymentService.$inject = ['$resource'];

function stopPaymentService($resource) {
    const resourceUrl = `${getBackOfficeAPIPath()}stopPayment/`;

    return {
        getCompanyConfiguration,
        getEligibleAccounts,
        saveCompanyConfiguration,
    };

    function getCompanyConfiguration(companyId) {
        const request = $resource(`${resourceUrl}GetCompanyConfiguration/:companyId`, {
            companyId,
        });
        return request.get().$promise;
    }

    function getEligibleAccounts(companyId) {
        return $resource(`${resourceUrl}GetEligibleStopPaymentAccounts/:companyId`, {
            companyId,
        }).get().$promise;
    }

    function saveCompanyConfiguration(companyId, approvalCount, accountIds) {
        const requestBody = {
            companyId,
            approvalCount,
            accountIds,
        };
        const request = $resource(`${resourceUrl}SaveCompanyConfiguration`);
        return request.save(requestBody).$promise;
    }
}
