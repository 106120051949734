import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('loginParametersService', loginParametersService);

loginParametersService.$inject = ['$resource'];

function loginParametersService($resource) {
    const resourceUrl = `${getBackOfficeAPIPath()}loginparameters/`;
    return {
        getLoginParameters,
        updateLoginParameters,
        revertToDefaults,
    };

    function updateLoginParameters(loginParametersPayload) {
        const resource = $resource(
            `${resourceUrl}:id`,
            { id: loginParametersPayload.id },
            { update: { method: 'PUT' } }
        );
        return resource.update(loginParametersPayload).$promise;
    }

    function getLoginParameters(type) {
        return $resource(`${resourceUrl}?type=:type`, { type }).get().$promise;
    }

    function revertToDefaults(parameterId, isCompanyParameter) {
        return $resource(`${resourceUrl}:id/defaults/?isCompanyParameter=:isCompanyParameter`, {
            id: parameterId,
            isCompanyParameter,
        }).get().$promise;
    }
}
