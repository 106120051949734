/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-use-before-define */
import { AlarmClock } from '@treasury/alarm-clock';
import { AccountRequests } from '@treasury/domain/backoffice/requests';
import '../src/decorate-title.js';

// eslint-disable-next-line import/no-duplicates
import '@uirouter/angularjs';
import 'angular-animate/angular-animate.js';
import 'angular-cookies/angular-cookies.js';
import 'angular-file-saver/dist/angular-file-saver.bundle.js';
import 'angular-messages/angular-messages.js';
import 'angular-print/angularPrint.js';
import 'angular-sanitize/angular-sanitize.js';
import 'bootstrap/js/collapse';
import 'bootstrap/js/tooltip';

import 'bootstrap/js/popover';

import 'jquery';
import 'moment-timezone/builds/moment-timezone-with-data.js';
import 'signalr/jquery.signalR';
import 'smartmenus';

import '@treasury/omega/polyfills/browser.js';

import '@treasury/omega/polyfills/focus-traversal-api-polyfill.js';
import '@treasury/omega/polyfills/nodeListForEach.js';
import '@webcomponents/shadycss';
import '@webcomponents/webcomponentsjs';
import './app.module';

import '@treasury/domain/dates/fi-date/fiDate.js';
import './app.config.js';
import './cache/expirationCache.js';
import './company/profile/accountInfo/controllers/accountInfoCtrl.js';
import './company/profile/accountInfo/controllers/addAccountsCtrl.js';
import './company/profile/accountInfo/controllers/companyAccountSettingsCtrl.js';
import './company/profile/accountInfo/controllers/editAccountCtrl.js';
import './company/profile/accountInfo/controllers/manageTransfersCtrl.js';
import './company/profile/contactInfo/controllers/contactInfoCtrl.js';
import './company/profile/contactInfo/controllers/manageContactCtrl.js';
import './company/profile/detail/controllers/addAssociatedCompanyCtrl.js';
import './company/profile/detail/controllers/changeAuthenticationMethodModalCtrl.js';
import './company/profile/detail/controllers/deactivateAuthenticationModalCtrl.js';
import './company/profile/detail/controllers/detailContainerCtrl.js';
import './company/profile/detail/controllers/manageCompanyStatusCtrl.js';
import './company/profile/detail/controllers/onboardCompanyCtrl.js';
import './company/profile/detail/controllers/showCompanyCommentsCtrl.js';
import './company/profile/productFeatures/controllers/ManageAchCompanyStatusController.js';
import './company/profile/productFeatures/controllers/addAchCompanyModalCtrl.js';
import './company/profile/productFeatures/controllers/addProductCtrl.js';
import './company/profile/productFeatures/controllers/addWireCompanyCtrl.js';
import './company/profile/productFeatures/controllers/customizeAchCtrl.js';
import './company/profile/productFeatures/controllers/customizeArpCtrl.js';
import './company/profile/productFeatures/controllers/customizeBillPayCtrl.js';
import './company/profile/productFeatures/controllers/customizeElectronicDocumentsCtrl.js';
import './company/profile/productFeatures/controllers/customizeInternalTransferCtrl.js';
import './company/profile/productFeatures/controllers/customizeRemoteDepositCaptureCtrl.js';
import './company/profile/productFeatures/controllers/customizeStopPaymentCtrl.js';
import './company/profile/productFeatures/controllers/customizeWausauStatementsCtrl.js';
import './company/profile/productFeatures/controllers/customizeWireTransferCtrl.js';
import './company/profile/productFeatures/controllers/darkCustomizeArpCtrl.js';
import './company/profile/productFeatures/controllers/editProductCtrl.js';
import './company/profile/productFeatures/controllers/manageAchOffsetAccountsCtrl.js';
import './company/profile/productFeatures/controllers/manageWireCompanyAccountsCtrl.js';
import './company/profile/productFeatures/controllers/nonCoreInternationalWireCompanyCtrl.js';
import './company/profile/productFeatures/controllers/productFeaturesCtrl.js';
import './company/profile/productFeatures/directives/darkPositivePayAccountTable.js';
import './company/profile/productFeatures/directives/positivePayAccountTable.js';
import './company/profile/shared/assignProductsAccounts/controllers/assignProductsAccountsCtrl.js';
import './company/profile/userInfo/controllers/accountAccessCtrl.js';
import './company/profile/userInfo/controllers/accountReconciliationReportingEntitlementsCtrl.js';
import './company/profile/userInfo/controllers/achCompanyUserEntitlementsCtrl.js';
import './company/profile/userInfo/controllers/achUserEntitlementsCtrl.js';
import './company/profile/userInfo/controllers/achUserLimitsCtrl.js';
import './company/profile/userInfo/controllers/approvalSettingsCtrl.js';
import './company/profile/userInfo/controllers/approveRejectCtrl.js';
import './company/profile/userInfo/controllers/arpEntitlementsCtrl.js';
import './company/profile/userInfo/controllers/authenticationSettingsCtrl.js';
import './company/profile/userInfo/controllers/copyUserModalCtrl.js';
import './company/profile/userInfo/controllers/discardChangesCtrl.js';
import './company/profile/userInfo/controllers/informationReportingEntitlementsCtrl.js';
import './company/profile/userInfo/controllers/integratedServiceEntitlementsCtrl.js';
import './company/profile/userInfo/controllers/internalTransferEntitlementsCtrl.js';
import './company/profile/userInfo/controllers/internalTransferUserLimitsCtrl.js';
import './company/profile/userInfo/controllers/ipRestrictCtrl.js';
import './company/profile/userInfo/controllers/manageUserStatusCtrl.js';
import './company/profile/userInfo/controllers/mangeAchCompaniesCtrl.js';
import './company/profile/userInfo/controllers/receivablesUserSettingsCtrl.js';
import './company/profile/userInfo/controllers/recollectUserChallengeInformationCtrl.js';
import './company/profile/userInfo/controllers/reenableUserAuthenticationCtrl.js';
import './company/profile/userInfo/controllers/ssoPosPayUserSettingsCtrl.js';
import './company/profile/userInfo/controllers/stpEntitlementsCtrl.js';
import './company/profile/userInfo/controllers/timeAccessCtrl.js';
import './company/profile/userInfo/controllers/unlockUserChallengeCtrl.js';
import './company/profile/userInfo/controllers/userInfoCtrl.js';
import './company/profile/userInfo/controllers/userInformationCtrl.js';
import './company/profile/userInfo/controllers/userInformationPanelCtrl.js';
import './company/profile/userInfo/controllers/userSettingsCtrl.js';
import './company/profile/userInfo/controllers/wireTransferDliUserLimitsCtrl.js';
import './company/profile/userInfo/controllers/wireTransferUserLimitsCtrl.js';
import './company/profile/userInfo/controllers/wireTransfersEntitlementsCtrl.js';
import './company/search/controllers/companySearchCtrl.js';
import './configuration/accountAnalysis/controllers/aaCompanyConfigurationCtrl.js';
import './configuration/accountAnalysis/controllers/aaConfigAccountsModalCtrl.js';
import './configuration/accountAnalysis/controllers/aaConfigCancelCtrl.js';
import './configuration/accountAnalysis/controllers/aaConfigSaveCancelCtrl.js';
import './configuration/accountAnalysis/controllers/aaInstitutionConfigurationCtrl.js';
import './configuration/challengeGroups/controllers/challengeGroupDetailCtrl.js';
import './configuration/challengeGroups/controllers/challengeGroupListCtrl.js';
import './configuration/challengeGroups/controllers/groupAssociatedCompaniesCtrl.js';
import './configuration/contentManagement/controllers/contentManagementConfigurationCtrl.js';
import './configuration/dashboard/controllers/dashboardConfigurationCtrl.js';
import './configuration/dashboard/controllers/manageLinkCtrl.js';
import './configuration/dashboard/controllers/manageMessageCtrl.js';
import './configuration/fiRolesList/controllers/fiRolesListCtrl.js';
import './configuration/fiRolesList/controllers/manageFiRolesCtrl.js';
import './configuration/fiRolesList/controllers/prerequisiteConfirmationCtrl.js';
import './configuration/fiUserList/controllers/fiUserListCtrl.js';
import './configuration/fiUserList/controllers/ipSettingsModalCtrl.js';
import './configuration/fiUserList/controllers/manageFiUserCtrl.js';
import './configuration/fiUserList/controllers/manageUserStatusCtrl.js';
import './configuration/fiUserList/controllers/resetTokenMfaConfirmation.js';
import './configuration/fiUserList/controllers/tokenMfaSettingsCtrl.js';
import './configuration/fiUserList/controllers/unlockTokenMfaConfirmation.js';
import './configuration/messagingCenter/controllers/messagingCenterConfigurationCtrl.js';
import './configuration/notifications/configureNotificationsCtrl.js';
import './configuration/passwordParameters/controllers/passwordParametersCtrl.js';
import './configuration/processing/controllers/processingConfigurationCtrl.js';
import './configuration/productFeature/controllers/configureAchCtrl.js';
import './configuration/productFeature/controllers/configureAgileticsWebEscrow.js';
import './configuration/productFeature/controllers/configureBDIeStatementsCtrl.js';
import './configuration/productFeature/controllers/configureBillPayCtrl.js';
import './configuration/productFeature/controllers/configureElectronicDocumentsCtrl.js';
import './configuration/productFeature/controllers/configureInformationReportingCtrl.js';
import './configuration/productFeature/controllers/configureInternalTransferCtrl.js';
import './configuration/productFeature/controllers/configurePositivePayController.js';
import './configuration/productFeature/controllers/configureRemoteDepositCaptureSsoCtrl.js';
import './configuration/productFeature/controllers/configureStopPaymentCtrl.js';
import './configuration/productFeature/controllers/configureWausauStatementsCtrl.js';
import './configuration/productFeature/controllers/configureWireTransferCorpayController.js';
import './configuration/productFeature/controllers/configureWireTransferCtrl.js';
import './configuration/productFeature/controllers/configureWireTransferDliCtrl.js';
import './configuration/productFeature/controllers/darkConfigurePositivePayController.js';
import './configuration/productFeature/controllers/duplicateTransactionCodeErrorModalCtrl.js';
import './configuration/productFeature/controllers/manageSecCodesCtrl.js';
import './configuration/productFeature/controllers/productFeatureConfigurationCtrl.js';
import './configuration/productFeature/controllers/viewExchangeRatesCtrl.js';
import './configuration/termsAndConditions/controllers/termsAndConditionsConfigurationCtrl.js';
import './configuration/transactionCode/controllers/cloneTransactionCodeGroupsCtrl.js';
import './configuration/transactionCode/controllers/editTransactionCodeCtrl.js';
import './configuration/transactionCode/controllers/manageTransactionCodeGroupsCtrl.js';
import './configuration/transactionCode/controllers/syncTransactionCodeCtrl.js';
import './configuration/transactionCode/controllers/transactionCodeConfigurationCtrl.js';
import './constants/accountMaskTypes.js';
import './constants/companyTypes.js';
import './constants/downloadPage.js';
import './constants/loginStatus.js';
import './constants/numericLimits.js';
import './constants/regex.js';
import './constants/remoteDepositTypes.js';
import './constants/secureTokenStatus.js';
import './constants/uiMessages.js';
import './constants/userCredentialStatus.js';
import './constants/wireStatusTypes.js';
import './directives/components/IpAddressTable/IpAddressTableDir.js';
import './directives/components/attributeList/attributeListDir.js';
import './directives/components/checkImage/checkImageDir.js';
import './directives/components/checkImage/checkImageModal/checkImageCtrl.js';
import './directives/components/differenceFlag/differenceFlagDir.js';
import './directives/components/dynamicPopover/dynamicPopover.js';
import './directives/components/exportOptionBar/exportOptionBarDir.js';
import './directives/components/fileUpload/fileUploadDir.js';
import './directives/components/focusIf/focusIfDir.js';
import './directives/components/lockedUser/lockedUserDir.js';
import './directives/components/nonBlockingSpinner/nonBlockingSpinner.js';
import './directives/components/reportFilter/reportFilterDir.js';
import './directives/components/reportFilter/saveReportModal/saveReportModalCtrl.js';
import './directives/components/reportTable/reportTableCtrl.js';
import './directives/components/reportTable/reportTableDir.js';
import './directives/components/resizer/resizerDir.js';
import './directives/components/secureToken/symantecCredentialState.js';
import './directives/components/sidebarContainer/sidebarContainerDir.js';
import './directives/components/sortableTable/sortableTableDir.js';
import './directives/components/spinner/spinnerDir.js';
import './directives/components/stickyPanelFooter/stickyPanelFooterDir.js';
import './directives/components/superUserPanel/superUserPanelDir.js';
import './directives/components/supportEnabled/supportEnabledDir.js';
import './directives/components/termsAndConditions/termsAndConditions.component.js';
import './directives/components/viewOnlyException/viewOnlyExceptionDir.js';
import './directives/components/viewOnlyForSupport/viewOnlyForSupportDir.js';
import './directives/controls/download/downloadDir.js';
import './directives/controls/dropdownMultiSelect/dropdownMultiSelectDir.js';
import './directives/controls/file/fileDir.js';
import './directives/controls/jha-numeric.js';
import './directives/controls/ng-currency.js';
import './directives/controls/ng-phone.js';
import './directives/controls/print/printDir.js';
import './directives/controls/switch/switchDir.js';
import './directives/controls/toggle/toggleDir.js';
import './directives/utility.js';
import './filters/camelCaseFilter.js';
import './filters/dateTime.js';
import './filters/orderByNumeric.js';
import './filters/orderByReport.js';
import './filters/regex.js';
import './filters/reportDataFilter.js';
import './jhaSupport/controllers/institutionSearchCtrl.js';
import './login/controllers/answerSecurityQuestionsCtrl.js';
import './login/controllers/authenticateSecureTokenCtrl.js';
import './login/controllers/changePasswordCtrl.js';
import './login/controllers/createSecurityQuestionsCtrl.js';
import './login/controllers/forgotPasswordCtrl.js';
import './login/controllers/forgotPasswordEmailConfirmationCtrl.js';
import './login/controllers/lockedOutCtrl.js';
import './login/controllers/loginCtrl.js';
import './login/controllers/registerSecureTokenCtrl.js';
import './login/controllers/unlockUserCtrl.js';
import './login/controllers/userVerificationCtrl.js';
import './login/controllers/userVerificationResetCtrl.js';
import './messageCenter/controllers/messageCenterArchiveModalCtrl.js';
import './messageCenter/controllers/messageCenterAssignModalCtrl.js';
import './messageCenter/controllers/messageCenterComposeModalCtrl.js';
import './messageCenter/controllers/messageCenterCtrl.js';
import './messageCenter/controllers/messageCenterResolveModalCtrl.js';
import './messageCenter/controllers/messageCenterSearchModalCtrl.js';
import './messageCenter/controllers/messageCenterViewModalCtrl.js';
import './polyfills/Number.js';
import './reports/controllers/reportsCtrl.js';
import './reports/controllers/reportsDashboardCtrl.js';
import './reports/controllers/wireDetailsCtrl.js';
import './reports/controllers/wireHistoryCtrl.js';
import './services/accountSrvc.js';
import './services/accountsSrvc.js';
import './services/achCompaniesSrvc.js';
import './services/achSrvc.js';
import './services/arpSrvc.js';
import './services/baiConfigurationSrvc.js';
import './services/challengeGroupsSrvc.js';
import './services/checkImageSrvc.js';
import './services/common/alertSrvc.js';
import './services/common/authInterceptorSrvc';
import './services/common/downloadSrvc.js';
import './services/common/entitlementsSrvc.js';
import './services/common/modalSrvc.js';
import './services/common/pagePushSrvc';
import './services/common/resolvePropertySrvc.js';
import './services/common/scopeSrvc.js';
import './services/common/spinnerSrvc.js';
import './services/companiesSrvc.js';
import './services/companyUserAccountSettingsSrvc.js';
import './services/companyUserAdminSettingsSrvc.js';
import './services/companyUserChallengeSettingsSrvc.js';
import './services/companyUsersSrvc.js';
import './services/configurationSrvc.js';
import './services/contentManagementConfigurationSrvc.js';
import './services/cutOffConfigurationSrvc.js';
import './services/fiPermissionSrvc.js';
import './services/holidayExceptionsSrvc.js';
import './services/informationCenterConfigurationSrvc.js';
import './services/ipSettingsSrvc.js';
import './services/jhaSupportService.js';
import './services/loginParametersSrvc.js';
import './services/loginStateSrvc.js';
import './services/messagingCenterSrvc.js';
import './services/notificationSrvc.js';
import './services/ofxSettingsSrvc.js';
import './services/qboSettingsSrvc.js';
import './services/reportsSrvc.js';
import './services/resourcePanelConfigurationSrvc.js';
import './services/rolesSrvc.js';
import './services/secCodeSrvc.js';
import './services/secureChallengeSrvc.js';
import './services/secureTokenSrvc.js';
import './services/stopPaymentSrvc.js';
import './services/termsAndConditionsSrvc.js';
import './services/transactionCodeGroupsSrvc.js';
import './services/transactionCodesSrvc.js';
import './services/usersSrvc.js';
import './services/utilitySrvc.js';
import './services/wireCompaniesSrvc.js';
import './services/wireService.js';
import './shared/header/headerCtrl.js';
import './shared/pageWrapper/controllers/pageWrapperCtrl.js';
import './shared/resetPassword/controllers/resetPasswordCtrl.js';
import './shared/userMultiSelect/userMultiSelect.js';

import '../Scripts/jquery-migrate/jquery-migrate-3.3.1.min.js';
import '../Scripts/jquery-watch/jquery-watch.min.js';

import '../Scripts/alphanum/jquery.alphanum.js';
import '../Scripts/angular-local-storage/angular-local-storage.js';
import '../Scripts/angular/angular-resource.js';
import '../Scripts/kendo/kendo.all.min.js';
import '../Scripts/navigation/navigation.js';
import '../Scripts/ngFileUpload/ng-file-upload.js';
import '../Scripts/toaster/toaster.js';
import '../Scripts/uiBootstrap/bootstrap.js';
import '../Scripts/uiBootstrap/ui-bootstrap-tpls-0.14.0.js';
import '../src/components/blocking-loader.js';

import './company/search/components/userSearch.js';

import '@treasury/omega/components/feature-flags/feature-flag-button';

import { AuthenticationService } from '@treasury/domain/services/authentication';
// eslint-disable-next-line import/no-duplicates
import { StateObject, StateParams, StateService, TransitionService } from '@uirouter/angularjs';
import { ITemplateCacheService } from 'angular';
import { NavigationRouteMap, registerConstants } from './app.constants';
import { NavigationService, registerServices } from './services';

interface AugmentedRootScope extends ng.IRootScopeService {
    bodylayout: 'login' | 'page-container';
    showHeader: boolean;
}

const boModule = angular.module('backOffice');

patchUiRouter();
registerServices(boModule);
registerConstants(boModule);

boModule.run([
    '$rootScope',
    'accountService',
    '$transitions',
    '$templateCache',
    'entitlementsService',

    function (
        $rootScope: AugmentedRootScope,
        accountService: any,
        $transitions: TransitionService,
        $templateCache: ng.ITemplateCacheService,
        entitlementsService: any
    ) {
        cacheTemplates($templateCache);

        $transitions.onStart({}, $transition => {
            const loginPages = [
                '',
                'login',
                'version',
                'forgot-password-verification',
                'change-password',
                'create-security-questions',
                'forgot-password',
                'locked-out',
                'unlock-user',
                'authenticate-secure-token',
                'register-secure-token',
                'answer-security-questions',
                'email-sent-confirmation',
                'user-verification',
                'user-verification-reset',
                'xperienceLogin',
            ];

            if (
                loginPages.indexOf($transition.$to().name) !== -1 ||
                $transition.$to().name.indexOf('login') !== -1
            ) {
                $rootScope.bodylayout = 'login';
                $rootScope.showHeader = false;
            } else {
                $rootScope.bodylayout = 'page-container';
                $rootScope.showHeader = true;

                AccountRequests.getCurrentFiDateTime().then((result: string) => {
                    AlarmClock.getInstance().time = result;
                });

                // hack; navigating to a route with a valid auth token circumvents login flow so
                // that is not a sure way to determine if authentication ocurred
                // if we successfully retrieved the entitlements, we know authentication has ocurred
                return (entitlementsService.getEntitlements() as Promise<any>)
                    .then(() => AuthenticationService.getInstance())
                    .then(authService => {
                        if (!authService.authenticated) {
                            authService.authenticate();
                        }
                    });
            }
        });
    },
]);

boModule.run([
    '$state',
    'entitlementsService',
    '$transitions',
    function ($state: StateService, entitlementsService: any, $transitions: TransitionService) {
        $transitions.onStart({}, $transition => {
            const { data } = $transition.$to();
            if (
                data &&
                data.entitlements &&
                !entitlementsService.hasAnyEntitlement(data.entitlements)
            ) {
                return $state.target('blank');
            }
        });
        $transitions.onError({}, $transition => {
            const error = $transition.error();
            if (error.redirected) {
                console.debug('ui-router state change was redirected', error);
            } else {
                console.warn('ui-router state change error', error);
            }
        });
    },
]);

boModule.run([
    '$transitions',
    'navigationRouteMap',
    'navigationService',
    function (
        $transitions: TransitionService,
        navigationRouteMap: NavigationRouteMap,
        navigationService: NavigationService
    ) {
        $transitions.onSuccess({}, transition => {
            const destinationNavigation = findNavigationFromRoute(
                transition.$to(),
                navigationRouteMap
            );

            if (destinationNavigation === undefined) {
                return;
            }

            navigationService.userActivityAudit(destinationNavigation);
        });
    },
]);

function findNavigationFromRoute(
    destinationState: StateObject,
    navigationRouteMap: NavigationRouteMap
) {
    const properties = Object.getOwnPropertyNames(
        navigationRouteMap
    ) as (keyof NavigationRouteMap)[];

    for (let i = 0; i < properties.length; ++i) {
        const navigation = properties[i];
        const route = navigationRouteMap[navigation];

        if (route === destinationState.name) {
            return navigation;
        }
    }

    return undefined;
}

function cacheTemplates($templateCache: ITemplateCacheService) {
    // Add templates referenced in the index.html page to the template cache
    $templateCache.put('app/shared/header/header.html', require('./shared/header/header.html'));
    // Add templates reference in JS snippets, not from .html files that are picked up by the nginclude-loader
    $templateCache.put(
        '/app/directives/components/sortableTable/sortableTableSortControl.html',
        require('./directives/components/sortableTable/sortableTableSortControl.html')
    );
    // Add templates from ng-includes in html files
    $templateCache.put(
        '/app/company/profile/accountInfo/views/accountInfoView.html',
        require('./company/profile/accountInfo/views/accountInfoView.html')
    );
    $templateCache.put(
        '/app/company/profile/productFeatures/views/productFeaturesView.html',
        require('./company/profile/productFeatures/views/productFeaturesView.html')
    );
    $templateCache.put(
        '/app/company/profile/userInfo/views/userInfoView.html',
        require('./company/profile/userInfo/views/userInfoView.html')
    );
    $templateCache.put(
        'app/company/profile/productFeatures/views/customizeInformationReporting.html',
        require('./company/profile/productFeatures/views/customizeInformationReporting.html')
    );
    $templateCache.put(
        'app/company/profile/productFeatures/views/customizeInternalTransfer.html',
        require('./company/profile/productFeatures/views/customizeInternalTransfer.html')
    );
    $templateCache.put(
        'app/company/profile/productFeatures/views/customizeArp.html',
        require('./company/profile/productFeatures/views/customizeArp.html')
    );
    $templateCache.put(
        'app/company/profile/productFeatures/views/darkCustomizeArp.html',
        require('./company/profile/productFeatures/views/darkCustomizeArp.html')
    );
    $templateCache.put(
        'app/company/profile/userInfo/views/arpEntitlementsForm.html',
        require('./company/profile/userInfo/views/arpEntitlementsForm.html')
    );
    $templateCache.put(
        '/app/directives/components/secureToken/vipLinkLabel.html',
        require('./directives/components/secureToken/vipLinkLabel.html')
    );
    $templateCache.put(
        '/app/directives/components/secureToken/vipLinkButton.html',
        require('./directives/components/secureToken/vipLinkButton.html')
    );
    $templateCache.put(
        '/app/directives/components/secureToken/vipLinkButton.html',
        require('./directives/components/secureToken/vipLinkButton.html')
    );
    $templateCache.put(
        '/app/directives/components/secureToken/resetCredentialsLabel.html',
        require('./directives/components/secureToken/resetCredentialsLabel.html')
    );
    $templateCache.put(
        '/app/directives/components/secureToken/resetCredentialsLabel.html',
        require('./directives/components/secureToken/resetCredentialsLabel.html')
    );
    $templateCache.put(
        'app/company/profile/userInfo/views/userInformationTemplate.html',
        require('./company/profile/userInfo/views/userInformationTemplate.html')
    );
    $templateCache.put(
        'app/company/profile/userInfo/views/accountAccessTemplate.html',
        require('./company/profile/userInfo/views/accountAccessTemplate.html')
    );
    $templateCache.put(
        'app/company/profile/userInfo/views/ipRestrictTemplate.html',
        require('./company/profile/userInfo/views/ipRestrictTemplate.html')
    );
    $templateCache.put(
        'app/company/profile/userInfo/views/timeAccessTemplate.html',
        require('./company/profile/userInfo/views/timeAccessTemplate.html')
    );
    $templateCache.put(
        'app/company/profile/userInfo/views/internalTransferUserLimits.html',
        require('./company/profile/userInfo/views/internalTransferUserLimits.html')
    );
    $templateCache.put(
        'app/company/profile/userInfo/views/achUserLimits.html',
        require('./company/profile/userInfo/views/achUserLimits.html')
    );
    $templateCache.put(
        'app/company/profile/userInfo/views/achUserEntitlements.html',
        require('./company/profile/userInfo/views/achUserEntitlements.html')
    );
    $templateCache.put(
        'app/company/profile/userInfo/views/wireTransferUserLimits.html',
        require('./company/profile/userInfo/views/wireTransferUserLimits.html')
    );
    $templateCache.put(
        'app/company/profile/userInfo/views/wireTransferDliUserLimits.html',
        require('./company/profile/userInfo/views/wireTransferDliUserLimits.html')
    );
    $templateCache.put(
        'app/company/profile/userInfo/views/wireTransfersEntitlementsTemplate.html',
        require('./company/profile/userInfo/views/wireTransfersEntitlementsTemplate.html')
    );
    $templateCache.put(
        'app/company/profile/userInfo/views/arpEntitlementsTemplate.html',
        require('./company/profile/userInfo/views/arpEntitlementsTemplate.html')
    );
    $templateCache.put(
        'app/company/profile/userInfo/views/achCompanyUserEntitlements.html',
        require('./company/profile/userInfo/views/achCompanyUserEntitlements.html')
    );
    $templateCache.put(
        'app/company/profile/userInfo/views/ssoPosPayUserSettings.html',
        require('./company/profile/userInfo/views/ssoPosPayUserSettings.html')
    );
    $templateCache.put(
        'app/company/profile/userInfo/views/wireTransfersDliEntitlementsTemplate.html',
        require('./company/profile/userInfo/views/wireTransfersDliEntitlementsTemplate.html')
    );
    $templateCache.put(
        'app/company/profile/userInfo/views/internalTransferEntitlementsTemplate.html',
        require('./company/profile/userInfo/views/internalTransferEntitlementsTemplate.html')
    );
    $templateCache.put(
        'app/company/profile/userInfo/views/accountReconciliationReportingEntitlementsTemplate.html',
        require('./company/profile/userInfo/views/accountReconciliationReportingEntitlementsTemplate.html')
    );
    $templateCache.put(
        'app/company/profile/userInfo/views/informationReportingEntitlementsTemplate.html',
        require('./company/profile/userInfo/views/informationReportingEntitlementsTemplate.html')
    );
    $templateCache.put(
        'app/company/profile/userInfo/views/stpEntitlementsTemplate.html',
        require('./company/profile/userInfo/views/stpEntitlementsTemplate.html')
    );
    $templateCache.put(
        'app/company/profile/userInfo/views/receivablesUserSettings.html',
        require('./company/profile/userInfo/views/receivablesUserSettings.html')
    );
    $templateCache.put(
        'app/company/profile/userInfo/views/integratedServicesEntitlementsTemplate.html',
        require('./company/profile/userInfo/views/integratedServicesEntitlementsTemplate.html')
    );
    $templateCache.put(
        'app/company/profile/userInfo/views/wireTransferEntitlementsForm.html',
        require('./company/profile/userInfo/views/wireTransferEntitlementsForm.html')
    );
    $templateCache.put(
        'app/configuration/termsAndConditions/views/viewTerms.html',
        require('./configuration/termsAndConditions/views/viewTerms.html')
    );

    $templateCache.put(
        'app/configuration/termsAndConditions/views/editTerms.html',
        require('./configuration/termsAndConditions/views/editTerms.html')
    );

    $templateCache.put(
        'app/reports/views/genericReport.html',
        require('./reports/views/genericReport.html')
    );

    $templateCache.put(
        'app/reports/views/wireRecon.html',
        require('./reports/views/wireRecon.html')
    );

    $templateCache.put('app/reports/views/achRecon.html', require('./reports/views/achRecon.html'));

    $templateCache.put(
        'app/configuration/productFeature/views/configureInformationReporting.html',
        require('./configuration/productFeature/views/configureInformationReporting.html')
    );

    $templateCache.put(
        'app/configuration/productFeature/views/configurePositivePay.html',
        require('./configuration/productFeature/views/configurePositivePay.html')
    );
    $templateCache.put(
        'app/configuration/productFeature/views/darkConfigurePositivePay.html',
        require('./configuration/productFeature/views/darkConfigurePositivePay.html')
    );
}

/**
 * Patches Angular UI Router to recover from error during route transitions
 * due to changes from JH Xperience scripts causing errors.
 *
 * TODO: remove when version 2 of Xperience has been patched or we migrate to version 3.
 */
function patchUiRouter() {
    const originalInherit = StateParams.prototype.$inherit;
    StateParams.prototype.$inherit = function (...args) {
        try {
            return originalInherit.apply(this, args);
        } catch (e) {
            // swallow the error and simply return the provided object
            return {
                ...args[0],
            };
        }
    };
}
