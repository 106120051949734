angular.module('backOffice').directive('exportOptionBar', [
    function () {
        return {
            restrict: 'EA',
            scope: {
                exportOptions: '=',
                downloadOptions: '=?',
            },
            template: require('./exportOptionBarTemplate.html'),
            controller: [
                '$scope',
                function ($scope) {
                    $scope.canPrint = function () {
                        return !!$scope.exportOptions && !!$scope.exportOptions.canPrint;
                    };

                    $scope.$watch(
                        'exportOptions',
                        newValue => {
                            if (newValue !== null && newValue !== undefined) {
                                $scope.exportOptions = newValue;
                                if (
                                    $scope.downloadOptions === null ||
                                    $scope.downloadOptions === undefined
                                ) {
                                    $scope.exportOptions.downloadOptions = [
                                        'CSV',
                                        'PDF',
                                        'BAI',
                                        'TIFF',
                                        'Word',
                                        'Quicken (QFX)',
                                        'Quickbooks (QBO)',
                                    ];
                                } else
                                    $scope.exportOptions.downloadOptions = $scope.downloadOptions;

                                $scope.exportOptions.isOpen = false;
                            }
                        },
                        true
                    );
                },
            ],
        };
    },
]);
