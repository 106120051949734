angular.module('backOffice').constant(
    'remoteDepositTypes',
    Object.freeze({
        UNDEFINED: 0,
        WAUSAU: 1,
        SMARTPAY: 2,
        properties: {
            1: {
                value: 1,
                name: 'Wausau',
                description: 'Wausau Deposit 24/7',
                order: 2,
                ssoLoginSystemId: 1,
            },
            2: {
                value: 2,
                name: 'SmartPay',
                description: 'ProfitStars Enterprise Payment Solutions',
                order: 1,
                ssoLoginSystemId: 3,
            },
        },
    })
);
