angular
    .module('backOffice')
    .controller(
        'NonCoreInternationalWireCompanyController',
        nonCoreInternationalWireCompanyController
    );

nonCoreInternationalWireCompanyController.$inject = [
    '$scope',
    'wireService',
    '$modalInstance',
    'wireCompanyId',
    'productFeature',
    'utilityService',
    'regexConstants',
    'numericLimitConstants',
];

function nonCoreInternationalWireCompanyController(
    $scope,
    wireService,
    $modalInstance,
    wireCompanyId,
    productFeature,
    utilityService,
    regexConstants,
    numericLimitConstants
) {
    $scope.balanceValidationTypes = [
        { id: 1, label: 'Available' },
        { id: 2, label: 'Collected' },
        { id: 3, label: 'Current' },
    ];
    $scope.wireCompanyId = wireCompanyId;
    $scope.productFeature = productFeature;
    $scope.filteredAccounts = [];
    $scope.selectedBalanceValidationType = $scope.balanceValidationTypes[0];
    $scope.cancel = cancel;
    $scope.showAvailableBalanceTable = showAvailableBalanceTable;
    $scope.reset = reset;
    $scope.save = save;
    $scope.saveDisabled = saveDisabled;
    $scope.resetDisabled = resetDisabled;
    $scope.setForm = setForm;
    $scope.updateList = updateList;
    $scope.getLabelColumnWidthClass = getLabelColumnWidthClass;
    $scope.getFieldColumnWidthClass = getFieldColumnWidthClass;
    $scope.filterAccounts = filterAccounts;
    $scope.selectOrDeselectAll = selectOrDeselectAll;
    $scope.getMinimum = getMinimum;
    $scope.alphaNumericPlusLimitedSpecialCharsRegex =
        regexConstants.AlphaNumericPlusLimitedSpecialCharsRegex;
    $scope.maxLimitAmount = numericLimitConstants.WireCompanyMaxLimitAmount;
    $scope.numericRegex = regexConstants.NumericRegex;

    (function () {
        if ($scope.wireCompanyId) {
            wireService.getNonCoreInternationalWireCompany($scope.wireCompanyId).then(result => {
                $scope.wireCompany = result;
                $scope.pristineWireCompany = angular.copy($scope.wireCompany);
                initAccounts();
            });
        } else {
            $scope.wireCompany = {};
            $scope.pristineWireCompany = {};
        }
    })();

    function initAccounts() {
        $scope.accounts = $scope.wireCompany.nonCoreAccounts.map(account => {
            // Display 0 amounts as blank
            account.dailyLimit = account.dailyLimit ? account.dailyLimit : undefined;
            account.perTransactionLimit = account.perTransactionLimit
                ? account.perTransactionLimit
                : undefined;
            return account;
        });

        $scope.accounts = $scope.accounts.concat(
            $scope.wireCompany.eligibleAccounts.map(account => {
                account.accountId = account.id;
                return account;
            })
        );

        angular.copy($scope.accounts, $scope.filteredAccounts);
    }

    function filterAccounts() {
        const { filterText } = $scope.filteredAccounts;
        if (filterText) {
            $scope.filteredAccounts = $scope.accounts.filter(
                account =>
                    utilityService.containsText(account.number, filterText) ||
                    utilityService.containsText(account.name, filterText) ||
                    utilityService.containsText(account.type, filterText)
            );
            $scope.filteredAccounts.filterText = filterText;
        } else {
            angular.copy($scope.accounts, $scope.filteredAccounts);
        }
        updateList();
    }

    function selectOrDeselectAll() {
        angular.forEach($scope.filteredAccounts, account => {
            account.active = $scope.filteredAccounts.selectAll;
        });
    }

    function getLabelColumnWidthClass(isRequired) {
        return ($scope.wireCompany.id ? 'col-md-3' : 'col-md-6') + (isRequired ? ' required' : '');
    }

    function getFieldColumnWidthClass() {
        return $scope.wireCompany.id ? 'col-md-9' : 'col-md-6';
    }

    function updateList() {
        const result =
            $scope.filteredAccounts.length > 0 &&
            !$scope.filteredAccounts.some(account => !account.active);
        if (result) {
            $scope.filteredAccounts.selectAll = true;
        } else {
            $scope.filteredAccounts.selectAll = false;
        }
    }

    function saveDisabled() {
        return !$scope.form.$dirty || $scope.form.$invalid;
    }

    function resetDisabled() {
        return !$scope.form.$dirty;
    }

    function showAvailableBalanceTable() {
        return (
            $scope.wireCompany.accountBalanceValidationType == $scope.balanceValidationTypes[0].id
        );
    }

    function cancel() {
        $modalInstance.dismiss();
    }

    function reset() {
        angular.copy($scope.pristineWireCompany, $scope.wireCompany);
        angular.copy($scope.accounts, $scope.filteredAccounts);
        $scope.form.$setPristine();
    }

    function save() {
        if ($scope.wireCompany.id) {
            update();
        } else {
            create();
        }
    }

    function create() {
        wireService
            .createNonCoreInternationalWireCompany($scope.productFeature.id, $scope.wireCompany)
            .then(result => {
                $scope.wireCompany = result;
                $modalInstance.close($scope.wireCompany);
            });
    }

    function update() {
        const modifiedWireCompany = angular.copy($scope.wireCompany);
        modifiedWireCompany.nonCoreAccounts = $scope.filteredAccounts.filter(
            account => account.active || account.nonCoreAccountId
        );
        wireService
            .editNonCoreInternationalWireCompany($scope.productFeature.id, modifiedWireCompany)
            .then(result => {
                $modalInstance.close($scope.wireCompany);
            });
    }

    function setForm(form) {
        $scope.form = form;
    }

    function getMinimum(array) {
        return utilityService.getMinimum(array);
    }
}
