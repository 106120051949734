import { BankDto, BeneficiaryDto, WireDto } from '@treasury/domain/shared/types';
import { Record as FdlRecord } from '@treasury/FDL';
import '@treasury/omega/components/omega-field';
import { html } from 'lit';
import { renderReadOnlyAddress } from './render-read-only-address.template';

export function renderBeneficiary(
    beneficiary: FdlRecord<BeneficiaryDto>,
    wire: FdlRecord<WireDto>,
    bank: FdlRecord<BankDto>
) {
    const bankAddress = {
        addressLine1: bank.getField('addressLine1'),
        addressLine2: bank.getField('addressLine2'),
        addressLine3: bank.getField('addressLine3'),
        city: bank.getField('city'),
        state: bank.getField('state'),
        postalCode: bank.getField('postalCode'),
        country: bank.getField('country'),
    };
    const beneficiaryAddress = {
        addressLine1: beneficiary.getField('addressLine1'),
        addressLine2: beneficiary.getField('addressLine2'),
        addressLine3: beneficiary.getField('addressLine3'),
        city: beneficiary.getField('city'),
        state: beneficiary.getField('state'),
        postalCode: beneficiary.getField('postalCode'),
        country: beneficiary.getField('country'),
    };
    return html` <style>
            h1 {
                margin: 0 0 5px 10px;
                font-size: 15px;
                font-weight: 600;
                line-height: 1.1;
            }
            .addressRow {
                display: flex;
                flex-flow: row wrap;
                margin: 5px 10px;
            }
            .addressLabel {
                flex: 1 1 var(--omega-field-label-width, 200px);
                max-width: var(--omega-field-label-max-width, none);
            }
            .addressValue {
                flex: 3 1 var(--omega-field-control-width, 120px);
                max-width: var(--omega-field-control-max-width, none);
            }
        </style>
        <div class="details-body omega-flex-form">
            <div class="form-column">
                <h1>Beneficiary Information</h1>
                <omega-field field="accountNumber" .record=${beneficiary}></omega-field>
                <omega-field field="name" .record=${beneficiary}></omega-field>
                <div class="addressRow">
                    <div class="addressLabel">Address</div>
                    <div class="addressValue">${renderReadOnlyAddress(beneficiaryAddress)}</div>
                </div>
                <omega-field field="notes" .record=${beneficiary}></omega-field>
                <omega-field field="bankId" .record=${bank}></omega-field>
                <omega-field field="name" .record=${bank}></omega-field>
                <div class="addressRow">
                    <div class="addressLabel">Bank Address</div>
                    <div class="addressValue">${renderReadOnlyAddress(bankAddress)}</div>
                </div>
            </div>
        </div>`;
}
