angular
    .module('backOffice')
    .controller('ConfigureWireTransferController', configureWireTransferController);

configureWireTransferController.$inject = [
    '$scope',
    'productFeatureId',
    '$modalInstance',
    'configurationService',
    'modalService',
    'toaster',
    'canEdit',
    'entitlementsService',
];

function configureWireTransferController(
    $scope,
    productFeatureId,
    $modalInstance,
    configurationService,
    modalService,
    toaster,
    canEdit,
    entitlementsService
) {
    $scope.approvalOptions = [
        { label: 'None', id: 0 },
        { label: '1', id: 1 },
        { label: '2', id: 2 },
        { label: '3', id: 3 },
    ];
    $scope.maxFutureDaysOptions = [5, 10, 15, 30, 60, 90, 120, 180, 366];
    $scope.canEdit = canEdit;
    $scope.productFeatureId = productFeatureId;

    $scope.cancel = cancel;
    $scope.save = save;
    $scope.toggleInternational = toggleInternational;
    $scope.disableInternational = disableInternational;
    $scope.togglePurpose = function () {
        $scope.productFeature.requirePurpose = !$scope.productFeature.requirePurpose;
    };

    $scope.haveWireLimitThresholds = haveWireLimitThresholds;
    $scope.canAddAnotherThreshold = canAddAnotherThreshold;
    $scope.deleteThreshold = deleteThreshold;
    $scope.isOnlyOneRow = isOnlyOneRow;
    $scope.isLastRow = isLastRow;
    $scope.checkWireLimitThresholds = checkWireLimitThresholds;
    $scope.getAvailableApprovalCounts = getAvailableApprovalCounts;
    $scope.addThreshold = addThreshold;
    $scope.getPreviousEndAmount = getPreviousEndAmount;
    $scope.getMaximum = getMaximum;
    $scope.toggleLimitFutureDated = toggleLimitFutureDated;
    $scope.toggleAllowWireFileUpload = toggleAllowWireFileUpload;
    $scope.thresholdOptions = {
        useWireLimitThresholds: false,
    };
    $scope.endAmountIncrement = 10000;

    init();

    function init() {
        loadProductFeature();
    }

    function toggleLimitFutureDated() {
        $scope.productFeature.limitFutureDateEnabled =
            !$scope.productFeature.limitFutureDateEnabled;
        $scope.productFeature.maximumFutureDays = $scope.productFeature.limitFutureDateEnabled
            ? 5
            : undefined;
    }

    function toggleAllowWireFileUpload() {
        $scope.productFeature.allowWireFileUpload = !$scope.productFeature.allowWireFileUpload;
    }

    function getPreviousEndAmount(index) {
        if (index !== 0) {
            $scope.productFeature.paymentThresholdApprovals[index].startAmount =
                $scope.productFeature.paymentThresholdApprovals[index - 1].endAmount;
            return $scope.productFeature.paymentThresholdApprovals[index - 1].endAmount;
        }
        $scope.productFeature.paymentThresholdApprovals[index].startAmount = 0.0;
        return 0.0;
    }

    function getAvailableApprovalCounts(index, threshold) {
        // SLewis@jackhenry.com: Seems like there should be a more elegant way to do this
        const nextLowestApprovalCount =
            index === 0
                ? threshold.approvalCount
                : $scope.productFeature.paymentThresholdApprovals[index - 1].approvalCount;
        const nextHighestApprovalCount =
            index === $scope.productFeature.paymentThresholdApprovals.length - 1
                ? threshold.approvalCount
                : $scope.productFeature.paymentThresholdApprovals[index + 1].approvalCount;
        return $scope.approvalOptions.filter(approvalOption => {
            if (approvalOption.id === threshold.approvalCount) {
                return true;
            }
            if (
                approvalOption.id < nextLowestApprovalCount &&
                threshold.approvalCount <= nextLowestApprovalCount
            ) {
                return true;
            }
            if (
                approvalOption.id > nextLowestApprovalCount &&
                approvalOption.id < nextHighestApprovalCount
            ) {
                return true;
            }
            if (
                approvalOption.id > nextHighestApprovalCount &&
                threshold.approvalCount >= nextHighestApprovalCount
            ) {
                return true;
            }
            return false;
        });
    }

    function checkWireLimitThresholds() {
        if (
            !$scope.productFeature.paymentThresholdApprovals ||
            $scope.productFeature.paymentThresholdApprovals.length <= 0
        ) {
            $scope.productFeature.paymentThresholdApprovals = [
                {
                    startAmount: 0.0,
                    endAmount: 10000,
                    approvalCount: 0,
                },
                {
                    startAmount: 10000,
                    endAmount: null,
                    approvalCount: 1,
                },
            ];
        }
    }

    function haveWireLimitThresholds() {
        if ($scope.productFeature) {
            if (
                !!$scope.productFeature.paymentThresholdApprovals &&
                $scope.productFeature.paymentThresholdApprovals.length > 0
            ) {
                return true;
            }
        }
        return false;
    }

    function canAddAnotherThreshold(index, item) {
        if (isLastRow(index)) {
            if (item.approvalCount !== 3) {
                return true;
            }
        }
        return false;
    }

    function isLastRow(index) {
        return $scope.productFeature.paymentThresholdApprovals.length - 1 === index;
    }

    function addThreshold() {
        const previousEndAmount =
            $scope.productFeature.paymentThresholdApprovals[
                $scope.productFeature.paymentThresholdApprovals.length - 1
            ].endAmount;
        const previousStartAmount =
            $scope.productFeature.paymentThresholdApprovals[
                $scope.productFeature.paymentThresholdApprovals.length - 1
            ].startAmount;
        if (previousEndAmount) {
            $scope.productFeature.paymentThresholdApprovals[
                $scope.productFeature.paymentThresholdApprovals.length - 1
            ].endAmount += $scope.endAmountIncrement;
        } else {
            $scope.productFeature.paymentThresholdApprovals[
                $scope.productFeature.paymentThresholdApprovals.length - 1
            ].endAmount = previousStartAmount + $scope.endAmountIncrement;
        }
        $scope.productFeature.paymentThresholdApprovals.push({
            approvalCount:
                $scope.productFeature.paymentThresholdApprovals[
                    $scope.productFeature.paymentThresholdApprovals.length - 1
                ].approvalCount + 1,
            startAmount:
                $scope.productFeature.paymentThresholdApprovals[
                    $scope.productFeature.paymentThresholdApprovals.length - 1
                ].endAmount,
            endAmount: null,
        });
    }

    function getMaximum(index) {
        if (index === 0) {
            return 0;
        }
        return $scope.productFeature.paymentThresholdApprovals[index - 1].endAmount;
    }

    function deleteThreshold(index) {
        $scope.productFeature.paymentThresholdApprovals.splice(index, 1);
        $scope.productFeature.paymentThresholdApprovals[
            $scope.productFeature.paymentThresholdApprovals.length - 1
        ].endAmount = null;
    }

    function isOnlyOneRow() {
        return $scope.productFeature.paymentThresholdApprovals.length === 1;
    }

    function toggleInternational() {
        if (!$scope.productFeature.isInternational) {
            $scope.productFeature.isInternational = true;
        } else {
            $scope.productFeature.isInternational = false;
        }
    }

    function disableInternational() {
        return $scope.productFeature.wireDliInternationalEnabled;
    }

    function save() {
        $scope.productFeature.paymentApprovalType = !$scope.thresholdOptions.useWireLimitThresholds
            ? 'Overall'
            : 'Threshold';
        configurationService
            .saveProductFeature($scope.productFeatureId, $scope.productFeature)
            .then(response => {
                toaster.save('Wire Configuration');
                $modalInstance.close();
            });
    }

    function cancel() {
        $modalInstance.dismiss();
    }

    function loadProductFeature() {
        configurationService.getProductFeatureDetails($scope.productFeatureId).then(response => {
            $scope.productFeature = response;
            $scope.thresholdOptions.useWireLimitThresholds = !!(
                !!$scope.productFeature.paymentApprovalType &&
                $scope.productFeature.paymentApprovalType === 'Threshold'
            );
        });
    }
}
