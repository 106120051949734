import { getBackOfficeAPIPath } from '@treasury/core/http';

angular
    .module('backOffice')
    .factory('CompanyUserAdminSettingsService', companyUserAdminSettingsService);

companyUserAdminSettingsService.$inject = ['$resource'];

function companyUserAdminSettingsService($resource) {
    const resourceUrl = `${getBackOfficeAPIPath()}companyAdminSettings/`;
    return {
        getCompanyAdminSettings,
        updateCompanyAdminSettings,
    };

    function getCompanyAdminSettings() {
        return $resource(resourceUrl).get().$promise;
    }

    function updateCompanyAdminSettings(approvalSettings) {
        const resource = $resource(
            `${resourceUrl}:id`,
            { id: approvalSettings.id },
            { update: { method: 'PUT' } }
        );
        return resource.update(approvalSettings).$promise;
    }
}
