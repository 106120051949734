/* eslint-disable import/extensions */
import { TmHttpClient, TmHttpResponseType } from '@treasury/core/http';
import { downloadBlob } from '../../../utilities/file-handling.js';

export class UserActivityReportRequests {
    static async fetchCompanies() {
        return (await TmHttpClient.getInstance()).request(
            `reportFilter/companies?companytype=parentCompany`,
            {
                method: 'GET',
                maxAgeInSeconds: 6000,
            }
        );
    }

    static async fetchCompanyUsers(id) {
        return (await TmHttpClient.getInstance()).request(`users/channel?companyIds=${id}`, {
            method: 'GET',
            maxAgeInSeconds: 6000,
        });
    }

    static async fetchActivityTypes() {
        return (await TmHttpClient.getInstance()).request(`reportFilter/activityTypes`, {
            method: 'GET',
            maxAgeInSeconds: 6000,
        });
    }

    static async fetchChannelUserActivity(body) {
        return (await TmHttpClient.getInstance()).request(`reports/ChannelUserActivity`, {
            method: 'POST',
            body,
        });
    }

    static async fetchBackOfficeUserActivity(body) {
        return (await TmHttpClient.getInstance()).request(`reports/getUserActivity`, {
            method: 'POST',
            body,
        });
    }

    static async fetchAllBackOfficeUsers() {
        return (await TmHttpClient.getInstance()).request(`users/allusers`, {
            method: 'GET',
            maxAgeInSeconds: 60000,
        });
    }

    static async downloadReport(body, downloadType) {
        const http = await TmHttpClient.getInstance();
        const response = await http.request(`download/`, {
            method: 'POST',
            responseType: TmHttpResponseType.ArrayBuffer,
            body,
        });
        const isPdf = downloadType.toLowerCase().includes('pdf');
        const fileType = isPdf ? 'PDF' : 'CSV';
        const type = isPdf ? 'application/pdf' : 'application/octet-stream';
        const blob = new Blob([response], { type });
        const filename = `${body.filter.reportFilter.name}.${fileType.toLowerCase()}`;

        return downloadBlob(blob, filename);
    }

    static async saveReport(body) {
        return (await TmHttpClient.getInstance()).request(`reports/reportCustomFilter`, {
            method: 'POST',
            body,
        });
    }
}
