const { Feature, FeatureFlagService } = require('@treasury/domain/services/feature-flags');

angular.module('backOffice').controller('CompanySearchController', companySearchController);

const searchTypes = ['company', 'alternate', 'user'];

companySearchController.$inject = [
    '$scope',
    'companiesService',
    '$state',
    '$filter',
    'entitlementsService',
    'toaster',
    '$q',
    'TmDi',
    'userSearchQueryService',
];

/**
 *
 * @param { ng.IScope } $scope
 * @param { ng.IFilterService } $filter
 * @param { ng.IQService } $q
 * @param { import('@jack-henry/frontend-utils/di').DiContainer } TmDi
 */
function companySearchController(
    $scope,
    companiesService,
    $state,
    $filter,
    entitlementsService,
    toaster,
    $q,
    TmDi,
    userSearchQueryService
) {
    $scope.searchCriteriaList = [
        { name: 'Company Name' },
        { name: 'TAX ID Number' },
        { name: 'CIF Number' },
    ];
    $scope.itemsPerPageList = [{ name: 10 }, { name: 25 }, { name: 50 }, { name: 100 }];
    $scope.pagination = { currentPage: 1, itemsPerPage: 10 };
    $scope.searchPerformed = false;
    $scope.searching = true;
    $scope.isSearchDisabled = isSearchDisabled;
    $scope.isSearchButtonDisabled = isSearchButtonDisabled;
    $scope.canViewCompanyProfile = canViewCompanyProfile;
    $scope.searchType = 'company';
    $scope.companyFilter = '';
    $scope.companySearchList = [];
    $scope.companyListFiltered = [];
    $scope.onboardedCompanies = [];
    $scope.filterCompanyList = filterCompanyList;
    $scope.isUisEnabled = false;
    $scope.isBoUserSearchEnabled = false;

    $scope.determineMaxLength = function (type) {
        $scope.maxLength = 10;
        if (type === 'CIF Number') {
            $scope.maxLength = 25;
        }
        if (type === 'Company Name') {
            $scope.maxLength = 250;
        }
    };

    $scope.clearSearchCriteria = function () {
        $scope.searchCriteria.searchText = null;
        $scope.companyFilter = '';
    };

    $scope.changeSearchType = function (type) {
        $scope.searching = true;
        $scope.searchType = type;
        $state.go('.', { searchType: type }, { notify: false });

        if (type !== 'user') {
            userSearchQueryService.searchTerms.query = '';
            [userSearchQueryService.searchTerms.column] = userSearchQueryService.searchOptions;
        }

        if (type === 'company') {
            $scope.companySearchList = $scope.onboardedCompanies;
            $scope.companyListFiltered = $scope.onboardedCompanies;
            $scope.searchPerformed = true;
            $scope.clearSearchCriteria();
        } else if (type === 'alternate') {
            $scope.searchPerformed = false;
            $scope.companySearchList = null;
            $scope.companyFilter = null;
        }
    };
    async function initFeatureFlags() {
        const ffService = await TmDi.getAsync(FeatureFlagService);
        const uisPromise = ffService.isEnabled(Feature.UisEnabled);
        const boUserSearchPromise = ffService.isEnabled(Feature.BoUserSearch);

        return $q.all([uisPromise, boUserSearchPromise]).then(resolutions => {
            [$scope.isUisEnabled, $scope.isBoUserSearchEnabled] = resolutions;
        });
    }

    function init() {
        initFeatureFlags();
        initializeSearchCriteria();

        // Accessing URL query parameters using $state.params
        const { searchType } = $state.params;
        if (searchTypes.indexOf(searchType) > -1) {
            $scope.changeSearchType(searchType);
        } else {
            if (searchType !== undefined) {
                toaster.error(
                    `Invalid Search Type Parameter: The search type parameter: '${searchType}' is not valid.`
                );
            }
            $scope.changeSearchType('company');
        }

        $scope.searchCompanies();
    }

    // used to store the search criteria
    function initializeSearchCriteria() {
        $scope.searchCriteria = {
            searchText: null,
            searchType: null,
        };
        $scope.companyFilter = null;

        // business logic, select Company Name by default
        $scope.searchCriteria.searchType = $scope.searchCriteriaList[0].name;
    }

    $scope.searchCompanies = function () {
        $scope.searching = true;
        $scope.searchPerformed = false;

        if ($scope.searchType === 'company') {
            // company search
            companiesService
                .searchCompanies(
                    $scope.searchCriteria.searchType,
                    $scope.searchCriteria.searchText ?? ''
                )
                .then(
                    response => {
                        $scope.searching = false;
                        $scope.onboardedCompanies = response;
                        $scope.companySearchList = response;
                        filterCompanyList();
                        $scope.searchPerformed = true;
                    },
                    () => {
                        $scope.searching = false;
                    }
                );
        } else if ($scope.searchType === 'alternate') {
            // alternate search
            companiesService
                .getNotOnboardedCompanies(
                    $scope.searchCriteria.searchType,
                    $scope.searchCriteria.searchText ?? ''
                )
                .then(
                    response => {
                        $scope.searching = false;
                        $scope.companySearchList = response;
                        filterCompanyList();
                        $scope.searchPerformed = true;
                    },
                    () => {
                        $scope.searching = false;
                    }
                );
        } else {
            $scope.searching = false;
        }
    };

    $scope.navigateToCompany = function (location, company) {
        if (company.id) {
            $state.go(location.toLowerCase(), { id: company.id });
        } else {
            $state.go(location.toLowerCase(), {
                id: 0,
                cifNumber: company.cifNumber,
            });
        }
    };

    // used to validate search text
    $scope.searchTextPattern = (function () {
        return {
            test(value) {
                if (
                    $scope.searchCriteria === null ||
                    $scope.searchCriteria.searchType !== 'TAX ID Number'
                ) {
                    return true;
                }
                const regexp = /^[0-9]{9}$/;
                return regexp.test(value);
            },
        };
    })();

    function isSearchDisabled() {
        return (
            !entitlementsService.hasAnyEntitlement('Execute Company Search', 'Switch FI') ||
            $scope.searching
        );
    }

    function isSearchButtonDisabled() {
        return (
            $scope.searchForm.$invalid ||
            $scope.searchCriteria.searchText == undefined ||
            $scope.searchCriteria.searchText.length === 0 ||
            isSearchDisabled()
        );
    }

    function canViewCompanyProfile(company) {
        return (
            entitlementsService.hasAnyEntitlement(
                'Access Company Profile',
                'Switch FI',
                'View All Only'
            ) && company.status !== 'Associated'
        );
    }

    function filterCompanyList() {
        $scope.companyListFiltered = $filter('filter')(
            $scope.companySearchList,
            $scope.companyFilter
        );
    }

    init();
}
