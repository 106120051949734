angular
    .module('backOffice')
    .controller('DeactivateAuthenticationModalController', deactivateAuthenticationModalController);

deactivateAuthenticationModalController.$inject = [
    '$scope',
    '$modalInstance',
    'secureChallengeService',
    'companyId',
];

function deactivateAuthenticationModalController(
    $scope,
    $modalInstance,
    secureChallengeService,
    companyId
) {
    $scope.cancel = function () {
        $modalInstance.close('cancel');
    };
    $scope.confirm = function () {
        $modalInstance.close('confirm');
    };
}
