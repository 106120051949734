angular.module('backOffice').directive('boFileUpload', boFileUpload);

function boFileUpload() {
    return {
        restrict: 'E',
        scope: {
            onUpload: '&',
            /**
             * This will be the files that are currently selected in the file input. Setting this to any value
             * other than null or undefined will do nothing. If you set it to null or undefined it will clear out
             * the currently selected files from the file input. This is behavior is caused by the same limitations
             * of the input file control from not accepting any values except for clearing out the current value.
             */
            selectedFiles: '=',
        },
        bindToController: true,
        template: require('./fileUploadTemplate.html'),
        controller: FileUploadController,
        controllerAs: '$ctrl',
    };
}

function FileUploadController() {
    const ctrl = this;

    ctrl.selectedFiles = null;

    ctrl.isUploadDisabled = isUploadDisabled;
    ctrl.upload = upload;

    function isUploadDisabled() {
        return !ctrl.selectedFiles;
    }

    function upload() {
        ctrl.onUpload()(ctrl.selectedFiles);

        ctrl.selectedFiles = null;
    }
}
