import { AchPaymentModelDto, AchRecipientModelDto, AuditModelDto } from '@treasury/api/bo';
import {
    AchChildSupportPayment,
    AchChildSupportRecipient,
    AchInternationalPayment,
    AchInternationalRecipient,
    AchPayment,
    AchRecipient,
    AchTaxPayment,
    TaxAmountDescription,
} from '@treasury/domain/backoffice/types/ach';
import { boolean, Record as FdlRecord, FieldType, string } from '@treasury/FDL';
import { FdlFieldDefinitions } from '@treasury/FDL/record';
import { OmegaColumnDefinition, OmegaColumnType } from '@treasury/omega/components/table';
import fieldTypes from '@treasury/policy/primitives';
import { format } from 'date-fns';
import { html } from 'lit';
import { AchChildSupportPaymentDetail } from '../parts/ach-payment-templates/ach-child-support-payment-detail';
import { AchInternationalPaymentDetail } from '../parts/ach-payment-templates/ach-international-payment-detail';
import { AchPaymentDetail } from '../parts/ach-payment-templates/ach-payment-detail';

export const achPaymentAudit = new FieldType<string>().with
    .rowCount(5)
    .and.template<AuditModelDto[]>(auditArray => {
        const auditText = auditArray.map(
            audit =>
                html`${format(audit.timestamp, 'M/d/Y pp')} : ${audit.username ?? 'Unknown'} :
                    ${audit.action ?? 'Unknown action'}${audit.comments
                        ? `: ${audit.comments}`
                        : ''}<br />`
        );
        return html`<style>
                .audit-wrapper {
                    overflow-y: scroll;
                    min-width: 100px;
                    min-height: 90px;
                    max-height: 400px;
                    padding: 5px;
                    border: 1px solid #ccc;
                    background-color: #eee;
                }
            </style>
            <div class="audit-wrapper">${auditText}</div> `;
    });

const isOneTime = (record: FdlRecord<AchPayment<AchPaymentModelDto> | AchChildSupportPayment>) =>
    record.getField('frequency').paymentFrequencyType === 'One Time Payment';

export const AchPaymentFieldDefinitions: FdlFieldDefinitions<
    AchPayment<AchPaymentModelDto> | AchChildSupportPayment
> = {
    transactionId: string.thatIs.readOnly() as unknown as FieldType<string>,
    name: string.thatIs.readOnly() as unknown as FieldType<string>,
    status: string.with.label('Status').thatIs.readOnly() as unknown as FieldType<string>,
    companyName: string.with
        .label('ACH Company Name')
        .thatIs.readOnly() as unknown as FieldType<string>,
    companyId: string.with
        .label('ACH Company ID')
        .thatIs.readOnly() as unknown as FieldType<string>,
    entryDescription: string.with
        .label('Entry Description')
        .thatIs.readOnly() as unknown as FieldType<string>,
    discretionaryData: string.with
        .label('Discretionary Data')
        .thatIs.readOnly() as unknown as FieldType<string>,
    restrictPayment: boolean.with
        .label('Restricted Payment')
        .and.defaultValue(false)
        .and.disabled()
        .thatIs.visibleWhen((record: FdlRecord<AchChildSupportPayment>) =>
            record.getField('restrictPayment')
        ) as unknown as FieldType<boolean>,
    secCode: string.with.label('SEC Code').thatIs.readOnly() as unknown as FieldType<string>,
    debitAmount: fieldTypes.money.with.label('Debit').thatIs.readOnly() as FieldType<number>,
    creditAmount: fieldTypes.money.with.label('Credit').thatIs.readOnly() as FieldType<number>,
    frequencySummary: string.with
        .label('Frequency')
        .thatIs.readOnly()
        .thatIs.visibleWhen(
            (record: FdlRecord<AchPayment<AchPaymentModelDto>>) => !isOneTime(record)
        ),
    effectiveDate: string.with
        .label('Effective Date')
        .thatIs.readOnly()
        .thatIs.visibleWhen((record: FdlRecord<AchPayment<AchPaymentModelDto>>) =>
            isOneTime(record)
        )
        .and.template<string>(effectiveDate => {
            return format(effectiveDate, 'MM/dd/yyyy');
        }),
    offsetAccount: string.with
        .label('Offset Account')
        .thatIs.visibleWhen(
            (record: FdlRecord<AchPayment<AchPaymentModelDto>>) =>
                !!record.getField('offsetAccount')
        )
        .thatIs.readOnly() as unknown as FieldType<string | undefined>,
    audit: achPaymentAudit.with.label('Audit').thatIs.readOnly() as unknown as FieldType<
        AuditModelDto[] | undefined
    >,
};

export const AchRecipientFieldDefinitions: FdlFieldDefinitions<AchRecipient<AchRecipientModelDto>> =
    {
        name: string.with.label('Recipient Name').thatIs.readOnly() as unknown as FieldType<string>,
        idNumber: string.with.label('ID Number').thatIs.readOnly() as unknown as FieldType<string>,
        accountNumber: string.with
            .label('Account Number')
            .thatIs.readOnly() as unknown as FieldType<string>,
        accountType: string.with
            .label('Account Type')
            .thatIs.readOnly() as unknown as FieldType<string>,
        routingNumber: string.with
            .label('Routing Number')
            .thatIs.readOnly() as unknown as FieldType<string>,
        transactionType: string.with
            .label('Credit/Debit')
            .thatIs.readOnly() as unknown as FieldType<string>,
        amount: fieldTypes.money.with
            .label('Amount')
            .thatIs.readOnly() as unknown as FieldType<number>,
        prenote: fieldTypes.yesNoBoolean.with
            .label('Prenote')
            .thatIs.readOnly() as FieldType<boolean>,
        hold: fieldTypes.yesNoBoolean.with.label('Hold').thatIs.readOnly() as FieldType<boolean>,
        addendas: new FieldType<string[]>().with.template(addendas => {
            return html`${addendas.map(addenda => html`<p>${addenda}</p>`)}`;
        }),
    };

export const getAchRecipientColumns = (
    context: AchPaymentDetail
): OmegaColumnDefinition<AchRecipient<AchRecipientModelDto>>[] => [
    {
        label: 'Recipient Name',
        field: 'name',
    },
    {
        label: 'ID Number',
        field: 'idNumber',
    },
    {
        label: 'Account Number',
        field: 'accountNumber',
    },
    {
        label: 'Account Type',
        field: 'accountType',
    },
    {
        label: 'Routing Number',
        field: 'routingNumber',
    },
    {
        label: 'Transaction Type',
        field: 'transactionType',
    },
    {
        label: 'Amount',
        field: 'amount',
    },
    {
        label: 'Prenote',
        field: 'prenote',
    },
    {
        label: 'Hold',
        field: 'hold',
    },
    {
        label: 'Addenda',
        type: OmegaColumnType.Actions,
        actions: [
            {
                label: 'Addenda',
                action: 'openAddendaDialog',
                visibleWhen: record => record.getField('addendas').length > 0,
            },
        ],
    },
];
export const AchRecipientFilters = [
    {
        field: 'prenote',
        fieldType: boolean.with
            .label('Prenote Only')
            .thatIs.inline()
            .as.tag('omega-checkbox')
            .thatHas.schema('boolean'),
        value: false,
    },
    {
        field: 'hold',
        fieldType: boolean.with
            .label('Hold Only')
            .thatIs.inline()
            .as.tag('omega-checkbox')
            .thatHas.schema('boolean'),
        value: false,
    },
];

export const AchTaxPaymentFieldDefinitions: FdlFieldDefinitions<AchTaxPayment> = {
    ...(AchPaymentFieldDefinitions as FdlFieldDefinitions<AchTaxPayment>),
    transactionId: string.thatIs.readOnly() as unknown as FieldType<string>,
    name: string.thatIs.readOnly() as unknown as FieldType<string>,
    status: string.with.label('Status').thatIs.readOnly() as unknown as FieldType<string>,
    taxPaymentType: string.with.label('Tax Payment Type').thatIs.readOnly() as unknown as FieldType<
        'State' | 'Federal' | undefined
    >,
    state: string.with
        .label('State')
        .thatIs.readOnly()
        .thatIs.visibleWhen(record =>
            (record as FdlRecord<AchTaxPayment>).getField('isState')
        ) as unknown as FieldType<string | undefined>,
    taxCode: string.thatIs.readOnly() as unknown as FieldType<string>,
    taxPayerId: string.thatIs.readOnly() as unknown as FieldType<string>,
    companyName: string.with
        .label('ACH Company Name')
        .thatIs.readOnly() as unknown as FieldType<string>,
    amounts: new FieldType<TaxAmountDescription[]>().with
        .label(record =>
            (record as FdlRecord<AchTaxPayment>).getField('amounts').length > 1
                ? 'Amounts'
                : ('Amount' as string)
        )
        .thatIs.readOnly(),
    frequencySummary: string.with
        .label('Frequency')
        .thatIs.readOnly()
        .thatIs.visibleWhen(
            (record: FdlRecord<AchPayment<AchPaymentModelDto>>) => !isOneTime(record)
        ),
    effectiveDate: string.with
        .label('Effective Date')
        .thatIs.readOnly()
        .thatIs.visibleWhen((record: FdlRecord<AchPayment<AchPaymentModelDto>>) =>
            isOneTime(record)
        )
        .and.template<string>(effectiveDate => {
            return format(effectiveDate, 'MM/dd/yyyy');
        }),
    recipientName: string.thatIs.readOnly() as unknown as FieldType<string>,
    recipientId: string.thatIs.readOnly() as unknown as FieldType<string>,
    recipientRoutingNumber: string.thatIs.readOnly() as unknown as FieldType<string>,
    recipientAccountNumber: string.thatIs.readOnly() as unknown as FieldType<string>,
    recipientAccountType: string.thatIs.readOnly() as unknown as FieldType<string | undefined>,
    audit: achPaymentAudit.with.label('Audit').thatIs.readOnly() as unknown as FieldType<
        AuditModelDto[] | undefined
    >,
};

export const AchChildSupportRecipientFieldDefinitions: FdlFieldDefinitions<AchChildSupportRecipient> =
    {
        ...(AchRecipientFieldDefinitions as FdlFieldDefinitions<AchChildSupportRecipient>),
        nonCustodialParentSsn: fieldTypes.socialSecurityNumber.thatIs
            .required()
            .with.label('Non-Custodial Parent SSN')
            .thatIs.readOnly(),
        nonCustodialParentFirstName: string.with
            .label('Non-Custodial Parent First Name')
            .thatIs.readOnly(),
        nonCustodialParentLastName: string.with
            .label('Non-Custodial Parent Last Name')
            .thatIs.readOnly(),
        caseIdentifier: string.with.label('Case Identifier').thatIs.readOnly(),
        fipsCode: string.with.label('FIPS Code').thatIs.readOnly(),
        payDate: fieldTypes.datePicker.with.label('Pay Date').thatIs.readOnly(),
        employmentTermination: fieldTypes.yesNoBoolean.with
            .label('Employment Termination')
            .thatIs.readOnly(),
        medicalSupport: fieldTypes.yesNoBoolean.with.label('Medical Support').thatIs.readOnly(),
    };

export const getAchChildSupportRecipientColumns = (
    context: AchChildSupportPaymentDetail
): OmegaColumnDefinition<AchChildSupportRecipient>[] => [
    {
        label: 'Recipient Name',
        field: 'name',
    },
    {
        label: 'ID Number',
        field: 'idNumber',
    },
    {
        label: 'Account Number',
        field: 'accountNumber',
    },
    {
        label: 'Account Type',
        field: 'accountType',
    },
    {
        label: 'Routing Number',
        field: 'routingNumber',
    },
    {
        label: 'Amount',
        field: 'amount',
    },
    {
        label: 'Prenote',
        field: 'prenote',
    },
    {
        label: 'Hold',
        field: 'hold',
    },
    {
        label: '',
        'display-toggle-all': true,
        'display-chevron': true,
        type: OmegaColumnType.Detail,
    },
];

export const AchInternationalPaymentFieldDefinitions: FdlFieldDefinitions<AchInternationalPayment> =
    {
        ...(AchPaymentFieldDefinitions as FdlFieldDefinitions<AchInternationalPayment>),
    };

export const getAchInternationalRecipientColumns = (
    context: AchInternationalPaymentDetail
): OmegaColumnDefinition<AchInternationalRecipient>[] => [
    {
        label: 'Recipient Name',
        field: 'name',
    },
    {
        label: 'Prenote',
        field: 'prenote',
    },
    {
        label: 'Hold',
        field: 'hold',
    },
];
