import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { LoggingService } from '@treasury/core/logging';
import { LitElement } from 'lit';

export class DevHelper {
    @InjectProperty()
    private declare loggingService: LoggingService;

    private auditing = true;

    public enableAuditing() {
        this.auditing = true;
    }

    public disableAuditing() {
        this.auditing = false;
    }

    /**
     * Logs the updated properties of a component. Intended for temporary use as a sanity check
     * while developing a component to make sure there are not superfluous updates happening.
     */
    public auditUpdates(changedProps: Map<PropertyKey, unknown>, componentThis: LitElement) {
        if (!this.auditing) {
            return;
        }
        const changedKeys = Array.from(changedProps.keys()).join(', ');
        const newValues = Array.from(changedProps).map(([k, v]) => ({
            property: k,
            oldValue: v,
            newValue: (componentThis as unknown as Record<PropertyKey, unknown>)[k],
        }));
        // eslint-disable-next-line no-console
        this.loggingService.logDebug(
            `Updated: %c%s\n%cChanges: %c%s\n%cDetails: %o`,
            'background-color: slategray; color: blue;',
            componentThis.tagName.toLocaleLowerCase(),
            'background-color: inherit; color: inherit;',
            'background-color: slategray; color: blue;',
            changedKeys,
            'background-color: inherit; color: inherit;',
            newValues
        );
    }
}
