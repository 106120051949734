angular
    .module('backOffice')
    .controller('AnswerSecurityQuestionsController', answerSecurityQuestionsController);

answerSecurityQuestionsController.$inject = [
    '$scope',
    '$state',
    'pagePushService',
    'accountService',
    'loginStateService',
    'loginStatus',
];

function answerSecurityQuestionsController(
    $scope,
    $state,
    pagePushService,
    accountService,
    loginStateService,
    loginStatus
) {
    $scope.message = '';
    $scope.challenges = [];

    $scope.reset = function () {
        init();
        $scope.form.$setPristine();
    };

    $scope.initialize = function (form) {
        $scope.form = form;
    };

    function init() {
        if (!loginStateService.validateCurrentState()) {
            return;
        }

        $scope.user = pagePushService.getUser();
        if ($scope.user === null || $scope.user === undefined) {
            $state.go('login');
        } else {
            $scope.challenges = angular.copy($scope.user.credential.data);
        }
    }

    $scope.confirmSecurityQuestions = function () {
        accountService.verifySecurityQuestions($scope.challenges).then(response => {
            accountService.checkForError(response);

            if (response.statusCode === loginStatus.ALLOW) {
                if (loginStateService.nextState()) {
                } else {
                    $scope.message = 'Unable to proceed. Please contact your administrator.';
                }
            } else {
                $scope.message = response.message;
            }
        });
    };

    init();
}
