import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('fiPermissionService', fiPermissionService);

fiPermissionService.$inject = ['$resource'];

function fiPermissionService($resource) {
    const resourceUrl = `${getBackOfficeAPIPath()}fipermission/`;
    return {
        getPermissions,
    };

    function getPermissions() {
        return $resource(resourceUrl).query().$promise;
    }
}
