angular
    .module('backOffice')
    .controller('ManageAchOffsetAccountsController', manageAchOffsetAccountsController);

manageAchOffsetAccountsController.$inject = [
    '$scope',
    '$modalInstance',
    '$window',
    '$filter',
    'toaster',
    'modalService',
    'achService',
    'parentCompanyId',
    'achCompanyId',
];

function manageAchOffsetAccountsController(
    $scope,
    $modalInstance,
    $window,
    $filter,
    toaster,
    modalService,
    achService,
    parentCompanyId,
    achCompanyId
) {
    $scope.parentCompanyId = parentCompanyId;
    $scope.achCompanyId = achCompanyId;
    $scope.selectedAccountList = [];
    $scope.selectedAccountListSelected = [];
    $scope.availableAccountList = [];
    $scope.availableAccountListSelected = [];
    $scope.filteredAvailableList = [];
    $scope.filteredSelectedList = [];

    $scope.$watchCollection('availableAccountList', () => {
        $scope.filter(
            $scope.searchAvailableAccounts,
            $scope.availableAccountList,
            $scope.filteredAvailableList
        );
    });
    $scope.$watchCollection('selectedAccountList', () => {
        $scope.filter(
            $scope.searchSelectedAccounts,
            $scope.selectedAccountList,
            $scope.filteredSelectedList
        );
    });

    function init() {
        achService
            .getAchOffsetAccounts($scope.parentCompanyId, $scope.achCompanyId)
            .then(response => {
                if (!!response && !!response.nonManagedAccounts) {
                    $scope.availableAccountList = angular.copy(response.nonManagedAccounts);
                    $scope.filteredAvailableList = angular.copy(response.nonManagedAccounts);
                    $scope.selectedAccountList = angular.copy(response.managedAccounts);
                    $scope.filteredSelectedList = angular.copy(response.managedAccounts);
                }
            });
    }

    init();

    $scope.filter = function (model, source, target) {
        const filtered = source.filter(account => filterFunction(account, model, source));
        target.length = 0;
        angular.forEach(filtered, account => {
            target.push(account);
        });
    };

    function filterFunction(account, model, source) {
        if (!model || !source || source.length <= 0) {
            return true;
        }
        return (
            (!!account.number && account.number.indexOf(model) !== -1) ||
            (!!account.name && account.name.toLowerCase().indexOf(model.toLowerCase()) !== -1) ||
            (!!account.type && account.type.toLowerCase().indexOf(model.toLowerCase()) !== -1) ||
            (!!account.cifNumber &&
                account.cifNumber.toLowerCase().indexOf(model.toLowerCase()) !== -1)
        );
    }

    $scope.clickRow = function (target, account) {
        const index = target.indexOf(account);
        if (index > -1) {
            target.splice(index, 1);
        } else {
            target.push(account);
        }
    };

    $scope.confirmCancel = function () {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Cancel',
                actionButtonText: 'OK',
                headerText: 'Confirm Cancellation',
                bodyText:
                    'You have made changes to this page. Are you sure you would like to leave this page without saving?',
                submit(result) {
                    $cancelModal.close();
                    $modalInstance.dismiss();
                },
            };
            var $cancelModal = modalService.showModal({}, modalOptions);
        } else {
            $modalInstance.dismiss();
        }
    };

    $scope.save = function () {
        achService
            .saveAchOffsetAccounts(
                $scope.parentCompanyId,
                $scope.achCompanyId,
                $scope.selectedAccountList.map(account => account.id)
            )
            .then(response => {
                if (response) {
                    $modalInstance.dismiss();
                    toaster.save('ACH Offset Accounts');
                }
            });
    };

    $scope.setForm = function (form) {
        $scope.form = form;
    };

    $scope.moveAll = function (source, target) {
        $scope.form.$setDirty();
        angular.forEach(source, account => {
            target.push(account);
        });
        source.length = 0;
    };

    $scope.moveSome = function (source, subset, target) {
        $scope.form.$setDirty();
        angular.forEach(subset, account => {
            source.splice(source.indexOf(account), 1);
            target.push(account);
        });
        subset.length = 0;
    };
}
