angular.module('backOffice').directive('multiSelectDropdown', [
    function () {
        return {
            restrict: 'A',
            replace: true,
            scope: {
                ngModel: '=',
                entityCaption: '@',
                selectionList: '=',
                selectionField: '@',
                ngDisabled: '=',
                displayInterval: '=?',
                triggerCascadeFilter: '@',
                applyCascadeFilter: '@',
                orderField: '@',
                isCustom: '@',
            },
            template: require('./dropdownMultiSelectTemplate.html'),
            controller: [
                '$scope',
                '$document',
                '$filter',
                '$rootScope',
                '$timeout',
                function ($scope, $document, $filter, $rootScope, $timeout) {
                    $scope.isDisabled = $scope.ngDisabled;

                    const field = $scope.orderField ? $scope.orderField : $scope.selectionField;
                    $scope.selectionList = $filter('orderBy')($scope.selectionList, field);

                    if (
                        $scope.selectionList &&
                        $scope.selectionList.length > 0 &&
                        $scope.selectionList[0].pageLoadChecked
                    ) {
                        $scope.pageLoadChecked = true;
                        $scope.checkAll = true;
                    }

                    $scope.selectionListCopy = $scope.selectionList.slice();

                    $scope.displayInterval = parseInt($scope.displayInterval);
                    if (!Number.isInteger($scope.displayInterval)) {
                        $scope.displayInterval = 10;
                    }

                    $scope.list = filter(0, $scope.displayInterval - 1);

                    $scope.filterModel = undefined;
                    if (!$scope.ngModel) {
                        $scope.ngModel = [];
                    }

                    $scope.status = {
                        isOpen: false,
                    };

                    function filter(startAt, endAt) {
                        const data = $filter('regex')(
                            $scope.selectionList,
                            $scope.filterModel,
                            $scope.selectionField
                        );
                        if (isNaN(endAt)) {
                            return data;
                        }
                        // adding 1 for slice because slice includes the start item, but excludes the end item
                        return data.slice(startAt, endAt + 1);
                    }

                    $scope.filterDropdown = function () {
                        $scope.list = filter(0, $scope.displayInterval - 1);
                        changeDropdownLabel();
                    };

                    $scope.loadMore = function () {
                        const last = $scope.list.length - 1;
                        const startOfNext = last + 1;
                        const endOfNext = last + $scope.displayInterval;
                        $scope.list = $scope.list.concat(filter(startOfNext, endOfNext));
                    };

                    function setModel(newValues) {
                        newValues = newValues || [];
                        $scope.selectionListCopy.forEach(item => {
                            item.isChecked = false;
                            for (let i = 0; i < newValues.length; ++i) {
                                if (newValues[i].id == item.id) {
                                    item.isChecked = true;
                                    break;
                                }
                            }
                        });

                        changeDropdownLabel();
                        broadcastCascadeFilter();
                    }

                    function changeDropdownLabel() {
                        if ($scope.pageLoadChecked) {
                            $scope.selectedValue = 'All Selected';
                        } else if (
                            $scope.ngModel !== undefined &&
                            $scope.ngModel !== null &&
                            $scope.ngModel.length > 1
                        ) {
                            if ($scope.ngModel.length === $scope.selectionList.length) {
                                $scope.selectedValue = 'All Selected';
                                $scope.checkAll = true;
                            } else {
                                $scope.selectedValue = `${$scope.ngModel.length} of ${$scope.selectionList.length} Selected`;
                                $scope.checkAll = false;
                            }
                        } else if (
                            $scope.ngModel !== undefined &&
                            $scope.ngModel !== null &&
                            $scope.ngModel.length === 1
                        ) {
                            for (let i = 0; i < $scope.selectionList.length; i++) {
                                const item = $scope.selectionList[i];
                                if (item && item.isChecked) {
                                    $scope.selectedValue = item.value;
                                    break;
                                }
                            }
                        } else {
                            $scope.selectedValue = $scope.entityCaption;
                        }
                    }

                    $scope.processClickEvent = function (selectAllMode) {
                        updateModel(selectAllMode);
                        broadcastCascadeFilter();
                        $scope.pageLoadChecked = false;
                    };

                    function broadcastCascadeFilter() {
                        if ($scope.triggerCascadeFilter === 'true') {
                            $rootScope.$broadcast('CascadeFilter', $scope.ngModel);
                        }
                    }

                    function updateModel(selectAllMode) {
                        const list = filter(0);
                        $scope.ngModel = [];
                        angular.forEach(list, item => {
                            if (selectAllMode === true) {
                                if ($scope.checkAll) {
                                    $scope.ngModel.push(item);
                                }
                                item.isChecked = $scope.checkAll;
                            } else if (
                                item.isChecked !== null &&
                                item.isChecked !== undefined &&
                                item.isChecked === true
                            ) {
                                $scope.ngModel.push(item);
                            }
                        });

                        changeDropdownLabel();
                    }

                    function init() {
                        changeDropdownLabel();
                        if ($scope.applyCascadeFilter === 'true') {
                            $scope.$on('CascadeFilter', applyCascadeFilter);
                        }
                    }

                    init();

                    $document.on('click', e => {
                        let target = e.target.parentElement;
                        let parentFound = false;
                        while (angular.isDefined(target) && target !== null && !parentFound) {
                            if (
                                (target.className.split(' ').indexOf('menuitem') > -1 ||
                                    target.className.split(' ').indexOf('dropdown') > -1) &&
                                !parentFound
                            ) {
                                parentFound = true;
                            }
                            target = target.parentElement;
                        }
                        if (!parentFound) {
                            $timeout(() => {
                                $scope.status.isOpen = false;
                            });
                        }
                    });

                    $scope.$watch('ngDisabled', newValue => {
                        $scope.isDisabled = newValue;
                    });

                    $scope.$watch('ngModel', setModel, true);

                    function applyCascadeFilter(event, relatedIds) {
                        const filteredList = [];
                        if (relatedIds.length > 0) {
                            angular.forEach($scope.selectionListCopy, (listItem, index) => {
                                if (
                                    listItem.cascadeFilterIds &&
                                    listItem.cascadeFilterIds.length > 0 &&
                                    relatedIds.some(x =>
                                        listItem.cascadeFilterIds.some(id => x.id == id)
                                    )
                                ) {
                                    filteredList.push(listItem);
                                } else {
                                    listItem.isChecked = false;
                                }
                            });
                            $scope.selectionList = $scope.list = filteredList;
                        }
                        $scope.processClickEvent();
                    }
                },
            ],
        };
    },
]);
