import { AchRecipient } from '.';
import { AchInternationalRecipientDto, defaultAchInternationalRecipientModelDto } from './data/ach.data';

export class AchInternationalRecipient extends AchRecipient<AchInternationalRecipientDto>{
    public createDefault(): this {
        return new AchInternationalRecipient(defaultAchInternationalRecipientModelDto) as this;
    }

    public get streetAddress() {
        return this.dto.streetAddress;  
    }

    public get city() {
        return this.dto.city;  
    } 
    
    public get state() {
        return this.dto.state;  
    }

    public get zipCode() {
        return this.dto.zipCode;  
    }

    public get country() {
        return this.dto.country;  
    }

    public get iatTransactionType() {
        return this.dto.iatTransactionType;  
    }

    public get receivingBankName() {
        return this.dto.receivingBankName;  
    }

    public get receivingBankNumber() {
        return this.dto.receivingBankNumber;  
    }

    public get receivingBankType() {
        return this.dto.receivingBankType;  
    }

}