import { TmHttpClient, TmHttpResponseType } from '@treasury/core/http';
import { AmountRangeFormatters } from '../../../shared/utilities/amount-range-formatters.js';
import corePagingParameters from '../../../shared/utilities/core-paging-parameters.js';
import { DateFormatters } from '../../../shared/utilities/date-formatters.js';
// eslint-disable-next-line import/extensions
import { downloadBlob } from '../../../utilities/file-handling.js';
import CompanyRequests from '../companies/company-requests.js';

let mappedAPI = {};
export class AchFilterExceptionsReportServices {
    static async fetchAchFilterExceptions({ sort, startIndex, pageSize, parameters }) {
        const pagingParameters = corePagingParameters(
            'CompanyName',
            sort || [],
            startIndex,
            pageSize
        );
        const companyGuid = await CompanyRequests.getCompanyGuid(parameters.companyId);
        const { start, end } = AmountRangeFormatters.getAmountStartAndEnd(parameters.fromAmount);
        const dateType = DateFormatters.getDateType(parameters.createdDate);
        const dateValue = DateFormatters.parseDate(parameters.createdDate).value;

        mappedAPI = {
            companyGuidUniqueId: companyGuid,
            AccountUniqueIds: parameters.accountNumber,
            AchCompanyId: parameters.achCompanyId,
            AchCompanyName: parameters.achCompanyName,
            AmountEnd: end,
            AmountStart: start,
            StandardEntryClassCodes: parameters.standardEntryClassCode,
            Statuses: parameters.status,
            TransactionTypes: parameters.transactionType,
            UserUniqueIds: parameters.userIds ?? [],
            FilterRuleName: parameters.filterRuleName,
            StartDate: dateType === 'dateRange' ? dateValue.start : dateValue,
            EndDate: dateType === 'dateRange' ? dateValue.end : dateValue,
            PagingParameters: pagingParameters,
        };
        const http = await TmHttpClient.getInstance();
        const response = await http.request(`achFilterReport/Search`, {
            method: 'POST',
            body: mappedAPI,
        });

        const mappedValues = response.data.map(obj => ({
            ...obj,
            amount:
                obj.fromAmount === obj.toAmount
                    ? `$${obj.fromAmount}`
                    : `$${obj.fromAmount} - $${obj.toAmount}`,
            expirationDateTime: !obj.expirationDate ? `None` : obj.expirationDate,
            accountCredits: obj.accountAllowsAllCredits ? 'Credits Allowed' : 'Credits Not Allowed',
            accountDebits: obj.accountAllowsAllDebits ? 'Debits Allowed' : 'Debits Not Allowed',
            detailStatus: obj.status,
        }));
        return { ...response, data: mappedValues };
    }

    static async fetchCompanyUsers(id) {
        const companyGuid = await CompanyRequests.getCompanyGuid(id);
        const http = await TmHttpClient.getInstance();
        return http.request(`achFilterReport/CompanyUsers/${companyGuid}`, {
            maxAgeInSeconds: 6000,
        });
    }

    static async fetchCompanyAccounts(id) {
        const companyGuid = await CompanyRequests.getCompanyGuid(id);
        const http = await TmHttpClient.getInstance();
        return http.request(`PositivePayAccount/Search`, {
            method: 'POST',
            body: {
                CompanyUniqueIds: [companyGuid],
                HasAchFilter: true,
            },
        });
    }

    static async fetchAuditRecords(params) {
        return (await TmHttpClient.getInstance()).request(`achFilterReport/auditHistory`, {
            method: 'POST',
            body: params,
        });
    }

    static async fetchAbaNumbers() {
        return (await TmHttpClient.getInstance()).request('ach/domesticBanks', {
            maxAgeInSeconds: 10000,
        });
    }

    static async saveReport(type) {
        const downloadTypePath = type === 'PDF' ? 'Pdf' : 'Csv';
        delete mappedAPI.PagingParameters;
        const body = {
            ...mappedAPI,
        };
        const http = await TmHttpClient.getInstance();
        const response = await http.request(`achFilterReport/Download/${downloadTypePath}`, {
            method: 'POST',
            body,
            responseType: TmHttpResponseType.ArrayBuffer,
        });
        const contentType = type === 'PDF' ? 'application/pdf' : 'application/octet-stream';
        const blob = new Blob([response], { contentType });
        const filename = `achFilterExceptionsRepo.${downloadTypePath.toLowerCase()}`;
        return downloadBlob(blob, filename);
    }
}
