import InstitutionPositivePay from '../institution-configuration/institution-positive-pay.js';
import CompanyAccounts from './company-accounts/company-accounts.js';
import CompanyFeatures from './company-features/company-features.js';

import { saveRequestBody } from './saveRequest/save-request-body.js';

import { CompanyPositivePayServices } from '../../requests/company-configuration/company-positive-pay.js';

export default class CompanyPositivePay {
    constructor(companyInfo, featureFlagService) {
        this.apiRequest = CompanyPositivePayServices;
        this.companyInformation = companyInfo;
        this.institutionOptions = new InstitutionPositivePay();
        this.companyAccounts = new CompanyAccounts(this.companyInformation, featureFlagService);
        this.companyFeatures = new CompanyFeatures(this.companyInformation);
        this.aggregateCalls();
    }

    aggregateCalls() {
        Promise.all([
            this.companyFeatures.transformData(),
            this.companyAccounts.setUp(),
        ]).then(() => {
            this.hasLoaded = true;
        });
    }

    companyName() {
        return this.companyInformation.name;
    }

    companyID() {
        return this.companyInformation.id;
    }

    /* 
    Visible functions determine if FI level override is in place

    Enabled functions determine if the feature is on at company level
    */

    areAchExceptionsVisible() {
        return this.institutionOptions.allowShowAchExceptions();
    }

    areCheckExceptionsVisible() {
        return this.institutionOptions.allowWorkCheckExceptions();
    }

    areIssuedItemsVisible() {
        return this.institutionOptions.allowIssuedItems();
    }

    isAllowCorrectionRequestVisible() {
        return this.institutionOptions.allowCorrectionRequest();
    }

    isAllowReturnReasonVisible() {
        return this.institutionOptions.allowReturnReason();
    }

    isSubstituteProductVisible() {
        return this.institutionOptions.allowSubstituteProduct();
    }

    areCheckExceptionsEnabled() {
        return this.companyFeatures.allowWorkCheckExceptions();
    }

    areAchFiltersEnabled() {
        return this.companyFeatures.allowManageAchFilter();
    }

    areAchExceptionsEnabled() {
        return this.companyFeatures.allowWorkAchExceptions();
    }

    isIssuedItemsEnabled() {
        return this.companyFeatures.allowIssuedItems();
    }

    isAllowCorrectionRequestEnabled() {
        return this.companyFeatures.allowCorrectionRequest();
    }

    isAllowReturnReasonEnabled() {
        return this.companyFeatures.allowReturnReason();
    }

    getAchExceptions() {
        return this.companyAccounts.getAchExceptions();
    }

    getCheckExceptions() {
        return this.companyAccounts.getCheckExceptions();
    }

    getAchFilterAccounts() {
        return this.companyAccounts.getAchFiltersFeatures();
    }

    getAchApprovers() {
        return this.companyFeatures.getApprovers();
    }

    updateAchApprovers(newValue) {
        return this.companyFeatures.updateApprovers(newValue);
    }

    reset() {
        this.companyAccounts.reset();
        this.companyFeatures.reset();
    }

    toggle(key) {
        this.companyFeatures.toggleFeature(key);
    }

    hasChanged() {
        return this.companyAccounts.hasChanged() || this.companyFeatures.hasChanged();
    }

    updateAccount(key, account) {
        return this.companyAccounts.toggleFilter(key, account);
    }

    async save() {
        this.companyAccounts.save();
        this.companyFeatures.save();
        const response = saveRequestBody(
            this.institutionOptions,
            this.companyAccounts,
            this.companyFeatures
        );
        return this.apiRequest.saveConfiguration(this.companyID(), response);
    }
}
