angular.module('backOffice').directive('jhaNumeric', () => ({
    restrict: 'A',
    require: 'ngModel',
    link($scope, $element, $attrs, $ngModel) {
        $ngModel.$parsers.push(value => {
            const numericValue = value.replace(/[^0-9]/g, '');

            if (numericValue != value) {
                $ngModel.$setViewValue(numericValue);
                $ngModel.$render(numericValue);
            }

            return numericValue;
        });
    },
}));
