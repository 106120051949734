angular.module('backOffice').controller('ForgotPasswordController', forgotPasswordController);

forgotPasswordController.$inject = ['$scope', '$state', 'usersService'];

function forgotPasswordController($scope, $state, usersService) {
    $scope.reset = function () {
        init();
    };

    $scope.message = '';

    $scope.processForgotPassword = function () {
        usersService.forgotPassword($scope.forgotPassword).then(
            response => {
                if (response.data !== false) {
                    $state.go('email-sent-confirmation');
                } else {
                    $scope.message =
                        'The Login information entered does not match any records in our system. Please re-enter the required information to try again.';
                }
            },
            error => {
                $scope.message =
                    error instanceof Error ? error.message : 'An unknown error occurred.';
            }
        );
    };

    $scope.message = '';

    function init() {
        $scope.forgotPassword = {
            fiId: '',
            loginId: '',
            email: '',
        };
    }

    init();

    $scope.initialize = function (form) {
        $scope.form = form;
    };
}
