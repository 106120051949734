import { AchRecipientModelDto } from '@treasury/api/bo';
import { DomainEntity } from '../../../shared';
import { defaultAchRecipientModelDto } from './data/ach.data';

export class AchRecipient<T extends AchRecipientModelDto> extends DomainEntity<T> {
    public createDefault(): this {
        return new AchRecipient(defaultAchRecipientModelDto) as this;
    }

    public get id() {
        return this.dto.id;
    }

    public get idNumber() {
        return this.dto.idNumber;
    }

    public get name() {
        return this.dto.name;
    }

    public get accountNumber() {
        return this.dto.accountNumber;
    }

    public get accountType() {
        return this.dto.accountType;
    }

    public get routingNumber() {
        return this.dto.routingNumber;
    }

    public get transactionType() {
        return this.dto.transactionType;
    }

    public get amount() {
        return this.dto.amount;
    }

    public get prenote() {
        return this.dto.prenote;
    }

    public get hold() {
        return this.dto.hold;
    }

    public get addendas() {
        return this.dto.addendas && this.dto.addendas.length
            ? this.dto.addendas.map(addenda => addenda.value ?? '')
            : [];
    }
}
