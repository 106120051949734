import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { Record as FdlRecord, FieldType } from '@treasury/FDL';
import { BrandingService } from '@treasury/domain/branding/branding.service';
import '@treasury/omega/components/omega-field-2';
import { LitElement, css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';

const fields = {
    logo: new FieldType().with
        .label('Logo')
        .with.type('image')
        .as.tag('omega-file-upload') as FieldType<string>,
    primaryColor: new FieldType().with
        .label('Primary Color')
        .with.type('color')
        .as.tag('input') as FieldType<string>,
    secondaryColor: new FieldType().with
        .label('Secondary Color')
        .with.type('color')
        .as.tag('input') as FieldType<string>,
    tertiaryColor: new FieldType().with
        .label('Tertiary Color')
        .with.type('color')
        .as.tag('input') as FieldType<string>,
    backgroundColor: new FieldType().with
        .label('Background Color')
        .with.type('color')
        .as.tag('input') as FieldType<string>,
    accentColor: new FieldType().with
        .label('Accent Color')
        .with.type('color')
        .as.tag('input') as FieldType<string>,
};

// TODO: fetched from new service
const values = {
    logo: 'https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png',
    primaryColor: '#000000',
    secondaryColor: '#000000',
    tertiaryColor: '#000000',
    backgroundColor: '#000000',
    accentColor: '#000000',
};

@customElement('branding-container')
export class BrandingContainer extends LitElement {
    @property({ type: Object })
    public record!: FdlRecord<any>;

    @property({ type: Object })
    private values = values;

    @InjectProperty()
    private declare brandingService: BrandingService;

    @property({ type: Boolean })
    private loading = false;

    @property({ type: Boolean })
    private showCancelConfirmation = false;

    async firstUpdated() {
        // TODO: stubbed until we have a service
        // this.values = await this.brandingService.getFiBranding();
        this.record = new FdlRecord(fields, this.values);
    }

    async updateBranding() {
        this.loading = true;
        try {
            // await this.brandingService.updateFiBranding(this.record.values);
        } catch (error) {
            console.error(error);
        } finally {
            this.loading = false;
        }
    }

    promptForCancellation() {
        this.showCancelConfirmation = true;
    }

    cancel() {
        window.history.back();
    }

    dismissCancelConfirmation() {
        this.showCancelConfirmation = false;
    }

    renderCancelConfirmation() {
        if (!this.showCancelConfirmation) return nothing;
        return html`<omega-alert
            id="cancel-format-alert"
            posture="assertive"
            title="Are You Sure?"
            type="warning"
            ?isVisible=${this.showCancelConfirmation}
            @close=${() => {
                this.showCancelConfirmation = false;
            }}
        >
            Current template formatting will be lost. Are you sure you want to proceed?
            <omega-button-bar slot="actions" alignment="right"
                ><omega-button @click=${() => this.cancel}>Continue</omega-button
                ><omega-button type="primary" @click=${() => this.dismissCancelConfirmation()}>
                    Cancel
                </omega-button>
            </omega-button-bar>
        </omega-alert>`;
    }

    render() {
        if (!this.record) return nothing;
        return html`${this.renderCancelConfirmation()}
            <div class="branding-container">
                <h2>Branding</h2>
                <div class="fieldset">
                    <omega-field-2
                        field="logo"
                        .record=${this.record}
                        @filesUploaded=${(event: CustomEvent) => {
                            this.record.setField('logo', event.detail.files[0]);
                        }}
                    ></omega-field-2
                    ><omega-field-2 field="primaryColor" .record=${this.record}></omega-field-2
                    ><omega-field-2 field="secondaryColor" .record=${this.record}></omega-field-2
                    ><omega-field-2 field="tertiaryColor" .record=${this.record}></omega-field-2
                    ><omega-field-2 field="backgroundColor" .record=${this.record}></omega-field-2
                    ><omega-field-2 field="accentColor" .record=${this.record}></omega-field-2>
                </div>
                <div class="button-bar">
                    <omega-button type="primary" @click=${() => this.updateBranding()}
                        >Save</omega-button
                    ><omega-button type="secondary" @click=${() => this.promptForCancellation()}
                        >Cancel</omega-button
                    >
                </div>
            </div>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .branding-container {
                margin: auto;
                padding: 10px;
                background-color: #ffffff;
                border-radius: 10px;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                border: solid 1px #e0e0e0;
                margin-top: 100px;
                width: 70vw;
                max-width: 600px;
            }
            .fieldset {
                display: flex;
                flex-direction: column;
                width: 75%;
                margin: auto;
            }
            .button-bar {
                display: flex;
                justify-content: flex-end;
                margin-top: 20px;
            }
        `;
    }
}
