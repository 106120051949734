import { IModule } from 'angular';

const navigationRouteMap = {
    companySearch: 'company-search' as const,
    userList: 'fi-user-list' as const,
    userRoles: 'fi-role-list' as const,
    productFeatureConfiguration: 'configuration' as const,
    channelPasswordParameters: 'cpp' as const,
    fiUserPasswordParameters: 'fipp' as const,
    dashboardConfiguration: 'dashboard-configuration' as const,
    processingConfiguration: 'processing-configuration' as const,
    transactionCodeConfiguration: 'tc-configuration' as const,
    termsandConditionsConfiguration: 'terms-conditions-configuration' as const,
    reports: 'reports.dashboard' as const,
    authenticationProfiles: 'authentication-profiles' as const,
    notificationConfiguration: 'notification-configure' as const,
    messagingCenterConfiguration: 'messaging-center-configuration' as const,
    contentManagementConfiguration: 'content-management-configuration' as const,
};

export function registerConstants(module: IModule) {
    return module.constant('navigationRouteMap', navigationRouteMap);
}

export type NavigationRouteMap = typeof navigationRouteMap;
