angular.module('backOffice').filter('reportData', [
    '$filter',
    function ($filter) {
        return function (input, columnModels, columnName) {
            input = input || '';

            const columnModel = getColumnModelByName(columnModels, columnName);
            if (!columnModel || !columnModel.displayType) {
                return input;
            }
            switch (columnModel.displayType) {
                case 'DateTime':
                    return $filter('date')(input, 'MM/dd/yyyy @ h:mma');
                case 'Date':
                    return $filter('date')(input, 'MM/dd/yyyy');
                case 'Time':
                    return $filter('date')(input, 'h:mma');
                case 'Currency':
                    return $filter('currency')(input);
                default:
                    return input;
            }

            return input;
        };

        function getColumnModelByName(colModels, colName) {
            if (!!colModels && colModels.length > 0) {
                const columnModel = colModels.filter(
                    columnModel => columnModel.columnName.toLowerCase() === colName.toLowerCase()
                );
                if (!!columnModel && columnModel.length === 1) {
                    return columnModel[0];
                }
            }
            return null;
        }
    },
]);
