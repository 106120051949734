angular
    .module('backOffice')
    .controller('GroupAssociatedCompaniesController', groupAssociatedCompaniesController);

groupAssociatedCompaniesController.$inject = [
    '$scope',
    'challengeGroupsService',
    'group',
    '$filter',
    '$modalInstance',
];

function groupAssociatedCompaniesController(
    $scope,
    challengeGroupsService,
    group,
    $filter,
    $modalinstance
) {
    $scope.group = group;
    $scope.companiesSearch = '';
    $scope.filteredList = [];
    $scope.cancel = cancel;

    init();

    function init() {
        challengeGroupsService.getGroupCompanies($scope.group.id).then(response => {
            $scope.groupCompanies = response;
            $scope.filteredList = $filter('filter')($scope.groupCompanies, $scope.companiesSearch);
        });
    }

    $scope.$watch('companiesSearch', () => {
        if ($scope.groupCompanies) {
            const expression = function (value) {
                const fields = ['companyName', 'cifNumber', 'status'];
                for (const i in fields) {
                    const field = fields[i];
                    const query = {};
                    query[field] = $scope.companiesSearch;
                    const r = $filter('filter')([value], query);
                    if (r.length > 0) return true;
                }
                return false;
            };
            $scope.filteredList = $filter('filter')($scope.groupCompanies, expression);
        }
    });

    function cancel() {
        $modalinstance.dismiss();
    }
}
