import { string, yesNoBoolean } from '@treasury/policy/primitives';

export const fields = {
    name: string.with.label('Name').thatIs.readOnly(),
    description: string.with.label('Description').thatIs.readOnly(),
    enabled: yesNoBoolean.with.label('Enabled').thatIs.readOnly(),
    featureFlagKeyUniqueId: string.thatIs.visibleWhen(() => false),
};
export const columns = [
    {
        label: 'Enabled',
        field: 'enabled',
    },
    {
        label: 'Name',
        field: 'name',
    },
    {
        label: 'Description',
        field: 'description',
    },
    {
        label: 'Actions',
        type: 'actions',
        actions: [
            {
                label: 'Delete',
                action: 'delete',
            },
            {
                label: 'Edit',
                action: 'edit',
            },
        ],
    },
];
