angular.module('backOffice').directive('ipAddress', () => ({
    restrict: 'E',
    replace: true,
    scope: {
        data: '=',
        form: '=',
    },
    template: require('./IpAddressTable.html'),
    controller: ipSettingsController,
}));

ipSettingsController.$inject = ['$scope', 'IpSettingsService', 'modalService'];

function ipSettingsController($scope, IpSettingsService, modalService) {
    $scope.addIpAddress = addIpAddress;
    $scope.deleteIpAddress = deleteIpAddress;
    $scope.data = $scope.data;
    $scope.filterIpAddresses = filterIpAddresses;

    $scope.$watch('data', () => {
        if ($scope.data) {
            if ($scope.data.ipAddresses) {
                $scope.ipAddresses = $scope.data.ipAddresses;
            }
        }
    });

    function filterIpAddresses() {
        if ($scope.ipAddresses) {
            return $scope.ipAddresses.filter(ip => !ip.isDeleted);
        }
        return [];
    }

    function addIpAddress() {
        $scope.ipAddresses.push({
            networkIp1: '',
            networkIp2: '',
            hostIp1: '',
            hostIp2: '',
        });
    }

    function deleteIpAddress(ipAddress) {
        if (ipAddress.id) {
            $scope.ipAddresses[$scope.ipAddresses.indexOf(ipAddress)].isDeleted = true;
            $scope.form.$dirty = true;
        } else {
            $scope.ipAddresses.splice($scope.ipAddresses.indexOf(ipAddress), 1);
            $scope.form.$dirty = true;
        }
    }
}
