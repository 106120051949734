angular.module('backOffice').constant(
    'secureTokenStatus',
    Object.freeze({
        NOT_SET: 'NotSet',
        ALLOW: 'Allow',
        CHALLENGE: 'Challenge',
        LOCKED: 'Locked',
        REGISTER: 'Register',
    })
);
