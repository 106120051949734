/* eslint-disable no-undef */
import { httpCache, removeAuthToken } from '@treasury/core/http';
import { exists } from '@treasury/utils';

angular.module('backOffice').controller('LoginController', loginController);

loginController.$inject = [
    '$scope',
    '$state',
    '$modalStack',
    'accountService',
    'pagePushService',
    'jhaSupportService',
    'loginStateService',
    '$timeout',
    'expirationCache',
    '$window',
];

/**
 *
 * @param { ng.IScope} $scope
 * @param { import('../../services/common/pagePushSrvc').BoPagePushService} pagePushService
 * @param { ng.ITimeoutService } $timeout
 * @param { ng.IWindowService } $window
 */
function loginController(
    $scope,
    $state,
    $modalStack,
    accountService,
    pagePushService,
    jhaSupportService,
    loginStateService,
    $timeout,
    expirationCache,
    $window
) {
    $scope.loginData = {
        fiId: '',
        loginId: '',
        password: '',
        useRefreshTokens: false,
        riskReceiptId: '',
    };

    $scope.message = '';

    function init() {
        expirationCache.getCache().destroy();
        $modalStack.dismissAll();
        $window.sessionStorage.clear();
        removeAuthToken();
        httpCache.clear();
    }
    init();

    $scope.navigateToForgotPassword = function () {
        $state.go('forgot-password');
    };

    $scope.login = function () {
        $scope.message = '';
        accountService
            .validateUser($scope.loginData)
            .then(response => {
                $scope.loginData.password = undefined;
                $scope.message = accountService.checkForError({
                    statusCode: response.userCredentialStatus,
                    message: response.message,
                });

                // need the password to be cleared out before we do any navigation to prevent the browser from saving the password.
                $timeout(() => {
                    $scope.loginData.credential = response;
                    $scope.loginData.riskReceiptId = response.riskReceiptId;
                    pagePushService.setUser($scope.loginData);
                    jhaSupportService.setUserFiId($scope.loginData.fiId);
                    loginStateService.initiate(response);
                });
            })
            .catch(err => {
                if (exists(err)) {
                    $scope.message = err instanceof Error ? err.message : err.error_description;
                }
            });
    };
}
