angular
    .module('backOffice')
    .controller('CustomizeStopPaymentController', customizeStopPaymentController);

customizeStopPaymentController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'modalService',
    'companiesService',
    'toaster',
    'stopPaymentService',
];

function customizeStopPaymentController(
    $scope,
    $state,
    $stateParams,
    modalService,
    companiesService,
    toaster,
    stopPaymentService
) {
    const CompanyId = $stateParams.id;

    $scope.allAccountsCheckbox = { checked: false };

    $scope.approvalOptions = [
        { label: 'None', value: 0 },
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
    ];

    $scope.productFeature = {};
    $scope.productFeature.accounts = [];
    $scope.filteredAccounts = [];

    $scope.setForm = setForm;
    $scope.goBack = goBack;
    $scope.save = save;
    $scope.toggleColumn = toggleColumn;
    $scope.updateAllAccountsChecked = updateAllAccountsChecked;
    $scope.requiredEntitlements = requiredEntitlements;
    $scope.pageTitle = '';

    // Public functions
    //

    function requiredEntitlements() {
        return $scope.productFeature.id === 0 ? 'Add Product Feature' : 'Edit Product Feature';
    }

    function setForm(form) {
        $scope.form = form;
    }

    function goBack() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel the changes you have made?',
                submit() {
                    cancelModal.dismiss();
                    $state.go('company', { id: CompanyId });
                },
            };
            const cancelModal = modalService.showModal({}, modalOptions);
        } else {
            $state.go('company', { id: CompanyId });
        }
    }

    function save() {
        const accountIds = [];
        for (let iAccount = 0; iAccount < $scope.filteredAccounts.length; ++iAccount) {
            const account = $scope.filteredAccounts[iAccount];
            if (account.isStopPayEnabled) {
                accountIds.push(account.id);
            }
        }

        stopPaymentService
            .saveCompanyConfiguration(CompanyId, $scope.productFeature.approvalCount, accountIds)
            .then(() => {
                toaster.save('Stop Payment');
                $scope.form.$setPristine();
            });
    }

    function toggleColumn(isStopPayEnabled) {
        $scope.filteredAccounts.forEach(account => {
            account.isStopPayEnabled = isStopPayEnabled;
        });
    }

    // Private functions
    //
    function init() {
        if ($stateParams.productFeatureId === '') {
            $scope.productFeature.id = 0;
        } else if (
            $stateParams.productFeatureId !== undefined ||
            $stateParams.productFeatureId !== null
        ) {
            $scope.productFeature.id = $stateParams.productFeatureId;
        }

        if ($scope.productFeature.id === 0) {
            $scope.pageTitle = 'Add Stop Payment';
        } else {
            $scope.pageTitle = $scope.hasEntitlement('Edit Product Feature')
                ? 'Edit Stop Payment'
                : 'View Stop Payment';
        }

        loadCompany();
        loadProductFeature();
        loadAccounts();
    }

    function loadCompany() {
        companiesService.getCompanyName(CompanyId).then(response => {
            $scope.company = response;
        });
    }

    function loadProductFeature() {
        stopPaymentService.getCompanyConfiguration(CompanyId).then(response => {
            $scope.productFeature = {
                id: response.id,
                approvalCount: response.approvalCount,
            };
        });
    }

    function loadAccounts() {
        stopPaymentService.getEligibleAccounts(CompanyId).then(response => {
            $scope.filteredAccounts = response.accounts;
            updateAllAccountsChecked();
        });
    }

    function updateAllAccountsChecked() {
        $scope.allAccountsCheckbox.checked = $scope.filteredAccounts.every(
            account => account.isStopPayEnabled
        );
    }

    init();
}
