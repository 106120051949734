import { getBackOfficeAPIPath } from '@treasury/core/http';

angular
    .module('backOffice')
    .factory('CompanyUserAccountSettingsService', companyUserAccountSettingsService);

companyUserAccountSettingsService.$inject = ['$resource'];

function companyUserAccountSettingsService($resource) {
    const resourceUrl = `${getBackOfficeAPIPath()}companyUserAccountSettings/`;

    return {
        getAccountSettings,
        updateCompanyAccountSettings,
    };

    function getAccountSettings() {
        return $resource(resourceUrl).get().$promise;
    }

    function updateCompanyAccountSettings(settings) {
        const resource = $resource(resourceUrl, {}, { update: { method: 'PUT' } });
        return resource.update(settings).$promise;
    }
}
