import { LitElement, html, css } from 'lit';
import '@treasury/omega/components/omega-tabs.js';
import '@treasury/omega/components/omega-tooltip.js';
import './alternate-search';
import './company-search';
import { property } from 'lit/decorators.js';

class CompanyListing extends LitElement {
    constructor() {
        super();
        this.activeTab = 'search';
    }

    @property({ type: String })
    activeTab = 'search';

    get hideSearch() {
        return this.activeTab !== 'search';
    }

    renderSearchView() {
        if (this.activeTab === 'search') {
            return html` <company-search></company-search> `;
        }
        return html` <alternate-search></alternate-search> `;
    }

    render() {
        return html`
            <div class="container">
                <h1 class="tm-title">
                    Company Listing
                    <omega-tooltip
                        light
                        icon="info-circle"
                        position="right"
                        .message=${html`<div style="max-width: 400px;">
                            <p>
                                <b>Company Search:</b> Includes all Treasury Management companies
                                with an Active, Inactive or Associated company status
                            </p>
                            <p>
                                <b>Alternate Search:</b> Includes all Treasury Management companies
                                with a Not Onboarded company status. Enter specific search criteria
                                to return the desired results. If you are unsure of the exact
                                company name, enter in a single or combination of characters to
                                retrieve a company
                            </p>
                        </div>`}
                    ></omega-tooltip>
                </h1>

                <div class="container nested">
                    <omega-tabs
                        .tabs=${[
                            {
                                label: html`Company Search`,
                                id: 'search',
                            },
                            {
                                label: html`Alternate Search`,
                                id: 'alternate',
                            },
                        ]}
                        activeTab=${this.activeTab}
                        label="Account Reconciliation"
                        @switchTab=${(event: CustomEvent) => {
                            this.activeTab = event.detail.activeTab;
                        }}
                    ></omega-tabs>
                    <company-search .hidden=${this.hideSearch}></company-search>
                    <alternate-search .hidden=${!this.hideSearch}></alternate-search>
                </div>
            </div>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .container {
                margin: 15px;
            }
            .nested {
                background-color: var(--omega-white);
                box-shadow: var(--omega-default-shadow);
            }
            .tm-title {
                color: var(--omega-text-header);
                padding: 0;
                margin: 0 15px;
                display: flex;
                font-size: 24px;
                font-weight: 500;
            }
        `;
    }
}

customElements.define('company-listing', CompanyListing);
