import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('messagingCenterService', messagingCenterService);

messagingCenterService.$inject = ['$resource'];

function messagingCenterService($resource) {
    const resourceUrl = `${getBackOfficeAPIPath()}messagingcenter/`;

    return {
        get,
        getMessageCategories,
        save,
        saveMessage,
        getMessageThread,
        downloadAttachment,
        search,
        silentSearch,
        update,
    };

    function get() {
        return $resource(resourceUrl, {}, { get: { method: 'GET', isArray: false } }).get()
            .$promise;
    }

    function save(model) {
        return $resource(resourceUrl).save(model).$promise;
    }

    function getMessageCategories() {
        return $resource(`${resourceUrl}getMessageCategories`).query().$promise;
    }

    function saveMessage(message) {
        return $resource(
            `${resourceUrl}savemessage`,
            {},
            {
                save: {
                    method: 'POST',
                    transformRequest: transformMessage,
                    headers: { 'Content-Type': undefined, enctype: 'multipart/form-data' },
                },
            }
        ).save(message).$promise;
    }

    function transformMessage(message) {
        const fd = new FormData();
        fd.append('CompanyId', message.companyId);
        fd.append('UserId', message.userId);
        fd.append('CategoryId', message.categoryId);
        fd.append('Body', message.body);
        fd.append('ParentMessageId', message.parentMessageId);
        fd.append('AssignedToUserId', message.assignedToUserId);
        angular.forEach(message.files, file => {
            fd.append('file', file.rawFile);
        });
        return fd;
    }

    function getMessageThread(id) {
        return $resource(`${resourceUrl}messagethread/:id`, { id }).query().$promise;
    }

    function downloadAttachment(id, oldAttachment = false) {
        let endpointUrl = `${getBackOfficeAPIPath()}file`;
        if (oldAttachment) {
            endpointUrl = `${resourceUrl}downloadattachment`;
        }
        return $resource(
            `${endpointUrl}/:id`,
            { id },
            {
                download: {
                    method: 'get',
                    responseType: 'arraybuffer',
                    transformResponse(data, headersGetter) {
                        return { data };
                    },
                },
            }
        ).download().$promise;
    }

    function search(searchTerms) {
        return $resource(
            `${resourceUrl}search`,
            {},
            { search: { method: 'post', isArray: true } }
        ).search(searchTerms).$promise;
    }

    function silentSearch(searchTerms) {
        return $resource(
            `${resourceUrl}search?silent=1`,
            {},
            { search: { method: 'post', isArray: true } }
        ).search(searchTerms).$promise;
    }

    function update(updateObject) {
        return $resource(`${resourceUrl}update`).save(updateObject).$promise;
    }
}
