import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('ofxSettingsService', ofxSettingsService);

ofxSettingsService.$inject = ['$resource'];

function ofxSettingsService($resource) {
    const resourceUrlBase = `${getBackOfficeAPIPath()}ofxsettings/`;

    return {
        getOfxEnabledFlagForFI,
        updateOfxEnabledFlagForFI,
        getOfxEnabledFlagForUser,
        updateOfxEnabledFlagForUser,
        getOfxEnabledFlagForCompany,
        updateOfxEnabledFlagForCompany,
    };

    function getOfxEnabledFlagForFI(ofxFiId) {
        return $resource(`${resourceUrlBase}getOfxEnabledFlagForFI`, { fiId: ofxFiId }).get()
            .$promise;
    }

    function updateOfxEnabledFlagForFI(ofxFiId, isOfxEnabled) {
        const resource = $resource(`${resourceUrlBase}updateOfxEnabledFlagForFI`, {
            fiId: ofxFiId,
            isOfxEnabled,
        });
        return resource.save(isOfxEnabled).$promise;
    }

    function getOfxEnabledFlagForUser(ofxFiId, userName) {
        return $resource(`${resourceUrlBase}getOfxEnabledFlagForUser`, {
            fiId: ofxFiId,
            userName,
        }).get().$promise;
    }

    function updateOfxEnabledFlagForUser(ofxFiId, userName, ofxUserEnabledFlag) {
        const resource = $resource(`${resourceUrlBase}updateOfxEnabledFlagForUser`, {
            fiId: ofxFiId,
            userName,
            ofxUserEnabled: ofxUserEnabledFlag,
        });
        return resource.save(ofxUserEnabledFlag).$promise;
    }

    function getOfxEnabledFlagForCompany(ofxFiId, companyId) {
        return $resource(`${resourceUrlBase}getOfxEnabledFlagForCompany`, {
            fiId: ofxFiId,
            companyId,
        }).get().$promise;
    }

    function updateOfxEnabledFlagForCompany(
        ofxFiId,
        companyId,
        isEnabled,
        isUserLevelAccess,
        ofxAuthenticationCode,
        modifiedBy
    ) {
        return $resource(`${resourceUrlBase}updateOfxEnabledFlagForCompany`).save({
            ofxFiNumber: ofxFiId,
            CompanyId: companyId,
            IsOfxEnabled: isEnabled,
            UserLevelAccessEnabled: isUserLevelAccess,
            OfxAuthenticationCode: ofxAuthenticationCode,
            ModifiedBy: modifiedBy,
        }).$promise;
    }
}
