import InstitutionPositivePay from '@treasury/domain/backoffice/mappings/institution-configuration/institution-positive-pay';
import InstitutionPositivePayRequests from '@treasury/domain/backoffice/requests/institution-configuration/institution-positive-pay-requests';

function darkConfigurePositivePayController(
    $scope,
    $stateParams,
    entitlementsService,
    toaster,
    $timeout
) {
    const checkExceptions = 'checkExceptionsSettings';

    function selectTab(tabName) {
        $scope.selectedTab = tabName;
    }

    const posPay = new InstitutionPositivePay();

    async function update() {
        if (posPay.hasLoaded) {
            $scope.workCheckExceptions = posPay.allowWorkCheckExceptions();
            $scope.allowIssuedItems = posPay.allowIssuedItems();
            $scope.showAchExceptions = posPay.allowShowAchExceptions();
            $scope.workAchExceptions = posPay.allowWorkAchExceptions();
            $scope.allowManageAchFilter = posPay.allowManageAchFilter();
            $scope.allowCorrectionRequest = posPay.allowCorrectionRequest();
            $scope.allowReturnReason = posPay.allowReturnReason();
            $scope.approveCount = posPay.getApprovers();

            $scope.addAdditionalInformation = addAdditionalInformation;
            $scope.deleteAdditionalInformation = deleteAdditionalInformation;
            $scope.hasAchFilterManagementEntitlement = hasEntitlement(
                'Feature.PositivePay.ACHFilterManagement'
            );
        } else {
            $timeout(() => {
                update();
            }, 100);
        }
    }

    function changeApprovers(value) {
        posPay.updateApprovers(value);
        update();
    }

    async function init() {
        $scope.selectedTab = checkExceptions;
        $scope.returnReasons =
            await InstitutionPositivePayRequests.getCheckExceptionReturnReasons();
        update();
    }

    function toggle(key) {
        posPay.toggleFeature(key);
        update();
    }

    function cancelChanges() {
        posPay.reset();
        update();
    }

    function hasEntitlement(entitlement) {
        return entitlementsService.hasEntitlement(entitlement);
    }

    function isSaveEnabled() {
        return posPay.hasChanged();
    }

    function isTabSelected(tabName) {
        return tabName === $scope.selectedTab;
    }

    async function saveConfiguration() {
        try {
            posPay.save();
            toaster.save('Positive Pay Configuration');
            update();
        } catch (e) {
            console.log('e', e);
        }
    }

    function addAdditionalInformation() {
        if ($scope.returnReasons.length === 4) {
            return;
        }
        $scope.returnReasons.push('');
    }

    function deleteAdditionalInformation(index) {
        $scope.returnReasons.splice(index, 1);
    }

    $scope.cancelChanges = cancelChanges;
    $scope.hasEntitlement = hasEntitlement;
    $scope.isSaveEnabled = isSaveEnabled;
    $scope.isTabSelected = isTabSelected;
    $scope.productFeatureId = $stateParams.productFeatureId;
    $scope.productFeatureStatus = $stateParams.productFeatureStatus;
    $scope.saveConfiguration = saveConfiguration;
    $scope.selectTab = selectTab;
    $scope.toggle = toggle;
    $scope.changeApprovers = changeApprovers;
    $scope.approvers = ['None', '1', '2', '3'];

    init();
}
window.angular
    .module('backOffice')
    .controller('darkConfigurePositivePayController', darkConfigurePositivePayController);
darkConfigurePositivePayController.$inject = [
    '$scope',
    '$stateParams',
    'entitlementsService',
    'toaster',
    '$timeout',
];
