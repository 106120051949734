import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('configurationService', configurationService);

configurationService.$inject = ['$resource', '$http', 'downloadService'];

function configurationService($resource, $http, downloadService) {
    const resourceUrl = `${getBackOfficeAPIPath()}productFeatureConfigurations/`;

    return {
        getFiProductFeatures,
        changeProductFeaturesStatus,
        getProductFeatureDetails,
        saveProductFeature,
        getProductFeatureDetailsByName,
        updateInstitutionArpProduct,
        updateRemoteDepositProduct,
        uploadAchStateTaxCodes,
        downloadAchStateTaxCodes,
        getAchStateTaxCodeFileMetadata,
        checkQfxProductStatusForFi,
        getAgileticsWebEscrowDetails,
        saveAgileticsWebEscrowDetails,
    };

    function saveProductFeature(id, productFeature) {
        const resource = $resource(`${resourceUrl}:id`, { id }, { update: { method: 'PUT' } });
        return resource.update(productFeature).$promise;
    }

    function changeProductFeaturesStatus(id, newStatus) {
        return $resource(`${resourceUrl}:id/ChangeStatus/:status`, {
            id,
            status: newStatus,
        }).save().$promise;
    }

    function getProductFeatureDetails(id) {
        return $resource(`${resourceUrl}:id`, { id }).get().$promise;
    }

    function getAgileticsWebEscrowDetails(id) {
        return $resource(`${getBackOfficeAPIPath()}agileticsSsoProductFeatureConfigurations/:id`, {
            id,
        }).get().$promise;
    }

    function saveAgileticsWebEscrowDetails(productFeature) {
        const resource = $resource(
            `${getBackOfficeAPIPath()}agileticsSsoProductFeatureConfigurations`
        );
        return resource.save({ ...productFeature }).$promise;
    }

    function getProductFeatureDetailsByName(name) {
        return $resource(`${resourceUrl}getByName/:name`, { name }).get().$promise;
    }

    function getFiProductFeatures() {
        return $resource(resourceUrl).query().$promise;
    }

    function updateInstitutionArpProduct(fiArpProductModel) {
        return $resource(`${resourceUrl}UpdateInstitutionArpProduct`).save(fiArpProductModel)
            .$promise;
    }

    function updateRemoteDepositProduct(remoteDepositProductModel) {
        return $resource(`${resourceUrl}UpdateRemoteDepositProduct`).save(remoteDepositProductModel)
            .$promise;
    }

    function uploadAchStateTaxCodes(file) {
        const url = `${resourceUrl}uploadAchStateTaxCodes`;

        return postFile(url, file).then(response => response.data);
    }

    function downloadAchStateTaxCodes() {
        const url = `${resourceUrl}downloadAchStateTaxCodes`;

        return downloadService.downloadAsFile(url);
    }

    function getAchStateTaxCodeFileMetadata() {
        const url = `${resourceUrl}getAchStateTaxCodeFileMetadata`;

        return $http.get(url).then(response => response.data);
    }

    function postFile(url, file) {
        return $http.post(url, file, {
            transformRequest(file) {
                const formData = new FormData();
                formData.append('file', file);

                return formData;
            },
            headers: {
                'Content-Type': undefined,
            },
        });
    }
    function checkQfxProductStatusForFi() {
        return $resource(`${resourceUrl}checkQfxProductStatusForFi`).get().$promise;
    }
}
