/* eslint-disable import/extensions */
import { TmHttpClient, TmHttpResponseType } from '@treasury/core/http';
import { downloadBlob } from '../../../utilities/file-handling.js';

/**
 * Static class grouping common calls for Back Office Reports.
 */
export class ReportRequests {
    static async getReports() {
        return (await TmHttpClient.getInstance()).request(`reports`, { method: 'GET' });
    }

    /**
     * @param {string} type Name of the report
     * @returns {Promise}
     */
    static async getReportColumns(type) {
        return (await TmHttpClient.getInstance()).request(
            `reports/reportColumnModels?type=${type}`,
            {
                method: 'GET',
                maxAgeInSeconds: 60000000,
            }
        );
    }

    /**
     * @param {string} type string name of the report
     * @returns {Promise}
     */
    static async getReportFilters(type) {
        return (await TmHttpClient.getInstance()).request(`reports/reportFilter?type=${type}`, {
            method: 'GET',
            maxAgeInSeconds: 60000000,
        });
    }

    static async getCascadingReportFilters(id) {
        return (await TmHttpClient.getInstance()).request(
            `reports/report/${id}/reportFiltersModel`,
            {
                method: 'GET',
                maxAgeInSeconds: 6000000,
            }
        );
    }

    /**
     *
     * @param {string} type string name of the report to retrieve the type id
     * @returns {Promise}
     */
    static async getDownloadType(type) {
        return (await TmHttpClient.getInstance()).request(
            `reports/GetDownloadPageByReportType?reportType=${type}`,
            {
                method: 'GET',
                maxAgeInSeconds: 60000000,
            }
        );
    }

    /**
     *
     * @param {string} typeId string or integer that returns the download options for the report type
     * @returns {Promise}
     */
    static async getDownloadOptions(typeId) {
        return (await TmHttpClient.getInstance()).request(
            `download/GetDownloadOptions?downloadPage=${typeId}`,
            {
                method: 'GET',
                maxAgeInSeconds: 60000000,
            }
        );
    }

    /**
     *
     * @param {string|number} id string or integer id of the report requested
     * @returns {Promise}
     */
    static async getReportMetaData(id) {
        return (await TmHttpClient.getInstance()).request(`reports/report/${id}`, {
            method: 'GET',
            maxAgeInSeconds: 60000000,
        });
    }

    /**
     *
     * @param {Object} body parameters from recordset
     * @returns {Promise}
     */
    static async runReport(body) {
        return (await TmHttpClient.getInstance()).request(`reports`, {
            method: 'POST',
            body,
        });
    }

    /**
     *
     * @param {string} id report id
     * @returns {Promise}
     */
    static async deleteReport(id) {
        return (await TmHttpClient.getInstance()).request(`reports/deletecustomreport/${id}`, {
            method: 'GET',
        });
    }

    /**
     *
     * @param {{reportId:(string|number), isFavorite:boolean}} param
     * @returns {Promise}
     */
    static async favoriteReport({ reportId, isFavorite }) {
        return (await TmHttpClient.getInstance()).request(
            `reports/favorite/${reportId}?value=${isFavorite}`,
            {
                method: 'GET',
            }
        );
    }

    /**
     *
     * @param {object} body
     * @returns {Promise}
     */
    static async saveReport(body) {
        return (await TmHttpClient.getInstance()).request(`reports/reportCustomFilter`, {
            method: 'POST',
            body,
        });
    }

    /**
     *
     * @param {string|number} id
     * @param {string} type
     * @returns {Promise}
     */
    static async fetchCustomReportFilters(type, id) {
        return (await TmHttpClient.getInstance()).request(
            `reports/reportCustomFilter?id=${id}&type=${type}`,
            {
                method: 'GET',
            }
        );
    }

    /**
     *
     * @param {object} body
     * @param {'pdf'|'csv'} downloadType
     * @returns {Promise<void>}
     */
    static async downloadReport(body, downloadType) {
        const response = await (
            await TmHttpClient.getInstance()
        ).request(`download/`, {
            method: 'POST',
            responseType: TmHttpResponseType.ArrayBuffer,
            body,
        });
        const isPdf = downloadType.toLowerCase().includes('pdf');
        const fileType = isPdf ? 'PDF' : 'CSV';
        const type = isPdf ? 'application/pdf' : 'application/octet-stream';
        const blob = new Blob([response], { type });
        const filename = `${body.filter.reportFilter.name}.${fileType.toLowerCase()}`;
        return downloadBlob(blob, filename);
    }

    /**
     *
     * @param {object} body
     * @param {'pdf'|'csv'} downloadType
     * @returns {Promise<void>}
     */
    static async downloadReportDetails(body, downloadType) {
        const response = await (
            await TmHttpClient.getInstance()
        ).request(`download/`, {
            method: 'POST',
            responseType: TmHttpResponseType.ArrayBuffer,
            body,
        });
        const isPdf = downloadType.toLowerCase().includes('pdf');
        const fileType = isPdf ? 'PDF' : 'CSV';
        const type = isPdf ? 'application/pdf' : 'application/octet-stream';
        const blob = new Blob([response], { type });
        const filename = `${body.filter.Guid}.${fileType.toLowerCase()}`;
        return downloadBlob(blob, filename);
    }

    static async printReportDetail(guid) {
        return (await TmHttpClient.getInstance()).request(`download/print/${guid}`, {
            method: 'GET',
        });
    }

    /**
     *
     * @param {string} id
     * @returns {Promise<void>}
     */
    /* NOT DONE */
    static async downloadAttachment(id) {
        const response = await (
            await TmHttpClient.getInstance()
        ).request(`File/${id}`, {
            method: 'GET',
            responseType: TmHttpResponseType.Raw,
        });
        const filename = response.headers.get('x-filename');
        const contentType = response.headers.get('content-type');
        const buffer = await response.arrayBuffer();
        const blob = new Blob([buffer], { type: contentType });
        return downloadBlob(blob, filename);
    }

    static async fetchWireHistory(id) {
        return (await TmHttpClient.getInstance()).request(`reports/getwirehistory/${id}`, {
            method: 'GET',
            maxAgeInSeconds: 6000,
        });
    }

    static async fetchWireDetails(wireId) {
        return (await TmHttpClient.getInstance()).request(`wires/${wireId}`, {
            method: 'GET',
        });
    }

    /**
     * Calls outside base-level report information aggregation.
     */
    // TODO: relocate these away from report factory requests
    static async getCompanies() {
        return (await TmHttpClient.getInstance()).request(
            `reportFilter/companies?companytype=parentCompany`,
            {
                method: 'GET',
                maxAgeInSeconds: 6000,
            }
        );
    }

    static async getCompaniesNew(companyTypeId) {
        return (await TmHttpClient.getInstance()).request(`Company/Search`, {
            method: 'POST',
            body: {
                CompanyTypeId: companyTypeId,
                RowStatusIds: [0, 1],
            },
        });
    }

    static async getCompanyInfo(companyId) {
        const companies = await this.getCompanies();
        return companies.find(value => value.id === companyId);
    }

    static async downloadWireDetails(wireId, downloadType) {
        const fileType = downloadType.toUpperCase();
        const downloadTypeId = fileType === 'CSV' ? 61 : 62;
        const response = await (
            await TmHttpClient.getInstance()
        ).request(`download/`, {
            method: 'POST',
            body: {
                downloadType: downloadTypeId,
                filter: { id: wireId },
            },
            responseType: TmHttpResponseType.ArrayBuffer,
        });
        const contentType = fileType === 'PDF' ? 'application/pdf' : 'application/octet-stream';
        const blob = new Blob([response], { contentType });
        const filename = `Wire Detail.${downloadType.toLowerCase()}`;
        return downloadBlob(blob, filename);
    }

    static async saveWireDetails(wire) {
        // TODO: Save it.
    }
}

export default ReportRequests;
