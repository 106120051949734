angular.module('backOffice').directive('positivePayAccountTable', positivePayAccountTable);

function positivePayAccountTable() {
    return {
        controller: positivePayAccountTableController,
        restrict: 'E',
        scope: {
            allAccounts: '=accounts',
            checkProperty: '=',
            title: '=',
            featureIsAvailable: '=',
        },
        template: require('./positivePayAccountTable.html'),
    };
}

positivePayAccountTableController.$inject = ['$scope'];

function positivePayAccountTableController($scope) {
    const allCifs = 'Select All';

    $scope.accountSearch = '';
    $scope.checkAll = false;
    $scope.cifNumbers = [];
    $scope.displayedAccounts = $scope.allAccounts;
    $scope.orderByField = 'number';
    $scope.reverseSort = false;
    $scope.selectedCifNumber = allCifs;

    $scope.checkAllChanged = checkAllChanged;
    $scope.chevronClass = chevronClass;
    $scope.hasAccountsForDisplay = hasAccountsForDisplay;
    $scope.hasNoAccounts = hasNoAccounts;
    $scope.orderByFunction = orderByFunction;
    $scope.showNoDisplayableAccounts = showNoDisplayableAccounts;
    $scope.updateCheckAll = updateCheckAll;
    $scope.updateDisplayedAccounts = updateDisplayedAccounts;
    $scope.updateOrderByField = updateOrderByField;

    $scope.$watch('allAccounts', accountsUpdated);
    $scope.$watch('featureIsAvailable', featureIsAvailableUpdated);

    function accountsUpdated() {
        initCifFilter();
        if ($scope.featureIsAvailable) {
            updateDisplayedAccounts();
        } else {
            deselectAllAccounts();
            filterAccounts();
        }
    }

    function checkAllChanged() {
        $scope.displayedAccounts.forEach(account => {
            updateAccountChecked(account, $scope.checkAll);
        });
    }

    function chevronClass(orderBy) {
        const classes = [];

        if ($scope.orderByField !== orderBy) {
            classes.push('invisible');
        }

        if ($scope.reverseSort) {
            classes.push('fa fa-chevron-down');
        } else {
            classes.push('fa fa-chevron-up');
        }

        return classes.join(' ');
    }

    function deselectAllAccounts() {
        $scope.checkAll = false;
        $scope.allAccounts.forEach(account => {
            updateAccountChecked(account, false);
        });
    }

    function featureIsAvailableUpdated() {
        if (!$scope.featureIsAvailable) {
            deselectAllAccounts();
        }
    }

    function filterAccounts() {
        let filteredAccounts = $scope.allAccounts;
        if ($scope.selectedCifNumber !== allCifs) {
            filteredAccounts = filteredAccounts.filter(
                item => item.cifNumber === $scope.selectedCifNumber
            );
        }

        if ($scope.accountSearch.length) {
            const searchText = $scope.accountSearch.toLowerCase();
            filteredAccounts = $scope.allAccounts.filter(
                account =>
                    account.accountType.toLowerCase().indexOf(searchText) > -1 ||
                    account.accountNumber.toLowerCase().indexOf(searchText) > -1 ||
                    account.cifNumber.toLowerCase().indexOf(searchText) > -1
            );
        }

        $scope.displayedAccounts = filteredAccounts;
    }

    function hasNoAccounts() {
        return $scope.allAccounts.length === 0;
    }

    function showNoDisplayableAccounts() {
        return $scope.allAccounts.length > 0 && $scope.displayedAccounts.length === 0;
    }

    function hasAccountsForDisplay() {
        return $scope.displayedAccounts.length !== 0;
    }

    function initCifFilter() {
        $scope.cifNumbers = [];
        const distinctCifs = {};
        angular.forEach($scope.allAccounts, account => {
            if (account.cifNumber) {
                distinctCifs[account.cifNumber] = true;
            }
        });
        $scope.cifNumbers = Object.getOwnPropertyNames(distinctCifs);
        $scope.cifNumbers.unshift(allCifs);
    }

    function orderByFunction(account) {
        if ($scope.orderByField === 'number') return parseFloat(account.number);

        return account[$scope.orderByField];
    }

    function updateAccountChecked(account, isChecked) {
        account[$scope.checkProperty] = isChecked;
    }

    function updateDisplayedAccounts() {
        filterAccounts();
        updateCheckAll();
    }

    function updateCheckAll() {
        const anyAccountsUnchecked = $scope.displayedAccounts.some(
            account => !account[$scope.checkProperty]
        );
        if (anyAccountsUnchecked || $scope.displayedAccounts.length === 0) {
            $scope.checkAll = false;
        } else {
            $scope.checkAll = true;
        }
    }

    function updateOrderByField(newOrderBy) {
        $scope.orderByField = newOrderBy;
        $scope.reverseSort = !$scope.reverseSort;
    }
}
