angular.module('backOffice').directive('symantecCredentialState', () => ({
    restrict: 'E',
    scope: {
        symantecCredentialStatus: '=',
    },
    controller: symantecCredentialStateController,
    template: require('./symantecCredentialState.html'),
}));

symantecCredentialStateController.$inject = ['$scope'];

function symantecCredentialStateController($scope) {
    $scope.getCredentialStateHelpText = getCredentialStateHelpText;
    $scope.getCredentialState = getCredentialState;

    const credentialStateHelpText = {
        1: 'The credential is not registered.',
        2: 'The credential is in use.',
        3: 'The credential is in use with an associated account, but is not currently available. This state is set voluntarily, such as when a credential is lost or stolen. Temporary security codes are allowed.',
        4: 'The system has locked the token after the user entered multiple consecutive incorrect security codes.',
        5: 'The credential is in use with an associated account but is not currently available. This state does not permit the use of temporary security codes. This state also stops billing.',
    };

    const symantecCredentialState = {
        1: 'New',
        2: 'Enabled',
        3: 'Disabled',
        4: 'Locked',
        5: 'Inactive',
    };

    function getCredentialState() {
        const credentialState = symantecCredentialState[$scope.symantecCredentialStatus];
        if (credentialState === undefined) {
            return 'Unknown';
        }

        return credentialState;
    }

    function getCredentialStateHelpText() {
        const helpText = credentialStateHelpText[$scope.symantecCredentialStatus];
        if (helpText === undefined) {
            return 'The credential state is unknown.';
        }

        return helpText;
    }
}
