import UserActivityReportsService from '@treasury/domain/backoffice/services/reports/user-activity-reports-service';
import { ReportsService } from '@treasury/domain/backoffice/services/reports/reports-service';

export default class UserActivityReportClient {
    async getActivityTypes(...args) {
        return UserActivityReportsService.getActivityTypes(...args);
    }

    async getCompanies(...args) {
        return UserActivityReportsService.getCompanies(...args);
    }

    async getCompanyUsers(...args) {
        return UserActivityReportsService.getCompanyUsers(...args);
    }

    async getAllUsers(...args) {
        return UserActivityReportsService.getAllUsers(...args);
    }

    async runChannelUserActivityReport(...args) {
        return UserActivityReportsService.getChannelUserActivity(...args);
    }

    async runBackOfficeUserActivityReport(...args) {
        return UserActivityReportsService.getBackOfficeUserActivity(...args);
    }

    async downloadReport(...args) {
        return UserActivityReportsService.downloadReport(...args);
    }

    async saveReport(...args) {
        return UserActivityReportsService.saveReport(...args);
    }

    async getCustomReportFilters(...args) {
        return ReportsService.getCustomReportFilters(...args);
    }
}
