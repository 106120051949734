import { AuthenticationService } from '@treasury/domain/services/authentication';

angular.module('backOffice').factory('loginStateService', loginStateService);

loginStateService.$inject = [
    '$state',
    'userCredentialStatus',
    'accountService',
    'secureTokenStatus',
];

function loginStateService($state, userCredentialStatus, accountService, secureTokenStatus) {
    const stateName = Object.freeze({
        LOGIN: 'login',
        CHANGE_PASSWORD: 'change-password',
        USER_VERIFICATION: 'user-verification',
        USER_VERIFICATION_RESET: 'user-verification-reset',
        CREATE_SECURITY_QUESTIONS: 'create-security-questions',
        ANSWER_SECURITY_QUESTIONS: 'answer-security-questions',
        AUTHENTICATE_SECURE_TOKEN: 'authenticate-secure-token',
        REGISTER_SECURE_TOKEN: 'register-secure-token',
        FORGOT_PASSWORD: 'forgot-password',
        LOCKED_OUT: 'locked-out',
        UNLOCK_USER: 'unlock-user',
    });

    const rsaStatus = Object.freeze({
        NOT_SET: 'NotSet',
        ALLOW: 'Allow',
        BLOCK: 'Block',
        CHALLENGE: 'Challenge',
        LOCKED: 'Locked',
        REGISTER: 'Register',
        Undefined: 'Undefined',
    });

    let initialStatus;
    let expectedStateName = stateName.LOGIN;

    const transitions = [
        {
            currentStateName: stateName.LOGIN,
            nextStates: [
                {
                    userCredentialStatus: userCredentialStatus.ALLOW,
                    rsaStatus: rsaStatus.ALLOW,
                    secureTokenStatus: secureTokenStatus.ALLOW,
                    loginComplete: true,
                },
                {
                    userCredentialStatus: [
                        userCredentialStatus.ALLOW,
                        userCredentialStatus.TEMPORARY,
                        userCredentialStatus.EXPIRED,
                    ],
                    rsaStatus: rsaStatus.CHALLENGE,
                    nextStateName: stateName.ANSWER_SECURITY_QUESTIONS,
                },
                {
                    userCredentialStatus: userCredentialStatus.ALLOW,
                    rsaStatus: rsaStatus.REGISTER,
                    secureTokenStatus: secureTokenStatus.ALLOW,
                    nextStateName: stateName.CREATE_SECURITY_QUESTIONS,
                },
                {
                    userCredentialStatus: [
                        userCredentialStatus.TEMPORARY,
                        userCredentialStatus.EXPIRED,
                    ],
                    rsaStatus: [rsaStatus.ALLOW, rsaStatus.REGISTER],
                    secureTokenStatus: secureTokenStatus.ALLOW,
                    nextStateName: stateName.CHANGE_PASSWORD,
                },
                {
                    userCredentialStatus: [
                        userCredentialStatus.TEMPORARY,
                        userCredentialStatus.EXPIRED,
                        userCredentialStatus.ALLOW,
                    ],
                    rsaStatus: [rsaStatus.ALLOW, rsaStatus.REGISTER],
                    secureTokenStatus: [secureTokenStatus.CHALLENGE, secureTokenStatus.LOCKED],
                    nextStateName: stateName.AUTHENTICATE_SECURE_TOKEN,
                },
                {
                    userCredentialStatus: [
                        userCredentialStatus.TEMPORARY,
                        userCredentialStatus.EXPIRED,
                        userCredentialStatus.ALLOW,
                    ],
                    rsaStatus: [rsaStatus.ALLOW, rsaStatus.REGISTER],
                    secureTokenStatus: secureTokenStatus.REGISTER,
                    nextStateName: stateName.REGISTER_SECURE_TOKEN,
                },
            ],
        },
        {
            currentStateName: stateName.AUTHENTICATE_SECURE_TOKEN,
            nextStates: [
                {
                    userCredentialStatus: userCredentialStatus.ALLOW,
                    rsaStatus: [rsaStatus.ALLOW, rsaStatus.CHALLENGE],
                    loginComplete: true,
                },
                {
                    userCredentialStatus: userCredentialStatus.ALLOW,
                    rsaStatus: rsaStatus.REGISTER,
                    nextStateName: stateName.CREATE_SECURITY_QUESTIONS,
                },
                {
                    userCredentialStatus: [
                        userCredentialStatus.EXPIRED,
                        userCredentialStatus.TEMPORARY,
                    ],
                    nextStateName: stateName.CHANGE_PASSWORD,
                },
            ],
        },
        {
            currentStateName: stateName.REGISTER_SECURE_TOKEN,
            nextStates: [
                {
                    userCredentialStatus: userCredentialStatus.ALLOW,
                    rsaStatus: [rsaStatus.ALLOW, rsaStatus.CHALLENGE],
                    loginComplete: true,
                },
                {
                    userCredentialStatus: userCredentialStatus.ALLOW,
                    rsaStatus: rsaStatus.REGISTER,
                    nextStateName: stateName.CREATE_SECURITY_QUESTIONS,
                },
                {
                    userCredentialStatus: [
                        userCredentialStatus.EXPIRED,
                        userCredentialStatus.TEMPORARY,
                    ],
                    nextStateName: stateName.CHANGE_PASSWORD,
                },
            ],
        },
        {
            currentStateName: stateName.ANSWER_SECURITY_QUESTIONS,
            nextStates: [
                {
                    userCredentialStatus: userCredentialStatus.ALLOW,
                    secureTokenStatus: secureTokenStatus.ALLOW,
                    loginComplete: true,
                },
                {
                    userCredentialStatus: [
                        userCredentialStatus.ALLOW,
                        userCredentialStatus.EXPIRED,
                        userCredentialStatus.TEMPORARY,
                    ],
                    secureTokenStatus: [secureTokenStatus.CHALLENGE, secureTokenStatus.LOCKED],
                    nextStateName: stateName.AUTHENTICATE_SECURE_TOKEN,
                },
                {
                    userCredentialStatus: [
                        userCredentialStatus.ALLOW,
                        userCredentialStatus.EXPIRED,
                        userCredentialStatus.TEMPORARY,
                    ],
                    secureTokenStatus: secureTokenStatus.REGISTER,
                    nextStateName: stateName.REGISTER_SECURE_TOKEN,
                },
                {
                    userCredentialStatus: [
                        userCredentialStatus.EXPIRED,
                        userCredentialStatus.TEMPORARY,
                    ],
                    secureTokenStatus: secureTokenStatus.ALLOW,
                    nextStateName: stateName.CHANGE_PASSWORD,
                },
            ],
        },
        {
            currentStateName: stateName.CREATE_SECURITY_QUESTIONS,
            nextStates: [
                {
                    loginComplete: true,
                },
            ],
        },
        {
            currentStateName: stateName.CHANGE_PASSWORD,
            nextStates: [
                {
                    rsaStatus: rsaStatus.REGISTER,
                    nextStateName: stateName.CREATE_SECURITY_QUESTIONS,
                },
                {
                    loginComplete: true,
                },
            ],
        },
        {
            currentStateName: stateName.USER_VERIFICATION,
            nextStates: [
                {
                    userCredentialStatus: [
                        userCredentialStatus.EXPIRED,
                        userCredentialStatus.TEMPORARY,
                    ],
                    rsaStatus: rsaStatus.CHALLENGE,
                    nextStateName: stateName.ANSWER_SECURITY_QUESTIONS,
                },
                {
                    userCredentialStatus: [
                        userCredentialStatus.EXPIRED,
                        userCredentialStatus.TEMPORARY,
                    ],
                    rsaStatus: [rsaStatus.ALLOW, rsaStatus.REGISTER],
                    secureTokenStatus: [secureTokenStatus.CHALLENGE, secureTokenStatus.LOCKED],
                    nextStateName: stateName.AUTHENTICATE_SECURE_TOKEN,
                },
                {
                    userCredentialStatus: [
                        userCredentialStatus.EXPIRED,
                        userCredentialStatus.TEMPORARY,
                    ],
                    rsaStatus: [rsaStatus.ALLOW, rsaStatus.REGISTER],
                    secureTokenStatus: secureTokenStatus.REGISTER,
                    nextStateName: stateName.REGISTER_SECURE_TOKEN,
                },
                {
                    userCredentialStatus: userCredentialStatus.TEMPORARY,
                    rsaStatus: [rsaStatus.REGISTER, rsaStatus.ALLOW],
                    secureTokenStatus: secureTokenStatus.ALLOW,
                    nextStateName: stateName.CHANGE_PASSWORD,
                },
            ],
        },
        {
            currentStateName: stateName.USER_VERIFICATION_RESET,
            nextStates: [
                {
                    userCredentialStatus: [
                        userCredentialStatus.EXPIRED,
                        userCredentialStatus.TEMPORARY,
                    ],
                    rsaStatus: rsaStatus.CHALLENGE,
                    nextStateName: stateName.ANSWER_SECURITY_QUESTIONS,
                },
                {
                    userCredentialStatus: [
                        userCredentialStatus.EXPIRED,
                        userCredentialStatus.TEMPORARY,
                    ],
                    rsaStatus: [rsaStatus.ALLOW, rsaStatus.REGISTER],
                    secureTokenStatus: [secureTokenStatus.CHALLENGE, secureTokenStatus.LOCKED],
                    nextStateName: stateName.AUTHENTICATE_SECURE_TOKEN,
                },
                {
                    userCredentialStatus: [
                        userCredentialStatus.EXPIRED,
                        userCredentialStatus.TEMPORARY,
                    ],
                    rsaStatus: [rsaStatus.ALLOW, rsaStatus.REGISTER],
                    secureTokenStatus: secureTokenStatus.REGISTER,
                    nextStateName: stateName.REGISTER_SECURE_TOKEN,
                },
                {
                    userCredentialStatus: userCredentialStatus.TEMPORARY,
                    rsaStatus: [rsaStatus.REGISTER, rsaStatus.ALLOW],
                    secureTokenStatus: secureTokenStatus.ALLOW,
                    nextStateName: stateName.CHANGE_PASSWORD,
                },
            ],
        },
    ];

    const service = {
        initiate,
        nextState,
        validateCurrentState,
        getSecureTokenStatus,
        getUserCredentialStatus,
    };

    function initiate(status) {
        initialStatus = {
            userCredentialStatus: status.userCredentialStatus,
            rsaStatus: status.rsaStatus,
            secureTokenStatus: status.secureTokenStatus,
        };
        return nextState();
    }

    function getSecureTokenStatus() {
        return initialStatus.secureTokenStatus;
    }

    function getUserCredentialStatus() {
        return initialStatus.userCredentialStatus;
    }

    function validateCurrentState() {
        if ($state.current.name !== expectedStateName) {
            $state.go(stateName.LOGIN);

            return false;
        }

        return true;
    }

    function nextState() {
        const matchedTransitions = transitions.filter(
            transition => transition.currentStateName === $state.current.name
        );

        if (matchedTransitions.length > 1) {
            throw new Error('Too many transitions found.');
        }

        if (matchedTransitions.length !== 1) {
            return false;
        }

        const matchedTransition = matchedTransitions[0];

        const matchedStates = matchedTransition.nextStates.filter(
            state =>
                matchValue(initialStatus.userCredentialStatus, state.userCredentialStatus) &&
                matchValue(initialStatus.rsaStatus, state.rsaStatus) &&
                matchValue(initialStatus.secureTokenStatus, state.secureTokenStatus)
        );

        if (matchedStates.length <= 0) {
            return false;
        }

        // States have order, so the first matching state takes precedence
        const matchedState = matchedStates[0];

        if (matchedState.loginComplete) {
            completeLogin();

            return true;
        }

        // Need to update the current state before visiting the next state so
        // it wont fail validation.
        expectedStateName = matchedState.nextStateName;
        $state.go(matchedState.nextStateName, matchedState.data);

        return true;
    }

    function matchValue(initialValue, value) {
        if (value == null) {
            return true;
        }

        if (Array.isArray(value)) {
            return value.some(item => initialValue === item);
        }

        return initialValue === value;
    }

    async function completeLogin() {
        accountService.completeLogin();

        expectedStateName = stateName.LOGIN;
        initialStatus = null;
        (await AuthenticationService.getInstance()).authenticate();
    }

    return service;
}
