angular
    .module('backOffice')
    .controller('aaConfigAccountsModalController', aaConfigAccountsModalController);

aaConfigAccountsModalController.$inject = [
    '$scope',
    '$modalInstance',
    '$filter',
    'companiesService',
    'companyId',
    'product',
    'description',
    'analysisTransactionTypeId',
    'accountCustomTransactionCodes',
    'transactionTypeCategory',
];

function aaConfigAccountsModalController(
    $scope,
    $modalInstance,
    $filter,
    companiesService,
    companyId,
    product,
    description,
    analysisTransactionTypeId,
    accountCustomTransactionCodes,
    transactionTypeCategory
) {
    $scope.product = product;
    $scope.description = description;
    $scope.analysisTransactionTypeId = analysisTransactionTypeId;
    $scope.filter = '';
    $scope.headerCheckBox = {
        isSelected: false,
    };

    init();

    $scope.cancel = function () {
        $modalInstance.close({
            action: 'cancel',
        });
    };

    $scope.saveAccounts = function () {
        const accountsToSave = [];
        angular.forEach($scope.accounts, account => {
            if (account.selected != undefined) {
                accountsToSave.push({
                    id: account.id,
                    active: account.selected,
                    number: account.number,
                });
            }
        });
        $modalInstance.close({
            action: 'save',
            accounts: accountsToSave,
        });
    };

    $scope.selectAccount = function (account) {
        if (!account.selected) {
            $scope.headerCheckBox.isSelected = false;
            if (accountCustomTransactionCodes[account.id] === undefined) {
                account.selected = undefined;
            }
        }
    };

    $scope.selectAll = function () {
        angular.forEach($scope.accounts, account => {
            account.selected = $scope.headerCheckBox.isSelected;
        });
    };

    $scope.filterAccounts = function () {
        $scope.filteredAccounts = $filter('filter')($scope.accounts, $scope.filter);
    };

    $scope.selectedIndexChanged = function (selectedIndex) {
        angular.forEach($scope.accounts, account => {
            if (accountCustomTransactionCodes[account.id] !== undefined) {
                account.selected = false;
            } else if (account.selected !== undefined) {
                account.selected = undefined;
            }
        });
        $scope.filteredAccounts[selectedIndex].selected = true;
    };

    function init() {
        $scope.isAch = product.toLowerCase().indexOf('ach') !== -1;
        $scope.isBulkWire = transactionTypeCategory.transactionName === 'BulkWireFileUploaded';

        if (accountCustomTransactionCodes == undefined) {
            accountCustomTransactionCodes = {};
        }
        getCompanyAccounts();
    }

    function getCompanyAccounts() {
        companiesService
            .getCompanyProductAccounts(companyId, analysisTransactionTypeId)
            .then(response => {
                if (
                    $scope.isAch &&
                    accountCustomTransactionCodes[0] !== undefined &&
                    accountCustomTransactionCodes[0].active
                ) {
                    const enteredAccountNumber = accountCustomTransactionCodes[0].accountNumber;
                    angular.forEach(response, account => {
                        if (account.number == enteredAccountNumber) {
                            $scope.selectedAccount = account.id;
                            delete accountCustomTransactionCodes[0];
                            accountCustomTransactionCodes[account.id] = {
                                accountId: account.id,
                                active: true,
                                number: account.number,
                            };
                        }
                    });
                } else {
                    $scope.headerCheckBox.isSelected = true;
                    angular.forEach(response, account => {
                        const accountCustomTransactionCode =
                            accountCustomTransactionCodes[account.id];

                        if (accountCustomTransactionCode != undefined) {
                            account.selected = accountCustomTransactionCode.active;
                            $scope.headerCheckBox.isSelected =
                                $scope.headerCheckBox.isSelected &&
                                accountCustomTransactionCode.active;
                            if (account.selected) {
                                $scope.selectedAccount = account.id;
                            }
                        } else {
                            $scope.headerCheckBox.isSelected = false;
                        }
                    });
                }
                $scope.accounts = response;
                $scope.filterAccounts();
            });
    }
}
