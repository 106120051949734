const {
    FeatureFlagService,
} = require('@treasury/domain/services/feature-flags/feature-flag.service.ts');
const { Feature } = require('@treasury/domain/services/feature-flags/feature-flag.types.ts');

angular.module('backOffice').controller('ManageMessageController', manageMessageController);

manageMessageController.$inject = [
    '$scope',
    '$modalInstance',
    'toaster',
    'selectedMessage',
    'modalService',
    'informationCenterConfigurationService',
    'entitlementsService',
];

function manageMessageController(
    $scope,
    $modalInstance,
    toaster,
    selectedMessage,
    modalService,
    informationCenterConfigurationService,
    entitlementsService
) {
    $scope.selectedMessage = selectedMessage;

    async function setMessageTypeList() {
        const isEnabled = await FeatureFlagService.isEnabled(Feature.MessageCenterEnhancement);
        if (isEnabled) {
            return ['None', 'Banner', 'Modal', 'Warning', 'Information', 'Alert'];
        }
        return ['None', 'Warning', 'Information', 'Alert'];
    }

    // this will dismiss the window.
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };
    $scope.requiredEntitlement = requiredEntitlement;
    $scope.getTitle = getTitle;

    init();

    function getTitle() {
        if (selectedMessage.id > -1) {
            if (entitlementsService.hasEntitlement('Edit Information Center Configuration')) {
                return 'Edit Information Center Posting';
            }
            return 'View Information Center Posting';
        }
        return 'Add Information Center Posting';
    }

    function requiredEntitlement() {
        if ($scope.selectedMessage.id > -1) {
            return 'Edit Information Center Configuration';
        }
        return 'Information Center Configuration Add Message';
    }
    async function init() {
        $scope.messageTypeList = await setMessageTypeList();
        const today = new Date();
        const dd = today.getDate();
        const mm = today.getMonth() + 1; // January is 0!
        const yyyy = today.getFullYear();
        $scope.currentDate = formatDate(`${mm}/${dd}/${yyyy}`);

        if ($scope.selectedMessage.id === -1) {
            $scope.selectedMessage.type = 'None';
            $scope.selectedMessage.status = 'Inactive';
        } else {
            $scope.selectedMessage.startDate = formatDate($scope.selectedMessage.startDate);
            $scope.selectedMessage.endDate = formatDate($scope.selectedMessage.endDate);

            // as per BA's if you edit a message that has a start date in the past, set it to today.
            determineIfStartDateShouldChange();
        }
    }

    function determineIfStartDateShouldChange() {
        const _startDate = new Date(formatDate($scope.selectedMessage.startDate));
        const currentDate = new Date($scope.currentDate);
        if (_startDate < currentDate) {
            $scope.selectedMessage.startDate = angular.copy($scope.currentDate);
        }
    }

    function formatDate(dt) {
        const date = new Date(dt);
        let dd = date.getDate();
        if (dd < 10) {
            dd = `0${dd}`;
        }
        let mm = date.getMonth() + 1; // January is 0!
        if (mm < 10) {
            mm = `0${mm}`;
        }
        const yyyy = date.getFullYear();
        return `${mm}/${dd}/${yyyy}`;
    }

    $scope.formatDate = function (column) {
        if (
            $scope.selectedMessage[column] !== null &&
            $scope.selectedMessage[column] !== undefined &&
            $scope.selectedMessage[column].length > 0
        ) {
            $scope.selectedMessage[column] = formatDate($scope.selectedMessage[column]);
        }
    };

    $scope.save = function () {
        if ($scope.selectedMessage.id > -1) {
            updateMessage();
        } else {
            createMessage();
        }
    };

    function deleteMessage() {
        informationCenterConfigurationService
            .deleteMessage($scope.selectedMessage.id)
            .then(response => {
                response.isDeleted = true;
                toaster.delete('Information Center Posting');
                $modalInstance.close(response);
            });
    }

    $scope.delete = function () {
        const modalOptions = {
            bodyText: 'Are you sure you want to delete the Message?',
            submit(result) {
                deleteMessage();
                $modalInstance.close(result);
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    };

    // used to set the form instance.
    $scope.setForm = function (form) {
        $scope.form = form;
    };

    // we watch for form.$dirty, this is used to alert the end user that they changed something in the form.
    $scope.$watch('form.$dirty', isDirty => {
        $modalInstance.isDirty = isDirty;
    });

    // create user
    function createMessage() {
        informationCenterConfigurationService
            .saveInformationCenterConfiguration($scope.selectedMessage)
            .then(response => {
                toaster.save('Information Center Posting');
                $modalInstance.close(response);
            });
    }

    // update user
    function updateMessage() {
        informationCenterConfigurationService
            .updateInformationCenterConfiguration($scope.selectedMessage)
            .then(response => {
                toaster.save('Information Center Posting');
                $modalInstance.close(response);
            });
    }
}
