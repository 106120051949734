import { DiContainer } from '@jack-henry/frontend-utils/di';
import { UsersService } from '@treasury/domain/backoffice/services';

angular
    .module('backOffice')
    .component('userSearch', {
        transclude: true,
        controller: userSearchController,
        template: require('../views/userSearchView.html'),
    })
    .service('userSearchQueryService', () => {
        const searchOptions = [
            { label: 'Name', value: 'UserName' },
            { label: 'Digital ID', value: 'DigitalId' },
            { label: 'TM User ID', value: 'UserAlias' },
        ];
        return {
            searchTerms: { query: '', column: searchOptions[0] },
            searchOptions,
        };
    });

// eslint-disable-next-line no-use-before-define
userSearchController.$inject = ['$scope', '$q', 'userSearchQueryService'];

function userSearchController($scope, $q, userSearchQueryService) {
    $scope.userSearchQueryService = userSearchQueryService;
    const { column, query } = userSearchQueryService.searchTerms;
    $scope.userSearchInput = query ?? '';
    $scope.searchResults = [];
    $scope.searchPerformed = true;
    $scope.userSearchSelect = column
        ? userSearchQueryService.searchOptions.find(o => o.value === column.value)
        : userSearchQueryService.searchOptions[0];
    $scope.columnSearchOptions = userSearchQueryService.searchOptions;

    const validateSearch = () => {
        const { query } = userSearchQueryService.searchTerms;
        if (!query || query.length < 3) {
            $scope.searchResults = [];
            $scope.searching = false;
            return false;
        }
        return true;
    };

    const searchUsers = async () => {
        const { query, column } = userSearchQueryService.searchTerms;
        if (!validateSearch()) return;

        $scope.searching = true;
        const di = await DiContainer.getInstance();
        const usersService = di.get(UsersService);
        const usersPromise = usersService.searchFiUsers(query, column.value);

        $q.resolve(usersPromise).then(users => {
            $scope.searchResults = users;
            $scope.searching = false;
        });
    };

    $scope.searchTermUpdated = function () {
        searchUsers();
    };

    $scope.columnSelected = function () {
        searchUsers();
    };

    searchUsers();
}
