import { exists } from '@treasury/utils';

angular
    .module('backOffice')
    .controller('UserVerificationResetController', userVerificationResetController);

userVerificationResetController.$inject = [
    '$scope',
    'accountService',
    '$location',
    'pagePushService',
    'jhaSupportService',
    'loginStateService',
    '$window',
];

function userVerificationResetController(
    $scope,
    accountService,
    $location,
    pagePushService,
    jhaSupportService,
    loginStateService,
    $window
) {
    $scope.loginData = null;
    $scope.message = '';
    $scope.user = {};

    $scope.login = login;
    $scope.reset = reset;

    init();

    function init() {
        $scope.loginData = {
            fiUser: '',
            loginId: '',
            password: '',
            useRefreshTokens: false,
            code: decodeURIComponent($location.search().code),
        };
        $window.sessionStorage.clear();
    }

    function login() {
        pagePushService.setUser($scope.loginData);
        jhaSupportService.setUserFiId($scope.loginData.fiId);

        // Get login information.
        $scope.loginData.userName = $scope.loginData.loginId;
        accountService
            .VerifyUserForPasswordReset($scope.loginData)
            .then(response => {
                $scope.loginData.credential = response;
                $scope.message = accountService.checkForError({
                    statusCode: response.userCredentialStatus,
                    message: response.message,
                });

                if (loginStateService.initiate(response)) {
                }
            })
            .catch(err => {
                if (exists(err)) {
                    $scope.message = err instanceof Error ? err.message : err.error_description;
                }
            });
    }

    function reset() {
        $scope.loginData.fiId = null;
        $scope.loginData.loginId = null;
        $scope.form.$setPristine();
    }
}
