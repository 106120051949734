import moment from 'moment';

angular.module('backOffice').directive('reportFilter', ReportFilterDirective);
//var moment = require('moment'); // require

function ReportFilterDirective() {
    return {
        restrict: 'A',
        replace: true,
        scope: {
            reportType: '=',
            reportId: '=',
            reportName: '=',
            reportNameDateRange: '=',
            reportData: '=',
            filterCollapsed: '=',
            reportGenerate: '&',
        },
        template: require('./reportFilterView.html'),
        controller: ReportFilterController,
    };
}

ReportFilterController.$inject = ['$scope', '$state', '$modal', 'toaster', 'reportsService'];

function ReportFilterController($scope, $state, $modal, toaster, reportsService) {
    // scope
    $scope.filterHeight = 200;

    $scope.reportFilter = {
        reportId: $scope.reportId,
        reportType: $scope.reportType,
    };

    $scope.customFilter = {
        reportId: 0,
        reportType: $scope.reportType,
    };

    // scope functions
    $scope.saveFilter = saveFilter;
    $scope.runReport = runReport;
    $scope.getDropDownOrderField = getDropDownOrderField;
    $scope.isSelectOneRecommendationNeeded = isSelectOneRecommendationNeeded;
    $scope.OnChange = OnChange;
    $scope.errors = {};
    // scope functions implementation

    function OnChange(filter, newValue, ctrlName) {
        const control = $scope.form[ctrlName];
        const { checkNumberMaxLength } = $scope.reportFilter;
        if (checkNumberMaxLength) {
            if (filter.model === 'checkNumber' && newValue.length > checkNumberMaxLength) {
                control.$setValidity('maxLength', false);
                $scope.errors[control.$name] = `Cannot exceed ${checkNumberMaxLength} characters`;
                control.$setDirty();
            } else {
                control.$setValidity('maxLength', true);
                delete $scope.errors[control.$name];
            }
        }
    }

    function isSelectOneRecommendationNeeded() {
        if ($scope.reportType === 'arpCheckException' || $scope.reportType === 'achExceptionReport')
            return true;
    }
    function getDropDownOrderField(filter) {
        const useOrder = filter.filterData.every(filterData => filterData.order != null);

        return useOrder ? 'order' : 'value';
    }

    function saveFilter(isExistingFilter) {
        if (!isExistingFilter) {
            const modalInstance = $modal.open({
                template: require('./saveReportModal/saveReportModalView.html'),
                size: 'md',
                controller: 'SaveReportModalController',
                backdrop: 'static',
                resolve: {
                    customFilter() {
                        return window.angular.copy($scope.customFilter);
                    },
                    isExistingFilter() {
                        return isExistingFilter;
                    },
                },
            });

            modalInstance.result.then(customFilter => {
                reportsService.updateReportCustomFilter(customFilter).then(response => {
                    toaster.save('Report');
                    $state.go('reports.view', {
                        type: response.reportType,
                        id: response.reportId,
                    });
                });
            });
        } else {
            reportsService.updateReportCustomFilter($scope.customFilter).then(response => {
                toaster.save('Report');
                $state.go('reports.view', {
                    type: response.reportType,
                    id: response.reportId,
                });
            });
        }
    }

    function specialCaseForSelectAllCompanies(filter) {
        /* 
            This is a hack to help out the API. 
            Selecting all companies is the same as selecting no companies.
            But selecting all companies generates a SQL statement with a huge IN () clause and bombs out.
            So if all companies are selected, we pretend like no companies are selected.
        */

        if (filter.reportType !== 'internalTransfer') return filter;

        const allCompaniesCount =
            $scope.reportFilter.filters.find(f => f.model === 'companies')?.filterData?.length ?? 0;

        if (filter.companies.length !== allCompaniesCount) return filter;

        return { ...filter, companies: [] };
    }

    function runReport() {
        $scope.reportGenerate({ filter: specialCaseForSelectAllCompanies($scope.customFilter) });
    }

    function load() {
        reportsService.getReport($scope.reportId).then(response => {
            $scope.report = response;
            $scope.isCustomReport = $scope.report.isCustomReport;
            if ($scope.isCustomReport) {
                $scope.filterCollapsed = true;
            }
            loadReportFilter();
        });
    }

    function loadReportFilter() {
        reportsService.getReportFilter($scope.reportType).then(response => {
            $scope.reportFilter = response;
            $scope.reportName = $scope.reportFilter.name;

            if ($scope.isCustomReport) {
                loadCustomReport();
            } else if (
                $scope.reportFilter.filters == null ||
                $scope.reportFilter.filters.length <= 0
            ) {
                runReport();
            }

            if ($scope.reportFilter.runDefaultSearch == true && $scope.isCustomReport != true) {
                setDefaultSearchFilterCriteria();
                runReport();
            } else if ($scope.reportFilter.setDefaultSearchToSelectAll) {
                setDefaultSearchFilterCriteria();
            }
        });
    }

    function setDefaultSearchFilterCriteria() {
        selectAllItems();

        $scope.customFilter.createdDate = 'Today';
        $scope.customFilter.datePeriod = 'Today';
        $scope.customFilter.amount = 'SpecificAmount';
        $scope.customFilter.transactionType = 'Both';
        $scope.customFilter.achDateField = 'CreatedDate';
    }

    function selectAllItems() {
        if ($scope.isCustomReport) return;

        $scope.reportFilter.filters.forEach(reportFilter => {
            if (!isMultiSelect(reportFilter)) return;

            $scope.customFilter[reportFilter.model] = [];
            reportFilter.filterData.forEach(filterData => {
                filterData.isChecked = true;
                filterData.pageLoadChecked = true;
                $scope.customFilter[reportFilter.model].push(filterData);
            });
        });
    }

    function loadCustomReport() {
        reportsService.getReportCustomFilter($scope.reportType, $scope.reportId).then(response => {
            $scope.customFilter = response;
            $scope.reportName = $scope.customFilter.name;
            runReport();
        });
    }

    function isMultiSelect(reportFilter) {
        return reportFilter.type === 'multi-select';
    }

    (function () {
        // init
        load();
        $scope.currentDate = moment().format('MM/DD/YYYY');
    })();
}
