/* eslint-disable global-require */
import { DiContainer } from '@jack-henry/frontend-utils/di';
import { LegacyNavigationService, NavigationService } from '@treasury/core/navigation';
import { states } from './app.routes.states';
import { boDiModule } from './constants';

export const boStatesModule = angular
    .module('backOffice.states', ['ui.router', 'ngAnimate', boDiModule.name])
    .config([
        '$httpProvider',
        '$animateProvider',
        'TmDi',
        function configureRoutes(
            $httpProvider: ng.IHttpProvider,
            $animateProvider: ng.animate.IAnimateProvider,
            di: DiContainer
        ) {
            $animateProvider.classNameFilter(/animation-enabled/);

            // initialize headers if not there
            if (!$httpProvider.defaults.headers) {
                $httpProvider.defaults.headers = {};
            }

            // initialize get if not there
            if (!$httpProvider.defaults.headers.get) {
                $httpProvider.defaults.headers.get = {};
            }

            // disable IE ajax request caching
            $httpProvider.defaults.headers.get['If-Modified-Since'] =
                'Mon, 26 Jul 1997 05:00:00 GMT';
            // extra
            $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
            $httpProvider.defaults.headers.get.Pragma = 'no-cache';

            const navService = di.get(NavigationService) as LegacyNavigationService;
            navService.addStates(states, location => {
                const { hash, pathname } = location;

                // Redirect the old hashbang urls to the new html5 urls
                if (/^\//.test(hash)) {
                    let workingHash = hash;

                    while (workingHash.charAt(0) === '/') {
                        workingHash = workingHash.substring(1);
                    }

                    return `${pathname}${hash}`;
                }

                return `/login`;
            });
        },
    ]);
