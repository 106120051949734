angular.module('backOffice').factory('expirationCache', expirationCache);

expirationCache.$inject = ['$timeout'];

let store = {};
const MAX_SIZE = 10;

function expirationCache($timeout) {
    const DEFAULT_CACHE_TIMEOUT_IN_SECONDS = 60 * 15;

    function getCache(timeoutInSeconds) {
        const timeoutInMilliseconds = 1000 * (timeoutInSeconds || DEFAULT_CACHE_TIMEOUT_IN_SECONDS);

        const cacheHandler = {
            get(key) {
                if (store.hasOwnProperty(key)) {
                    return store[key].value;
                }

                return undefined;
            },
            put(key, value) {
                if (store[key] !== undefined) {
                    cancelTimeout(key);
                } else if (Object.keys(store).length >= MAX_SIZE) {
                    removeOldestElement();
                }

                const expiration = Date.now() + timeoutInMilliseconds;
                store[key] = {
                    value,
                    expires: expiration,
                    expirePromise: $timeout(() => {
                        cacheHandler.remove(key);
                    }, timeoutInMilliseconds),
                };
            },
            remove(key) {
                cancelTimeout(key);
                delete store[key];
            },
            removeAll() {
                removeAllElements();
            },
            destroy() {
                removeAllElements();
            },
        };

        return cacheHandler;
    }

    function cancelTimeout(key) {
        if (store[key] !== undefined) {
            $timeout.cancel(store[key].expirePromise);
        }
    }

    function removeAllElements() {
        Object.keys(store).forEach(cancelTimeout);
        store = {};
    }

    function removeOldestElement() {
        let oldestElement = {
            key: '',
            expires: new Date(8640000000000000), // largest possible JS date
        };

        for (const key in store) {
            const element = store[key];

            if (element.expires < oldestElement.expires) {
                oldestElement = {
                    key,
                    expires: element.expires,
                };
            }
        }

        $timeout.cancel(store[oldestElement.key].expirePromise);
        delete store[oldestElement.key];
    }

    return {
        getCache,
    };
}
