angular.module('backOffice').controller('AccessController', accessController);

accessController.$inject = [
    '$scope',
    '$filter',
    'toaster',
    'companyUsersService',
    'utilityService',
];

function accessController($scope, $filter, toaster, companyUsersService, utilityService) {
    $scope.isSuperUser = $scope.$parent.userHeader.isSuperUser;
    $scope.selectAllCbox = {};
    $scope.accountAccess = {};
    $scope.accountAccessPristine = {};

    $scope.reset = reset;
    $scope.save = save;
    $scope.selectAll = selectAll;
    $scope.setCheckbox = setCheckbox;
    $scope.setForm = setForm;
    $scope.filterAccounts = filterAccounts;
    $scope.filter = { filterText: null };

    function init() {
        loadAccountAccess();
    }

    function save() {
        const updatedAccounts = {
            addedAccounts: [],
            removedAccounts: [],
        };

        angular.forEach($scope.accountAccess.accounts, pending => {
            angular.forEach($scope.accountAccessPristine.accounts, pristine => {
                if (pending.companyAccount.id === pristine.companyAccount.id) {
                    if (pending.hasPendingUserAccess && !pristine.hasPendingUserAccess) {
                        updatedAccounts.addedAccounts.push(pending);
                    } else if (!pending.hasPendingUserAccess && pristine.hasPendingUserAccess) {
                        updatedAccounts.removedAccounts.push(pending);
                    }
                }
            });
        });

        if (
            updatedAccounts.addedAccounts.length > 0 ||
            updatedAccounts.removedAccounts.length > 0
        ) {
            companyUsersService
                .updateUserAccountAccess($scope.companyId, $scope.id, updatedAccounts)
                .then(response => {
                    $scope.accountAccessPristine = window.angular.copy($scope.accountAccess);
                    $scope.$parent.notifySave();
                    $scope.form.$setPristine();
                    toaster.save('Account Access');
                });
        }
    }

    function selectAll() {
        window.angular.forEach($scope.filteredAccounts, account => {
            account.hasPendingUserAccess = $scope.selectAllCbox.value;
        });
    }

    function setForm(form) {
        $scope.form = form;
    }

    function setCheckbox() {
        updateSelectAllCheckboxStatus();
    }

    function reset() {
        $scope.accountAccess = angular.copy($scope.accountAccessPristine);
        $scope.filteredAccounts = $scope.accountAccess.accounts;
        $scope.form.$setPristine();
    }

    // Private
    function loadAccountAccess() {
        companyUsersService
            .getUserAccountAccess($scope.companyId, $scope.id, false)
            .then(response => {
                $scope.accountAccess = response;
                $scope.accountAccessPristine = angular.copy($scope.accountAccess);
                $scope.filteredAccounts = $filter('orderByNumeric')(
                    $scope.accountAccess.accounts,
                    'companyAccount.number',
                    false
                );
                updateSelectAllCheckboxStatus();
            });
    }

    function updateSelectAllCheckboxStatus() {
        $scope.selectAllCbox.value = true;
        angular.forEach($scope.filteredAccounts, account => {
            if (account.hasPendingUserAccess === false) {
                $scope.selectAllCbox.value = false;
            }
        });
    }

    function filterAccounts() {
        if ($scope.filter.filterText) {
            $scope.filteredAccounts = $filter('filter')(
                $scope.accountAccess.accounts,
                $scope.filter.filterText
            );
        } else {
            $scope.filteredAccounts = $filter('orderByNumeric')(
                $scope.accountAccess.accounts,
                'companyAccount.number',
                false
            );
        }
        updateSelectAllCheckboxStatus();
    }

    init();
}
