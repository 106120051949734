angular.module('backOffice').controller('InstitutionSearchController', InstitutionSearchController);

InstitutionSearchController.$inject = ['jhaSupportService', '$state', 'entitlementsService'];

function InstitutionSearchController(jhaSupportService, $state, entitlementsService) {
    const vm = this;

    vm.clearSearch = clearSearch;
    vm.impersonateInstitution = impersonateInstitution;
    vm.filterInstitutions = filterInstitutions;
    vm.institutions = null;
    vm.filteredInstitutions = null;

    init();

    function init() {
        getInstitutions();
    }

    function clearSearch() {
        vm.searchText = '';
        vm.filterInstitutions();
    }

    function getInstitutions() {
        jhaSupportService.getInstitutions().then(institutions => {
            vm.institutions = institutions;
            vm.filteredInstitutions = vm.institutions;
        });
    }

    function impersonateInstitution(institution) {
        jhaSupportService.impersonateInstitution(institution.institutionId).then(() => {
            entitlementsService.updateEntitlements().then(() => {
                $state.go('company-search');
            });
        });
    }

    function filterInstitutions(filterText) {
        if (filterText) {
            vm.filteredInstitutions = vm.institutions.filter(
                institution =>
                    stringContainsText(institution.name, filterText) ||
                    stringContainsText(institution.fiId, filterText)
            );
        } else {
            vm.filteredInstitutions = vm.institutions;
        }
    }

    function stringContainsText(target, search) {
        return target.toUpperCase().indexOf(search.toUpperCase()) >= 0;
    }
}
