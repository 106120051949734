import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('wireService', wireService);

wireService.$inject = ['$resource', '$http', '$filter'];

function wireService($resource, $http, $filter) {
    const resourceUrl = `${getBackOfficeAPIPath()}wires/`;
    return {
        getCompanies,
        getAccounts,
        saveWireCompany,
        updateWireCompany,
        getExchangeFees,
        saveExchangeFees,
        getWire,
        summarizeFrequency,
        frequencyTypeToFriendly,
        uploadRatesheet,
        getExchangeRatesMetadata,
        getExchangeRates,
        getNonCoreInternationalWireCompany,
        createNonCoreInternationalWireCompany,
        editNonCoreInternationalWireCompany,
        updateExternalNonCoreFxWire,
    };

    function getWire(id) {
        const url = resourceUrl + id;

        return $http.get(url).then(response => response.data);
    }

    function getCompanies(id, isDli) {
        return $resource(`${resourceUrl}:id/companies/:isDli`, { id, isDli }).query().$promise;
    }

    function getAccounts(id, companyId, isDli) {
        return $resource(
            `${resourceUrl}:id/companies/:companyId/accounts/:isDli`,
            {},
            { getAccounts: { method: 'GET', isArray: false } }
        ).getAccounts({ id, companyId, isDli }).$promise;
    }

    function saveWireCompany(productId, wireCompany, isDli) {
        wireCompany.isDli = isDli;
        return $resource(`${resourceUrl}:id/companies`, { id: productId }).save(wireCompany)
            .$promise;
    }

    function updateWireCompany(productId, wireCompany, isDli) {
        wireCompany.isDli = isDli;
        const resource = $resource(
            `${resourceUrl}:id/companies/:actionId`,
            { id: productId, actionId: wireCompany.id },
            { update: { method: 'PUT' } }
        );
        return resource.update(wireCompany).$promise;
    }

    function getExchangeFees() {
        return $resource(`${resourceUrl}getExchangeFees`).query().$promise;
    }

    function saveExchangeFees(exchangeFees) {
        return $resource(
            `${resourceUrl}saveExchangeFees`,
            {},
            { saveExchangeFees: { method: 'POST', isArray: true } }
        ).saveExchangeFees(exchangeFees).$promise;
    }

    function uploadRatesheet(ratesheet) {
        return $resource(
            `${resourceUrl}uploadRatesheet`,
            {},
            {
                save: {
                    method: 'POST',
                    transformRequest(data) {
                        const fd = new FormData();
                        fd.append('effectiveDate', ratesheet.effectiveDate);
                        fd.append('file', ratesheet.file);
                        return fd;
                    },
                    headers: { 'Content-Type': undefined, enctype: 'multipart/form-data' },
                },
            }
        ).save(ratesheet).$promise;
    }

    function getExchangeRatesMetadata() {
        return $resource(`${resourceUrl}getExchangeRatesMetadata`).query().$promise;
    }

    function getExchangeRates(effectiveDate) {
        return $resource(
            `${resourceUrl}getExchangeRates`,
            { effectiveDate },
            { get: { method: 'GET', isArray: true } }
        ).get().$promise;
    }

    function getNonCoreInternationalWireCompany(companyId) {
        return $resource(`${resourceUrl}getnoncoreinternationalwirecompany`, {
            id: companyId,
        }).get().$promise;
    }

    function createNonCoreInternationalWireCompany(productId, wireCompany) {
        return $resource(`${resourceUrl}:id/createnoncoreinternationalwirecompany`, {
            id: productId,
        }).save(wireCompany).$promise;
    }

    function editNonCoreInternationalWireCompany(productId, wireCompany) {
        const resource = $resource(
            `${resourceUrl}:id/editnoncoreinternationalwirecompany`,
            { id: productId },
            { update: { method: 'PUT' } }
        );
        return resource.update(wireCompany).$promise;
    }

    function updateExternalNonCoreFxWire(wire) {
        return $resource(`${resourceUrl}updateexternallyprocessedfxwire`).save(wire).$promise;
    }

    function summarizeFrequency(frequency) {
        if (frequency.type === 'One Time' || frequency.type === 'OneTime') {
            return 'One Time';
        }

        let summary = 'Occurs ';
        switch (frequencyTypeToFriendly(frequency.type)) {
            case 'Weekly':
                summary += ` every ${getDayOfWeek(frequency)} ${getStartAndEnd(frequency)}`;
                break;
            case 'Every Two Weeks':
                summary += ` on ${getDayOfWeek(frequency)} every two weeks ${getStartAndEnd(
                    frequency
                )}`;
                break;
            case 'Twice a Month':
                summary += ` on ${getDaysOfMonth(frequency, true)} every month ${getStartAndEnd(
                    frequency
                )}`;
                break;
            case 'Monthly':
                summary += ` on ${getDaysOfMonth(frequency)} of every month ${getStartAndEnd(
                    frequency
                )}`;
                break;
            case 'Quarterly':
                summary += ` on the ${getDayForADate(
                    frequency.startOn
                )} every three months ${getStartAndEnd(frequency)}`;
                break;
            case 'Every Six Months':
                summary += ` on the ${getDayForADate(
                    frequency.startOn
                )} every six months ${getStartAndEnd(frequency)}`;
                break;
            case 'Yearly':
                summary += ` on ${getDayForAnnual(frequency.startOn)} every year ${getStartAndEnd(
                    frequency
                )}`;
                break;
        }
        summary += '.';
        return summary;
    }

    function frequencyTypeToFriendly(frequencyType) {
        if (frequencyType === null) {
            return 'Undefined';
        }

        let result = frequencyType;

        switch (frequencyType) {
            case 'EveryTwoWeeks':
                result = 'Every Two Weeks';
                break;
            case 'TwiceAMonth':
                result = 'Twice a Month';
                break;
            case 'EverySixMonths':
                result = 'Every Six Months';
                break;
            case 'OneTime':
                result = 'One Time';
                break;
        }

        return result;
    }

    function getDayForAnnual(date) {
        return $filter('date')(new Date(date), 'MM/dd');
    }

    function getDayForADate(date) {
        return formatDay(parseInt($filter('date')(new Date(date), 'd')));
    }

    function getStartAndEnd(frequency) {
        if (frequency.endOn) {
            return `from ${formatDate(frequency.startOn)} to ${formatDate(frequency.endOn)}`;
        }
        return `starting on ${formatDate(frequency.startOn)}`;
    }

    function formatDay(day) {
        if (day === 1 || day === 21 || day === 31) {
            return `${day}st`;
        }
        if (day === 2 || day === 22) {
            return `${day}nd`;
        }
        if (day === 3 || day === 23) {
            return `${day}rd`;
        }
        return `${day}th`;
    }

    function formatDate(date) {
        return $filter('date')(date, 'MM/dd/yyyy');
    }

    function getDayOfWeek(frequency) {
        return frequency.repeatOn;
    }

    function getDaysOfMonth(frequency, twoDays) {
        const day1 = frequency.repeatOnDay1;
        const day2 = frequency.repeatOnDay2;

        if (!twoDays) {
            if (frequency.repeatOnLastBusinessDay) {
                return 'the last business day';
            }
            return `the ${formatDay(day1)}`;
        }
        if (frequency.repeatOnLastBusinessDay) {
            return `the ${formatDay(day1)} and the last business day`;
        }
        return `the ${formatDay(day1)} and ${formatDay(day2)}`;
    }
}
