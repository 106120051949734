angular
    .module('backOffice')
    .controller('UnlockUserChallengeController', unlockUserChallengeController);

unlockUserChallengeController.$inject = ['$scope', '$modalInstance', 'authenticationMethod'];

function unlockUserChallengeController($scope, $modalInstance, authenticationMethod) {
    $scope.options = { recollect: false };
    $scope.form = null;
    $scope.authenticationMethod = authenticationMethod;

    $scope.setForm = function (form) {
        $scope.form = form;
    };

    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    $scope.unlock = function () {
        $modalInstance.close($scope.options.recollect);
    };
}
