angular.module('backOffice').constant(
    'userCredentialStatus',
    Object.freeze({
        NOT_SET: 'NotSet',
        ALLOW: 'Allow',
        EXPIRED: 'Expired',
        INVALID: 'Invalid',
        TEMPORARY: 'Temporary',
    })
);
