angular
    .module('backOffice')
    .controller('ConfigureInternalTransferController', configureInternalTransferController);

configureInternalTransferController.$inject = [
    '$scope',
    '$state',
    'productFeatureId',
    '$modalInstance',
    'configurationService',
    'modalService',
    'toaster',
];

function configureInternalTransferController(
    $scope,
    $state,
    productFeatureId,
    $modalInstance,
    configurationService,
    modalService,
    toaster
) {
    $scope.productFeatureId = productFeatureId;
    $scope.productFeature = {};
    $scope.toggleAccount = toggleAccount;
    $scope.toggleAllowLoanPaymentPastDue = toggleAllowLoanPaymentPastDue;
    $scope.toggleFromAccountType = toggleFromAccountType;
    $scope.save = save;
    $scope.cancel = cancel;
    $scope.setDirty = setDirty;

    $scope.accounts = [
        {
            id: 1,
            name: 'Checking',
            selectAll: false,
        },
        {
            id: 2,
            name: 'Savings',
            selectAll: false,
        },
        {
            id: 3,
            name: 'Club',
            selectAll: false,
        },
        {
            id: 4,
            name: 'Time Deposit',
            selectAll: false,
        },
        {
            id: 5,
            name: 'Loans',
            selectAll: false,
        },
    ];

    function toggleAccount(account) {
        $scope.productFeature.accountPermissions.forEach(permission => {
            permission.fromAccountTypes.forEach(fromAccount => {
                if (fromAccount.fromAccountType === account.name) {
                    fromAccount.isAllowed = account.selectAll;
                }
            });
        });
    }

    function toggleFromAccountType(type) {
        let count = 0;

        $scope.productFeature.accountPermissions.forEach(permission => {
            permission.fromAccountTypes.forEach(fromAccount => {
                if (fromAccount.fromAccountType === type && fromAccount.isAllowed) {
                    count += 1;
                }
            });
        });

        $scope.accounts.forEach(_account => {
            if (_account.name === type) {
                if (count === 5) {
                    _account.selectAll = true;
                } else {
                    _account.selectAll = false;
                }
            }
        });
    }

    function toggleAllowLoanPaymentPastDue() {
        $scope.productFeature.allowLoanPaymentPastDue =
            !$scope.productFeature.allowLoanPaymentPastDue;
        $scope.form.$setDirty();
    }

    function setDirty() {
        $scope.form.$dirty = true;
    }

    // this will dismiss the window.
    function cancel() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel the changes you have made?',
                submit() {
                    cancelModal.dismiss();
                    $modalInstance.dismiss();
                },
            };
            const cancelModal = modalService.showModal({}, modalOptions);
        } else {
            $modalInstance.dismiss();
        }
    }

    // we watch for form.$dirty, this is used to alert the end user that they changed something in the form.
    $scope.$watch('form.$dirty', isDirty => {
        $modalInstance.isDirty = isDirty;
    });

    function save() {
        let count = 0;
        $scope.productFeature.accountPermissions.forEach(permission => {
            permission.fromAccountTypes.forEach(fromAccount => {
                if (fromAccount.isAllowed) {
                    count += 1;
                }
            });
        });

        if (count > 0) {
            configurationService
                .saveProductFeature($scope.productFeatureId, $scope.productFeature)
                .then(() => {
                    toaster.save('Transfer Configuration');
                    $modalInstance.close();
                });
        } else {
            const modalOptions = {
                headerText: 'Warning',
                closeButtonText: 'Cancel',
                actionButtonText: 'Ok',
                bodyText:
                    'Removing all check marks will establish default settings that do not allow for transfers to be created for any account types. This can be changed at the company level, but the default settings will be pulled from this page.',
                submit(result) {
                    configurationService
                        .saveProductFeature($scope.productFeatureId, $scope.productFeature)
                        .then(() => {
                            toaster.save('Transfer Configuration');
                            $modalInstance1.close(result);
                        });
                    $modalInstance.close();
                },
            };
            const $modalInstance1 = modalService.showModal({}, modalOptions);
        }
    }

    function loadProductFeature() {
        configurationService.getProductFeatureDetails($scope.productFeatureId).then(response => {
            $scope.productFeature = response;
            $scope.productFeature.accountPermissions.forEach(permission => {
                permission.fromAccountTypes.forEach(fromAccount => {
                    $scope.accounts.forEach(account => {
                        if (fromAccount.fromAccountType === account.name) {
                            account.selectAll = fromAccount.isAllowed;
                        }
                    });
                });
            });
        });
    }

    function init() {
        loadProductFeature();
    }

    init();
}
