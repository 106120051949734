import EntitlementsService from '@treasury/domain/backoffice/services/entitlements/entitlements-service.js';

angular.module('backOffice').factory('entitlementsService', entitlementsService);

entitlementsService.$inject = ['$resource', '$q', '$rootScope'];

function entitlementsService($resource, $q, $rootScope) {
    let _entitlements = null;

    return {
        reset,
        getEntitlements,
        getEntitlementGroups,
        hasEntitlement,
        hasAnyEntitlement,
        updateEntitlements,
    };

    function reset() {
        _entitlements = null;
        return EntitlementsService.reset();
    }

    async function getEntitlements() {
        _entitlements = await EntitlementsService.getEntitlements();
        $rootScope.$broadcast('entitlementsSet');
        return _entitlements;
    }

    async function updateEntitlements() {
        await EntitlementsService.reset();
        return getEntitlements();
    }

    function getEntitlementGroups() {
        return EntitlementsService.getEntitlementGroups();
    }

    function hasEntitlement(key) {
        return _entitlements?.some(entitlement => key === entitlement.name);
    }

    function hasAnyEntitlement(...args) {
        if (!_entitlements) {
            throw new Error('Entitlements have not been returned yet');
        }
        // usage: function takes either one array argument, or several arguments treated as an array

        const hasArrayArgument = args.length === 1 && Array.isArray(args[0]);
        const qualifyingEntitlements = hasArrayArgument ? args[0] : args;
        if (!qualifyingEntitlements?.length) return false;

        return qualifyingEntitlements.some(qualifyingEntitlement =>
            hasEntitlement(qualifyingEntitlement)
        );
    }
}
