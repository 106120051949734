import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { FdlFieldDefinitions, Record as FdlRecord, Recordset } from '@treasury/FDL';
import {
    AchPaymentModelDto,
    AchRecipientModelDto,
    ChildSupportPaymentModelDto,
} from '@treasury/api/bo';
import { NavigationService } from '@treasury/core/navigation';
import { AchPaymentsService } from '@treasury/domain/backoffice/services/ach/ach-payments.service';
import {
    AchChildSupportPayment,
    AchChildSupportRecipient,
    AchPayment,
    AchRecipient,
    AchTaxPayment,
} from '@treasury/domain/backoffice/types/ach';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-button';
import '@treasury/omega/components/omega-download-bar';
import { fontAwesome } from '@treasury/omega/css/icons/font-awesome.js';
import { AlertMixin } from '@treasury/omega/mixins';
import { LitElement, css, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import '../../components/blocking-loader';
import {
    AchChildSupportRecipientFieldDefinitions,
    AchPaymentFieldDefinitions,
    AchRecipientFieldDefinitions,
} from '../data/ach-payment-field-definitions';
import './ach-payment-templates/ach-child-support-payment-detail';
import './ach-payment-templates/ach-payment-detail';
import './ach-payment-templates/ach-tax-payment-detail';

const mixin = AlertMixin(ListeningElementMixin(LitElement));

interface RouteParams {
    reportClient: object;
}

enum AchPaymentType {
    Regular = 1,
    ChildSupport = 4,
}

@customElement('ach-payment-detail-container')
export class AchPaymentDetailContainer extends mixin {
    @InjectProperty()
    private declare readonly navService: NavigationService;

    @InjectProperty()
    private declare readonly achPaymentsService: AchPaymentsService;

    @state()
    public paymentTypeId!: number;

    @state()
    public paymentId!: string;

    @state()
    public achPayment!: AchPayment<AchPaymentModelDto> | AchChildSupportPayment;

    @state()
    private achPaymentRecord!: FdlRecord<AchPayment<AchPaymentModelDto> | AchChildSupportPayment>;

    @state()
    private achRecipientRecordset!:
        | Recordset<AchRecipient<AchRecipientModelDto>>
        | Recordset<AchChildSupportRecipient>;

    @state()
    private isLoading = true;

    reportClient: any;

    async firstUpdated() {
        try {
            const { params } = await this.navService.getRouteData<RouteParams>();
            this.reportClient = params.reportClient;

            const urlPathParams = window.location.pathname.split('/');
            this.paymentId = urlPathParams.pop() as string;
            this.paymentTypeId = Number(urlPathParams.pop());

            if (!this.paymentId || !this.paymentTypeId) {
                throw new Error('No ACH Payment ID or Type found');
            }

            switch (this.paymentTypeId) {
                case AchPaymentType.Regular:
                    this.achPayment = (await this.achPaymentsService.getRegularPaymentById(
                        this.paymentId
                    )) as AchPayment<AchPaymentModelDto>;
                    this.achPaymentRecord = new FdlRecord(
                        AchPaymentFieldDefinitions as FdlFieldDefinitions<
                            AchPayment<AchPaymentModelDto>
                        >,
                        this.achPayment
                    );
                    this.achRecipientRecordset = new Recordset(AchRecipientFieldDefinitions, () =>
                        this.achPayment.recipients ? this.achPayment.recipients : []
                    );
                    this.achRecipientRecordset.requestUpdate();
                    break;
                case AchPaymentType.ChildSupport:
                    this.achPayment = (await this.achPaymentsService.getChildSupportPaymentById(
                        this.paymentId
                    )) as AchChildSupportPayment;
                    this.achPaymentRecord = new FdlRecord(
                        AchPaymentFieldDefinitions,
                        this.achPayment
                    );
                    this.achRecipientRecordset = new Recordset(
                        AchChildSupportRecipientFieldDefinitions,
                        () =>
                            (this.achPayment as AchChildSupportPayment).cspRecipients
                                ? (this.achPayment as AchChildSupportPayment).cspRecipients
                                : []
                    );
                    this.achRecipientRecordset.requestUpdate();
                    break;
            }
        } catch (e) {
            console.log(e);
        } finally {
            this.isLoading = false;
        }
    }

    async downloadAchPaymentDetail(e: any) {
        await this.achPaymentsService.downloadAchPaymentDetails(
            this.paymentId,
            e.detail.downloadType.toLowerCase()
        );
    }

    renderPaymentDetail() {
        if (!this.paymentTypeId) return nothing;
        switch (this.paymentTypeId) {
            case AchPaymentType.Regular:
                return html`<ach-payment-detail
                    .paymentRecord=${this.achPaymentRecord}
                    .recipientRecordset=${this.achRecipientRecordset}
                ></ach-payment-detail>`;
            case AchPaymentType.ChildSupport:
                return html`<ach-child-support-payment-detail
                    .paymentRecord=${this.achPaymentRecord}
                    .recipientRecordset=${this.achRecipientRecordset}
                ></ach-child-support-payment-detail>`;
        }
    }

    renderPayment() {
        if (!this.achPayment) return nothing;
        return html`<div class="details-container">
            <omega-button
                id="go-to-user-list"
                type="icon"
                class="fa fa-chevron-left"
                @click="${() => {
                    window.history.back();
                }}"
                >Back to ACH Payment Report</omega-button
            >
            <div class="jh-panel">
                <div class="jh-header background-white">
                    <omega-download-bar
                        pageTitle="Payment Detail - ${this.achPayment.transactionId}"
                        .actions=${['download']}
                        .hideActionLabels=${true}
                        .downloadOptions=${['CSV', 'NACHA', 'PDF']}
                        @download=${(e: any) => this.downloadAchPaymentDetail(e)}
                    ></omega-download-bar>
                </div>
                <div class="form-container ach-payment-detail-card">
                    ${this.renderAlert()} ${this.renderPaymentDetail()}
                </div>
            </div>
        </div>`;
    }

    renderBlockingLoader() {
        if (this.isLoading) return html`<blocking-loader></blocking-loader>`;
        return nothing;
    }

    render() {
        return [this.renderBlockingLoader(), this.renderPayment()];
    }

    static get styles() {
        return [
            fontAwesome,
            css`
                .jh-header {
                    color: #505050;
                    border-bottom: 1px solid #dddddd;
                    height: 50px;
                    margin-left: 15px;
                    margin-right: 15px;
                    position: relative;
                }
                .jh-panel {
                    clear: both;
                    background-color: #ffffff;
                    border-radius: 2px;
                    box-shadow: 0 2px 4px 0 rgba(209, 209, 209, 0.5);
                }
                .details-container {
                    margin-left: 25px;
                    margin-right: 15px;
                }
                omega-download-bar {
                    --omega-download-bar-report-title-padding: 4px;
                }
            `,
        ];
    }
}
