import { LitElement, html, nothing } from 'lit';
import '@treasury/omega/components/progress/omega-progress';
import { string, list, money, amountRange, date, dateRange } from '@treasury/policy/primitives';
import { transactionType } from '@treasury/policy/ach';
import { secCode } from '@treasury/policy/company.js';
import { AchFilterExceptionsReportServices } from '@treasury/domain/backoffice/requests/reports/ach-filter-exceptions';
import { ReportRequests } from '@treasury/domain/backoffice/requests/reports/report-requests';
import { alphabetizeOnText } from '@treasury/domain/shared/utilities/compare-functions';
import { Recordset } from '@treasury/FDL';
import './ach-filter-detail-row.js';
import '@treasury/omega/components/omega-badge.js';
import accountingNotation from '@treasury/policy/lib/formatters/accountingNotation.formatter';
import usd from '@treasury/policy/lib/formatters/usd.formatter';
import '@treasury/omega/layouts/omega-report';

class AchFilterExceptionReport extends LitElement {
    static get properties() {
        return {
            subTitle: String,
            recordset: Object,
        };
    }

    constructor() {
        super();
        this.disableFunction = record => !record?.getField('companyId');
        this.pageTitle = 'Positive Pay - ACH Exceptions - Filter Rules';
        this.rowsPerPage = 25;
        this.fields = {
            createdDate: date.thatIs.readOnly(),
            expirationDate: date.thatIs.readOnly(),
            AchFilterUniqueId: string.thatIs.readOnly(),
            accountNumber: string.thatIs.readOnly(),
            accountFilterSettings: string.thatIs.readOnly(),
            accountCredits: string.thatIs.readOnly(),
            accountDebits: string.thatIs.readOnly(),
            routingNumber: string.thatIs.readOnly(),
            achCompanyName: string.thatIs.readOnly(),
            achCompanyId: string.thatIs.readOnly(),
            companyId: string.thatIs.readOnly(),
            standardEntryClassCode: secCode.thatIs.readOnly(),
            filterRuleName: string.thatIs.readOnly(),
            fromAmount: money.thatIs.readOnly(),
            // FIXME: This will need to be refactored to put this field type definition in a common location in the policy project since Channel also uses this exact same toAmount definition for the ACH Filters container (see the ach-filter-fields.js file).
            toAmount: money.thatIs
                .readOnly()
                .with.label('Amount')
                .and.textAlign('left')
                .and.formatter((value, record) => {
                    let fromAmountValue = record.getField('fromAmount');
                    const toAmountValue = record.getField('toAmount');
                    // Ugly hack to get around the API returning NULL for the FromAmount when we have a valid range of 0 - something. Once Services updates the API (AB#314023)
                    if (fromAmountValue == null && toAmountValue) {
                        fromAmountValue = 0;
                    }
                    if (
                        fromAmountValue != null &&
                        toAmountValue != null &&
                        fromAmountValue !== toAmountValue
                    ) {
                        return `
                        ${accountingNotation(usd(fromAmountValue))} - ${accountingNotation(
                            usd(toAmountValue)
                        )}
                    `;
                    }
                    if (fromAmountValue) {
                        return `${accountingNotation(usd(fromAmountValue))}`;
                    }
                    return '';
                }),

            transactionType: transactionType.thatIs.readOnly().with.template((value, record) => {
                const allowTypeValue = record.getField('allowType');
                const transactionTypeValue = record.getField('transactionType');
                const allowTypeLabel = allowTypeValue === 'Allow' ? 'Allowed' : 'Not Allowed';
                return `${transactionTypeValue} ${allowTypeLabel}`;
            }),
            status: string.thatIs.readOnly().and.template(status => {
                if (status === 'Active')
                    return html` <omega-badge type="success">active</omega-badge> `;
                if (status === 'Failed')
                    return html` <omega-badge type="error">failed</omega-badge> `;
                if (status === 'Deleted')
                    return html` <omega-badge type="error">deleted</omega-badge> `;
                if (status === 'Inactive')
                    return html` <omega-badge type="inactive">inactive</omega-badge> `;
                if (status === 'Pending')
                    return html`<omega-badge type="warning" clickable
                        >pending<br />approval</omega-badge
                    > `;
                return nothing;
            }),
            detailStatus: list.thatIs.readOnly(),
        };

        this.filters = [
            {
                field: 'companyId',
                fieldType: string.with
                    .options({
                        data: ReportRequests.getCompanies(),
                        text: record => record.name,
                        value: record => record.id,
                        compareFunction: alphabetizeOnText,
                    })
                    .with.filtering()
                    .thatHas.label('Company Name')
                    .thatIs.required()
                    .as.tag('omega-select'),
                value: '',
            },
            {
                field: 'userIds',
                fieldType: string.with
                    .options({
                        fetch: async record => {
                            const id = record.getField('companyId');
                            if (id) {
                                this.subTitle = '';
                                this.pageTitle = 'Positive Pay - ACH Exceptions - Filter Rules';
                                const companyName = await ReportRequests.getCompanyInfo(id);
                                this.pageTitle = companyName.name;
                                this.subTitle = 'Positive Pay - ACH Exceptions - Filter Rules';
                            }
                            return !id
                                ? Promise.resolve([])
                                : AchFilterExceptionsReportServices.fetchCompanyUsers(id);
                        },
                        text: record => `${record.name} - ${record.alias}`,
                        value: record => record.userUniqueId,
                        compareFunction: alphabetizeOnText,
                    })
                    .with.filtering()
                    .and.multipleValues()
                    .thatIs.disabledWhen(this.disableFunction)
                    .thatHas.label('User')
                    .as.tag('omega-select'),
                value: [],
            },
            {
                field: 'standardEntryClassCode',
                fieldType: string.with
                    .options({
                        data: [
                            'ARC',
                            'BOC',
                            'CCD',
                            'CIE',
                            'CTX',
                            'IAT',
                            'POP',
                            'POS',
                            'PPD',
                            'RCK',
                            'TEL',
                            'WEB',
                            'XCK',
                        ].map(s => ({ text: s, value: s })),
                    })
                    .with.defaultValue([])
                    .and.multipleValues()
                    .thatIs.disabledWhen(this.disableFunction)
                    .thatHas.label('SEC Code')
                    .as.tag('omega-select'),
            },
            {
                field: 'fromAmount',
                fieldType: amountRange.thatIs
                    .segmented()
                    .and.disabledWhen(this.disableFunction)
                    .and.multipleValues()
                    .thatHas.label('Amount')
                    .as.tag('omega-range'),
                value: ['specific', 0],
            },
            {
                field: 'transactionType',
                fieldType: string.with
                    .options({
                        data: ['Credit', 'Debit'].map(t => ({ text: t, value: t })),
                    })
                    .and.multipleValues()
                    .thatIs.disabledWhen(this.disableFunction)
                    .thatHas.label('Transaction Type')
                    .as.tag('omega-select'),
                value: [],
            },
            {
                field: 'accountNumber',
                fieldType: string.with
                    .options({
                        fetch: record => {
                            const id = record.getField('companyId');
                            return !id
                                ? Promise.resolve([])
                                : AchFilterExceptionsReportServices.fetchCompanyAccounts(id);
                        },
                        text: record => `${record.accountNumber}`,
                        value: record => record.accountUniqueId,
                    })
                    .and.filtering()
                    .and.multipleValues()
                    .thatIs.disabledWhen(this.disableFunction)
                    .thatHas.label('Accounts')
                    .as.tag('omega-select'),
                value: [],
            },
            {
                field: 'status',
                fieldType: string.with
                    .options({
                        // The rejected status will be added for Day 2 when the approvers logic is added
                        // data: ['Active', 'Inactive', 'Deleted', 'Rejected'].map(s => ({
                        data: ['Active', 'Inactive', 'Deleted'].map(s => ({
                            text: s,
                            value: s,
                        })),
                    })
                    .and.multipleValues()
                    .thatIs.disabledWhen(this.disableFunction)
                    .thatHas.label('Status')
                    .as.tag('omega-select'),
                value: [],
            },
            {
                field: 'filterRuleName',
                fieldType: string.thatIs
                    .disabledWhen(this.disableFunction)
                    .thatHas.label('Filter Rule Name')
                    .as.tag('omega-input'),
            },
            {
                field: 'createdDate',
                fieldType: dateRange.thatHas.label('Created Date').as.tag('omega-datepicker'),
                value: '$TODAY',
            },
        ];

        this.columns = [
            {
                field: 'createdDate',
                label: 'Created Date',
            },
            {
                field: 'filterRuleName',
                label: 'Filter Rule Name',
            },
            { field: 'accountNumber', label: 'Account' },
            { field: 'achCompanyName', label: 'ACH Company Name' },
            {
                field: 'achCompanyId',
                label: 'Company ID',
            },
            {
                field: 'standardEntryClassCode',
                label: 'SEC',
            },
            {
                field: 'toAmount',
                label: 'Amount',
            },
            {
                field: 'transactionType',
                label: 'Transaction Type',
            },
            {
                field: 'status',
                label: 'Status',
            },
        ];
        this.recordset = new Recordset(
            this.fields,
            AchFilterExceptionsReportServices.fetchAchFilterExceptions
        );
    }

    handlePrint() {
        window.print();
    }

    async handleDownload(e) {
        try {
            await AchFilterExceptionsReportServices.saveReport(e.detail.type);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    async clearSubtitle() {
        this.subTitle = '';
        this.pageTitle = 'Positive Pay - ACH Exceptions - Filter Rules';
    }

    renderLoader() {
        if (this.loading) {
            return html`<omega-progress card class="large-loader"></omega-progress>`;
        }
        return nothing;
    }

    renderDetail(record, close) {
        return html`<ach-filter-detail-row
            .record=${record}
            .closeFunction=${close}
        ></ach-filter-detail-row>`;
    }

    renderOmegaReport() {
        if (this.loading || !this.recordset) return nothing;
        return html`<omega-report
            .title=${this.pageTitle}
            .subTitle=${this.subTitle}
            .actions=${this.actions}
            .recordset=${this.recordset}
            .filters=${this.filters}
            .detailFunction=${(record, close) => this.renderDetail(record, close)}
            .columns=${this.columns}
            .options=${['print', 'download']}
            .downloadOptions=${['CSV', 'PDF']}
            .rowsPerPage=${this.rowsPerPage}
            @reportDownload=${this.handleDownload}
            @resetFilter=${this.clearSubtitle}
        >
        </omega-report>`;
    }

    render() {
        return html` ${this.renderLoader()} ${this.renderOmegaReport()} `;
    }
}

customElements.define('ach-filter-exception-report', AchFilterExceptionReport);
export default AchFilterExceptionReport;
