angular.module('backOffice').controller('ReportsDashboardController', ReportsDashboardController);

ReportsDashboardController.$inject = [
    '$filter',
    '$scope',
    '$state',
    'reportsService',
    'toaster',
    'modalService',
    'entitlementsService',
];

function ReportsDashboardController(
    $filter,
    $scope,
    $state,
    reportsService,
    toaster,
    modalService,
    entitlementsService
) {
    $scope.reports = [];
    $scope.currentUserSavedReports = [];
    $scope.standardReportsList = [];
    $scope.isFormLoaded = false;
    $scope.filterObj = {
        name: '',
        isFavorite: undefined,
        isStandardReport: undefined,
    };

    $scope.deleteCustomReport = deleteCustomReport;
    // Exposing the drag/drop function for Kendo for testing purposes
    $scope.draggedDropped = draggedDropped;
    $scope.hint = hint;
    $scope.isString = isString;
    $scope.isTabSelected = isTabSelected;
    $scope.placeholder = placeholder;
    $scope.runReport = runReport;
    $scope.selectTab = selectTab;
    $scope.toggleFavorite = toggleFavorite;
    $scope.numberOfReports = numberOfReports;
    $scope.routeToBetaReports = routeToBetaReports;

    $scope.$watch(
        () =>
            angular.isArray($scope.reports) &&
            $scope.reports.length > 0 &&
            angular.isString($scope.selectedTabName),
        (newValue, oldValue) => {
            if (newValue) {
                sequence();
            }
        }
    );

    $scope.$watch('selectedTabName', (newValue, oldValue) => {
        if (angular.isString(newValue) && angular.isString(oldValue) && newValue !== oldValue) {
            sequence();
        }
    });

    $scope.sortableOptions = {
        disabled: '.disabled',
        end: draggedDropped,
    };

    $scope.all = 'all';
    $scope.custom = 'custom';
    $scope.customReport = 'Custom Report';
    $scope.droptarget = 'drop-target';
    $scope.favorite = 'favorite';
    $scope.favorites = 'Favorites';
    $scope.standard = 'standard';
    $scope.reportview = 'reports.view';
    $scope.reportviews = 'reports.views';

    function isString(obj) {
        return angular.isString(obj) && obj.length > 0;
    }

    function draggedDropped(e) {
        // Do not update sequence if report name is filtered
        if ($scope.filterObj.name) {
            return;
        }
        const updatedSequences = [];
        const filteredReports = $filter('filter')($scope.reports, $scope.filterObj);
        if (filteredReports.length === 1) {
            return;
        }
        const orderedReports = $filter('orderByReport')(filteredReports, $scope.selectedTabName);

        angular.forEach(orderedReports, report => {
            const order = getOrder(report, $scope.selectedTabName);
            if (order < e.oldIndex && order >= e.newIndex) {
                setOrder(report, order + 1);
                updatedSequences.push({
                    reportId: report.id,
                    selector: $scope.selectedTabName,
                    order: order + 1,
                });
            } else if (order > e.oldIndex && order <= e.newIndex) {
                setOrder(report, order - 1);
                updatedSequences.push({
                    reportId: report.id,
                    selector: $scope.selectedTabName,
                    order: order - 1,
                });
            } else if (order === e.oldIndex) {
                setOrder(report, e.newIndex);
                updatedSequences.push({
                    reportId: report.id,
                    selector: $scope.selectedTabName,
                    order: e.newIndex,
                });
            }
        });

        if (updatedSequences.length > 1) {
            reportsService.updateSequence(updatedSequences).then(() => {
                toaster.save('Reports sequence has been');
            });
        }
    }

    function placeholder(element) {
        return element.clone().addClass($scope.droptarget).text('');
    }

    function hint(element) {
        return element.clone();
    }

    function routeToBetaReports() {
        $state.go('reports.beta-dashboard');
    }

    function runReport(report) {
        /**
         * Reports not using the standard 'report view' must be routed to individually
         */
        switch (report.type) {
            case 'achFilterException':
                $state.go('reports.ach-filter-exception', {
                    report,
                });
                break;
            case 'boFiUserActivity':
                $state.go('reports.fi-user-activity', {
                    report,
                    type: report.type,
                    id: report.id,
                });
                break;
            case 'boChannelUserActivity':
                $state.go('reports.customer-user-activity', {
                    type: report.type,
                    id: report.id,
                    report,
                });
                break;
            case 'wireUpload':
                $state.go('reports.wire-upload', {
                    report,
                });
                break;
            case 'arpCheckException':
                if (entitlementsService.hasEntitlement(`Feature.PositivePay.ReturnReason`)) {
                    $state.go($scope.reportview, {
                        type: report.type,
                        id: report.id,
                        report,
                    });
                    break;
                }
                // NOTE: this is only here as a fallback until the new
                // check exception report is lifted from it's FF
                $state.go($scope.reportviews, {
                    type: report.type,
                    id: report.id,
                    report,
                });
                break;
            default:
                $state.go($scope.reportview, {
                    type: report.type,
                    id: report.id,
                    report,
                });
                break;
        }
    }

    function deleteCustomReport(report) {
        const modalOptions = {
            headerText: 'Confirm Deletion',
            bodyText: 'Are you sure you want to delete this report?',
            actionButtonText: 'Delete Report',
            actionButtonClass: 'btn-danger',
            closeButtonText: 'Cancel',
            submit(result) {
                reportsService.deleteCustomReport(report.id).then(response => {
                    $scope.reports.splice($scope.reports.indexOf(report), 1);
                    toaster.delete($scope.customReport);
                    const selectors = report.sequences.map(sequence => sequence.selector);
                    angular.forEach(selectors, selector => {
                        resequenceOrder(report, selector);
                    });
                });
                $modalInstance.close(result);
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    function toggleFavorite(report) {
        report.isFavoriteReport = !report.isFavoriteReport;
        reportsService.favorite(report.id, report.isFavoriteReport).then(response => {
            toaster.save($scope.favorites);
            if (!report.isFavoriteReport) {
                resequenceOrder(report, 'favorite');
            }
        });
    }

    function resequenceOrder(report, selector) {
        const order = getOrder(report, selector);
        if ($.isNumeric(order)) {
            angular.forEach($scope.reports, reorder => {
                const sequence = reorder.sequences.filter(
                    sequence => sequence.selector === selector
                );
                if (!!sequence && sequence.length === 1) {
                    if (reorder.id === report.id) {
                        reorder.sequences.splice(reorder.sequences.indexOf(sequence[0]), 1);
                    } else if (sequence[0].order > order) {
                        sequence[0].order--;
                    }
                }
            });
        }
    }

    function selectTab(tabName) {
        $scope.selectedTabName = tabName;

        switch (tabName) {
            case $scope.all:
                $scope.filterObj.isFavoriteReport = undefined;
                $scope.filterObj.isCustomReport = undefined;
                break;
            case $scope.favorite:
                $scope.filterObj.isFavoriteReport = true;
                $scope.filterObj.isCustomReport = undefined;
                break;
            case $scope.custom:
                $scope.filterObj.isFavoriteReport = undefined;
                $scope.filterObj.isCustomReport = true;
                break;
            case $scope.standard:
                $scope.filterObj.isFavoriteReport = undefined;
                $scope.filterObj.isCustomReport = false;
                break;
        }
    }

    function isTabSelected(tabName) {
        return tabName === $scope.selectedTabName;
    }

    function numberOfReports(type) {
        if ($scope.reports) {
            switch (type) {
                case $scope.custom:
                    return $scope.reports.filter(report => report.isCustomReport).length;
                case $scope.standard:
                    return $scope.reports.filter(report => !report.isCustomReport).length;
                case $scope.favorite:
                    return $scope.reports.filter(report => report.isFavoriteReport).length;
                default:
                    return $scope.reports.length;
            }
        }
    }

    // private
    function loadReports() {
        reportsService.getReports().then(response => {
            $scope.reports = response;
        });
    }

    // Prime reports that have not been sequenced (ie. not drag-dropped on the UI and saved to DB)
    function sequence() {
        const filteredReports = $filter('filter')($scope.reports, $scope.filterObj);
        if (!angular.isArray(filteredReports) || filteredReports.length <= 0) {
            return;
        }
        const orderedReports = $filter('orderByReport')(filteredReports, $scope.selectedTabName);

        const availableSequenceNumber = [];
        angular.forEach(orderedReports, report => {
            const order = getOrder(report, $scope.selectedTabName, true);
            if (!angular.isUndefined(order)) {
                availableSequenceNumber.push(order);
            }
        });

        const maxNumber = orderedReports.length - 1;
        let minNumber = -1;
        angular.forEach(orderedReports, report => {
            if (!angular.isArray(report.sequences)) {
                minNumber = nextAvailableNumber(availableSequenceNumber, minNumber, maxNumber);
                report.sequences = [];
                report.sequences.push({
                    reportId: report.id,
                    selector: $scope.selectedTabName,
                    order: minNumber,
                });
            } else if (!$.isNumeric(getOrder(report, $scope.selectedTabName))) {
                minNumber = nextAvailableNumber(availableSequenceNumber, minNumber, maxNumber);
                report.sequences.push({
                    reportId: report.id,
                    selector: $scope.selectedTabName,
                    order: minNumber,
                });
            }
        });
    }

    function nextAvailableNumber(arr, min, max) {
        while (min < max) {
            if (arr.indexOf(++min) == -1) return min;
        }
        return min;
    }

    function getOrder(report, selector, mustExist) {
        if (!angular.isArray(report.sequences) || report.sequences.length <= 0) {
            return undefined;
        }
        const sequence = report.sequences.filter(sequence => sequence.selector === selector);
        if (mustExist) {
            return !!sequence && sequence.length === 1 && !!sequence[0].id
                ? sequence[0].order
                : undefined;
        }
        return !!sequence && sequence.length === 1 ? sequence[0].order : undefined;
    }

    function setOrder(report, order) {
        const sequence = report.sequences.filter(
            sequence => sequence.selector === $scope.selectedTabName
        );
        sequence[0].order = order;
    }

    // init
    (function () {
        loadReports();
        $scope.selectTab($scope.all);
    })();
}
