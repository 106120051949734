angular.module('backOffice').filter('regex', [
    '$filter',
    'resolvePropertyService',
    function ($filter, resolvePropertyService) {
        return function (array, exp, property) {
            let regexp;
            try {
                regexp = new RegExp(exp, 'gi');
            } catch (e) {
                regexp = undefined;
            }

            function comparator(item) {
                if (exp) {
                    let propertyValue = resolvePropertyService.resolveProperty(item, property);

                    if (propertyValue == null) {
                        return false;
                    }

                    propertyValue = propertyValue.toLowerCase();

                    if (regexp) {
                        return propertyValue.match(regexp) != null;
                    }
                    return propertyValue.indexOf(exp.toLowerCase()) >= 0;
                }
                return true;
            }

            if (!angular.isArray(array)) {
                return array;
            }
            if (!angular.isString(exp)) {
                return array;
            }

            return $filter('filter')(array, comparator);
        };
    },
]);
