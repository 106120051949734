import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('accountService', accountService);

accountService.$inject = ['$resource', '$http', '$q', '$state', 'entitlementsService'];

function accountService($resource, $http, $q, $state, entitlementsService) {
    const resourceUrl = `${getBackOfficeAPIPath()}account/`;
    let settings = null;

    return {
        validateUser,
        checkForError,
        saveSecurityQuestions,
        verifySecurityQuestions,
        getSettings,
        updatePassword,
        getPasswordRequirements,
        VerifyUserForPasswordReset,
        getOnDemandMfaChallengeSecurityQuestions,
        recentLogins,
        completeLogin,
    };

    function getSettings() {
        const deferred = $q.defer();
        $resource(`${resourceUrl}settings`)
            .get()
            .$promise.then(response => {
                settings = response;
                deferred.resolve(settings);
            });
        return deferred.promise;
    }

    function recentLogins() {
        return $resource(`${resourceUrl}recentLogins`).get().$promise;
    }

    /* Use this for all PW Resets of existing users, other methods are deprecated */
    function VerifyUserForPasswordReset(newUserPayload) {
        const deferred = $q.defer();
        $http.put(`${resourceUrl}VerifyUserForPasswordReset`, newUserPayload).then(
            response => {
                deferred.resolve(response.data);
            },
            err => {
                deferred.reject(err);
            }
        );
        return deferred.promise;
    }

    function validateUser(newUserPayload, verifyUser) {
        const deferred = $q.defer();
        if (verifyUser) {
            $http.put(`${resourceUrl}verifyuser`, newUserPayload).then(
                response => {
                    deferred.resolve(response.data);
                },
                err => {
                    deferred.reject(err);
                }
            );
        } else {
            $http.post(`${resourceUrl}login`, newUserPayload).then(
                response => {
                    deferred.resolve(response.data);
                },
                err => {
                    deferred.reject(err);
                }
            );
        }
        return deferred.promise;
    }

    function checkForError(validateUserResponse) {
        if (validateUserResponse.statusCode === 'Block') {
            return validateUserResponse.message;
        }
        if (validateUserResponse.statusCode === 'Invalid') {
            return validateUserResponse.message;
        }
        if (validateUserResponse.statusCode === 'Locked') {
            $state.go('locked-out');
            return true;
        }
        return '';
    }

    function saveSecurityQuestions(securityQuestionsPayload) {
        const deferred = $q.defer();
        $http.post(`${resourceUrl}securityquestions`, securityQuestionsPayload).then(
            response => {
                deferred.resolve(response.data);
            },
            err => {
                deferred.reject(err);
            }
        );
        return deferred.promise;
    }

    function verifySecurityQuestions(userQuestions) {
        const deferred = $q.defer();
        $http.put(`${resourceUrl}verifysecurityquestions`, userQuestions).then(
            response => {
                deferred.resolve(response.data);
            },
            err => {
                deferred.reject(err);
            }
        );
        return deferred.promise;
    }

    function updatePassword(newUserPayload) {
        const deferred = $q.defer();
        $http.put(`${resourceUrl}updatepassword`, newUserPayload).then(
            response => {
                deferred.resolve(response.data);
            },
            err => {
                deferred.reject(err);
            }
        );
        return deferred.promise;
    }

    function getPasswordRequirements(fiId) {
        return $resource(`${resourceUrl}passwordrequirements?fiId=:fiId`, { fiId }).query()
            .$promise;
    }

    function getOnDemandMfaChallengeSecurityQuestions(newUserPayload) {
        const deferred = $q.defer();
        $http.put(`${resourceUrl}getOnDemandMfaChallengeSecurityQuestions`, newUserPayload).then(
            response => {
                deferred.resolve(response.data);
            },
            err => {
                deferred.reject(err);
            }
        );
        return deferred.promise;
    }

    function completeLogin() {
        entitlementsService
            .reset()
            .then(() => entitlementsService.getEntitlements())
            .then(() => {
                if (entitlementsService.hasEntitlement('Switch FI')) {
                    $state.go('institution-search');
                } else {
                    $state.go('company-search');
                }
            });
    }
}
