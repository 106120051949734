import { Injectable } from '@jack-henry/frontend-utils/di';
import { getFiBranding, updateFiBranding } from './branding.requests';
import { FiBranding } from './fi-branding.types';

@Injectable()
export class BrandingService {
    public async getFiBranding() {
        return getFiBranding();
    }

    public async updateFiBranding(branding: FiBranding) {
        return updateFiBranding(branding);
    }
}
