import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';

angular
    .module('backOffice')
    .controller('AddAssociatedCompanyController', addAssociatedCompanyController);

addAssociatedCompanyController.$inject = [
    '$q',
    '$scope',
    '$modalInstance',
    'companyId',
    'companiesService',
    'toaster',
    'TmDi',
];

/**
 * @param { ng.IQService } $q
 * @param { ng.IScope } $scope
 * @param { import('@jack-henry/frontend-utils/di').DiContainer } TmDi
 */
function addAssociatedCompanyController(
    $q,
    $scope,
    $modalInstance,
    companyId,
    companiesService,
    toaster,
    TmDi
) {
    $scope.companyId = companyId;
    $scope.searchCriteriaList = [
        { name: 'Company Name' },
        { name: 'TAX ID Number' },
        { name: 'CIF Number' },
    ];
    $scope.searchPerformed = false;
    $scope.isConfirmationMode = false;
    $scope.selectedCompany = null;
    $scope.showAddAnotherCompanyButton = false;

    // this will dismiss the window.
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    $scope.save = function () {
        companiesService
            .saveAssociatedCompany($scope.companyId, $scope.selectedCompany.cifNumber)
            .then(response => {
                toaster.save('Company Associated');
                $modalInstance.close(response);
            });
    };

    $scope.saveAndAddAnotherCompany = function () {
        companiesService
            .saveAssociatedCompany($scope.companyId, $scope.selectedCompany.cifNumber)
            .then(() => {
                toaster.save('Company Associated');
                $scope.isConfirmationMode = false;
                $scope.selectedCompany = null;
                $scope.loadNotOnboardedCompanies();
            });
    };

    $scope.selectCompany = function (company) {
        $scope.isConfirmationMode = true;
        $scope.selectedCompany = company;
    };

    $scope.determineMaxLength = function (type) {
        if (type === 'CIF Number') {
            $scope.maxLength = 25;
        } else if (type === 'Company Name') {
            $scope.maxLength = 250;
        } else {
            $scope.maxLength = 10;
        }
    };

    // used to store the search criteria
    function initializeSearchCriteria() {
        $scope.searchCriteria = {
            searchText: null,
            searchType: null,
        };

        // business logic, select Company Name by default
        $scope.searchCriteria.searchType = $scope.searchCriteriaList[0].name;
    }

    $scope.clearSearchCriteria = function () {
        initializeSearchCriteria();
    };

    // used to set the form instance.
    $scope.setForm = function (form) {
        $scope.form = form;
    };

    // we watch for form.$dirty, this is used to alert the end user that they changed something in the form.
    $scope.$watch('form.$dirty', isDirty => {
        $modalInstance.isDirty = isDirty;
    });

    $scope.loadNotOnboardedCompanies = function () {
        companiesService
            .getNotOnboardedCompanies(
                $scope.searchCriteria.searchType,
                $scope.searchCriteria.searchText
            )
            .then(response => {
                $scope.searchPerformed = true;
                $scope.companySearchList = response;
            });
    };

    // used to validate search text
    $scope.searchTextPattern = (function () {
        let regexp = /^(\d{2})-\d{7}$/;
        return {
            test(value) {
                if (
                    $scope.searchCriteria === null ||
                    $scope.searchCriteria.searchType !== 'TAX ID Number'
                ) {
                    return true;
                }

                // Allowed Tax ID format - 12-3456789
                let result = regexp.test(value);

                // If it fails, try Tax ID format - 12-3456789 (with a keypad minus)
                if (result === false) {
                    regexp = /^(\d{2})-\d{7}$/;
                    result = regexp.test(value);
                }

                // If it fails, try Allowed Tax ID format - 123456789
                if (result === false) {
                    regexp = /^[0-9]{9}$/;
                    result = regexp.test(value);
                }

                return result;
            },
        };
    })();

    function init() {
        initializeSearchCriteria();

        const initPromise = TmDi.getAsync(FeatureFlagService)
            .then(ffService => ffService.isEnabled(Feature.AddAnotherCompanyButton))
            .then(showAddAnotherCompanyButton => {
                $scope.showAddAnotherCompanyButton = showAddAnotherCompanyButton;
            });

        return $q.resolve(initPromise);
    }

    init();
}
