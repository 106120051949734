angular.module('backOffice').controller('ManageTransfersController', manageTransfersController);

manageTransfersController.$inject = [
    '$scope',
    '$modalInstance',
    'accountsService',
    'account',
    '$filter',
];

function manageTransfersController($scope, $modalInstance, accountsService, account, $filter) {
    $scope.toAccountPermissions = [];
    $scope.filteredToAccountPermissions = [];

    $scope.account = account;
    $scope.allChecked = false;
    $scope.formIsDirty = false;
    $scope.searchText = null;
    $scope.setAllCheckboxes = setAllCheckboxes;
    $scope.setCheckbox = setCheckbox;
    $scope.setForm = setForm;
    $scope.filterToAccountPermissions = filterToAccountPermissions;

    $scope.save = save;
    $scope.cancel = cancel;

    init();

    function init() {
        accountsService.getToAccountPermissions(account.id).then(response => {
            $scope.toAccountPermissions = response;
            updateSelectAllCheckboxStatus();
            filterToAccountPermissions();
        });
    }

    function setForm(form) {
        $scope.form = form;
    }

    function save() {
        accountsService
            .updateToAccountPermissions(account.id, $scope.toAccountPermissions)
            .then(response => {
                $modalInstance.close();
            });
    }

    function cancel() {
        $modalInstance.dismiss();
    }

    function setAllCheckboxes(value) {
        window.angular.forEach($scope.toAccountPermissions, toAccountPermission => {
            toAccountPermission.isAllowed = value;
        });
        $scope.formIsDirty = true;
        $modalInstance.isDirty = true;
    }

    function setCheckbox() {
        updateSelectAllCheckboxStatus();
        $scope.formIsDirty = true;
        $modalInstance.isDirty = true;
    }

    function filterToAccountPermissions() {
        $scope.filteredToAccountPermissions = $filter('filter')(
            $scope.toAccountPermissions,
            $scope.searchText
        );
    }

    //
    // Private

    function updateSelectAllCheckboxStatus() {
        $scope.allChecked = true;
        window.angular.forEach($scope.toAccountPermissions, toAccountPermission => {
            if (toAccountPermission.isAllowed === false) {
                $scope.allChecked = false;
            }
        });
    }
}
