angular.module('backOffice').directive('popOver', ($compile, $templateCache) => {
    const getTemplate = function () {
        $templateCache.put('templateId.html', 'This is the content of the template');
        console.log($templateCache.get('popover_template.html'));
        return $templateCache.get('popover_template.html');
    };
    return {
        restrict: 'A',
        transclude: true,
        template: '<span ng-transclude></span>',
        link(scope, element, attrs) {
            let popOverContent;
            if (scope.users) {
                console.log(scope.users);
                const html = getTemplate();
                console.log(html);
                popOverContent = $compile(html)(scope);
                console.log(popOverContent);
                const options = {
                    content: popOverContent,
                    placement: 'bottom',
                    html: true,
                };
                console.log($(element));
                $(element).popover(options);
            }
        },
        scope: {
            users: '=',
        },
    };
});
