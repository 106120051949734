angular
    .module('backOffice')
    .controller(
        'ContentManagementConfigurationController',
        contentManagementConfigurationController
    );

contentManagementConfigurationController.$inject = [
    '$scope',
    'contentManagementConfigurationService',
    'toaster',
    'accountService',
];

function contentManagementConfigurationController(
    $scope,
    contentManagementConfigurationService,
    toaster,
    accountService
) {
    $scope.selectedTab = 'FI Product Information';
    $scope.selectTab = selectTab;
    $scope.isTabSelected = isTabSelected;
    $scope.subTab = subTab;
    $scope.save = save;
    $scope.configuration = { archive: 'DoNotArchive' };
    $scope.cancel = cancel;
    $scope.loadUserName = loadUserName;

    function selectTab(tabName) {
        $scope.selectedTab = tabName;
        if ($scope.selectedTab === 'Enrollment' || $scope.selectedTab === 'Password Reset') {
            $scope.subTabName = 'FI User';
            $scope.selectedTabName = 'FI User';
        }
    }

    function isTabSelected(tabName) {
        return tabName === $scope.selectedTabName;
    }

    function subTab(tabName) {
        $scope.subTabName = tabName;
        $scope.selectedTabName = tabName;
    }

    function save() {
        contentManagementConfigurationService.save($scope.configuration).then(() => {
            toaster.success('Changes saved successfully.');
        });
    }

    function cancel() {
        $scope.configuration = angular.copy($scope.copyConfiguration);
        $scope.form.$setPristine();
    }

    function loadUserName() {
        accountService.recentLogins().then(response => {
            $scope.userName = response.userName;
        });
    }

    function init() {
        contentManagementConfigurationService.get().then(response => {
            $scope.configuration = angular.copy(response);
            $scope.copyConfiguration = angular.copy($scope.configuration);
        });
        loadUserName();
    }

    init();
}
