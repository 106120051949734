/* eslint-disable sonarjs/cognitive-complexity */
import { CheckExceptionsRequests } from '@treasury/domain/backoffice/requests/reports/check-exception-requests.js';
import camelToTitleCase from '@treasury/domain/utilities/camel-to-title-case';
import kebabToCamelCase from '@treasury/domain/utilities/kebab-to-camel-case';
import titleToCamelCase from '@treasury/domain/utilities/title-to-camel-case';
import { CheckImageColumn } from '@treasury/omega/components/table';
import {
    attachmentlink,
    audithistory,
    auditinfo,
    checkImageLink as checkimagelink,
    comment,
    currency,
    currencyNoSymbol,
    decimal,
    decisionHistory as decisionhistory,
    historylink,
    int,
    multiSelect,
    radio,
    select,
    selectAmount,
    selectDate,
    selectDateAny,
    string,
    text,
    total
} from '@treasury/policy/backoffice-report';
import { count, date, dateTime } from '@treasury/policy/primitives';

const DATE_PERIODS = {
    Today: 'today',
    SpecificDate: 'specific',
    DateRange: 'range',
    'Week-To-Date': 'week-to-date',
    'Month-To-Date': 'month-to-date',
    'Year-To-Date': 'year-to-date',
};

const DATE_TYPES = ['datePeriod',
'datePeriodDate',
'datePeriodEnd',
'datePeriodStart',
'issuedDate',
'createdDate',];

const reportFilterFieldTypes = {
    string,
    int,
    decimal,
    currency,
    currencyNoSymbol,
    date,
    dateTime,
    audithistory,
    auditinfo,
    historylink,
    count,
    total,
    multiSelect,
    select,
    radio,
    selectAmount,
    selectDateAny,
    selectDate,
    text,
    decisionhistory,
    comment,
    checkimagelink,
    attachmentlink,
};
const reportFieldTypes = reportFilterFieldTypes;

export default class ReportFactory {
    constructor(metaData, columns, filters, custom) {
        this.metaData = metaData;
        this.columnData = columns;
        this.filterData = filters;
        this.customFilterData = custom;
    }

    get metaData() {
        return this._metaData;
    }

    set metaData(value) {
        this._metaData = value;
    }

    get filterData() {
        return this._filterData;
    }

    set filterData(value) {
        this._filterData = value;
    }

    get autostart() {
        return this.filterData?.runDefaultSearch || this.metaData?.isCustomReport;
    }

    get hasCascadingFilter() {
        return this.filterData?.filters.some(filter => filter.triggerCascadeFilter);
    }

    get cascadingFilterField() {
        const cascadingFilter = this.filterData?.filters.find(
            filter => filter.triggerCascadeFilter
        );
        return cascadingFilter?.model;
    }

    get reportColumns() {
        return this.buildColumns(this.columnData);
    }

    get reportFilters() {
        return this.buildFilters(this.filterData, this.customFilterData);
    }

    get reportFields() {
        return this.buildFields(this.columnData);
    }

    getColumnField(column) {
        // Ugly hack because the API expects the field name as camelCase but the column model returned by the API is in the wrong case
        if (column.columnName === 'OOBNumber') {
            return 'oobNumber';
        }
        return column.columnName.charAt(0).toLowerCase() + column.columnName.slice(1);
    }

    isCommandColumn(column) {
        // this was a poor extension of the model that forces us to check for transaction Id's that
        // hopefully always relate to an action triggered by an id on the record
        const commandDisplayTypes = ['historylink', 'attachmentlink', 'comment'];
        if (commandDisplayTypes.includes(column.displayType.toLowerCase())) return true;
        if (column.displayType.toLowerCase() === 'checkimagelink') return false;
        return column.columnName.toLowerCase() === 'transactionid' && column.detailsAccessible;
    }

    isTooltipColumn(column) {
        if (column.displayType === 'auditinfo') return true;
        if (column.displayType.toLowerCase() === 'checkimagelink') return false;
        // The transaction id check is the result of a bad model to determine
        // when to render an icon / tooltip column
        // Ideally we'll work with services to improve the model and remove this branch
        return (
            column.detailsAccessible &&
            column.columnName.toLowerCase() !== 'transactionid' &&
            column.displayType.toLowerCase() !== 'attachmentlink' &&
            column.displayType.toLowerCase() !== 'comment'
        );
    }

    isCheckImageColumn(column) {
        return column.displayType.toLowerCase() === 'checkimagelink';
    }

    getColumnType(column) {
        if (this.isTooltipColumn(column)) {
            return 'tooltip';
        }
        if (this.isCommandColumn(column)) {
            return 'command';
        }
        if (this.isCheckImageColumn(column)) {
            return CheckImageColumn;
        }
        return undefined;
    }

    getColumnAction(column) {
        if (column.displayType.toLowerCase() === 'historylink') {
            return 'showWireHistory';
        }
        if (column.displayType.toLowerCase() === 'attachmentlink') {
            return 'downloadAttachment';
        }
        if (column.displayType.toLowerCase() === 'comment') {
            return 'showComment';
        }
        if (this.isCommandColumn(column) && column.columnName.toLowerCase() === 'transactionid') {
            return 'routeToDetail';
        }
        return undefined;
    }

    getColumnLabel(column) {
        return column.displayName ? column.displayName : column.description;
    }

    isColumnWithSummary(column) {
        return (
            column.displayType.toLowerCase() === 'currency' ||
            column.displayType.toLowerCase() === 'decimal'
        );
    }

    buildColumns(cols) {
        const visibleColumns = cols;
        return visibleColumns
            .filter(column => this.getColumnFieldType(column))
            .map(column => ({
                field: this.getColumnField(column),
                label: this.getColumnLabel(column),
                sortable: column.isSortable,
                truncateText: column.displayType !== 'DateTime',
                order: column.displayOrder,
                summary: this.isColumnWithSummary(column),
                name: column.columnName,
                type: this.getColumnType(column),
                action: this.getColumnAction(column),
                fetchCheckImages: CheckExceptionsRequests.fetchCheckImages,
            }))
            .sort((a, b) => a.displayOrder - b.displayOrder);
    }

    buildFields(cols) {
        this.fields = {};
        cols.forEach(column => {
            const field = this.getColumnField(column);
            if (this.getColumnFieldType(column)) {
                this.fields[field] = this.getColumnFieldType(column)
                    .with.label(column.displayName)
                    .thatIs.sortable(column.isSortable)
                    .thatIs.readOnly();

                if (column.displayType.toLowerCase() === 'comment') {
                    this.fields[field] = this.fields[field].thatHas.compareFunction((a, b) => 0);
                }

                if(column.displayType === 'DateTime' || column.displayType == 'Currency') {
                    this.fields[field] = this.fields[field].and.maxColumnWidth(null).and.targetColumnWidth(null);
                }

                // This conditional can be removed once all ACH Payment types are supported for this Feature: https://banno-jha.atlassian.net/browse/TMUX-2195 
                if(this.metaData.type === 'achPayment' && column.columnName.toLowerCase() === 'transactionid') {
                    this.fields[field] = this.fields[field].and.disabledWhen(record => record.getField('achPaymentUniqueId') === '00000000-0000-0000-0000-000000000000')
                }

            }
        });
        return this.fields;
    }

    getFilterTag(filter) {
        // TODO: this is a hack to get the tag from the filter
    }

    buildFilters(filterResponse, customReportFilters) {
        this.customFilterData = customReportFilters;
        if (!this.filterData) return [];
        return this.filterData.filters
            .map(filter => ({
                ...filter,
                field: filter.model,
                fieldType: this.getFilterFieldType(filter)
                    .with.label(filter.label)
                    .thatHas.options(this.getOptions(filter))
                    .thatIs.requiredWhen(record => this.isFilterRequired(filter, record))
                    .and.placeholder(this.buildPlaceholder(filter))
                    .thatIs.segmented()
                    .and.disabledWhen(record => this.disableCascadingFilters(filter, record)),
                value: this.getFieldValue(filter),
                inline: this.isRadio(filter),
            }))
            .sort((a, b) => a.order - b.order);
    }

    buildDownloadOptions(response) {
        // need to keep name and downloadType properties to use in download request
        return response.map(option => option.name);
    }

    buildPlaceholder(filter) {
        if (this.isSelect(filter)) return `Select`;
        return ``;
    }

    // *** TODO: create interpreter to translate proper field type with validation, etc. here ***//
    getColumnFieldType(column) {
        const fieldType = titleToCamelCase(column.displayType);
        return reportFieldTypes[fieldType];
    }

    getFilterFieldType(filter) {
        const filterType = kebabToCamelCase(filter.type);
        return reportFilterFieldTypes[filterType];
    }
    //* ******/

    getFieldValue(filter) {
        if (this.customFilterData && this.customFilterData[filter.model])
            return this.mapCustomFilterValuesToFilters(filter);
        if (this.isRadio(filter)) return null;
        if (this.isSelect(filter)) return [];
        if (this.isAmountRange(filter)) {
            return [filter.filterData[0].id, 0, 0];
        }
        if (this.isDateFilter(filter)) return this.getDateFilterDefaultValue(filter);
        return filter.filterData;
    }

    getDateFilterDefaultValue(filter) {
        if (filter.model === 'issuedDate') return 'no-date-selected';
        return 'today';
    }

    mapCustomFilterValuesToFilters(filter) {
        if (this.isAmountFilter(filter)) {
            return this.mapAmountToFilterField(filter);
        }
        if (this.isDateFilter(filter)) {
            return this.mapDateToFilterField(filter);
        }
        if (this.isMultiSelect(filter)) {
            return this.mapMultiSelectToFilterField(filter);
        }
        if (this.isSelect(filter) || this.isRadio(filter)) {
            return this.mapSelectOrRadioToFilterField(filter);
        }
        return this.customFilterData[filter.model];
    }

    isAmountFilter(filter) {
        return ['amount', 'amountEnd', 'amountSpecific', 'amountStart'].includes(filter.model);
    }

    isDateFilter(filter) {
        return DATE_TYPES.includes(filter.model);
    }

    mapAmountToFilterField(filter) {
        if (['amountEnd', 'amountSpecific', 'amountStart'].includes(filter)) return null;
        if (this.customFilterData.amountSpecific)
            return [this.customFilterData.amount, this.customFilterData.amountSpecific];
        return [
            this.customFilterData.amount,
            this.customFilterData.amountStart,
            this.customFilterData.amountEnd,
        ];
    }

    mapDateToFilterField(filter) {
        const key = DATE_TYPES.find(k => k === filter.model);
        if ([`${key}Date`, `${key}End`, `${key}Start`].includes(filter)) return null;
        if (this.customFilterData[`${key}Date`]) return this.customFilterData[`${key}Date`];
        if (this.customFilterData[`${key}Start`])
            return {
                dates: [this.customFilterData[`${key}Start`], this.customFilterData[`${key}End`]],
                id: 'range',
            };
        return DATE_PERIODS[this.customFilterData[`${key}`]];
    }

    mapSelectOrRadioToFilterField(filter) {
        const customFilterValue = this.customFilterData[filter.model];
        if (!customFilterValue) return null;
        return filter.filterData.find(v => v.id === customFilterValue);
    }

    mapMultiSelectToFilterField(filter) {
        const customFilterValue = this.customFilterData[filter.model];
        if (!customFilterValue.length) return customFilterValue;
        const customFilterValueIds = customFilterValue.map(v => {
            const matchKey = v.id ? 'id' : 'value';
            return v[matchKey].toString();
        });
        return filter.filterData.filter(data => {
            const matchKey = data.id ?? data.value;
            return customFilterValueIds.includes(matchKey);
        });
    }

    hasDatePeriodSelection(record) {
        return record.getField('datePeriod');
    }

    isFilterRequired(filter, record) {
        /**
         * outlier check we should roll into the report model.
         * update 'required' property to be 'requiredWhen' and either true, false, or the filter model (e.g. 'achDateField')
         */
        if (filter.model === 'achDateField') return this.hasDatePeriodSelection(record);
        return !!filter.required;
    }

    getOptions(filter) {
        if (this.isRadio(filter) || this.isAmountRange(filter)) {
            return { data: filter.filterData, text: 'value', value: 'id' };
        }

        if (!this.isSelect(filter)) return null;

        return {
            fetch: async record => this.cascadingFilteredData(filter, record),
            text: 'value',
            value: record => record,
            hideSelectAll: true,
        };
    }

    cascadingFilteredData(filter, record) {
        if (!this.hasCascadingFilter) return Promise.resolve(filter.filterData);
        if (filter.triggerCascadeFilter) return Promise.resolve(filter.filterData);
        const cascadeValue = record.getField(this.cascadingFilterField).map(v => v.id);
        if (filter.applyCascadeFilter)
            return Promise.resolve(this.applyCascadeFilteringToOptions(filter, cascadeValue));
        return Promise.resolve(filter.filterData);
    }

    disableCascadingFilters(filter, record) {
        if (!this.cascadingFilterField) return false;
        const cascadeValue = record.getField(this.cascadingFilterField);
        if (!this.hasCascadingFilter) return false;
        if (filter.triggerCascadeFilter) return false;
        if (!filter.applyCascadeFilter) return false;
        return cascadeValue.length === 0;
    }

    applyCascadeFilteringToOptions(filter, cascadeValue) {
        if (Array.isArray(cascadeValue) && !cascadeValue.length) return filter.filterData;
        if (!cascadeValue) return filter.filterData;
        return filter.filterData.filter(data =>
            data.cascadeFilterIds.map(v => v.toString()).some(v => cascadeValue.includes(v))
        );
    }

    summaryFields(summary) {
        const fields = {};
        delete summary.rows;
        Object.keys(summary).forEach(summaryField => {
            fields[summaryField] = reportFieldTypes[this.summaryFieldType(summaryField)].with
                .label(this.summaryFieldLabel(summaryField))
                .thatIs.readOnly();
        });
        return fields;
    }

    summaryFieldType(field) {
        return camelToTitleCase(field).split(' ').pop().toLowerCase();
    }

    summaryFieldLabel(field) {
        const fieldAsArray = camelToTitleCase(field).split(' ');
        return fieldAsArray.join(' ');
    }

    summaryValues(summary) {
        const values = { ...summary };
        delete values.rows;
        return values;
    }

    isMultiSelect(filter) {
        return filter.type === 'multi-select';
    }

    isSelect(filter) {
        return filter.type === 'multi-select' || filter.type === 'select';
    }

    isDatePicker(filter) {
        return filter.type === 'select-date-any' || 'select-date';
    }

    isRadio(filter) {
        return filter.type === 'radio';
    }

    isAmountRange(filter) {
        return filter.type === 'select-amount';
    }
}
