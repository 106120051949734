import { ChallengeGroupModelDto } from '@treasury/api/bo';
import { DomainEntity } from '../../../../shared';

const defaultCompany: ChallengeGroupModelDto = {
    id: 0,
    name: '',
    isDefault: false,
};

export class ChallengeGroup extends DomainEntity<ChallengeGroupModelDto> {
    constructor(dto: ChallengeGroupModelDto) {
        super(dto);
        this.id = dto.id;
        this.name = dto.name;
        this.isDefault = dto.isDefault;
        this.description = dto.description;
    }

    id: number;
    name: string;
    isDefault: boolean;
    description?: string;

    public createDefault(): this {
        return new ChallengeGroup(defaultCompany) as this;
    }
}
