
import { AchInternationalRecipient, AchPayment } from './';
import { AchInternationalPaymentModelDto, defaultAchInternationalPaymentModelDto } from './data/ach.data';


export class AchInternationalPayment extends AchPayment<AchInternationalPaymentModelDto> {
    public createDefault(): this {
        return new AchInternationalPayment(defaultAchInternationalPaymentModelDto) as this;
    }

    public get streetAddress() {
        return this.dto.streetAddress;
    }

    public get city() {
        return this.dto.city;
    }

    public get state() {
        return this.dto.state;
    }

    public get zipCode() {
        return this.dto.state;
    }

    public get country() {
        return this.dto.country;
    }

    public get destinationCountryCode() {
        return this.dto.destinationCountryCode;
    }

    public get destinationCurrencyCode() {
        return this.dto.destinationCurrencyCode;
    }

    public get foreignExchangeIndicator() {
        return this.dto.foreignExchangeIndicator;
    }

    public get foreignExchangeReference() {
        return this.dto.foreignExchangeReference;
    }

    public get originatorCurrencyCode() {
        return this.dto.originatorCurrencyCode;
    }

    public get fxRecipients() {
        if (!this.dto.fxRecipients) return [];
        return this.dto.fxRecipients.map(recipient => new AchInternationalRecipient(recipient));
    }
    
}
