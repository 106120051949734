angular
    .module('backOffice')
    .controller(
        'TermsAndConditionsConfigurationController',
        termsAndConditionsConfigurationController
    );

termsAndConditionsConfigurationController.$inject = [
    '$scope',
    'modalService',
    'termsAndConditionsService',
];

function termsAndConditionsConfigurationController(
    $scope,
    modalService,
    termsAndConditionsService
) {
    $scope.editorOptions = [
        'bold',
        'italic',
        'strikethrough',
        'subscript',
        'superscript',
        'insertUnorderedList',
        'insertOrderedList',
        'createLink',
        'unlink',
    ];

    $scope.mode = null;
    $scope.termsAndConditions = null;
    $scope.restoreTermsAndConditions = null;
    $scope.actionEventName = null;
    $scope.cancelEventName = null;
    $scope.form = {};
    $scope.setActionEvent = setActionEvent;
    $scope.setCancelEvent = setCancelEvent;
    $scope.isActionEventDisabled = isActionEventDisabled;
    $scope.returnToTermsEditor = returnToTermsEditor;
    $scope.returnToTermsDisplay = returnToTermsDisplay;
    $scope.submitChanges = submitChanges;
    $scope.displayTermsAndConditions = displayTermsAndConditions;
    $scope.isEditMode = isEditMode;
    $scope.getTemplateUrl = getTemplateUrl;

    init();

    function init() {
        configureButtons();
        termsAndConditionsService.getTermsAndConditions().then(response => {
            $scope.termsAndConditions = response;
            $scope.restoreTermsAndConditions = angular.copy($scope.termsAndConditions);
            if (!$scope.termsAndConditions || !$scope.termsAndConditions.text) {
                updateMode('Edit');
                $scope.termsAndConditions = {
                    requireUsersAcceptanceAfterEdit: true,
                };
            }
        });
    }

    function configureButtons() {
        if ($scope.mode === null) {
            $scope.actionEventName = 'Edit';
            $scope.cancelEventName = null;
        }

        if ($scope.mode === 'Edit') {
            $scope.actionEventName = 'Review';
            $scope.cancelEventName = 'Cancel';
        }

        if ($scope.mode === 'Review') {
            $scope.actionEventName = 'Confirm';
            $scope.cancelEventName = 'Cancel';
        }
    }

    function updateMode(mode) {
        $scope.mode = mode;
        configureButtons();
    }

    function isEditMode() {
        return $scope.mode === 'Edit';
    }

    function setActionEvent(state) {
        if (state === 'Confirm') {
            submitChanges();
        } else {
            updateMode(state);
        }
    }

    function setCancelEvent() {
        if ($scope.mode === 'Edit') {
            returnToTermsDisplay();
        }
        if ($scope.mode === 'Review') {
            returnToTermsEditor();
        }
    }

    function returnToTermsDisplay() {
        if ($scope.form && $scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel the changes?',
                submit() {
                    modalInstance.close();
                    $scope.termsAndConditions = angular.copy($scope.restoreTermsAndConditions);
                    updateMode(null);
                    $scope.form.$dirty = false;
                },
            };
            const modalInstance = modalService.showModal({}, modalOptions);
        } else {
            updateMode(null);
        }
    }

    function returnToTermsEditor() {
        const modalOptions = {
            closeButtonText: 'Continue Review',
            actionButtonText: 'OK',
            headerText: 'Cancel',
            bodyText: 'Are you sure you want to cancel the review?',
            submit() {
                modalInstance.close();
                updateMode('Edit');
            },
        };
        const modalInstance = modalService.showModal({}, modalOptions);
    }

    function isActionEventDisabled(actionEventName) {
        return actionEventName === 'Review' && $scope.form && !$scope.form.$dirty;
    }

    function submitChanges() {
        termsAndConditionsService
            .saveTermsAndConditions($scope.termsAndConditions)
            .then(response => {
                $scope.termsAndConditions = response;
                $scope.restoreTermsAndConditions = response;
                updateMode(null);
            });
    }

    function displayTermsAndConditions() {
        if ($scope.termsAndConditions && $scope.termsAndConditions.text) {
            return $scope.termsAndConditions.text;
        }
    }

    function getTemplateUrl() {
        if ($scope.mode === 'Edit') {
            return 'app/configuration/termsAndConditions/views/editTerms.html';
        }
        return 'app/configuration/termsAndConditions/views/viewTerms.html';
    }
    $scope.onChange = function () {
        $scope.form.$dirty = true;
    };
}
