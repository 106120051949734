import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('wireCompaniesService', wireCompaniesService);

wireCompaniesService.$inject = ['$resource'];

function wireCompaniesService($resource) {
    const resourceUrl = `${getBackOfficeAPIPath()}wireCompanies`;
    return {
        search,
        getCompanyAccounts,
    };

    function search(name, companyId, isDli) {
        return $resource(`${resourceUrl}/:id/search`, { id: companyId }).query({
            companyName: name,
            isDli,
        }).$promise;
    }

    function getCompanyAccounts(companyId, parentCompanyId, isDli) {
        return $resource(
            `${resourceUrl}/accounts`,
            {},
            { getCompanyAccounts: { method: 'GET', isArray: false } }
        ).getCompanyAccounts({
            coreCompanyId: companyId,
            parentCompanyId,
            isDli,
        }).$promise;
    }
}
