import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('secureChallengeService', secureChallengeService);

secureChallengeService.$inject = ['$resource'];

function secureChallengeService($resource) {
    const resourceUrl = `${getBackOfficeAPIPath()}secure-challenge/`;

    return {
        updateStatus,
        updateChallengeMethod,
        updateChallengeGroup,
    };

    function updateStatus(companyId, isSecureChallengeEnabled) {
        return $resource(`${resourceUrl}update-status`, {
            companyId,
            isSecureChallengeEnabled,
        }).save().$promise;
    }

    function updateChallengeMethod(companyId, challengeMethodType) {
        return $resource(`${resourceUrl}update-challenge-method`, {
            companyId,
            challengeMethodType,
        }).save().$promise;
    }

    function updateChallengeGroup(companyId, challengeGroupId) {
        return $resource(`${resourceUrl}update-challenge-group`, {
            companyId,
            challengeGroupId,
        }).save().$promise;
    }
}
