import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { Record, Recordset } from '@treasury/FDL';
import { NavigationService } from '@treasury/core/navigation';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-button';
import '@treasury/omega/components/omega-field';
import '@treasury/omega/components/omega-filter-bar';
import '@treasury/omega/components/omega-table';
import { ActionDetail } from '@treasury/omega/layouts/omega-report/omega-report.types';
import { AlertMixin } from '@treasury/omega/mixins';
import { OmegaAlertConfig } from '@treasury/omega/services/omega-alert';
import { LitElement, css, html, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import AlternateSearchClient from '../clients/alternate-search-client';
import { columns, fields, filters } from '../data/alternate-search';

const AlertingListeningElement = AlertMixin(ListeningElementMixin(LitElement));
class AlternateSearch extends AlertingListeningElement {
    constructor() {
        super();
        this.client = new AlternateSearchClient();
        this.filterRecord = new Record(filters, { searchType: 'Company Name', searchValue: '' });
        this.actions = {
            routeToCompanyConfiguration: (companyRecord: any) => {
                const { id, cifNumber } = companyRecord.values;
                if (id) {
                    this.navService.navigate('company', { id });
                } else {
                    this.navService.navigate('company', { cifNumber, id: 0 });
                }
            },
        };
    }

    @InjectProperty()
    private declare readonly navService: NavigationService;

    @property({ type: Boolean })
    public hidden = true;

    @property({ type: Object })
    private client: any;

    @property({ type: Recordset })
    private recordset: any;

    @property({ type: Array })
    private filters: Array<[]> = [];

    @property({ type: Boolean })
    private loading = false;

    @property({ type: Object })
    private parameters: any;

    @property({ type: Object })
    alert: OmegaAlertConfig = {
        visible: false,
        message: '',
        code: '',
        time: '',
        title: '',
        type: 'success',
        posture: 'polite',
        actions: html``,
    };

    @property({ type: Boolean })
    private tableLoading = false;

    @property({ type: Object })
    private filterRecord: any;

    @property({ type: Boolean })
    private filterRecordIsValid = false;

    @property({ type: Boolean })
    private searchClicked = false;

    @property({ type: Object })
    private actions: any;

    firstUpdated() {
        this.recordset = new Recordset(fields, () =>
            this.client.fetchNotOnboardedCompanies(this.parameters)
        );
        this.recordset.setInitialPageSize(25);
        this.listenTo(this.filterRecord, 'change', () => {
            this.parameters = this.filterRecord.values;
            this.filterRecordIsValid =
                this.filterRecord.hasRequiredValues() && this.filterRecord.isValid();
        });
    }

    private handleActions({ action, record, rowIndex }: ActionDetail) {
        this.actions[action](record, rowIndex);
    }

    renderFilters() {
        if (!this.filterRecord) return nothing;
        return html`<div class="filters-wrapper">
            <div class="field-wrapper">
                <omega-field field="searchType" .record=${this.filterRecord}></omega-field>
            </div>
            <div class="field-wrapper">
                <omega-field field="searchValue" .record=${this.filterRecord}></omega-field>
            </div>
            <omega-button
                type="primary"
                .disabled=${!this.filterRecordIsValid}
                @click=${async () => {
                    this.parameters = this.filterRecord.values;
                    this.searchClicked = true;
                    await this.recordset.requestHardUpdate();
                }}
                >Search</omega-button
            >
            <omega-button type="secondary" @click=${() => this.filterRecord.reset()}
                >Reset</omega-button
            >
        </div>`;
    }

    renderTable() {
        if (!this.searchClicked) return nothing;
        return html`<omega-filter-bar
                .filters=${[]}
                .recordset=${this.recordset}
                @change=${({ detail }: CustomEvent) => {
                    this.filters = detail;
                }}
            ></omega-filter-bar>
            <omega-table
                .recordset=${this.recordset}
                .columnDefinitions=${columns}
                .loading=${this.tableLoading}
                .filters=${this.filters}
                @action=${(e: CustomEvent<ActionDetail>) => this.handleActions(e.detail)}
            ></omega-table>`;
    }

    render() {
        if (this.hidden) return nothing;
        return html` ${this.renderAlert()} ${this.renderFilters()} ${this.renderTable()}`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                margin: 10px;
            }
            .filters-wrapper {
                margin: 10px 50px;
            }
            .field-wrapper {
                max-width: 500px;
                margin-bottom: 10px;
                margin-left: 8px;
            }
        `;
    }
}

customElements.define('alternate-search', AlternateSearch);
