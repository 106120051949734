import { FieldType } from '@treasury/FDL';
import { FdlFieldDefinitions } from '@treasury/FDL/record';
import FieldTypes from '@treasury/policy/primitives';
import { Company } from '../types/company.types';

export const columns = [
    {
        label: 'Company Name',
        field: 'name',
        type: 'command',
        action: 'routeToCompanyConfiguration',
    },
    {
        label: 'Tax Number',
        field: 'taxNumber',
    },
    {
        label: 'Company Id',
        field: 'companyId',
    },
    {
        label: 'CIF Number',
        field: 'cifNumber',
    },
    {
        label: 'Status',
        field: 'status',
    },
];
export const fields: FdlFieldDefinitions<Company> = {
    name: FieldTypes.string.thatIs
        .disabledWhen((record: any) => record.getField('status').toLowerCase() === 'associated')
        .thatIs.readOnly() as FieldType<string>,
    taxNumber: FieldTypes.string.thatIs.readOnly() as FieldType<string>,
    companyId: FieldTypes.string.thatIs.readOnly() as FieldType<string>,
    cifNumber: FieldTypes.string.thatIs.readOnly() as FieldType<string>,
    status: FieldTypes.string.thatIs.readOnly() as FieldType<string>,
};
