angular.module('backOffice').controller('UnlockUserController', unlockUserController);

unlockUserController.$inject = ['$scope', '$state'];

function unlockUserController($scope, $state) {
    $scope.reset = function () {
        init();
        $scope.form.$setPristine();
    };

    $scope.processForgotPassword = function () {
        $state.go('email-sent-confirmation');
    };

    $scope.message = '';

    function init() {
        $scope.forgotPassword = {
            fiId: '',
            loginId: '',
            password: '',
        };
    }

    init();

    $scope.initialize = function (form) {
        $scope.form = form;
    };
}
