angular.module('backOffice').controller('ResetPassWordController', ResetPassWordController);

ResetPassWordController.$inject = [
    '$scope',
    '$modalInstance',
    '$modal',
    'userDetails',
    'companyId',
    'toaster',
    'usersService',
    'companyUsersService',
];

function ResetPassWordController(
    $scope,
    $modalInstance,
    $modal,
    userDetails,
    companyId,
    toaster,
    usersService,
    companyUsersService
) {
    // variables
    $scope.resetPassword = {
        emailPasswordLink: true,
    };
    $scope.error = '';

    $scope.helpText = '';
    $scope.buttonText = '';
    $scope.selectedUser = userDetails;
    $scope.companyId = companyId;

    // functions
    $scope.sendPassword = sendPassword;
    $scope.onMethodChanged = onMethodChanged;
    $scope.cancel = cancel;

    // watch
    $scope.$watch('form.$dirty', isDirty => {
        $modalInstance.isDirty = isDirty;
    });

    function cancel() {
        $modalInstance.dismiss();
    }

    function sendPassword() {
        if ($scope.companyId === null) {
            // eslint-disable-next-line no-negated-condition
            if (!$scope.resetPassword.emailPasswordLink) {
                $scope.error = '';
                usersService
                    .manualResetPassword($scope.selectedUser.id, $scope.resetPassword)

                    .then(
                        () => {
                            toaster.save('User Temporary Password');
                            $modalInstance.close();
                        },
                        err => {
                            $scope.error =
                                err instanceof Error ? err.message : 'An unknown error occurred.';
                        }
                    );
            } else {
                usersService.resetPassword($scope.selectedUser.id).then(() => {
                    toaster.save('User Password Reset');
                    $modalInstance.close();
                });
            }
            // eslint-disable-next-line no-negated-condition
        } else if (!$scope.resetPassword.emailPasswordLink) {
            companyUsersService
                .manualResetPassword($scope.companyId, $scope.selectedUser.id, $scope.resetPassword)
                .then(() => {
                    toaster.save('User Temporary Password');
                    $modalInstance.close();
                });
        } else {
            companyUsersService.resetPassword($scope.companyId, $scope.selectedUser.id).then(() => {
                toaster.save('User Password Reset');
                $modalInstance.close();
            });
        }
    }

    function init() {
        onMethodChanged();
    }

    function onMethodChanged() {
        if ($scope.resetPassword.emailPasswordLink) {
            $scope.buttonText = 'Email Password Link';
            $scope.helpText = `An email will be sent to ${$scope.selectedUser.userName} with a link to reset their password.`;
            $scope.resetPassword.tempPassword = ''; // clear if they switch back to auto generated link
        } else {
            $scope.buttonText = 'Save Password';
            $scope.helpText =
                'The user will not receive an automated message. You must provide the temporary password to the user. The user will be prompted to change their password after successfully logging in.';
        }
    }

    init();
}
