angular.module('backOffice').controller('FiRolesListController', fiRolesListController);

fiRolesListController.$inject = ['$scope', '$modal', 'rolesService', 'entitlementsService'];

function fiRolesListController($scope, $modal, rolesService, entitlementsService) {
    $scope.joinUsers = joinUsers;
    $scope.canViewRoles = canViewRoles;
    $scope.filterRoles = filterRoles;
    $scope.manageRole = manageRole;
    $scope.showAddRoleButton = showAddRoleButton;

    $scope.searchText = '';
    $scope.editRoleLinkText = 'View';

    init();

    function init() {
        loadRoles();
        $scope.orderByField = 'name';
        $scope.reverseSort = false;

        if (entitlementsService.hasEntitlement('Edit User Role')) {
            $scope.editRoleLinkText = 'Edit';
        }
    }

    function loadRoles() {
        rolesService.getRoles().then(response => {
            $scope.rolesList = response;
            processData();
            filterRoles();
        });
    }

    function joinUsers(role) {
        if (role._users) {
            if (role.expanded) {
                return role._users.map(user => user).join(', ');
            }
            return role._users
                .map(user => user)
                .slice(0, 10)
                .join(', ');
        }
    }

    function processData() {
        angular.forEach($scope.rolesList, role => {
            if (role.users !== null && role.users !== undefined) {
                const users = role.users.split(',');
                for (let i = 0; i < users.length; i++) {
                    users[i] = users[i].trim();
                }
                role._users = '';
                role._usersDetail = '';
                role._users = users;
                role._usersDetail = users;
            }
        });
    }

    function canViewRoles() {
        return entitlementsService.hasAnyEntitlement(
            'Add User Role',
            'Edit User Role',
            'View User Role',
            'Switch FI'
        );
    }

    function showAddRoleButton() {
        return entitlementsService.hasAnyEntitlement('Add User Role', 'Switch FI');
    }

    function isUserListed(role, text) {
        let result = false;
        if (role.users !== null && role.users !== undefined) {
            if (role.users.toUpperCase().indexOf(text.toUpperCase()) > -1) {
                result = true;
            }
        }
        return result;
    }

    function manageRole(roleToEdit, canEdit) {
        const modalInstance = $modal.open({
            template: require('../views/manageFiRoles.html'),
            size: 'lg',
            controller: 'ManageFiRolesController',
            backdrop: 'static',
            resolve: {
                roleDetails() {
                    return angular.copy(roleToEdit);
                },
                canDelete() {
                    return $scope.hasEntitlement('Edit User Role');
                },
                canEdit() {
                    return canEdit;
                },
            },
        });
        modalInstance.result.then(user => {
            init();
        });
    }

    function filterRoles() {
        $scope.filteredList = $scope.rolesList.filter(
            role =>
                role.name.toUpperCase().indexOf($scope.searchText.toUpperCase()) > -1 ||
                role.description.toUpperCase().indexOf($scope.searchText.toUpperCase()) > -1 ||
                isUserListed(role, $scope.searchText) === true
        );
    }
}
