angular
    .module('backOffice')
    .controller('aaInstitutionConfigurationController', aaInstitutionConfigurationController);

aaInstitutionConfigurationController.$inject = [
    '$scope',
    '$modal',
    '$state',
    'transactionCodesService',
    'toaster',
];

function aaInstitutionConfigurationController(
    $scope,
    $modal,
    $state,
    transactionCodesService,
    toaster
) {
    $scope.selectedTab = 'ACH';
    $scope.selectTab = selectTab;
    init();

    $scope.goToProductFeatureConfiguration = function () {
        $state.go('configuration');
    };

    $scope.saveConfiguration = function () {
        const codesToSave = [];
        $scope.selectedTransactionTypeCategory.analysisTransactionTypes.forEach(
            analysisTransactionType => {
                const transactionCode =
                    $scope.analysisTransactionCodes[
                        analysisTransactionType.analysisTransactionTypeId
                    ];
                if (transactionCode != null && transactionCode.analysisTransactionTypeId != null) {
                    codesToSave.push(transactionCode);
                }
            }
        );
        transactionCodesService.saveAnalysisTransactionCodes(codesToSave).then(response => {
            afterAnalysisTransactionCodesLoaded(response);
            toaster.save('Account Analysis Configuration');
            $scope.aaConfigForm.$setPristine();
        });
    };

    $scope.cancel = function () {
        if ($scope.aaConfigForm.$dirty) {
            $modal
                .open({
                    template: require('../views/aaConfigCancelView.html'),
                    controller: 'aaConfigCancelController',
                    resolve: {
                        saveOrCancel: false,
                    },
                })
                .result.then(result => {
                    if (result === 'yes') {
                        $state.reload();
                    }
                });
        } else {
            $state.reload();
        }
    };

    $scope.analysisTransactionCodeChanged = function (analysisTransactionTypeId) {
        const analysisTransactionCode = $scope.analysisTransactionCodes[analysisTransactionTypeId];
        if (!analysisTransactionCode.analysisTransactionCodeId) {
            // new transaction code
            if (analysisTransactionCode.transactionCode.length) {
                analysisTransactionCode.analysisTransactionTypeId = analysisTransactionTypeId;
                analysisTransactionCode.active = true;
            } else {
                $scope.analysisTransactionCodes[analysisTransactionTypeId] = defaultCode();
            }
        } else {
            analysisTransactionCode.active = analysisTransactionCode.transactionCode.length !== 0;
        }
    };

    function init() {
        getAnalysisTransactionTypes();
        getAnalysisTransactionCodes();
    }

    function getAnalysisTransactionTypes() {
        const achAnalysisTransactionTypeCategoryId = 1;
        const idxAchAnalysisTransactionTypeCategoryId = 0;
        transactionCodesService.getAnalysisTransactionTypes().then(response => {
            $scope.analysisTransactionTypeCategories =
                transactionCodesService.groupAnalysisTransactionTypesByCategory(response);
            selectTab(
                'ACH',
                achAnalysisTransactionTypeCategoryId,
                idxAchAnalysisTransactionTypeCategoryId
            );
            createMissingCodeObjects();
        });
    }

    function getAnalysisTransactionCodes() {
        transactionCodesService
            .getAnalysisTransactionCodesForInstitution()
            .then(afterAnalysisTransactionCodesLoaded);
    }

    function afterAnalysisTransactionCodesLoaded(response) {
        // create object that is searchable by AnalysisTransactionType
        const analysisTransactionCodes = $scope.analysisTransactionCodes || {};
        response.forEach(analysisTransactionCode => {
            analysisTransactionCodes[analysisTransactionCode.analysisTransactionTypeId] =
                analysisTransactionCode;
            if (!analysisTransactionCode.active) {
                // since the codes existed before this feature we need to blank out the inactive ones so it looks consistant for the user.
                analysisTransactionCode.transactionCode = '';
            }
        });
        $scope.analysisTransactionCodes = analysisTransactionCodes;
        createMissingCodeObjects();
    }

    function createMissingCodeObjects() {
        if ($scope.analysisTransactionCodes && $scope.analysisTransactionTypeCategories) {
            $scope.analysisTransactionTypeCategories.forEach(analysisTransactionTypeCategory => {
                analysisTransactionTypeCategory.analysisTransactionTypes.forEach(
                    analysisTransactionType => {
                        if (
                            !$scope.analysisTransactionCodes[
                                analysisTransactionType.analysisTransactionTypeId
                            ]
                        ) {
                            $scope.analysisTransactionCodes[
                                analysisTransactionType.analysisTransactionTypeId
                            ] = defaultCode();
                        }
                    }
                );
            });
        }
    }

    function defaultCode() {
        return { transactionCode: '' };
    }

    function selectTab(
        tabName,
        analysisTransactionTypeCategoryId,
        idxAnalysisTransactionTypeCategory
    ) {
        if ($scope.aaConfigForm.$dirty) {
            promptToSave();
        } else {
            $scope.selectedTab = tabName;
            const selectedTransactionTypeCategory = $scope.analysisTransactionTypeCategories.filter(
                item => analysisTransactionTypeCategoryId === item.analysisTransactionTypeCategoryId
            );
            $scope.selectedTransactionTypeCategory = selectedTransactionTypeCategory[0];
            $scope.idxAnalysisTransactionTypeCategory = idxAnalysisTransactionTypeCategory;
        }
    }

    function promptToSave() {
        $modal.open({
            template: require('../views/aaConfigSaveCancelView.html'),
            controller: 'aaConfigSaveCancelController',
        });
    }
}
