const userStorageKey = 'bo-user';

interface UserLoginData {
    fiId: string;
    loginId: string;
    password: string;
    useRefreshTokens: boolean;
    riskReceiptId?: string;
    credential: {
        biometricsInfo: {
            authenticationId?: string;
            key?: string;
        };
        data: unknown[];
        message: string;
        numberOfSecurityQuestions: number;
        riskReceiptId?: undefined;
        rsaStatus: 'Allow' | 'Deny';
        secureTokenStatus: 'Allow' | 'Deny';
        userCredentialStatus: 'Allow' | 'Deny';
        id: number;
        updatedBy?: string;
        updatedDate?: string;
    };
}

export class BoPagePushService {
    // eslint-disable-next-line no-empty-function
    constructor(private $window: ng.IWindowService) {}

    static $inject = ['$window'];

    private currentUser?: UserLoginData;

    public getUser() {
        if (!this.currentUser) {
            const userJson = this.$window.sessionStorage.getItem(userStorageKey);
            if (!userJson) {
                throw new Error(
                    'Could not get the current user. It was not in memory or previously set in storage.'
                );
            }

            this.currentUser = JSON.parse(userJson);
        }
        return this.currentUser;
    }

    public setUser(user: UserLoginData) {
        if (!user) {
            return;
        }

        const userJson = JSON.stringify(user);
        this.$window.sessionStorage.setItem(userStorageKey, userJson);
        this.currentUser = user;
    }
}

angular.module('backOffice').service('pagePushService', BoPagePushService);
