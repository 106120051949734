angular.module('backOffice').controller('AddAccountsController', addAccountsController);

addAccountsController.$inject = [
    '$scope',
    '$filter',
    'companiesService',
    'accountsService',
    'companyId',
    'companyName',
    'cifNumber',
    '$modalInstance',
    'regexConstants',
];

function addAccountsController(
    $scope,
    $filter,
    companiesService,
    accountsService,
    companyId,
    companyName,
    cifNumber,
    $modalInstance,
    regexConstants
) {
    const selectAll = 'Select All';
    $scope.addAccountsList = [];
    $scope.accountListFiltered = [];
    $scope.unrelatedSearchTypes = [
        { name: 'Account Number', type: 'Account Number' },
        { name: 'CIF Number', type: 'CIF' },
    ];
    $scope.filter = {
        checkAll: false,
        searchValue: '',
        accountFilter: '',
        searchType: $scope.unrelatedSearchTypes[0].type,
        searched: false,
        selectedCifNumber: 'Select All',
        selectedAccountType: 'Select All',
        selectedUnrelatedAccountType: 'Checking',
        selectedRelatedCompany: 'Select All',
    };
    $scope.companyId = companyId;
    $scope.companyName = companyName;
    $scope.cifNumber = cifNumber;
    $scope.alphaNumericRegex = regexConstants.AlphaNumericRegex;
    $scope.numericRegex = regexConstants.NumericRegex;
    $scope.filter.selectedCifNumber = null;
    $scope.associatedCompanies = null;
    $scope.options = {
        activate: false,
        related: true,
        showAccountsWarning: false,
        showCifWarning: false,
        maxLength: 40,
    };

    $scope.filterAccounts = function (array, searchTerm) {
        // If no array is given, exit.
        if (!array) {
        } else if (!searchTerm) {
            return array;
        } else {
            const term = searchTerm.toLowerCase();

            return array.filter(item => {
                const termInId = item.type.toLowerCase().indexOf(term) > -1;
                const termInName = item.number.toLowerCase().indexOf(term) > -1;
                if ($scope.options.related) {
                    const termInCompany = item.companyName.toLowerCase().indexOf(term) > -1;
                    const termInCif = item.cifNumber.toLowerCase().indexOf(term) > -1;
                    return termInId || termInName || termInCompany || termInCif;
                }
                return termInId || termInName;
            });
        }
    };

    $scope.updateList = function () {
        let result = true;
        angular.forEach($scope.accountListFiltered, obj => {
            if (!obj.checked) result = false;
        });
        if (result) {
            $scope.filter.checkAll = true;
        } else {
            $scope.filter.checkAll = false;
        }
    };

    // this will dismiss the window.
    $scope.cancel = function () {
        // if ($scope.options.showAccountsWarning) {
        //    $scope.options.showAccountsWarning = false;
        //    return;
        // }
        $modalInstance.dismiss();
    };

    $scope.save = function () {
        if (!$scope.options.related) {
            if (!$scope.options.showCifWarning) {
                $scope.options.showAccountsWarning = !$scope.options.showAccountsWarning;
                if ($scope.options.showAccountsWarning) {
                    // Oh, no! jQuery!
                    $('.modal').scrollTop(0);
                    return;
                }
            }
        }
        addAccountsToCompany();
    };

    $scope.selectOrDeselectAll = function () {
        angular.forEach(
            $scope.filterAccounts($scope.addAccountsList, $scope.filter.accountFilter),
            account => {
                if ($scope.filter.checkAll) account.checked = true;
                else account.checked = false;
            }
        );
    };

    $scope.$watch('filter.accountFilter', (newValue, oldValue) => {
        if (oldValue !== newValue) {
            $scope.filterRelatedAccounts();
        }
    });

    // used to set the form instance.
    $scope.setForm = function (form) {
        $scope.form = form;
    };

    // we watch for form.$dirty, this is used to alert the end user that they changed something in the form.
    $scope.$watch('form.$dirty', isDirty => {
        $modalInstance.isDirty = isDirty;
    });

    $scope.$watch(
        'addAccountsList',
        newValue => {
            if (newValue !== null && newValue !== undefined) {
                let allChecked = true;
                angular.forEach($scope.addAccountsList, account => {
                    if (
                        account.checked === null ||
                        account.checked === undefined ||
                        account.checked === false
                    ) {
                        allChecked = false;
                    }
                });
                $scope.checkAll = allChecked;
            }
        },
        true
    );

    function addAccountsToCompany() {
        const lookupList = { lookups: [] };

        // short term solution
        const addedAccounts = [];
        angular.forEach($scope.addAccountsList, account => {
            if (account.checked === true) {
                account.status = 'Inactive';
                addedAccounts.push(angular.copy(account));
                lookupList.lookups.push({
                    key: account.cifNumber,
                    value: `${account.number}|${account.type}`,
                });
            }
        });

        if (lookupList.lookups.length > 0) {
            companiesService
                .addAccountsToCompany($scope.companyId, lookupList, $scope.options.activate)
                .then(response => {
                    $modalInstance.close(addedAccounts);
                });
        }
    }

    $scope.loadAccountsToAdd = function () {
        if ($scope.options.related) {
            loadRelatedAccounts();
        } else {
            $scope.loadUnrelatedAccounts();
        }
    };

    $scope.loadUnrelatedAccounts = function () {
        $scope.form.$setPristine();
        $scope.addAccountsList = [];
        $scope.accountListFiltered = [];
        $scope.options.showCifWarning = false;
        $scope.options.showAccountWarning = false;
        $scope.filter = {
            checkAll: false,
            searchValue: '',
            accountFilter: '',
            searchType: $scope.unrelatedSearchTypes[0].type,
            searched: false,
            selectedCifNumber: 'Select All',
            selectedAccountType: 'Select All',
            selectedUnrelatedAccountType: 'Checking',
            selectedRelatedCompany: 'Select All',
        };
    };

    $scope.filterRelatedAccounts = function () {
        $scope.accountListFiltered = $scope.filterAccounts(
            $scope.addAccountsList,
            $scope.filter.accountFilter
        );
        $scope.accountListFiltered = $scope.accountListFiltered.filter(account => {
            if (
                $scope.filter.selectedCifNumber !== selectAll &&
                account.cifNumber !== $scope.filter.selectedCifNumber
            ) {
                return false;
            }
            if (
                $scope.filter.selectedAccountType !== selectAll &&
                account.type !== $scope.filter.selectedAccountType
            ) {
                return false;
            }
            if (
                $scope.filter.selectedRelatedCompany !== selectAll &&
                account.companyName !== $scope.filter.selectedRelatedCompany
            ) {
                return false;
            }
            return true;
        });
        $scope.filter.checkAll = false;

        // we set checked to false for all records to allow sorting to work.
        angular.forEach($scope.addAccountsList, account => {
            account.checked = false;
        });
    };

    $scope.filterUnrelatedAccounts = function () {
        $scope.accountListFiltered = $scope.filterAccounts(
            $scope.addAccountsList,
            $scope.filter.accountFilter
        );
        $scope.filter.checkAll = false;

        // we set checked to false for all records to allow sorting to work.
        angular.forEach($scope.addAccountsList, account => {
            account.checked = false;
        });
    };

    function loadRelatedAccounts() {
        $scope.form.$setPristine();
        $scope.addAccountsList = [];
        $scope.accountListFiltered = [];

        $scope.filter = {
            checkAll: false,
            searchValue: '',
            accountFilter: '',
            searchType: $scope.unrelatedSearchTypes[0].type,
            searched: false,
            selectedCifNumber: 'Select All',
            selectedAccountType: 'Select All',
            selectedUnrelatedAccountType: 'Checking',
            selectedRelatedCompany: 'Select All',
        };
        accountsService.getAvailableAccounts($scope.companyId, true, 'null').then(response => {
            $scope.addAccountsList = $filter('orderBy')(
                response,
                $scope.orderByFunction,
                $scope.reverseSort
            );
            $scope.filterRelatedAccounts();
        });
    }

    function isCIfNumberAssociated(cif) {
        for (let i = 0; i < $scope.cifNumbers.length; i++) {
            if ($scope.cifNumbers[i] === cif) {
                return true;
            }
        }
        return false;
    }

    $scope.searchUnrelatedAccounts = function () {
        const searchModel =
            $scope.filter.searchType === 'Account Number'
                ? {
                      accountNumber: $scope.filter.searchValue,
                      accountType: $scope.filter.selectedUnrelatedAccountType,
                  }
                : { cif: $scope.filter.searchValue };
        accountsService.searchUnrelatedAccounts($scope.companyId, searchModel).then(response => {
            $scope.filter.searched = true;
            $scope.options.showCifWarning =
                ($scope.filter.searchType === 'CIF' &&
                    isCIfNumberAssociated($scope.filter.searchValue)) ||
                ($scope.filter.searchType === 'Account Number' &&
                    !!response.accounts &&
                    isCIfNumberAssociated(response.accounts[0].cifNumber));
            $scope.addAccountsList = $filter('orderBy')(
                response.accounts,
                $scope.orderByFunction,
                $scope.reverseSort
            );
            $scope.filterUnrelatedAccounts();
        });
    };

    $scope.newSearch = function () {
        $scope.addAccountsList = [];
        $scope.accountListFiltered = [];
        $scope.filter = {
            checkAll: false,
            searchValue: '',
            accountFilter: '',
            searchType: $scope.unrelatedSearchTypes[0].type,
            searched: false,
        };
    };

    $scope.areAccountsChecked = function () {
        let isChecked = false;
        angular.forEach($scope.addAccountsList, account => {
            if (account.checked) {
                isChecked = true;
            }
        });
        return isChecked;
    };

    $scope.orderByFunction = function (account) {
        if ($scope.orderByField == 'number') return parseFloat(account[$scope.orderByField]);
        if ($scope.orderByField == 'coreStatus') return account.account.coreStatus;

        return account[$scope.orderByField];
    };

    function init() {
        // Get Associated Companies
        companiesService.getAssociatedCompanies(companyId).then(response => {
            $scope.associatedCompanies = response;
            $scope.cifNumbers = $filter('orderBy')(
                $scope.associatedCompanies.map(company => company.cifNumber)
            );
            $scope.cifNumbers.unshift($scope.cifNumber);
            $scope.cifNumbers.unshift('Select All');

            $scope.relatedCompanies = $filter('orderBy')(
                $scope.associatedCompanies.map(company => company.name)
            );
            $scope.relatedCompanies.unshift($scope.companyName);
            $scope.relatedCompanies.unshift('Select All');

            $scope.loadAccountsToAdd();
        });

        accountsService.getAccountTypes().then(response => {
            $scope.unrelatedAccountTypes = angular.copy(response);
            $scope.accountTypes = response;
            $scope.accountTypes.unshift('Select All');
            $scope.filter.selectedAccountType = response[0];
        });

        // set default sort order
        $scope.orderByField = 'number';
        $scope.reverseSort = false;
    }

    init();
}
