import { clone } from '@treasury/utils';

export const saveRequestBody = (institutionOptions, companyAccounts, companyFeatures) => {
    const Options = [];
    const ProductFeatureAccounts = [];
    if (institutionOptions.allowShowAchExceptions()) {
        const achEnabled = companyFeatures.getACHExceptionFeatures();
        const achKeys = Object.keys(achEnabled);
        achKeys.forEach(key => {
            Options.push({ name: key, value: achEnabled[key] });
        });
        ProductFeatureAccounts.push(companyAccounts.getAchExceptionBody());
    }

    if (institutionOptions.allowWorkCheckExceptions()) {
        const checkEnabled = companyFeatures.getCheckExceptionFeatures();
        const checkKeys = Object.keys(checkEnabled);
        checkKeys.forEach(key => {
            Options.push({ name: key, value: checkEnabled[key] });
        });
        ProductFeatureAccounts.push(companyAccounts.getCheckExceptionBody());
    }

    if (institutionOptions.allowManageAchFilter()) {
        const filtersEnabled = companyFeatures.getACHFiltersFeatures();
        const filtersKeys = Object.keys(filtersEnabled);
        filtersKeys.forEach(key => {
            Options.push({ name: key, value: filtersEnabled[key] });
        });
        const featureParticipations = clone(companyAccounts.getAchFiltersBody());
        featureParticipations.featureName = companyAccounts.keyMapping.achFilterManagement;
        ProductFeatureAccounts.push(featureParticipations);
    }

    return { ProductFeatureAccounts, Options };
};
