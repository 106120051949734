angular
    .module('backOffice')
    .controller('CustomizeElectronicDocumentsController', customizeElectronicDocumentsController);

customizeElectronicDocumentsController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'modalService',
    'companiesService',
    'configurationService',
    'toaster',
];

function customizeElectronicDocumentsController(
    $scope,
    $state,
    $stateParams,
    modalService,
    companiesService,
    configurationService,
    toaster
) {
    const CompanyId = $stateParams.id;

    const featureName = 'ElectronicDocuments';
    $scope.productFeature = {};
    $scope.goBack = goBack;
    $scope.save = save;
    $scope.setForm = setForm;
    $scope.requiredEntitlements = requiredEntitlements;
    $scope.pageTitle = pageTitle;

    $scope.documentTypes = {
        printMail: 1,
        esi: 2,
        wausau: 3,
    };

    function init() {
        if ($stateParams.productFeatureId === '') {
            $scope.productFeature.id = 0;
        } else if (
            $stateParams.productFeatureId !== undefined ||
            $stateParams.productFeatureId !== null
        ) {
            $scope.productFeature.id = $stateParams.productFeatureId;
        }

        loadCompany();
    }

    function pageTitle() {
        if ($scope.productFeature.id === 0) {
            return 'Add Electronic Documents';
        }
        return $scope.hasEntitlement('Edit Product Feature')
            ? 'Edit Electronic Documents'
            : 'View Electronic Documents';
    }

    function requiredEntitlements() {
        return $scope.productFeature.id === 0 ? 'Add Product Feature' : 'Edit Product Feature';
    }

    function setForm(form) {
        $scope.form = form;
    }

    function goBack() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel the changes you have made?',
                submit(result) {
                    cancelModal.dismiss();
                    $state.go('company', { id: CompanyId });
                },
            };
            var cancelModal = modalService.showModal({}, modalOptions);
        } else {
            $state.go('company', { id: CompanyId });
        }
    }

    function loadCompany() {
        companiesService.getCompanyName(CompanyId).then(response => {
            $scope.company = response;
            loadProductFeature();
        });
    }

    function save() {
        if ($scope.productFeature.id !== 0) {
            companiesService
                .updateProductFeature(CompanyId, $scope.productFeature)
                .then(response => {
                    toaster.save('Electronic Documents Configuration');
                    $scope.form.$setPristine();
                });
        } else if ($scope.productFeature.id === 0) {
            companiesService.saveProductFeature(CompanyId, $scope.productFeature).then(response => {
                $scope.productFeature.id = response.id;
                toaster.save('Electronic Documents Configuration');
                $scope.form.$setPristine();
            });
        }
    }

    function loadProductFeature() {
        if ($scope.productFeature.id !== 0) {
            companiesService
                .getProductPermissions(CompanyId, $scope.productFeature.id)
                .then(response => {
                    $scope.productFeature = response;
                });
        } else {
            configurationService.getProductFeatureDetailsByName(featureName).then(response => {
                $scope.productFeature = response;
                $scope.productFeature.id = 0;
            });
        }
    }

    init();
}
