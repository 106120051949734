import { clone, deepEquals, produce } from '@treasury/utils';
import { CompanyPositivePayServices } from '../../../requests/company-configuration/company-positive-pay.js';
import { companyConfigMapping } from '../api-mapping.js';

export default class CompanyFeatures {
    constructor(companyInfo) {
        this.apiRequest = CompanyPositivePayServices;
        this.companyInfo = clone(companyInfo);
        this.keyMapping = companyConfigMapping;
        this.parentFieldsMap = {
            useSubstitute: ['canEditSubstitute'],
        };
        this.childFieldsMap = {
            achFilter: ['approvers'],
            showAchExceptions: ['workAchExceptions'],
        };
        this.adminKeys = ['achFilter', 'showAchExceptions'];
    }

    getCompanyId() {
        return this.companyInfo.id;
    }

    async transformData() {
        const api = await this.apiRequest.getConfiguration(this.getCompanyId());
        const transformedValues = {};
        api.forEach(pair => {
            transformedValues[pair.name] = pair.value;
        });
        this.companyConfiguration = clone(transformedValues);
        this.initialState = clone(transformedValues);
    }

    InstitutionEnabled(key) {
        if (!key) return false;
        if (this.companyConfiguration?.[key]) return true;
        return false;
    }

    getAllFeatures() {
        return this.companyConfiguration;
    }

    getACHExceptionFeatures() {
        const work = 'PositivePay.WorkAchExceptions';
        const show = 'PositivePay.ShowAchExceptions';
        return {
            [work]: this.companyConfiguration[work],
            [show]: this.companyConfiguration[show],
        };
    }

    getCheckExceptionFeatures() {
        const check = 'PositivePay.WorkCheckExceptions';
        const issued = 'PositivePay.AllowIssuedItemActivityReview';
        const allowReturnReason = 'PositivePay.CheckException.AllowReturnReason';
        const allowCorrectionRequest = 'PositivePay.CheckException.AllowCorrectionRequest';
        return {
            [check]: this.companyConfiguration[check],
            [issued]: this.companyConfiguration[issued],
            [allowCorrectionRequest]: this.companyConfiguration[allowCorrectionRequest],
            [allowReturnReason]: this.companyConfiguration[allowReturnReason],
        };
    }

    getACHFiltersFeatures() {
        const filter = 'PositivePay.ACHFilterManagement.AllowManageAchFilters';
        const approvers = 'PositivePay.ACHFilterManagement.ApproversRequired';
        return {
            [filter]: this.companyConfiguration[filter],
            [approvers]: this.companyConfiguration[approvers],
        };
    }

    companyEnabled(key) {
        if (this.InstitutionEnabled(this.keyMapping[key])) {
            return this.isTrue(this.keyMapping[key]);
        }
        return false;
    }

    areAchExceptionsVisible() {
        return this.InstitutionEnabled(this.keyMapping.showAchExceptions);
    }

    areCheckExceptionsVisible() {
        return this.InstitutionEnabled(this.keyMapping.workCheckExceptions);
    }

    canToggleSubstituteProduct() {
        return this.companyEnabled('canEditSubstitute');
    }

    allowManageAchFilter() {
        return this.companyEnabled('achFilter');
    }

    allowWorkAchExceptions() {
        return this.companyEnabled('workAchExceptions');
    }

    allowIssuedItems() {
        return this.companyEnabled('issuedItems');
    }

    allowCorrectionRequest() {
        return this.companyEnabled('allowCorrectionRequest');
    }

    allowReturnReason() {
        return this.companyEnabled('allowReturnReason');
    }

    allowWorkCheckExceptions() {
        return this.companyEnabled('workCheckExceptions');
    }

    allowShowAchExceptions() {
        return this.companyEnabled('showAchExceptions');
    }

    allowAchFilterManagement() {
        return this.companyEnabled('allowAchFilterManagement');
    }

    allowSubstituteProduct() {
        return this.companyEnabled('useSubstitute');
    }

    isEditAllowedByParent(key) {
        const parentField = this.parentFieldsMap[key];
        return this.isTrue(this.keyMapping[parentField]);
    }

    hasParent(key) {
        if (!key) return false;
        return key in this.parentFieldsMap;
    }

    childFields(key) {
        return this.childFieldsMap[key];
    }

    isAdminFeature(key) {
        return this.adminKeys.includes(key);
    }

    toggleFeature(key) {
        // Specific logic needed to toggle ACHFilterManagement off when workAchExceptions is off
        if (key === 'workAchExceptions' && this.isTrue(this.keyMapping[key])) {
            this.set('achFilter', '0');
        }
        if (this.hasParent(key) && this.isEditAllowedByParent(key)) {
            this.changeFeatureState(key);
        }
        if (!this.hasParent(key)) this.changeFeatureState(key);
    }

    updateChildrenFieldValues(dependentFields) {
        dependentFields.forEach(field => this.set(field, '0'));
    }

    validateAdminActions(key) {
        if (this.isAdminFeature(key)) {
            this.updateChildrenFieldValues(this.childFields(key));
        }
    }

    changeFeatureState(key) {
        if (this.isTrue(this.keyMapping[key])) {
            this.set(key, '0');
            this.validateAdminActions(key);
        } else {
            this.set(key, '1');
        }
    }

    isTrue(key) {
        return this.get(key) === '1';
    }

    reset() {
        this.companyConfiguration = this.initialState;
    }

    hasChanged() {
        return !deepEquals(this.companyConfiguration, this.initialState);
    }

    get(key) {
        return this.companyConfiguration[key];
    }

    set(key, value) {
        const data = this.companyConfiguration;
        const updateConfiguration = produce(data, draft => {
            draft[this.findProperty(key)] = value;
        });

        this.companyConfiguration = clone(updateConfiguration);
    }

    getApprovers() {
        const value = this.get(this.findProperty('approvers'));
        if (value === '0') return 'None';
        return value;
    }

    updateApprovers(newValue) {
        if (newValue === undefined || newValue === null) return;
        if (newValue === 'None') {
            this.set('approvers', '0');
        } else {
            this.set('approvers', newValue.toString());
        }
    }

    async save() {
        try {
            this.updateInitialState(this.companyConfiguration);
            return this.companyConfiguration;
        } catch (e) {
            return e;
        }
    }

    updateInitialState(companyConfig) {
        this.initialState = clone(companyConfig);
    }

    findProperty(key) {
        return this.keyMapping[key];
    }
}
