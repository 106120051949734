import { ChannelUserModelDto } from '@treasury/api/bo';
import { DomainEntity } from '../../../shared';

const defaultUser: ChannelUserModelDto = {
    userId: 0,
    companyId: 0,
    userUniqueId: '',
};

export class User extends DomainEntity<ChannelUserModelDto> {
    constructor(dto: ChannelUserModelDto) {
        super(dto);
        this.userId = dto.userId;
        this.companyId = dto.companyId;
        this.userUniqueId = dto.userUniqueId;
        this.userName = dto.userName;
        this.userAlias = dto.userAlias;
        this.digitalId = dto.digitalId;
        this.companyName = dto.companyName;
        this.status = dto.status;
    }

    public userId?: number;

    public companyId?: number;

    public userUniqueId?: string;

    public userName?: string;

    public userAlias?: string;

    public digitalId?: string;

    public companyName?: string;

    public status?: string;

    public createDefault(): this {
        return new User(defaultUser) as this;
    }
}
