angular.module('backOffice').controller('EditAccountController', editAccountController);

editAccountController.$inject = [
    '$scope',
    'companiesService',
    '$modalInstance',
    'selectedAccount',
    'productList',
    'companyId',
    '$modal',
    'toaster',
    'canEdit',
];

function editAccountController(
    $scope,
    companiesService,
    $modalInstance,
    selectedAccount,
    productList,
    companyId,
    $modal,
    toaster,
    canEdit
) {
    $scope.productList = productList;
    $scope.selectedAccount = selectedAccount;
    $scope.canEdit = canEdit;
    $scope.companyId = companyId;
    $scope.quickAddEntitlements = {
        productsList: [],
        selectedProduct: [],
        usersList: [],
        selectedUsers: [],
        isUsersDisabled: true,
        permissionsList: [],
        selectedPermissions: [],
        isPermissionsDisabled: true,
    };
    $scope.isConfirmationMode = false;
    $scope.returnToEditMode = function () {
        $scope.maxDisplayUsers = 10;
        $scope.isConfirmationMode = false;
    };

    // watches to change of isConfirmationMode
    $scope.$watch('isConfirmationMode', newValue => {
        const preText = 'Edit Account';
        if (newValue === false) {
            $scope.headerText = preText;
        } else {
            $scope.headerText = `Verify ${preText}`;
        }
    });

    $scope.userEntitlements = {
        permissions: [],
    };

    $scope.maxDisplayUsers = 10;

    // used to determine when the quick add entitlements has been completed.
    $scope.isProcessed = false;

    // this will dismiss the window.
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    // this will update the company
    $scope.save = function () {
        $scope.isConfirmationMode = true;
        $scope.maxDisplayUsers = 1000;
    };

    $scope.confirm = function () {
        editAccountToCompany();
    };

    $scope.processQuickAdd = function () {
        $scope.isProcessed = false;

        // for each accountUserPermission in accountUserPermissions...
        angular.forEach($scope.userEntitlements.accountUserPermissions, accountUserPermission => {
            // if the selected account equals the selected accountUserPermission, move on.
            if ($scope.selectedAccount.id === accountUserPermission.accountId) {
                // for each selected permission...
                angular.forEach($scope.quickAddEntitlements.selectedPermissions, permission => {
                    // for each selected userPermission in userPermissions...
                    angular.forEach(accountUserPermission.userPermissions, userPermission => {
                        // if the selected permission equals the selected userPermission, move on.
                        if (permission.id === userPermission.permissionId) {
                            // add the rest of the users to the collection.
                            angular.forEach($scope.quickAddEntitlements.selectedUsers, newUser => {
                                let found = false;
                                let index = 0;
                                angular.forEach(userPermission.users, evalUser => {
                                    if (parseInt(newUser.userId) === parseInt(evalUser.userId)) {
                                        found = true;
                                    }
                                    if (!found) {
                                        index++;
                                    }
                                });
                                if (!found) {
                                    userPermission.users.push(angular.copy(newUser));
                                } else {
                                    userPermission.users[index].isDeleted = false;
                                }
                            });
                        }
                    });
                });
            }
        });
        $scope.isProcessed = true;

        // reset quickadd
        $scope.quickAddEntitlements.selectedProduct = $scope.quickAddEntitlements.productsList[0];
        $scope.quickAddEntitlements.selectedUsers = [];
        $scope.quickAddEntitlements.isUsersDisabled = true;
        $scope.quickAddEntitlements.selectedPermissions = [];
        $scope.quickAddEntitlements.isPermissionsDisabled = true;
    };

    $scope.changeAccountStatus = function (account) {
        // change status
        const newStatus = account.status === 'Inactive' ? 'Active' : 'Inactive';
        account.status = newStatus;
        $scope.form.$dirty = true;
    };

    function editAccountToCompany() {
        // save account
        if ($scope.selectedAccount.users !== null && $scope.selectedAccount.users !== undefined) {
            const tmpCollection = [];
            angular.forEach($scope.selectedAccount.users, user => {
                if (
                    user.isDeleted === null ||
                    user.isDeleted === undefined ||
                    user.isDeleted === false
                ) {
                    tmpCollection.push({
                        key: user.userId,
                        value: user.userId,
                    });
                }
            });
            $scope.selectedAccount.users = tmpCollection;
        }
        companiesService.saveAccount($scope.companyId, $scope.selectedAccount).then(response => {
            $modalInstance.close(response);
        });
    }

    $scope.determineShowManageUsers = function (users) {
        let result = false;
        if (users.length > 0) {
            angular.forEach(users, user => {
                if (!user.isDeleted) {
                    result = true;
                }
            });
        }
        return result;
    };

    $scope.determineShowAddUsers = function (users) {
        let result = true;
        if (users.length === 0) {
            result = true;
        } else {
            angular.forEach(users, user => {
                if (!user.isDeleted) {
                    result = false;
                }
            });
        }
        return result;
    };

    $scope.setDirty = function () {
        $scope.form.$dirty = true;
    };

    // used to set the form instance.
    $scope.setForm = function (form) {
        $scope.form = form;
    };

    // we watch for form.$dirty, this is used to alert the end user that they changed something in the form.
    $scope.$watch('form.$dirty', isDirty => {
        $modalInstance.isDirty = isDirty;
    });

    $scope.viewAccountUsers = function (account, overload) {
        const modalInstance = $modal.open({
            template: require('../../../../shared/userMultiSelect/userMultiSelect.html'),
            size: 'md',
            controller: 'UserMultiSelectController',
            backdrop: 'static',
            resolve: {
                selectedUserList() {
                    return angular.copy(account.users);
                },
                companyId() {
                    return $scope.companyId;
                },
                overloadAvailableUserList() {
                    let result = [];
                    if (overload === true) {
                        result = angular.copy($scope.selectedAccount.users);
                    }
                    return result;
                },
            },
        });
        modalInstance.result.then(userList => {
            account.users = angular.copy(userList.userList);
            if (userList.changed) {
                $scope.setDirty();
            }
        });
    };

    $scope.loadValuesByProduct = function () {
        const obj = $scope.quickAddEntitlements;
        obj.permissionsList = [];
        obj.isPermissionsDisabled = true;
        obj.isUsersDisabled = true;

        // remove default placeholder.
        // if (obj.productsList.length >= 2) {
        //    obj.productsList.splice(0, 1);
        // }
        angular.forEach($scope.selectedAccount.productPermissions, productPermission => {
            if (productPermission.productId === obj.selectedProduct.productId) {
                // load permissions list
                angular.forEach(productPermission.permissions, permissionType => {
                    obj.permissionsList.push({
                        permission: permissionType.permission,
                        id: permissionType.id,
                    });
                });
                const userList = [];
                angular.forEach($scope.quickAddEntitlements.usersList, user => {
                    user.isChecked = false;
                    userList.push(user);
                });
                $scope.quickAddEntitlements.usersList = userList;

                obj.isPermissionsDisabled = false;
                obj.isUsersDisabled = false;

                // populate entitlement grid
                $scope.userEntitlements = productPermission;
            }
        });
    };

    function generateQuickAddLists() {
        const obj = $scope.quickAddEntitlements;
        obj.productsList = [];
        angular.forEach($scope.selectedAccount.productPermissions, permission => {
            const productObj = {
                productName: permission.productName,
                productId: permission.productId,
            };
            obj.productsList.push(angular.copy(productObj));
        });

        // need to add palceholder value
        const placeholderObj = {
            productName: 'Select a Product',
            productId: -1,
        };
        obj.productsList.splice(0, 0, placeholderObj);
        obj.selectedProduct = placeholderObj;
    }

    function loadAccountEntitlements() {
        companiesService
            .getAccountPermissions($scope.companyId, $scope.selectedAccount.id)
            .then(response => {
                $scope.selectedAccount = response;
                generateQuickAddLists();
                loadSuperUsers();
            });
    }

    function transformToUserSummary(user) {
        return {
            id: -1,
            isDeleted: false,
            userId: user.id,
            userName: user.userName,
        };
    }

    function loadSuperUsers() {
        companiesService.getSuperUsers($scope.companyId).then(response => {
            $scope.superUsers = response;
        });
    }

    function loadAllUsers() {
        companiesService.getUsers($scope.companyId).then(response => {
            $scope.availableUserList = response;
        });
    }

    $scope.$watch(
        'selectedAccount.users',
        newValue => {
            if (newValue !== null && newValue !== undefined && newValue.length > 0) {
                const tmp = [];
                angular.forEach($scope.selectedAccount.users, selectedUser => {
                    let _user = null;
                    let index = 0;
                    angular.forEach($scope.availableUserList, user => {
                        if (
                            user.userName === selectedUser.value ||
                            user.userName === selectedUser.userName
                        ) {
                            _user = user;
                        } else if (_user === null) {
                            index++;
                        }
                    });
                    if (_user !== null) {
                        tmp.push({
                            userId: $scope.availableUserList[index].id,
                            userName: $scope.availableUserList[index].userName,
                            isDeleted: null,
                        });
                    }
                });
                if (tmp.length > 0) {
                    $scope.selectedAccount.users = tmp;
                    $scope.quickAddEntitlements.usersList = angular.copy(
                        $scope.selectedAccount.users
                    );
                    resetPermissions();
                }
            } else {
                $scope.selectedAccount.users = [];
                resetPermissions();
            }
        },
        true
    );

    function resetPermissions() {
        angular.forEach($scope.selectedAccount.productPermissions, productPermission => {
            angular.forEach(productPermission.accountUserPermissions, accountUserPermission => {
                angular.forEach(accountUserPermission.userPermissions, userPermission => {
                    angular.forEach(userPermission.users, user => {
                        let found = false;
                        angular.forEach($scope.selectedAccount.users, _user => {
                            if (_user.userName === user.userName) {
                                found = true;
                            }
                        });
                        if (found === false) {
                            user.isDeleted = true;
                        }
                    });
                });
            });
        });
    }

    function init() {
        loadAllUsers();
        loadAccountEntitlements();
    }

    init();
}
