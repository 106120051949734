import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import { XperienceService } from '@treasury/core/xperience';
import { SessionStorageService } from '@treasury/utils';
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import '../../../src/components/blocking-loader.js';

export const XperienceLoginContainerTagName = 'xperience-login';

@customElement(XperienceLoginContainerTagName)
export class XperienceLoginContainer extends LitElement {
    @InjectProperty()
    private declare readonly navigationService: NavigationService;

    @InjectProperty()
    private declare readonly xperienceService: XperienceService;

    @InjectProperty()
    private declare readonly sessionStorageService: SessionStorageService;

    private async login() {
        try {
            const loginResponse = await this.xperienceService.login();
            const { fiId, alias } = loginResponse;
            this.sessionStorageService.set('userFiId', fiId);
            this.sessionStorageService.set('bo-user', {
                fiId,
                loginId: alias,
            });
            this.navigationService.navigate('company-search');
        } catch (e) {
            console.error(e);
            this.navigationService.navigate('login');
        }
    }

    public async firstUpdated() {
        this.login();
    }

    render() {
        return html`<blocking-loader></blocking-loader>`;
    }
}
