import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('notificationService', notificationService);

notificationService.$inject = ['$resource'];

function notificationService($resource) {
    const resourceUrl = `${getBackOfficeAPIPath()}notificationConfiguration/`;

    return {
        getNotificationConfigurations,
        save,
    };

    function getNotificationConfigurations() {
        return $resource(resourceUrl).get().$promise;
    }

    function save(configuration) {
        const resource = $resource(resourceUrl, {}, { update: { method: 'PUT' } });
        return resource.update(configuration).$promise;
    }
}
