import { css } from 'lit';

export const reportContainerStyles = css`
    :host {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;
        --omega-table-background: var(--omega-white);
        --omega-dialog-width: 900px;
        --omega-field-control-max-width: 500px;
    }
    .comment-content {
        margin: 0 16px 12px;
    }
`;
