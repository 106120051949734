import { Injectable } from '@jack-henry/frontend-utils/di';
import { EntitlementsService } from '@treasury/domain/backoffice/services';
import { WirePaymentDetailService } from '@treasury/domain/backoffice/services/reports/wire-payment-detail-service';
import { WireDto } from '@treasury/domain/shared/types';

@Injectable()
export class WirePaymentDetailClient {
    // eslint-disable-next-line no-useless-constructor
    constructor(private readonly wireDetailService: WirePaymentDetailService) {}

    async UpdateExternallyProcessedFXWire(recordValues: WireDto) {
        return this.wireDetailService.updateExternallyProcessedFXWire(recordValues);
    }

    async hasEntitlement(entitlement: string) {
        return EntitlementsService.hasEntitlement(entitlement);
    }
}
