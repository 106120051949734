import { TmHttpClient } from '@treasury/core/http';
import { FiBranding } from './fi-branding.types.js';

export const getFiBranding = async () =>
    (await TmHttpClient.getInstance()).request<FiBranding>('branding/Get', { method: 'GET' });

export const updateFiBranding = async (branding: FiBranding) =>
    (await TmHttpClient.getInstance()).request<FiBranding>('branding/Update', {
        method: 'POST',
        body: branding,
    });
