import { RateQuote } from '@treasury/domain/shared/types/rate-quote';
import { FdlFieldDefinitions, FieldType } from '@treasury/FDL';
import fieldTypes from '@treasury/policy/primitives';

export const ExchangeRateFieldDefinitions: FdlFieldDefinitions<RateQuote> = {
    amexContractNumber: fieldTypes.string.with
        .label('Contract')
        .thatIs.readOnly() as FieldType<string>,
    originAmount: fieldTypes.money.with.label('USD Amount').thatIs.readOnly() as FieldType<number>,
    destinationAmount: fieldTypes.currencyNoSymbol.with
        .label((record: any) => {
            const destinationCurrencyCode = record.getField('destinationCurrencyCode');
            return `${destinationCurrencyCode} Amount`;
        })
        .thatIs.readOnly() as FieldType<number>,
    destinationCurrencyCode: fieldTypes.string as FieldType<string>,
    // eslint-disable-next-line @treasury/no-date
    valueDate: fieldTypes.date.with.label('Effective Date').thatIs.readOnly() as FieldType<Date>,
    exchangeFee: fieldTypes.money.with.label('Exchange Fee').thatIs.readOnly() as FieldType<number>,
    totalCost: fieldTypes.money.with
        .label('Total Cost of Wire')
        .thatIs.readOnly() as FieldType<number>,
    conversionRate: fieldTypes.number as FieldType<number>,
};
