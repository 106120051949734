angular.module('backOffice').controller('AddWireCompanyController', addWireCompanyController);

addWireCompanyController.$inject = [
    '$scope',
    '$modal',
    'wireCompaniesService',
    '$modalInstance',
    'productFeature',
    '$state',
    '$stateParams',
    'parentCompanyId',
    'isDli',
];

function addWireCompanyController(
    $scope,
    $modal,
    wireCompaniesService,
    $modalInstance,
    productFeature,
    $state,
    $stateParams,
    parentCompanyId,
    isDli
) {
    $scope.selectWireCompany = selectWireCompany;
    $scope.search = search;
    $scope.clearSearchCriteria = clearSearchCriteria;
    $scope.cancel = cancel;
    $scope.id = $stateParams.id;
    $scope.productFeature = productFeature;
    $scope.dynamicPopover = {
        template: 'accountsTemplate.html',
    };
    $scope.isDli = isDli;

    function search(name) {
        $scope.searchPerformed = true;
        if (name.length > 0) {
            wireCompaniesService.search(name, $scope.id, isDli).then(response => {
                $scope.wireCompanies = response;
            });
        } else {
            $scope.wireCompanies = [];
        }
    }

    function cancel() {
        $modalInstance.dismiss();
    }

    function selectWireCompany(company) {
        const modalInstance = $modal.open({
            template: require('../views/manageWireCompanyAccountsModalView.html'),
            size: 'lg',
            controller: 'ManageWireCompanyAccountsController',
            backdrop: 'static',
            resolve: {
                wireCompany() {
                    return company;
                },
                type() {
                    return 'Add';
                },
                productFeature() {
                    return $scope.productFeature;
                },
                parentCompanyId() {
                    return parentCompanyId;
                },
                isDli() {
                    return isDli;
                },
            },
        });
        modalInstance.result.then(response => {
            $modalInstance.close(response);
        });
    }

    function initializeSearchCriteria() {
        $scope.wireCompanyName = '';
    }

    function clearSearchCriteria() {
        initializeSearchCriteria();
    }
}
