angular
    .module('backOffice')
    .controller('InternalTransferUserLimitsController', internalTransferUserLimitsController);

internalTransferUserLimitsController.$inject = ['$scope', 'toaster', 'companyUsersService'];

function internalTransferUserLimitsController($scope, toaster, companyUsersService) {
    $scope.userLimits = null;
    $scope.userLimitsPristine = null;

    $scope.reset = reset;
    $scope.save = save;
    $scope.setForm = setForm;
    $scope.fieldLimits = { maximumAmount: '9999999999999.99' };

    function init() {
        loadUserLimits();
    }

    function save() {
        $scope.userLimits.ProductType = 'InternalTransfer';
        companyUsersService
            .updateProductLimit($scope.companyId, $scope.id, $scope.userLimits)
            .then(response => {
                $scope.userLimitsPristine = angular.copy($scope.userLimits);
                $scope.$parent.notifySave();
                $scope.form.$setPristine();
                toaster.save('Transfers User Limits');
            });
    }

    function reset() {
        $scope.userLimits = angular.copy($scope.userLimitsPristine);
        $scope.form.$setPristine();
    }

    function setForm(form) {
        $scope.form = form;
    }

    // Private
    function loadUserLimits() {
        companyUsersService
            .getProductLimit($scope.companyId, $scope.id, 'InternalTransfer')
            .then(response => {
                $scope.userLimits = response;
                $scope.userLimitsPristine = angular.copy($scope.userLimits);
            });
    }

    init();
}
