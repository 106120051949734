/* eslint-disable import/extensions */
import { TmHttpClient, TmHttpResponseType } from '@treasury/core/http';
import { downloadBlob } from '../../../utilities/file-handling.js';
import CheckExceptionMappings from '../../mappings/reports/check-exception-mappings.js';

export class CheckExceptionsRequests {
    static async fetchCheckExceptions({ parameters }) {
        const body = CheckExceptionMappings.mapParams(parameters);
        const http = await TmHttpClient.getInstance();
        const response = await http.request(`CheckException/Search`, {
            method: 'POST',
            body,
        });

        return {
            data: response.items,
            totalCount: response.items.length,
        };
    }

    static async downloadReport(detail) {
        const body = CheckExceptionMappings.mapParams(detail.filter);
        const http = await TmHttpClient.getInstance();

        if (detail.type.toUpperCase() === 'CSV') {
            const response = await http.request(`CheckException/Download/Csv`, {
                method: 'POST',
                body,
                responseType: TmHttpResponseType.ArrayBuffer,
            });
            const blob = new Blob([response], { type: `application/octet-stream` });
            return downloadBlob(blob, 'searchCheckException.csv');
        }
        const response = await http.request(`CheckException/Download/Pdf`, {
            method: 'POST',
            body,
            responseType: TmHttpResponseType.ArrayBuffer,
        });
        const blob = new Blob([response], { type: `application/pdf` });
        return downloadBlob(blob, 'searchCheckException.pdf');
    }

    static async getAccountsForCompanies(companyIds) {
        const http = await TmHttpClient.getInstance();
        return http.request(`PositivePayAccount/Search`, {
            method: 'POST',
            body: { CompanyUniqueIds: [companyIds] },
        });
    }

    static async getUsersForCompany(companyId) {
        return (await TmHttpClient.getInstance()).request(
            `reportFilter/users/channel?companyUniqueIds=${companyId}`,
            {
                method: 'GET',
            }
        );
    }

    static async fetchCheckImages(checkImageNumber) {
        return (await TmHttpClient.getInstance()).request(
            `checkimages/check?checkImageNumber=${encodeURIComponent(checkImageNumber)}`,
            {
                method: 'GET',
            }
        );
    }
}
