angular.module('backOffice').directive('spinner', [
    '$rootScope',
    function ($rootScope) {
        return {
            link(scope, element, attrs) {
                $rootScope.$on('loader_show', () => element.show());
                $rootScope.$on('loader_hide', () => element.hide());
            },
        };
    },
]);
