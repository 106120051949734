angular.module('backOffice').directive('download', [
    function () {
        return {
            restrict: 'A',
            replace: true,
            scope: {
                downloadPage: '=',
                filter: '=?',
            },
            template: require('./downloadTemplate.html'),
            controller: DownloadController,
            controllerAs: 'vm',
            bindToController: true,
        };
    },
]);

DownloadController.$inject = ['$scope', 'downloadService', 'FileSaver'];

function DownloadController($scope, downloadService, fileSaver) {
    const vm = this;

    vm.downloadOptions = [];
    vm.download = download;
    vm.canDownload = canDownload;
    vm.isReportEmpty = isReportEmpty;

    $scope.$watch('vm.downloadPage', onDownloadPageChange);

    function download(option) {
        const request = {
            downloadType: option.downloadType,
            filter: vm.filter,
        };

        downloadService.download(request).then(response => {
            const blob = new Blob([response.content], { type: response.mimeType });

            fileSaver.saveAs(blob, response.fileName);
        });
    }

    function canDownload() {
        return vm.downloadOptions != null && vm.downloadOptions.length > 0;
    }

    function isReportEmpty() {
        return isFilterEmpty() || isSubViewIdEmpty();
    }

    function isFilterEmpty() {
        return vm.filter.reportFilter === null;
    }

    function isSubViewIdEmpty() {
        return vm.filter.id === null;
    }

    function onDownloadPageChange(newDownloadPage) {
        if (newDownloadPage) {
            downloadService.getDownloadOptions(newDownloadPage).then(options => {
                vm.downloadOptions = options;
            });
        } else {
            vm.downloadOptions = [];
        }
    }
}
