import {
    id,
    isCustomReport,
    isFavoriteReport,
    name,
    type,
} from '@treasury/policy/backoffice-report.js';

export const fields = {
    name: name.thatIs.readOnly(),
    isCustomReport,
    isFavoriteReport,
    id,
    type,
};

export const columns = [
    {
        type: 'command',
        label: 'Report Name',
        field: 'name',
        action: 'routeToReport',
    },
    {
        label: 'Type',
        type: 'read-only',
        field: 'isCustomReport',
    },
    {
        type: 'checkbox',
        label: 'Favorite',
        field: 'isFavoriteReport',
    },
    {
        label: 'Delete Report',
        type: 'icon',
        action: 'deleteReport',
        conditional: record => {
            if (record.getField('isCustomReport')) {
                return { icon: 'trash', label: 'Delete Report', action: 'deleteReport' };
            }
            return false;
        },
    },
];

export const filters = [
    {
        field: 'isFavoriteReport',
        label: 'Favorite',
        fieldType: isFavoriteReport.with.label('Favorite').as.tag('omega-checkbox'),
        value: false,
    },
    {
        field: 'isCustomReport',
        label: 'Custom',
        fieldType: isCustomReport.with.label('Custom').as.tag('omega-checkbox'),
        value: false,
    },
];
