/* eslint-disable import/no-extraneous-dependencies */
import { exists } from '@treasury/utils';
import { css, html, LitElement, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { fontAwesome } from '../css/icons/font-awesome.js';
import './icons/omegaIconError.js';
import './icons/omegaIconSuccess.js';
import './icons/omegaIconWarningOutline.js';

const OMEGA_HELP_ICON = 'fa-question-circle';
const OMEGA_INFO_ICON = 'fa-info-circle';
const OMEGA_SUCCESS_ICON = 'fa-check-circle-o';
const OMEGA_WARNING_ICON = 'fa-exclamation-triangle';
const OMEGA_TIME_SENSITIVE = 'fa-clock-o';
const OMEGA_ERROR_ICON = 'fa-exclamation-circle';
const OMEGA_ASSERTIVE = 'assertive';
const OMEGA_POLITE = 'polite';
class OmegaAlert extends LitElement {
    static get properties() {
        return {
            type: {
                type: String,
            },
            title: {
                type: String,
            },
            posture: {
                type: String,
            },
            isVisible: {
                type: Boolean,
            },
            closeable: {
                type: Boolean,
            },
            width: {
                type: String,
            },
        };
    }

    constructor() {
        super();
        this.isVisible = false;
        this.closeable = true;
        this.posture = OMEGA_POLITE;
        this.width = '450px';
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-alert--default',
        };
    }

    getFontAwesomeClassName() {
        return {
            help: OMEGA_HELP_ICON,
            info: OMEGA_INFO_ICON,
            success: OMEGA_SUCCESS_ICON,
            warning: OMEGA_WARNING_ICON,
            'time-sensitive': OMEGA_TIME_SENSITIVE,
            error: OMEGA_ERROR_ICON,
        }[this.type || 'info'];
    }

    isDefaultPosture() {
        return this.posture !== OMEGA_ASSERTIVE;
    }

    isAssertivePosture() {
        return this.posture === OMEGA_ASSERTIVE;
    }

    setMessageType() {
        return this.type ? `${this.type.charAt(0).toUpperCase() + this.type.slice(1)}!` : '';
    }

    dismiss() {
        this.isVisible = false;
        this.dispatchEvent(new CustomEvent('close'));
    }

    updated(changedProps) {
        if (changedProps.has('isVisible') && this.isVisible && this.isTimedAlert())
            this.setAutoDismissTimer();
    }

    activate() {
        this.isVisible = true;
        if (this.isTimedAlert()) {
            this.setAutoDismissTimer();
        }
    }

    isTimedAlert() {
        /* Disabling timed alerts, but leaving in case future need */
        /* return this.posture != null && this.type === 'success'; */
        return false;
    }

    setAutoDismissTimer() {
        this.timer = setTimeout(() => {
            this.isVisible = false;
            this.dispatchEvent(new CustomEvent('close'));
        }, 5000);
        return this.timer;
    }

    handleKeyup(event) {
        if (event.key === 'Enter') {
            this.dismiss();
        }
    }

    renderAssertiveContentByType() {
        const message = this.title ?? this.setMessageType();
        if (this.type === 'success') {
            return html` <omega-icon-success class="omega-alert-icon"></omega-icon-success>
                <p class=${`omega-alert-header omega-alert-${this.posture}-${this.type}-text`}>
                    ${message}
                </p>`;
        }
        if (this.type === 'warning') {
            return html` <div class="assertive-icon-wrapper">
                <omega-icon-warning-outline class="omega-alert-icon"></omega-icon-warning-outline>
                <p class=${`omega-alert-header omega-alert-${this.posture}-${this.type}-text`}>
                    ${message}
                </p>
            </div>`;
        }
        return html`<div class="omega-alert-assertive-header">
            <i class="fa ${this.getFontAwesomeClassName()} omega-alert-icon"></i>
            <span class="omega-alert-assertive-header-text">${this.title}</span>
        </div>`;
    }

    renderAssertivePosture() {
        const alertClasses = {
            'omega-alert': true,
            'align-center': true,
            [`omega-alert-${this.posture}`]: exists(this.posture),
            [`omega-alert-${this.type}`]: exists(this.type),
        };

        return html`<div
                class=${classMap({ 'omega-alert-background': true, show: this.isVisible })}
            ></div>
            <div
                role="alert"
                class=${classMap(alertClasses)}
                aria-hidden=${!this.isVisible}
                .style=${`width: ${this.width}`}
            >
                ${this.closeable
                    ? html`<i
                          class="fa fa-times omega-alert-close-button close-assertive-alert-button"
                          @click=${this.dismiss}
                          @keyup=${event => this.handleKeyup(event)}
                          role="button"
                          aria-label="Close"
                      ></i>`
                    : nothing}
                ${this.renderAssertiveContentByType()}
                <div class="omega-assertive-alert-content">
                    <slot></slot>
                    <br />
                    <div class="omega-assertive-alert-content-link">
                        <slot name="link"></slot>
                    </div>
                </div>
                <slot name="actions" class="omega-alert-actions"></slot>
            </div>`;
    }

    renderPoliteIcon() {
        if (this.type === 'error') {
            return html`<omega-icon-error class="omega-alert-icon"></omega-icon-error>`;
        }
        // eslint-disable-next-line sonarjs/no-nested-template-literals
        return html`<i class=${`omega-alert-icon fa ${this.getFontAwesomeClassName()}`}></i>`;
    }

    renderDefaultPosture() {
        const alertClasses = {
            'omega-alert': true,
            'align-left': true,
            [`omega-alert-${this.posture}`]: exists(this.posture),
            [`omega-alert-${this.type}`]: exists(this.type),
        };

        return html`<div
            role="alert"
            class=${classMap(alertClasses)}
            aria-hidden=${!this.isVisible}
        >
            <div class="omega-alert-content">
                ${this.closeable
                    ? html`<i
                          class="fa fa-times omega-alert-close-button close-default-alert-button"
                          @click=${this.dismiss}
                          @keyup=${event => this.handleKeyup(event)}
                          role="button"
                          aria-label="Close"
                      ></i>`
                    : nothing}
                ${this.renderPoliteIcon()}
                <div class="omega-alert-text-column">
                    ${this.title
                        ? html`<div class="omega-alert-header">${this.title}</div> `
                        : nothing}
                    <p
                        class=${classMap({
                            'omega-alert-message': this.title,
                            'omega-alert-message__no-title': !this.title,
                        })}
                    >
                        <slot></slot>
                    </p>
                </div>
            </div>
        </div>`;
    }

    render() {
        if (this.isAssertivePosture()) {
            return this.renderAssertivePosture();
        }
        if (this.isDefaultPosture()) {
            return this.renderDefaultPosture();
        }
        return nothing;
    }

    static get styles() {
        return [
            fontAwesome,
            css`
                :host {
                    display: block;
                }
                .omega-alert {
                    position: relative;
                    padding: 10px;
                    margin: var(--omega-alert-margin, 10px 0);
                    box-shadow: 0 0 10px 0 rgba(57, 57, 57, 0.5);
                    border-radius: 5px;
                    z-index: 100;
                    opacity: 1;
                    transition: all 0.45s cubic-bezier(0, 0.76, 0.35, 1.04);
                    background-color: var(--omega-white);
                }

                .omega-alert[aria-hidden='true'] {
                    height: 0;
                    padding: 0;
                    margin: 0;
                    opacity: 0;
                    z-index: -100;
                    transition: all 0.35s cubic-bezier(0.47, 0.41, 0, 1.78);
                }

                .omega-alert-background {
                    position: fixed;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background-color: rgba(151, 151, 151, 0.3);
                    opacity: 0;
                    z-index: -99;
                    display: none;
                    height: 100%;
                    width: 100%;
                }

                .omega-alert-background.show {
                    display: block;
                    opacity: 1;
                    z-index: 99;
                }

                .omega-alert-content {
                    display: flex;
                }

                .omega-assertive-alert-content {
                    margin-top: 10px;
                    font-size: 13px;
                    margin-left: 34px;
                }

                .omega-assertive-alert-content-link {
                    margin-top: 12px;
                }

                .omega-assertive-alert-content-link ::slotted(*) {
                    color: var(--omega-primary);
                }

                .omega-alert-icon {
                    margin-right: 8px;
                    font-size: 22px;
                }

                .omega-alert-assertive-icon {
                    font-size: 60px;
                    margin-bottom: 5px;
                }

                p {
                    padding: 0;
                    margin: 0;
                }

                .omega-alert-info {
                    border: var(--omega-primary-border);
                }

                .omega-alert.omega-alert-success {
                    border: var(--omega-success-border);
                }

                .omega-alert.omega-alert-warning {
                    border: var(--omega-warning-border);
                }

                .omega-alert.omega-alert-success.align-left,
                .omega-alert.omega-alert-warning.align-left {
                    text-align: left;
                }

                .omega-alert.omega-alert-success.align-right,
                .omega-alert.omega-alert-warning.align-right {
                    text-align: right;
                }

                .omega-alert.omega-alert-success.align-center,
                .omega-alert.omega-alert-warning.align-center {
                    text-align: center;
                }

                .omega-alert-success .omega-alert-icon {
                    color: var(--omega-success);
                }
                .omega-alert-success .omega-assertive-alert-content,
                .omega-alert-warning .omega-assertive-alert-content {
                    margin-left: 0;
                    margin-bottom: 40px;
                }

                .omega-alert-success omega-icon-success {
                    width: 75px;
                    height: 75px;
                    margin: 10px auto 0 auto;
                }

                .omega-alert-header {
                    color: var(--omega-text-header);
                    font-size: 16px;
                    font-weight: 500;
                }

                .omega-alert-header.omega-alert-assertive-success-text {
                    color: var(--omega-text-success);
                }

                .omega-alert-warning {
                    border: var(--omega-warning-border);
                }

                .omega-alert-warning .omega-alert-icon {
                    color: var(--omega-warning);
                    fill: var(--omega-warning);
                    height: 28px;
                    width: 28px;
                    display: inline-block;
                }

                .omega-alert-assertive.omega-alert-warning .omega-alert-icon {
                    width: 75px;
                    height: 75px;
                    margin: 10px auto 0 auto;
                }

                .omega-alert-assertive-warning-text {
                    color: var(--omega-warning);
                }

                .omega-alert-error {
                    border: var(--omega-error-border);
                }

                .omega-alert-error .omega-alert-icon {
                    color: var(--omega-error);
                }

                .omega-alert-assertive-error-text {
                    color: var(--omega-text-error);
                }

                .omega-alert-time-sensitive {
                    border: var(--omega-time-sensitive-border);
                }

                .omega-alert-time-sensitive .omega-alert-icon {
                    color: var(--omega-text-time-sensitive);
                }

                .omega-alert-assertive-time-sensitive-text {
                    color: var(--omega-text-time-sensitive);
                }

                .omega-alert-help {
                    border: var(--omega-help-border);
                }

                .omega-alert-help .omega-alert-icon {
                    color: var(--omega-text-help);
                }

                .omega-alert-assertive-help-text {
                    color: var(--omega-text-help);
                }

                .omega-alert-assertive .omega-alert-header {
                    font-size: 28px;
                    margin: 12px 0;
                }

                .omega-alert-message__no-title {
                    font-size: 14px;
                    font-weight: 400;
                    color: var(--omega-text-header);
                    line-height: 22px;
                }

                .omega-alert-message {
                    font-size: 13px;
                    font-weight: 400;
                    margin-top: 6px;
                    color: var(--omega-text-default);
                }

                .omega-alert-assertive {
                    position: fixed;
                    z-index: 1100;
                    top: calc(35%);
                    left: calc(40% - 87.5px);
                    width: 400px;
                    min-height: 125px;
                    padding: 15px;
                    background-color: white;
                    box-shadow: 0 0 10px 0 rgba(57, 57, 57, 0.5);
                    color: var(--omega-text-default);
                    font-size: var(--omega-p);
                    text-align: left;
                    border-radius: 3px;
                }

                .omega-alert-assertive-header {
                    width: 100%;
                    text-align: left;
                    display: flex;
                    align-items: center;
                }

                .assertive-icon-wrapper {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                }

                .omega-alert-assertive-header-text {
                    font-size: 16px;
                    font-weight: 500;
                }

                .omega-alert-close-button {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    font-weight: var(--omega-bold);
                    color: var(--omega-text-tertiary);
                    cursor: pointer;
                }

                .close-assertive-alert-button {
                    right: 10px;
                    top: 5px;
                    font-size: var(--omega-h3);
                }

                .close-default-alert-button {
                    right: 5px;
                    top: 3px;
                    font-size: var(--omega-label);
                }

                .omega-alert-close-button i {
                    color: var(--omega-text-tertiary);
                }

                .omega-alert-actions {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    display: flex;
                    align-content: flex-end;
                }
                omega-icon-error {
                    margin-top: -5px;
                }
            `,
        ];
    }
}

customElements.define('omega-alert', OmegaAlert);
export default OmegaAlert;
