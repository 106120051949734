angular
    .module('backOffice')
    .controller('IntegratedServiceEntitlementsController', integratedServiceEntitlementsController);

integratedServiceEntitlementsController.$inject = [
    '$scope',
    '$window',
    '$filter',
    'companyUsersService',
    'toaster',
];

function integratedServiceEntitlementsController(
    $scope,
    $window,
    $filter,
    companyUsersService,
    toaster
) {
    $scope.isSuperUser = $scope.$parent.userHeader.isSuperUser;
    $scope.entitlements = null;
    $scope.entitlementsPristine = null;
    $scope.setForm = setForm;
    $scope.reset = reset;
    $scope.save = save;

    function init() {
        loadEntitlements();
    }

    function setForm(form) {
        $scope.form = form;
    }

    function save() {
        const updates = companyUsersService.buildUpdateProductEntitlementsRequest(
            $scope.entitlements,
            $scope.entitlementsPristine
        );

        companyUsersService
            .updateProductEntitlements($scope.companyId, $scope.id, updates)
            .then(response => {
                $scope.entitlementsPristine = $window.angular.copy($scope.entitlements);
                $scope.$parent.notifySave();
                $scope.form.$setPristine();
                toaster.save('integrated Service Entitlements');
            });
    }
    function reset() {
        $scope.entitlements = angular.copy($scope.entitlementsPristine);
        $scope.form.$setPristine();
    }

    // Private
    function loadEntitlements() {
        companyUsersService
            .getProductEntitlements($scope.companyId, $scope.id, 'IntegratedServices')
            .then(response => {
                $scope.entitlements = response;
                if (response.accountProductClaims) {
                    for (let i = 0; i < response.accountProductClaims.length; i++) {
                        $scope.columnValues.push(false);
                    }
                }
                $scope.entitlementsPristine = $window.angular.copy($scope.entitlements);
            });
    }

    init();
}
