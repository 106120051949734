/* eslint-disable import/extensions */
import { TmHttpClient } from '@treasury/core/http';

export default class FeatureFlagRequests {
    static async getFeatureFlags() {
        const http = await TmHttpClient.getInstance();
        const entitlements = await http.request(`entitlements`, {
            method: 'GET',
            maxAgeInSeconds: 50000,
        });
        return entitlements.filter(value => value.name.toLowerCase().trim().includes('feature'));
    }

    static async getInstitutions() {
        return (await TmHttpClient.getInstance()).request(`FeatureFlags/AllInstitutions`, {
            method: 'POST',
            maxAgeInSeconds: 50000,
            body: {
                includeInactive: false,
                disableCache: false,
            },
        });
    }

    static async getFeatureFlagInstitutions(body) {
        return (await TmHttpClient.getInstance()).request(`FeatureFlags/AllInstitutions`, {
            method: 'POST',
            body,
        });
    }

    static async fetchFeatureFlags(body) {
        const { page, pageSize } = body;
        return (await TmHttpClient.getInstance()).request(`FeatureFlags/AllFeatureFlags`, {
            method: 'POST',
            body: {
                disableCache: false,
                institutionRecordId: '',
                DataApplicationNameTypes: [],
                pagingParameters: {
                    pageSize,
                    pagNumber: page,
                    orderBys: [],
                    searchBys: [],
                },
            },
        });
    }

    static async addFeatureFlag(body) {
        return (await TmHttpClient.getInstance()).request(`FeatureFlags/SaveFeatureFlags`, {
            method: 'POST',
            body,
        });
    }

    // eslint-disable-next-line sonarjs/no-identical-functions
    static async updateFeatureFlag(body) {
        return (await TmHttpClient.getInstance()).request(`FeatureFlags/SaveFeatureFlags`, {
            method: 'POST',
            body,
        });
    }

    static async deleteFeatureFlag(id) {
        return (await TmHttpClient.getInstance()).request(`DeleteFeatureFlagKeys/${id}`, {
            method: 'DELETE',
        });
    }
}
