export const institutionAPIMap = {
    achFilter: 'PositivePay.ACHFilterManagement.AllowManageAchFilters',
    approvers: 'PositivePay.ACHFilterManagement.ApproversRequired',
    issuedItems: 'PositivePay.AllowIssuedItemActivityReview',
    allowSubstitute: 'PositivePay.AllowSubstituteProduct',
    substitute: 'PositivePay.CanAllowSubstituteProduct',
    showAchExceptions: 'PositivePay.ShowAchExceptions',
    workAchExceptions: 'PositivePay.WorkAchExceptions',
    workCheckExceptions: 'PositivePay.WorkCheckExceptions',
    allowReturnReason: 'PositivePay.CheckException.AllowReturnReason',
    allowCorrectionRequest: 'PositivePay.CheckException.AllowCorrectionRequest',
};
