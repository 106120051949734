import { ChildSupportRecipientModelDto } from '@treasury/api/bo';
import { AchRecipient } from '.';
import { defaultAchChildSupportRecipientModelDto } from './data/ach.data';

export class AchChildSupportRecipient extends AchRecipient<ChildSupportRecipientModelDto> {
    public createDefault(): this {
        return new AchChildSupportRecipient(defaultAchChildSupportRecipientModelDto) as this;
    }

    public get details() {
        return this.dto.addendas && this.dto.addendas.length ? this.dto.addendas[0] : undefined;
    }

    public get caseIdentifier() {
        return this.details?.caseIdentifier ?? '';
    }

    public get employmentTermination() {
        return this.details?.employmentTermination ?? false;
    }

    public get fipsCode() {
        return this.details?.fipsCode ?? '';
    }

    public get medicalSupport() {
        return this.details?.medicalSupport ?? false;
    }

    public get nonCustodialParentFirstName() {
        return this.details?.nonCustodialParentFirstName ?? '';
    }

    public get nonCustodialParentLastName() {
        return this.details?.nonCustodialParentLastName ?? '';
    }

    public get nonCustodialParentSsn() {
        return this.details?.nonCustodialParentSsn ?? '';
    }

    public get payDate() {
        return this.details?.payDate ?? '';
    }
}
