import { LitElement, html, css } from 'lit';
import '../components/omega-table.js';
import '../components/omega-button.js';
import '../components/omega-button-bar.js';

// Displays two-dimensional data as an editable table and provides for basic load and save behavior
export default class OmegaTableEdit extends LitElement {
    static get properties() {
        return {
            title: String,
            columns: Array,
            recordset: Object,
            loading: { type: Boolean, attribute: false },
        };
    }

    constructor() {
        super();
        this.loading = true;
        this.columns = [];
        this.recordset = null;
    }

    async connectedCallback() {
        super.connectedCallback();
        this.loadTable();
    }

    async loadTable() {
        if (!this.recordset) return;
        await this.recordset.requestHardUpdate();
        this.loading = false;
    }

    resetTable() {
        this.recordset.reset();
    }

    isSaveDisabled() {
        return this.loading || !this.recordset || this.recordset.totalCount == 0;
    }

    handleSaveClick() {
        this.loading = true;
        this.dispatchEvent(
            new CustomEvent('save', {
                detail: { reloadTable: () => this.loadTable() },
                bubbles: false,
                composed: true,
            })
        );
    }

    renderDetail() {
        if (this.loading) {
            return html`<omega-progress></omega-progress>`;
        }
        return html`
            <omega-table
                .recordset=${this.recordset}
                .columnDefinitions=${this.columns}
                .rowsPerPage=${this.recordset.pageSize}
            >
            </omega-table>
        `;
    }

    render() {
        return html`
            <div class="view-header-left">${this.title}</div>
            <div class="jh-panel">
                <div class="jh-header"><br /></div>
                <div class="jh-detail">${this.renderDetail()}</div>
                <div class="jh-footer">
                    <omega-button-bar>
                        <omega-button
                            type="primary"
                            @click=${this.handleSaveClick}
                            ?disabled=${this.isSaveDisabled()}
                        >
                            Save
                        </omega-button>
                        <omega-button type="secondary" @click=${this.resetTable}>
                            Reset
                        </omega-button>
                    </omega-button-bar>
                </div>
            </div>
        `;
    }

    // REVIEW: should styles for BO view header and footer go in a base class?
    static get styles() {
        return css`
            .view-header-left {
                color: #505050;
                display: flex;
                font-size: 27px;
                font-weight: 400;
                flex: 0 0 auto;
                height: 40px;
                line-height: 40px;
                margin-top: 10px;
                margin-bottom: 10px;
            }
            .jh-panel {
                margin-top: 10px;
                margin-bottom: 10px;
                background-color: var(--omega-white);
                clear: both;
                border-radius: 2px;
                box-shadow: 0 2px 4px 0 rgba(209, 209, 209, 0.5);
            }
            .jh-panel .jh-header {
                position: relative;
                width: 100%;
                min-height: 30px;
                flex: 0 0 auto;
                background-color: var(--omega-white);
            }
            .jh-panel .jh-detail {
                position: relative;
                width: 100%;
                border-top: 1px solid #ddd;
                flex: 0 0 auto;
            }
            .jh-panel .jh-footer {
                position: relative;
                width: 100%;
                float: left;
                border-top: 1px solid #ddd;
                flex: 0 0 auto;
                background-color: var(--omega-white);
            }
        `;
    }
}

customElements.define('omega-table-edit', OmegaTableEdit);
