angular
    .module('backOffice')
    .controller('ManageWireCompanyAccountsController', manageWireCompanyAccountsController);

manageWireCompanyAccountsController.$inject = [
    '$scope',
    '$modal',
    'wireCompany',
    'wireCompaniesService',
    'type',
    'productFeature',
    'wireService',
    '$filter',
    '$modalInstance',
    '$stateParams',
    'parentCompanyId',
    'isDli',
];

function manageWireCompanyAccountsController(
    $scope,
    $modal,
    wireCompany,
    wireCompaniesService,
    type,
    productFeature,
    wireService,
    $filter,
    $modalInstance,
    $stateParams,
    parentCompanyId,
    isDli
) {
    $scope.save = save;
    $scope.wireCompany = wireCompany;
    $scope.wireCompanyAccounts = [];
    $scope.cifNumber = $stateParams.cifNumber;
    $scope.selectOrDeselectAllAccounts = selectOrDeselectAllAccounts;
    $scope.isConfirmationMode = false;
    $scope.updatedList = [];
    $scope.selectedList = [];
    $scope.confirm = confirm;
    $scope.type = type;
    $scope.filter = { selectAll: false };
    $scope.cifNumbers = ['SelectAll'];
    $scope.productFeature = productFeature;
    $scope.cancel = cancel;
    $scope.updateList = updateList;
    $scope.updateFilteredList = updateFilteredList;
    $scope.productFeatureType = $stateParams.type;
    $scope.checkDisability = checkDisability;
    $scope.parentCompanyId = parentCompanyId;
    $scope.IsAccountCheckDisabled = IsAccountCheckDisabled;
    $scope.isDli = isDli;
    init();

    function IsAccountCheckDisabled(account) {
        return !(account.isCompanyAccount && account.isAccountActive);
    }

    function init() {
        if ($scope.type === 'Add') {
            wireCompaniesService
                .getCompanyAccounts($scope.wireCompany.id, parentCompanyId, isDli)
                .then(response => {
                    $scope.wireCompany = response;
                    $scope.wireCompanyAccounts = response.accounts;
                    angular.forEach($scope.wireCompanyAccounts, item => {
                        item.isSelected = false;
                    });
                    $scope.filteredWireCompanyAccounts = angular.copy($scope.wireCompanyAccounts);
                    getCifNumbersList();
                });
        } else if ($scope.type === 'Edit') {
            wireService
                .getAccounts($scope.productFeature.id, $scope.wireCompany.id, isDli)
                .then(response => {
                    $scope.wireCompany = response;
                    $scope.wireCompanyAccounts = response.accounts;
                    angular.forEach($scope.wireCompanyAccounts, item => {
                        if (item.isSelected) {
                            item.isOriginallySelected = true;
                        } else if (!item.isSelected) {
                            item.isOriginallySelected = false;
                        }
                    });
                    $scope.filteredWireCompanyAccounts = angular.copy($scope.wireCompanyAccounts);
                    getCifNumbersList();
                });
        }
    }
    // we watch for form.$dirty, this is used to alert the end user that they changed something in the form.
    $scope.$watch('form.$dirty', isDirty => {
        $modalInstance.isDirty = isDirty;
    });

    $scope.$watch('accountsSearchText', () => {
        updateFilteredList();
    });

    $scope.$watch('selectedCifNumber', () => {
        updateFilteredList();
    });

    function checkDisability() {
        if ($scope.updatedList.length > 0) {
            return false;
        }
        return true;
    }

    function save() {
        $scope.isConfirmationMode = true;
        if ($scope.updatedList.length > 0) {
            angular.forEach($scope.updatedList, item => {
                if (item.isSelected) {
                    item.isEnabled = true;
                    item.isDisabled = false;
                } else if (!item.isSelected && item.isOriginallySelected) {
                    item.isDisabled = true;
                    item.isEnabled = false;
                }
            });
            $scope.selectedList = angular.copy($scope.updatedList);
        } else {
            $scope.selectedList = [];
        }

        angular.forEach($scope.wireCompanyAccounts, wireAccount => {
            angular.forEach($scope.updatedList, account => {
                if (account.id === wireAccount.id) {
                    wireAccount = account;
                }
            });
        });
        $scope.wireCompany.wireCompanyAccounts = $scope.wireCompanyAccounts;
    }

    function updateFilteredList() {
        let _filteredList;
        if ($scope.selectedCifNumber === 'SelectAll') {
            var expression = function (value, index) {
                const fields = ['number', 'cifNumber', 'transactionLimit', 'dailyLimit'];
                for (const i in fields) {
                    const field = fields[i];
                    const query = {};
                    query[field] = $scope.accountsSearchText;
                    const r = $filter('filter')([value], query);
                    if (r.length > 0) return true;
                }
                return false;
            };
            _filteredList = $filter('filter')($scope.wireCompanyAccounts, expression);
        } else {
            _filteredList = $filter('filter')(
                $scope.wireCompanyAccounts,
                $scope.selectedCifNumber,
                true
            );
            var expression = function (value, index) {
                const fields = ['number', 'cifNumber', 'transactionLimit', 'dailyLimit'];
                for (const i in fields) {
                    const field = fields[i];
                    const query = {};
                    query[field] = $scope.accountsSearchText;
                    const r = $filter('filter')([value], query);
                    if (r.length > 0) return true;
                }
                return false;
            };
            _filteredList = $filter('filter')(_filteredList, expression);
        }
        $scope.filteredWireCompanyAccounts = _filteredList;

        let _allSelected = true;
        angular.forEach($scope.filteredWireCompanyAccounts, _account => {
            if (!_account.isSelected) {
                _allSelected = false;
            }
        });
        $scope.filter.selectAll = _allSelected;
    }

    function cancel() {
        if ($scope.type === 'Add' && $scope.isConfirmationMode === false) {
            $modalInstance.close();
        } else if ($scope.isConfirmationMode === true) {
            $scope.isConfirmationMode = false;
        } else {
            $modalInstance.dismiss();
        }
    }

    function selectOrDeselectAllAccounts() {
        let filteredList;
        if ($scope.selectedCifNumber === 'SelectAll') {
            filteredList = $filter('filter')($scope.wireCompanyAccounts, $scope.accountsSearchText);
        } else {
            filteredList = $filter('filter')(
                $scope.wireCompanyAccounts,
                $scope.selectedCifNumber,
                true
            );
            filteredList = $filter('filter')(filteredList, $scope.accountsSearchText);
        }
        $scope.filteredWireCompanyAccounts = filteredList;
        angular.forEach($scope.filteredWireCompanyAccounts, account => {
            if (account.isCompanyAccount && account.isAccountActive) {
                account.isSelected = $scope.filter.selectAll;
            }
        });
        angular.forEach($scope.filteredWireCompanyAccounts, account => {
            if (
                account.isSelected &&
                !account.isOriginallySelected &&
                $scope.updatedList.indexOf(account) == -1
            ) {
                $scope.updatedList.push(account);
            } else if (
                !account.isSelected &&
                account.isOriginallySelected &&
                $scope.updatedList.indexOf(account) == -1
            ) {
                account.isDeleted = true;
                $scope.updatedList.push(account);
            } else if (
                account.isSelected &&
                account.isOriginallySelected &&
                $scope.updatedList.indexOf(account) !== -1
            ) {
                $scope.updatedList.splice($scope.updatedList.indexOf(account), 1);
            } else if (
                account.isSelected === false &&
                !account.isOriginallySelected &&
                $scope.updatedList.indexOf(account) !== -1
            ) {
                $scope.updatedList.splice($scope.updatedList.indexOf(account), 1);
            }
        });
    }

    function getCifNumbersList() {
        angular.forEach($scope.wireCompanyAccounts, item => {
            $scope.cifNumbers.push(item.cifNumber);
            $scope.selectedCifNumber = $scope.cifNumbers[0];
        });
    }

    function confirm() {
        if ($scope.type === 'Add') {
            wireService
                .saveWireCompany($scope.productFeature.id, $scope.wireCompany, isDli)
                .then(response => {
                    $modalInstance.close(response);
                });
        } else if ($scope.type === 'Edit') {
            wireService
                .updateWireCompany($scope.productFeature.id, $scope.wireCompany, isDli)
                .then(response => {
                    $modalInstance.close(response);
                });
        }
    }

    function updateList(account) {
        if (
            account.isSelected === true &&
            !account.isOriginallySelected &&
            $scope.updatedList.indexOf(account) == -1
        ) {
            $scope.updatedList.push(account);
        } else if (
            account.isSelected === false &&
            account.isOriginallySelected &&
            $scope.updatedList.indexOf(account) == -1
        ) {
            account.isDeleted = true;
            $scope.updatedList.push(account);
        } else if (account.isSelected === false && !account.isOriginallySelected) {
            $scope.updatedList.splice($scope.updatedList.indexOf(account), 1);
        } else if (
            account.isSelected &&
            account.isOriginallySelected &&
            $scope.updatedList.indexOf(account) !== -1
        ) {
            $scope.updatedList.splice($scope.updatedList.indexOf(account), 1);
        }
        let allSelected = true;
        angular.forEach($scope.filteredWireCompanyAccounts, _account => {
            if (!_account.isSelected) {
                allSelected = false;
            }
        });
        $scope.filter.selectAll = allSelected;
    }
}
