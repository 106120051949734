angular
    .module('backOffice')
    .controller('ChallengeGroupListController', challengeGroupListController);

challengeGroupListController.$inject = [
    '$scope',
    '$state',
    'challengeGroupsService',
    '$filter',
    'entitlementsService',
];

function challengeGroupListController(
    $scope,
    $state,
    challengeGroupsService,
    $filter,
    entitlementsService
) {
    $scope.edit = edit;
    $scope.view = view;
    $scope.searchText = '';
    $scope.createGroup = createGroup;
    $scope.hasEditEntitlement = hasEditEntitlement;
    $scope.hasAddEntitlement = hasAddEntitlement;
    $scope.editOrViewText = hasEditEntitlement() ? 'Edit' : 'View';

    function loadGroups() {
        challengeGroupsService.getGroups().then(response => {
            $scope.groupList = response;
            $scope.filteredList = $filter('filter')($scope.groupList, $scope.searchText);
        });
    }

    function init() {
        loadGroups();
    }

    init();

    function hasEditEntitlement() {
        return entitlementsService.hasAnyEntitlement('Edit Challenge Point Profile', 'Switch FI');
    }

    function hasAddEntitlement() {
        return entitlementsService.hasAnyEntitlement('Add Challenge Point Profile');
    }

    function edit(group) {
        $state.go('challenge-group-detail', { id: group.id, type: 'edit' });
    }

    function createGroup() {
        $state.go('challenge-group-detail', { id: 0, type: 'edit' });
    }

    function view(group) {
        $state.go('challenge-group-detail', { id: group.id, type: 'view' });
    }

    $scope.$watch('searchText', () => {
        const expression = function (value) {
            const fields = ['name', 'description'];
            for (const i in fields) {
                const field = fields[i];
                const query = {};
                query[field] = $scope.searchText;
                const r = $filter('filter')([value], query);
                if (r.length > 0) return true;
            }
            return false;
        };
        $scope.filteredList = $filter('filter')($scope.groupList, expression);
    });
}
