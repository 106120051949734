angular.module('backOffice').controller('DetailContainerController', detailContainerController);

detailContainerController.$inject = [
    '$scope',
    'companiesService',
    '$state',
    '$stateParams',
    '$modal',
    'toaster',
    'challengeGroupsService',
    'companyTypes',
    'secureChallengeService',
    'spinnerService',
];

function detailContainerController(
    $scope,
    companiesService,
    $state,
    $stateParams,
    $modal,
    toaster,
    challengeGroupsService,
    companyTypes,
    secureChallengeService,
    spinnerService
) {
    const $ctrl = this;

    $ctrl.isBlank = isBlank;
    $ctrl.cityStateZip = cityStateZip;

    $scope.id = $stateParams.id;
    $scope.cifNumber = $stateParams.cifNumber;
    $scope.companyDetails = {};
    $scope.companyStatus = { status: null };
    $scope.showCompanyIdEdit = false;
    $scope.associatedCompany = { isCollapsed: true };
    $scope.updateAuthenticationStatus = updateAuthenticationStatus;
    $scope.isEditingGroup = false;
    $scope.selectModel = {};
    $scope.requireConfirmation = {};
    $scope.refreshCompany = refreshCompany;
    $scope.refreshAssociated = refreshAssociated;

    $scope.isWidthSmall = function (widthValue) {
        return widthValue;
    };

    $scope.navigate = function (destination) {
        $state.go(destination);
    };

    $scope.navigateToBillingOptions = function () {
        $state.go('account-analysis-configuration-company',
        {companyId: $scope.id, companyName: $scope.companyDetails.name});
    }

    $scope.isValidCompanyId = function (tempCompanyId) {
        if (tempCompanyId !== null && tempCompanyId !== undefined && tempCompanyId.length > 0) {
            return true;
        }
        return false;
    };

    $scope.addAssociatedCompany = function () {
        const modalInstance = $modal.open({
            template: require('../views/addAssociatedCompanyModalView.html'),
            size: 'md',
            controller: 'AddAssociatedCompanyController',
            backdrop: 'static',
            resolve: {
                companyId() {
                    return $scope.companyDetails.id;
                },
            },
        });
        modalInstance.result.then(newAssociatedCompany => {
            $scope.associatedCompanies.push(newAssociatedCompany);
        });
    };

    $scope.changeCompanyStatus = function () {
        const modalInstance = $modal.open({
            template: require('../views/manageCompanyStatus.html'),
            size: 'md',
            controller: 'ManageCompanyStatusController',
            backdrop: 'static',
            resolve: {
                companyDetails() {
                    return $scope.companyDetails;
                },
            },
        });
        modalInstance.result.then(selectedCompanyStatus => {
            $scope.companyStatus.status = selectedCompanyStatus.status;
            $scope.companyStatus.comments = selectedCompanyStatus.comments;
        });
    };

    $scope.showCompanyComments = function () {
        $modal.open({
            template: require('../views/showCompanyComments.html'),
            size: 'md',
            controller: 'ShowCompanyCommentsController',
            backdrop: 'static',
            resolve: {
                comments() {
                    return $scope.companyStatus.comments;
                },
            },
        });
    };

    $scope.onboardCompany = function () {
        const modalInstance = $modal.open({
            template: require('../views/onboardCompanyModalView.html'),
            size: 'lg',
            controller: 'OnboardCompanyController',
            backdrop: 'static',
        });

        modalInstance.result.then(companyId => {
            companiesService
                .onboardCompany(
                    $scope.cifNumber,
                    $scope.companyDetails.name,
                    companyId,
                    $scope.companyDetails.taxNumber
                )
                .then(response => {
                    $scope.companyDetails = response;
                    $scope.companyStatus.status = $scope.companyDetails.status;
                    $scope.companyStatus.comments = $scope.companyDetails.comments;
                    $state.go('.', { id: $scope.companyDetails.id });
                    loadAddressMapLocation();
                    determineIsCompanyEdit();
                });
        });
    };

    $scope.clearTempCompanyId = function () {
        $scope.companyDetails.tempCompanyId = null;
        $scope.showCompanyIdEdit = false;
    };

    $scope.saveTempCompanyId = function () {
        $scope.companyDetails.companyId = $scope.companyDetails.tempCompanyId;
        companiesService.updateCompanyId($scope.companyDetails).then(
            response => {
                $scope.companyDetails.companyId = response.companyId;
                $scope.showCompanyIdEdit = false;
                toaster.save('Company ID');
            },
            response => {
                $scope.companyDetails.companyId = null;
                $scope.companyDetails.tempCompanyId = null;
            }
        );
    };

    $scope.editCompanyId = function () {
        $scope.showCompanyIdEdit = true;
        $scope.companyDetails.tempCompanyId = $scope.companyDetails.companyId;
    };

    $scope.editChallengeGroup = function () {
        $scope.isEditingGroup = true;
    };

    $scope.cancelChallengeGroupEdit = function () {
        $scope.isEditingGroup = false;
        $scope.selectModel.challengeGroup =
            $scope.companyDetails.companyAuthenticationSettings.challengeGroup;
    };

    $scope.updateChallengeGroup = function () {
        if (
            $scope.selectModel.challengeGroup != null &&
            ($scope.companyDetails.companyAuthenticationSettings == null ||
                $scope.companyDetails.companyAuthenticationSettings.challengeGroup == null ||
                $scope.selectModel.challengeGroup.id !==
                    $scope.companyDetails.companyAuthenticationSettings.challengeGroup.id)
        ) {
            secureChallengeService
                .updateChallengeGroup(
                    $scope.companyDetails.id,
                    $scope.selectModel.challengeGroup.id
                )
                .then(response => {
                    $scope.companyDetails.companyAuthenticationSettings.isActive =
                        response.isActive;
                    $scope.companyDetails.companyAuthenticationSettings.challengeGroup =
                        response.challengeGroup;
                    $scope.companyDetails.companyAuthenticationSettings.challengeMethodType =
                        response.challengeMethodType;
                    $scope.requireConfirmation.challengeMethodType = response.challengeMethodType;
                    $scope.selectModel.challengeGroup = response.challengeGroup;
                    $scope.isEditingGroup = false;
                    toaster.save('Authentication profile');
                });
        } else {
            $scope.isEditingGroup = false;
        }
    };

    $scope.updateAuthenticationMethod = function () {
        $modal
            .open({
                template: require('../views/changeAuthenticationMethodModalView.html'),
                controller: 'ChangeAuthenticationMethodModalController',
                backdrop: 'static',
            })
            .result.then(selection => {
                if (selection === 'confirm') {
                    secureChallengeService
                        .updateChallengeMethod(
                            $scope.id,
                            $scope.requireConfirmation.challengeMethodType
                        )
                        .then(
                            () => {
                                // success
                                $scope.companyDetails.companyAuthenticationSettings.challengeMethodType =
                                    $scope.requireConfirmation.challengeMethodType;
                                toaster.save('Authentication method');
                            },
                            () => {
                                // error
                                $scope.requireConfirmation.challengeMethodType =
                                    $scope.companyDetails.companyAuthenticationSettings.challengeMethodType;
                            }
                        );
                } else {
                    $scope.requireConfirmation.challengeMethodType =
                        $scope.companyDetails.companyAuthenticationSettings.challengeMethodType;
                }
            });
    };

    function init() {
        spinnerService.configureGroup(
            'companyDetails',
            [
                '/companies',
                `/companies/${$scope.companyDetails.id}/associatedcompanies`,
                '/challenge-groups',
                `/companies/${$scope.id}/accounts`,
            ],
            []
        );
        loadCompanyDetails();
    }

    function isBlank(value) {
        if (!value) return true;

        return value.toString().trim().length === 0;
    }

    function cityStateZip() {
        const { address } = $scope.companyDetails;
        const city =
            (isBlank(address.city) ? '' : address.city) + (isBlank(address.state) ? '' : ',');
        const { state } = address;
        const zipCode = /[1-9]/.test(address.zipCode) ? address.zipCode : '';

        return [city, state, zipCode].join(' ').trim();
    }

    function determineIsCompanyEdit() {
        if (
            $scope.companyDetails.status === 'Inactive' &&
            ($scope.companyDetails.companyId === null ||
                $scope.companyDetails.companyId === undefined ||
                $scope.companyDetails.companyId.length === 0)
        ) {
            $scope.showCompanyIdEdit = true;
            $scope.companyDetails.tempCompanyId = null;
        }
    }

    function loadCompanyDetails() {
        spinnerService.startGroupRequest('companyDetails');

        if ($scope.id && $scope.id > 0) {
            companiesService.getCompany($scope.id, companyTypes.Parent).then(handleResponse);
        } else if ($scope.cifNumber) {
            companiesService
                .getNonOnboardedCompany($scope.cifNumber, companyTypes.Parent)
                .then(handleResponse);
        }

        function handleResponse(response) {
            spinnerService.stopGroupRequest('companyDetails');
            $scope.companyDetails = response;
            $scope.requireConfirmation.challengeMethodType =
                response.companyAuthenticationSettings.challengeMethodType;
            loadAddressMapLocation();

            if ($scope.companyDetails.id !== null) {
                loadAssociatedCompanies($scope.companyDetails.id);
            }
            $scope.companyStatus.status = $scope.companyDetails.status;
            $scope.companyStatus.comments = $scope.companyDetails.comments;
            determineIsCompanyEdit();
            loadGroups();
        }
    }

    function refreshCompany() {
        $scope.companyDetails.name = null;
        loadCompanyDetails();
    }

    function refreshAssociated() {
        $scope.associatedCompanies = null;
        $scope.associatedCompany = { isCollapsed: false };
        loadAssociatedCompanies($scope.companyDetails.id);
    }

    function loadGroups() {
        spinnerService.startGroupRequest('companyDetails');
        challengeGroupsService.getGroups().then(response => {
            spinnerService.stopGroupRequest('companyDetails');
            $scope.groupList = response;
            $scope.selectModel.challengeGroup =
                $scope.companyDetails.companyAuthenticationSettings.challengeGroup;
        });
    }

    function loadAddressMapLocation() {
        if (angular.isDefined($scope.companyDetails.address)) {
            $scope.mapLocation = `${$scope.companyDetails.address.line1},${$scope.companyDetails.address.city},${$scope.companyDetails.address.zipCode}`;
        } else {
            $scope.mapLocation = '';
        }
    }

    function updateAuthenticationStatus() {
        if ($scope.companyDetails.companyAuthenticationSettings.isActive) {
            $modal
                .open({
                    template: require('../views/deactivateAuthenticationModalView.html'),
                    controller: 'DeactivateAuthenticationModalController',
                    backdrop: 'static',
                    resolve: {
                        companyId() {
                            return $scope.companyDetails.id;
                        },
                    },
                })
                .result.then(selection => {
                    if (selection === 'confirm') {
                        secureChallengeService
                            .updateStatus($scope.companyDetails.id, false)
                            .then(afterUpdateAuthenticationStatus);
                    }
                });
        } else {
            secureChallengeService
                .updateStatus($scope.companyDetails.id, true)
                .then(afterUpdateAuthenticationStatus);
        }
    }

    function afterUpdateAuthenticationStatus(response) {
        $scope.companyDetails.companyAuthenticationSettings.isActive = response.isActive;
        $scope.companyDetails.companyAuthenticationSettings.challengeGroup =
            response.challengeGroup;
        $scope.companyDetails.companyAuthenticationSettings.challengeMethodType =
            response.challengeMethodType;
        $scope.requireConfirmation.challengeMethodType = response.challengeMethodType;
        $scope.selectModel.challengeGroup = response.challengeGroup;
        toaster.save('Authentication status');
    }

    function loadAssociatedCompanies(companyId) {
        spinnerService.startGroupRequest('companyDetails');
        companiesService.getAssociatedCompanies(companyId).then(
            response => {
                spinnerService.stopGroupRequest('companyDetails');
                $scope.associatedCompanies = response;
            },
            response => {
                $scope.associatedCompanies = [];
            }
        );
    }

    init();
}
