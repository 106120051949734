import { FeatureFlagAdministrationService } from '@treasury/domain/backoffice/services/feature-flags/feature-flag-administration-service';
import { FeatureFlagService } from '@treasury/domain/services/feature-flags';

export default class FeatureFlagClient {
    async getInstitutions() {
        return FeatureFlagAdministrationService.getInstitutions();
    }

    async getFeatureFlags() {
        return FeatureFlagService.getFeatureFlags();
    }

    async fetchFeatureFlags(...args) {
        return FeatureFlagAdministrationService.fetchFeatureFlags(...args);
    }

    async addFeatureFlag(...args) {
        return FeatureFlagAdministrationService.addFeatureFlag(...args);
    }

    async updateFeatureFlag(...args) {
        return FeatureFlagAdministrationService.updateFeatureFlag(...args);
    }

    async deleteFeatureFlag(id) {
        return FeatureFlagAdministrationService.deleteFeatureFlag(id);
    }
}
