angular.module('backOffice').controller('ManageBillPayController', manageBillPayController);

manageBillPayController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'modalService',
    'companiesService',
    'configurationService',
    'toaster',
];

function manageBillPayController(
    $scope,
    $state,
    $stateParams,
    modalService,
    companiesService,
    configurationService,
    toaster
) {
    const CompanyId = $stateParams.id;
    $scope.productFeature = {};

    $scope.setForm = setForm;
    $scope.goBack = goBack;
    $scope.save = save;
    $scope.companyAccounts = [];
    $scope.requiredEntitlements = requiredEntitlements;
    $scope.toggleAllowIPayBusinessBillPayForMobile = toggleAllowIPayBusinessBillPayForMobile;

    function init() {
        if ($stateParams.productFeatureId === '') {
            $scope.productFeature.id = 0;
        } else if (
            $stateParams.productFeatureId !== undefined ||
            $stateParams.productFeatureId !== null
        ) {
            $scope.productFeature.id = $stateParams.productFeatureId;
        }

        loadCompany();
    }

    function requiredEntitlements() {
        return $scope.productFeature.id === 0 ? 'Add Product Feature' : 'Edit Product Feature';
    }

    function toggleAllowIPayBusinessBillPayForMobile() {
        $scope.productFeature.allowIPayBusinessBillPayForMobile =
            !$scope.productFeature.allowIPayBusinessBillPayForMobile;
        $scope.form.$setDirty();
    }

    function setForm(form) {
        $scope.form = form;
    }

    function goBack() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel the changes you have made?',
                submit(result) {
                    cancelModal.dismiss();
                    $state.go('company', { id: CompanyId });
                },
            };
            var cancelModal = modalService.showModal({}, modalOptions);
        } else {
            $state.go('company', { id: CompanyId });
        }
    }

    function save() {
        const isAddingBillPay = $scope.productFeature.id === 0;
        if (isAddingBillPay) {
            $scope.productFeature.defaultAccountId = $scope.productFeature.defaultAccount.id;
            companiesService.saveProductFeature(CompanyId, $scope.productFeature).then(response => {
                $scope.productFeature.id = response.id;
                toaster.save('Bill Pay Configuration');
                $scope.form.$setPristine();
            });
        } else {
            companiesService
                .updateProductFeature(CompanyId, $scope.productFeature)
                .then(response => {
                    toaster.save('Bill Pay Configuration');
                    $scope.form.$setPristine();
                });
        }
    }

    function loadCompany() {
        companiesService.getCompanyName(CompanyId).then(response => {
            $scope.company = response;
            loadProductFeature();
        });
        companiesService.getDepositAccounts(CompanyId).then(response => {
            $scope.companyAccounts = response;
            setDefaultAccount();
        });
    }

    function loadProductFeature() {
        if ($scope.productFeature.id !== 0) {
            companiesService
                .getProductPermissions(CompanyId, $scope.productFeature.id)
                .then(response => {
                    $scope.productFeature = response;
                    setDefaultAccount();
                });
        } else {
            configurationService.getProductFeatureDetailsByName('BillPay').then(response => {
                $scope.productFeature = response;
                setDefaultAccount();
                $scope.productFeature.id = 0;
                $scope.productFeature.type = 'BillPayProductFeature';
            });
        }
    }

    function setDefaultAccount() {
        if ($scope.companyAccounts.length > 0 && $scope.productFeature.defaultAccountId > 0) {
            $scope.productFeature.defaultAccount = $scope.companyAccounts.reduce(
                (acc, val) => (val.id === $scope.productFeature.defaultAccountId ? val : acc),
                null
            );
        }
    }

    init();
}
