/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface TermsAndConditionsModelDto {
    /** @minLength 1 */
    text: string;
    requireUsersAcceptanceAfterEdit: boolean;
}
export type LogonResponseDto = BaseResponseDto & object;
export interface BaseResponseDto {
    baseRequest?: BaseRequestDto;
    responseDetailCollection?: ResponseDetailCollectionDto;
    success: boolean;
}
export interface BaseRequestDto {
    activityTracking?: ActivityTrackingDto;
    applicationNameType: ApplicationNameTypeDto;
    authenticationUser?: AuthenticationUserDto;
    clientIpAddress?: string;
    institutionId?: string;
    alias?: string;
    secondaryId?: string;
    endUser?: EndUserDto;
    productInformation?: ProductInformationDto;
    requestType?: string;
}
export interface ActivityTrackingDto {
    activityId?: string;
    parentActivityId?: string;
}
export enum ApplicationNameTypeDto {
    Undefined = 0,
    BackOffice = 1,
    Banno = 2,
    ECS = 3,
    EPS = 4,
    Esi = 5,
    GoDough = 6,
    GoDoughClassic = 7,
    GoDough3X = 8,
    IPay = 9,
    NetTeller = 10,
    PointMobility = 11,
    Treasury = 12,
    BSLKeys = 13,
    BSL = 14,
    ESIFIM = 15,
    EStatements = 16,
    Geezeo = 17,
    BannoPeople = 18,
    CoreDirector = 19,
    Newgen = 20,
    Corserv = 21,
    BOKF = 22,
    Autobooks = 23,
    MobileWeb = 24,
    HCL = 25,
    JxChangeApp = 26,
    TreasuryMobile = 27,
    TreasuryBackOffice = 28,
    BslSubSystem = 29,
    AccountRecon = 30,
    Ach = 31,
    AlertNetCore = 32,
    BankAccount = 33,
    Company = 34,
    Event = 35,
    JxChangeProxy = 36,
    PositivePay = 37,
    Sso = 38,
    User = 39,
    Wire = 40,
    Edpp = 41,
    FxGateway = 42,
    InstitutionNetCore = 43,
    AuthorizationNetCore = 44,
    File = 45,
    Configuration = 46,
    AchReturn = 47,
    ApiCatalog = 48,
    BslSubsystemAdmin = 49,
    EsiMailProcessor = 50,
    Nacha = 51,
    Pdf = 52,
    AchNotificationOfChange = 53,
    RateLimiting = 54,
    AchCompany = 55,
    TreasuryScheduler = 56,
    Report = 57,
    AccountReconIssuedItems = 58,
    PositivePayIssuedItems = 59,
    InternalTransfer = 60,
    CheckExceptions = 61,
    AlertUpdate = 62,
    TreasuryAccount = 63,
    TreasuryAuthentication = 64,
    TreasuryOdiEesListener = 65,
    TreasuryPayment = 66,
    TreasuryReporting = 67,
    TreasuryAch = 68,
    TreasuryWire = 69,
    TreasuryBackOfficeApi = 70,
    TreasuryChannelApi = 71,
    TreasuryNotification = 72,
    NachaIngest = 73,
    AchReturnProcessor = 74,
    AchNocProcessor = 75,
    NachaProcessor = 76,
    NachaSerialize = 77,
    AchPayment = 78,
    TextMessagingNetCore = 79,
    EmailNetCore = 80,
    LoggingNetCore = 81,
    MessageCenterNetCore = 82,
    AuthenticationRules = 83,
    Credentials = 84,
    HttpProxy = 85,
    DocumentNetCore = 86,
    AuthenticationNetCore = 87,
    ChallengeManagementNetCore = 88,
    FileWatcher = 89,
    FileProcessor = 90,
    EsiApplication = 91,
    AccountReports = 92,
    AuditNetCore = 93,
    TreasuryWireNetCore = 94,
    FraudNetCore = 95,
    BslAdmin = 96,
    JxR = 97,
    HealthCheck = 98,
    EchoQueueHandlerService = 99,
    BackgroundUpload = 100,
    JxrBannoBusiness = 101,
    Disclosure = 102,
}
export type AuthenticationUserDto = EndUserDto & object;
export interface EndUserDto {
    alias?: string;
    companyId?: string;
    externalId?: string;
    externalSecondaryId?: string;
    internalId?: string;
    internalSecondaryId?: string;
    userType: UserTypeDto;
    device?: string;
    browser?: string;
}
export enum UserTypeDto {
    Undefined = 0,
    Banno = 1,
    CashManagement = 2,
    Esi = 3,
    NetTeller = 4,
    Sso = 5,
    Treasury = 6,
    ESIFIM = 7,
    BackOffice = 8,
    Geezeo = 9,
    GeezeoMobile = 10,
    BannoCashManagement = 11,
    GeezeoCashManagement = 12,
    GeezeoMobileCashManagement = 13,
    Newgen = 14,
    Server = 15,
    Jha = 16,
    BannoPeople = 17,
}
export interface ProductInformationDto {
    productName?: string;
    featureName?: string;
    version?: string;
}
export type ResponseDetailCollectionDto = ResponseDetailDto[];
export interface ResponseDetailDto {
    /** @format int32 */
    responseCode: number;
    responseMessage?: string;
}
/**
 * Contains  error HTTP content data.
 *
 */
export interface ErrorHttpContentDto {
    /**
     * Gets or sets message.
     *
     */
    message?: string;
    /**
     * Gets or sets code.
     *
     * @format int32
     */
    code: number;
    /**
     * Gets or sets time.
     *
     */
    time?: string;
}
export interface LogonInputModelDto {
    client_assertion?: string;
    client_assertion_type?: string;
    grant_type?: string;
    scope?: string;
    logonRequest?: BaseRequestDto;
}
export type HealthCheckActionResponseDto = BaseResponseDto & {
    getPackageInformationSuccessful: boolean;
    packageInformation?: PackageInformationDto;
    getRedisConnectivitySuccessful: boolean;
    redisConnectivityStatus?: RedisConnectivityStatusCollectionDto;
    redisMultiplexerAbleToConnect: boolean;
    getDatabaseConnectivitySuccessful: boolean;
    databaseConnectivityStatus?: DatabaseConnectivityStatusCollectionDto;
};
export interface PackageInformationDto {
    buildInformation?: BuildInformationDto;
    releaseInformation?: ReleaseInformationDto;
    /** @format date-time */
    createdDateTime: string;
    machineName?: string;
}
export interface BuildInformationDto {
    commitId?: string;
    repository?: string;
    azdoProject?: string;
    packageVersion?: string;
    buildUrl?: string;
}
export interface ReleaseInformationDto {
    /** @format date-time */
    deploymentDateUtc: string;
    releaseVersion?: string;
    projectId?: string;
    packageName?: string;
    packageVersion?: string;
}
export type RedisConnectivityStatusCollectionDto = RedisConnectivityStatusDto[];
export interface RedisConnectivityStatusDto {
    redisClusterName?: string;
    ableToConnect: boolean;
    ipAddress?: string;
    additionalInformation?: string;
}
export type DatabaseConnectivityStatusCollectionDto = DatabaseConnectivityStatusDto[];
export interface DatabaseConnectivityStatusDto {
    connectionStringName?: string;
    ableToConnect: boolean;
    additionalInformation?: string;
}
export type InitialLoginCredentialModelDto = ModelBaseDto & {
    biometricsInfo?: UserDeviceResponseModelDto;
    data?: SecurityQuestionModelDto[];
    message?: string;
    /** @format int32 */
    numberOfSecurityQuestions: number;
    riskReceiptId?: string;
    rsaStatus?: string;
    secureTokenStatus?: string;
    userCredentialStatus?: string;
};
export interface UserDeviceResponseModelDto {
    authenticationId?: string;
    key?: string;
}
export type SecurityQuestionModelDto = ModelBaseDto & {
    answer?: string;
    id?: string;
    text?: string;
};
export interface ModelBaseDto {
    /** @format int32 */
    id: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate?: string;
}
export type UserModelDto = ModelBaseDto & {
    /** @minLength 1 */
    userId: string;
    /** @format guid */
    userUniqueId: string;
    /** @minLength 1 */
    userName: string;
    department?: string;
    /** @minLength 1 */
    email: string;
    phoneNumber?: string;
    fax?: string;
    isActiveUser: boolean;
    isSuperUser: boolean;
    isUserAdmin: boolean;
    isReportsAdmin: boolean;
    isAccountAdmin: boolean;
    status?: string;
    isLocked: boolean;
    isMfaBlocked: boolean;
    isEnrolled: boolean;
    phoneExtension?: string;
    comments?: string;
    enrollUser: boolean;
    company?: CompanyModelDto;
    fiID?: string;
    loginID?: string;
    code?: string;
    password?: string;
    name?: string;
    useClientBiometricsAuthentication: boolean;
    biometricsInfo?: UserDeviceRequestModelDto;
    backOfficeSsoEnabled: boolean;
    permissions?: ProductAccountPermissionsModelDto[];
    productLimits?: UserProductFeatureLimitModelDto[];
    roles?: RoleModelDto[];
    companyAccounts?: LookupModelDto[];
    ipRestriction?: IpRestrictionModelDto;
    userTimeAccess?: UserTimeAccessDto;
    mfaId?: string;
    hasLoggedIn: boolean;
    isTokenMfaLocked: boolean;
};
export type CompanyModelDto = ModelBaseDto & {
    /** @minLength 1 */
    cifNumber: string;
    /** @minLength 1 */
    taxNumber: string;
    companyId?: string;
    /** @minLength 1 */
    status: string;
    /** @minLength 1 */
    name: string;
    /**
     * @format date-time
     * @minLength 1
     */
    customerSince: string;
    /**
     * @format date-time
     * @minLength 1
     */
    addedOn: string;
    comments?: string;
    address?: AddressModelDto;
    companyAuthenticationSettings?: CompanyAuthenticationSettingsModelDto;
};
export type AddressModelDto = ModelBaseDto & {
    line1?: string;
    line2?: string;
    line3?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    country?: string;
};
export interface CompanyAuthenticationSettingsModelDto {
    isActive: boolean;
    challengeGroup?: ChallengeGroupModelDto;
    /** @format int32 */
    challengeMethodType: number;
    isSecureTokensEnabled: boolean;
}
export type ChallengeGroupModelDto = ModelBaseDto & {
    /** @minLength 1 */
    name: string;
    description?: string;
    isDefault: boolean;
};
export interface UserDeviceRequestModelDto {
    authenticationId?: string;
    hash?: string;
}
export interface ProductAccountPermissionsModelDto {
    permissions?: ProductPermissionModelDto[];
    accountUserPermissions?: AccountUserPermissionModelDto[];
    /** @format int32 */
    productId?: number;
    productName?: string;
}
export type ProductPermissionModelDto = ModelBaseDto & {
    /** @minLength 1 */
    permission: string;
};
export interface AccountUserPermissionModelDto {
    /** @format int32 */
    accountId?: number;
    accountName?: string;
    userPermissions?: UserPermissionModelDto[];
}
export interface UserPermissionModelDto {
    /** @format int32 */
    permissionId?: number;
    users?: UserSummaryModelDto[];
    permissionName?: string;
}
export interface UserSummaryModelDto {
    /** @format int32 */
    id?: number;
    userId?: string;
    digitalId?: string;
    userName?: string;
    isLocked: boolean;
    isAdmin: boolean;
    isSuperUser: boolean;
    status?: string;
    publishState?: string;
    isActive: boolean;
    isEditor: boolean;
    isDeleted?: boolean;
    isAuthenticationLocked?: boolean;
    /** @format date-time */
    isAuthenticationSuspended?: string;
    /** @format date-time */
    utcSuspendEndDate?: string;
    isMfaLocked: boolean;
    /** @format int32 */
    authenticationMethodId: number;
    securityAndPasswordSettingsLink?: string;
}
export type UserProductFeatureLimitModelDto = ModelBaseDto & {
    /** @format decimal */
    initiationLimit?: number;
    /** @format decimal */
    approvalLimit?: number;
    productFeature?: ProductFeatureModelDto;
};
export type ProductFeatureModelDto = ModelBaseDto & {
    /** @format int32 */
    id: number;
    /** @minLength 1 */
    type: string;
    status?: string;
    name?: string;
    permissions?: ProductAccountPermissionsModelDto;
    accountIds?: number[];
    isConfigurable: boolean;
};
export type RoleModelDto = ModelBaseDto & {
    /** @minLength 1 */
    name: string;
    description?: string;
    users?: string;
    permissions?: FiPermissionModelDto[];
};
export type FiPermissionModelDto = ModelBaseDto & {
    name?: string;
    description?: string;
    isDeleted: boolean;
    claimPrequisite?: string[];
};
export interface LookupModelDto {
    value?: string;
    key?: string;
}
export interface IpRestrictionModelDto {
    ipRestrictions?: IpRestrictionAddressModelDto[];
    ipRestrictionsPending?: IpRestrictionAddressModelDto[];
    /** @format int32 */
    userId: number;
    restricted: boolean;
    restrictedPending: boolean;
}
export interface IpRestrictionAddressModelDto {
    /** @format int32 */
    id: number;
    /** @format byte */
    octet1: number;
    /** @format byte */
    octet2: number;
    /** @format byte */
    octet3?: number;
    /** @format byte */
    octet4?: number;
    name?: string;
}
export type UserTimeAccessDto = RequestResponseDto & {
    timeRestricted: boolean;
    /** @format int32 */
    userId: number;
    userTimeAccessDays?: UserTimeAccessDayDto[];
};
export interface UserTimeAccessDayDto {
    timeAccessType: TimeAccessTypeDto;
    dayOfWeek: DayOfWeekDto;
    /** @format duration */
    beginTime?: string;
    /** @format duration */
    endTime?: string;
}
export enum TimeAccessTypeDto {
    Undefined = 0,
    AllowAllDay = 1,
    RestrictAllDay = 2,
    TimeRange = 3,
}
export enum DayOfWeekDto {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
}
export interface RequestResponseDto {
    success: boolean;
    errorMessage?: string;
}
export type SecureTokenMfaLoginResponseModelDto = ModelBaseDto & {
    message?: string;
    status?: string;
};
export type SecureTokenMfaLoginRequestModelDto = ModelBaseDto & {
    oneTimePasswordAndPin?: string;
};
export interface RegisterSecureTokenMfaResponseModelDto {
    message?: string;
    status?: string;
}
export interface RegisterSecureTokenMfaRequestModelDto {
    credentialId?: string;
    token?: string;
    pin?: string;
}
export interface SecureTokenMfaCredentialsResponseModelDto {
    credentialId?: string;
    shouldCancelCollection: boolean;
}
/** Represents an action result that returns an empty HttpStatusCode.OK response. */
export interface OkResultDto {
    /** Gets a HTTP request message for the results. */
    request?: HttpRequestMessageDto;
}
export interface HttpRequestMessageDto {
    version?: VersionDto;
    content?: HttpContentDto;
    method?: HttpMethodDto;
    /** @format uri */
    requestUri?: string;
    headers?: HttpRequestHeadersDto;
    properties?: Record<string, any>;
}
export interface VersionDto {
    /** @format int32 */
    major: number;
    /** @format int32 */
    minor: number;
    /** @format int32 */
    build: number;
    /** @format int32 */
    revision: number;
    majorRevision: number;
    minorRevision: number;
}
export interface HttpContentDto {
    headers?: HttpContentHeadersDto;
}
export type HttpContentHeadersDto = KeyValuePairOfStringAndIEnumerableOfStringDto[] & {
    Allow?: string[];
    ContentDisposition?: ContentDispositionHeaderValueDto;
    ContentEncoding?: string[];
    ContentLanguage?: string[];
    /** @format int64 */
    ContentLength?: number;
    /** @format uri */
    ContentLocation?: string;
    /** @format byte */
    ContentMD5?: string;
    ContentRange?: ContentRangeHeaderValueDto;
    ContentType?: MediaTypeHeaderValueDto;
    /** @format date-time */
    Expires?: string;
    /** @format date-time */
    LastModified?: string;
};
export interface ContentDispositionHeaderValueDto {
    dispositionType?: string;
    parameters?: NameValueHeaderValueDto[];
    name?: string;
    fileName?: string;
    fileNameStar?: string;
    /** @format date-time */
    creationDate?: string;
    /** @format date-time */
    modificationDate?: string;
    /** @format date-time */
    readDate?: string;
    /** @format int64 */
    size?: number;
}
export interface NameValueHeaderValueDto {
    name?: string;
    value?: string;
}
export interface ContentRangeHeaderValueDto {
    unit?: string;
    /** @format int64 */
    from?: number;
    /** @format int64 */
    to?: number;
    /** @format int64 */
    length?: number;
    hasLength: boolean;
    hasRange: boolean;
}
export interface MediaTypeHeaderValueDto {
    charSet?: string;
    parameters?: NameValueHeaderValueDto[];
    mediaType?: string;
}
export interface KeyValuePairOfStringAndIEnumerableOfStringDto {
    key?: string;
    value?: string[];
}
export interface HttpMethodDto {
    method?: string;
}
export type HttpRequestHeadersDto = KeyValuePairOfStringAndIEnumerableOfStringDto[] & {
    Accept?: MediaTypeWithQualityHeaderValueDto[];
    AcceptCharset?: StringWithQualityHeaderValueDto[];
    AcceptEncoding?: StringWithQualityHeaderValueDto[];
    AcceptLanguage?: StringWithQualityHeaderValueDto[];
    Authorization?: AuthenticationHeaderValueDto;
    Expect?: NameValueWithParametersHeaderValueDto[];
    ExpectContinue?: boolean;
    From?: string;
    Host?: string;
    IfMatch?: EntityTagHeaderValueDto[];
    /** @format date-time */
    IfModifiedSince?: string;
    IfNoneMatch?: EntityTagHeaderValueDto[];
    IfRange?: RangeConditionHeaderValueDto;
    /** @format date-time */
    IfUnmodifiedSince?: string;
    /** @format int32 */
    MaxForwards?: number;
    ProxyAuthorization?: AuthenticationHeaderValueDto;
    Range?: RangeHeaderValueDto;
    /** @format uri */
    Referrer?: string;
    TE?: TransferCodingWithQualityHeaderValueDto[];
    UserAgent?: ProductInfoHeaderValueDto[];
    CacheControl?: CacheControlHeaderValueDto;
    Connection?: string[];
    ConnectionClose?: boolean;
    /** @format date-time */
    Date?: string;
    Pragma?: NameValueHeaderValueDto[];
    Trailer?: string[];
    TransferEncoding?: TransferCodingHeaderValueDto[];
    TransferEncodingChunked?: boolean;
    Upgrade?: ProductHeaderValueDto[];
    Via?: ViaHeaderValueDto[];
    Warning?: WarningHeaderValueDto[];
};
export type MediaTypeWithQualityHeaderValueDto = MediaTypeHeaderValueDto & {
    /** @format double */
    quality?: number;
};
export interface StringWithQualityHeaderValueDto {
    value?: string;
    /** @format double */
    quality?: number;
}
export interface AuthenticationHeaderValueDto {
    scheme?: string;
    parameter?: string;
}
export type NameValueWithParametersHeaderValueDto = NameValueHeaderValueDto & {
    parameters?: NameValueHeaderValueDto[];
};
export interface EntityTagHeaderValueDto {
    tag?: string;
    isWeak: boolean;
}
export interface RangeConditionHeaderValueDto {
    /** @format date-time */
    date?: string;
    entityTag?: EntityTagHeaderValueDto;
}
export interface RangeHeaderValueDto {
    unit?: string;
    ranges?: RangeItemHeaderValueDto[];
}
export interface RangeItemHeaderValueDto {
    /** @format int64 */
    from?: number;
    /** @format int64 */
    to?: number;
}
export type TransferCodingWithQualityHeaderValueDto = TransferCodingHeaderValueDto & {
    /** @format double */
    quality?: number;
};
export interface TransferCodingHeaderValueDto {
    value?: string;
    parameters?: NameValueHeaderValueDto[];
}
export interface ProductInfoHeaderValueDto {
    product?: ProductHeaderValueDto;
    comment?: string;
}
export interface ProductHeaderValueDto {
    name?: string;
    version?: string;
}
export interface CacheControlHeaderValueDto {
    noCache: boolean;
    noCacheHeaders?: string[];
    noStore: boolean;
    /** @format duration */
    maxAge?: string;
    /** @format duration */
    sharedMaxAge?: string;
    maxStale: boolean;
    /** @format duration */
    maxStaleLimit?: string;
    /** @format duration */
    minFresh?: string;
    noTransform: boolean;
    onlyIfCached: boolean;
    public: boolean;
    private: boolean;
    privateHeaders?: string[];
    mustRevalidate: boolean;
    proxyRevalidate: boolean;
    extensions?: NameValueHeaderValueDto[];
}
export interface ViaHeaderValueDto {
    protocolName?: string;
    protocolVersion?: string;
    receivedBy?: string;
    comment?: string;
}
export interface WarningHeaderValueDto {
    /** @format int32 */
    code: number;
    agent?: string;
    text?: string;
    /** @format date-time */
    date?: string;
}
export interface CredentialDto {
    statusCode?: string;
    message?: string;
    data?: SecurityQuestionDto[];
    /** @format int32 */
    numberOfSecurityQuestions: number;
    riskReceiptId?: string;
}
export interface SecurityQuestionDto {
    answer?: string;
    id?: string;
    text?: string;
}
export type RecordedSecurityQuestionDto = SecurityQuestionDto & {
    answerOnFile?: string;
};
export interface ChangePasswordViewModelDto {
    statusCode?: string;
    message?: string[];
}
export interface UserSettingsModelDto {
    /** @format int32 */
    idleTimeoutDuration?: number;
    /** @format int32 */
    unreadMessageCount: number;
}
export interface RecentLoginsModelDto {
    userName?: string;
    standardLoginInfo?: UserLoginInfoModelDto;
    tokenMfaLoginInfo?: UserLoginInfoModelDto;
}
export interface UserLoginInfoModelDto {
    lastSuccessfulLogin?: string;
    lastSuccessfulLoginIPAddress?: string;
    lastFailedLogin?: string;
    lastFailedLoginIPAddress?: string;
}
export type InstitutionCollectionDto = InstitutionDto[];
export interface InstitutionDto {
    recordId?: string;
    uniqueId?: string;
    routingNumber?: string;
    name?: string;
    treasury?: TreasuryInstitutionDto;
}
export interface TreasuryInstitutionDto {
    /** @format int32 */
    institutionId: number;
    financialInstitutionId?: string;
    rowStatusId: number;
    logOffAddress?: string;
    /** @format int32 */
    fileArchiveId?: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    notificationEnabled?: boolean;
    /** @format int32 */
    timeZoneId: number;
    paymentProcessTime?: string;
    achExceptionReturnCode?: string;
    logo?: string;
    jxEnvironment?: string;
    rsaInstId?: string;
    ipRestricted?: boolean;
    notificationEmail?: string;
    showCutoffTimeZone?: boolean;
    brandName?: string;
    supportEmail?: string;
    supportPhone?: string;
    welcomeEmailText?: string;
    supportDepartmentName?: string;
    productName?: string;
    /** @format int32 */
    parentInstitutionId?: number;
    coreReleaseVersion?: string;
}
export interface GetAllInstitutionsRequestDto {
    includeInactive: boolean;
    disableCache: boolean;
}
export type FeatureFlagDataPageDto = DataPageOfFeatureFlagDto & object;
export interface DataPageOfFeatureFlagDto {
    /** @format int32 */
    pageSize: number;
    /** @format int32 */
    maxPageSize: number;
    /** @format int32 */
    pageNumber: number;
    /** @format int32 */
    totalCount: number;
    /** @format int32 */
    recordCountInCurrentPage: number;
    items?: FeatureFlagDto[];
}
export type FeatureFlagDto = FeatureFlagKeyDto & {
    enabled: boolean;
    value?: string;
};
export interface FeatureFlagKeyDto {
    /** @format guid */
    featureFlagKeyUniqueId: string;
    applicationNameTypes?: ApplicationNameTypeCollectionDto;
    name?: string;
    description?: string;
    updatedByUserId?: string;
    /** @format date-time */
    updatedDate: string;
}
export type ApplicationNameTypeCollectionDto = ApplicationNameTypeDto[];
export interface GetAllFeatureFlagsRequestDto {
    disableCache: boolean;
    institutionRecordId?: string;
    dataApplicationNameTypes?: ApplicationNameTypeCollectionDto;
    pagingParameters?: PagingParametersDto;
}
export interface PagingParametersDto {
    /** @format int32 */
    pageSize: number;
    /** @format int32 */
    pageNumber: number;
    orderBys?: OrderByCollectionDto;
    searchBys?: SearchByCollectionDto;
}
export type OrderByCollectionDto = OrderByDto[];
export interface OrderByDto {
    name?: string;
    descending: boolean;
}
export type SearchByCollectionDto = SearchByDto[];
export interface SearchByDto {
    name?: string;
    value?: string;
    secondValue?: string;
    searchType: SearchTypeDto;
    values?: TextKeyCollectionDto;
}
export enum SearchTypeDto {
    None = 0,
    Contains = 1,
    StartsWith = 2,
    EndsWidth = 3,
    Equals = 4,
    NotEquals = 5,
    GreaterThan = 6,
    GreaterThanOrEqual = 7,
    LessThan = 8,
    LessThanOrEqual = 9,
    Between = 10,
}
export type TextKeyCollectionDto = string[];
export interface SaveFeatureFlagsRequestDto {
    applyToAllInstitutions: boolean;
    featureFlags?: FeatureFlagCollectionDto;
    institutionIdType: InstitutionIdTypeDto;
    institutionIds?: TextKeyCollectionDto;
    disableCache: boolean;
}
export type FeatureFlagCollectionDto = FeatureFlagDto[];
export enum InstitutionIdTypeDto {
    None = 0,
    RecordId = 1,
    UniqueId = 2,
}
export interface BaiConfigurationModelDto {
    baiCodeTitles?: BaiCodeTitleModelDto[];
    baiCodeTypes?: BaiCodeTypeModelDto[];
    selectedTransactionCodes?: TransactionCodeModelDto[];
    transactionCodeList?: TransactionCodeModelDto[];
}
export interface BaiCodeTitleModelDto {
    /** @format int32 */
    id: number;
    name?: string;
    description?: string;
}
export type BaiCodeTypeModelDto = ModelBaseDto & {
    /** @format int32 */
    baiCode: number;
    transactionType?: string;
    level?: string;
    description?: string;
    /** @format int32 */
    baiCodeTitleId: number;
    isSelected: boolean;
    selectedTransactionCodes?: TransactionCodeModelDto[];
};
export type TransactionCodeModelDto = ModelBaseDto & {
    /** @minLength 1 */
    code: string;
    /** @minLength 1 */
    description: string;
    /** @format int32 */
    institutionId: number;
    /** @minLength 1 */
    type: string;
    isExcluded: boolean;
    isDeleted: boolean;
    isDescriptionChanged: boolean;
    isTypeChanged: boolean;
    /** @format int32 */
    ofxTransactionTypeId?: number;
    ofxTransactionTypeName?: string;
    /** @format int32 */
    baiCode?: number;
    changeStatus?: string;
    groups?: TransactionCodeGroupModelDto[];
    isChecked: boolean;
    label?: string;
    value?: string;
};
export type TransactionCodeGroupModelDto = ModelBaseDto & {
    /** @minLength 1 */
    name: string;
    isReadOnly: boolean;
};
export interface CheckImageModelDto {
    checkId?: string;
    /** @format byte */
    frontImage?: string;
    /** @format byte */
    backImage?: string;
}
export type UserApprovalSettingsModelDto = ModelBaseDto & {
    channelApproveChannel: boolean;
    channelApproveBackOffice: boolean;
    backOfficeApproveBackOffice: boolean;
};
export interface CompanyUserAccountSettingsModelDto {
    useAccountNicknames: boolean;
    /** @format int32 */
    accountMaskTypeId: number;
    allowUserToOverrideAccountMasking: boolean;
    /** @format int32 */
    timeZoneId: number;
    showCutOffTimeZone: boolean;
}
export interface SearchCompanyUserModelDto {
    activeUsersOnly: boolean;
    enrolledUsersOnly: boolean;
    includeRoles: boolean;
    /** @format int32 */
    companyId: number;
}
export interface UserStateModelDto {
    company?: CompanyModelDto;
    /** @format int32 */
    userId: number;
    name?: string;
    isActive: boolean;
    isLocked: boolean;
    approvalRequired: boolean;
    pendingState?: string;
    editedByUser?: UserModelDto;
    /** @format date-time */
    editedDate?: string;
    hasPendingChanges: boolean;
    isEnrolled: boolean;
    isEditor: boolean;
    hasMinimumRequirements: boolean;
    isSuperUser: boolean;
    alias?: string;
}
export interface UserProductFeatureSummaryModelDto {
    /** @format int32 */
    userId: number;
    changedUserInformation: boolean;
    changedAccountAccess: boolean;
    hasAch: boolean;
    changedAchEntitlements: boolean;
    changedAchUserLimits: boolean;
    hasStandardPosPay: boolean;
    hasSsoPosPay: boolean;
    changedStandardPosPayEntitlements: boolean;
    changedSsoPosPayUserSettings: boolean;
    hasInformationReporting: boolean;
    changedInformationReportingEntitlements: boolean;
    hasInternalTransfers: boolean;
    changedInternalTransfersEntitlements: boolean;
    changedInternalTransfersUserLimits: boolean;
    hasWireTransfers: boolean;
    hasWireTransfersDomestic: boolean;
    hasWireTransfersInternational: boolean;
    changedWireTransfersEntitlements: boolean;
    changedWireTransfersUserLimits: boolean;
    hasWireTransfersDli: boolean;
    changedWireTransfersDliEntitlements: boolean;
    changedWireTransfersDliUserLimits: boolean;
    hasStopPayment: boolean;
    changedStopPaymentEntitlements: boolean;
    changedTimeAccess: boolean;
    changedIpRestrictions: boolean;
    hasIntegratedServices: boolean;
    changedIntegratedServices: boolean;
    hasReceivables: boolean;
    changedReceivables: boolean;
    hasAccountReconciliationReporting: boolean;
    changedAccountReconciliationReporting: boolean;
}
export type UserInformationModelDto = RequestResponseDto & {
    /** @format int32 */
    userId: number;
    /** @minLength 1 */
    name: string;
    /** @minLength 1 */
    loginId: string;
    /** @minLength 1 */
    department: string;
    /** @minLength 1 */
    email: string;
    /** @minLength 1 */
    phone: string;
    /** @minLength 1 */
    extension: string;
    mobile?: string;
    mobileExtension?: string;
    /** @minLength 1 */
    fax: string;
    accountNicknames?: boolean;
    accountMasking?: boolean;
    verificationMethod?: string;
    isMfaBlocked: boolean;
    isSuperUser: boolean;
    isAccountMaskingConfigurable: boolean;
    isMethodOfVerificationConfigurable: boolean;
    isAdmin: boolean;
    /** @format int32 */
    copyUserId: number;
    isEnrolled: boolean;
    isActive: boolean;
    mfaId?: string;
    hasLoggedIn: boolean;
};
export interface UserInformationResponseModelDto {
    user?: UserInformationModelDto;
    pendingUser?: UserInformationModelDto;
}
export type ChallengeSettingsModelDto = ModelBaseDto & {
    /** @format int32 */
    authenticationMethodId: number;
    userStatus?: string;
    /** @format int32 */
    userStatusId: number;
    oobVerificationMethodType?: string;
    isOobPinCollected?: boolean;
    oobMessagePhoneNumber?: string;
    oobCallPhoneNumber?: string;
    /** @format int32 */
    tokenCredentialState: number;
    tokenUserId?: string;
    /** @format int32 */
    userId: number;
    /** @format date-time */
    utcSuspendEndTime?: string;
    /** @format date-time */
    localSuspendEndTime?: string;
    symantecUserExists: boolean;
};
export interface GetUserAccountAccessModelDto {
    accounts?: AccountAccessModelDto[];
}
export type AccountAccessModelDto = ModelBaseDto & {
    companyAccount?: CompanyAccountModelDto;
    hasUserAccess: boolean;
    hasPendingUserAccess: boolean;
};
export type CompanyAccountModelDto = ModelBaseDto & {
    /** @minLength 1 */
    name: string;
    /** @minLength 1 */
    number: string;
    cifNumber?: string;
    /** @minLength 1 */
    status: string;
    type?: string;
    account?: AccountModelDto;
    productPermissions?: ProductAccountPermissionsModelDto[];
    users?: LookupModelDto[];
    /** @format decimal */
    perTransactionLimit?: number;
    /** @format decimal */
    dailyLimit?: number;
};
export type AccountModelDto = ModelBaseDto & {
    companyName?: string;
    number?: string;
    cifNumber?: string;
    type?: string;
    coreStatus?: string;
    isCompanyAccount: boolean;
    linkedTMCompanies?: string[];
};
export interface SaveUserAccountAccessModelDto {
    addedAccounts?: AccountAccessModelDto[];
    removedAccounts?: AccountAccessModelDto[];
}
export interface GetUserProductEntitlementsModelDto {
    productType?: string;
    userProductClaims?: UserClaimModelDto[];
    accountProductClaims?: ClaimModelDto[];
    accounts?: ProductAccountDto[];
}
export interface UserClaimModelDto {
    /** @format int32 */
    claimId: number;
    claimName?: string;
    hasUserClaim?: boolean;
    hasPendingUserClaim?: boolean;
    claimDescription?: string;
}
export interface ClaimModelDto {
    /** @format int32 */
    claimId: number;
    claimName?: string;
    claimDescription?: string;
}
export interface ProductAccountDto {
    /** @format int32 */
    id: number;
    name?: string;
    number?: string;
    userAccountClaims?: UserClaimModelDto[];
}
export interface GetUserAchEntitlementsModelDto {
    achCompanies?: AchCompanyModelDto[];
    userAchCompanies?: AchCompanyModelDto[];
    userProductClaims?: UserClaimModelDto[];
}
export type AchCompanyModelDto = ModelBaseDto & {
    /** @minLength 1 */
    companyName: string;
    /** @minLength 1 */
    companyId: string;
    secCodes?: SecCodeModelDto[];
    entryDescription?: string;
    discretionaryData?: string;
    settlementAccount?: string;
    settlementAccountType?: string;
    balanceValidationType?: string;
    availableBalanceTable?: string;
    /** @format decimal */
    totalDailyLimit: number;
    /** @minLength 1 */
    notOnUsTransactionTypes: string;
    /** @minLength 1 */
    batchBalanceRequirements: string;
    /** @format int32 */
    onUsTransactionsRequired: number;
    /** @format decimal */
    onUsAmountRequiredPerBatch: number;
    isActive: boolean;
    isPaymentProcessingCompany: boolean;
    allowAchPaymentProcessingCompany: boolean;
    allowUnbalancedPayments: boolean;
    /** @format int32 */
    prefundingDays?: number;
    offsetAccountNumber?: string;
    /** @format guid */
    uniqueIdentifier: string;
    allowSameDayAch: boolean;
    coreSecCode?: string;
    companyStatus?: EntityStatusTypeModelDto;
    transactionType?: TransactionTypeModelDto;
    paymentBalanceType?: PaymentBalanceTypeModelDto;
    configurationErrors?: ConfigurationErrorModelDto[];
};
export interface SecCodeModelDto {
    /** @minLength 1 */
    code: string;
    /** @minLength 1 */
    description: string;
    /** @format int32 */
    id: number;
    coreCode?: string;
}
export interface EntityStatusTypeModelDto {
    /** @format int32 */
    id: number;
    name?: string;
    description?: string;
}
export interface TransactionTypeModelDto {
    /** @format int32 */
    id: number;
    name?: string;
    description?: string;
    coreCode?: string;
}
export interface PaymentBalanceTypeModelDto {
    /** @format int32 */
    id: number;
    name?: string;
    description?: string;
    coreCode?: string;
}
export interface ConfigurationErrorModelDto {
    /** @format int32 */
    id: number;
    message?: string;
    failure: boolean;
}
export interface GetUserProductLimitsResponseModelDto {
    productType?: string;
    userProductLimit?: UserProductLimitsModelDto;
    pendingUserProductLimit?: UserProductLimitsModelDto;
    userAccountLimits?: AccountUserLimitModelDto[];
    /** @format decimal */
    productDailyLimit: number;
}
export interface UserProductLimitsModelDto {
    /** @format decimal */
    initiationLimit: number;
    /** @format decimal */
    approvalLimit: number;
}
export type AccountUserLimitModelDto = ModelBaseDto & {
    userAccount?: UserAccountModelDto;
    userAccountLimit?: UserAccountLimitModelDto;
    pendingUserAccountLimit?: UserAccountLimitModelDto;
};
export interface UserAccountModelDto {
    /** @format int32 */
    id: number;
    /** @format int32 */
    userId: number;
    account?: CompanyAccountModelDto;
}
export interface UserAccountLimitModelDto {
    /** @format decimal */
    perTransactionLimit: number;
    /** @format decimal */
    dailyLimit: number;
}
export interface GetUserAchLimitsModelDto {
    userProductLimit?: UserProductLimitsModelDto;
    pendingUserProductLimit?: UserProductLimitsModelDto;
    userAchCompanies?: UserAchCompanyLimitModelDto[];
    /** @format decimal */
    productDailyLimit: number;
}
export interface UserAchCompanyLimitModelDto {
    achCompany?: AchCompanyModelDto;
    userAchCompanyLimit?: AchCompanyLimitModelDto;
    pendingUserAchCompanyLimit?: AchCompanyLimitModelDto;
}
export interface AchCompanyLimitModelDto {
    /** @format decimal */
    perTransactionLimit: number;
    /** @format decimal */
    dailyLimit: number;
}
export interface SaveUserProductEntitlementsModelDto {
    addedUserClaims?: number[];
    addedUserClaimAccounts?: Record<string, number[]>;
    removedUserClaims?: number[];
    removedUserClaimAccounts?: Record<string, number[]>;
}
export interface SaveUserAchProductEntitlementsModelDto {
    addedEntitlements?: UserProductEntitlementDto[];
    removedEntitlements?: UserProductEntitlementDto[];
    achCompanies?: AchCompanyModelDto[];
}
export interface UserProductEntitlementDto {
    account?: ProductAccountDto;
    claim?: ClaimModelDto;
}
export type CompanyUserTimeAccessDto = RequestResponseDto & {
    timeRestricted: boolean;
    timeRestrictedPending: boolean;
    /** @format int32 */
    userId: number;
    userTimeAccessDays?: UserTimeAccessDayDto[];
    userTimeAccessDaysPending?: UserTimeAccessDayDto[];
};
export interface SsoPosPayUserSettingsModelDto {
    pendingSsoLoginId?: string;
    ssoLoginId?: string;
    userProductClaims?: UserClaimModelDto[];
}
export interface ReceivablesUserSettingsModelDto {
    pendingSsoAccessKey?: string;
    ssoAccessKey?: string;
    pendingSsoLoginId?: string;
    ssoLoginId?: string;
    userProductClaims?: UserClaimModelDto[];
    /** @format int32 */
    remoteDepositProductTypeId: number;
}
export interface CompanyUserChallengeSettingsModelDto {
    isOobAuthenticationPinRequired: boolean;
    /** @format int32 */
    numberOfOobAttemptsAllowed: number;
    isTokenEnabledForChallenges: boolean;
    /** @format int32 */
    numberOfTokenChallengePointAttemptsAllowed: number;
    hasSecureTokenAccountId: boolean;
    isChannelLoginTwoFactorAuthenticationEnabled: boolean;
}
export type CutoffConfigurationModelDto = ModelBaseDto & {
    /**
     * @format date-time
     * @minLength 1
     */
    transferTime: string;
};
export interface DownloadRequestDto {
    downloadType: DownloadTypeDto;
    filter?: DownloadFilterDto;
}
export enum DownloadTypeDto {
    Unknown = 0,
    WireTransferPaymentReportCsv = 1,
    WireTransferPaymentReportPdf = 2,
    FxWireTransferPaymentReportCsv = 11,
    FxWireTransferPaymentReportPdf = 12,
    AchPaymentReportCsv = 21,
    AchPaymentReportPdf = 22,
    PositivePayIssuedItemsReportCsv = 31,
    PositivePayIssuedItemsReportPdf = 32,
    CompanyUserActivityReportCsv = 41,
    CompanyUserActivityReportPdf = 42,
    InstitutionUserActivityReportCsv = 51,
    InstitutionUserActivityReportPdf = 52,
    WireDetailsCsv = 61,
    WireDetailsPdf = 62,
    InternalTransferPaymentReportCsv = 71,
    InternalTransferPaymentReportPdf = 72,
    LoanPaymentReportCsv = 73,
    LoanPaymentReportPdf = 74,
    WireTransferPaymentReportDetailsCsv = 81,
    WireTransferPaymentReportDetailsPdf = 82,
    FxWireTransferPaymentReportDetailsCsv = 91,
    FxWireTransferPaymentReportDetailsPdf = 92,
    BoFiUserActivityCsv = 93,
    BoFiUserActivityPdf = 94,
    BoCheckExceptionCsv = 95,
    BoCheckExceptionPdf = 96,
    ChannelUserActivityCsv = 97,
    ChannelUserActivityPdf = 98,
    BoAchExceptionCsv = 99,
    BoAchExceptionPdf = 100,
    AchFilterExceptionsPdf = 101,
    AchFilterExceptionsCsv = 102,
    GetAchNocPdf = 103,
    GetAchNocCsv = 104,
    AchNocDownloadSummaryPdf = 105,
    AchNocDownloadSummaryCsv = 106,
    AchNocDownloadDetailPdf = 107,
    AchNocDownloadDetailCsv = 108,
    AchReturnSummaryPdf = 109,
    AchReturnDetailsPdf = 110,
    AchReturnSummaryCsv = 111,
    AchReturnDetailsCsv = 112,
    GetAchReturnPdf = 113,
    GetAchReturnCsv = 114,
}
export interface DownloadFilterDto {
    downloadType?: string;
    informationType?: string;
    outPutType: OutputTypeDto;
    downloadDefinitionName?: string;
}
export enum OutputTypeDto {
    Unknown = 0,
    Pdf = 1,
    Csv = 2,
    Tiff = 3,
    Bai = 4,
    Quicken = 5,
    QuickBook = 6,
    Nacha = 7,
    PdfInDetail = 8,
    CsvInDetail = 9,
    Ofx = 10,
}
export type ReportDownloadFilterDto = DownloadFilterDto & {
    reportFilter?: ReportFilterBaseDto;
};
export interface ReportFilterBaseDto {
    reportType?: string;
    /** @format int32 */
    reportId: number;
    name?: string;
    /** @format int32 */
    pageNumber: number;
    /** @format int32 */
    pageSize: number;
    runDefaultSearch: boolean;
    orderBys?: OrderByModelDto[];
    filters?: ReportFilterDto[];
    downloadType?: string;
    setDefaultSearchToSelectAll: boolean;
    /** @format int32 */
    checkNumberMaxLength: number;
}
export interface OrderByModelDto {
    name?: string;
    descending: boolean;
}
export interface ReportFilterDto {
    type?: string;
    label?: string;
    model?: string;
    filterData?: FilterLookupModelDto[];
    required: boolean;
    /** @format int32 */
    order: number;
    triggerCascadeFilter: boolean;
    applyCascadeFilter: boolean;
}
export interface FilterLookupModelDto {
    id?: string;
    value?: string;
    cascadeFilterIds?: number[];
    /** @format int32 */
    order?: number;
}
export type IdDownloadFilterDto = DownloadFilterDto & {
    /** @format int32 */
    id: number;
};
export type GuidDownloadFilterDto = DownloadFilterDto & {
    /** @format guid */
    guid: string;
};
export interface DownloadOptionDto {
    name?: string;
    downloadType: DownloadTypeDto;
}
export enum DownloadPageDto {
    None = 0,
    WireTransferPaymentReport = 1,
    FxWireTransferPaymentReport = 2,
    AchPaymentReport = 3,
    PositivePayIssuedItemsReport = 4,
    CompanyUserActivityReport = 5,
    InstitutionUserActivityReport = 6,
    WireTransferPaymentDetails = 7,
    FxWireTransferPaymentDetails = 8,
    InternalTransferPaymentReport = 9,
    LoanPaymentReport = 10,
    BoFiUserActivity = 11,
    BoCheckException = 12,
    ChannelUserActivity = 13,
    BoAchException = 14,
    AchFilterExceptions = 15,
    GetAchNoc = 16,
    AchNoc = 17,
    AchReturn = 18,
    GetAchReturn = 19,
}
export type EntitlementGroupModelDto = ModelBaseDto & {
    category?: string;
    /** @format int32 */
    sequence: number;
    entitlements?: EntitlementModelDto[];
};
export interface EntitlementModelDto {
    /** @format int32 */
    claimId: number;
    /** @format int32 */
    sequence: number;
    name?: string;
    prerequisiteClaims?: number[];
}
export type HolidayCalendarModelDto = ModelBaseDto & {
    /** @minLength 1 */
    name: string;
    /**
     * @format date-time
     * @minLength 1
     */
    date: string;
    isPastDue: boolean;
};
export type InformationCenterConfigurationModelDto = ModelBaseDto & {
    /**
     * @format date-time
     * @minLength 1
     */
    startDate: string;
    /**
     * @format date-time
     * @minLength 1
     */
    endDate: string;
    /** @minLength 1 */
    title: string;
    description?: string;
    /** @minLength 1 */
    type: string;
    /** @minLength 1 */
    status: string;
    /** @format int32 */
    sequence: number;
};
export interface InstitutionModelDto {
    /** @format int32 */
    institutionId: number;
    name?: string;
    fiId?: string;
    status?: string;
    treasuryProductName?: string;
    supportDepartmentName?: string;
    supportEmail?: string;
    supportPhone?: string;
    logo?: string;
    logoutURL?: string;
}
export interface QboSettingsModelDto {
    intuitBankId?: string;
}
export type LoginIdPasswordParametersModelDto = ModelBaseDto & {
    /** @format int32 */
    loginIdMinLength: number;
    /** @format int32 */
    loginIdMaxLength: number;
    allowAlphabetsInLoginId: boolean;
    allowNumbersInLoginId: boolean;
    allowSpecialCharactersInLoginId: boolean;
    alphabetsInLoginIdAreRequired: boolean;
    numbersInLoginIdAreRequired: boolean;
    specialCharactersInLoginIdAreRequired: boolean;
    /** @format int32 */
    numberOfUppercaseRequiredInLoginId?: number;
    /** @format int32 */
    numberOfLowercaseRequiredInLoginId?: number;
    redirectUrl?: string;
    /** @format int32 */
    passwordMinLength: number;
    /** @format int32 */
    passwordMaxLength: number;
    allowAlphabetsInPassword: boolean;
    allowNumbersInPassword: boolean;
    allowSpecialCharactersInPassword: boolean;
    alphabetsInPasswordAreRequired: boolean;
    numbersInPasswordAreRequired: boolean;
    specialCharactersInPasswordAreRequired: boolean;
    /** @format int32 */
    numberOfUppercaseRequiredInPassword?: number;
    /** @format int32 */
    numberOfLowercaseRequiredInPassword?: number;
    willPasswordExpire: boolean;
    /** @format int32 */
    passwordExpireInDays?: number;
    /** @format int32 */
    warnPasswordExpireInDays: number;
    /** @format int32 */
    numberOfPrevUsedPasswords?: number;
    /** @format int32 */
    numberOfAllowedAttemptsBeforeLock?: number;
    /** @minLength 1 */
    invalidLoginIdPasswordMessage: string;
    allowLockedOutMessage: boolean;
    /** @minLength 1 */
    lockedOutMessage: string;
    sendLockedEmail: boolean;
    /** @minLength 1 */
    lockedEmailMessage: string;
    /** @format int32 */
    sessionTimeoutMinutes: number;
    isCompanyParameter: boolean;
    isDefault: boolean;
};
export interface NavigationMenuModelDto {
    navigationMenus?: NavigationModelDto[];
    ssoInstitutions?: SsoInstitutionModelDto[];
    currentFiId?: string;
}
export type NavigationModelDto = ModelBaseDto & {
    /** @minLength 1 */
    key: string;
    /** @minLength 1 */
    name: string;
    path?: string;
    subMenus?: NavigationModelDto[];
};
export interface SsoInstitutionModelDto {
    fiId?: string;
    institutionName?: string;
    /** @format int32 */
    institutionId: number;
}
export interface UpdateSsoUserInstitutionModelDto {
    fiId?: string;
}
export type NotificationConfigurationModelDto = ModelBaseDto & {
    isActive: boolean;
    groups?: NotificationConfigurationGroupModelDto[];
};
export type NotificationConfigurationGroupModelDto = ModelBaseDto & {
    /** @minLength 1 */
    name: string;
    features?: NotificationConfigurationFeatureModelDto[];
};
export type NotificationConfigurationFeatureModelDto = ModelBaseDto & {
    /** @minLength 1 */
    name: string;
    isRequired: boolean;
    isActive: boolean;
    isChanged: boolean;
    isEmailAllowed: boolean;
    isTextMessageAllowed: boolean;
    isDesktopAllowed: boolean;
    /** @format int32 */
    notificationTypeId: number;
    /** @format int32 */
    sortOrder: number;
    isActiveByDefault: boolean;
};
export type ChallengeGroupDetailModelDto = ChallengeGroupModelDto & {
    actionGroup?: ChallengeActionGroupModelDto[];
};
export type ChallengeActionGroupModelDto = ModelBaseDto & {
    /** @minLength 1 */
    name: string;
    /** @minLength 1 */
    description: string;
    actions?: ChallengeActionsModelDto[];
};
export type ChallengeActionsModelDto = ModelBaseDto & {
    challengeUser: boolean;
    isThresholdPresent: boolean;
    /** @minLength 1 */
    thresholdType: string;
    /** @format decimal */
    thresholdAmount: number;
    /** @minLength 1 */
    name: string;
    /** @minLength 1 */
    description: string;
    hoverText?: string;
};
export type ChallengeGroupCompanyModelDto = ChallengeGroupModelDto & {
    /** @minLength 1 */
    companyName: string;
    /** @minLength 1 */
    cifNumber: string;
    /** @minLength 1 */
    status: string;
};
export type FiProductFeatureModelDto = ModelBaseDto & {
    isCompanyProductConfigurable: boolean;
    /** @minLength 1 */
    name: string;
    /** @minLength 1 */
    status: string;
    /** @minLength 1 */
    type: string;
};
/** Represents an action result that returns an empty BadRequest response. */
export interface BadRequestResultDto {
    /** Gets the request message which led to this result. */
    request?: HttpRequestMessageDto;
}
export type FiArpProductFeatureModelDto = FiProductFeatureModelDto & {
    allowArpExceptions: boolean;
    showAchExceptions: boolean;
    manageAchExceptions: boolean;
    workCheckException: boolean;
    workAchException: boolean;
    canAllowSubstituteProduct: boolean;
    allowSubstituteProduct: boolean;
    allowIssuedItemActivityReview: boolean;
};
export interface UpdateRemoteDepositProductModelDto {
    remoteDepositCaptureSsoConfigurations?: UpdateRemoteDepositCaptureSsoModelDto[];
}
export interface UpdateRemoteDepositCaptureSsoModelDto {
    /** @format int32 */
    institutionSsoProductId: number;
    isActive: boolean;
}
export interface UploadStateTaxCodeFileResponseModelDto {
    fileErrors?: ErrorSummaryModelDto;
    success: boolean;
}
export interface ErrorSummaryModelDto {
    details?: string[];
    summaryMessage?: string;
}
export interface StateTaxCodeFileMetadataModelDto {
    stateTaxCodesFileName?: string;
}
export interface FiCorpayProductModelDto {
    /** @minLength 1 */
    sharedSecretKey: string;
}
export interface FiCorpayProductResponseModelDto {
    success: boolean;
}
export type FiAgileticsSsoProductModelDto = FiProductFeatureModelDto & {
    /**
     * @format guid
     * @minLength 1
     */
    institutionSsoProductOptionUniqueId: string;
    /** @minLength 1 */
    productDisplayName: string;
};
export interface FiAgileticsSsoProductResponseModelDto {
    success: boolean;
}
export interface FiQfxProductModelDto {
    fiId?: string;
    enabledFlag?: string;
}
export type ResourceConfigurationModelDto = ModelBaseDto & {
    /** @minLength 1 */
    title: string;
    /** @minLength 1 */
    link: string;
    tooltip?: string;
    /** @minLength 1 */
    status: string;
    displayExternalWarning: boolean;
    externalWarningMessage?: string;
    logo?: string;
    /** @format int32 */
    sequence: number;
};
export interface InstitutionMessagingCenterModelDto {
    useSharedEmail: boolean;
    sharedEmail?: string;
    disclaimer?: string;
    archive: ArchiveSettingDto;
}
export enum ArchiveSettingDto {
    Undefined = 0,
    DoNotArchive = 1,
    ThreeMonths = 2,
    SixMonths = 3,
    NineMonths = 4,
    TwelveMonths = 5,
}
export interface MessageCategoryModelDto {
    /** @format int32 */
    fiMessageCategoryId: number;
    messageCategory?: string;
    categoryDescription?: string;
}
export interface MessageModelDto {
    /** @format int32 */
    id: number;
    messageId?: string;
    companyName?: string;
    /** @format int32 */
    companyId?: number;
    fromUserName?: string;
    toUserName?: string;
    /** @format int32 */
    fromUserId?: number;
    /** @format int32 */
    toUserId?: number;
    /** @format int32 */
    categoryId: number;
    subject?: string;
    body?: string;
    status?: string;
    assignedTo?: string;
    sentDate?: string;
    sentTime?: string;
    /** @format date-time */
    sentDateTime: string;
    sentMessage: boolean;
    hasAttachment: boolean;
    attachmentNames?: string[];
    attachments?: MessageAttachmentModelDto[];
    parentMessage?: MessageModelDto;
    isArchived: boolean;
    /** @format int32 */
    parentMessageId?: number;
    /** @format int32 */
    assignedToUserId?: number;
    isReply: boolean;
    isSent: boolean;
    messageIdSortValue?: string;
}
export interface MessageAttachmentModelDto {
    /** @format int32 */
    id: number;
    name?: string;
    /** @format byte */
    data?: string;
    /** @format guid */
    fileIdentifier?: string;
    /** @format int32 */
    typeId: number;
}
export interface MessageCenterSearchTermsDto {
    /** @format int32 */
    companyId?: number;
    /** @format int32 */
    statusId?: number;
    /** @format int32 */
    fromUserId?: number;
    /** @format int32 */
    assignedToUserId?: number;
    messageId?: string;
    dateFrom?: string;
    dateTo?: string;
    /** @format int32 */
    messageTypeId?: number;
}
export interface UpdateMessageModelDto {
    /** @format int32 */
    messageId: number;
    /** @format int32 */
    actionTypeId: number;
    /** @format int32 */
    statusTypeId: number;
    /** @format int32 */
    assignedUserId: number;
    updateConversation: boolean;
}
/** Represents the state of an attempt to bind a posted form to an action method, which includes validation information. */
export interface ModelStateDictionaryDto {
    /**
     * Gets the number of key/value pairs in the collection.
     * @format int32
     */
    Count: number;
    /** Gets a value that indicates whether the collection is read-only. */
    IsReadOnly: boolean;
    /** Gets a value that indicates whether this instance of the model-state dictionary is valid. */
    IsValid: boolean;
    /** Gets a collection that contains the keys in the dictionary. */
    Keys?: string[];
    /** Gets a collection that contains the values in the dictionary. */
    Values?: ModelStateDto[];
    /** Encapsulates the state of model binding to a property of an action-method argument, or to the argument itself. */
    Item?: ModelStateDto;
}
/** Encapsulates the state of model binding to a property of an action-method argument, or to the argument itself. */
export interface ModelStateDto {
    /** Gets a ValueProviderResult object that encapsulates the value that was being bound during model binding. */
    value?: ValueProviderResultDto;
    /** Gets a ModelErrorCollection object that contains any errors that occurred during model binding. */
    errors?: ModelErrorCollectionDto;
}
/** Represents the result of binding a value (such as from a form post or query string) to an action-method argument property, or to the argument itself. */
export interface ValueProviderResultDto {
    /** Gets or sets the raw value that is converted to a string for display. */
    attemptedValue?: string;
    /** Gets or sets the culture. */
    culture?: string;
    /** Gets or set the raw value that is supplied by the value provider. */
    rawValue?: any;
}
/** Represents a collection of ModelError instances. */
export type ModelErrorCollectionDto = ModelErrorDto[];
/** Represents an error that occurs during model binding. */
export interface ModelErrorDto {
    /** Gets or sets the exception object. */
    exception?: ExceptionDto;
    /** Gets or sets the error message. */
    errorMessage?: string;
}
export interface ExceptionDto {
    Message?: string;
    InnerException?: ExceptionDto;
    StackTrace?: string;
    Source?: string;
}
export interface AnalysisTransactionCodeDto {
    /** @format int32 */
    analysisTransactionCodeId: number;
    /** @format int32 */
    institutionId: number;
    /** @format int32 */
    analysisTransactionTypeId: number;
    transactionCode?: string;
    active: boolean;
}
export interface AnalysisTransactionTypeDto {
    /** @format int32 */
    analysisTransactionTypeId: number;
    transactionName?: string;
    description?: string;
    /** @format int32 */
    sortOrder: number;
    analysisTransactionTypeCategory?: AnalysisTransactionTypeCategoryDto;
}
export interface AnalysisTransactionTypeCategoryDto {
    /** @format int32 */
    analysisTransactionTypeCategoryId: number;
    categoryName?: string;
    /** @format int32 */
    sortOrder: number;
}
export interface CustomTransactionCodeDto {
    /** @format int32 */
    id: number;
    transactionCode?: string;
    /** @format int32 */
    analysisTransactionTypeId: number;
    active: boolean;
    accountCustomTransactionCodes?: AccountCustomTransactionCodeDto[];
    /** @format int32 */
    analysisTransactionTypeCategoryId: number;
}
export interface AccountCustomTransactionCodeDto {
    /** @format int32 */
    id: number;
    /** @format int32 */
    accountId: number;
    /** @format int32 */
    customTransactionCodeId: number;
    active: boolean;
    accountNumber?: string;
}
export interface OfxTransactionTypeModelDto {
    /** @format int32 */
    ofxTransactionTypeId: number;
    name?: string;
    displayName?: string;
    description?: string;
}
export interface SamlLoginResponseDto {
    success: boolean;
    fiId?: string;
    alias?: string;
    responseDetails?: ResponseDetailModelCollectionDto;
}
export type ResponseDetailModelCollectionDto = ResponseDetailModelDto[];
export interface ResponseDetailModelDto {
    /** @format int32 */
    responseCode: number;
    responseMessage?: string;
}
export interface SamlLoginRequestDto {
    samlToken?: string;
}
export interface GetUsersModelDto {
    users?: BackOfficeUserListModelDto[];
    isTokenMfaEnabled: boolean;
    backOfficeSsoEnabled: boolean;
}
export interface BackOfficeUserListModelDto {
    alias?: string;
    userName?: string;
    department?: string;
    email?: string;
    phoneNumber?: string;
    fax?: string;
    status?: string;
    isActiveUser: boolean;
    isUserAdmin: boolean;
    isSuperUser: boolean;
    isAccountAdmin: boolean;
    isReportsAdmin: boolean;
    isLocked: boolean;
    isMfaBlocked: boolean;
    isTokenMfaLocked: boolean;
    isEnrolled: boolean;
    phoneExtension?: string;
    roles?: RoleModelDto[];
    /** @format int32 */
    id: number;
    backOfficeSsoEnabled: boolean;
}
export interface UserLoginTokenDetailModelDto {
    /** @format int32 */
    userLoginTokenStatus: number;
    /** @format int32 */
    credentialStatus: number;
    tokenVendorUserId?: string;
}
export interface SearchChannelUsersModelDto {
    companyUniqueIds?: string[];
}
export type SearchUsersDataPageDto = DataPageOfSearchUserDto & {
    /** @format int32 */
    fiUserCount: number;
};
export interface DataPageOfSearchUserDto {
    /** @format int32 */
    pageSize: number;
    /** @format int32 */
    maxPageSize: number;
    /** @format int32 */
    pageNumber: number;
    /** @format int32 */
    totalCount: number;
    /** @format int32 */
    recordCountInCurrentPage: number;
    items?: SearchUserDto[];
}
export interface SearchUserDto {
    /**
     * @format guid
     * @minLength 1
     */
    userUniqueId: string;
    /** @minLength 1 */
    tmUserId: string;
    /** @minLength 1 */
    status: string;
    /** @minLength 1 */
    companyName: string;
    /** @minLength 1 */
    name: string;
    /** @format date-time */
    lastSuccessfulLogin?: string;
    digitalId?: string;
}
export interface SearchUsersFilterDto {
    searchText?: string;
    pagingParameters?: PagingModelDto;
}
export interface PagingModelDto {
    /** @format int32 */
    pageSize: number;
    /** @format int32 */
    pageNumber: number;
    orderBys?: OrderByModelDto[];
}
export interface CurrentUserTimeAccessDto {
    timeRestricted: boolean;
    userTimeAccessDays?: UserTimeAccess2Dto[];
}
export interface UserTimeAccess2Dto {
    /** @format int32 */
    userId: number;
    /** @format int32 */
    userTimeAccessTypeId: number;
    /** @format int32 */
    dayOfWeekId: number;
    /** @format date-time */
    startTime: string;
    /** @format date-time */
    endTime: string;
}
export type SaveUserActivityRequestDto = BaseRequestDto & {
    /** @format date-time */
    activityDate: string;
    activityMessage?: string;
    /** @format int32 */
    relatedId?: number;
    userActivityType: UserActivityTypeDto;
};
export enum UserActivityTypeDto {
    Account = 1,
    Ach = 2,
    Admin = 3,
    Dashboard = 4,
    Login = 5,
    MessageCenter = 6,
    Navigating = 7,
    PositivePay = 8,
    ProfileAndNotification = 9,
    Reporting = 10,
    StopPayment = 11,
    Transaction = 12,
    Transfer = 13,
    Wire = 14,
    AccountReconciliationReporting = 15,
    Authentication = 16,
}
export type ChannelUserModelCollectionDto = ChannelUserModelDto[];
export interface ChannelUserModelDto {
    /** @format int32 */
    userId: number;
    /** @format int32 */
    companyId: number;
    /** @format guid */
    userUniqueId: string;
    userName?: string;
    userAlias?: string;
    digitalId?: string;
    companyName?: string;
    status?: string;
}
export interface ChannelUserSearchFilterDto {
    searchText?: string;
    searchType?: string;
}
export interface ProcessingTimesConfigurationModelDto {
    productFeatureProcessingTimes?: ProductFeatureProcessingTimesModelDto[];
    timeZone?: TimeZoneModelDto;
}
export type ProductFeatureProcessingTimesModelDto = ModelBaseDto & {
    /** @minLength 1 */
    name: string;
    /** @format date-time */
    startTime?: string;
    /** @format date-time */
    cutOffTime?: string;
    nextBusinessDay: boolean;
    showNextBusinessDay: boolean;
    startTimeNotApplicable: boolean;
    showInChannel: boolean;
};
export interface TimeZoneModelDto {
    /** @format int32 */
    timeZoneId: number;
    showCutOffTimeZone: boolean;
}
export interface CompanySearchModelDto {
    cifNumber?: string;
    taxNumber?: string;
    companyId?: string;
    status?: string;
    name?: string;
    /** @format int32 */
    id: number;
}
export type LookupListModelDto = ModelBaseDto & {
    lookups?: LookupModelDto[];
};
export type CompanyLimitModelDto = ModelBaseDto & {
    /** @format decimal */
    monthlyLimit?: number;
    /** @format decimal */
    dailyLimit?: number;
};
export interface SsoProductResponseModelDto {
    success: boolean;
}
export interface SsoProductModelDto {
    /** @format guid */
    ssoProductOptionUniqueId: string;
    optionName?: string;
    value?: string;
}
export interface AccountProductsMappingDetailModelDto {
    products?: ProductFeatureModelDto[];
    accountProducts?: AccountProductsMappingModelDto[];
}
export interface AccountProductsMappingModelDto {
    account?: CompanyAccountModelDto;
    productFeatures?: AccountProductMappingModelDto[];
}
export interface AccountProductMappingModelDto {
    isSelected?: boolean;
    productFeature?: ProductFeatureModelDto;
}
export type ContactInfoModelDto = ModelBaseDto & {
    /** @minLength 1 */
    comments: string;
    user?: UserModelDto;
};
export type CompanyAccountDetailModelDto = ModelBaseDto & {
    companyAccountsUsers?: CompanyAccountsModelDto[];
};
export interface CompanyAccountsModelDto {
    /** @format int32 */
    companyAccountUserId?: number;
    /** @format int32 */
    accountId?: number;
    accountName?: string;
    cifNumber?: string;
    accountType?: string;
    accountNumber?: string;
    users?: UserSummaryModelDto[];
}
export type AssociatedCompanyModelDto = ModelBaseDto & {
    name?: string;
    taxNumber?: string;
    cifNumber?: string;
    address?: string;
    /** @format int32 */
    holdingCompanyId: number;
};
export interface CompanyAccountSettingsModelDto {
    useAccountNicknames: boolean;
    /** @format int32 */
    accountMaskTypeId: number;
    allowUserToOverrideAccountMasking: boolean;
}
export type SuspendUserChallengeModelDto = ModelBaseDto & {
    /** @format date-time */
    utcSuspendEndTime?: string;
    /** @format date-time */
    localSuspendEndTime?: string;
};
export type RemoteDepositProductModelDto = ProductFeatureModelDto & {
    /** @format int32 */
    remoteDepositProductTypeId: number;
    ssoCustomerId?: string;
};
export interface IntuitOfxModelDto {
    intuitOfxId?: string;
}
export interface QfxProductModelDto {
    enabledFlag: boolean;
}
export interface UnrelatedAccountSearchResultsModelDto {
    isCifAlreadyAssociated: boolean;
    accounts?: AccountModelDto[];
}
export interface UnrelatedAccountSearchModelDto {
    cif?: string;
    accountType?: string;
    accountNumber?: string;
}
export interface ToAccountPermissionModelDto {
    toAccount: CompanyAccountModelDto;
    isAllowed: boolean;
}
export type WireCompanyModelDto = ModelBaseDto & {
    /** @format int32 */
    id: number;
    name?: string;
    cifNumber?: string;
    /** @format decimal */
    perTransactionLimit: number;
    /** @format decimal */
    dailyLimit: number;
    isInternationalDisabled: boolean;
    canSelect: boolean;
    isDli: boolean;
    accounts?: WireCompanyAccountModelDto[];
};
export type WireCompanyAccountModelDto = ModelBaseDto & {
    /** @format int32 */
    id: number;
    /** @minLength 1 */
    cifNumber: string;
    /** @minLength 1 */
    number: string;
    name?: string;
    isSelected?: boolean;
    isCompanyAccount?: boolean;
    isDeleted?: boolean;
    /** @format decimal */
    transactionLimit?: number;
    /** @format decimal */
    dailyLimit?: number;
    type?: string;
    isAccountActive: boolean;
};
export interface WireFileFilterModelDto {
    companyIds?: number[];
    uploadedByIds?: number[];
    uploadDateType?: string;
    /** @format date-time */
    uploadDate?: string;
    /** @format date-time */
    uploadDateStart?: string;
    /** @format date-time */
    uploadDateEnd?: string;
    fileName?: string;
    wireAmountType?: string;
    /** @format decimal */
    wireAmount?: number;
    /** @format decimal */
    wireAmountMin?: number;
    /** @format decimal */
    wireAmountMax?: number;
    paging?: PagingModelDto;
}
export interface ReportFilterModelOfWireFileFilterModelDto {
    /** @format int32 */
    reportId: number;
    reportType?: string;
    reportName?: string;
    reportFilter?: WireFileFilterModelDto;
}
export interface NonCoreInternationalWireCompanyModelDto {
    /** @format int32 */
    id: number;
    name?: string;
    /** @format decimal */
    dailyLimit: number;
    /** @format decimal */
    perTransactionLimit: number;
    /** @format int32 */
    availableBalanceValidationCoreTable?: number;
    /** @format int32 */
    accountBalanceValidationType?: number;
    nonCoreAccounts?: NonCoreInternationalWireCompanyAccountModelDto[];
    eligibleAccounts?: CompanyAccountModelDto[];
}
export interface NonCoreInternationalWireCompanyAccountModelDto {
    /** @format int32 */
    nonCoreAccountId: number;
    /** @format int32 */
    accountId: number;
    name?: string;
    number?: string;
    type?: string;
    /** @format decimal */
    perTransactionLimit: number;
    /** @format decimal */
    dailyLimit: number;
    active: boolean;
}
export interface WireModelDto {
    additionalInformation?: string[];
    /** @format decimal */
    amount: number;
    audit?: string;
    availableApprovers?: string[];
    beneficiary?: BeneficiaryModelDto;
    /** @format int32 */
    completedApprovalCount?: number;
    confirmationNumber?: string;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    debitAccount?: string;
    destinationCurrency?: string;
    errorSummary?: ErrorSummaryModelDto;
    frequency?: FrequencyModelDto;
    /** @format int32 */
    id: number;
    isDli: boolean;
    isEdited: boolean;
    isInternational: boolean;
    isUserApprovalLimitLess: boolean;
    message?: string;
    messageType?: string;
    /** @format int32 */
    numberOfApprovalsNeeded: number;
    parentFrequency?: FrequencyModelDto;
    purpose?: string;
    rateQuote?: RateQuoteModelDto;
    referenceBeneficiary?: string;
    /** @format int32 */
    statusId: number;
    status?: string;
    successMessage?: string;
    transactionId?: string;
    wireCompany?: WireCompanyModelDto;
    externallyProcessed: boolean;
}
export interface BeneficiaryModelDto {
    accountNumber?: string;
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    bank?: BankModelDto;
    city?: string;
    /** @format int32 */
    completedApprovalCount?: number;
    country?: string;
    countryCode?: string;
    /** @format date-time */
    createdDate?: string;
    currencyType?: string;
    /** @format int32 */
    id: number;
    intermediaryBanks?: IntermediaryBankModelDto[];
    isEdited?: boolean;
    isForeignCurrency?: boolean;
    isFreeForm: boolean;
    isInternational: boolean;
    isTemplateBeneficiary: boolean;
    name?: string;
    notes?: string;
    /** @format int32 */
    numberOfApprovalsNeeded: number;
    postalCode?: string;
    postalCodeExtension?: string;
    state?: string;
    status?: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
}
export interface BankModelDto {
    /** @format int32 */
    addressId: number;
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    bankId?: string;
    bankIdType?: string;
    city?: string;
    country?: string;
    countryCode?: string;
    isInternational: boolean;
    name?: string;
    notes?: string;
    onUs: boolean;
    postalCode?: string;
    state?: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
}
export type IntermediaryBankModelDto = BankModelDto & {
    accountNumber?: string;
};
export interface FrequencyModelDto {
    /** @format date-time */
    endOn?: string;
    noEndDate?: boolean;
    repeatOn?: string;
    /** @format int32 */
    repeatOnDay1?: number;
    /** @format int32 */
    repeatOnDay2?: number;
    repeatOnLastBusinessDay?: boolean;
    /** @format date-time */
    startOn?: string;
    type?: string;
    /** @format date-time */
    valueDate?: string;
}
export interface RateQuoteModelDto {
    amexContractNumber?: string;
    /** @format int32 */
    amexQuoteId?: number;
    /** @format decimal */
    conversionRate: number;
    /** @format decimal */
    destinationAmount: number;
    destinationCurrencyCode?: string;
    /** @format decimal */
    exchangeFee: number;
    /** @format int32 */
    expirationTime: number;
    /** @format int32 */
    id: number;
    /** @format decimal */
    originAmount: number;
    /** @format decimal */
    totalCost: number;
    /** @format date-time */
    valueDate?: string;
    /** @format decimal */
    wireFee: number;
    rateId?: string;
}
export type WireExchangeFeeDto = ModelBaseDto & {
    /** @format decimal */
    amount: number;
    /** @format double */
    percent: number;
};
export interface ExchangeRatesheetDto {
    /** @format int32 */
    id: number;
    /** @format date-time */
    effectiveDate: string;
    exchangeRates?: ExchangeRateDto[];
}
export interface ExchangeRateDto {
    countryName?: string;
    countryCode?: string;
    exchangeCurrency?: string;
    /** @format decimal */
    rate: number;
    baseCurrency?: string;
}
export interface ExchangeRateMetadataDto {
    /** @format int32 */
    businessDaysFromNow: number;
    /** @format date-time */
    effectiveDate: string;
    exchangeRateSheetMetadata?: ExchangeRateSheetMetadataDto;
}
export interface ExchangeRateSheetMetadataDto {
    fileName?: string;
    /** @format date-time */
    uploadDate: string;
    uploadedBy?: string;
}
export interface BaseResponse2Dto {
    baseRequest?: BaseRequest2Dto;
    responseDetailCollection?: ResponseDetailCollection2Dto;
    success: boolean;
}
export interface BaseRequest2Dto {
    activityTracking?: ActivityTracking2Dto;
    alias?: string;
    applicationNameType: ApplicationNameType2Dto;
    authenticationUser?: AuthenticationUser2Dto;
    clientIpAddress?: string;
    endUser?: EndUser2Dto;
    institutionId?: string;
    productInformation?: ProductInformation2Dto;
    requestType?: string;
    secondaryId?: string;
}
export interface ActivityTracking2Dto {
    activityId?: string;
    parentActivityId?: string;
}
export enum ApplicationNameType2Dto {
    Undefined = 0,
    BackOffice = 1,
    Banno = 2,
    ECS = 3,
    EPS = 4,
    Esi = 5,
    GoDough = 6,
    GoDoughClassic = 7,
    GoDough3X = 8,
    IPay = 9,
    NetTeller = 10,
    PointMobility = 11,
    Treasury = 12,
    BSLKeys = 13,
    BSL = 14,
    ESIFIM = 15,
    EStatements = 16,
    Geezeo = 17,
    BannoPeople = 18,
    CoreDirector = 19,
    Newgen = 20,
}
export type AuthenticationUser2Dto = EndUser2Dto & object;
export interface EndUser2Dto {
    alias?: string;
    externalId?: string;
    externalSecondaryId?: string;
    internalId?: string;
    internalSecondaryId?: string;
    userType: UserType2Dto;
}
export enum UserType2Dto {
    Undefined = 0,
    Banno = 1,
    CashManagement = 2,
    Esi = 3,
    NetTeller = 4,
    Sso = 5,
    Treasury = 6,
    ESIFIM = 7,
    BackOffice = 8,
    Geezeo = 9,
    GeezeoMobile = 10,
    BannoCashManagement = 11,
    GeezeoCashManagement = 12,
    GeezeoMobileCashManagement = 13,
    Newgen = 14,
}
export interface ProductInformation2Dto {
    featureName?: string;
    productName?: string;
    version?: string;
}
export type ApproveTransferTemplateRequestDto = BaseRequest2Dto & {
    /** @format int32 */
    transferTemplateId: number;
};
export type CreateTransferTemplateRequestDto = BaseRequest2Dto & {
    internalTransferTemplate?: InternalTransferTemplateDto;
    validateTemplate: boolean;
};
export type InternalTransferTemplateDto = BaseEntityDto & {
    /** @format int32 */
    approvalCount: number;
    company?: CompanyDto;
    /** @format int32 */
    companyId: number;
    /** @format int32 */
    completedApprovals: number;
    /** @format int32 */
    createdBy: number;
    createdByUser?: UserDto;
    /** @format date-time */
    createdDate: string;
    eligibleApprovers?: string[];
    fromAccountNumber?: string;
    /** @format int32 */
    id: number;
    internalTransferTemplateAudits?: InternalTransferTemplateAuditDto[];
    internalTransferTemplateDetails?: InternalTransferTemplateDetailDto[];
    internalTransferTemplateStatusType?: InternalTransferTemplateStatusTypeDto;
    /** @format int32 */
    internalTransferTemplateStatusTypeId: number;
    internalTransferType?: InternalTransferTypeDto;
    /** @format int32 */
    internalTransferTypeId: number;
    oneToOneMemo?: string;
    templateAccountDetails?: AccountDto[];
    templateName?: string;
    toAccountNumber?: string;
    /** @format decimal */
    totalAmount: number;
    /** @format int32 */
    updatedBy: number;
    updatedByUser?: UserDto;
    /** @format date-time */
    updatedDate: string;
    userPermissions?: string[];
};
export type CompanyDto = BaseEntityDto & {
    accounts?: AccountDto[];
    achCompany?: AchCompanyDto;
    address?: AddressDto;
    beneficiaries?: BeneficiaryDto[];
    canSelect: boolean;
    childCompanies?: CompanyDto[];
    cifNumber?: string;
    comments?: string;
    companyAccountDisplaySetting?: CompanyAccountDisplaySettingDto;
    companyChallengeGroup?: CompanyChallengeGroupDto;
    companyLimits?: CompanyLimitDto[];
    companyType?: CompanyTypeDto;
    /** @format int32 */
    companyTypeId: number;
    companyUniqueId?: string;
    companyUserApprovalSetting?: CompanyUserApprovalSettingDto;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format date-time */
    customerSince?: string;
    fileImportLayouts?: FileImportLayoutDto[];
    /** @format int32 */
    id: number;
    institution?: Institution2Dto;
    /** @format int32 */
    institutionId: number;
    internalTransferTransactions?: InternalTransferTransactionDto[];
    name?: string;
    ofxAuthenticationCode?: string;
    parentCompany?: CompanyDto;
    /** @format int32 */
    parentCompanyId?: number;
    products?: ProductDto[];
    rowStatus?: RowStatusDto;
    rowStatusId: number;
    taxNumber?: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    userAccounts?: UserAccountDto[];
    users?: UserDto[];
    wires?: WireDto[];
};
export type AccountDto = BaseEntityDto & {
    accountNumberTypeFormat?: string;
    accountProducts?: AccountProductDto[];
    achOffsetAccounts?: AchOffsetAccountDto[];
    allowLoanPaymentPastDue: boolean;
    analysisTransactionCounts?: AnalysisTransactionCountDto[];
    arpExceptionCorrections?: ArpExceptionCorrectionDto[];
    arpExceptions?: ArpExceptionDto[];
    arpReportFiles?: ArpReportFileDto[];
    associatedClaims?: AssociatedClaimDto[];
    /** @format decimal */
    balance?: number;
    cifNumber?: string;
    /** @format decimal */
    collectedBalance?: number;
    company?: CompanyDto;
    /** @format int32 */
    companyId: number;
    coreAccount?: CoreAccountDto;
    coreStatus?: string;
    createdBy?: string;
    /** @format date-time */
    createdDate?: string;
    /** @format decimal */
    currentBalance?: number;
    /** @format decimal */
    dailyLimit: number;
    /** @format decimal */
    dailyUsedValue: number;
    description?: string;
    /** @format int32 */
    id: number;
    internalTransferTransactions?: InternalTransferTransactionDto[];
    internalTransferTransactions1?: InternalTransferTransactionDto[];
    isAccountLinked: boolean;
    isCompanyAccount: boolean;
    isSelected: boolean;
    linkedTMCompanies?: string[];
    /** @format decimal */
    loanPaymentAmountDue?: number;
    /** @format date-time */
    loanPaymentDueDate?: string;
    name?: string;
    nonCoreWireCompanyInternationalAccounts?: NonCoreWireCompanyInternationalAccountDto[];
    number?: string;
    /** @format decimal */
    perTransactionLimit: number;
    reportingFilterAccounts?: ReportingFilterAccountDto[];
    rowStatus?: RowStatusDto;
    rowStatusId: number;
    showAccountNickName: boolean;
    stopPayments?: StopPaymentDto[];
    /** @format decimal */
    templateTransferAmount: number;
    templateTransferMemo?: string;
    type?: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    userAccountGroupAccounts?: UserAccountGroupAccountDto[];
    userAccounts?: UserAccountDto[];
    users?: UserDto[];
    wires?: WireDto[];
};
export type AccountProductDto = BaseEntityDto & {
    account?: AccountDto;
    /** @format int32 */
    accountId: number;
    accountProductArp?: AccountProductArpDto;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    product?: ProductDto;
    productAccountPermission?: ProductAccountPermissionDto[];
    /** @format int32 */
    productId: number;
};
export interface AccountProductArpDto {
    accountProduct?: AccountProductDto;
    hasAchException: boolean;
    hasCheckException: boolean;
    /** @format int32 */
    id: number;
    /** @format int32 */
    updatedBy: number;
    /** @format date-time */
    updatedDate: string;
}
export type ProductDto = BaseEntityDto & {
    accountProducts?: AccountProductDto[];
    achProduct?: AchProductDto;
    arpProduct?: ArpProductDto;
    billPayProduct?: BillPayProductDto;
    company?: CompanyDto;
    /** @format int32 */
    companyId?: number;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    electronicDocumentsProduct?: ElectronicDocumentsProductDto;
    /** @format int32 */
    id: number;
    internalTransferProduct?: InternalTransferProductDto;
    isConfigurable: boolean;
    productPermissions?: ProductPermissionDto[];
    productType?: ProductTypeDto;
    /** @format int32 */
    productTypeId: number;
    remoteDepositProduct?: RemoteDepositProductDto;
    reportingConfigurations?: ReportingConfigurationDto[];
    reportingProduct?: ReportingProductDto;
    rowStatus?: RowStatusDto;
    rowStatusId: number;
    stopPaymentProduct?: StopPaymentProductDto;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    userProductLimits?: UserProductLimitDto[];
    wireInternationalProduct?: WireInternationalProductDto;
    wireProduct?: WireProductDto;
};
export interface AchProductDto {
    accountBalanceValidationConfiguredInCore: boolean;
    /** @format int32 */
    achCompanyMatchTypeId: number;
    allowFederalTaxPayments: boolean;
    allowSameDayAch: boolean;
    allowStateTaxPayments: boolean;
    allowTaxPayments: boolean;
    /** @format int32 */
    batchApprovalCount: number;
    batchAuthenticationType?: AuthenticationTypeDto;
    /** @format int32 */
    batchAuthenticationTypeId: number;
    /** @format decimal */
    dailyLimit: number;
    disableSameDay: boolean;
    /** @format int32 */
    id: number;
    /** @format int32 */
    paymentApprovalCount: number;
    paymentAuthenticationType?: AuthenticationTypeDto;
    /** @format int32 */
    paymentAuthenticationTypeId: number;
    product?: ProductDto;
    /** @format int32 */
    recipientApprovalCount: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    validateAccountBalance: boolean;
}
export type AuthenticationTypeDto = BaseEntityDto & {
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
};
export interface BaseEntityDto {
    objectState: ObjectStateDto;
}
export enum ObjectStateDto {
    Unchanged = 0,
    Added = 1,
    Modified = 2,
    Deleted = 3,
}
export type InternalTransferProductLinkAccountDto = BaseEntityDto & {
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    fromAccountType?: AccountTypeDto;
    /** @format int32 */
    fromAccountTypeId: number;
    /** @format int32 */
    id: number;
    internalTransferProduct?: InternalTransferProductDto;
    /** @format int32 */
    productId: number;
    toAccountType?: AccountTypeDto;
    /** @format int32 */
    toAccountTypeId: number;
};
export interface AccountTypeDto {
    achBatchRecipients?: AchBatchRecipientDto[];
    achPaymentRecipients?: AchPaymentRecipientDto[];
    achTransactionCodes?: AchTransactionCodeDto[];
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
    reportingType?: string;
}
export type AchBatchRecipientDto = BaseEntityDto & {
    accountNumber?: string;
    accountType?: AccountTypeDto;
    /** @format int32 */
    accountTypeId: number;
    achBatch?: AchBatchDto;
    /** @format int32 */
    achBatchId: number;
    achBatchRecipientAddendas?: AchBatchRecipientAddendaDto[];
    addenda?: string;
    /** @format decimal */
    amount: number;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    hold: boolean;
    /** @format int32 */
    id: number;
    preNote: boolean;
    recipientIdNumber?: string;
    recipientName?: string;
    routingNumber?: string;
    /** @format int32 */
    rowNum?: number;
    transactionType?: TransactionTypeDto;
    /** @format int32 */
    transactionTypeId: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
};
export type AchBatchDto = BaseEntityDto & {
    achBatchAudits?: AchBatchAuditDto[];
    achBatchRecipients?: AchBatchRecipientDto[];
    achCompany?: AchCompanyDto;
    /** @format int32 */
    achCompanyId: number;
    /** @format int32 */
    achCompanyMatchType: number;
    achCompanyName?: string;
    achPayments?: AchPaymentDto[];
    achSecCode?: AchSecCodeDto;
    /** @format int32 */
    achSecCodeId: number;
    /** @format int32 */
    approvalsRequired: number;
    approvedBy?: TupleOfStringAndDateTimeDto[];
    batchName?: string;
    batchStatusType?: BatchStatusTypeDto;
    /** @format int32 */
    batchStatusTypeId: number;
    /** @format int32 */
    batchUniqueId: number;
    companyIdentity?: string;
    /** @format int32 */
    completedApprovals: number;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format decimal */
    credit?: number;
    /** @format decimal */
    creditAmount: number;
    /** @format decimal */
    debit?: number;
    /** @format decimal */
    debitAmount: number;
    descretionaryData?: string;
    eligibleApprovers?: string[];
    entryDescription?: string;
    errorMessage?: string;
    fileArchive?: FileArchiveDto;
    /** @format int32 */
    fileArchiveId?: number;
    /** @format int32 */
    id: number;
    isEdited: boolean;
    /** @format int32 */
    numberOfRecipients: number;
    restricted: boolean;
    secCode?: string;
    status?: string;
    successMessage?: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    userPermissions?: string[];
};
export type AchBatchAuditDto = BaseEntityDto & {
    achActionType?: AchActionTypeDto;
    achBatch?: AchBatchDto;
    /** @format int32 */
    achBatchId: number;
    /** @format int32 */
    actionTypeId: number;
    comments?: string;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    userName?: string;
};
export type AchActionTypeDto = BaseEntityDto & {
    achBatchAudits?: AchBatchAuditDto[];
    achPaymentAudits?: AchPaymentAuditDto[];
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
};
export type AchPaymentAuditDto = BaseEntityDto & {
    achActionType?: AchActionTypeDto;
    /** @format int32 */
    achActionTypeId: number;
    achPayment?: AchPaymentDto;
    /** @format int32 */
    achPaymentId: number;
    comments?: string;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    userName?: string;
};
export type AchPaymentDto = BaseEntityDto & {
    account?: AccountDto;
    achBatch?: AchBatchDto;
    /** @format int32 */
    achBatchId?: number;
    achCompany?: AchCompanyDto;
    /** @format int32 */
    achCompanyId: number;
    achCompanyName?: string;
    achFederalTaxPayment?: AchFederalTaxPaymentDto;
    achNachaFileCopyAudits?: AchNachaFileCopyAuditDto[];
    achPaymentAudits?: AchPaymentAuditDto[];
    /** @format int32 */
    achPaymentDraftId: number;
    achPaymentRecipients?: AchPaymentRecipientDto[];
    /** @format int32 */
    achPaymentRecipientsCount: number;
    achPaymentType?: AchPaymentTypeDto;
    /** @format int32 */
    achPaymentTypeId: number;
    achStateTaxPayment?: AchStateTaxPaymentDto;
    achStatusType?: AchStatusTypeDto;
    achTransactionId?: string;
    /** @format int32 */
    approvalsRequired: number;
    approvedBy?: TupleOfStringAndDateTimeDto[];
    balanceValidationNotRequired: boolean;
    batchName?: string;
    /** @format int32 */
    batchUniqueId: number;
    chilAchPayments?: AchPaymentDto[];
    comment?: string;
    companyIdentity?: string;
    /** @format int32 */
    completedApprovals: number;
    copiedFromTransactionId?: string;
    createdBy?: string;
    createdByUser?: UserDto;
    /** @format date-time */
    createdDate: string;
    /** @format decimal */
    credit?: number;
    /** @format decimal */
    debit?: number;
    descretionaryData?: string;
    /** @format date-time */
    effectiveDate: string;
    eligibleApprovers?: string[];
    /** @format date-time */
    endDate?: string;
    entryDescription?: string;
    errorSummary?: ErrorSummaryDto;
    failureReason?: string;
    fileArchive?: FileArchiveDto;
    /** @format int32 */
    fileArchiveId?: number;
    fileName?: string;
    hasAccess: boolean;
    /** @format int32 */
    id: number;
    /** @format date-time */
    initiatedDate?: string;
    /** @format int32 */
    offsetAccountId?: number;
    /** @format decimal */
    onUsAmount?: number;
    /** @format int32 */
    onUsCount: number;
    parentAchPayment?: AchPaymentDto;
    /** @format int32 */
    parentAchPaymentId?: number;
    paymentFrequencyType?: PaymentFrequencyTypeDto;
    /** @format int32 */
    paymentFrequencyTypeId: number;
    paymentOnLastBusinessDay?: boolean;
    paymentProcessingBasedOn1Record?: boolean;
    recipientIdsforReversal?: number[];
    referenceNumber?: string;
    /** @format int32 */
    repeatOnDayOne?: number;
    /** @format int32 */
    repeatOnDayTwo?: number;
    restricted: boolean;
    /** @format int32 */
    reversalAchPaymentId?: number;
    secCode?: string;
    sentFileArchive?: FileArchiveDto;
    /** @format int32 */
    sentFileArchiveId?: number;
    /** @format date-time */
    startDate?: string;
    /** @format int32 */
    statusTypeId: number;
    successMessage?: string;
    updatedBy?: string;
    updatedByUser?: UserDto;
    /** @format date-time */
    updatedDate: string;
    userPermissions?: string[];
    canReverseFull: boolean;
    canReverseTransactions: boolean;
};
export type AchCompanyDto = BaseEntityDto & {
    /** @format int32 */
    achBatchBalanceTypeId: number;
    achBatches?: AchBatchDto[];
    achCompanyId?: string;
    achCompanySecCodes?: AchCompanySecCodeDto[];
    achOffsetAccounts?: AchOffsetAccountDto[];
    achPayments?: AchPaymentDto[];
    /** @format int32 */
    allowedNotOnUsTransactionTypeId: number;
    availableBalanceTable?: string;
    balanceValidationType?: string;
    batchBalanceType?: BatchBalanceTypeDto;
    company?: CompanyDto;
    coreSecCode?: string;
    /** @format decimal */
    dailyLimit: number;
    discretionaryData?: string;
    entryDescription?: string;
    /** @format int32 */
    id: number;
    isPaymentProcessingCompany: boolean;
    paymentProcessingCompanyAllowed: boolean;
    /** @format decimal */
    requiredOnUsAmountPerBatch: number;
    /** @format int32 */
    requiredOnUsTransactionCount: number;
    sameDayAchAllowed: boolean;
    settlementAccount?: string;
    settlementAccountType?: string;
    transactionType?: TransactionTypeDto;
    updatedBy?: string;
    /** @format date-time */
    updatedDate?: string;
    userAchCompanies?: UserAchCompanyDto[];
};
export type AchCompanySecCodeDto = BaseEntityDto & {
    achCompany?: AchCompanyDto;
    achSecCode?: AchSecCodeDto;
    /** @format int32 */
    achSecCodeId: number;
    /** @format int32 */
    companyId: number;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
};
export interface AchSecCodeDto {
    description?: string;
    /** @format int32 */
    id: number;
    secCode?: string;
}
export interface AchOffsetAccountDto {
    account?: AccountDto;
    /** @format int32 */
    accountId: number;
    achCompany?: AchCompanyDto;
    /** @format int32 */
    achCompanyId: number;
    /** @format int32 */
    id: number;
    isActive: boolean;
    /** @format int32 */
    updatedBy: number;
    /** @format date-time */
    updatedDate: string;
    user?: UserDto;
}
export type UserDto = BaseEntityDto & {
    accountLookups?: LookupDto[];
    accountProducts?: AccountProductDto[];
    achOffsetAccounts?: AchOffsetAccountDto[];
    alias?: string;
    associatedClaims?: AssociatedClaimDto[];
    backOfficeSsoEnabled: boolean;
    beneficiaryAudits?: BeneficiaryAuditDto[];
    boReports?: BoReportDto[];
    company?: CompanyDto;
    /** @format int32 */
    companyId?: number;
    contactInfo?: string;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    department?: string;
    email?: string;
    enrolled: boolean;
    favoriteReports?: FavoriteReportDto[];
    fax?: string;
    hasLoggedIn: boolean;
    /** @format int32 */
    id: number;
    importBatchId?: string;
    institution?: Institution2Dto;
    /** @format int32 */
    institutionId: number;
    isAdmin: boolean;
    isEditor: boolean;
    isSuperUser: boolean;
    locked: boolean;
    /** @format int32 */
    loginFailureCount: number;
    mfaLocked: boolean;
    name?: string;
    notificationEmail?: string;
    notificationPhoneCarrier?: TextMessagingCarrierDto;
    /** @format int32 */
    notificationPhoneCarrierId?: number;
    notificationPhoneNumber?: string;
    notificationRecipientId?: string;
    password?: string;
    /** @format date-time */
    passwordDate: string;
    pendingState?: PendingEditStatusDto;
    phoneExtension?: string;
    phoneNumber?: string;
    publishState?: string;
    reportingFilterSorts?: ReportingFilterSortDto[];
    reportingFilters?: ReportingFilterDto[];
    rowStatus?: RowStatusDto;
    rowStatusId: number;
    sessions?: SessionDto[];
    showAccountNickName?: boolean;
    showMaskedAccount?: boolean;
    tempPasswordToken?: string;
    /** @format date-time */
    tempPasswordTokenExpirationDate?: string;
    /** @format date-time */
    termsAcceptedDate?: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    userAccountGroups?: UserAccountGroupDto[];
    userAccounts?: UserAccountDto[];
    userAchCompanies?: UserAchCompanyDto[];
    userAudits?: UserAuditDto[];
    userDevices?: UserDeviceDto[];
    userIpAccessSetting?: UserIpAccessSettingDto;
    userIpAccesses?: UserIpAccessDto[];
    userNotificationTypes?: UserNotificationTypeDto[];
    userNotifications?: UserNotificationDto[];
    userOobDetail?: UserOobDetailDto;
    userPasswordHistories?: UserPasswordHistoryDto[];
    userProductLimits?: UserProductLimitDto[];
    userRoles?: UserRoleDto[];
    userSecureTokenSecurity?: UserSecureTokenSecurityDto;
    userSsoLogins?: UserSsoLoginDto[];
    userTimeAccessSetting?: UserTimeAccessSettingDto;
    userTimeAccesses?: UserTimeAccess3Dto[];
    userType?: UserType1Dto;
    /** @format int32 */
    userTypeId: number;
    userWidgets?: UserWidgetDto[];
    wireAudits?: WireAuditDto[];
};
export interface LookupDto {
    key?: string;
    value?: string;
}
export type AssociatedClaimDto = BaseEntityDto & {
    account?: AccountDto;
    /** @format int32 */
    accountId?: number;
    claim?: ClaimDto;
    /** @format int32 */
    claimId: number;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format date-time */
    endDate?: string;
    /** @format int32 */
    id: number;
    role?: RoleDto;
    /** @format int32 */
    roleId?: number;
    /** @format date-time */
    startDate: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    user?: UserDto;
    /** @format int32 */
    userId?: number;
};
export type ClaimDto = BaseEntityDto & {
    active: boolean;
    associatedClaims?: AssociatedClaimDto[];
    claimNavigations?: ClaimNavigationDto[];
    claimType?: string;
    description?: string;
    /** @format int32 */
    id: number;
    productClaims?: ProductClaimDto[];
    uiPermission?: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    userType?: UserType1Dto;
    /** @format int32 */
    userTypeId: number;
};
export interface ClaimNavigationDto {
    /** @format int32 */
    '<ClaimId>k__BackingField': number;
    /** @format int32 */
    '<ClaimNavigationId>k__BackingField': number;
    '<Claim>k__BackingField': ClaimDto;
    /** @format int32 */
    '<NavigationId>k__BackingField': number;
    '<Navigation>k__BackingField': NavigationDto;
}
export interface NavigationDto {
    applicationType?: ApplicationTypeDto;
    /** @format int32 */
    applicationTypeId?: number;
    childNavigations?: NavigationDto[];
    key?: string;
    name?: string;
    /** @format int32 */
    navigationId: number;
    path?: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
}
export type ApplicationTypeDto = BaseEntityDto & {
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
    navigations?: NavigationDto[];
};
export type ProductClaimDto = BaseEntityDto & {
    claim?: ClaimDto;
    /** @format int32 */
    claimId: number;
    /** @format int32 */
    id: number;
    productType?: ProductTypeDto;
    /** @format int32 */
    productTypeId: number;
};
export type ProductTypeDto = BaseEntityDto & {
    description?: string;
    /** @format int32 */
    id: number;
    institutionProductTypes?: InstitutionProductTypeDto[];
    name?: string;
    productClaims?: ProductClaimDto[];
    products?: ProductDto[];
};
export type InstitutionProductTypeDto = BaseEntityDto & {
    /** @format int32 */
    id: number;
    institution?: Institution2Dto;
    institutionAchProduct?: InstitutionAchProductDto;
    institutionArpProduct?: InstitutionArpProductDto;
    institutionBillPayProduct?: InstitutionBillPayProductDto;
    institutionElectronicDocuments?: InstitutionElectronicDocumentsDto;
    /** @format int32 */
    institutionId: number;
    institutionInternalTransferProduct?: InstitutionInternalTransferProductDto;
    institutionProductCutoffTimes?: InstitutionProductCutoffTimeDto[];
    institutionReportingConfigurations?: InstitutionReportingConfigurationDto[];
    institutionReportingProduct?: InstitutionReportingProductDto;
    institutionSsoProducts?: InstitutionSsoProductDto[];
    institutionStopPayment?: InstitutionStopPaymentDto;
    institutionWireInternationalProduct?: InstitutionWireInternationalProductDto;
    institutionWireProduct?: InstitutionWireProductDto;
    internationalUsdReadOnly: boolean;
    isCompanyProductConfigurable: boolean;
    productType?: ProductTypeDto;
    /** @format int32 */
    productTypeId: number;
    rowStatus?: RowStatusDto;
    rowStatusId: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
};
export type Institution2Dto = BaseEntityDto & {
    achExceptionReturnCode?: string;
    analysisTransactionCodes?: AnalysisTransactionCode2Dto[];
    boReports?: BoReportDto[];
    brandName?: string;
    challengeGroups?: ChallengeGroupDto[];
    companies?: CompanyDto[];
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    fiId?: string;
    fileArchive?: FileArchiveDto;
    /** @format int32 */
    fileArchiveId?: number;
    /** @format int32 */
    id: number;
    informationCenterConfigurations?: InformationCenterConfigurationDto[];
    institutionAccountDisplaySetting?: InstitutionAccountDisplaySettingDto;
    institutionAffiliations?: InstitutionAffiliationDto[];
    institutionMessageCenter?: InstitutionMessageCenterDto;
    institutionNachaFileRecord?: InstitutionNachaFileRecordDto;
    institutionName?: string;
    institutionNotificationTypes?: InstitutionNotificationTypeDto[];
    institutionOobConfiguration?: InstitutionOobConfigurationDto;
    institutionProductTypes?: InstitutionProductTypeDto[];
    institutionTerm?: InstitutionTermDto;
    institutionUserApprovalSetting?: InstitutionUserApprovalSettingDto;
    institutionWireInternationalWorkflowOverrides?: InstitutionWireInternationalWorkflowOverridesDto;
    jxEnvironment?: string;
    jxRoutingNumber?: string;
    /** @format date-time */
    lastEodDate?: string;
    loginConfigurations?: LoginConfigurationDto[];
    logo?: string;
    logoutURL?: string;
    notificationEmail?: string;
    notificationEnabled?: boolean;
    /** @format duration */
    paymentProcessTime: string;
    resourceConfigurations?: ResourceConfigurationDto[];
    roles?: RoleDto[];
    rowStatus?: RowStatusDto;
    rowStatusId: number;
    rsaInstId?: string;
    showCutOffTimeZone?: boolean;
    supportDepartmentName?: string;
    supportEmail?: string;
    supportPhone?: string;
    /** @format int32 */
    timeZoneId: number;
    transactionCodeGroups?: TransactionCodeGroupDto[];
    transactionCodes?: TransactionCodeDto[];
    treasuryProductName?: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    users?: UserDto[];
    welcomeEmailText?: string;
};
export type AnalysisTransactionCode2Dto = BaseEntityDto & {
    active: boolean;
    /** @format int32 */
    analysisTransactionCodeId: number;
    analysisTransactionType?: AnalysisTransactionType2Dto;
    /** @format int32 */
    analysisTransactionTypeId: number;
    institution?: Institution2Dto;
    /** @format int32 */
    institutionId: number;
    transactionCode?: string;
};
export type AnalysisTransactionType2Dto = BaseEntityDto & {
    analysisTransactionCodes?: AnalysisTransactionCode2Dto[];
    analysisTransactionCounts?: AnalysisTransactionCountDto[];
    analysisTransactionTypeCategory?: AnalysisTransactionTypeCategory2Dto;
    /** @format int32 */
    analysisTransactionTypeCategoryId: number;
    /** @format int32 */
    analysisTransactionTypeId: number;
    description?: string;
    /** @format int32 */
    sortOrder: number;
    transactionName?: string;
};
export interface AnalysisTransactionCountDto {
    account?: AccountDto;
    /** @format int32 */
    accountId: number;
    /** @format date-time */
    activityDate: string;
    /** @format int32 */
    analysisTransactionCountId: number;
    analysisTransactionType?: AnalysisTransactionType2Dto;
    /** @format int32 */
    analysisTransactionTypeId?: number;
    /** @format int32 */
    createdBy: number;
    /** @format date-time */
    createdDate: string;
    transactionCode?: string;
    /** @format int32 */
    transactionCount: number;
}
export type AnalysisTransactionTypeCategory2Dto = BaseEntityDto & {
    categoryName?: string;
    /** @format int32 */
    id: number;
    /** @format int32 */
    sortOrder: number;
};
export type BoReportDto = BaseEntityDto & {
    boFavoriteReports?: BoFavoriteReportDto[];
    boReport1?: BoReportDto[];
    boReport2?: BoReportDto;
    boReportColumns?: BoReportColumnDto[];
    boReportFilters?: BoReportFilterDto[];
    customFilter?: string;
    /** @format int32 */
    id: number;
    institution?: Institution2Dto;
    /** @format int32 */
    institutionId?: number;
    isCustomReport: boolean;
    isFavoriteReport?: boolean;
    /** @format int32 */
    ownerId?: number;
    reportName?: string;
    reportType?: string;
    runDefaultSearch: boolean;
    sequences?: BoReportUISequenceDto[];
    /** @format int32 */
    standardReportId?: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    user?: UserDto;
};
export type BoFavoriteReportDto = BaseEntityDto & {
    boReport?: BoReportDto;
    /** @format int32 */
    boReportId: number;
    /** @format int32 */
    id: number;
    user?: UserDto;
    /** @format int32 */
    userId: number;
};
export type BoReportColumnDto = BaseEntityDto & {
    boReport?: BoReportDto;
    /** @format int32 */
    boReportId: number;
    columnName?: string;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    description?: string;
    detailsAccessible: boolean;
    displayName?: string;
    /** @format int32 */
    displayOrder: number;
    displayType?: string;
    /** @format int32 */
    id: number;
    isSortable: boolean;
};
export type BoReportFilterDto = BaseEntityDto & {
    applyCascadeFilter: boolean;
    boReport?: BoReportDto;
    /** @format int32 */
    boReportId: number;
    filterLabel?: string;
    filterModel?: string;
    filterType?: string;
    /** @format int32 */
    id: number;
    /** @format int32 */
    order: number;
    required: boolean;
    triggerCascadeFilter: boolean;
};
export type BoReportUISequenceDto = BaseEntityDto & {
    boReport?: BoReportDto;
    /** @format int32 */
    boReportId: number;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    /** @format int32 */
    order: number;
    selector?: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    user?: UserDto;
    /** @format int32 */
    userId: number;
};
export type ChallengeGroupDto = BaseEntityDto & {
    challengeGroupDetails?: ChallengeGroupDetailDto[];
    companyChallengeGroups?: CompanyChallengeGroupDto[];
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    description?: string;
    /** @format int32 */
    id: number;
    institution?: Institution2Dto;
    /** @format int32 */
    institutionId: number;
    isDefault: boolean;
    name?: string;
    rowStatusId: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
};
export type ChallengeGroupDetailDto = BaseEntityDto & {
    challengeGroup?: ChallengeGroupDto;
    /** @format int32 */
    challengeGroupId: number;
    challengePoint?: ChallengePointDto;
    /** @format int32 */
    challengePointId: number;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    isActivatedByThreshold: boolean;
    isActive: boolean;
    /** @format decimal */
    thresholdAmount?: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
};
export type ChallengePointDto = BaseEntityDto & {
    challengeGroupDetails?: ChallengeGroupDetailDto[];
    challengeLocation?: string;
    challengePointCategory?: ChallengePointCategoryDto;
    /** @format int32 */
    challengePointCategoryId: number;
    challengePointFrequencyType?: ChallengePointFrequencyTypeDto;
    /** @format int32 */
    challengePointFrequencyTypeId: number;
    description?: string;
    hasThreshold: boolean;
    hoverText?: string;
    /** @format int32 */
    id: number;
    name?: string;
    userSessionChallengeStatus?: UserSessionChallengeStatusDto[];
};
export type ChallengePointCategoryDto = BaseEntityDto & {
    challengePoints?: ChallengePointDto[];
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
    /** @format decimal */
    sort: number;
};
export type ChallengePointFrequencyTypeDto = BaseEntityDto & {
    challengePoints?: ChallengePointDto[];
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
};
export interface UserSessionChallengeStatusDto {
    /** @format date-time */
    challengeDate: string;
    /** @format int32 */
    challengeMethodTypeId: number;
    challengePoint?: ChallengePointDto;
    /** @format int32 */
    challengePointId: number;
    /** @format int32 */
    challengeStatusId: number;
    challengeStatusType?: ChallengeStatusTypeDto;
    /** @format int32 */
    id: number;
    /** @format int32 */
    oobReceiptId?: number;
    oobValidationMode?: OobValidationModeDto;
    /** @format int32 */
    oobValidationModeId?: number;
    session?: SessionDto;
    /** @format guid */
    sessionId: string;
}
export type ChallengeStatusTypeDto = BaseEntityDto & {
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
    userSessionChallengeStatus?: UserSessionChallengeStatusDto[];
};
export interface OobValidationModeDto {
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
}
export interface SessionDto {
    browser?: string;
    /** @format guid */
    clientConnectionId?: string;
    clientIpAddress?: string;
    device?: string;
    /** @format guid */
    id: string;
    /** @format date-time */
    latestSessionActivityDate?: string;
    /** @format date-time */
    loginDate: string;
    /** @format date-time */
    logoffDate?: string;
    mfaCompleted: boolean;
    mfaRequired: boolean;
    printMailSSoId?: string;
    printMailUrl?: string;
    secureTokenMfaCompleted: boolean;
    secureTokenMfaRequired: boolean;
    sessionActivities?: SessionActivityDto[];
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    user?: UserDto;
    /** @format int32 */
    userId: number;
    userSessionChallengeStatus?: UserSessionChallengeStatusDto[];
}
export interface SessionActivityDto {
    /** @format guid */
    activityId: string;
    activityName?: string;
    /** @format date-time */
    createdDate: string;
    session?: SessionDto;
    /** @format guid */
    sessionId: string;
}
export type CompanyChallengeGroupDto = BaseEntityDto & {
    challengeGroup?: ChallengeGroupDto;
    /** @format int32 */
    challengeGroupId: number;
    /** @format int32 */
    challengeMethodTypeId: number;
    company?: CompanyDto;
    /** @format int32 */
    id: number;
    /** @format int32 */
    rowStatusId: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
};
export type FileArchiveDto = BaseEntityDto & {
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format byte */
    fileContent?: string;
    fileName?: string;
    /** @format int32 */
    fileTypeId: number;
    fileValidationFailures?: FileValidationFailureDto[];
    /** @format int32 */
    id: number;
    isValid: boolean;
    /** @format guid */
    uniqueId: string;
};
export interface FileValidationFailureDto {
    fileArchive?: FileArchiveDto;
    /** @format int32 */
    fileArchiveId: number;
    fileValidationFailureType?: FileValidationFailureTypeDto;
    /** @format int32 */
    fileValidationFailureTypeId: number;
    /** @format int32 */
    id: number;
    validationFailureJson?: string;
}
export interface FileValidationFailureTypeDto {
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
}
export type InformationCenterConfigurationDto = BaseEntityDto & {
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    description?: string;
    /** @format date-time */
    endDate: string;
    /** @format int32 */
    id: number;
    informationCenterMessageType?: InformationCenterMessageTypeDto;
    /** @format int32 */
    informationCenterMessageTypeId: number;
    institution?: Institution2Dto;
    /** @format int32 */
    institutionId: number;
    rowStatus?: RowStatusDto;
    rowStatusId: number;
    /** @format int32 */
    sequence?: number;
    /** @format date-time */
    startDate: string;
    title?: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
};
export type InformationCenterMessageTypeDto = BaseEntityDto & {
    description?: string;
    /** @format int32 */
    id: number;
    informationCenterConfigurations?: InformationCenterConfigurationDto[];
    name?: string;
};
export type RowStatusDto = BaseEntityDto & {
    accounts?: AccountDto[];
    addresses?: AddressDto[];
    beneficiaries?: BeneficiaryDto[];
    beneficiaryAudits?: BeneficiaryAuditDto[];
    companies?: CompanyDto[];
    description?: string;
    domesticBanks?: DomesticBankDto[];
    id: number;
    informationCenterConfigurations?: InformationCenterConfigurationDto[];
    institutionNotificationTypes?: InstitutionNotificationTypeDto[];
    institutionProductTypes?: InstitutionProductTypeDto[];
    institutions?: Institution2Dto[];
    name?: string;
    products?: ProductDto[];
    resourceConfigurations?: ResourceConfigurationDto[];
    roles?: RoleDto[];
    schedules?: ScheduleDto[];
    userNotificationTypes?: UserNotificationTypeDto[];
    users?: UserDto[];
};
export type AddressDto = BaseEntityDto & {
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    city?: string;
    country?: CountryDto;
    /** @format int32 */
    countryId?: number;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    postalCode?: string;
    postalCodeExtension?: string;
    rowStatus?: RowStatusDto;
    rowStatusId: number;
    /** @format int32 */
    rowVersionId?: number;
    state?: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
};
export type CountryDto = BaseEntityDto & {
    addresses?: AddressDto[];
    countryCode?: string;
    countryName?: string;
    currencyCode?: string;
    currencyName?: string;
    /** @format int32 */
    id: number;
    intradaySpot?: boolean;
};
export type BeneficiaryDto = BaseEntityDto & {
    additionalDetails?: string;
    address?: AddressDto;
    /** @format int32 */
    addressId: number;
    /** @format int32 */
    approvalCount: number;
    approvedBy?: TupleOfStringAndDateTimeDto[];
    beneficiaryAccountNumber?: string;
    beneficiaryAudits?: BeneficiaryAuditDto[];
    beneficiaryName?: string;
    company?: CompanyDto;
    /** @format int32 */
    companyId: number;
    /** @format int32 */
    completedApprovals: number;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    domesticBank?: DomesticBankDto;
    /** @format int32 */
    domesticBankId?: number;
    domesticIntermediaryAccountNumber?: string;
    domesticIntermediaryNotes?: string;
    eligibleApprovers?: string[];
    foreignBankAddress?: AddressDto;
    /** @format int32 */
    foreignBankAddressId?: number;
    foreignBankId?: string;
    foreignBankIdentificationType?: BankIdentificationTypeDto;
    /** @format int32 */
    foreignBankIdentificationTypeId?: number;
    foreignBankName?: string;
    foreignIntermediaryBankAddress?: AddressDto;
    /** @format int32 */
    foreignIntermediaryBankAddressId?: number;
    foreignIntermediaryBankId?: string;
    foreignIntermediaryBankIdentificationType?: BankIdentificationTypeDto;
    /** @format int32 */
    foreignIntermediaryBankIdentificationTypeId?: number;
    foreignIntermediaryBankName?: string;
    foreignIntermediaryNotes?: string;
    /** @format int32 */
    id: number;
    /** @format int32 */
    institutionId: number;
    intermediaryDomesticBank?: DomesticBankDto;
    /** @format int32 */
    intermediaryDomesticBankId?: number;
    isEdited: boolean;
    isForeignCurrency?: boolean;
    isFreeForm: boolean;
    isInternational: boolean;
    isTemplateBeneficiary: boolean;
    rowStatus?: RowStatusDto;
    rowStatusId: number;
    status?: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    useDomesticIntermediary?: boolean;
    useForeignIntermediary?: boolean;
    userDefinedDomesticBankAddress?: AddressDto;
    /** @format int32 */
    userDefinedDomesticBankAddressId?: number;
    userDefinedDomesticBankId?: string;
    userDefinedDomesticBankIdentificationType?: BankIdentificationTypeDto;
    /** @format int32 */
    userDefinedDomesticBankIdentificationTypeId?: number;
    userDefinedDomesticBankName?: string;
    userPermissions?: string[];
    wires?: WireDto[];
};
export interface TupleOfStringAndDateTimeDto {
    item1?: string;
    /** @format date-time */
    item2: string;
}
export type BeneficiaryAuditDto = BaseEntityDto & {
    /** @format date-time */
    actionDate: string;
    /** @format int32 */
    actionUserId: number;
    beneficiary?: BeneficiaryDto;
    beneficiaryActionType?: BeneficiaryActionTypeDto;
    /** @format int32 */
    beneficiaryActionTypeId: number;
    /** @format int32 */
    beneficiaryId: number;
    comments?: string;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    rowStatus?: RowStatusDto;
    rowStatusId: number;
    /** @format int32 */
    rowVersionId: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    user?: UserDto;
};
export type BeneficiaryActionTypeDto = BaseEntityDto & {
    beneficiaryAudits?: BeneficiaryAuditDto[];
    /** @format int32 */
    id: number;
    name?: string;
};
export type DomesticBankDto = BaseEntityDto & {
    addressLine1?: string;
    addressLine2?: string;
    bankName?: string;
    city?: string;
    createdBy?: string;
    /** @format date-time */
    createdDate?: string;
    domesticBankTypeId?: number;
    /** @format int32 */
    id: number;
    isActive: boolean;
    onUs: boolean;
    routingNumber?: string;
    rowStatus?: RowStatusDto;
    rowStatusId?: number;
    /** @format int32 */
    rowVersionId?: number;
    state?: StateDto;
    stateCode?: string;
    /** @format int32 */
    stateId?: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate?: string;
    zipCode?: string;
};
export type StateDto = BaseEntityDto & {
    /** @format int32 */
    id: number;
    stateCode?: string;
    stateName?: string;
};
export type BankIdentificationTypeDto = BaseEntityDto & {
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
};
export type WireDto = BaseWireDto & {
    approvedBy?: TupleOfStringAndDateTimeDto[];
    /** @format int32 */
    completedApprovals: number;
    createdByUser?: UserDto;
    /** @format decimal */
    destinationCurrencyAmount: number;
    eligibleApprovers?: string[];
    errorSummary?: ErrorSummaryDto;
    /** @format decimal */
    exchangeFee: number;
    /** @format decimal */
    totalCost: number;
    updatedByUser?: UserDto;
    userPermissions?: string[];
    wireActionTypeEnum: WireActionTypeEnumDto;
    wireAudits?: WireAuditDto[];
    wireQuotes?: WireQuoteDto[];
};
export interface ErrorSummaryDto {
    details?: ErrorDetailDto[];
    summaryMessage?: string;
    summaryMessageList?: string[];
    type: ErrorTypeDto;
}
export interface ErrorDetailDto {
    attributeName?: string;
    key?: string;
    message?: string;
    messageList?: LookupDto[];
    type: ErrorTypeDto;
}
export enum ErrorTypeDto {
    Error = 0,
    Info = 1,
    Warning = 2,
}
export enum WireActionTypeEnumDto {
    Undefined = 0,
    New = 1,
    Modified = 2,
    Cancelled = 3,
    QuoteRequested = 4,
    QuoteAccepted = 5,
    QuoteRejected = 6,
    Approved = 7,
    Rejected = 8,
    Transmitted = 9,
    Expired = 10,
    Failed = 11,
    Posted = 12,
}
export type WireAuditDto = BaseEntityDto & {
    /** @format date-time */
    actionDate: string;
    /** @format int32 */
    actionUserId: number;
    comments?: string;
    /** @format int32 */
    id: number;
    user?: UserDto;
    wire?: WireDto;
    wireActionType?: WireActionTypeDto;
    /** @format int32 */
    wireActionTypeId: number;
    /** @format int32 */
    wireId: number;
};
export type WireActionTypeDto = BaseEntityDto & {
    /** @format int32 */
    id: number;
    wireActionType1?: string;
    wireAudits?: WireAuditDto[];
};
export type WireQuoteDto = BaseEntityDto & {
    amexContractNumber?: string;
    /** @format int32 */
    amexQuoteId?: number;
    /** @format int32 */
    createdBy: number;
    /** @format date-time */
    createdDate: string;
    createdUser?: UserDto;
    /** @format int32 */
    debitAccountId?: number;
    /** @format decimal */
    destinationCurrencyAmount: number;
    destinationCurrencyCode?: string;
    /** @format decimal */
    exchangeFee: number;
    /** @format decimal */
    exchangeRate: number;
    /** @format date-time */
    expirationDate: string;
    /** @format int32 */
    id: number;
    /** @format int32 */
    institutionFxWireRatesheetId?: number;
    /** @format date-time */
    quoteDate: string;
    /** @format int32 */
    quoteStatusTypeId: number;
    /** @format decimal */
    totalWireCost: number;
    /** @format decimal */
    usdAmount: number;
    /** @format int32 */
    updatedBy: number;
    /** @format date-time */
    updatedDate: string;
    updatedUser?: UserDto;
    wire?: WireDto;
    /** @format decimal */
    wireFee: number;
    wireFeeCurCode?: string;
    /** @format int32 */
    wireId?: number;
    wireQuoteStatusType?: WireQuoteStatusTypeDto;
};
export type WireQuoteStatusTypeDto = BaseEntityDto & {
    /** @format int32 */
    id: number;
    name?: string;
    wireQuotes?: WireQuoteDto[];
};
export type BaseWireDto = BaseEntityDto & {
    account?: AccountDto;
    additionalInformation?: string;
    /** @format decimal */
    amount: number;
    /** @format int32 */
    approvalCount: number;
    associatedWire?: WireDto;
    /** @format int32 */
    associatedWireId?: number;
    beneficiary?: BeneficiaryDto;
    beneficiaryDetails?: string;
    /** @format int32 */
    beneficiaryId: number;
    company?: CompanyDto;
    /** @format int32 */
    companyId: number;
    confirmationNumber?: string;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    debitAccountId: number;
    destinationCurrencyCode?: string;
    /** @format date-time */
    endDate?: string;
    externallyProcessed: boolean;
    failureReason?: string;
    /** @format int32 */
    firstRepeatOnDay?: number;
    /** @format int32 */
    frequencyTypeId: number;
    /** @format int32 */
    id: number;
    isDliWire: boolean;
    isDomestic: boolean;
    parentCompany?: CompanyDto;
    paymentFrequencyType?: PaymentFrequencyTypeDto;
    paymentId?: string;
    purpose?: string;
    referenceBeneficiary?: string;
    referenceNumber?: string;
    repeatOnLastBusinessDay?: boolean;
    /** @format int32 */
    secondRepeatOnDay?: number;
    /** @format date-time */
    startDate?: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    /** @format date-time */
    valueDate?: string;
    /** @format int32 */
    wireCompanyId: number;
    wireStatusType?: WireStatusTypeDto;
    /** @format int32 */
    wireStatusTypeId: number;
    wireTemplate?: WireTemplateDto;
    /** @format int32 */
    wireTemplateId?: number;
};
export type PaymentFrequencyTypeDto = BaseEntityDto & {
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
    schedules?: ScheduleDto[];
    wires?: WireDto[];
};
export type ScheduleDto = BaseEntityDto & {
    /** @format int32 */
    companyId?: number;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format date-time */
    endDate?: string;
    /** @format int32 */
    firstRepeatOn?: number;
    /** @format int32 */
    frequencyId: number;
    /** @format int32 */
    id: number;
    importBatchId?: string;
    institutionFiId?: string;
    /** @format int32 */
    interval?: number;
    isWeekendIncluded?: boolean;
    /** @format date-time */
    lastScheduled?: string;
    pauseSchedule: boolean;
    paymentFrequencyType?: PaymentFrequencyTypeDto;
    productType?: string;
    recycleJob: boolean;
    repeatOnLastBusinessDay?: boolean;
    rowStatus?: RowStatusDto;
    rowStatusId: number;
    /** @format int32 */
    runningTime?: number;
    scheduleName?: string;
    scheduleSystem?: ScheduleSystemDto;
    /** @format int32 */
    scheduleSystemId: number;
    scheduledJobs?: ScheduledJobDto[];
    /** @format int32 */
    secondRepeatOn?: number;
    /** @format date-time */
    startDateTime?: string;
    /** @format int32 */
    systemUniqueId: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    /** @format int32 */
    userId?: number;
    /** @format date-time */
    valueDateTime?: string;
};
export type ScheduleSystemDto = BaseEntityDto & {
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
    schedules?: ScheduleDto[];
};
export type ScheduledJobDto = BaseEntityDto & {
    /** @format guid */
    activityId?: string;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format date-time */
    executionDateTime: string;
    /** @format date-time */
    frequencyTargetDate?: string;
    /** @format int64 */
    id: number;
    jobStatusType?: JobStatusTypeDto;
    /** @format byte */
    jobStatusTypeId: number;
    responseMessages?: string;
    schedule?: ScheduleDto;
    /** @format int32 */
    scheduleId: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
};
export type JobStatusTypeDto = BaseEntityDto & {
    description?: string;
    /** @format byte */
    id: number;
    name?: string;
    scheduledJobs?: ScheduledJobDto[];
};
export type WireStatusTypeDto = BaseEntityDto & {
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
    wires?: WireDto[];
};
export type WireTemplateDto = BaseEntityDto & {
    /** @format int32 */
    approvalCount: number;
    approvedBy?: TupleOfStringAndDateTimeDto[];
    beneficiary?: BeneficiaryDto;
    /** @format int32 */
    beneficiaryId: number;
    /** @format int32 */
    completedApprovals: number;
    /** @format int32 */
    createdBy: number;
    /** @format date-time */
    createdDate: string;
    debitAccount?: AccountDto;
    /** @format int32 */
    debitAccountId: number;
    eligibleApprovers?: string[];
    errorSummary?: ErrorSummaryDto;
    /** @format int32 */
    id: number;
    initiatedRecurringWire: boolean;
    isInternational: boolean;
    notes?: string;
    purpose?: string;
    referenceBeneficiary?: string;
    templateName?: string;
    templateStatusType?: WireTemplateStatusTypeDto;
    /** @format int32 */
    templateStatusTypeId: number;
    /** @format int32 */
    updatedBy: number;
    /** @format date-time */
    updatedDate: string;
    updatedUser?: UserDto;
    userPermissions?: string[];
    wireCompany?: CompanyDto;
    /** @format int32 */
    wireCompanyId: number;
    wireTemplateAudits?: WireTemplateAuditDto[];
};
export type WireTemplateStatusTypeDto = BaseEntityDto & {
    /** @format int32 */
    id: number;
    name?: string;
    wireTemplates?: WireTemplateDto[];
};
export type WireTemplateAuditDto = BaseEntityDto & {
    /** @format date-time */
    actionDate: string;
    actionUser?: UserDto;
    /** @format int32 */
    actionUserId: number;
    /** @format int32 */
    id: number;
    wireTemplateActionType?: WireTemplateActionTypeDto;
    /** @format int32 */
    wireTemplateActionTypeId: number;
    /** @format int32 */
    wireTemplateId: number;
};
export type WireTemplateActionTypeDto = BaseEntityDto & {
    /** @format int32 */
    id: number;
    name?: string;
    wireTemplateAudits?: WireTemplateAuditDto[];
};
export type InstitutionNotificationTypeDto = BaseEntityDto & {
    desktopNotification: boolean;
    email: boolean;
    /** @format int32 */
    id: number;
    institution?: Institution2Dto;
    /** @format int32 */
    institutionId: number;
    isNotificationEnabled: boolean;
    isRequired: boolean;
    notificationGroup?: string;
    notificationType?: NotificationTypeDto;
    /** @format int32 */
    notificationTypeId: number;
    rowStatus?: RowStatusDto;
    rowStatusId: number;
    /** @format int32 */
    sortOrder?: number;
    textMessage: boolean;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
};
export type NotificationTypeDto = BaseEntityDto & {
    description?: string;
    /** @format int32 */
    id: number;
    institutionNotificationTypes?: InstitutionNotificationTypeDto[];
    isActiveByDefault: boolean;
    name?: string;
    notificationPriorityType?: NotificationPriorityTypeDto;
    /** @format int32 */
    notificationPriorityTypeId: number;
    notificationProductType?: NotificationProductTypeDto;
    /** @format int32 */
    notificationProductTypeId: number;
    notifications?: NotificationDto[];
    /** @format int32 */
    sortOrder?: number;
    userNotificationTypes?: UserNotificationTypeDto[];
};
export interface NotificationPriorityTypeDto {
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
    notificationTypes?: NotificationTypeDto[];
}
export type NotificationProductTypeDto = BaseEntityDto & {
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
    notificationTypes?: NotificationTypeDto[];
};
export interface NotificationDto {
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format date-time */
    createdDateFiLocalTime?: string;
    /** @format int32 */
    id: number;
    notificationPayload?: string;
    notificationType?: NotificationTypeDto;
    /** @format int32 */
    notificationTypeId: number;
    /** @format guid */
    notificationUniqueIdentifier: string;
    showAlert: boolean;
    /** @format int32 */
    userNotificationId: number;
}
export type UserNotificationTypeDto = BaseEntityDto & {
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    desktopNotification?: boolean;
    email?: boolean;
    /** @format int32 */
    id: number;
    isDesktopAllowed?: boolean;
    isDesktopRequired: boolean;
    isEmailAllowed?: boolean;
    isEmailRequired: boolean;
    isTextAllowed?: boolean;
    isTextRequired: boolean;
    notificationGroup?: string;
    notificationType?: NotificationTypeDto;
    /** @format int32 */
    notificationTypeId: number;
    rowStatus?: RowStatusDto;
    rowStatusId: number;
    /** @format int32 */
    sortOrder?: number;
    textMessage?: boolean;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    user?: UserDto;
    /** @format int32 */
    userId: number;
};
export type ResourceConfigurationDto = BaseEntityDto & {
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    displayExternalWarning: boolean;
    externalWarningMessage?: string;
    /** @format int32 */
    id: number;
    institution?: Institution2Dto;
    /** @format int32 */
    institutionId: number;
    link?: string;
    /** @format byte */
    logo?: string;
    rowStatus?: RowStatusDto;
    rowStatusId: number;
    /** @format int32 */
    sequence?: number;
    title?: string;
    tooltip?: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
};
export type RoleDto = BaseEntityDto & {
    associatedClaims?: AssociatedClaimDto[];
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    description?: string;
    /** @format int32 */
    id: number;
    institution?: Institution2Dto;
    /** @format int32 */
    institutionId: number;
    name?: string;
    roleType?: RoleTypeDto;
    /** @format int32 */
    roleTypeId: number;
    rowStatus?: RowStatusDto;
    rowStatusId: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    userRoles?: UserRoleDto[];
};
export type RoleTypeDto = BaseEntityDto & {
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
    roles?: RoleDto[];
};
export type UserRoleDto = BaseEntityDto & {
    /** @format date-time */
    endDate?: string;
    /** @format int32 */
    id: number;
    role?: RoleDto;
    /** @format int32 */
    roleId: number;
    /** @format date-time */
    startDate: string;
    user?: UserDto;
    /** @format int32 */
    userId: number;
};
export interface InstitutionAccountDisplaySettingDto {
    accountMaskType?: AccountMaskTypeDto;
    /** @format int32 */
    accountMaskTypeId: number;
    allowOverrideMasking: boolean;
    /** @format int32 */
    createdBy: number;
    createdByUser?: UserDto;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    institution?: Institution2Dto;
    showAccountName: boolean;
    /** @format int32 */
    updatedBy: number;
    updatedByUser?: UserDto;
    /** @format date-time */
    updatedDate: string;
}
export interface AccountMaskTypeDto {
    description?: string;
    /** @format int32 */
    id: number;
}
export interface InstitutionAffiliationDto {
    /** @format int32 */
    affiliatedInstitutionId: number;
    /** @format int32 */
    id: number;
    /** @format int32 */
    institutionId: number;
    /** @format int32 */
    updatedBy: number;
    /** @format date-time */
    updatedDate: string;
}
export type InstitutionMessageCenterDto = BaseEntityDto & {
    archiveSetting?: ArchiveSetting2Dto;
    /** @format int32 */
    archiveSettingId: number;
    disclaimer?: string;
    /** @format int32 */
    id: number;
    institution?: Institution2Dto;
    sharedEmail?: string;
    /** @format int32 */
    updatedBy: number;
    /** @format date-time */
    updatedDate: string;
    updatedUser?: UserDto;
    useSharedEmail: boolean;
};
export interface ArchiveSetting2Dto {
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
}
export interface InstitutionNachaFileRecordDto {
    addendaRecordIndicator?: string;
    blockingFactor?: string;
    checkDigit?: string;
    fileIdModifier?: string;
    formatCode?: string;
    /** @format int32 */
    id: number;
    immediateDestination?: string;
    immediateDestinationName?: string;
    immediateOrigin?: string;
    immediateOriginName?: string;
    institution?: Institution2Dto;
    originatingDfi?: string;
    originatorStatusCode?: string;
    priorityCode?: string;
    receivingDfi?: string;
    recordSize?: string;
}
export interface InstitutionOobConfigurationDto {
    allowedOobChallengeFailureCount: number;
    /** @format int32 */
    id: number;
    institution?: Institution2Dto;
    oobChallengePinNeeded: boolean;
    oobEnabled: boolean;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
}
export type InstitutionTermDto = BaseEntityDto & {
    /** @format int32 */
    id: number;
    institution?: Institution2Dto;
    requireAcceptance: boolean;
    termsAndConditions?: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
};
export interface InstitutionUserApprovalSettingDto {
    boUserRequireBoApproval: boolean;
    chUserRequireBoApproval: boolean;
    chUserRequireChApproval: boolean;
    /** @format int32 */
    id: number;
    institution?: Institution2Dto;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
}
export type InstitutionWireInternationalWorkflowOverridesDto = BaseEntityDto & {
    allowAccountBalanceValidation: boolean;
    /** @format int32 */
    createdBy: number;
    /** @format date-time */
    createdDate: string;
    forceInternationalUsdViaForeignCurrencyWorkflow: boolean;
    /** @format int32 */
    id: number;
    institution?: Institution2Dto;
    rowStatusId: number;
    /** @format int32 */
    updatedBy: number;
    /** @format date-time */
    updatedDate: string;
    useExternalFxQuoteRatesheets: boolean;
    useExternalFxWireProcessing: boolean;
    useNonCoreWireCompanies: boolean;
};
export type LoginConfigurationDto = BaseEntityDto & {
    allowAlphabetsInLoginId: boolean;
    allowAlphabetsInPassword: boolean;
    allowLockedOutMessage: boolean;
    allowNumbersInLoginId: boolean;
    allowNumbersInPassword: boolean;
    allowSpecialCharactersInLoginId: boolean;
    allowSpecialCharactersInPassword: boolean;
    alphabetsInLoginIdAreRequired: boolean;
    alphabetsInPasswordAreRequired: boolean;
    /** @format int32 */
    id: number;
    institution?: Institution2Dto;
    /** @format int32 */
    institutionId: number;
    invalidLoginIdPasswordMessage?: string;
    isCompany: boolean;
    isDefault: boolean;
    lockedEmailMessage?: string;
    lockedOutMessage?: string;
    /** @format int32 */
    loginIdMaxLength: number;
    /** @format int32 */
    loginIdMinLength: number;
    /** @format int32 */
    numberOfAllowedAttemptsBeforeLock: number;
    /** @format int32 */
    numberOfLowercaseRequiredInLoginId: number;
    /** @format int32 */
    numberOfLowercaseRequiredInPassword: number;
    /** @format int32 */
    numberOfPrevUsedPasswords: number;
    /** @format int32 */
    numberOfUppercaseRequiredInLoginId: number;
    /** @format int32 */
    numberOfUppercaseRequiredInPassword: number;
    numbersInLoginIdAreRequired: boolean;
    numbersInPasswordAreRequired: boolean;
    /** @format int32 */
    passwordExpireInDays: number;
    /** @format int32 */
    passwordMaxLength: number;
    /** @format int32 */
    passwordMinLength: number;
    sendLockedEmail: boolean;
    /** @format int32 */
    sessionTimeoutMinutes: number;
    specialCharactersInLoginIdAreRequired: boolean;
    specialCharactersInPasswordAreRequired: boolean;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    /** @format int32 */
    warnPasswordExpireInDays: number;
    willPasswordExpire: boolean;
};
export type TransactionCodeGroupDto = BaseEntityDto & {
    /** @format int32 */
    id: number;
    institution?: Institution2Dto;
    /** @format int32 */
    institutionId: number;
    isReadOnly: boolean;
    name?: string;
    transactionCodeGroupAssociations?: TransactionCodeGroupAssociationDto[];
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
};
export type TransactionCodeGroupAssociationDto = BaseEntityDto & {
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    transactionCode?: TransactionCodeDto;
    transactionCodeGroup?: TransactionCodeGroupDto;
    /** @format int32 */
    transactionCodeGroupId: number;
    /** @format int32 */
    transactionCodeId: number;
};
export type TransactionCodeDto = BaseEntityDto & {
    achTransactionCodes?: AchTransactionCodeDto[];
    /** @format int32 */
    baiCode?: number;
    baiCodeType?: BaiCodeTypeDto;
    changeStatus?: string;
    code?: string;
    description?: string;
    excluded: boolean;
    /** @format int32 */
    id: number;
    institution?: Institution2Dto;
    /** @format int32 */
    institutionId: number;
    isDeleted: boolean;
    isDescriptionChanged: boolean;
    isTypeChanged: boolean;
    transactionCodeGroupAssociations?: TransactionCodeGroupAssociationDto[];
    type?: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
};
export interface AchTransactionCodeDto {
    accountType?: AccountTypeDto;
    /** @format int32 */
    accountTypeId: number;
    /** @format int32 */
    id: number;
    transactionCode?: TransactionCodeDto;
    /** @format int32 */
    transactionCodeId: number;
    transactionType?: TransactionTypeDto;
    /** @format int32 */
    transactionTypeId: number;
}
export type TransactionTypeDto = BaseEntityDto & {
    achBatchRecipients?: AchBatchRecipientDto[];
    achCompanies?: AchCompanyDto[];
    achPaymentRecipients?: AchPaymentRecipientDto[];
    achTransactionCodes?: AchTransactionCodeDto[];
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
};
export type AchPaymentRecipientDto = BaseEntityDto & {
    accountNumber?: string;
    accountType?: AccountTypeDto;
    /** @format int32 */
    accountTypeId: number;
    /** @format int32 */
    achMasterRecipientId?: number;
    achPayment?: AchPaymentDto;
    /** @format int32 */
    achPaymentId: number;
    achPaymentRecipientAddendas?: AchPaymentRecipientAddendaDto[];
    addenda?: string;
    /** @format decimal */
    amount: number;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    hold: boolean;
    /** @format int32 */
    id: number;
    isRoutingNumberOnUs: boolean;
    isRoutingNumberValid: boolean;
    preNote: boolean;
    recipientIdNumber?: string;
    recipientName?: string;
    routingNumber?: string;
    /** @format int32 */
    rowNum?: number;
    transactionType?: TransactionTypeDto;
    /** @format int32 */
    transactionTypeId: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
};
export type AchPaymentRecipientAddendaDto = BaseEntityDto & {
    /** @format int32 */
    achPaymentId: number;
    achPaymentRecipient?: AchPaymentRecipientDto;
    /** @format int32 */
    achPaymentRecipientId: number;
    addenda?: string;
    addendaType?: AddendaTypeDto;
    /** @format int32 */
    addendaTypeId: number;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    /** @format int32 */
    rowNum?: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
};
export interface AddendaTypeDto {
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
}
export type BaiCodeTypeDto = BaseEntityDto & {
    /** @format int32 */
    baiCode: number;
    /** @format int32 */
    baiCodeTitleId: number;
    description?: string;
    isSelected: boolean;
    level?: string;
    transactionType?: string;
};
export interface InstitutionAchProductDto {
    accountBalanceValidationConfiguredInCore: boolean;
    achCompanyMatchType?: AchCompanyMatchTypeDto;
    /** @format int32 */
    achCompanyMatchTypeId: number;
    allowFederalTaxPayments: boolean;
    allowSameDayAch: boolean;
    allowStateTaxPayments: boolean;
    allowTaxPayments: boolean;
    /** @format int32 */
    batchApprovalCount: number;
    batchAuthenticationType?: AuthenticationTypeDto;
    /** @format int32 */
    batchAuthenticationTypeId: number;
    copyInitiatedNachaFiles?: boolean;
    /** @format int32 */
    daysInAdvance?: number;
    /** @format int32 */
    id: number;
    institutionAchRoutings?: InstitutionAchRoutingDto[];
    institutionAchSecCodes?: InstitutionAchSecCodeDto[];
    institutionProductType?: InstitutionProductTypeDto;
    internationalEnabled: boolean;
    nachaFileUploadPath?: string;
    /** @format int32 */
    paymentApprovalCount: number;
    paymentAuthenticationType?: AuthenticationTypeDto;
    /** @format int32 */
    paymentAuthenticationTypeId: number;
    paymentProcessingCompanyConfiguredInCore?: boolean;
    /** @format int32 */
    recipientApprovalCount: number;
    remoteServerHost?: string;
    remoteServerPassword?: string;
    /** @format int32 */
    remoteServerPort?: number;
    remoteServerUserName?: string;
    stateTaxCodesArchiveFile?: FileArchiveDto;
    /** @format int32 */
    stateTaxCodesArchiveFileId?: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    validateAccountBalance: boolean;
}
export type AchCompanyMatchTypeDto = BaseEntityDto & {
    description?: string;
    /** @format int32 */
    id: number;
    institutionAchProducts?: InstitutionAchProductDto[];
    name?: string;
};
export type InstitutionAchRoutingDto = BaseEntityDto & {
    bankName?: string;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format date-time */
    endDate?: string;
    /** @format int32 */
    id: number;
    institutionAchProduct?: InstitutionAchProductDto;
    /** @format int32 */
    institutionProductTypeId: number;
    routingNumber?: string;
    /** @format date-time */
    startDate: string;
};
export type InstitutionAchSecCodeDto = BaseEntityDto & {
    achSecCode?: AchSecCodeDto;
    /** @format int32 */
    achSecCodeId: number;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    institutionAchProduct?: InstitutionAchProductDto;
    /** @format int32 */
    institutionProductTypeId: number;
};
export interface InstitutionArpProductDto {
    allowArpExceptionCorrections: boolean;
    allowSubstituteProduct: boolean;
    /** @format int32 */
    id: number;
    institutionProductType?: InstitutionProductTypeDto;
    manageAchExceptions: boolean;
    showAchExceptions: boolean;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    workAchException: boolean;
    workCheckException: boolean;
}
export interface InstitutionBillPayProductDto {
    billPayServiceName?: string;
    billPayServiceUrl?: string;
    clientId?: string;
    /** @format int32 */
    id: number;
    institutionProductType?: InstitutionProductTypeDto;
    landingPageUrl?: string;
    /** @format int32 */
    navigationMode?: number;
    sharedKey?: string;
    /** @format int32 */
    updatedBy: number;
    /** @format date-time */
    updatedDate: string;
}
export interface InstitutionElectronicDocumentsDto {
    clientId?: string;
    electronicDocumentServiceName?: string;
    electronicDocumentServiceUri?: string;
    electronicDocumentType?: ElectronicDocumentTypeDto;
    /** @format int32 */
    electronicDocumentTypeId?: number;
    /** @format int32 */
    id: number;
    institutionProductType?: InstitutionProductTypeDto;
    /** @format double */
    monthlyFee: number;
    /** @format int32 */
    navigationMode?: number;
    sharedKey?: string;
    showSsoFields?: boolean;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
}
export interface ElectronicDocumentTypeDto {
    description?: string;
    /** @format int32 */
    id: number;
    institutionElectronicDocuments?: InstitutionElectronicDocumentsDto[];
    name?: string;
}
export type InstitutionInternalTransferProductDto = BaseEntityDto & {
    allowLoanPaymentPastDue: boolean;
    /** @format int32 */
    id: number;
    institutionInternalTransferProductLinkAccounts?: InstitutionInternalTransferProductLinkAccountDto[];
    institutionProductType?: InstitutionProductTypeDto;
};
export type InstitutionInternalTransferProductLinkAccountDto = BaseEntityDto & {
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    fromAccountTypeId: number;
    /** @format int32 */
    id: number;
    institutionInternalTransferProduct?: InstitutionInternalTransferProductDto;
    /** @format int32 */
    institutionProductTypeId: number;
    /** @format int32 */
    toAccountTypeId: number;
};
export type InstitutionProductCutoffTimeDto = BaseEntityDto & {
    /** @format duration */
    blackoutPeriodEndTime?: string;
    /** @format duration */
    blackoutPeriodStartTime?: string;
    cutoffTimeSubType?: CutoffTimeSubTypeDto;
    /** @format int32 */
    cutoffTimeSubTypeId?: number;
    /** @format duration */
    endCutoffTime: string;
    /** @format int32 */
    id: number;
    institutionProductType?: InstitutionProductTypeDto;
    /** @format int32 */
    institutionProductTypeId: number;
    processingDayType?: ProcessingDayTypeDto;
    /** @format int32 */
    processingDayTypeId?: number;
    showInChannel?: boolean;
    /** @format duration */
    startCutoffTime?: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
};
export type CutoffTimeSubTypeDto = BaseEntityDto & {
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
};
export type ProcessingDayTypeDto = BaseEntityDto & {
    /** @format int32 */
    id: number;
    institutionProductCutoffTimes?: InstitutionProductCutoffTimeDto[];
    processingDay?: string;
};
export type InstitutionReportingConfigurationDto = BaseEntityDto & {
    hasBai: boolean;
    hasCsv: boolean;
    hasDownload: boolean;
    hasEmail: boolean;
    hasPdf: boolean;
    hasQuickBooks: boolean;
    hasQuicken: boolean;
    hasTiff: boolean;
    hasWord: boolean;
    /** @format int32 */
    id: number;
    institutionProductType?: InstitutionProductTypeDto;
    /** @format int32 */
    institutionProductTypeId: number;
    /** @format int32 */
    standardReportId: number;
    supportsBai: boolean;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
};
export type InstitutionReportingProductDto = BaseEntityDto & {
    channelMobile: boolean;
    channelTablet: boolean;
    channelWeb: boolean;
    exportFormats?: ReportExportFormatDto[];
    /** @format int32 */
    id: number;
    institutionProductType?: InstitutionProductTypeDto;
};
export interface ReportExportFormatDto {
    isConfigured: boolean;
    name?: string;
}
export type InstitutionSsoProductDto = BaseEntityDto & {
    clientId?: string;
    /** @format int32 */
    id: number;
    institutionProductType?: InstitutionProductTypeDto;
    /** @format int32 */
    institutionProductTypeId: number;
    landingPageUrl?: string;
    rowStatusId: number;
    serviceUrl?: string;
    sharedEncryptionInitializationVector?: string;
    sharedEncryptionKey?: string;
    ssoLoginSystem?: SsoLoginSystemDto;
    /** @format int32 */
    ssoLoginSystemId: number;
    /** @format int32 */
    updatedBy: number;
    /** @format date-time */
    updatedDate: string;
    user?: UserDto;
};
export type SsoLoginSystemDto = BaseEntityDto & {
    description?: string;
    institutionSsoProducts?: InstitutionSsoProductDto[];
    name?: string;
    /** @format int32 */
    ssoLoginSystemId: number;
    userSsoLogins?: UserSsoLoginDto[];
};
export type UserSsoLoginDto = BaseEntityDto & {
    /** @format int32 */
    rowStatusId: number;
    ssoAccessKey?: string;
    ssoLoginId?: string;
    ssoLoginSystem?: SsoLoginSystemDto;
    /** @format int32 */
    ssoLoginSystemId: number;
    /** @format int32 */
    updatedBy: number;
    /** @format date-time */
    updatedDate: string;
    user?: UserDto;
    /** @format int32 */
    userId: number;
    /** @format int32 */
    userSsoLoginId: number;
};
export interface InstitutionStopPaymentDto {
    approvalCount: number;
    /** @format double */
    feePerRequest: number;
    /** @format int32 */
    id: number;
    institutionProductType?: InstitutionProductTypeDto;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
}
export type InstitutionWireInternationalProductDto = BaseEntityDto & {
    /** @format int32 */
    beneficiaryApprovalCount: number;
    /** @format decimal */
    dailyLimit: number;
    fxClientId?: string;
    /** @format int32 */
    id: number;
    institutionProductType?: InstitutionProductTypeDto;
    institutionWirePaymentApprovalSettings?: InstitutionWirePaymentApprovalSettingDto[];
    internationalUsdReadOnly: boolean;
    /** @format byte */
    outgoingSettlementTypeId: number;
    /** @format int32 */
    paymentApprovalCount: number;
    /** @format byte */
    paymentApprovalType: number;
    requirePaymentPurpose: boolean;
    /** @format int32 */
    updatedBy: number;
    /** @format date-time */
    updatedDate: string;
    updatedUser?: UserDto;
    validateAccountBalance?: boolean;
    wireSettlementAccount?: string;
    wireTraditionalInternationalEnabled: boolean;
    wireUsdEnabled: boolean;
};
export interface InstitutionWirePaymentApprovalSettingDto {
    /** @format byte */
    approvalCount: number;
    /** @format decimal */
    endAmount?: number;
    /** @format int32 */
    id: number;
    institutionProductType?: InstitutionProductTypeDto;
    /** @format int32 */
    institutionProductTypeId: number;
    /** @format decimal */
    startAmount: number;
    /** @format int32 */
    updatedBy: number;
    /** @format date-time */
    updatedDate: string;
    user?: UserDto;
}
export type InstitutionWireProductDto = BaseEntityDto & {
    /** @format int32 */
    beneficiaryApprovalCount: number;
    beneficiaryAuthenticationType?: AuthenticationTypeDto;
    /** @format int32 */
    beneficiaryAuthenticationTypeId?: number;
    /** @format decimal */
    dailyLimit: number;
    /** @format int32 */
    id: number;
    institutionProductType?: InstitutionProductTypeDto;
    institutionWirePaymentApprovalSettings?: InstitutionWirePaymentApprovalSettingDto[];
    internationalEnabled: boolean;
    limitFutureDateEnabled: boolean;
    /** @format int32 */
    maximumFutureDays?: number;
    /** @format int32 */
    paymentApprovalCount: number;
    /** @format byte */
    paymentApprovalType?: number;
    paymentAuthenticationType?: AuthenticationTypeDto;
    /** @format int32 */
    paymentAuthenticationTypeId?: number;
    requirePaymentPurpose?: boolean;
    /** @format byte */
    templateApprovalCount?: number;
    /** @format int32 */
    updatedBy: number;
    /** @format date-time */
    updatedDate: string;
    updatedUser?: UserDto;
    wireDliInternationalEnabled: boolean;
};
export type UserType1Dto = BaseEntityDto & {
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
    users?: UserDto[];
};
export type FavoriteReportDto = BaseEntityDto & {
    /** @format int32 */
    id: number;
    reportingFilter?: ReportingFilterDto;
    /** @format int32 */
    reportingFilterId: number;
    user?: UserDto;
    /** @format int32 */
    userId: number;
};
export type ReportingFilterDto = BaseEntityDto & {
    accountTypes?: string;
    amountOperator?: string;
    companyUniqueId?: string;
    dateType?: string;
    favoriteReports?: FavoriteReportDto[];
    /** @format decimal */
    fromAmount?: number;
    /** @format date-time */
    fromDate?: string;
    fromPaymentId?: string;
    /** @format int32 */
    id: number;
    /** @format int32 */
    institutionId?: number;
    isAscendingSort: boolean;
    isFavorite: boolean;
    isStandardReport: boolean;
    name?: string;
    /** @format int32 */
    owner?: number;
    paymentIdOperator?: string;
    paymentTypes?: FilterPaymentTypeDto[];
    postedTransactions?: boolean;
    /** @format int32 */
    priorDays?: number;
    reportType?: string;
    reportingBalanceFilter?: ReportingBalanceFilterDto;
    reportingConfigurations?: ReportingConfigurationDto[];
    reportingFilter1?: ReportingFilterDto[];
    reportingFilter2?: ReportingFilterDto;
    reportingFilterAccounts?: ReportingFilterAccountDto[];
    reportingFilterPaymentTypes?: ReportingFilterPaymentTypeDto[];
    reportingFilterSorts?: ReportingFilterSortDto[];
    reportingTransactionFilter?: ReportingTransactionFilterDto;
    /** @format int32 */
    sequence?: number;
    sortBy?: string;
    /** @format date-time */
    specificDate?: string;
    /** @format int32 */
    standardReportTypeId?: number;
    /** @format decimal */
    toAmount?: number;
    /** @format date-time */
    toDate?: string;
    toPaymentId?: string;
    transactionDirection?: string;
    transactionType?: string;
    type?: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    user?: UserDto;
};
export interface FilterPaymentTypeDto {
    type?: string;
}
export type ReportingBalanceFilterDto = BaseEntityDto & {
    /** @format int32 */
    id: number;
    reportingFilter?: ReportingFilterDto;
};
export type ReportingConfigurationDto = BaseEntityDto & {
    hasBai: boolean;
    hasCsv: boolean;
    hasDownload: boolean;
    hasEmail: boolean;
    hasPdf: boolean;
    hasQuickBooks: boolean;
    hasQuicken: boolean;
    hasTiff: boolean;
    hasWord: boolean;
    /** @format int32 */
    id: number;
    product?: ProductDto;
    /** @format int32 */
    productId: number;
    reportingFilter?: ReportingFilterDto;
    /** @format int32 */
    standardReportId: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
};
export type ReportingFilterAccountDto = BaseEntityDto & {
    account?: AccountDto;
    /** @format int32 */
    accountId: number;
    /** @format int32 */
    id: number;
    reportingFilter?: ReportingFilterDto;
    /** @format int32 */
    reportingFilterId: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
};
export type ReportingFilterPaymentTypeDto = BaseEntityDto & {
    /** @format int32 */
    id: number;
    paymentType?: string;
    reportingFilter?: ReportingFilterDto;
    /** @format int32 */
    reportingFilterId: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
};
export interface ReportingFilterSortDto {
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    reportingFilter?: ReportingFilterDto;
    /** @format int32 */
    reportingFilterId: number;
    /** @format int32 */
    sequence: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    user?: UserDto;
    /** @format int32 */
    userId: number;
}
export type ReportingTransactionFilterDto = BaseEntityDto & {
    amountOperator?: string;
    /** @format decimal */
    fromAmount?: number;
    fromPaymentId?: string;
    /** @format int32 */
    id: number;
    paymentIdOperator?: string;
    reportingFilter?: ReportingFilterDto;
    /** @format decimal */
    toAmount?: number;
    toPaymentId?: string;
    transactionDirection?: string;
    transactionType?: string;
};
export type TextMessagingCarrierDto = BaseEntityDto & {
    active: boolean;
    /** @format int32 */
    id: number;
    name?: string;
    providerId?: string;
    serviceId?: string;
    serviceTypeId?: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
};
export enum PendingEditStatusDto {
    Edited = 1,
    Pending = 2,
    Approved = 3,
    Discard = 4,
    Rejected = 5,
    FiPending = 6,
}
export type UserAccountGroupDto = BaseEntityDto & {
    accountGroupName?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    /** @format byte */
    sequency: number;
    /** @format date-time */
    updatedDate: string;
    user?: UserDto;
    userAccountGroupAccounts?: UserAccountGroupAccountDto[];
    /** @format int32 */
    userId: number;
};
export type UserAccountGroupAccountDto = BaseEntityDto & {
    account?: AccountDto;
    /** @format int32 */
    accountId: number;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    /** @format int32 */
    sequence: number;
    userAccountGroup?: UserAccountGroupDto;
    /** @format int32 */
    userAccountGroupId: number;
};
export type UserAccountDto = BaseEntityDto & {
    account?: AccountDto;
    /** @format int32 */
    accountId: number;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    user?: UserDto;
    userAccountLimits?: UserAccountLimitDto[];
    /** @format int32 */
    userId: number;
};
export type UserAccountLimitDto = BaseEntityDto & {
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format decimal */
    dailyLimit: number;
    /** @format int32 */
    id: number;
    /** @format decimal */
    perTransactionLimit: number;
    productType?: ProductTypeDto;
    /** @format int32 */
    productTypeId: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    userAccount?: UserAccountDto;
    /** @format int32 */
    userAccountId: number;
};
export type UserAchCompanyDto = BaseEntityDto & {
    achCompany?: AchCompanyDto;
    /** @format int32 */
    achCompanyId: number;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format decimal */
    dailyLimit?: number;
    /** @format int32 */
    id: number;
    /** @format decimal */
    perTransactionLimit?: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    user?: UserDto;
    /** @format int32 */
    userId: number;
};
export type UserAuditDto = BaseEntityDto & {
    comment?: string;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    user?: UserDto;
    /** @format int32 */
    userId: number;
};
export type UserDeviceDto = BaseEntityDto & {
    authenticationId?: string;
    /** @format int32 */
    id: number;
    key?: string;
    /** @format date-time */
    keyCreatedDate: string;
    /** @format date-time */
    keyLastUsedDate: string;
    user?: UserDto;
    /** @format int32 */
    userId: number;
};
export type UserIpAccessSettingDto = BaseEntityDto & {
    /** @format int32 */
    createdBy: number;
    /** @format date-time */
    createdDate: string;
    restricted?: boolean;
    /** @format int32 */
    updatedBy: number;
    /** @format date-time */
    updatedDate: string;
    user?: UserDto;
    /** @format int32 */
    userId: number;
};
export type UserIpAccessDto = BaseEntityDto & {
    /** @format int32 */
    createdBy: number;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    ipAddress?: string;
    nickname?: string;
    /** @format int32 */
    updatedBy: number;
    /** @format date-time */
    updatedDate: string;
    user?: UserDto;
    /** @format int32 */
    userId: number;
};
export type UserNotificationDto = BaseEntityDto & {
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    isEmailSent: boolean;
    isRead: boolean;
    isTextMessageSent: boolean;
    notification?: NotificationDto;
    /** @format int32 */
    notificationId: number;
    notificationStatusType?: NotificationStatusTypeDto;
    /** @format int32 */
    notificationStatusTypeId: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    user?: UserDto;
    /** @format int32 */
    userId: number;
};
export interface NotificationStatusTypeDto {
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
}
export interface UserOobDetailDto {
    audioPhoneNumber?: string;
    challengePin?: string;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    isLocked: boolean;
    isOobPinCollected: boolean;
    /** @format date-time */
    localSuspendEndDate?: string;
    needCollection: boolean;
    oobAuthCount?: number;
    oobValidationMode?: OobValidationModeDto;
    /** @format int32 */
    preferredOobValidationModeId: number;
    remindMeCount?: number;
    smsPhoneNumber?: string;
    /** @format date-time */
    suspendEndDate?: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    user?: UserDto;
}
export type UserPasswordHistoryDto = BaseEntityDto & {
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    password?: string;
    user?: UserDto;
    /** @format int32 */
    userId: number;
};
export type UserProductLimitDto = BaseEntityDto & {
    /** @format decimal */
    approvalLimit?: number;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    /** @format decimal */
    initiationLimit?: number;
    product?: ProductDto;
    /** @format int32 */
    productId: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    user?: UserDto;
    /** @format int32 */
    userId: number;
};
export interface UserSecureTokenSecurityDto {
    challengePin?: string;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    isLocked: boolean;
    /** @format date-time */
    localSuspendEndDate?: string;
    needCollection: boolean;
    remindMeCount: number;
    /** @format date-time */
    suspendEndDate?: string;
    tokenAuthFailureCount: number;
    tokenCredentialId?: string;
    tokenVendorUserId?: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    user?: UserDto;
}
export type UserTimeAccessSettingDto = BaseEntityDto & {
    /** @format int32 */
    createdBy: number;
    /** @format date-time */
    createdDate: string;
    restricted?: boolean;
    /** @format int32 */
    updatedBy: number;
    /** @format date-time */
    updatedDate: string;
    user?: UserDto;
    /** @format int32 */
    userId: number;
};
export type UserTimeAccess3Dto = BaseEntityDto & {
    /** @format int32 */
    createdBy: number;
    /** @format date-time */
    createdDate: string;
    dayOfWeekId: number;
    /** @format duration */
    endTime?: string;
    /** @format int32 */
    id: number;
    /** @format duration */
    startTime?: string;
    /** @format int32 */
    updatedBy: number;
    /** @format date-time */
    updatedDate: string;
    user?: UserDto;
    /** @format int32 */
    userId: number;
    userTimeAccessTypeId: number;
};
export type UserWidgetDto = BaseEntityDto & {
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    height: number;
    /** @format int32 */
    id: number;
    positionLeft: number;
    positionTop: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    /** @format int32 */
    userId: number;
    widget?: WidgetDto;
    /** @format int32 */
    widgetId: number;
    width: number;
};
export type WidgetDto = BaseEntityDto & {
    defaultContent?: string;
    defaultHeight?: number;
    defaultLeft?: number;
    defaultTop?: number;
    defaultWidth?: number;
    description?: string;
    hasPermission: boolean;
    /** @format int32 */
    id: number;
    imageUrl?: string;
    /** @format int32 */
    institutionId: number;
    isFullScreenAllowed: boolean;
    isPermissionRequired: boolean;
    isRequired: boolean;
    isResizable: boolean;
    maxHeight?: number;
    maxWidth?: number;
    minHeight?: number;
    minWidth?: number;
    name?: string;
    permissionMessage?: string;
    title?: string;
    userWidgets?: UserWidgetDto[];
};
export interface BatchBalanceTypeDto {
    achCompanies?: AchCompanyDto[];
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
}
export interface AchFederalTaxPaymentDto {
    achFederalTaxPaymentAmounts?: AchFederalTaxPaymentAmountDto[];
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    payFromAccount?: AccountDto;
    /** @format int32 */
    payFromAccountId: number;
    recipientAccountNumber?: string;
    recipientAccountType?: AccountTypeDto;
    /** @format int32 */
    recipientAccountTypeId: number;
    recipientIdNumber?: string;
    recipientName?: string;
    recipientRoutingNumber?: string;
    taxCode?: string;
    taxPayerId?: string;
    /** @format date-time */
    taxPeriodEndDate: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
}
export interface AchFederalTaxPaymentAmountDto {
    achFederalAmountType?: AchFederalAmountTypeDto;
    /** @format int32 */
    achFederalAmountTypeId?: number;
    /** @format int32 */
    achPaymentId: number;
    /** @format decimal */
    amount: number;
    amountType?: string;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
}
export interface AchFederalAmountTypeDto {
    amountType?: string;
    description?: string;
    /** @format int32 */
    id: number;
}
export interface AchNachaFileCopyAuditDto {
    /** @format int32 */
    '<AchPaymentId>k__BackingField': number;
    '<AchPayment>k__BackingField': AchPaymentDto;
    '<CreatedByUser>k__BackingField': UserDto;
    /** @format int32 */
    '<CreatedBy>k__BackingField': number;
    /** @format date-time */
    '<CreatedDate>k__BackingField': string;
    '<FileCopyStatus>k__BackingField': string;
    /** @format int32 */
    '<Id>k__BackingField': number;
    '<Message>k__BackingField': string;
    '<NachaFileName>k__BackingField': string;
}
export interface AchPaymentTypeDto {
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
}
export interface AchStateTaxPaymentDto {
    achStateTaxPaymentAmounts?: AchStateTaxPaymentAmountDto[];
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    payFromAccount?: AccountDto;
    /** @format int32 */
    payFromAccountId: number;
    recipientAccountNumber?: string;
    recipientAccountType?: AccountTypeDto;
    /** @format int32 */
    recipientAccountTypeId: number;
    recipientIdNumber?: string;
    recipientName?: string;
    recipientRoutingNumber?: string;
    state?: StateDto;
    /** @format int32 */
    stateId?: number;
    taxCode?: string;
    taxPayerId?: string;
    /** @format date-time */
    taxPeriodEndDate: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
}
export interface AchStateTaxPaymentAmountDto {
    /** @format int32 */
    achPaymentId: number;
    achStateAmountType?: AchStateAmountTypeDto;
    /** @format int32 */
    achStateAmountTypeId: number;
    /** @format decimal */
    amount: number;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
}
export interface AchStateAmountTypeDto {
    amountType?: string;
    description?: string;
    /** @format int32 */
    id: number;
}
export interface AchStatusTypeDto {
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
}
export type BatchStatusTypeDto = BaseEntityDto & {
    achBatches?: AchBatchDto[];
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
};
export type AchBatchRecipientAddendaDto = BaseEntityDto & {
    /** @format int32 */
    achBatchId?: number;
    achBatchRecipient?: AchBatchRecipientDto;
    /** @format int32 */
    achBatchRecipientId: number;
    addenda?: string;
    addendaType?: AddendaTypeDto;
    /** @format int32 */
    addendaTypeId: number;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    /** @format int32 */
    rowNum?: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
};
export interface InternalTransferProductDto {
    allowLoanPaymentPastDue: boolean;
    /** @format int32 */
    approvalCount?: number;
    /** @format int32 */
    authenticationTypeId?: number;
    /** @format decimal */
    dailyLimit?: number;
    /** @format int32 */
    id: number;
    internalTransferProductLinkAccounts?: InternalTransferProductLinkAccountDto[];
    product?: ProductDto;
    /** @format byte */
    templateApprovalCount?: number;
}
export type WireInternationalProductDto = BaseEntityDto & {
    /** @format int32 */
    beneficiaryApprovalCount: number;
    /** @format decimal */
    dailyLimit: number;
    /** @format int32 */
    id: number;
    internationalUsd: boolean;
    internationalUsdReadOnly: boolean;
    /** @format int32 */
    paymentApprovalCount: number;
    /** @format byte */
    paymentApprovalType: number;
    product?: ProductDto;
    requirePaymentPurpose: boolean;
    /** @format int32 */
    updatedBy: number;
    /** @format date-time */
    updatedDate: string;
    updatedUser?: UserDto;
    validateAccountBalance?: boolean;
    wirePaymentApprovalSettings?: WirePaymentApprovalSettingDto[];
    wireUsdEnabled: boolean;
};
export interface WirePaymentApprovalSettingDto {
    /** @format byte */
    approvalCount: number;
    /** @format decimal */
    endAmount?: number;
    /** @format int32 */
    id: number;
    product?: ProductDto;
    /** @format int32 */
    productId: number;
    /** @format decimal */
    startAmount: number;
    /** @format int32 */
    updatedBy: number;
    /** @format date-time */
    updatedDate: string;
    user?: UserDto;
    wireProduct?: WireProductDto;
}
export type WireProductDto = BaseEntityDto & {
    /** @format int32 */
    beneficiaryApprovalCount: number;
    /** @format int32 */
    beneficiaryAuthenticationTypeId: number;
    /** @format decimal */
    dailyLimit: number;
    /** @format int32 */
    farthestWireExistInDays: number;
    /** @format int32 */
    id: number;
    internationalEnabled: boolean;
    internationalUsdReadOnly: boolean;
    isCompanyDliUsdEnabled: boolean;
    isCompanyWireProductEnabled: boolean;
    isDliCompanyProductEnabled: boolean;
    isFiDliProductEnabled: boolean;
    isFiDliUsdEnabled: boolean;
    isFiInternationalEnabled: boolean;
    isFiWireProductEnabled: boolean;
    isInternationalUsdEnabled: boolean;
    limitFutureDateEnabled: boolean;
    /** @format int32 */
    maximumFutureDays?: number;
    /** @format int32 */
    paymentApprovalCount: number;
    /** @format byte */
    paymentApprovalType?: number;
    /** @format int32 */
    paymentAuthenticationTypeId: number;
    product?: ProductDto;
    requirePaymentPurpose?: boolean;
    requirePaymentPurposeDli: boolean;
    /** @format byte */
    templateApprovalCount?: number;
    /** @format int32 */
    updatedBy: number;
    /** @format date-time */
    updatedDate: string;
    updatedUser?: UserDto;
    wirePaymentApprovalSettings?: WirePaymentApprovalSettingDto[];
};
export type ArpExceptionCorrectionDto = BaseEntityDto & {
    account?: AccountDto;
    /** @format int32 */
    accountId: number;
    /** @format decimal */
    amount: number;
    arpException?: ArpExceptionDto;
    /** @format int32 */
    arpExceptionId: number;
    checkNumber?: string;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    /** @format date-time */
    issuedDate?: string;
    payee?: string;
};
export type ArpExceptionDto = BaseEntityDto & {
    account?: AccountDto;
    /** @format int32 */
    accountId: number;
    activityIntentKey?: string;
    arpDecision?: ArpDecisionDto;
    /** @format int32 */
    arpDecisionId?: number;
    arpExceptionCorrections?: ArpExceptionCorrectionDto[];
    checkImageNumber?: string;
    checkNumber?: string;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    ddaBatchNumber?: string;
    ddaSequenceNumber?: string;
    /** @format date-time */
    decisionDate?: string;
    fromworkStation?: string;
    /** @format int32 */
    id: number;
    isDebit: boolean;
    /** @format decimal */
    issuedAmount?: number;
    /** @format date-time */
    issuedDate?: string;
    /** @format decimal */
    paidAmount?: number;
    payee?: string;
    posPayItemId?: string;
    protected?: string;
    reason?: string;
    sourceOfEntry?: string;
    transactionId?: string;
};
export interface ArpDecisionDto {
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
}
export type InternalTransferTransactionDto = BaseEntityDto & {
    /** @format decimal */
    amount: number;
    approvedBy?: TupleOfStringAndDateTimeDto[];
    batchId?: string;
    childInternalTransferTransactions?: InternalTransferTransactionDto[];
    comments?: string;
    company?: CompanyDto;
    /** @format int32 */
    companyId: number;
    /** @format int32 */
    completedApprovals: number;
    confirmationNumber?: string;
    createdBy?: string;
    createdByUser?: UserDto;
    /** @format date-time */
    createdDate: string;
    eligibleApprovers?: string[];
    /** @format date-time */
    endDate?: string;
    errorSummary?: ErrorSummaryDto;
    /** @format int32 */
    firstRepeatOnDay?: number;
    fromAccount?: AccountDto;
    /** @format int32 */
    fromAccountId: number;
    hasNoEndDate?: boolean;
    /** @format int32 */
    id: number;
    internalTransferAudits?: InternalTransferAuditDto[];
    internalTransferStatusType?: InternalTransferStatusTypeDto;
    /** @format int32 */
    internalTransferStatusTypeId: number;
    internalTransferTemplate?: InternalTransferTemplateDto;
    /** @format int32 */
    internalTransferTemplateId?: number;
    isEdited: boolean;
    /** @format date-time */
    lastEditedDate: string;
    memo?: string;
    message?: string;
    messageType?: string;
    /** @format int32 */
    numberOfApprovalNeeded?: number;
    parentInternalTransferTransaction?: InternalTransferTransactionDto;
    /** @format int32 */
    parentInternalTransferTransactionId?: number;
    paymentFrequencyType?: PaymentFrequencyTypeDto;
    /** @format int32 */
    paymentFrequencyTypeId: number;
    paymentOptionType?: PaymentOptionTypeDto;
    /** @format int32 */
    paymentOptionTypeId?: number;
    permissions?: ProductPermissionDto[];
    repeatOnLastBusinessDay?: boolean;
    /** @format int32 */
    seconRepeatOnDay?: number;
    /** @format date-time */
    startDate?: string;
    submittedToScheduler: boolean;
    toAccount?: AccountDto;
    /** @format int32 */
    toAccountId: number;
    transactionId?: string;
    /** @format date-time */
    transferDate?: string;
    updatedBy?: string;
    updatedByUser?: UserDto;
    /** @format date-time */
    updatedDate: string;
};
export interface InternalTransferAuditDto {
    comments?: string;
    /** @format int32 */
    createdBy: number;
    /** @format date-time */
    createdDate: string;
    internalTransferActionType?: InternalTransferActionTypeDto;
    /** @format int32 */
    internalTransferActionTypeId: number;
    /** @format int32 */
    internalTransferAuditId: number;
    internalTransferTransaction?: InternalTransferTransactionDto;
    /** @format int32 */
    internalTransferTransactionId: number;
    user?: UserDto;
}
export interface InternalTransferActionTypeDto {
    description?: string;
    /** @format int32 */
    internalTransferActionTypeId: number;
    internalTransferAudits?: InternalTransferAuditDto[];
    name?: string;
}
export interface InternalTransferStatusTypeDto {
    description?: string;
    /** @format int32 */
    internalTransferStatusTypeId: number;
    internalTransferTransactions?: InternalTransferTransactionDto[];
    name?: string;
}
export type PaymentOptionTypeDto = BaseEntityDto & {
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
};
export interface ProductPermissionDto {
    /** @format int32 */
    id: number;
    permission?: string;
    product?: ProductDto;
    productAccountPermissions?: ProductAccountPermissionDto[];
    /** @format int32 */
    productId: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
}
export interface ProductAccountPermissionDto {
    accountProduct?: AccountProductDto;
    /** @format int32 */
    accountProductId: number;
    entitlements?: EntitlementDto[];
    /** @format int32 */
    id: number;
    productPermission?: ProductPermissionDto;
    /** @format int32 */
    productPermissionId: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
}
export interface EntitlementDto {
    /** @format int32 */
    id: number;
    objectState: ObjectStateDto;
    updatedBy?: string;
    /** @format date-time */
    updatedDate?: string;
    user?: UserDto;
}
export type NonCoreWireCompanyInternationalAccountDto = BaseEntityDto & {
    account?: AccountDto;
    /** @format int32 */
    accountId: number;
    /** @format int32 */
    createdBy: number;
    /** @format date-time */
    createdDate: string;
    /** @format decimal */
    dailyLimit: number;
    /** @format int32 */
    id: number;
    nonCoreWireCompanyInternational?: NonCoreWireCompanyInternationalDto;
    /** @format decimal */
    perTransactionLimit: number;
    rowStatus?: RowStatusDto;
    rowStatusId: number;
    /** @format int32 */
    updatedBy: number;
    /** @format date-time */
    updatedDate: string;
    /** @format int32 */
    wireCompanyId: number;
};
export type NonCoreWireCompanyInternationalDto = BaseEntityDto & {
    /** @format int32 */
    availableBalanceValidationCoreTable?: number;
    company?: CompanyDto;
    /** @format int32 */
    createdBy: number;
    /** @format date-time */
    createdDate: string;
    /** @format decimal */
    dailyLimit: number;
    /** @format int32 */
    id: number;
    nonCoreWireCompanyInternationalAccounts?: NonCoreWireCompanyInternationalAccountDto[];
    /** @format decimal */
    perTransactionLimit: number;
    rowStatus?: RowStatusDto;
    rowStatusId: number;
    /** @format int32 */
    updatedBy: number;
    /** @format date-time */
    updatedDate: string;
    wireInternationalBalanceValidationType?: WireInternationalBalanceValidationTypeDto;
    /** @format int32 */
    wireInternationalBalanceValidationTypeId?: number;
};
export type WireInternationalBalanceValidationTypeDto = BaseEntityDto & {
    /** @format int32 */
    id: number;
    name?: string;
};
export type StopPaymentDto = BaseEntityDto & {
    account?: AccountDto;
    /** @format int32 */
    accountId: number;
    /** @format double */
    amount?: number;
    /** @format int32 */
    approvalCount: number;
    /** @format int32 */
    approvalsCompleted: number;
    approvedBy?: TupleOfStringAndDateTimeDto[];
    /** @format date-time */
    checkDate?: string;
    confirmationNumber?: string;
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    eligibleApprovers?: string[];
    /** @format int64 */
    endCheckNumber?: number;
    enteredBy?: string;
    /** @format date-time */
    expireDate?: string;
    /** @format int32 */
    id: number;
    payee?: string;
    remarks?: string;
    /** @format int64 */
    startCheckNumber: number;
    stopPaymentAudits?: StopPaymentAuditDto[];
    stopPaymentStatusType?: StopPaymentStatusTypeDto;
    /** @format int32 */
    stopPaymentStatusTypeId: number;
    transactionId?: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    userPermissions?: string[];
};
export type StopPaymentAuditDto = BaseEntityDto & {
    createdBy?: string;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    remarks?: string;
    stopPayment?: StopPaymentDto;
    stopPaymentAuditAction?: StopPaymentAuditActionDto;
    /** @format int32 */
    stopPaymentAuditActionId: number;
    /** @format int32 */
    stopPaymentId: number;
};
export type StopPaymentAuditActionDto = BaseEntityDto & {
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
    stopPaymentAudits?: StopPaymentAuditDto[];
};
export interface StopPaymentStatusTypeDto {
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
}
export type CompanyLimitDto = BaseEntityDto & {
    company?: CompanyDto;
    /** @format int32 */
    companyId: number;
    /** @format decimal */
    dailyLimit?: number;
    /** @format int32 */
    id: number;
    /** @format decimal */
    monthlyLimit?: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
};
export type CompanyTypeDto = BaseEntityDto & {
    companies?: CompanyDto[];
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
};
export type FileImportLayoutDto = BaseEntityDto & {
    company?: CompanyDto;
    /** @format int32 */
    companyId: number;
    delimiter?: string;
    fileFormatType?: FileFormatTypeDto;
    /** @format int32 */
    fileFormatTypeId: number;
    fileImportLayoutDetails?: FileImportLayoutDetailDto[];
    /** @format int32 */
    id: number;
    productType?: ProductTypeDto;
    /** @format int32 */
    productTypeId: number;
    templateName?: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
};
export type FileFormatTypeDto = BaseEntityDto & {
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
};
export type FileImportLayoutDetailDto = BaseEntityDto & {
    additionalInformationFour?: string;
    additionalInformationOne?: string;
    additionalInformationThree?: string;
    additionalInformationTwo?: string;
    /** @format int32 */
    beginPosition?: number;
    /** @format int32 */
    endPosition?: number;
    fileImportField?: FileImportFieldDto;
    /** @format int32 */
    fileImportFieldId: number;
    fileImportLayout?: FileImportLayoutDto;
    /** @format int32 */
    fileImportLayoutId: number;
    /** @format int32 */
    id: number;
    /** @format int32 */
    order: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
};
export type FileImportFieldDto = BaseEntityDto & {
    description?: string;
    fieldName?: string;
    fileType?: FileTypeDto;
    /** @format int32 */
    fileTypeId: number;
    /** @format int32 */
    id: number;
};
export type FileTypeDto = BaseEntityDto & {
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
};
export type InternalTransferAddTransactionDetailDto = BaseEntityDto & {
    /** @format decimal */
    amount?: number;
    batchId?: string;
    /** @format date-time */
    endDate?: string;
    errorSummary?: ErrorSummaryDto;
    /** @format int32 */
    firstRepeatOnDay?: number;
    fromAccount?: AccountDto;
    hasNoEndDate?: boolean;
    /** @format int32 */
    id: number;
    /** @format int32 */
    internalTransferStatusTypeId: number;
    /** @format int32 */
    internalTransferTemplateId?: number;
    memo?: string;
    /** @format int32 */
    paymentFrequencyTypeId: number;
    /** @format int32 */
    paymentOptionTypeId?: number;
    repeatOnLastBusinessDay?: boolean;
    /** @format int32 */
    seconRepeatOnDay?: number;
    /** @format date-time */
    startDate?: string;
    toAccount?: AccountDto;
    transactionId?: string;
    /** @format date-time */
    transferDate?: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate?: string;
};
export interface ArpProductDto {
    allowArpExceptionCorrections: boolean;
    fiAllowArpExceptionCorrections: boolean;
    fiAllowSubstituteProduct: boolean;
    fiManageAchExceptions: boolean;
    fiShowAchExceptions: boolean;
    fiWorkAchException: boolean;
    fiWorkCheckException: boolean;
    /** @format int32 */
    id: number;
    manageAchExceptions: boolean;
    product?: ProductDto;
    showAchExceptions: boolean;
    substituteProductClientId?: string;
    substituteProductLandingPageUrl?: string;
    substituteProductName?: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
    useSubstituteProduct: boolean;
    workAchException: boolean;
    workCheckException: boolean;
}
export interface BillPayProductDto {
    account?: AccountDto;
    /** @format int32 */
    accountId?: number;
    billPayServiceName?: string;
    /** @format int32 */
    id: number;
    product?: ProductDto;
    /** @format int32 */
    updatedBy: number;
    /** @format date-time */
    updatedDate: string;
}
export interface ElectronicDocumentsProductDto {
    esiEmail?: string;
    esiId?: string;
    electronicDocumentServiceName?: string;
    /** @format int32 */
    electronicDocumentTypeId?: number;
    /** @format int32 */
    id: number;
    /** @format double */
    monthlyFee: number;
    product?: ProductDto;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
}
export interface RemoteDepositProductDto {
    companySsoLogin?: CompanySsoLoginDto;
    /** @format int32 */
    companySsoLoginId?: number;
    /** @format int32 */
    id: number;
    /** @format decimal */
    monthlyFee?: number;
    remoteDepositProductType?: RemoteDepositProductTypeDto;
    /** @format int32 */
    remoteDepositProductTypeId: number;
    /** @format int32 */
    updatedBy: number;
    /** @format date-time */
    updatedDate: string;
}
export interface CompanySsoLoginDto {
    clientCompanyId?: string;
    company?: CompanyDto;
    /** @format int32 */
    companyId: number;
    /** @format int32 */
    id: number;
    ssoLoginSystem?: SsoLoginSystemDto;
    /** @format int32 */
    ssoLoginSystemId: number;
    /** @format int32 */
    updatedBy: number;
    /** @format date-time */
    updatedDate: string;
}
export interface RemoteDepositProductTypeDto {
    description?: string;
    name?: string;
    /** @format int32 */
    remoteDepositProductTypeId: number;
}
export interface ReportingProductDto {
    /** @format int32 */
    id: number;
    product?: ProductDto;
}
export interface StopPaymentProductDto {
    approvalCount: number;
    /** @format double */
    feePerRequest: number;
    /** @format int32 */
    id: number;
    product?: ProductDto;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
}
export interface ArpReportFileDto {
    account?: AccountDto;
    /** @format int32 */
    accountId: number;
    /** @format date-time */
    createdDate: string;
    fileContent?: string;
    fileName?: string;
    /** @format int32 */
    id: number;
}
export interface CoreAccountDto {
    cifNumber?: string;
    coreStatus?: string;
    number?: string;
    type?: string;
}
export interface CompanyAccountDisplaySettingDto {
    accountMaskType?: AccountMaskTypeDto;
    /** @format int32 */
    accountMaskTypeId: number;
    allowOverrideMasking: boolean;
    company?: CompanyDto;
    /** @format int32 */
    createdBy: number;
    createdByUser?: UserDto;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    showAccountName: boolean;
    /** @format int32 */
    updatedBy: number;
    updatedByUser?: UserDto;
    /** @format date-time */
    updatedDate: string;
}
export interface CompanyUserApprovalSettingDto {
    boUserRequireBoApproval: boolean;
    chUserRequireBoApproval: boolean;
    chUserRequireChApproval: boolean;
    company?: CompanyDto;
    /** @format int32 */
    id: number;
    updatedBy?: string;
    /** @format date-time */
    updatedDate: string;
}
export interface InternalTransferTemplateAuditDto {
    /** @format int32 */
    createdBy: number;
    createdByUser?: UserDto;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    id: number;
    internalTransferTemplate?: InternalTransferTemplateDto;
    internalTransferTemplateActionType?: InternalTransferTemplateActionTypeDto;
    /** @format int32 */
    internalTransferTemplateActionTypeId: number;
    /** @format int32 */
    internalTransferTemplateId: number;
}
export interface InternalTransferTemplateActionTypeDto {
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
}
export interface InternalTransferTemplateDetailDto {
    /** @format decimal */
    amount: number;
    /** @format int32 */
    createdBy: number;
    createdByUser?: UserDto;
    /** @format date-time */
    createdDate: string;
    fromAccount?: AccountDto;
    /** @format int32 */
    fromAccountId: number;
    /** @format int32 */
    id: number;
    internalTransferTemplate?: InternalTransferTemplateDto;
    /** @format int32 */
    internalTransferTemplateId: number;
    memo?: string;
    toAccount?: AccountDto;
    /** @format int32 */
    toAccountId: number;
    /** @format int32 */
    updatedBy: number;
    updatedByUser?: UserDto;
    /** @format date-time */
    updatedDate: string;
}
export interface InternalTransferTemplateStatusTypeDto {
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
}
export interface InternalTransferTypeDto {
    description?: string;
    /** @format int32 */
    id: number;
    name?: string;
}
export type DeleteTransferTemplateRequestDto = BaseRequest2Dto & {
    /** @format int32 */
    transferTemplateId: number;
};
export type EditTransferTemplateRequestDto = BaseRequest2Dto & {
    internalTransferTemplate?: InternalTransferTemplateDto;
};
export type GetTransferRequestDto = BaseRequest2Dto & {
    /** @format int32 */
    internalTransferId: number;
};
export type GetAllTransfersRequestDto = BaseRequest2Dto & {
    internalTransferIds?: number[];
};
export type GetTransfersRequestDto = BaseRequest2Dto & {
    batchId?: string;
};
export type GetTransferTemplateDetailRequestDto = BaseRequest2Dto & {
    editTemplate: boolean;
    /** @format int32 */
    transferTemplateId: number;
};
export type RejectTransferTemplateRequestDto = BaseRequest2Dto & {
    /** @format int32 */
    transferTemplateId: number;
};
export type SearchTransferRequestDto = BaseRequest2Dto & {
    transferFilter?: InternalTransferTransactionFilterDto;
};
export interface InternalTransferTransactionFilterDto {
    accountOperator?: string;
    bothAccounts?: AccountDto[];
    /** @format date-time */
    createdDateFrom?: string;
    /** @format date-time */
    createdDateTo?: string;
    fromAccounts?: AccountDto[];
    /** @format decimal */
    fromAmount?: number;
    /** @format int32 */
    paymentOptionTypeId: number;
    status?: LookupDto[];
    toAccounts?: AccountDto[];
    /** @format decimal */
    toAmount?: number;
    transactionId?: string;
    /** @format date-time */
    transferDateFrom?: string;
    /** @format date-time */
    transferDateTo?: string;
}
export type SearchTransferTemplateRequestDto = BaseRequest2Dto & {
    /** @format decimal */
    amount?: number;
    /** @format decimal */
    amountMax?: number;
    /** @format decimal */
    amountMin?: number;
    amountType?: string;
    fromAccounts?: AccountDto[];
    internalTransferTemplateStatusTypes?: InternalTransferTemplateStatusTypeEnumDto[];
    internalTransferTypes?: InternalTransferTypeEnumDto[];
    templateName?: string;
    toAccounts?: AccountDto[];
};
export enum InternalTransferTemplateStatusTypeEnumDto {
    Undefined = 0,
    PendingApproval = 1,
    Ready = 2,
    Rejected = 3,
    Deleted = 4,
}
export enum InternalTransferTypeEnumDto {
    Undefined = 0,
    OneToOne = 1,
    OneToMany = 2,
    ManyToOne = 3,
}
export type SearchRecurringTransferRequestDto = BaseRequest2Dto & {
    searchFilter?: RecurringInternalTransferFilterDto;
};
export interface RecurringInternalTransferFilterDto {
    accountOperator?: string;
    /** @format decimal */
    amountEnd: number;
    /** @format decimal */
    amountSpecific: number;
    /** @format decimal */
    amountStart: number;
    amountType?: string;
    bothAccounts?: AccountDto[];
    /** @format date-time */
    createdDateEnd?: string;
    /** @format date-time */
    createdDateSpecific?: string;
    /** @format date-time */
    createdDateStart?: string;
    createdDateType?: string;
    frequency?: LookupDto[];
    fromAccounts?: AccountDto[];
    /** @format date-time */
    nextTransferDateEnd?: string;
    /** @format date-time */
    nextTransferDateSpecific?: string;
    /** @format date-time */
    nextTransferDateStart?: string;
    nextTransferDateType?: string;
    status?: LookupDto[];
    toAccounts?: AccountDto[];
    transactionId?: string;
}
export type ExecuteScheduledTransferRequestDto = BaseRequest2Dto & {
    /** @format int32 */
    companyId: number;
    institutionFiId?: string;
    /** @format int32 */
    internalTransferId: number;
    /** @format int64 */
    jobId: number;
    /** @format date-time */
    nextPaymentDate?: string;
    /** @format int32 */
    userId: number;
};
export type GetPendingTransfersRequestDto = BaseRequest2Dto & {
    isTransferLoanPayment: boolean;
};
export type TransferStatusCheckRequestDto = BaseRequest2Dto & object;
export type SearchLoanPaymentRequestDto = BaseRequest2Dto & {
    accountOperator?: string;
    /** @format decimal */
    amountFrom?: number;
    /** @format decimal */
    amountTo?: number;
    bothAccounts?: AccountDto[];
    /** @format date-time */
    createdDate?: string;
    /** @format date-time */
    createdDateFrom?: string;
    /** @format date-time */
    createdDateTo?: string;
    createdDateType?: string;
    fromAccounts?: AccountDto[];
    /** @format date-time */
    paymentDate?: string;
    /** @format date-time */
    paymentDateFrom?: string;
    /** @format date-time */
    paymentDateTo?: string;
    paymentDateType?: string;
    paymentOptionTypes?: LookupDto[];
    statuses?: LookupDto[];
    toAccounts?: AccountDto[];
    transactionId?: string;
};
export type CreateStopPaymentRequestDto = BaseRequest2Dto & {
    stopPayment?: StopPaymentDto;
};
export type GetStopPaymentDetailsRequestDto = BaseRequest2Dto & {
    /** @format int32 */
    stopPaymentId: number;
};
export type CancelStopPaymentRequestDto = BaseRequest2Dto & {
    remarks?: string;
    /** @format int32 */
    stopPaymentId: number;
};
export type SearchStopPaymentsRequestDto = BaseRequest2Dto & {
    outPutType: OutPutTypeDto;
    stopSearchFilter?: StpSearchModelDto;
};
export enum OutPutTypeDto {
    Entity = 0,
    Pdf = 1,
    Csv = 2,
    Tiff = 3,
    Bai = 4,
    Quicken = 5,
    QuickBook = 6,
    Nacha = 7,
}
export interface StpSearchModelDto {
    accounts?: AccountDto[];
    /** @format decimal */
    checkAmount?: number;
    /** @format decimal */
    checkAmountEnd?: number;
    /** @format decimal */
    checkAmountStart?: number;
    checkAmountType?: string;
    /** @format date-time */
    checkDate?: string;
    /** @format date-time */
    checkDateEnd?: string;
    /** @format date-time */
    checkDateStart?: string;
    checkDateType?: string;
    checkNumber?: string;
    checkNumberEnd?: string;
    checkNumberStart?: string;
    checkNumberType?: string;
    /** @format date-time */
    createdDate?: string;
    /** @format date-time */
    createdDateEnd?: string;
    /** @format date-time */
    createdDateStart?: string;
    createdDateType?: string;
    payee?: string;
    statusKeys?: string[];
}
export type RejectStopPaymentRequestDto = BaseRequest2Dto & {
    lookups?: LookupDto[];
};
export type ApproveStopPaymentRequestDto = BaseRequest2Dto & {
    lookups?: LookupDto[];
};
export type StopPaymentRequestExpiredRequestDto = BaseRequest2Dto & {
    accountNumber?: string;
    accountType?: string;
    confirmationNumber?: string;
};
export type StopPaymentUpdateRequestDto = BaseRequest2Dto & {
    accountNumber?: string;
    accountType?: string;
    /** @format float */
    amount?: number;
    /** @format date-time */
    checkDate?: string;
    confirmationNumber?: string;
    /** @format int64 */
    endCheckNumber?: number;
    /** @format date-time */
    expireDate?: string;
    payeeName?: string;
    /** @format int64 */
    startCheckNumber?: number;
};
export type GetEligibleStopPaymentAccountsRequestDto = BaseRequest2Dto & {
    /** @format int32 */
    companyId: number;
};
export type GetCompanyStopPaymentConfigurationRequestDto = BaseRequest2Dto & {
    /** @format int32 */
    companyId: number;
};
export type SaveCompanyStopPaymentRequestDto = BaseRequest2Dto & {
    accountIds?: number[];
    approvalCount: number;
    /** @format int32 */
    companyId: number;
};
export type TreasuryChallengeRequestDto = BaseRequest2Dto & {
    clientStatus?: string;
    oneTimePassword?: string;
};
export type AddTransferRequestDto = TreasuryChallengeRequestDto & {
    internalTransferAddTransaction?: InternalTransferAddTransactionDto;
};
export interface InternalTransferAddTransactionDto {
    batchId?: string;
    errorSummary?: ErrorSummaryDto;
    /** @format int32 */
    id: number;
    transactions?: InternalTransferAddTransactionDetailDto[];
    type?: string;
    updatedBy?: string;
    /** @format date-time */
    updatedDate?: string;
}
export type ApproveTransferRequestDto = TreasuryChallengeRequestDto & {
    comments?: string;
    internalTransferIds?: number[];
};
export type EditTransferRequestDto = TreasuryChallengeRequestDto & {
    internalTransfer?: InternalTransferTransactionDto;
};
export type RejectTransferRequestDto = TreasuryChallengeRequestDto & {
    comments?: string;
    internalTransferIds?: number[];
};
export type CancelTransferRequestDto = TreasuryChallengeRequestDto & {
    comments?: string;
    /** @format int32 */
    internalTransferId: number;
};
export type AddOfxTransferRequestDto = TreasuryChallengeRequestDto & {
    internalTransferAddTransaction?: InternalTransferAddTransactionDto;
};
export type EditOfxTransferRequestDto = TreasuryChallengeRequestDto & {
    internalTransfer?: InternalTransferTransactionDto;
};
export type CancelOfxTransferRequestDto = TreasuryChallengeRequestDto & {
    comments?: string;
    /** @format int32 */
    internalTransferId: number;
};
export type ResponseDetailCollection2Dto = ResponseDetail2Dto[];
export interface ResponseDetail2Dto {
    /** @format int32 */
    responseCode: number;
    responseMessage?: string;
}
export type ApproveTransferTemplateResponseDto = BaseResponse2Dto & {
    internalTransferTemplate?: InternalTransferTemplateDto;
};
export type CreateTransferTemplateResponseDto = BaseResponse2Dto & {
    internalTransferTemplate?: InternalTransferTemplateDto;
    templateNameExist: boolean;
};
export type DeleteTransferTemplateResponseDto = BaseResponse2Dto & object;
export type EditTransferTemplateResponseDto = BaseResponse2Dto & {
    internalTransferTemplate?: InternalTransferTemplateDto;
};
export type GetTransferResponseDto = BaseResponse2Dto & {
    internalTransfer?: InternalTransferTransactionDto;
};
export type GetAllTransfersResponseDto = BaseResponse2Dto & {
    internalTransfers?: InternalTransferTransactionDto[];
    ofxInternalTransfers?: OfxInternalTransferTransactionDto[];
};
export interface OfxInternalTransferTransactionDto {
    /** @format decimal */
    amount: number;
    fromAccountNumber?: string;
    fromAccountType?: string;
    /** @format int32 */
    id: number;
    status?: string;
    toAccountNumber?: string;
    toAccountType?: string;
    /** @format date-time */
    transferDate?: string;
    transferId?: string;
}
export type GetTransfersResponseDto = BaseResponse2Dto & {
    internalTransfers?: InternalTransferTransactionDto[];
};
export type GetTransferTemplateDetailResponseDto = BaseResponse2Dto & {
    internalTransferTemplate?: InternalTransferTemplateDto;
};
export type RejectTransferTemplateResponseDto = BaseResponse2Dto & {
    internalTransferTemplate?: InternalTransferTemplateDto;
};
export type SearchTransferResponseDto = BaseResponse2Dto & {
    internalTransfers?: InternalTransferTransactionDto[];
    ofxInternalTransfers?: OfxInternalTransferTransactionDto[];
};
export type SearchTransferTemplateResponseDto = BaseResponse2Dto & {
    internalTransferTemplates?: InternalTransferTemplateDto[];
};
export type SearchRecurringTransferResponseDto = BaseResponse2Dto & {
    internalTransfers?: InternalTransferTransactionDto[];
};
export type CancelTransferResponseDto = BaseResponse2Dto & {
    internalTransfer?: InternalTransferTransactionDto;
};
export type GetPendingTransfersResponseDto = BaseResponse2Dto & {
    internalTransfers?: InternalTransferTransactionDto[];
};
export type TransferStatusCheckResponseDto = BaseResponse2Dto & object;
export type SearchLoanPaymentResponseDto = BaseResponse2Dto & {
    internalTransfers?: InternalTransferTransactionDto[];
};
export type ExecuteScheduledTransferResponseDto = BaseResponse2Dto & {
    confirmationNumber?: string;
};
export type CancelOfxTransferResponseDto = BaseResponse2Dto & {
    errorSummary?: ErrorSummaryDto;
    transactionId?: string;
};
export type CreateStopPaymentResponseDto = BaseResponse2Dto & {
    stopPayment?: StopPaymentDto;
};
export type GetStopPaymentDetailsResponseDto = BaseResponse2Dto & {
    stopPayment?: StopPaymentDto;
};
export type CancelStopPaymentResponseDto = BaseResponse2Dto & {
    stopPayment?: StopPaymentDto;
};
export type SearchStopPaymentsResponseDto = BaseResponse2Dto & {
    stopPayments?: StopPaymentDto[];
};
export type RejectStopPaymentResponseDto = BaseResponse2Dto & {
    stopPayments?: StopPaymentDto[];
};
export type ApproveStopPaymentResponseDto = BaseResponse2Dto & {
    stopPayments?: StopPaymentDto[];
};
export type StopPaymentRequestExpiredResponseDto = BaseResponse2Dto & object;
export type StopPaymentUpdateResponseDto = BaseResponse2Dto & object;
export type GetEligibleStopPaymentAccountsResponseDto = BaseResponse2Dto & {
    accounts?: EligibleStopPaymentAccountDto[];
};
export interface EligibleStopPaymentAccountDto {
    cifNumber?: string;
    /** @format int32 */
    id: number;
    isStopPayEnabled: boolean;
    name?: string;
    number?: string;
    type?: string;
}
export type GetCompanyStopPaymentConfigurationResponseDto = BaseResponse2Dto & {
    /** @format int32 */
    approvalCount: number;
    /** @format int32 */
    id: number;
};
export type SaveCompanyStopPaymentResponseDto = BaseResponse2Dto & object;
export type TreasuryChallengeResponseDto = BaseResponse2Dto & {
    actionType?: string;
    challengeMethodType?: ChallengeMethodTypeEnumDto;
    errorCode?: string;
    hasAlternate: boolean;
    message?: string;
    methodUsed?: string;
    sendMessage?: string;
    status?: string;
};
export enum ChallengeMethodTypeEnumDto {
    OutOfBand = 1,
    SecureToken = 2,
}
export type AddTransferResponseDto = TreasuryChallengeResponseDto & {
    internalTransferAddTransaction?: InternalTransferAddTransactionDto;
};
export type ApproveTransferResponseDto = TreasuryChallengeResponseDto & {
    internalTransfers?: InternalTransferTransactionDto[];
};
export type EditTransferResponseDto = TreasuryChallengeResponseDto & {
    internalTransfer?: InternalTransferTransactionDto;
};
export type RejectTransferResponseDto = TreasuryChallengeResponseDto & {
    internalTransfers?: InternalTransferTransactionDto[];
};
export type AddOfxTransferResponseDto = TreasuryChallengeResponseDto & {
    errorSummary?: ErrorSummaryDto;
    transactionId?: string;
};
export type EditOfxTransferResponseDto = TreasuryChallengeResponseDto & {
    errorSummary?: ErrorSummaryDto;
    transactionId?: string;
};
export type AchFilterUserModelCollectionDto = AchFilterUserModelDto[];
export interface AchFilterUserModelDto {
    /** @format int32 */
    userId: number;
    name?: string;
    alias?: string;
    /** @format guid */
    userUniqueId: string;
}
export interface ErrorResponseModelDto {
    responseDetails?: ResponseDetailModelCollectionDto;
    time?: string;
}
export type CompanyUserAccountModelCollectionDto = CompanyUserAccountModelDto[];
export interface CompanyUserAccountModelDto {
    /** @format guid */
    accountUniqueId: string;
    accountNumber?: string;
    entitledUserUniqueIds?: string[];
}
export type AchFilterAuditHistoryModelCollectionDto = AchFilterAuditHistoryModelDto[];
export interface AchFilterAuditHistoryModelDto {
    /** @format guid */
    achFilterUniqueId: string;
    auditEvents?: AchFilterAuditEventModelCollectionDto;
}
export type AchFilterAuditEventModelCollectionDto = AchFilterAuditEventModelDto[];
export interface AchFilterAuditEventModelDto {
    /** @format int32 */
    achFilterAuditEventId: number;
    /** @format date-time */
    actionDate?: string;
    action?: string;
    actionUsername?: string;
    editedFields?: string[];
}
export interface AchFilterAuditHistoryRequestModelDto {
    achFilterUniqueIds?: string[];
}
export interface ReportDataModelOfListOfAchFilterReportModelDto {
    reportTitle?: string;
    data?: AchFilterReportModelDto[];
    /** @format int32 */
    totalCount: number;
    isPaged: boolean;
    /** @format int32 */
    maxPageSize: number;
    /** @format int32 */
    recordCountInCurrentPage: number;
}
export interface AchFilterReportModelDto {
    accountNickname?: string;
    accountNumber?: string;
    achCompanyId?: string;
    achCompanyName?: string;
    /** @format guid */
    achFilterUniqueId: string;
    allowType?: string;
    accountAllowsAllCredits: boolean;
    accountAllowsAllDebits: boolean;
    /** @format date-time */
    createdDate?: string;
    /** @format date-time */
    expirationDate?: string;
    filterRuleName?: string;
    /** @format decimal */
    fromAmount?: number;
    remarks?: string;
    routingNumber?: string;
    standardEntryClassCode?: string;
    status?: string;
    /** @format decimal */
    toAmount?: number;
    transactionType?: string;
    companyName?: string;
}
export interface AchFilterReportRequestModelDto {
    accountUniqueIds?: GuidKeyCollectionDto;
    achCompanyId?: string;
    achCompanyName?: string;
    /** @format decimal */
    amountEnd?: number;
    /** @format decimal */
    amountStart?: number;
    /** @format date-time */
    endDate?: string;
    pagingParameters?: PagingParametersDto;
    standardEntryClassCodes?: TextKeyCollectionDto;
    /** @format date-time */
    startDate?: string;
    statuses?: TextKeyCollectionDto;
    transactionTypes?: TextKeyCollectionDto;
    userUniqueIds?: GuidKeyCollectionDto;
    /** @format guid */
    companyGuidUniqueId: string;
    filterRuleName?: string;
}
export type GuidKeyCollectionDto = string[];
export interface AchFilterReportDownloadRequestModelDto {
    accountUniqueIds?: GuidKeyCollectionDto;
    achCompanyId?: string;
    achCompanyName?: string;
    /** @format decimal */
    amountEnd?: number;
    /** @format decimal */
    amountStart?: number;
    /** @format date-time */
    endDate?: string;
    pagingParameters?: PagingParametersDto;
    standardEntryClassCodes?: TextKeyCollectionDto;
    /** @format date-time */
    startDate?: string;
    statuses?: TextKeyCollectionDto;
    transactionTypes?: TextKeyCollectionDto;
    userUniqueIds?: GuidKeyCollectionDto;
    /** @format guid */
    companyGuidUniqueId: string;
    filterRuleName?: string;
}
export interface UserActivityTypeModelDto {
    /** @format int32 */
    id: number;
    name?: string;
}
export type PositivePayCompanyCollectionDto = PositivePayCompanyDto[];
export interface PositivePayCompanyDto {
    /** @format guid */
    companyGuidUniqueId: string;
    name?: string;
}
export interface DateTypeModelDto {
    /** @format int32 */
    id: number;
    /** @format int32 */
    orderId: number;
    name?: string;
    description?: string;
}
export interface GetCompanyListByFiModelDto {
    companies?: FilterLookupModelCollectionDto;
}
export type FilterLookupModelCollectionDto = FilterLookupModelDto[];
export interface GetAchCompanyListByParentCompanyModelDto {
    companies?: FilterLookupModelCollectionDto;
}
export interface GetAchCompanyListByParentCompanyRequestModelDto {
    companyGuidUniqueIds?: GuidKeyCollectionDto;
}
export type ReportUserModelCollectionDto = ReportUserModelDto[];
export interface ReportUserModelDto {
    userId?: string;
    name?: string;
    /** @format guid */
    userUniqueId: string;
}
export interface GetBoReportUserListForCompaniesResponseModelDto {
    users?: FilterLookupModelCollectionDto;
}
export interface GetBoReportUserListForCompaniesRequestModelDto {
    companyGuidUniqueIds?: GuidKeyCollectionDto;
}
export interface GetInternalTransferStatusTypesResponseModelDto {
    internalTransferStatusTypes?: FilterLookupModelCollectionDto;
}
export interface GetAccountsRequestModelDto {
    companyGuidUniqueIds?: GuidKeyCollectionDto;
}
export interface ReportModelBaseDto {
    columnModels?: ReportColumnModelBaseDto[];
    type?: string;
    /** @format int32 */
    id?: number;
    name?: string;
    description?: string;
    isCustomReport: boolean;
    isFavoriteReport: boolean;
    sequences?: ReportSequenceDto[];
}
export interface ReportColumnModelBaseDto {
    columnName?: string;
    displayType?: string;
    displayName?: string;
    /** @format int32 */
    displayOrder: number;
    isSortable: boolean;
    description?: string;
    detailsAccessible: boolean;
    detailColumnConfiguration?: DetailColumnConfigurationModelDto;
}
export interface DetailColumnConfigurationModelDto {
    detailHeader?: string;
    detailActions?: DetailActionModelCollectionDto;
    detailFields?: DetailFieldModelCollectionDto;
}
export type DetailActionModelCollectionDto = DetailActionModelDto[];
export interface DetailActionModelDto {
    actionsUrl?: string;
    icon?: string;
    message?: string;
    optionsUrl?: string;
    action?: ActionMetaDataModelDto;
}
export interface ActionMetaDataModelDto {
    parameters?: string;
    url?: string;
}
export type DetailFieldModelCollectionDto = DetailFieldModelDto[];
export interface DetailFieldModelDto {
    /** @format int32 */
    displayOrder: number;
    displayType?: string;
    fieldName?: string;
    label?: string;
}
export interface ReportSequenceDto {
    /** @format int32 */
    id?: number;
    /** @format int32 */
    reportId: number;
    /** @format int32 */
    order: number;
    selector?: string;
}
export interface GetBoReportFilterModelDto {
    filters?: FilterCollectionDto;
    reportName?: string;
    reportType?: string;
    runDefaultSearch: boolean;
    /** @format int32 */
    reportId: number;
}
export type FilterCollectionDto = FilterDto[];
export interface FilterDto {
    label?: string;
    model?: string;
    /** @format int32 */
    order: number;
    isRequired: boolean;
    cascadeChildren?: any[];
    type?: string;
    isDataBound: boolean;
    endpoint?: string;
    parameters?: ParameterCollectionDto;
    toggleOptions?: ToggleOptionCollectionDto;
    filterOptions?: FilterOptionCollectionDto;
}
export type ParameterCollectionDto = ParameterDto[];
export interface ParameterDto {
    name?: string;
    type?: string;
    localSource?: string;
}
export type ToggleOptionCollectionDto = ToggleOptionDto[];
export interface ToggleOptionDto {
    name?: string;
    /** @format int32 */
    order: number;
}
export type FilterOptionCollectionDto = FilterOptionDto[];
export interface FilterOptionDto {
    id?: string;
    value?: string;
    /** @format int32 */
    order: number;
}
export interface OfxCompanyModelDto {
    errorDescription?: string;
    isOfxEnabled: boolean;
    ofxAuthenticationCode?: string;
    success: boolean;
    userLevelAccessEnabled: boolean;
}
export type SaveReportCustomFilterResponseDto = BaseResponseDto & object;
export type WireHistoryModelDto = ModelBaseDto & {
    wireCompany?: string;
    companyName?: string;
    /** @format date-time */
    valueDate?: string;
    /** @format decimal */
    amount: number;
    status?: string;
    beneficiaryName?: string;
    beneficiaryAccount?: string;
    frequency?: string;
    debitAccount?: string;
    transactionId?: string;
    purpose?: string;
    changedBy?: string;
    /** @format date-time */
    changedDate: string;
};
export interface ReportDataModelOfListOfUserActivityModelDto {
    reportTitle?: string;
    data?: UserActivityModelDto[];
    /** @format int32 */
    totalCount: number;
    isPaged: boolean;
    /** @format int32 */
    maxPageSize: number;
    /** @format int32 */
    recordCountInCurrentPage: number;
}
export interface UserActivityModelDto {
    /** @format int64 */
    userActivityId: number;
    /** @format date-time */
    activityDate: string;
    userName?: string;
    loginId?: string;
    activity?: string;
    sessionId?: string;
    ipAddress?: string;
    device?: string;
    browser?: string;
    companyName?: string;
    userStatus?: string;
    activityType?: string;
    userType?: string;
}
export type ChannelUserActivityReportFilterDto = ReportFilterBaseDto & {
    userIds?: number[];
    dateType?: string;
    /** @format date-time */
    fromDate?: string;
    /** @format date-time */
    toDate?: string;
    /** @format date-time */
    specificDate?: string;
    /** @format date-time */
    fromTime?: string;
    /** @format date-time */
    toTime?: string;
    /** @format int32 */
    userActivityTypeId?: number;
    /** @format int32 */
    companyId: number;
};
export interface FiNumberModelDto {
    errorDescription?: string;
    isOfxEnabled: boolean;
    ofxFiNumber?: string;
    success: boolean;
    userLevelAccessEnabled: boolean;
    ofxReady: boolean;
}
export interface OfxUserAccessModelDto {
    errorDescription?: string;
    isOfxEnabledForUser: boolean;
    ofxFiNumber?: string;
    success: boolean;
}
export interface SaveOfxCompanyRequestDto {
    /** @format int32 */
    companyId: number;
    isOfxEnabled: boolean;
    ofxFiNumber?: string;
    ofxAuthenticationCode?: string;
    userLevelAccessEnabled: boolean;
    modifiedBy?: string;
}
/** Represents an action result that returns an empty NotFound response. */
export interface NotFoundResultDto {
    /** Gets the request message which led to this result. */
    request?: HttpRequestMessageDto;
}
export type CompanyModelCollectionDto = CompanyModel2Dto[];
export interface CompanyModel2Dto {
    cifNumber?: string;
    name?: string;
    /** @format guid */
    uniqueId: string;
    companyUniqueId?: string;
}
export interface CompanySearchModel2Dto {
    /** @format int32 */
    companyTypeId?: number;
    rowStatusIds?: NumberKeyCollectionDto;
}
export type NumberKeyCollectionDto = number[];
export type AccountModelCollectionDto = AccountModel2Dto[];
export interface AccountModel2Dto {
    /** @format int32 */
    accountId: number;
    accountNumber?: string;
    name?: string;
    /** @format guid */
    accountUniqueId: string;
}
export interface PositivePayAccountSearchModelDto {
    companyUniqueIds?: string[];
    hasCheckException: boolean;
    hasAchException: boolean;
    hasAchFilter: boolean;
}
export type CheckExceptionModelDataPageDto = DataPageOfCheckExceptionModelDto & {
    hasEtlCompletedSuccessfully: boolean;
};
export interface DataPageOfCheckExceptionModelDto {
    /** @format int32 */
    pageSize: number;
    /** @format int32 */
    maxPageSize: number;
    /** @format int32 */
    pageNumber: number;
    /** @format int32 */
    totalCount: number;
    /** @format int32 */
    recordCountInCurrentPage: number;
    items?: CheckExceptionModelDto[];
}
export interface CheckExceptionModelDto {
    /** @format guid */
    arpExceptionDetailUniqueId: string;
    /** @format int32 */
    arpExceptionDetailId: number;
    accountNumber?: string;
    checkNumber?: string;
    /** @format decimal */
    paidAmount: number;
    /** @format decimal */
    issuedAmount?: number;
    /** @format date-time */
    postedDate?: string;
    /** @format date-time */
    issuedDate?: string;
    issuedPayee?: string;
    returnReasonDescription?: string;
    /** @format guid */
    attachment?: string;
    decisionStatus?: string;
    comment?: string;
    exceptionReason?: string;
    financialInstitutionId?: string;
    companyName?: string;
    /** @format int32 */
    companyId: number;
    companyUniqueId?: string;
    fromWorkStation?: string;
    attachmentFileName?: string;
    /** @format guid */
    returnReasonUniqueId?: string;
    /** @format int32 */
    returnReasonId?: number;
    /** @format guid */
    lastDecisionByUserUniqueId?: string;
    lastDecisionTakenBy?: string;
    updatedBy?: string;
    sourceOfEntry?: string;
    protected?: string;
    ddaBatchNumber?: string;
    ddaSequenceNumber?: string;
    posPayItemId?: string;
    audits?: AuditModelDto[];
    isCorrectionRequestCreated: boolean;
    checkImageNumber?: string;
}
export interface AuditModelDto {
    username?: string;
    action?: string;
    /** @format date-time */
    timestamp: string;
    comments?: string;
}
export interface SearchCheckExceptionModelDto {
    accountUniqueIds?: string[];
    checkNumber?: string;
    decisionStatus?: string;
    decisionByUserUniqueIds?: string[];
    issuedPayee?: string;
    issuedDate?: DateFilterModelDto;
    issuedAmount?: DecimalFilterModelDto;
    postedDate?: DateFilterModelDto;
    postedAmount?: DecimalFilterModelDto;
    type?: string;
    exceptionReasons?: string[];
    pagingParameters?: PagingParametersDto;
}
export interface DateFilterModelDto {
    /** @format date-time */
    startDate: string;
    /** @format date-time */
    endDate: string;
    searchType?: string;
}
export interface DecimalFilterModelDto {
    /** @format decimal */
    startAmount: number;
    /** @format decimal */
    endAmount: number;
}
/** Represents an action result that returns a specified response message. */
export interface ResponseMessageResultDto {
    /** @format binary */
    response?: File;
}
export type CompanyOptionModelCollectionDto = CompanyOptionModelDto[];
export interface CompanyOptionModelDto {
    name?: string;
    value?: string;
}
export type ProductFeatureAccountModelCollectionDto = ProductFeatureAccountModelDto[];
export interface ProductFeatureAccountModelDto {
    featureParticipations?: ProductAccountFeatureParticipationModelCollectionDto;
    featureName?: string;
}
export type ProductAccountFeatureParticipationModelCollectionDto =
    ProductFeatureAccountParticipationModelDto[];
export interface ProductFeatureAccountParticipationModelDto {
    /** @format int32 */
    accountId: number;
    accountNumber?: string;
    accountType?: string;
    cifNumber?: string;
    isParticipating: boolean;
}
export interface GetProductFeatureAccountsRequestModelDto {
    /** @format guid */
    companyGuidUniqueId?: string;
    accountNumber?: string;
    accountType?: string;
}
export interface SavePositivePayCompanyConfigurationRequestModelDto {
    productFeatureAccounts?: ProductFeatureAccountModelCollectionDto;
    options?: CompanyOptionModelCollectionDto;
}
export type ProductFeatureAccountParticipationsResponseDto = BaseResponseDto & {
    productFeatureAccountParticipations?: ProductAccountFeatureParticipationModelCollectionDto;
};
export type InstitutionOptionModelCollectionDto = InstitutionOptionModelDto[];
export interface InstitutionOptionModelDto {
    name?: string;
    value?: string;
}
export interface GetArpAccountsResponseModelDto {
    checkExceptionAccounts?: ArpCheckExceptionAccountModelDto[];
    achExceptionAccounts?: ArpAchExceptionAccountModelDto[];
}
export type ArpCheckExceptionAccountModelDto = ArpExceptionAccountModelDto & {
    hasCheckException: boolean;
};
export interface ArpExceptionAccountModelDto {
    /** @format int32 */
    accountId: number;
    accountNumber?: string;
    cifNumber?: string;
    accountType?: string;
}
export type ArpAchExceptionAccountModelDto = ArpExceptionAccountModelDto & {
    hasAchException: boolean;
};
export interface UpdateCompanyArpProductModelDto {
    arpProductFeature?: ArpProductFeatureModelDto;
    achExceptionAccounts?: number[];
    checkExceptionAccounts?: number[];
    /** @format int32 */
    companyId: number;
}
export type ArpProductFeatureModelDto = ProductFeatureModelDto & {
    allowArpExceptions: boolean;
    arpAccounts?: AccountModelDto[];
    fiAllowArpExceptions: boolean;
    fiAllowSubstituteProduct: boolean;
    fiManageAchExceptions: boolean;
    fiShowAchExceptions: boolean;
    fiWorkAchException: boolean;
    fiWorkCheckException: boolean;
    manageAchExceptions: boolean;
    showAchExceptions: boolean;
    substituteProductConfig?: ArpSsoSubstituteConfigModelDto;
    useSubstituteProduct: boolean;
    workAchException: boolean;
    workCheckException: boolean;
    allowIssuedItemActivityReview: boolean;
};
export interface ArpSsoSubstituteConfigModelDto {
    ssoProductName?: string;
    ssoClientId?: string;
    ssoLandingPageUrl?: string;
}
export type InstitutionCheckExceptionReturnReasonCollectionDto =
    InstitutionCheckExceptionReturnReasonDto[];
export interface InstitutionCheckExceptionReturnReasonDto {
    /** @format guid */
    institutionCheckExceptionReturnReasonUniqueId?: string;
    description?: string;
    isDeleted: boolean;
    isDefault: boolean;
}
export interface InstitutionProductConfigurationModelDto {
    institutionExternalReportTypes?: InstitutionExternalReportTypesModelDto[];
}
export type InstitutionExternalReportTypesModelDto = GenericEnumModelDto & {
    /** @format guid */
    uniqueId: string;
    /** @format int32 */
    institutionId: number;
    /** @format int32 */
    externalReportTypeId: number;
    isEnabled: boolean;
    /** @format int32 */
    updatedBy: number;
    /** @format date-time */
    updatedDate: string;
};
export interface GenericEnumModelDto {
    /** @format int32 */
    id: number;
    name?: string;
    displayName?: string;
    description?: string;
}
export interface CompanyProductConfigurationModelDto {
    /** @format int32 */
    companyId: number;
    accounts?: AccountProductConfigurationModelDto[];
}
export type AccountProductConfigurationModelDto = AccountModelDto & {
    /** @format int32 */
    accountId: number;
    isEnabled: boolean;
};
export interface GetAchCompanyMatchTypeParametersResponseDto {
    matchTypeParameters?: MatchTypeParameterCollectionDto;
}
export type MatchTypeParameterCollectionDto = MatchTypeParameterDto[];
export interface MatchTypeParameterDto {
    /** @format int32 */
    id: number;
    name?: string;
    includedInMatch: boolean;
    value?: string;
}
export interface GetAchCompanyByMatchTypeParametersResponseDto {
    achCompanies?: CompanyIdParentCompanyNameTupleCollectionDto;
}
export type CompanyIdParentCompanyNameTupleCollectionDto = CompanyIdParentCompanyNameTupleDto[];
export interface CompanyIdParentCompanyNameTupleDto {
    /** @format guid */
    achCompanyId: string;
    parentCompanyName?: string;
}
export interface CopyCoreAchCompanyDataResponseDto {
    /** @format guid */
    achCompanyId: string;
    success: boolean;
    responseDetails?: ResponseDetailModelCollectionDto;
}
export interface CopyCoreAchCompanyDataRequestDto {
    /** @format guid */
    copyAchCompanyId: string;
    achCompanyIds?: GuidKeyCollectionDto;
}
export interface RefreshCoreAchCompanyDataResponseDto {
    /** @format guid */
    achCompanyId: string;
    success: boolean;
    responseDetails?: ResponseDetailModelCollectionDto;
}
export interface RefreshCoreAchCompanyDataRequestDto {
    /** @format guid */
    achCompanyId: string;
    name?: string;
    identifier?: string;
    secCode?: string;
    entryDescription?: string;
    discretionaryData?: string;
}
export type DomesticBankModelCollectionDto = DomesticBankModelDto[];
export interface DomesticBankModelDto {
    bankName?: string;
    /** @format int32 */
    id: number;
    isOnUs: boolean;
    routingNumber?: string;
}
export interface AchNoticeOfChangeFilterModelDto {
    originalEffectiveDate?: DateFilterModelDto;
    companyUniqueIds?: string[];
    achCompanyId?: number[];
    recipientName?: string;
    pagingParameters?: PagingModelDto;
}
export interface GetChildSupportPaymentResponseDto {
    childSupportPayment?: ChildSupportPaymentModelDto;
    errorMessage?: string;
}
export interface ChildSupportPaymentModelDto {
    /** @format int32 */
    id: number;
    /** @format guid */
    achPaymentGuidUniqueId: string;
    frequency?: AchFrequencyModelDto;
    /** @format int32 */
    parentId: number;
    /** @format int32 */
    batchId: number;
    offsetAccount?: LookupModelDto;
    name?: string;
    templateName?: string;
    achCompanyName?: string;
    achCompanyId?: string;
    secCode?: string;
    discretionaryData?: string;
    entryDescription?: string;
    restricted: boolean;
    /** @format decimal */
    debitAmount: number;
    /** @format decimal */
    creditAmount: number;
    transactionId?: string;
    status?: string;
    /** @format int32 */
    numberOfApprovalsNeeded: number;
    audit?: AuditModelDto[];
    /** @format int32 */
    reversalAchPaymentId?: number;
    /** @format int32 */
    achPaymentTypeId: number;
    recipients?: ChildSupportRecipientModelDto[];
}
export interface AchFrequencyModelDto {
    repeatOn?: string;
    /** @format date-time */
    startOn?: string;
    /** @format date-time */
    endOn?: string;
    /** @format date-time */
    effectiveDate?: string;
    /** @format date-time */
    initiatedDate?: string;
    noEndDate: boolean;
    /** @format int32 */
    repeatOnDay1: number;
    /** @format int32 */
    repeatOnDay2: number;
    repeatOnLastBusinessDay?: boolean;
    /** @minLength 1 */
    paymentFrequencyType: string;
    /** @format date-time */
    nextPaymentDate?: string;
    summary?: string;
}
export type ChildSupportRecipientModelDto = AchRecipientModelDto & {
    addendas?: ChildSupportRecipientAddendaModelDto[];
};
export interface ChildSupportRecipientAddendaModelDto {
    caseIdentifier?: string;
    employmentTermination?: boolean;
    fipsCode?: string;
    medicalSupport?: boolean;
    nonCustodialParentFirstName?: string;
    nonCustodialParentLastName?: string;
    nonCustodialParentSsn?: string;
    payDate?: string;
}
export interface AchRecipientModelDto {
    /** @format int32 */
    id: number;
    /** @minLength 1 */
    name: string;
    /** @minLength 1 */
    idNumber: string;
    /** @minLength 1 */
    accountNumber: string;
    /** @minLength 1 */
    accountType: string;
    /** @minLength 1 */
    routingNumber: string;
    /** @minLength 1 */
    transactionType: string;
    /** @format decimal */
    amount: number;
    prenote: boolean;
    hold: boolean;
    /** @format int32 */
    achMasterRecipientId?: number;
    addendas?: LookupModelDto[];
}
export interface GetAchPaymentResponseDto {
    achPayment?: AchPaymentModelDto;
    errorMessage?: string;
}
export interface AchPaymentModelDto {
    /** @format int32 */
    id: number;
    /**
     * @format guid
     * @minLength 1
     */
    achPaymentGuidUniqueId: string;
    recipients: AchRecipientModelDto[];
    frequency: AchFrequencyModelDto;
    /** @format int32 */
    parentId: number;
    /** @format int32 */
    batchId: number;
    offsetAccount: LookupModelDto;
    /** @minLength 1 */
    name: string;
    templateName?: string;
    /** @minLength 1 */
    achCompanyName: string;
    /** @minLength 1 */
    achCompanyId: string;
    /** @minLength 1 */
    secCode: string;
    /** @minLength 1 */
    discretionaryData: string;
    /** @minLength 1 */
    entryDescription: string;
    restricted: boolean;
    /** @format decimal */
    debitAmount: number;
    /** @format decimal */
    creditAmount: number;
    /** @minLength 1 */
    transactionId: string;
    /** @minLength 1 */
    status: string;
    /** @format int32 */
    numberOfApprovalsNeeded: number;
    audit?: AuditModelDto[];
    /** @format int32 */
    reversalAchPaymentId?: number;
    /** @format int32 */
    achPaymentTypeId: number;
}
export interface DownloadPaymentResponseDto {
    /** @format byte */
    content?: string;
    fileName?: string;
    mimeType?: string;
    errorMessage?: string;
}
