angular.module('backOffice').controller('CustomizeArpController', customizeArpController);

customizeArpController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'companiesService',
    'toaster',
    'arpService',
    '$q',
];

function customizeArpController(
    $scope,
    $state,
    $stateParams,
    companiesService,
    toaster,
    arpService,
    $q
) {
    $scope.productType = $stateParams.type;
    $scope.productFeature = {};
    $scope.accounts = {
        check: [],
        ach: [],
    };
    $scope.company = null;
    $scope.goBack = goBack;
    $scope.save = save;
    $scope.isSaving = false;
    $scope.selectedCifNumber = null;
    $scope.showAccounts = showAccounts;
    $scope.exceptionTypes = {
        check: 0,
        ach: 2,
    };
    $scope.exceptionType = $scope.exceptionTypes.check;
    $scope.canShowExceptionType = canShowExceptionType;
    $scope.showExceptionType = showExceptionType;
    $scope.exceptionButtonClasses = exceptionButtonClasses;
    $scope.workAchExceptionDisabled = workAchExceptionDisabled;
    $scope.updateShowAchExceptions = updateShowAchExceptions;
    $scope.requiredEntitlements = requiredEntitlements;
    $scope.allowIssuedItemsActivityInfo =
        'Activation of this configuration will allow users to review the Issued Items Activity listing.';

    const productFeatureId =
        $stateParams.productFeatureId === '' ? 0 : $stateParams.productFeatureId;

    function loadProductFeature() {
        $q.all([
            arpService.getTreasuryAccounts($stateParams.id),
            companiesService.getPositivePayProductFeature($stateParams.id, productFeatureId),
        ]).then(responses => {
            const accountsResponse = responses[0];
            $scope.accounts.check = accountsResponse.checkExceptionAccounts;
            $scope.accounts.ach = accountsResponse.achExceptionAccounts;

            const productFeatureResponse = responses[1];
            $scope.productFeature = productFeatureResponse;

            if (productFeatureId === 0) {
                // adding product
                $scope.productFeature.id = 0;
                $scope.productFeature.type = 'ArpProductFeature';
            }
        });
    }

    function init() {
        companiesService.getCompanyName($stateParams.id).then(response => {
            $scope.company = response;
            loadProductFeature();
        });
    }

    function requiredEntitlements() {
        return productFeatureId === 0 ? 'Add Product Feature' : 'Edit Product Feature';
    }

    init();

    function save() {
        $scope.isSaving = true;
        const saveArpProductModel = {
            arpProductFeature: $scope.productFeature,
            achExceptionAccounts: getAccountsWithAchExceptions(),
            checkExceptionAccounts: getAccountsWithCheckExceptions(),
            companyId: $stateParams.id,
        };
        arpService.saveArpProduct(saveArpProductModel).then(response => {
            $scope.productFeature.id = response.id;
            toaster.save('Positive Pay');
            $scope.isSaving = false;
        });
    }

    function showAccounts() {
        return !$scope.productFeature.useSubstituteProduct;
    }

    function goBack() {
        $state.go('company', { id: $stateParams.id });
    }

    function canShowExceptionType(exceptionType) {
        return $scope.exceptionType === exceptionType;
    }

    function showExceptionType(exceptionType) {
        $scope.exceptionType = exceptionType;
    }

    function exceptionButtonClasses(exceptionType) {
        return $scope.exceptionType === exceptionType ? 'btn-no-style' : 'btn-link';
    }

    function getAccountsWithAchExceptions() {
        const achExceptionAccounts = [];
        $scope.accounts.ach.forEach(account => {
            if (account.hasAchException) {
                achExceptionAccounts.push(account.accountId);
            }
        });

        return achExceptionAccounts;
    }

    function getAccountsWithCheckExceptions() {
        const checkExceptionAccounts = [];
        $scope.accounts.check.forEach(account => {
            if (account.hasCheckException) {
                checkExceptionAccounts.push(account.accountId);
            }
        });

        return checkExceptionAccounts;
    }

    function workAchExceptionDisabled() {
        return (
            !$scope.productFeature.fiWorkAchException || !$scope.productFeature.showAchExceptions
        );
    }

    function updateShowAchExceptions() {
        $scope.productFeature.showAchExceptions = !$scope.productFeature.showAchExceptions;
        $scope.productFeature.workAchException =
            $scope.productFeature.showAchExceptions && $scope.productFeature.workAchException;
    }
}
