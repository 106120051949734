import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('arpService', arpService);

arpService.$inject = ['$resource', '$q'];

function arpService($resource, $q) {
    return {
        getTreasuryAccounts,
        saveArpProduct,
    };

    function getTreasuryAccounts(companyId) {
        return $resource(resourceUrl('getTreasuryaccounts/'), { companyId }).get().$promise;
    }

    function saveArpProduct(saveArpProductModel) {
        return $resource(resourceUrl('SaveCompanyArpProduct')).save(saveArpProductModel).$promise;
    }

    function resourceUrl(path) {
        return `${getBackOfficeAPIPath()}arp/${path}`;
    }
}
