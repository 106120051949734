'using strict';

function countColumnsInTable(table) {
    const cellCount = table.querySelectorAll('tbody > tr:first-child td').length || 0;
    const headerCount = table.querySelectorAll('thead > tr:first-child th').length || 0;
    return Math.max(cellCount, headerCount);
}

angular.module('backOffice').directive('sortableTable', [
    '$timeout',
    '$log',
    'localStorageService',
    '$compile',
    function ($timeout, $log, localStorageService, $compile) {
        function link($scope, $element) {
            if ($scope.enablePagination) {
                $timeout(() => {
                    const externalPagination = $element[0].querySelector('tfoot');
                    if (externalPagination) {
                        $log.warn(
                            'The sortable-table directive now has pagination controls built in. The tfoot should be removed so that built-in pagination can take over.',
                            externalPagination
                        );
                        return;
                    }
                    $scope.columnCount = countColumnsInTable($element[0]);
                    $scope.hasBuiltInPaginationControls = !$scope.detachedPagination;
                });
            }
        }

        return {
            restrict: 'A',
            replace: true,
            transclude: true,
            scope: {
                controller: '=?',
                entityType: '=?',
                enablePagination: '@',
                detachedPagination: '=',
                rowData: '=',
                enableSequence: '@',
                sequenceable: '=?',
                resequenceCallback: '&',
                sortable: '=?',
                id: '@?',
                isStriped: '=?',
                isBordered: '=?',
                tableName: '@?',
                defaultItemsPerPage: '=',
                onlySortAfterChange: '=?',
            },
            template: require('./sortableTableTemplate.html'),
            link,
            controller: sortableTableController,
            localStorage: localStorageService,
        };
    },
]);

sortableTableController.$inject = [
    '$scope',
    '$element',
    '$filter',
    '$transclude',
    '$compile',
    '$timeout',
];

function sortableTableController($scope, $element, $filter, $transclude, $compile, $timeout) {
    $scope.disableSequencing = false;
    $scope.hasSequencePermission = true;
    $scope.mostRecentSortOrder = [];
    $scope.sortByField = null;
    $scope.sortDirection = null;
    $scope.isStriped = $scope.isStriped === undefined ? true : $scope.isStriped;
    $scope.isBordered = $scope.isBordered === undefined ? true : $scope.isBordered;
    $scope.tableName = $scope.tableName || 'table';
    $scope.resequenceCallback =
        $scope.resequenceCallback === undefined ? null : $scope.resequenceCallback;
    $scope.pagination = {
        currentPage: 1,
        itemsPerPage: getItemsPerPage(),
        maxSize: 5,
        itemsPerPageList: [{ name: 10 }, { name: 25 }, { name: 50 }, { name: 100 }],
    };
    $scope.paginate = paginate;
    $scope.showPagination = showPagination;

    $scope.getColumnNameStart = getColumnNameStart;
    $scope.getColumnNameEnd = getColumnNameEnd;
    $scope.sortBy = sortBy;

    $scope.$watchCollection('rowData', onRowDataChange);

    if ($scope.enableSequence) {
        $scope.$watch('hasSequencePermission', onHasSequencePermissionChange);
        $scope.$watch('sequenceable', onSequenceableChange);
    }

    init();

    function showPagination() {
        return (
            $scope.enablePagination &&
            $scope.rowData &&
            $scope.rowData.length < $scope.pagination.itemsPerPage
        );
    }

    function paginate() {
        if ($scope.id) {
            const cache = getItemsPerPageCache();
            cache[$scope.id] = $scope.pagination.itemsPerPage;
            localStorage.setItem('itemsPerPageByTableId', JSON.stringify(cache));
        }
        const startIndex = ($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage;
        const endIndex = startIndex + $scope.pagination.itemsPerPage;

        $scope.controller.paginatedList = $scope.mostRecentSortOrder.slice(startIndex, endIndex);
        $scope.controller.pageStart = $scope.mostRecentSortOrder.length === 0 ? 0 : startIndex + 1;
        $scope.controller.pageEnd = Math.min(endIndex, $scope.mostRecentSortOrder.length);

        $scope.columnCount = countColumnsInTable($element[0]);

        return $scope.controller.paginatedList;
    }

    function getItemsPerPage() {
        const cache = getItemsPerPageCache();
        return cache[$scope.id] || $scope.defaultItemsPerPage || 25;
    }

    function getItemsPerPageCache() {
        return JSON.parse(localStorage.getItem('itemsPerPageByTableId') || '{}');
    }

    function init() {
        initContent();
        $scope.sequenceable = $scope.sequenceable || false;
        $scope.sortable = $scope.sortable || true;
        $scope.controller = $scope.controller || {};
        $scope.onlySortAfterChange = $scope.onlySortAfterChange || false;

        $scope.sortChanged = true;

        $scope.controller.sortableOptions = {
            placeholder: 'sortable-table-placeholder',
            helper: 'original',
            cursor: 'move',
            start: onStartDrag,
            update: onUpdate,
            stop: onStop,
        };

        $scope.controller.getSortedRows = function () {
            if (!$scope.onlySortAfterChange || $scope.sortChanged) {
                $scope.mostRecentSortOrder = getSortedRows();
                $scope.sortChanged = false;
            }

            if ($scope.enablePagination) {
                return $scope.paginate();
            }

            return $scope.mostRecentSortOrder;
        };

        if ($scope.enableSequence) {
            $scope.controller.getNextSequenceNumber = function () {
                return $scope.rowData ? $scope.rowData.length + 1 : 1;
            };
        }

        $scope.controller.isSorting = function (field) {
            if ($scope.sortDirection === null) {
                return false;
            }

            if (field) {
                return field === $scope.sortByField;
            }

            return true;
        };

        if ($scope.enablePagination) {
            $scope.controller.pagination = $scope.pagination;
            $scope.controller.paginate = $scope.paginate;
            $scope.controller.paginatedList = [];
            $scope.controller.showPagination = $scope.showPagination;
        }
    }

    function sortBy(field, type) {
        $scope.sortChanged = true;

        if ($scope.sortByField !== field) {
            $scope.sortByField = field;
            $scope.sortByType = type;
            $scope.sortDirection = 'asc';
            $scope.disableSequencing = true;
            return;
        }

        if ($scope.sortDirection === null) {
            $scope.sortDirection = 'asc';
            $scope.disableSequencing = true;
            return;
        }

        if ($scope.sortDirection === 'asc') {
            $scope.sortDirection = 'desc';
            $scope.disableSequencing = true;
            return;
        }

        $scope.sortDirection = null;
        $scope.sortByField = null;
        $scope.disableSequencing = false;
    }

    function getColumnNameStart(name) {
        return name.split(' ').slice(0, -1).join(' ');
    }

    function getColumnNameEnd(name) {
        return name.split(' ').pop() || '';
    }

    // Events
    function onStartDrag(event, ui) {
        ui.placeholder.css('height', ui.item.css('height'));

        const cells = Array.prototype.slice.call(ui.helper.children());
        cells.forEach((cell, i) => {
            const column = $scope.columns[i];
            if (column && column.width) {
                $(cell).css('width', column.width);
            }
        });

        if ($scope.disableSequencing && $scope.sequenceable) {
            const warningMessage = $('<div />', {
                class: 'warning-message',
                html: `You are currently sorting by "${getColumnNameByField(
                    $scope.sortByField
                )}".<br>You may not re-order rows while sorting.`,
            });
            const warningBackground = $('<div />', {
                class: 'warning-background',
            });
            ui.helper.append(warningBackground);
            ui.helper.append(warningMessage);
        }
    }

    function onUpdate(event, ui) {
        if ($scope.disableSequencing) {
            ui.item.sortable.cancel();
        }
    }

    function onStop(event, ui) {
        $('.sortable-table').find('.warning-background').remove();
        $('.sortable-table').find('.warning-message').remove();

        if ($scope.resequenceCallback) {
            const newIndex = ui.item.sortable.dropindex;
            $scope.resequenceCallback({
                account: $scope.rowData[newIndex],
                newIndex,
            });
        }
    }

    function onRowDataChange(rowData) {
        if (!rowData) {
            return;
        }

        if ($scope.enableSequence) {
            for (let i = 0; i < rowData.length; i++) {
                const item = rowData[i];
                if (!item.sequence) {
                    item.sequence = -1;
                    rowData.splice(i, 1);
                    rowData.push(item);
                    i--;
                }
            }

            rowData.forEach((row, index) => {
                row.sequence = index + 1;
            });
        }

        if ($scope.enablePagination) {
            $scope.pagination.currentPage = 1;
        }

        delete $scope.sortByField;

        $scope.mostRecentSortOrder = getSortedRows(rowData);
    }

    function onSequenceableChange(sequenceable) {
        if (!$scope.controller.sortableOptions) {
            return;
        }

        $scope.controller.sortableOptions.disabled = !sequenceable || !$scope.hasSequencePermission;
    }

    function onHasSequencePermissionChange(hasSequencePermission) {
        if (!$scope.controller.sortableOptions) {
            return;
        }

        $scope.controller.sortableOptions.disabled = !hasSequencePermission || !$scope.sequenceable;
    }

    // Private
    function getColumnNameByField(field) {
        for (let i = 0; i < $scope.columns.length; i++) {
            if ($scope.columns[i].field === field) {
                if ($scope.columns[i].name) {
                    return $scope.columns[i].name;
                }
            }
        }
        return field;
    }

    function getSortedRows(newRowData) {
        if ($scope.sortable) {
            if ($scope.sortByField) {
                const filterType =
                    $scope.sortByType && $scope.sortByType.toLowerCase() === 'number'
                        ? 'orderByNumeric'
                        : 'orderBy';

                return $filter(filterType)(
                    $scope.rowData,
                    $scope.sortByField,
                    $scope.sortDirection === 'desc'
                );
            }
            return $scope.rowData || [];
        }
        if (newRowData) {
            mergeRowChanges($scope.mostRecentSortOrder, $scope.rowData);
        }
        return $scope.mostRecentSortOrder;
    }

    function mergeRowChanges(oldList, newList) {
        Array.prototype.push.apply(
            oldList,
            newList.filter(item => oldList.indexOf(item) === -1)
        );
    }

    // Adds the transcluded content into the element.
    function initContent() {
        $transclude(clone => {
            createColumns(
                $(clone).closest('colgroup').find('col').toArray(),
                $(clone).closest('thead').find('th').toArray()
            );
            $element.append(clone);
        });
    }

    /**
     *
     * @param {HtmlElement} el
     * @param {String} attributeName
     * @returns {String} compiledAttributeName
     *
     * This gets the compiled attribute name so that you can use string interpolation in the template
     */
    function getCompiledAttributeValue(el, attributeName) {
        const attribute = $(el).attr(attributeName);
        return /{{\s*([^}]*)}}/.test(attribute)
            ? $scope.$parent.$parent[attribute?.replace('{{', '').replace('}}', '')]
            : null;
    }

    // Create the column sort controls and store column information. (Remove existing sort controls if they exist.)
    function createColumns(colList, thList) {
        $scope.columns = [];
        thList.forEach((th, i) => {
            const column = {};
            column.name = getCompiledAttributeValue(th, 'name') || $(th).attr('name') || null;
            column.field = $(th).attr('field') || null;
            column.type = $(th).attr('type') || null;
            $scope.columns[i] = column;
        });

        // Insert the sortable column header control template as the first child in each TH.
        thList.forEach((th, i) => {
            $(th).find('.sort-control').remove();
            if ($scope.columns[i].name) {
                const sortControl = $(
                    `<span class="sort-control" data-ng-include="'/app/directives/components/sortableTable/sortableTableSortControl.html'" data-ng-if="columns[${i}]" data-ng-init="column = columns[${i}]"></span>`
                );
                $(th).prepend(sortControl);
                $compile(sortControl[0])($scope);
            }
        });

        colList.forEach((col, i) => {
            const colWidth = $(col).attr('width');
            if (colWidth) {
                $scope.columns[i].width = colWidth;
            }
        });

        $timeout(() => {
            thList.forEach((th, i) => {
                const cssWidth = $(th).css('width');
                if (cssWidth && cssWidth !== '0px') {
                    $scope.columns[i].width = cssWidth;
                }
            });
        });
    }
}
