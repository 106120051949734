import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('rolesService', rolesService);

rolesService.$inject = ['$resource'];

function rolesService($resource) {
    const resourceUrl = `${getBackOfficeAPIPath()}roles/`;
    return {
        getRoles,
        saveRole,
        updateRole,
        deleteRole,
    };

    function deleteRole(roleId, contactId) {
        return $resource(`${resourceUrl}:id`, { id: roleId }).delete().$promise;
    }

    function updateRole(rolePayload) {
        const resource = $resource(
            `${resourceUrl}:id`,
            { id: rolePayload.id },
            { update: { method: 'PUT' } }
        );
        return resource.update(rolePayload).$promise;
    }

    function saveRole(rolePayload) {
        return $resource(resourceUrl).save(rolePayload).$promise;
    }

    function getRoles() {
        return $resource(resourceUrl).query().$promise;
    }
}
