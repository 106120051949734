angular.module('backOffice').constant(
    'loginStatus',
    Object.freeze({
        ALLOW: 'Allow',
        BLOCK: 'Block',
        INVALID: 'Invalid',
        RESET_PASSWORD: 'ResetPassword',
        RESET_EXPIRED_PASSWORD: 'ResetExpiredPassword',
        RSA_ENROLLMENT: 'Enrollment',
        RSA_CHALLENGE: 'Challenge',
        RSA_LOCKED: 'Locked',
        RSA_UNDEFINED: 'Undefined',
        RSA_ENROLLMENT_RESET_PASSWORD: 'ResetPasswordEnrollment',
        RSA_ENROLLMENT_RESET_EXPIRED_PASSWORD: 'ResetExpiredPasswordEnrollment',
        RSA_CHALLENGE_RESET_EXPIRED_PASSWORD: 'ChallengeResetExpiredPassword',
        RSA_CHALLENGE_RESET_PASSWORD: 'ChallengeResetPassword',
        SECURE_TOKEN_REGISTRATION: 'SecureTokenRegistration',
        SECURE_TOKEN_CHALLENGE: 'SecureTokenChallenge',
        SECURE_TOKEN_LOCKED: 'SecureTokenLocked',
        SECURE_TOKEN_DISABLED: 'SecureTokenDisabled',
    })
);
