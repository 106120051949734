angular
    .module('backOffice')
    .controller('EditTransactionCodeController', editTransactionCodeController);

editTransactionCodeController.$inject = [
    '$scope',
    '$modalInstance',
    'transactionCodeGroupsService',
    'transactionCode',
    'toaster',
    'transactionCodesService',
];

function editTransactionCodeController(
    $scope,
    $modalInstance,
    transactionCodeGroupsService,
    transactionCode,
    toaster,
    transactionCodesService
) {
    $scope.groupInEdit = null;
    $scope.transactionCode = transactionCode;

    // this will dismiss the window.
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    // used to set the form instance.
    $scope.setForm = function (form) {
        $scope.form = form;
    };

    init();

    function init() {
        loadTransactionCodeGroups();
        loadOfxValues();
    }

    // we watch for form.$dirty, this is used to alert the end user that they changed something in the form.
    $scope.$watch('form.$dirty', isDirty => {
        $modalInstance.isDirty = isDirty;
    });

    function mergeData() {
        angular.forEach(transactionCode.groups, group => {
            angular.forEach($scope.transactionCodeGroupsList, transactionCode => {
                if (group.name === transactionCode.name) {
                    transactionCode.checked = true;
                }
            });
        });
    }

    function loadTransactionCodeGroups() {
        transactionCodeGroupsService.getTransactionCodeGroups().then(response => {
            $scope.transactionCodeGroupsList = response;
            mergeData();
        });
    }

    function loadOfxValues() {
        transactionCodesService.getOfxTransactionTypes().then(response => {
            $scope.ofxTypeList = response;
            const idx = response.findIndex(
                ofxTranType =>
                    ofxTranType.displayName == $scope.transactionCode.ofxTransactionTypeName
            );
            if (idx > -1) {
                $scope.selectedOfxType = response[idx];
                $scope.ofxTransactionTypeId = $scope.selectedOfxType.ofxTransactionTypeId;
            }
        });
    }

    $scope.save = function () {
        updateGroup();
    };

    function updateGroup() {
        // purge groups
        $scope.transactionCode.groups = [];

        // rebuild the groups list based on changes.
        angular.forEach($scope.transactionCodeGroupsList, group => {
            if (group.checked) {
                $scope.transactionCode.groups.push(group);
            }
        });

        $scope.transactionCode.ofxTransactionTypeId = $scope.selectedOfxType.ofxTransactionTypeId;
        transactionCodesService
            .updateTransactionCode($scope.transactionCode.id, $scope.transactionCode)
            .then(response => {
                $scope.transactionCode.ofxTransactionTypeName = $scope.selectedOfxType.displayName;
                toaster.save('Transaction Code');
                $modalInstance.close($scope.transactionCode);
            });
    }
}
