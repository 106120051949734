export const companyConfigMapping = {
    achFilter: 'PositivePay.ACHFilterManagement.AllowManageAchFilters',
    approvers: 'PositivePay.ACHFilterManagement.ApproversRequired',
    issuedItems: 'PositivePay.AllowIssuedItemActivityReview',
    showAchExceptions: 'PositivePay.ShowAchExceptions',
    substituteProductConfig: 'PositivePay.SubstituteProductConfig.SsoClientId',
    ssoProduct: 'PositivePay.SubstituteProductConfig.SsoProductName',
    ssoProductUrl: 'PositivePay.SubstituteProductConfig.SsoLandingPageUrl',
    useSubstitute: 'PositivePay.SubstituteProductConfig.UseSubstituteProduct',
    workAchExceptions: 'PositivePay.WorkAchExceptions',
    workCheckExceptions: 'PositivePay.WorkCheckExceptions',
    allowReturnReason: 'PositivePay.CheckException.AllowReturnReason',
    allowCorrectionRequest: 'PositivePay.CheckException.AllowCorrectionRequest',
};

export const accountMapping = {
    checkException: 'WorkCheckExceptions',
    achException: 'WorkACHExceptions',
    achFilterManagement: 'ACHFilterManagement',
};
