import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('termsAndConditionsService', termsAndConditionsService);

termsAndConditionsService.$inject = ['$resource', '$http', '$q'];

function termsAndConditionsService($resource, $http, $q) {
    const resourceUrl = `${getBackOfficeAPIPath()}termsandconditions/`;

    return {
        getTermsAndConditions,
        saveTermsAndConditions,
    };

    function getTermsAndConditions() {
        return $resource(resourceUrl, {}, { get: { method: 'GET', isArray: false } }).get()
            .$promise;
    }

    function saveTermsAndConditions(model) {
        return $resource(resourceUrl).save(model).$promise;
    }
}
