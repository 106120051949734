import { boStatesModule } from './app.routes';
import { boDiModule } from './constants';

angular.module('backOffice', [
    'ui.router',
    'ui.bootstrap',
    'ngResource',
    'LocalStorageModule',
    'toaster',
    'ngAnimate',
    'focus-if',
    'kendo.directives',
    'ngFileUpload',
    'ngSanitize',
    'ngMessages',
    'ngCookies',
    'ngFileSaver',
    boDiModule.name,
    boStatesModule.name,
]);
