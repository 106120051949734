/* eslint-disable import/extensions */
import { TmHttpClient } from '@treasury/core/http';
import { AmountRangeFormatters } from '../../../shared/utilities/amount-range-formatters';
import { DateFormatters } from '../../../shared/utilities/date-formatters';
import { PagingFormatters } from '../../../shared/utilities/paging-formatters';

/**
 * Calls associated with reports => 'Wire Upload Report'
 */
export class WireUploadReportServices {
    /**
     *
     * @param {*} sortColumns
     * @param {*} filterTree
     * @param {*} startRowNumber
     * @param {*} rowCount
     * @param {*} params
     */
    // TODO: update for pagination
    static async fetchWireUploads({ parameters, startIndex, pageSize, page, sort }) {
        const body = {
            companyIds: [parameters.companyIds],
            uploadedByIds: parameters.userIds,
            uploadDateType: DateFormatters.getDateType(parameters.uploadDate),
            UploadDate: DateFormatters.parseDate(parameters.uploadDate).value.start
                ? null
                : DateFormatters.parseDate(parameters.uploadDate).value,
            UploadDateStart: DateFormatters.parseDate(parameters.uploadDate).value.start,
            UploadDateEnd: DateFormatters.parseDate(parameters.uploadDate).value.end,
            fileName: parameters.fileName,
            wireAmountMin: AmountRangeFormatters.getRangeStart(parameters.amount),
            wireAmountMax: AmountRangeFormatters.getRangeEnd(parameters.amount),
            wireAmount: AmountRangeFormatters.getSpecificAmount(parameters.amount),
            wireAmountType: AmountRangeFormatters.getRangeType(parameters.amount),
            paging: {
                pageSize,
                pageNumber: page,
                orderBys: PagingFormatters.parseSortColumns(sort).length
                    ? PagingFormatters.parseLatestSortColumn(sort)
                    : [
                          {
                              Name: 'UploadDate',
                              Descending: true,
                          },
                      ],
            },
        };

        const http = await TmHttpClient.getInstance();
        const response = await http.request(`wireFileUploadReport`, {
            method: 'POST',
            body,
        });

        return { data: response.data, totalCount: response.totalCount };
    }

    /**
     *
     * @param {integer} id : reportId for the wireFileFilterModel
     * retrieves a saved wire upload report
     */
    static async getWireUploadsReport(id) {
        return (await TmHttpClient.getInstance()).request(`wireFileUploadReport/${id}`, {
            method: 'GET',
        });
    }

    /**
     *
     * @param {object} params : report data for the new saved report
     * saves a new custom report with the selected filter values
     */
    static async saveWireUploadsReport(params, report) {
        const body = {
            reportId: report.id || 0,
            reportType: 'wireUpload',
            reportName: 'Wire Upload Report',
            reportFilter: {
                companyIds: params.companyIds.pop(),
                uploadedByIds: params.userIds,
                uploadDateType: DateFormatters.getDateType(params.uploadDate),
                uploadDate: DateFormatters.parseDate(params.uploadDate),
                uploadDateStart: DateFormatters.parseDate(params.uploadDate).start,
                uploadDateEnd: DateFormatters.parseDate(params.uploadDate).end,
                fileName: params.fileName,
                wireAmountMin: AmountRangeFormatters.getRangeStart(params.amount),
                wireAmountMax: AmountRangeFormatters.getRangeEnd(params.amount),
                wireAmount: AmountRangeFormatters.getSpecificAmount(params.amount),
                wireAmountType: AmountRangeFormatters.getRangeType(params.amount),
                paging: {
                    pageSize: 25,
                    pageNumber: 1,
                    orderBys: [
                        {
                            name: 'UploadDate',
                            descending: true,
                        },
                    ],
                },
            },
        };

        const http = await TmHttpClient.getInstance();
        return http.request(`wireFileUploadReport`, {
            method: 'PUT',
            body,
        });
    }

    /**
     *
     * @param {object} params : just the filter values from the report
     * downloads the results of the wire file upload report
     */
    static async downloadWireUploadsReport(params) {
        return (await TmHttpClient.getInstance()).request(`wireFileUploadReport/Download`, {
            method: 'POST',
            body: { ...params },
        });
    }
}
