import { LitElement, html, css, nothing } from 'lit';
import '@treasury/omega/components/omega-field';
import camelToTitleCase from '@treasury/domain/utilities/camel-to-title-case';

class ReportSummary extends LitElement {
    static get properties() {
        return {
            summaryRecord: Object,
            summary: Object,
        };
    }

    firstUpdated() {
        delete this.summary.rows;
    }

    removeFieldTypeFromField(field) {
        const fieldAsArray = camelToTitleCase(field).split(' ');
        fieldAsArray.pop();
        return fieldAsArray.join('');
    }

    getGroupTitle(group) {
        return camelToTitleCase(this.removeFieldTypeFromField(group[0]));
    }

    getGroupField(group, key) {
        const field = group.find(f => f.toLowerCase().includes(key));
        return this.summaryRecord.print(field);
    }

    renderColumns() {
        const columnFieldGroups = [];
        const summaryKeys = Object.keys(this.summary);
        summaryKeys.forEach(key => {
            const matchString = this.removeFieldTypeFromField(key);
            const matchedField = summaryKeys.find(k =>
                k.toLowerCase().includes(matchString.toLowerCase())
            );
            if (matchedField !== key) {
                columnFieldGroups.push([key, matchedField]);
            }
        });
        return columnFieldGroups.map(
            group => html`<div class="summary-column">${this.renderFields(group)}</div>`
        );
    }

    renderFields(group) {
        const groupTitle = this.getGroupTitle(group);
        const groupCount = this.getGroupField(group, 'count');
        const groupAmount = this.getGroupField(group, 'total');

        return html`<h4 class="summary-group-title">${groupTitle}</h4>
            <h3 class="summary-group-count">${groupCount}</h3>
            <p class="summary-group-amount">${groupAmount}</p>`;
    }

    render() {
        if (this.summaryRecord) {
            return html`${this.renderColumns()}`;
        }
        return nothing;
    }

    static get styles() {
        return css`
            :host {
                background: var(--omega-white);
                display: flex;
                flex-wrap: wrap;
                padding: 5px 10px;
                justify-content: space-evenly;
            }
            .summary-column {
                display: flex;
                flex-direction: column;
                min-width: 135px;
            }
            .summary-group-title {
                font-size: var(--omega-p, 13px);
                text-transform: uppercase;
                font-weight: var(--omega-light, 100);
                color: var(--omega-secondary-lighten-100, #7c858c);
                margin: 0;
            }
            .summary-group-count {
                margin: 0;
                font-weight: var(--omega-medium);
                border-bottom: 1px solid var(--omega-secondary-lighten-200, #a8adb2);
            }
            .summary-group-amount {
                margin: 0;
            }
            @media print {
                .summary-group-title {
                    color: #000;
                }
                .summary-column {
                    min-width: 75px;
                }
            }
        `;
    }
}

customElements.define('report-summary', ReportSummary);
