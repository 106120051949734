/* eslint-disable @treasury/max-boolean-operators */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import '@treasury/omega/components/omega-time-input.js';

angular.module('backOffice').controller('ManageFiUserController', manageFiUserController);

manageFiUserController.$inject = [
    '$scope',
    'userDetails',
    '$modalInstance',
    'toaster',
    'rolesService',
    'usersService',
    'modalService',
    'permissions',
    'entitlementsService',
    'loginParametersService',
];

function manageFiUserController(
    $scope,
    userDetails,
    $modalInstance,
    toaster,
    rolesService,
    usersService,
    modalService,
    permissions,
    entitlementsService,
    loginParametersService
) {
    $scope.selectedUser = userDetails;
    $scope.deleteUserDisabled = deleteUserDisabled;
    $scope.selectedUser.faxExtension = null;
    $scope.canAddRow = canAddRow;
    $scope.isLastRow = isLastRow;
    $scope.deleteRow = deleteRow;
    $scope.addRow = addRow;
    $scope.validation = {
        octetRegExp: /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])$/,
        optionalOctetRegExp: /^$|^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])$/,
        cidrRegExp: '^$|^([0-9]|[1-2][0-9]|3[0-2])$',
    };
    $scope.timeAccess = [];
    $scope.timeAccessPristine = [];
    $scope.timeRestricted = { value: false };
    $scope.timeRestrictedPristine = {};
    $scope.minuteStep = 15;
    $scope.changeAccessType = changeAccessType;
    $scope.changeBeginTime = changeBeginTime;
    $scope.changeEndTime = changeEndTime;
    $scope.toggleApplyToAllWeekdays = toggleApplyToAllWeekdays;
    $scope.applyToAllWeekdays = { selected: false };
    $scope.getRestrictedLabel = getRestrictedLabel;
    $scope.userTimeAccess = {};
    $scope.isCollapsed = {
        timeAccessPanel: true,
        ipRestrictPanel: true,
    };
    $scope.timeAccessType = [
        { name: 'Allowed All Day', value: 1 },
        { name: 'Restricted All Day', value: 2 },
        { name: 'Allowed Time Range', value: 3 },
    ];
    $scope.timeAccessDays = [
        { name: 'Sunday', value: 0 },
        { name: 'Monday', value: 1 },
        { name: 'Tuesday', value: 2 },
        { name: 'Wednesday', value: 3 },
        { name: 'Thursday', value: 4 },
        { name: 'Friday', value: 5 },
        { name: 'Saturday', value: 6 },
    ];
    $scope.hasInvalidEndTime = hasInvalidEndTime;
    $scope.isInvalidEndTime = isInvalidEndTime;
    $scope.tempNumberHolder = {
        phone1: null,
        phone2: null,
        phone3: null,
        phone1Valid: false,
        phone2Valid: false,
        fax1: null,
        fax1Valid: false,
        fax2: null,
        fax2Valid: false,
        fax3: null,
    };
    $scope.isSaveAndEnrollDisabled = isSaveAndEnrollDisabled;
    $scope.isSaveDisabled = isSaveDisabled;
    $scope.saveButtonClasses = saveButtonClasses;
    $scope.unlockAccountDisabled = unlockAccountDisabled;
    $scope.backOfficeSsoEnabled = permissions.backOfficeSsoEnabled;
    $scope.isExistingUser = isExistingUser;
    $scope.showDeleteUserModal = showDeleteUserModal;
    $scope.requiredEntitlement = requiredEntitlement;
    $scope.getTitle = getTitle;
    $scope.limitCount = limitCount;
    $scope.cancel = cancel;
    $scope.save = save;

    function isPhoneNumberValid() {
        const number = $scope.selectedUser.phoneNumber;

        return number !== undefined && number !== null && number.length === 10;
    }

    function isSaveAndEnrollDisabled() {
        return (
            permissions.canEdit === false ||
            $scope.form.$invalid ||
            isRoleChecked() === false ||
            $scope.hasInvalidEndTime() ||
            isPhoneNumberValid() === false
        );
    }

    function isSaveDisabled() {
        return (
            permissions.canEdit === false ||
            $scope.form.$pristine ||
            $scope.form.$invalid ||
            isRoleChecked() === false ||
            $scope.hasInvalidEndTime() ||
            isPhoneNumberValid() === false
        );
    }

    function isWeekday(dayOfWeek) {
        //Returns true if day of week value represents any of Monday through Friday (1-5), false if weekend day of week (0,6).
        return dayOfWeek % 6 !== 0;
    }

    function isMonday(dayOfWeek) {
        return dayOfWeek === 1;
    }

    function inRangeTuesdayToFriday(dayOfWeek) {
        return isWeekday(dayOfWeek) && !isMonday(dayOfWeek);
    }

    function compareDayOfWeekByMondayThroughSunday(a, b) {
        //Re-index for Monday-Sunday, uses modular math to map the index ring one backward.
        const aMapped = (a.dayOfWeek + 6) % 7;
        const bMapped = (b.dayOfWeek + 6) % 7;
        return aMapped - bMapped;
    }

    function isRoleChecked() {
        const checkedRoles = $scope.rolesList.filter(role => role.isChecked);

        return checkedRoles.length > 0;
    }

    function getTitle() {
        if ($scope.selectedUser.id > 0) {
            if (entitlementsService.hasEntitlement('Edit FI User')) {
                return 'Edit User';
            }
            return 'View User';
        }
        return 'Add User';
    }

    function requiredEntitlement() {
        if ($scope.selectedUser.id > 0) {
            return 'Edit FI User';
        }
        return 'Add FI User';
    }

    $scope.$watch('selectedUser.ipRestriction.restricted', newValue => {
        if (
            !$scope.selectedUser.ipRestriction ||
            !$scope.selectedUser.ipRestriction.ipRestrictions ||
            $scope.selectedUser.ipRestriction.ipRestrictions.length <= 0
        ) {
            $scope.selectedUser.ipRestriction = {
                ipRestrictions: [
                    { octet1: null, octet2: null, octet3: null, octet4: null, name: null },
                ],
            };
        }
    });

    $scope.$watch(
        'tempNumberHolder',
        newValue => {
            if (
                newValue.phone1 !== null &&
                newValue.phone1 !== undefined &&
                newValue.phone1.length === 3 &&
                Number.isNaN(newValue.phone1) === false
            ) {
                $scope.tempNumberHolder.phone1Valid = true;
            } else {
                $scope.tempNumberHolder.phone1Valid = false;
            }
            if (
                newValue.phone2 !== null &&
                newValue.phone2 !== undefined &&
                newValue.phone2.length === 3 &&
                Number.isNaN(newValue.phone2) === false
            ) {
                $scope.tempNumberHolder.phone2Valid = true;
            } else {
                $scope.tempNumberHolder.phone2Valid = false;
            }
            if (
                newValue.fax1 !== null &&
                newValue.fax1 !== undefined &&
                newValue.fax1.length === 3 &&
                Number.isNaN(newValue.fax1) === false
            ) {
                $scope.tempNumberHolder.fax1Valid = true;
            } else {
                $scope.tempNumberHolder.fax1Valid = false;
            }
            if (
                newValue.fax2 !== null &&
                newValue.fax2 !== undefined &&
                newValue.fax2.length === 3 &&
                Number.isNaN(newValue.fax2) === false
            ) {
                $scope.tempNumberHolder.fax2Valid = true;
            } else {
                $scope.tempNumberHolder.fax2Valid = false;
            }
            if (
                newValue.phone1 !== null &&
                newValue.phone1 !== undefined &&
                newValue.phone1.length === 3 &&
                newValue.phone2 !== null &&
                newValue.phone2 !== undefined &&
                newValue.phone2.length === 3 &&
                newValue.phone3 !== null &&
                newValue.phone3 !== undefined &&
                newValue.phone3.length === 4
            ) {
                $scope.selectedUser.phoneNumber =
                    newValue.phone1.toString() +
                    newValue.phone2.toString() +
                    newValue.phone3.toString();
            }
            if (
                newValue.fax1 !== null &&
                newValue.fax1 !== undefined &&
                newValue.fax1.length === 3 &&
                newValue.fax2 !== null &&
                newValue.fax2 !== undefined &&
                newValue.fax2.length === 3 &&
                newValue.fax3 !== null &&
                newValue.fax3 !== undefined &&
                newValue.fax3.length === 4
            ) {
                $scope.selectedUser.fax =
                    newValue.fax1.toString() + newValue.fax2.toString() + newValue.fax3.toString();
            }
        },
        true
    );

    function limitCount(field, count) {
        if ($scope.selectedUser[field].length > count) {
            $scope.selectedUser[field] = $scope.selectedUser[field].substring(0, count);
        }
    }

    function loadRoles() {
        rolesService.getRoles().then(response => {
            $scope.rolesList = response;
            manipulateData();
        });
    }

    function manipulateData() {
        // used to break up phone/fax
        if (
            $scope.selectedUser.phoneNumber !== null &&
            $scope.selectedUser.phoneNumber !== undefined
        ) {
            $scope.tempNumberHolder.phone1 = $scope.selectedUser.phoneNumber.substring(0, 3);
            $scope.tempNumberHolder.phone2 = $scope.selectedUser.phoneNumber.substring(3, 6);
            $scope.tempNumberHolder.phone3 = $scope.selectedUser.phoneNumber.substring(6, 10);
        }
        if ($scope.selectedUser.fax !== null && $scope.selectedUser.fax !== undefined) {
            $scope.tempNumberHolder.fax1 = $scope.selectedUser.fax.substring(0, 3);
            $scope.tempNumberHolder.fax2 = $scope.selectedUser.fax.substring(3, 6);
            $scope.tempNumberHolder.fax3 = $scope.selectedUser.fax.substring(6, 10);
        }

        // used to determine which roles are already checked.
        angular.forEach($scope.selectedUser.roles, role => {
            angular.forEach($scope.rolesList, roleItem => {
                if (roleItem.id === role.id) {
                    roleItem.isChecked = true;
                }
            });
        });
    }

    function init() {
        loadLoginParameters();
        if ($scope.selectedUser.id > 0) {
            loadUserDetails();
        } else {
            loadRoles();
            $scope.selectedUser.ipRestriction = { restricted: true };
            $scope.timeRestricted = { value: true };
            usersService.getNewUserTimeAccess().then(response => {
                loadTimeAccess(response);
            });
        }
    }

    function loadLoginParameters() {
        loginParametersService.getLoginParameters('fi').then(response => {
            $scope.loginParameters = response;
        });
    }

    function loadUserDetails() {
        usersService.getUser($scope.selectedUser.id).then(response => {
            window.angular.copy(response, $scope.selectedUser);
            loadRoles();
            loadTimeAccess($scope.selectedUser.userTimeAccess);
        });
    }

    function cancel() {
        $modalInstance.dismiss();
    }

    function normalizeData() {
        $scope.selectedUser.roles = [];
        angular.forEach($scope.rolesList, roleItem => {
            if (
                roleItem.isChecked !== null &&
                roleItem.isChecked !== undefined &&
                roleItem.isChecked === true
            ) {
                $scope.selectedUser.roles.push(roleItem);
            }
        });
    }

    function formatTime(hoursMinutes) {
        if (hoursMinutes == null) return '00:00:00';
        return `${hoursMinutes}:00`;
    }

    function presentationBeginTimeFromTime(time) {
        return time == null ? `08:00` : time.slice(0, 5);
    }

    function presentationEndTimeFromTime(time) {
        return time == null ? `17:00` : time.slice(0, 5);
    }

    function saveTimeAccess() {
        $scope.userTimeAccess.userTimeAccessDays = $scope.timeAccess;
        $scope.userTimeAccess.timeRestricted = $scope.timeRestricted.value;
        for (let i = 0; i < $scope.userTimeAccess.userTimeAccessDays.length; i++) {
            const item = $scope.userTimeAccess.userTimeAccessDays[i];
            item.timeAccessType = item.selectedAccessType.value;
            item.beginTime = formatTime(item.editBeginTime);
            item.endTime = formatTime(item.editEndTime);
        }
        $scope.selectedUser.userTimeAccess = $scope.userTimeAccess;
    }

    function save(saveType) {
        normalizeData();

        // Discard last row of IP restrictions if it is empty
        const lastRow = $scope.selectedUser.ipRestriction.ipRestrictions.slice(-1)[0];

        if (lastRow?.octet1 == null && lastRow?.octet2 == null) {
            $scope.selectedUser.ipRestriction.ipRestrictions.pop();
        }

        // If saveType is Enroll, set EnrollUser to true
        if (saveType === 'enroll') {
            $scope.selectedUser.enrollUser = true;
        } else {
            $scope.selectedUser.enrollUser = false;
        }

        if ($scope.selectedUser.id > 0) {
            let secondModalInstance;
            const modalOptions = {
                bodyText: 'Are you sure you want to save changes to the User?',
                submit(result) {
                    updateUser();
                    secondModalInstance.close(result);
                },
            };
            secondModalInstance = modalService.showModal({}, modalOptions);
        } else {
            createUser();
        }
    }

    function deleteUser() {
        usersService.deleteUser($scope.selectedUser.id).then(response => {
            toaster.delete('FI User');
            $modalInstance.close(response);
        });
    }

    function showDeleteUserModal() {
        const modalOptions = {
            bodyText: 'Are you sure you want to delete the User?',
            submit(result) {
                deleteUser();
                deleteModalInstance.close(result);
            },
        };
        const deleteModalInstance = modalService.showModal({}, modalOptions);
    }

    function createUser() {
        saveTimeAccess();
        usersService.saveUser($scope.selectedUser).then(response => {
                toaster.save('FI User');
                $modalInstance.close();
        }).catch(e => {
            const modalOptions = {
                isAlert: true,
                headerText: 'Error Creating User',
                alertType: 'Error',
                summaryText: e.message,
                submit(result) {
                    saveModalInstance.close(result);
                },
            };
            const saveModalInstance = modalService.showModal({}, modalOptions);
        });
    }

    function updateUser() {
        saveTimeAccess();

        usersService.updateUser($scope.selectedUser).then(response => {
            toaster.save('FI User');
            $modalInstance.close(response);
        });
    }

    function canAddRow(index) {
        return $scope.form.$valid && isLastRow(index);
    }

    function addRow() {
        $scope.selectedUser.ipRestriction.ipRestrictions.push({
            octet1: null,
            octet2: null,
            octet3: null,
            octet4: null,
            name: null,
        });
    }

    function deleteRow(index) {
        $scope.form.$setDirty(true);
        $scope.selectedUser.ipRestriction.ipRestrictions.splice(index, 1);
        if ($scope.selectedUser.ipRestriction.ipRestrictions.length <= 0) {
            $scope.selectedUser.ipRestriction.ipRestrictions.push({
                octet1: null,
                octet2: null,
                octet3: null,
                octet4: null,
                name: null,
            });
        }
    }

    function isLastRow(index) {
        return $scope.selectedUser.ipRestriction.ipRestrictions.length - 1 === index;
    }

    function changeAccessType(day) {
        if ($scope.applyToAllWeekdays.selected) {
            if (isMonday(day.dayOfWeek)) {
                $scope.timeAccess.forEach(listedDay => {
                    if (inRangeTuesdayToFriday(listedDay.dayOfWeek)) {
                        listedDay.selectedAccessType = day.selectedAccessType;
                    }
                });
            } else if (isWeekday(day.dayOfWeek)) {
                $scope.applyToAllWeekdays.selected = false;
            }
        }
    }

    function changeBeginTime(day, $event) {
        const newBeginTime = $event.detail;
        if (newBeginTime == null) return;
        if (!day?.dayOfWeek) return;

        $scope.timeAccess[day.dayOfWeek - 1].editBeginTime = newBeginTime;
        if ($scope.applyToAllWeekdays.selected) {
            if (isMonday(day.dayOfWeek)) {
                $scope.timeAccess.forEach(listedDay => {
                    if (inRangeTuesdayToFriday(listedDay.dayOfWeek)) {
                        listedDay.editBeginTime = newBeginTime;
                    }
                });
            } else if (isWeekday(day.dayOfWeek)) {
                $scope.applyToAllWeekdays.selected = false;
            }
        }
        $scope.form.$setDirty();
    }

    function changeEndTime(day, $event) {
        const newEndTime = $event.detail;
        if (newEndTime == null) return;
        if (!day?.dayOfWeek) return;

        $scope.timeAccess[day.dayOfWeek - 1].editEndTime = newEndTime;
        if ($scope.applyToAllWeekdays.selected) {
            if (isMonday(day.dayOfWeek)) {
                $scope.timeAccess.forEach(listedDay => {
                    if (inRangeTuesdayToFriday(listedDay.dayOfWeek)) {
                        listedDay.editEndTime = newEndTime;
                    }
                });
            } else if (isWeekday(day.dayOfWeek)) {
                $scope.applyToAllWeekdays.selected = false;
            }
        }
        $scope.form.$setDirty();
    }

    function isInvalidEndTime(day) {
        if (!$scope.timeRestricted.value) return false;
        if (day.selectedAccessType.value !== 3) return false;
        return day.editBeginTime >= day.editEndTime;
    }

    function hasInvalidEndTime() {
        if (!$scope.timeRestricted.value) return false;
        return $scope.timeAccess.some(day => isInvalidEndTime(day));
    }

    function toggleApplyToAllWeekdays() {
        if ($scope.applyToAllWeekdays.selected) {
            const newSelectedAccessType = $scope.timeAccess[0].selectedAccessType;
            const newEditBeginTime = $scope.timeAccess[0].editBeginTime;
            const newEditEndTime = $scope.timeAccess[0].editEndTime;

            $scope.timeAccess.forEach(day => {
                if (inRangeTuesdayToFriday(day.dayOfWeek)) {
                    day.selectedAccessType = newSelectedAccessType;
                    day.editBeginTime = newEditBeginTime;
                    day.editEndTime = newEditEndTime;
                }
            });
        }
    }

    function makeDefaultDay(displayDay) {
        return {
            timeAccessType: 1,
            dayOfWeek: displayDay.value,
            editBeginTime: `08:00`,
            editEndTime: `17:00`,
            label: displayDay.name,
            selectedAccessType: $scope.timeAccessType[0],
        };
    }

    function loadDay(day) {
        day.label = $scope.timeAccessDays.find(val => day.dayOfWeek === val.value)?.name;
        day.selectedAccessType = $scope.timeAccessType.find(
            val => day.timeAccessType === val.value
        );
        day.editBeginTime = presentationBeginTimeFromTime(day.beginTime);
        day.editEndTime = presentationEndTimeFromTime(day.endTime);
    }

    function fillInMissingDays(userTimeAccessDays) {
        $scope.timeAccessDays.forEach(displayDay => {
            const userDayPending = userTimeAccessDays.find(
                day => day.dayOfWeek === displayDay.value
            );
            if (!userDayPending) {
                userTimeAccessDays.push(makeDefaultDay(displayDay));
            }
        });
        userTimeAccessDays.sort(compareDayOfWeekByMondayThroughSunday);
    }

    function loadTimeAccess(userTimeAccess) {
        userTimeAccess.userTimeAccessDays.forEach(day => loadDay(day));
        fillInMissingDays(userTimeAccess.userTimeAccessDays);

        $scope.timeAccess = userTimeAccess.userTimeAccessDays;
        $scope.timeRestricted.value = userTimeAccess.timeRestricted;
        $scope.timeAccessPristine = angular.copy($scope.timeAccess);
        $scope.timeRestrictedPristine = angular.copy($scope.timeRestricted);
        $scope.userTimeAccess = userTimeAccess;
    }

    function getRestrictedLabel() {
        return $scope.timeRestricted.value ? 'Restricted' : 'Unrestricted';
    }

    function deleteUserDisabled() {
        return !permissions.canDelete || !permissions.canEdit;
    }

    function saveButtonClasses() {
        if ($scope.selectedUser.isEnrolled) return 'btn-primary';
        if (!permissions.canEdit) return 'btn-default';
        return '';
    }

    function unlockAccountDisabled() {
        return !permissions.canUnlock || !$scope.selectedUser.isLocked;
    }

    function isExistingUser() {
        return $scope.selectedUser.id > 0;
    }

    init();
}
