import kebabToCamelCase from '../../../utilities/kebab-to-camel-case.js';
import {
    mapReportToCustomReportRequest,
    mapReportToRequest,
    mapResponseToReport,
} from '../../mappings/reports/map-report-to-request.js';
import { ReportRequests } from '../../requests/reports/report-requests.js';

export class ReportsService {
    static async getReports() {
        return ReportRequests.getReports();
    }

    static async getReportColumns(type) {
        const camelCaseType = kebabToCamelCase(type);
        return ReportRequests.getReportColumns(camelCaseType);
    }

    static async getReportFilters(type) {
        const camelCaseType = kebabToCamelCase(type);
        return ReportRequests.getReportFilters(camelCaseType);
    }

    static async getCascadingReportFilters(id) {
        return ReportRequests.getCascadingReportFilters(id);
    }

    static async getCustomReportFilters(id, type) {
        return ReportRequests.fetchCustomReportFilters(id, type);
    }

    static async getDownloadType(type) {
        const camelCaseType = kebabToCamelCase(type);
        return ReportRequests.getDownloadType(camelCaseType);
    }

    static async getDownloadOptions(typeId) {
        return ReportRequests.getDownloadOptions(typeId);
    }

    static async getReportMetaData(id) {
        return ReportRequests.getReportMetaData(id);
    }

    static async runReport(params, metaData, columns, customValues) {
        const requestParameters = mapReportToRequest(params, metaData, columns, customValues);
        const response = await ReportRequests.runReport(requestParameters);
        return mapResponseToReport(response);
    }

    static async deleteReport(id) {
        return ReportRequests.deleteReport(id);
    }

    static async saveReport(params, columns, recordset, metaData) {
        const requestParameters = mapReportToCustomReportRequest(
            params,
            recordset,
            columns,
            metaData
        );
        return ReportRequests.saveReport(requestParameters);
    }

    static async favoriteReport(params) {
        return ReportRequests.favoriteReport(params);
    }

    static async downloadReport(params, downloadType, recordset, metaData, columns) {
        const parameters = { parameters: params.filter };
        const filters = mapReportToRequest(parameters, metaData, columns);
        const requestParameters = {
            filter: {
                reportFilter: {
                    ...filters,
                    reportId: 0,
                    pageNumber: recordset.pageNumber,
                    pageSize: recordset.pageSize,
                },
            },
            downloadType,
        };
        return ReportRequests.downloadReport(requestParameters, params.type);
    }

    static async downloadReportDetail(downloadTypeId, downloadType, recordGuid, sortField) {
        const requestParameters = {
            downloadType: downloadTypeId,
            filter: {
                downloadType,
                Guid: recordGuid,
            },
            primaryField: sortField,
        };
        return ReportRequests.downloadReportDetails(requestParameters, downloadType);
    }

    static async printReportDetail(recordGuid) {
        // TODO: don't know anything about the service request shape yet
        return ReportRequests.printReportDetail(recordGuid);
    }

    static async downloadAttachment(id) {
        return ReportRequests.downloadAttachment(id);
    }

    static async getWireHistory(id) {
        return ReportRequests.fetchWireHistory(id);
    }

    static async getWireDetails(wireId) {
        return ReportRequests.fetchWireDetails(wireId);
    }

    static async saveWireDetails(wire) {
        return ReportRequests.saveWireDetails(wire);
    }

    static async downloadWireDetails(wireId, downloadType) {
        return ReportRequests.downloadWireDetails(wireId, downloadType);
    }
}
