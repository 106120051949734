// cspell:ignore ratesheet

angular
    .module('backOffice')
    .controller('ConfigureWireTransferDliController', configureWireTransferDliController);

configureWireTransferDliController.$inject = [
    '$scope',
    'productFeatureId',
    '$modalInstance',
    'configurationService',
    'toaster',
    'canEdit',
    'wireService',
    '$filter',
    'regexConstants',
    '$modal',
    'entitlementsService',
];

function configureWireTransferDliController(
    $scope,
    productFeatureId,
    $modalInstance,
    configurationService,
    toaster,
    canEdit,
    wireService,
    $filter,
    regexConstants,
    $modal,
    entitlementsService
) {
    $scope.canEdit = canEdit;
    $scope.productFeatureId = productFeatureId;
    $scope.useCore = true;

    $scope.disableInternational = disableInternational;
    $scope.cancel = cancel;
    $scope.save = save;
    $scope.togglePurpose = togglePurpose;
    $scope.toggleInternational = toggleInternational;

    $scope.approvalOptions = [
        { label: 'None', id: 0 },
        { label: '1', id: 1 },
        { label: '2', id: 2 },
        { label: '3', id: 3 },
    ];
    $scope.haveWireLimitThresholds = haveWireLimitThresholds;
    $scope.canAddAnotherThreshold = canAddAnotherThreshold;
    $scope.deleteThreshold = deleteThreshold;
    $scope.isOnlyOneRow = isOnlyOneRow;
    $scope.isLastRow = isLastRow;
    $scope.checkWireLimitThresholds = checkWireLimitThresholds;
    $scope.getAvailableApprovalCounts = getAvailableApprovalCounts;
    $scope.addThreshold = addThreshold;
    $scope.getPreviousEndAmount = getPreviousEndAmount;
    $scope.getMaximum = getMaximum;
    $scope.thresholdOptions = {
        useWireLimitThresholds: false,
    };
    $scope.endAmountIncrement = 10000;
    $scope.validateExchangeAmounts = validateExchangeAmounts;
    $scope.formatExchangePercent = formatExchangePercent;
    $scope.outgoingSettlementType = {
        isAch: true,
    };
    $scope.exchangeFeeRegex = regexConstants.ExchangeFeeRegex;
    $scope.emailListRegex = regexConstants.EmailListRegex;
    $scope.ratesheet = { error: false };

    $scope.canUpload = canUpload;
    $scope.onSelectFile = onSelectFile;
    $scope.onRemoveFile = onRemoveFile;
    $scope.upload = upload;
    $scope.toggleAccountBalanceValidation = toggleAccountBalanceValidation;
    $scope.viewExchangeRates = viewExchangeRates;
    $scope.setForm = setForm;
    $scope.displayNotificationInbox = entitlementsService.hasAnyEntitlement(
        'Feature.FXWire.PaymentNotificationInbox'
    );
    $scope.fxPaymentNotificationInboxString = '';

    function setForm(form) {
        $scope.form = form;
    }

    function toggleAccountBalanceValidation() {
        $scope.productFeature.validateAccountBalance =
            !$scope.productFeature.validateAccountBalance;
        $scope.form.$setDirty();
    }

    function canUpload() {
        return $scope.ratesheet.file;
    }

    function onSelectFile(e) {
        if (e.files) {
            $scope.ratesheet.error = false;
            $scope.ratesheet.file = e.files[0].rawFile;
            $scope.ratesheet.fid = e.files[0].uid;
            $scope.ratesheet.effectiveDate =
                $scope.exchangeRateMetadata[$scope.ratesheet.index].effectiveDate;
            $scope.$apply();
        }
    }

    function onRemoveFile(e) {
        if ($scope.ratesheet.fid === e.files[0].uid) {
            resetRatesheet();
            $scope.$apply();
        }
    }

    function upload() {
        wireService.uploadRatesheet($scope.ratesheet).then(
            response => {
                $scope.ratesheet.error = !response.id;
                if (!$scope.ratesheet.error) {
                    resetRatesheet();

                    // Open to ideas about how else one can do this on Kendo UI 2015.x
                    // Cite: https://www.telerik.com/forums/remove-file-from-the-upload-list-after-its-deleted
                    // For later versions of Kendo UI, there are methods available (ex. removeAllFiles).
                    angular.element('.k-upload-files').remove();

                    toaster.save('Exchange Rates');
                    getExchangeRateMetadata();
                }
            },
            response => {
                $scope.ratesheet.error = true;
            }
        );
    }

    function getExchangeRateMetadata() {
        wireService.getExchangeRatesMetadata().then(result => {
            $scope.exchangeRateMetadata = result;
        });
    }

    function viewExchangeRates(effectiveDate) {
        wireService.getExchangeRates(effectiveDate).then(result => {
            $modal.open({
                template: require('../views/viewExchangeRates.html'),
                size: 'md',
                controller: 'ViewExchangeRatesController',
                backdrop: 'static',
                resolve: {
                    rates() {
                        return result;
                    },
                    effectiveDate() {
                        return effectiveDate;
                    },
                },
            });
        });
    }

    function resetRatesheet() {
        $scope.ratesheet.file = null;
        $scope.ratesheet.fid = null;
        $scope.ratesheet.effectiveDate = null;
        $scope.ratesheet.error = false;
    }

    function save() {
        $scope.productFeature.paymentApprovalType = !$scope.thresholdOptions.useWireLimitThresholds
            ? 'Overall'
            : 'Threshold';
        $scope.productFeature.outgoingSettlementTypeId = $scope.outgoingSettlementType.isAch
            ? 1
            : 2;
        let invoked = 0;

        if ($scope.productFeature.fxPaymentNotificationInboxString) {
            $scope.productFeature.fxPaymentNotificationEmailAddresses =
                $scope.productFeature.fxPaymentNotificationInboxString
                    .split(/[;\s]+/)
                    .filter(address => address !== '');
        }

        configurationService
            .saveProductFeature($scope.productFeatureId, $scope.productFeature)
            .then(() => {
                if (++invoked === 2) {
                    toaster.save('Wire Transfer - Foreign Currency Wire Configuration');
                    $modalInstance.close();
                }
            });

        //eslint-disable-next-line sonarjs/no-identical-functions
        wireService.saveExchangeFees($scope.exchangeFees).then(() => {
            if (++invoked === 2) {
                toaster.save('Wire Transfer - Foreign Currency Wire Configuration');
                $modalInstance.close();
            }
        });
    }

    function cancel() {
        $modalInstance.dismiss();
    }

    function togglePurpose() {
        $scope.productFeature.requirePurpose = !$scope.productFeature.requirePurpose;
        $scope.form.$setDirty();
    }

    function toggleInternational(isFx) {
        if (isFx) {
            $scope.productFeature.foreignCurrency = !$scope.productFeature.foreignCurrency;
        } else if (!$scope.productFeature.internationalUsd) {
            $scope.productFeature.internationalUsd = true;
        } else {
            $scope.productFeature.internationalUsd = false;
        }
        $scope.form.$setDirty();
    }

    function disableInternational() {
        return $scope.productFeature.wireTraditionalInternationalEnabled;
    }

    //
    // Private

    function loadProductFeature() {
        configurationService.getProductFeatureDetails($scope.productFeatureId).then(response => {
            $scope.productFeature = response || {};
            if ($scope.productFeature.fxPaymentNotificationEmailAddresses) {
                $scope.productFeature.fxPaymentNotificationInboxString =
                    $scope.productFeature.fxPaymentNotificationEmailAddresses.join(';');
            }
            $scope.thresholdOptions.useWireLimitThresholds = !!(
                !!$scope.productFeature.paymentApprovalType &&
                $scope.productFeature.paymentApprovalType === 'Threshold'
            );
            $scope.outgoingSettlementType.isAch =
                $scope.productFeature.outgoingSettlementTypeId === 1;
            wireService.getExchangeFees().then(response => {
                $scope.exchangeFees = response;
                for (let i = response.length; i < 6; i++) {
                    $scope.exchangeFees.push({
                        amount: null,
                        percent: null,
                    });
                }
                validateExchangeAmounts();
            });
        });
    }

    function getPreviousEndAmount(index) {
        if (index !== 0) {
            $scope.productFeature.paymentThresholdApprovals[index].startAmount =
                $scope.productFeature.paymentThresholdApprovals[index - 1].endAmount;
            return $scope.productFeature.paymentThresholdApprovals[index - 1].endAmount;
        }
        $scope.productFeature.paymentThresholdApprovals[index].startAmount = 0.0;
        return 0.0;
    }

    function getAvailableApprovalCounts(index, threshold) {
        const nextLowestApprovalCount =
            index === 0
                ? threshold.approvalCount
                : $scope.productFeature.paymentThresholdApprovals[index - 1].approvalCount;
        const nextHighestApprovalCount =
            index === $scope.productFeature.paymentThresholdApprovals.length - 1
                ? threshold.approvalCount
                : $scope.productFeature.paymentThresholdApprovals[index + 1].approvalCount;
        return $scope.approvalOptions.filter(approvalOption => {
            if (approvalOption.id === threshold.approvalCount) {
                return true;
            }
            if (
                approvalOption.id < nextLowestApprovalCount &&
                threshold.approvalCount <= nextLowestApprovalCount
            ) {
                return true;
            }
            if (
                approvalOption.id > nextLowestApprovalCount &&
                approvalOption.id < nextHighestApprovalCount
            ) {
                return true;
            }
            if (
                approvalOption.id > nextHighestApprovalCount &&
                threshold.approvalCount >= nextHighestApprovalCount
            ) {
                return true;
            }
            return false;
        });
    }

    function checkWireLimitThresholds() {
        if (
            !$scope.productFeature.paymentThresholdApprovals ||
            $scope.productFeature.paymentThresholdApprovals.length <= 0
        ) {
            $scope.productFeature.paymentThresholdApprovals = [
                {
                    startAmount: 0.0,
                    endAmount: 10000,
                    approvalCount: 0,
                },
                {
                    startAmount: 10000,
                    endAmount: null,
                    approvalCount: 1,
                },
            ];
        }
    }

    function haveWireLimitThresholds() {
        if ($scope.productFeature) {
            if (
                !!$scope.productFeature.paymentThresholdApprovals &&
                $scope.productFeature.paymentThresholdApprovals.length > 0
            ) {
                return true;
            }
        }
        return false;
    }

    function canAddAnotherThreshold(index, item) {
        if (isLastRow(index)) {
            if (item.approvalCount !== 3) {
                return true;
            }
        }
        return false;
    }

    function isLastRow(index) {
        return $scope.productFeature.paymentThresholdApprovals.length - 1 === index;
    }

    function addThreshold() {
        const previousEndAmount =
            $scope.productFeature.paymentThresholdApprovals[
                $scope.productFeature.paymentThresholdApprovals.length - 1
            ].endAmount;
        const previousStartAmount =
            $scope.productFeature.paymentThresholdApprovals[
                $scope.productFeature.paymentThresholdApprovals.length - 1
            ].startAmount;
        if (previousEndAmount) {
            $scope.productFeature.paymentThresholdApprovals[
                $scope.productFeature.paymentThresholdApprovals.length - 1
            ].endAmount += $scope.endAmountIncrement;
        } else {
            $scope.productFeature.paymentThresholdApprovals[
                $scope.productFeature.paymentThresholdApprovals.length - 1
            ].endAmount = previousStartAmount + $scope.endAmountIncrement;
        }
        $scope.productFeature.paymentThresholdApprovals.push({
            approvalCount:
                $scope.productFeature.paymentThresholdApprovals[
                    $scope.productFeature.paymentThresholdApprovals.length - 1
                ].approvalCount + 1,
            startAmount:
                $scope.productFeature.paymentThresholdApprovals[
                    $scope.productFeature.paymentThresholdApprovals.length - 1
                ].endAmount,
            endAmount: null,
        });
    }

    function getMaximum(index) {
        if (index === 0) {
            return 0;
        }
        return $scope.productFeature.paymentThresholdApprovals[index - 1].endAmount;
    }

    function deleteThreshold(index) {
        $scope.productFeature.paymentThresholdApprovals.splice(index, 1);
        $scope.productFeature.paymentThresholdApprovals[
            $scope.productFeature.paymentThresholdApprovals.length - 1
        ].endAmount = null;
    }

    function isOnlyOneRow() {
        return $scope.productFeature.paymentThresholdApprovals.length === 1;
    }

    function validateExchangeAmounts() {
        const max = 99999999999;
        let last = 0;
        let maxSet = false;
        for (let i = 0; i < $scope.exchangeFees.length; i++) {
            if (maxSet) {
                $scope.exchangeFees[i].disabled = true;
                $scope.exchangeFees[i].amount = null;
                $scope.exchangeFees[i].percent = null;
                $scope.form[`exchangeFeeAmount${i + 1}`].$setValidity('invalidAmount', true);
            } else {
                $scope.exchangeFees[i].disabled = false;
                if (!$scope.exchangeFees[i].amount && !isNaN($scope.exchangeFees[i].amount)) {
                    $scope.exchangeFees[i].amount = max;
                    maxSet = true;
                    $scope.form[`exchangeFeeAmount${i + 1}`].$setValidity('invalidAmount', true);
                } else if ($scope.exchangeFees[i].amount > last) {
                    last = $scope.exchangeFees[i].amount;
                    if ($scope.exchangeFees[i].amount === max) {
                        maxSet = true;
                    }
                    $scope.form[`exchangeFeeAmount${i + 1}`].$setValidity('invalidAmount', true);
                } else {
                    $scope.form[`exchangeFeeAmount${i + 1}`].$setValidity('invalidAmount', false);
                }
            }
        }
        formatExchangePercent();
    }

    function formatExchangePercent() {
        for (let i = 0; i < $scope.exchangeFees.length; i++) {
            if (!$scope.exchangeFees[i].disabled) {
                if ($scope.exchangeFees[i].percent === null) {
                    $scope.exchangeFees[i].percent = 0;
                }
                if (!isNaN($scope.exchangeFees[i].percent)) {
                    $scope.exchangeFees[i].percent = $filter('number')(
                        $scope.exchangeFees[i].percent,
                        4
                    );
                }
            }
        }
    }

    (function () {
        // init

        loadProductFeature();
        getExchangeRateMetadata();
    })();
}