import { DiContainer } from '@jack-henry/frontend-utils/di';
import { RealtimeService } from '@treasury/core/real-time';
import { XperienceService } from '@treasury/core/xperience';
import { LogoutManager } from '@treasury/domain/services/logout';

angular.module('backOffice').controller('HeaderController', HeaderController);

HeaderController.$inject = [
    '$scope',
    '$timeout',
    '$rootScope',
    '$state',
    'navigationService',
    'accountService',
    'modalService',
    'entitlementsService',
    'jhaSupportService',
];

function HeaderController(
    $scope,
    $timeout,
    $rootScope,
    $state,
    navigationService,
    accountService,
    modalService,
    entitlementsService,
    jhaSupportService
) {
    $scope.authentication = $rootScope.authentication;
    $scope.gotoMessageCenter = gotoMessageCenter;
    $scope.hasBackOfficeSso = hasBackOfficeSso;
    $scope.isJhaSupportUser = isJhaSupportUser;
    $scope.logOff = logOff;
    $scope.navigateToView = navigateToView;
    $scope.showLastSuccessfulLogin = showLastSuccessfulLogin;
    $scope.showLoginFailures = showLoginFailures;
    $scope.showStandardLoginFailure = showStandardLoginFailure;
    $scope.showTokenLoginFailure = showTokenLoginFailure;
    $scope.updateInstitution = updateInstitution;

    $scope.isLoginPage = false;
    $scope.navigationList = [];
    $scope.userName = '';
    $scope.lastSuccessfulLogin = '';
    $scope.lastFailedLogin = '';
    $scope.lastFailedLoginIPAddress = '';
    $scope.unreadMessageCount = 0;
    $scope.tokenLoginInfo = {};
    $scope.backOfficeSso = {
        institutions: [],
        currentFiId: null,
    };
    $scope.selectedInstitution = '';
    $scope.hasSelectedInstitution = false;
    $scope.$watch(() => $state.current.name, getSettings);
    $scope.xperienceHosted = false;

    $scope.navigateToCompanySearch = function () {
        $state.go('company-search');
    };

    $scope.navigateToInstitutionSearch = function () {
        $state.go('institution-search');
    };

    async function init() {
        loadNavigationList();
        listenForNotifications();
        await checkXperienceHosted();
    }

    async function checkXperienceHosted() {
        const TmDi = await DiContainer.getInstance();
        const xperienceService = TmDi.get(XperienceService);
        if(xperienceService.isXperienceFrameworkHosted === undefined) {
            await xperienceService.connectToMessageBus();
        }
        $scope.xperienceHosted = xperienceService.isXperienceFrameworkHosted;
        $scope.$apply();
    }

    async function listenForNotifications() {
        const di = await DiContainer.getInstance();
        const realTimeService = await di.getAsync(RealtimeService);

        realTimeService.subscribe('updateNotificationList', notificationResponse => {
            $timeout(() => {
                const originalMessageCount = notificationResponse.TotalUnreadCount;

                if (originalMessageCount !== notificationResponse.TotalUnreadCount) {
                    $rootScope.$broadcast('messageCountUpdated');
                }

                $scope.unreadMessageCount = notificationResponse.TotalUnreadCount;
            });
        });
    }

    async function logOff() {
        const logoutManager = (await DiContainer.getInstance()).get(LogoutManager);
        const modalOptions = {
            closeButtonText: 'Cancel',
            actionButtonText: 'OK',
            headerText: 'Sign Out',
            bodyText: 'Are you sure you want to logout from the application?',
            submit() {
                logoutManager.logOut('UserInitiated');
                $state.go('login');
                $modalInstance.close();
            },
        };

        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    function navigateToView(location) {
        $state.go(location.toLowerCase());
    }

    function loadNavigationList() {
        navigationService.getNavigation().then(response => {
            $scope.navigationList = navigationService.mapApplicationPaths(response.navigationMenus);
            $scope.backOfficeSso.institutions = response.ssoInstitutions;
            $scope.backOfficeSso.currentFiId = response.currentFiId;
        });
    }

    function getSelectedInstitution() {
        const userFiId = jhaSupportService.getUserFiId();
        $scope.selectedInstitution = userFiId;
        $scope.hasSelectedInstitution = userFiId && userFiId.toLowerCase() !== 'jha';
    }

    function getSettings() {
        accountService.getSettings().then(settings => {
            $scope.unreadMessageCount = settings.unreadMessageCount;
        });
        loadRecentLogins();
        getSelectedInstitution();
        entitlementsService.getEntitlements();
    }

    function hasBackOfficeSso() {
        const ssoInstitutions = $scope.backOfficeSso.institutions;
        return ssoInstitutions != null && ssoInstitutions.length > 0;
    }

    function isJhaSupportUser() {
        return !$scope.isLoginPage && entitlementsService.hasEntitlement('Switch FI');
    }

    function gotoMessageCenter() {
        $state.go('message-center');
    }

    function loadRecentLogins() {
        accountService.recentLogins().then(response => {
            $scope.userName = response.userName;
            const { standardLoginInfo } = response;
            if (standardLoginInfo != null) {
                $scope.lastSuccessfulLogin = standardLoginInfo.lastSuccessfulLogin;
                if (standardLoginInfo.lastFailedLogin !== null) {
                    $scope.lastFailedLogin = standardLoginInfo.lastFailedLogin;
                    $scope.lastFailedLoginIPAddress = standardLoginInfo.lastFailedLoginIPAddress;
                } else {
                    $scope.lastFailedLogin = '';
                }
            }

            $scope.tokenLoginInfo = response.tokenMfaLoginInfo || {};
        });
    }

    function showLastSuccessfulLogin() {
        return $scope.lastSuccessfulLogin != null && $scope.lastSuccessfulLogin.length;
    }

    function showLoginFailures() {
        return showStandardLoginFailure() || showTokenLoginFailure();
    }

    function showStandardLoginFailure() {
        return $scope.lastFailedLogin != null && $scope.lastFailedLogin.length;
    }

    function showTokenLoginFailure() {
        return (
            $scope.tokenLoginInfo.lastFailedLogin != null &&
            $scope.tokenLoginInfo.lastFailedLogin.length
        );
    }

    function updateInstitution(institution) {
        if (institution.fiId === $scope.backOfficeSso.currentFiId) return;

        navigationService.updateInstitution(institution.institutionId).then(response => {
            entitlementsService.updateEntitlements().then(() => {
                $scope.backOfficeSso.currentFiId = response.fiId;
                const companySearchRouteName = 'company-search';
                if ($state.$current.name === companySearchRouteName) {
                    $state.reload();
                } else {
                    $state.go(companySearchRouteName);
                }
            });
        });
    }

    init();
}
