const environment = window.location.host.split('.')[0];
const isLocal = /\.local$/.test(window.location.host);
const environmentDecorators = {
    mock: '👟',
    dev: '🛠',
    qa: '🧪',
    lt: '💪',
};

const decorator = (isLocal ? '💻 ' : '') + (environmentDecorators[environment] ?? '');

if (decorator) {
    document.title = `${decorator} 🏦  - Treasury Management Back Office`;
}
