import { CompanyModelDto } from '@treasury/api/bo';
import { html, nothing } from 'lit';
import { DomainEntity } from '../../../../shared';
import { ChallengeGroup } from './challenge-group.entity';

const defaultCompany: CompanyModelDto = {
    id: 0,
    cifNumber: '',
    name: '',
    status: '',
    taxNumber: '',
    customerSince: '',
    addedOn: '',
};

export class Company extends DomainEntity<CompanyModelDto> {
    constructor(dto: CompanyModelDto, challengeGroups: ChallengeGroup[] = []) {
        super(dto);
        this.cifNumber = dto.cifNumber;
        this.companyId = dto.companyId;
        this.name = dto.name;
        this.taxNumber = dto.taxNumber;
        this.addressLine1 = dto.address?.line1 ?? '';
        this.addressLine2 = dto.address?.line2 ?? '';
        this.addressLine3 = dto.address?.line3 ?? '';
        this.zipCode = dto.address?.zipCode ?? '';
        this.city = dto.address?.city ?? '';
        this.state = dto.address?.state ?? '';
        this.challengeGroups = challengeGroups;
        this.status = dto.status;
    }

    public challengeGroups: ChallengeGroup[] = [];

    public cifNumber?: string;

    public companyId?: string;

    public name?: string;

    public taxNumber?: string;

    public addressLine1: string;

    public addressLine2: string;

    public addressLine3: string;

    public city: string;

    public state: string;

    public zipCode: string;

    public status: string;

    public get customerSince() {
        return new Date(this.dto.customerSince);
    }

    public get treasuryCustomerSince() {
        return new Date(this.dto.addedOn);
    }

    public createDefault(): this {
        return new Company(defaultCompany) as this;
    }

    public get authenticationEnabled() {
        return Boolean(this.dto.companyAuthenticationSettings?.isActive);
    }

    public set authenticationEnabled(value: boolean) {
        this.dto.companyAuthenticationSettings!.isActive = value;
    }

    public get authenticationMethod() {
        return this.dto.companyAuthenticationSettings?.challengeMethodType!;
    }

    public get authenticationProfileName() {
        return this.dto.companyAuthenticationSettings?.challengeGroup?.name;
    }
}
