angular.module('backOffice').controller('WireDetailsController', WireDetailsController);

WireDetailsController.$inject = [
    'wireService',
    '$stateParams',
    '$state',
    'downloadPage',
    'reportsService',
    'entitlementsService',
    'wireStatusTypes',
];

function WireDetailsController(
    wireService,
    $stateParams,
    $state,
    downloadPage,
    reportsService,
    entitlementsService,
    wireStatusTypes
) {
    const vm = this;

    const userCanEditWires = entitlementsService.hasEntitlement('Edit FX Wire Payment');
    let state = 'readonly';

    vm.id = $stateParams.id;
    vm.returnReportId = $stateParams.reportId;
    vm.downloadFilter = {
        id: vm.id,
    };
    vm.statusList = wireStatusTypes.map(type => {
        if (type.id === 4 || type.id === 6) {
            type.canSelect = true;
        }
        return type;
    });

    vm.close = close;
    vm.getLastAddressLine = getLastAddressLine;
    vm.getBankCountry = getBankCountry;
    vm.showIntermediaryBanks = showIntermediaryBanks;
    vm.getReturnReportName = getReturnReportName;
    vm.isEditable = isEditable;
    vm.edit = edit;
    vm.review = review;
    vm.confirm = confirm;
    vm.hasEditButton = hasEditButton;
    vm.hasReviewButton = hasReviewButton;
    vm.hasConfirmButton = hasConfirmButton;
    vm.isReviewButtonDisabled = isReviewButtonDisabled;
    vm.changeStatus = changeStatus;
    vm.showIntermediaryAddress = showIntermediaryAddress;

    init();

    function init() {
        if (vm.returnReportId) {
            reportsService.getReport(vm.returnReportId).then(report => {
                vm.returnReport = report;

                if (vm.returnReport.type === 'wireTransferDliPayment') {
                    vm.downloadPage = downloadPage.FxWireTransferPaymentDetails;
                } else {
                    vm.downloadPage = downloadPage.WireTransferPaymentDetails;
                }
            });
        }

        wireService.getWire(vm.id).then(wire => {
            vm.wire = wire;

            vm.wire.frequency.type = wireService.frequencyTypeToFriendly(vm.wire.frequency.type);
            vm.wire.frequency.summary = getFrequencySummary(vm.wire);
            vm.wireStatusWhenItWasLoaded = wire.status;
        });
    }

    function showIntermediaryAddress(bank) {
        return vm.wire.isInternational && !bank.isInternational;
    }

    function showIntermediaryBanks() {
        return (
            vm.wire != null &&
            vm.wire.beneficiary != null &&
            vm.wire.beneficiary.intermediaryBanks != null &&
            vm.wire.beneficiary.intermediaryBanks.length > 0
        );
    }

    function getBankCountry(bank) {
        return bank.country != null ? bank.country : 'United States';
    }

    function getFrequencySummary(wire) {
        if (wire != null) {
            return wireService.summarizeFrequency(wire.frequency);
        }

        return null;
    }

    function getLastAddressLine(address) {
        let text = '';

        if (!address) return '';

        if (address.addressLine3) {
            return address.addressLine3;
        }

        if (address.city) {
            text += address.city;

            if (address.state || address.postalCode || address.country) {
                text += ', ';
            }
        }

        if (address.state) {
            text += address.state;

            if (address.postalCode) {
                text += ' ';
            } else if (address.country) {
                text += ', ';
            }
        }

        if (address.postalCode) {
            text += address.postalCode;

            if (address.country) {
                text += ', ';
            }
        }

        if (address.country) {
            text += address.country;
        }

        return text;
    }

    function close() {
        if (vm.returnReport) {
            $state.go('reports.view', { type: vm.returnReport.type, id: vm.returnReport.id });
        } else {
            $state.go('reports.dashboard');
        }
    }

    function getReturnReportName() {
        if (!vm.returnReportId || !vm.returnReport || !vm.returnReport.name) {
            return 'Report Dashboard';
        }

        return vm.returnReport.name;
    }

    function isEditable() {
        return state === 'edit';
    }

    function edit() {
        state = 'edit';
    }

    function review() {
        state = 'review';
    }

    function confirm() {
        wireService.updateExternalNonCoreFxWire(vm.wire).then(response => {
            state = 'confirm';
        });
    }

    function hasEditButton() {
        const wireCanBeEdited =
            vm.wireStatusWhenItWasLoaded === 'Transmitted' && vm.wire.externallyProcessed;
        const isAppropriateState = state === 'readonly' || state === 'review';
        return wireCanBeEdited && userCanEditWires && isAppropriateState;
    }

    function hasReviewButton() {
        return state === 'edit';
    }

    function hasConfirmButton() {
        return state === 'review';
    }

    function isReviewButtonEnabled() {
        return vm.wire.statusId === 6 && vm.wire.confirmationNumber;
    }

    function isReviewButtonDisabled() {
        return !isReviewButtonEnabled();
    }

    function changeStatus() {
        vm.wire.status = vm.statusList[vm.wire.statusId].name;
    }
}
