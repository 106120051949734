import { Injectable, InjectProperty } from '@jack-henry/frontend-utils/di';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';

@Injectable()
export class WireIsoService {
    @InjectProperty({ async: true, token: FeatureFlagService })
    private declare featureFlagService: Promise<FeatureFlagService>;

    private async getEntitlementWireISO20022() {
        return (await this.featureFlagService).isEnabled(Feature.WireIsoFeatureFlag);
    }

    private async getEntitlementWireBeneficiaryIso20022() {
        return (await this.featureFlagService).isEnabled(Feature.WireIsoBeneficiaryFeatureFlag);
    }

    /**
     * For angular code, call this in the controller. Returns the correct set of labels based on feature flag settings
     *
     * @returns {Promise<{[key: string]: string}>}
     */
    public async getCorrectItemLabels() {
        return (await this.getEntitlementWireISO20022())
            ? this.iso20022WireLabels
            : this.legacyWireLabels;
    }

    public iso20022WireLabels = {
        creditor: 'Creditor',
        creditorLowercase: 'creditor',
        agent: 'Agent',
        debtor: 'Debtor',
        instructingAgent: 'Instructing Agent',
        creditorAgent: 'Creditor Agent',
        intermediaryAgent: 'Intermediary Agent',
        internationalIntermediaryAgent: 'Intermediary Agent',
        agentId: 'Agent ID',
        endToEndId: 'End to End ID',
        endToEndIdLowercase: 'end to end ID',
        address1: 'Building Name and Street Name',
        address2and3:
            'Department, Sub Department, Building Name,Floor, Room, Town Location Name, District Name, Post Box',
        townName: 'Town Name',
        countrySubDivision: 'Country Sub Division',
        postCode: 'Post Code',
        country: 'Country',
        accountNumber: 'Account Number',
        amount: 'Amount',
        remittanceInformation: 'Remittance Information',
        purpose: 'Purpose',
    };

    public legacyWireLabels = {
        creditor: 'Beneficiary',
        creditorLowercase: 'beneficiary',
        agent: 'Bank',
        debtor: 'Originator',
        instructingAgent: 'Sending Bank',
        creditorAgent: 'Beneficiary Bank',
        intermediaryAgent: 'Receiving Bank',
        internationalIntermediaryAgent: 'International Intermediary Bank',
        agentId: 'Routing Number',
        endToEndId: 'Reference Beneficiary',
        endToEndIdLowercase: 'reference beneficiary',
        address1: 'Address 1',
        address2and3: 'Address 2/3',
        townName: 'City',
        countrySubDivision: 'State',
        postCode: 'Zip Code',
        country: 'Country',
        accountNumber: 'Account Number',
        amount: 'Amount',
        remittanceInformation: 'Additional Information',
        purpose: 'Purpose',
    };
}
