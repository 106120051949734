import { AchTaxPayment } from '@treasury/domain/backoffice/types/ach';
import { Record as FdlRecord } from '@treasury/FDL';
import '@treasury/omega/components/omega-field';
import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('ach-tax-payment-detail')
export class AchTaxPaymentDetail extends LitElement {
   
@property()
private paymentRecord!: FdlRecord<AchTaxPayment>;


render() {
    if(!this.paymentRecord) return nothing;
    return html` <h3 class="payment-name">
            ${this.paymentRecord.values.name}
        </h3>
        <div class="details-body omega-flex-form">
            <div class="form-column col-1">
                <omega-field field="status" .record=${this.paymentRecord}></omega-field>
                <omega-field
                    field="taxPaymentType"
                    .record=${this.paymentRecord}
                ></omega-field>
                <omega-field
                    field="state"
                    .record=${this.paymentRecord}
                ></omega-field>
                <omega-field
                    field="taxCode"
                    .record=${this.paymentRecord}
                ></omega-field>
                <omega-field
                    field="taxPayerId"
                    .record=${this.paymentRecord}
                ></omega-field>
            </div>

            <div class="form-column col-2">
                <omega-field field="companyName" .record=${this.paymentRecord}></omega-field>
                <omega-field field="amounts" .record=${this.paymentRecord}></omega-field>
            </div>
            <div class="form-column col-3">
            <omega-field
                    field="recipientName"
                    .record=${this.paymentRecord}
                ></omega-field>
                <omega-field
                    field="recipientId"
                    .record=${this.paymentRecord}
                ></omega-field>
                <omega-field
                    field="recipientRoutingNumber"
                    .record=${this.paymentRecord}
                ></omega-field>
                <omega-field
                    field="recipientAccountNumber"
                    .record=${this.paymentRecord}
                ></omega-field>
                <omega-field
                    field="recipientAccountType"
                    .record=${this.paymentRecord}
                ></omega-field>
                <omega-field
                    no-align
                    field="audit"
                    .record=${this.paymentRecord}
                ></omega-field>
            </div>
        </div>`;
}

static get styles() {
    return [
        css`
            .payment-name {
                margin: 10px 0 0 20px;
                color: #505050;
            }
            .details-body {
                --omega-label-font-weight: 700;
                margin-left: 14px;
            }
            .omega-flex-form {
                display: flex;
            }
            .form-column {
                flex: 1 1 0;
                margin: 10px;
            }
            .col-1 {
                --omega-field-label-max-width: 170px;
            }
            .col-2 {
                --omega-field-label-max-width: 100px;
            }
            .col-3 {
                --omega-field-label-max-width: 60px;
            }
            .form-column:not(:last-of-type) {
                padding-right: 10px;
                border-right: 1px solid #d3d6d9;
            }
            .omega-flex-form omega-field {
                --omega-field-control-width: 120px;
                margin: 5px 10px;
            }
        `,
    ];
}

}