import { Record, Recordset, boolean, string } from '@treasury/FDL';
import { TmApiError } from '@treasury/domain/shared';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-button.js';
import '@treasury/omega/components/omega-dialog';
import '@treasury/omega/components/omega-field.js';
import '@treasury/omega/components/omega-filter-bar.js';
import '@treasury/omega/components/omega-table.js';
import '@treasury/omega/components/progress/omega-progress.js';
import { LitElement, css, html, nothing } from 'lit';
import { mix } from 'mixwith';
import '../../components/blocking-loader.js';
import BackOfficeAlertMixin from '../../mix-ins/back-office-alert-mixin.js';
import FeatureFlagClient from '../clients/feature-flag-client.js';
import { columns, fields } from '../data/feature-flag-fields.js';

class FeatureFlags extends mix(LitElement).with(ListeningElementMixin, BackOfficeAlertMixin) {
    static get properties() {
        return {
            alert: Object,
            columns: Array,
            fields: Object,
            institution: String,
            recordset: Object,
            client: Object,
            loading: Boolean,
            saving: Boolean,
            isCreateFlagDialogOpen: Boolean,
            institutions: Array,
            editingFlag: Boolean,
            featureFlagRecord: Object,
            validFlag: Boolean,
        };
    }

    constructor() {
        super();
        this.fields = fields;
        this.columns = columns;
        this.pageTitle = 'Feature Flags';
        this.rowsPerPage = 50;
        this.actions = {
            delete: record => this.promptForDeletion(record),
            edit: record => this.promptForEdit(record),
        };
        this.isCreateFlagDialogOpen = false;
        this.editingFlag = false;
        this.institutions = [];
        this.validFlag = false;
    }

    async firstUpdated() {
        if (!this.client) this.client = new FeatureFlagClient();
        try {
            this.loading = true;
            this.recordset = new Recordset(this.fields, this.client.fetchFeatureFlags);
            this.recordset.setInitialPageSize(this.rowsPerPage);
            await this.recordset.requestUpdate();
            this.listenTo(this.recordset, 'updated', ({ detail }) =>
                this.updateFlag(detail.record)
            );
            this.listenTo(this.recordset, 'loading', ({ detail }) => {
                this.loading = detail.loading;
            });
            this.featureFlagRecord = new Record(
                {
                    name: string.with
                        .label('Name')
                        .thatIs.required()
                        .thatHas.placeholder('FF Name...'),
                    description: string.with
                        .label('Description')
                        .thatIs.required()
                        .thatHas.placeholder('FF Description...'),
                    enabled: boolean.with.label('Enabled'),
                    applyToAllInstitutions: boolean.with
                        .label('Apply to all institutions')
                        .thatIs.visibleWhen(() => false),
                    institutionIds: string.with
                        .label('Institutions')
                        .with.options({
                            fetch: this.client.getInstitutions,
                            text: 'name',
                            value: 'uniqueId',
                            hideSelectAll: true,
                        })
                        .thatHas.multipleValues()
                        .with.filtering()
                        .thatIs.required(),
                },
                {
                    name: '',
                    description: '',
                    enabled: false,
                    applyToAllInstitutions: false,
                    institutionIds: [],
                }
            );
            this.listenTo(this.featureFlagRecord, 'change', () => {
                this.validFlag = !this.featureFlagRecord.hasErrors();
            });
        } catch (e) {
            const message = e instanceof Error ? e.message : 'An unknown error occurred.';
            const code = e instanceof TmApiError ? e.errorCode : undefined;
            this.alert = {
                ...this.alert,
                visible: true,
                type: 'error',
                message,
                code,
            };
        } finally {
            this.loading = false;
        }
    }

    async deleteFlag(record) {
        try {
            this.saving = true;
            const id = record.getField('featureFlagKeyUniqueId');
            await this.client.deleteFeatureFlag(id);
            this.alert = {
                ...this.alert,
                visible: true,
                type: 'success',
                message: 'Feature flag deleted successfully',
            };
            this.recordset.requestHardUpdate();
        } catch (e) {
            const message = e instanceof Error ? e.message : 'An unknown error occurred.';
            const code = e instanceof TmApiError ? e.errorCode : undefined;

            this.alert = {
                ...this.alert,
                visible: true,
                type: 'error',
                message,
                code,
            };
        } finally {
            this.saving = false;
        }
    }

    promptForDeletion(record) {
        this.alert = {
            ...this.alert,
            posture: 'assertive',
            type: 'warning',
            title: 'Are you sure?',
            code: '',
            message: 'Are you sure you want to delete this feature flag?',
            visible: true,
            actions: html`<omega-button
                    type="reject"
                    @click=${() => {
                        this.alert.visible = false;
                        this.alert.actions = '';
                        this.alert.posture = 'polite';
                        this.alert.title = '';

                        this.deleteFlag(record);
                    }}
                    >Delete</omega-button
                ><omega-button
                    @click=${() => {
                        this.alert.visible = false;
                        this.alert.actions = '';
                        this.alert.posture = 'polite';
                        this.alert.title = '';
                    }}
                    >Cancel</omega-button
                >`,
        };
    }

    promptForEdit(record) {
        this.featureFlagRecord.setFields(record.values);
        this.editingFlag = true;
        this.isCreateFlagDialogOpen = true;
    }

    async saveFlag() {
        this.saving = true;
        this.isCreateFlagDialogOpen = false;
        try {
            const value = this.featureFlagRecord.getField('enabled') ? '1' : '0';
            await this.client.addFeatureFlag({
                institutionIdType: 2,
                institutionIds: this.featureFlagRecord.getField('institutionIds'),
                disableCache: true,
                applyToAllInstitutions: this.featureFlagRecord.getField('applyToAllInstitutions'),
                FeatureFlags: [{ ...this.featureFlagRecord.values, value }],
            });
            this.recordset.requestHardUpdate();
            this.alert = {
                ...this.alert,
                visible: true,
                time: '',
                code: '',
                type: 'success',
                message: 'Feature Flag created successfully',
            };
        } catch (e) {
            const message = e instanceof Error ? e.message : 'An unknown error occurred.';
            const code = e instanceof TmApiError ? e.errorCode : undefined;
            const time = e instanceof TmApiError ? e.timestamp : undefined;

            this.alert = {
                ...this.alert,
                visible: true,
                type: 'error',
                message,
                code,
                time,
            };
        } finally {
            this.saving = false;
            this.featureFlagRecord.reset();
        }
    }

    handleAction({ action, record, rowIndex }) {
        const actionFunction = this.actions[action];
        if (actionFunction) actionFunction(record, rowIndex);
    }

    renderLoader() {
        if (this.saving) return html`<omega-progress card></omega-progress>`;
        return nothing;
    }

    renderFlagActionDialog() {
        if (!this.isCreateFlagDialogOpen) return nothing;
        const title = this.editingFlag ? 'Edit Feature Flag' : 'Create Feature Flag';
        const action = this.saveFlag;
        return html`<omega-dialog
            open
            .dialogTitle=${title}
            @close=${() => {
                this.isCreateFlagDialogOpen = false;
                this.featureFlagRecord.reset();
            }}
        >
            <div class="dialog-body" slot="content">
                <omega-field .record=${this.featureFlagRecord} field="name"></omega-field>
                <omega-field .record=${this.featureFlagRecord} field="description"></omega-field>
                <omega-field .record=${this.featureFlagRecord} field="enabled"></omega-field>
                <omega-field
                    .record=${this.featureFlagRecord}
                    field="applyToAllInstitutions"
                ></omega-field>
                <omega-field field="institutionIds" .record=${this.featureFlagRecord}></omega-field>
            </div>
            <omega-button
                slot="actions"
                @click=${() => {
                    this.isCreateFlagDialogOpen = false;
                    this.featureFlagRecord.reset();
                }}
                >Cancel</omega-button
            >
            <omega-button
                slot="actions"
                type="primary"
                @click=${action}
                .disabled=${!this.validFlag}
                >Save</omega-button
            >
        </omega-dialog>`;
    }

    renderBlockingLoader() {
        if (!this.saving) return nothing;
        return html`<blocking-loader></blocking-loader>`;
    }

    render() {
        if (!this.recordset) return nothing;
        return html`
            ${this.renderAlert()} ${this.renderFlagActionDialog()}${this.renderBlockingLoader()}
            <div class="header-bar">
                <h2>Feature Flags</h2>
                <omega-button
                    @click=${() => {
                        this.isCreateFlagDialogOpen = true;
                    }}
                    >Create New Flag</omega-button
                >
            </div>
            <omega-filter-bar
                .filters=${[]}
                .recordset=${this.recordset}
                @change=${({ detail }) => {
                    this.tableFilters = detail;
                }}
            ></omega-filter-bar>
            <omega-table
                autostart
                .recordset=${this.recordset}
                .columnDefinitions=${this.columns}
                .loading=${this.loading}
                .rowsPerPage=${this.rowsPerPage}
                @action=${({ detail }) => this.handleAction(detail)}
                @error=${({ detail }) => {
                    const { error } = detail;
                    this.alert = {
                        ...this.alert,
                        visible: true,
                        type: 'error',
                        message: error.responseDetails[0].responseMessage,
                        code: error.responseDetails[0].responseCode,
                    };
                }}
            ></omega-table>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                padding: 5px 15px;
            }
            omega-field {
                margin-bottom: 10px;
            }
            .header-bar {
                display: flex;
                justify-content: space-between;
            }
            .dialog-body {
                padding: 20px;
            }
        `;
    }
}

customElements.define('feature-flags', FeatureFlags);
