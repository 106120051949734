import { html, nothing } from 'lit';
import '@treasury/omega/components/omega-alert.js';

export default function BackOfficeAlertMixin(superClass) {
    class BackOfficeAlert extends superClass {
        constructor() {
            super();
            this.alert = {
                title: '',
                message: '',
                visible: false,
                code: '',
                time: '',
                type: '',
                posture: 'polite',
                actions: '',
            };
        }

        renderAlert() {
            const { code, time, message, type, title, actions, posture, visible } = this.alert;
            const renderedCode = code ? html`${code}: ` : nothing;
            const renderedTime = time ? html`<br />Time: ${time}` : nothing;

            if (!visible) return nothing;

            return html`
                <omega-alert
                    type=${type}
                    title=${title}
                    posture=${posture}
                    ?isVisible=${visible}
                    @close=${() => {
                        this.alert = { ...this.alert, visible: false };
                    }}
                >
                    ${renderedCode} ${message} ${renderedTime}
                    <div slot="actions">${actions}</div>
                </omega-alert>
            `;
        }
    }
    return BackOfficeAlert;
}
