import { BankDto } from '@treasury/domain/shared/types';
import { FieldType, string } from '@treasury/FDL';
import { FdlFieldDefinitions } from '@treasury/FDL/record';

export function createBankFieldDefinitions(bankIdLabel: string): FdlFieldDefinitions<BankDto> {
    return {
        bankId: string.with.label(bankIdLabel).thatIs.readOnly() as unknown as FieldType<number>,
        name: string.with.label('Bank Name').thatIs.readOnly(),
        notes: string.with.label('Notes').thatIs.readOnly(),
        addressLine1: string.with.label('Address Line 1').thatIs.readOnly(),
        addressLine2: string.with.label('Address Line 2').thatIs.readOnly(),
        addressLine3: string.with.label('Address Line 3').thatIs.readOnly(),
        city: string.with.label('City').thatIs.readOnly(),
        state: string.with.label('State').thatIs.readOnly(),
        postalCode: string.with.label('Zip').thatIs.readOnly(),
        country: string.with.label('Bank Country').thatIs.readOnly(),
        countryCode: string.with.label('Bank Country').thatIs.readOnly(),
    };
}
