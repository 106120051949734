import { Injectable } from '@jack-henry/frontend-utils/di';
import { ChallengeGroupsClient, CompaniesClient } from '@treasury/api/bo';
import { ChallengeGroup, Company } from './';
import { CompanyAccount } from './entities/company-account.entity';

@Injectable()
export class CompanyService {
    constructor(
        private readonly companiesClient: CompaniesClient,
        private readonly challengeGroupsClient: ChallengeGroupsClient
    ) {}

    /**
     * Retrieves a company by its ID and type.
     * @param companyId - The ID of the company.
     * @param companyType - The type of the company.
     * @returns A Promise that resolves to a Company object.
     */
    public async getCompanyById(companyId: number, companyType: number) {
        const companyResponse = await this.companiesClient.companiesGetOnboardedCompany(
            companyId,
            companyType
        );

        const challengeGroups = await this.getAllChallengeGroups();

        return new Company(companyResponse.data, challengeGroups);
    }

    /**
     * Retrieves all challenge groups.
     * @returns {Promise<ChallengeGroup[]>} A promise that resolves to an array of ChallengeGroup objects.
     */
    public async getAllChallengeGroups() {
        const challengeGroups = await this.challengeGroupsClient.challengeGroupsGetAll();
        return challengeGroups.data.map(cg => new ChallengeGroup(cg));
    }

    async getCompanyAccounts(companyId: number) {
        const accounts = (await this.companiesClient.companiesAccountsAllGet(companyId)).data;
        return accounts.map(account => new CompanyAccount(account));
    }

    async updateCompanyStatus(companyId: number, accountId: number, active: boolean) {
        return await this.companiesClient.companiesAccountStatus(
            companyId,
            accountId,
            active ? 'Active' : 'Inactive'
        );
    }
}
