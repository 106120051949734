angular.module('backOffice').controller('ApprovalSettingsController', approvalSettingsController);

approvalSettingsController.$inject = ['$scope', 'companyId', 'companiesService', '$modalInstance'];

function approvalSettingsController($scope, companyId, companiesService, $modalInstance) {
    $scope.companyId = companyId;
    $scope.save = save;
    $scope.saveIpSettings = saveIpSettings;
    $scope.setIpForm = setIpForm;
    $scope.setUserApprovalForm = setUserApprovalForm;
    $scope.cancel = cancel;
    init();

    function init() {
        companiesService.getApprovalSettings($scope.companyId).then(response => {
            $scope.approvalSettings = response;
        });
        getCompaniesIpAddressSettings();
    }

    function save() {
        companiesService
            .updateApprovalSettings($scope.companyId, $scope.approvalSettings)
            .then(response => {
                $scope.approvalSettings = response;
            });
    }

    function saveIpSettings() {
        companiesService
            .updateIpAddressSettings($scope.companyId, $scope.ipAddresses)
            .then(response => {
                $scope.ipAddresses = response;
            });
    }

    function setIpForm(form) {
        $scope.Ipform = form;
    }

    function setUserApprovalForm(form) {
        $scope.userApprovalForm = form;
    }

    function cancel() {
        $modalInstance.close();
    }

    function getCompaniesIpAddressSettings() {
        companiesService.getIpAddressSettings($scope.companyId).then(response => {
            $scope.ipAddresses = response;
        });
    }
}
