import '@treasury/omega/components/omega-button.js';
import '@treasury/omega/components/omega-filter-bar.js';
import '@treasury/omega/components/omega-table.js';
import '@treasury/omega/components/progress/omega-progress.js';
import '@treasury/omega/layouts/omegaSection.js';
import { css, html, LitElement } from 'lit';

class DarkContainer extends LitElement {
    constructor() {
        super();
        this.views = {
            /**
             * COMPANY ROUTES
             */
            company: {
                group: 'COMPANY',
                pages: [
                    {
                        name: 'Company Listing',
                        url: `company-search-dark`,
                    },
                ],
            },

            /**
             * USER ROUTES
             */
            user: {
                group: 'USER',
                pages: [],
            },

            /**
             * CONFIGURATION ROUTES
             */
            configuration: {
                group: 'CONFIGURATION',
                pages: [],
            },

            /**
             * REPORTING
             */
            reporting: {
                group: 'REPORTS',
                pages: [],
            },
        };
    }

    routeClick(e) {
        window.location.href = e.url;
    }

    renderPageButton(view) {
        return html`<omega-button type="primary" @click=${() => this.routeClick(view)}
            >${view.name}</omega-button
        >`;
    }

    renderPageList() {
        return Object.keys(this.views).map(
            key =>
                html`<h3>${this.views[key].group}</h3>
                    ${this.views[key].pages.map(view => this.renderPageButton(view))}`
        );
    }

    render() {
        return html`
            <h1>Dark Pages</h1>
            <div class="dark-display">${this.renderPageList()}</div>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                margin: 20px;
            }
            .dark-display {
                flex-grow: 2;
            }
        `;
    }
}

export default DarkContainer;
window.customElements.define('dark-container', DarkContainer);
