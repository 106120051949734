angular
    .module('backOffice')
    .controller('aaCompanyConfigurationController', aaCompanyConfigurationController);

aaCompanyConfigurationController.$inject = [
    '$scope',
    '$stateParams',
    '$state',
    '$modal',
    'companiesService',
    'transactionCodesService',
    'toaster',
];

function aaCompanyConfigurationController(
    $scope,
    $stateParams,
    $state,
    $modal,
    companiesService,
    transactionCodesService,
    toaster
) {
    const achAnalysisTransactionTypeCategoryId = 1;
    $scope.achAccountNumbers = {};
    $scope.selectedTab = 'ACH';
    $scope.selectTab = selectTab;
    init();

    $scope.openAccountsModal = function (
        analysisTransactionTypeCategoryId,
        idxAnalysisTransactionType
    ) {
        const transactionTypeCategory =
            $scope.analysisTransactionTypeCategories[analysisTransactionTypeCategoryId];
        const { analysisTransactionTypeId } =
            transactionTypeCategory.analysisTransactionTypes[idxAnalysisTransactionType];
        $modal
            .open({
                template: require('../views/aaConfigAccountsModalView.html'),
                controller: 'aaConfigAccountsModalController',
                windowClass: 'scroll-modal',
                resolve: {
                    companyId() {
                        return $scope.companyId;
                    },
                    product() {
                        return transactionTypeCategory.category;
                    },
                    transactionTypeCategory() {
                        return transactionTypeCategory.analysisTransactionTypes[
                            idxAnalysisTransactionType
                        ];
                    },
                    analysisTransactionTypeId() {
                        return transactionTypeCategory.analysisTransactionTypes[
                            idxAnalysisTransactionType
                        ].analysisTransactionTypeId;
                    },
                    description() {
                        return transactionTypeCategory.analysisTransactionTypes[
                            idxAnalysisTransactionType
                        ].description;
                    },
                    accountCustomTransactionCodes() {
                        const customTransactionCode =
                            $scope.customTransactionCodes[analysisTransactionTypeId];
                        if (customTransactionCode != undefined) {
                            return customTransactionCode.accountCustomTransactionCodes;
                        }
                        return {};
                    },
                },
            })
            .result.then(result => {
                if (result.action === 'save') {
                    if (result.accounts.length) {
                        const customTransactionCode =
                            $scope.customTransactionCodes[analysisTransactionTypeId];
                        if (customTransactionCode.accountCustomTransactionCodes === undefined) {
                            customTransactionCode.accountCustomTransactionCodes = {};
                        }
                        angular.forEach(result.accounts, account => {
                            const accountCustomTransactionCode =
                                customTransactionCode.accountCustomTransactionCodes[account.id];
                            if (accountCustomTransactionCode != undefined) {
                                accountCustomTransactionCode.active = account.active;
                            } else if (account.active) {
                                customTransactionCode.accountCustomTransactionCodes[account.id] = {
                                    accountId: account.id,
                                    customTransactionCodeId: customTransactionCode.id,
                                    active: account.active,
                                    accountNumber: account.number,
                                };
                            }

                            // special ach single account logic
                            if (
                                transactionTypeCategory.analysisTransactionTypeCategoryId ==
                                    achAnalysisTransactionTypeCategoryId &&
                                account.active
                            ) {
                                if (
                                    customTransactionCode.accountCustomTransactionCodes[0] !=
                                    undefined
                                ) {
                                    delete customTransactionCode.accountCustomTransactionCodes[0];
                                }
                                $scope.achAccountNumbers[analysisTransactionTypeId] =
                                    account.number;
                            }
                        });
                        $scope.customTransactionCodes[analysisTransactionTypeId] =
                            customTransactionCode;
                        $scope.aaConfigForm.$setDirty();
                    }
                }
            });
    };

    $scope.saveConfiguration = function (analysisTransactionTypeCategoryId) {
        // convert transaction codes to array

        const customTransactionCodes = [];
        angular.forEach($scope.customTransactionCodes, customTransactionCode => {
            if (
                customTransactionCode.analysisTransactionTypeCategoryId ===
                analysisTransactionTypeCategoryId
            ) {
                if (
                    customTransactionCode.accountCustomTransactionCodes !== undefined &&
                    Object.keys(customTransactionCode.accountCustomTransactionCodes).length
                ) {
                    const accounts = [];
                    angular.forEach(
                        customTransactionCode.accountCustomTransactionCodes,
                        accountCustomTransactionCode => {
                            if (accountCustomTransactionCode != undefined) {
                                accounts.push({
                                    accountId: accountCustomTransactionCode.accountId,
                                    active: accountCustomTransactionCode.active,
                                    customTransactionCodeId:
                                        accountCustomTransactionCode.customTransactionCodeId,
                                    id: accountCustomTransactionCode.id,
                                });
                            }
                        }
                    );
                    customTransactionCodes.push({
                        id: customTransactionCode.id,
                        transactionCode: customTransactionCode.transactionCode.trim(),
                        analysisTransactionTypeId: customTransactionCode.analysisTransactionTypeId,
                        accountCustomTransactionCodes: accounts,
                    });
                }
            }
        });
        transactionCodesService
            .saveCustomTransactionCodes(
                customTransactionCodes,
                $scope.companyId,
                analysisTransactionTypeCategoryId
            )
            .then(responseCustomTransactionCodes => {
                $scope.customTransactionCodes = transformCustomTransactionCodes(
                    responseCustomTransactionCodes
                );
                createMissingCustomTransactionCodes();
                toaster.save('Account Analysis Configuration');
                $scope.aaConfigForm.$setPristine();
            });
    };

    $scope.isSaveButtonDisabled = function () {
        if (!$scope.aaConfigForm.$dirty || !$scope.aaConfigForm.$valid) {
            return true;
        }

        for (const customTransactionCode of Object.values($scope.customTransactionCodes)) {
            const accountCustomTransactionCodes =
                customTransactionCode.accountCustomTransactionCodes || {};
            if (customTransactionCode.transactionCode.trim().length) {
                if (Object.values(accountCustomTransactionCodes).length) {
                    return false;
                }
                return true;
            }
        }
    };

    $scope.achAccountNumberChanged = function (analysisTransactionTypeId) {
        let { accountCustomTransactionCodes } =
            $scope.customTransactionCodes[analysisTransactionTypeId];
        const accountNumber = $scope.achAccountNumbers[analysisTransactionTypeId];

        if (accountCustomTransactionCodes === undefined) {
            $scope.customTransactionCodes[analysisTransactionTypeId].accountCustomTransactionCodes =
                accountCustomTransactionCodes = {};
        }

        let isNewObjectNeeded = accountNumber.length > 0;
        for (const accountId in accountCustomTransactionCodes) {
            const accountCustomTransactionCode = accountCustomTransactionCodes[accountId];
            if (accountCustomTransactionCode.accountNumber === accountNumber) {
                accountCustomTransactionCode.active = true;
                isNewObjectNeeded = false;
            } else {
                if (accountCustomTransactionCode.id == undefined) {
                    // remove unsaved account
                    delete accountCustomTransactionCodes[accountId];
                }
                accountCustomTransactionCode.active = false;
            }
        }

        if (isNewObjectNeeded) {
            accountCustomTransactionCodes[0] = {
                id: 0,
                accountId: 0,
                active: true,
                accountNumber,
            };
        }
    };

    $scope.cancel = function () {
        if ($scope.aaConfigForm.$dirty) {
            $modal
                .open({
                    template: require('../views/aaConfigCancelView.html'),
                    controller: 'aaConfigCancelController',
                })
                .result.then(result => {
                    if (result === 'yes') {
                        $state.reload();
                    }
                });
        } else {
            $state.reload();
        }
    };

    $scope.navigateToCompany = function() {
        $state.go('company', {id: $scope.companyId});
    }

    function init() {
        if ($stateParams.companyName != undefined) {
            $scope.companyId = $stateParams.companyId;
            $scope.companyName = $stateParams.companyName;
            loadTransactionCodeData();
        } else if (
            $stateParams.companyId != undefined &&
            !isNaN($stateParams.companyId) &&
            $stateParams.companyId > 0
        ) {
            // call service
            companiesService.getCompanyName($stateParams.companyId).then(getCompanyResponse => {
                $scope.companyId = $stateParams.companyId;
                $scope.companyName = getCompanyResponse.name;
                loadTransactionCodeData();
            });
        } else {
            // invalid params, redirect to company search
            $state.go('company-search');
        }
    }

    function loadTransactionCodeData() {
        const achAnalysisTransactionTypeCategoryId = 1;
        const idxAchAnalysisTransactionTypeCategoryId = 0;
        transactionCodesService
            .getAnalysisTransactionTypes()
            .then(analysisTransactionTypeResponse => {
                $scope.analysisTransactionTypeCategories =
                    transactionCodesService.groupAnalysisTransactionTypesByCategory(
                        analysisTransactionTypeResponse
                    );
                selectTab(
                    'ACH',
                    achAnalysisTransactionTypeCategoryId,
                    idxAchAnalysisTransactionTypeCategoryId
                );
                createMissingCustomTransactionCodes();
            });
    }

    function transformCustomTransactionCodes(customTransactionCodes) {
        const transformed = {};
        if (customTransactionCodes.length > 0) {
            $scope.isUsingDefaultFiCounters =
                Object.keys(customTransactionCodes[0].accountCustomTransactionCodes).length === 0;
        }
        angular.forEach(customTransactionCodes, customTransactionCode => {
            // make object searchable by accountId
            const accountCustomTransactionCodes = {};
            angular.forEach(
                customTransactionCode.accountCustomTransactionCodes,
                accountCustomTransactionCode => {
                    accountCustomTransactionCodes[accountCustomTransactionCode.accountId] =
                        accountCustomTransactionCode;
                }
            );
            customTransactionCode.accountCustomTransactionCodes = accountCustomTransactionCodes;
            transformed[customTransactionCode.analysisTransactionTypeId] = customTransactionCode;
        });
        return transformed;
    }

    function newCustomTransactionCode(
        transactionCode,
        analysisTransactionTypeId,
        analysisTransactionTypeCategoryId
    ) {
        return {
            transactionCode,
            analysisTransactionTypeId,
            analysisTransactionTypeCategoryId,
        };
    }

    function createMissingCustomTransactionCodes() {
        if (
            $scope.analysisTransactionTypeCategories != undefined &&
            $scope.customTransactionCodes != undefined
        ) {
            angular.forEach(
                $scope.analysisTransactionTypeCategories,
                analysisTransactionTypeCategory => {
                    angular.forEach(
                        analysisTransactionTypeCategory.analysisTransactionTypes,
                        analysisTransactionType => {
                            // add missing CustomTransactionCode for this transaction type
                            const customTransactionCode =
                                $scope.customTransactionCodes[
                                    analysisTransactionType.analysisTransactionTypeId
                                ];
                            if (customTransactionCode === undefined) {
                                $scope.customTransactionCodes[
                                    analysisTransactionType.analysisTransactionTypeId
                                ] = newCustomTransactionCode(
                                    '',
                                    analysisTransactionType.analysisTransactionTypeId,
                                    analysisTransactionTypeCategory.analysisTransactionTypeCategoryId
                                );
                            } else if (
                                analysisTransactionTypeCategory.analysisTransactionTypeCategoryId ===
                                achAnalysisTransactionTypeCategoryId
                            ) {
                                // special logic to display ach account numbers
                                $scope.achAccountNumbers[
                                    analysisTransactionType.analysisTransactionTypeId
                                ] = '';

                                const { accountCustomTransactionCodes } = customTransactionCode;
                                if (accountCustomTransactionCodes != undefined) {
                                    for (const accountId in accountCustomTransactionCodes) {
                                        const accountCustomTransactionCode =
                                            accountCustomTransactionCodes[accountId];
                                        if (accountCustomTransactionCode.active) {
                                            $scope.achAccountNumbers[
                                                analysisTransactionType.analysisTransactionTypeId
                                            ] =
                                                accountCustomTransactionCodes[
                                                    accountId
                                                ].accountNumber;
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    );
                }
            );
        }
    }

    function promptToSave() {
        $modal
            .open({
                template: require('../views/aaConfigSaveCancelView.html'),
                controller: 'aaConfigSaveCancelController',
            })
            .result.then(result => {
                if (result === 'ok') {
                    // do nothing
                }
            });
    }

    function selectTab(
        tabName,
        analysisTransactionTypeCategoryId,
        idxAnalysisTransactionTypeCategory
    ) {
        if ($scope.aaConfigForm.$dirty) {
            promptToSave();
        } else {
            changeTab(
                tabName,
                analysisTransactionTypeCategoryId,
                idxAnalysisTransactionTypeCategory
            );
        }
    }

    function changeTab(
        tabName,
        analysisTransactionTypeCategoryId,
        idxAnalysisTransactionTypeCategory
    ) {
        $scope.selectedTab = tabName;
        const selectedTransactionTypeCategory = $scope.analysisTransactionTypeCategories.filter(
            item => analysisTransactionTypeCategoryId === item.analysisTransactionTypeCategoryId
        );
        $scope.selectedTransactionTypeCategory = selectedTransactionTypeCategory[0];
        $scope.idxAnalysisTransactionTypeCategory = idxAnalysisTransactionTypeCategory;
        transactionCodesService
            .getCustomTransactionCodesForCompany(
                $scope.companyId,
                analysisTransactionTypeCategoryId
            )
            .then(customTransactionCodes => {
                $scope.customTransactionCodes =
                    transformCustomTransactionCodes(customTransactionCodes);
                createMissingCustomTransactionCodes();
            });
    }
}
