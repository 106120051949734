angular.module('backOffice').directive('reportTable', reportTable);

function reportTable() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            reportData: '=',
            columnModels: '=',

            isPaged: '=',
            pageSize: '=',
            pageNumber: '=',
            pageMaxSize: '=',
            totalCount: '=',
            reportId: '=',
            reportType: '=',

            /*
             * In the view for this directive we are using the ng-model for binding for pageNumber and pageSize and using
             * ng-change to know when to evaluate the onPaged and onSorted expressions.
             *
             * This causes an interesting situation where pageSize and pageNumber are updated for the directive scope and the ng-change is
             * fired but it has not updated for the pageSize and pageNumber attributes bound to this directive in the parent scope.
             *
             * This results in onPaged and onSorted being evaluated while the pageNumber and pageSize in the parent scope
             * still have the previous value. To work around this the pageNumber and pageSize are passed to the expressions
             * and they should be used instead of the values bound to pageNumber and pageSize in the parent scope.
             *
             * See:
             * https://github.com/angular/angular.js/issues/4558
             * https://stackoverflow.com/questions/33178205/ngchange-fires-before-value-makes-it-out-of-isolate-scope
             */
            onPaged: '&?',
            onSorted: '&',

            sorts: '=',
            defaultSorts: '=',
        },
        template: require('./reportTableView.html'),
        controller: 'ReportTableController as vm',
        bindToController: true,
    };
}
