import { LitElement, html, css } from 'lit';

import { FiAccountReconConfiguration } from '@treasury/domain/backoffice/requests/institution-configuration/account-recon-configuration.js';
import { string, boolean } from '@treasury/policy/primitives';
import { Recordset } from '@treasury/FDL';
// import openDialog from '@treasury/omega/open-dialog.js';
import '@treasury/omega/layouts/omega-table-edit.js';
import { mix } from 'mixwith';
import BackOfficeAlertMixin from '../../mix-ins/back-office-alert-mixin.js';

// usage: <account-reconciliation-reports-configuration></account-reconciliation-reports-configuration>
export default class AccountReconciliationReportsConfiguration extends mix(LitElement).with(
    BackOfficeAlertMixin
) {
    static get properties() {
        return {
            alert: Object,
        };
    }

    constructor() {
        super();
        this.title = 'Account Reconciliation Reporting Configuration';
        this.configurationFields = {
            displayName: string.with
                .minColumnWidth(200)
                .and.targetColumnWidth(200)
                .and.maxColumnWidth(200)
                .thatIs.readOnly(),
            description: string.with
                .minColumnWidth(300)
                .and.targetColumnWidth(300)
                .and.maxColumnWidth(300)
                .thatIs.readOnly(),
            isEnabled: boolean.with
                .minColumnWidth(40)
                .and.targetColumnWidth(40)
                .and.maxColumnWidth(4000)
                .as.tag('omega-toggle'),
        };
        this.columns = [
            { field: 'displayName', label: 'Report Name' },
            { field: 'description', label: 'Report Description' },
            { field: 'isEnabled', type: 'checkbox', label: 'Enabled' },
        ];
        this.accountReconConfiguration = {};
        this.fetchAccountReconConfiguration = async () => {
            const result = await FiAccountReconConfiguration.getConfigurationSettings();
            if (!result) return null;
            this.accountReconConfiguration = result;
            return result.institutionExternalReportTypes;
        };
        this.recordset = new Recordset(
            this.configurationFields,
            this.fetchAccountReconConfiguration
        );
        this.recordset.pageSize = 25;
    }

    async saveAccountReconConfiguration(e) {
        this.alert = { ...this.alert, visible: false };
        const result = await FiAccountReconConfiguration.saveConfigurationSettings(
            this.accountReconConfiguration
        );
        e.detail.reloadTable();
        this.alertOnSave(result); // Don't wait for this part before returning.
    }

    async alertOnSave(result) {
        // TODO: this whole function should be standardized and encapsulated somewhere to be reused throughout BackOffice.
        let titleStatus = 'Failed';
        let contentStatus = 'did not save successfully';
        if (result === true) {
            titleStatus = 'Succeeded';
            contentStatus = 'saved successfully';
        }

        this.alert = {
            ...this.alert,
            title: `Save ${titleStatus}`,
            message: `${this.title} ${contentStatus}.`,
            type: 'success',
            posture: 'polite',
            visible: true,
        };
    }

    render() {
        return html`
            ${this.renderAlert()}
            <div class="page-container" id="account-reconciliation-reports-configuration">
                <div id="form" allowed-entitlements="Edit Product Feature Configuration">
                    <omega-table-edit
                        title=${this.title}
                        .columns=${this.columns}
                        .recordset=${this.recordset}
                        @save=${this.saveAccountReconConfiguration}
                    ></omega-table-edit>
                </div>
            </div>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            ::after ::before {
                box-sizing: border-box;
            }
            .page-container {
                padding-left: 30px;
                padding-right: 30px;
                margin-top: 10px;
                margin-bottom: 10px;
                position: relative;
                background-color: #ecf2f6;
                box-sizing: border-box;
            }
        `;
    }
}

customElements.define(
    'account-reconciliation-reports-configuration',
    AccountReconciliationReportsConfiguration
);
