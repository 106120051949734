import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('baiConfigurationService', baiConfigurationService);

baiConfigurationService.$inject = ['$resource'];

function baiConfigurationService($resource) {
    const resourceUrl = `${getBackOfficeAPIPath()}baiconfiguration/`;
    return {
        getBaiConfiguration,
        saveBaiConfiguration,
    };

    function saveBaiConfiguration(selectedTransactionCodes) {
        return $resource(resourceUrl).save(selectedTransactionCodes).$promise;
    }

    function getBaiConfiguration() {
        return $resource(resourceUrl).get().$promise;
    }
}
