angular
    .module('backOffice')
    .controller('ManageCompanyUserStatusController', manageCompanyUserStatusController);

manageCompanyUserStatusController.$inject = [
    '$scope',
    'companyId',
    'userDetails',
    '$modalInstance',
    'companyUsersService',
];

function manageCompanyUserStatusController(
    $scope,
    companyId,
    userDetails,
    $modalInstance,
    companyUsersService
) {
    $scope.selectedUserStatus = userDetails;
    $scope.companyId = companyId;
    $scope.comments = '';

    // this will dismiss the window.
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    $scope.save = function () {
        companyUsersService
            .changeUserActiveStatus(
                $scope.companyId,
                $scope.selectedUserStatus.id,
                $scope.selectedUserStatus.isActive,
                $scope.comments
            )
            .then(response => {
                $modalInstance.close(response);
            });
    };

    // used to set the form instance.
    $scope.setForm = function (form) {
        $scope.form = form;
    };

    // we watch for form.$dirty, this is used to alert the end user that they changed something in the form.
    $scope.$watch('form.$dirty', isDirty => {
        $modalInstance.isDirty = isDirty;
    });
}
