angular.module('backOffice').controller('CustomizeAchController', customizeAchController);

customizeAchController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'companiesService',
    'modalService',
    'toaster',
    '$modal',
    'configurationService',
    'achService',
    'entitlementsService',
];

function customizeAchController(
    $scope,
    $state,
    $stateParams,
    companiesService,
    modalService,
    toaster,
    $modal,
    configurationService,
    achService,
    entitlementsService
) {
    const CompanyId = $stateParams.id;
    $scope.approvalOptions = ['None', '1', '2', '3'];
    $scope.internationalApprovalOptions = ['None', '1', '2', '3'];
    $scope.authenticationOptions = ['None', 'Password', 'Out of Band'];
    $scope.productFeature = {};
    $scope.list = [];
    $scope.productFeatureId = $stateParams.productFeatureId;
    $scope.achCompaniesFilterText = '';
    $scope.achCompaniesFiltered = [];
    $scope.hasInternationalTemplateEntitlement = hasInternationalTemplateEntitlement;

    $scope.getSecCodesString = getSecCodesString;
    $scope.addAchCompany = addAchCompany;
    $scope.deleteAchCompany = deleteAchCompany;
    $scope.manageSecCodes = manageSecCodes;
    $scope.cancel = cancel;
    $scope.save = save;
    $scope.goBack = goBack;
    $scope.changeStatus = changeStatus;
    $scope.updateAllowUnbalancedPayments = updateAllowUnbalancedPayments;
    $scope.fieldLimits = { maximumAmount: '9999999999999.99' };
    $scope.showBalanceValidationFields = showBalanceValidationFields;
    $scope.toggleAccountBalanceValidation = toggleAccountBalanceValidation;
    $scope.toggleAllowSameDay = toggleAllowSameDay;
    $scope.changePaymentProcessing = changePaymentProcessing;
    $scope.isSaveDisabled = isSaveDisabled;
    $scope.isUnlimited = isUnlimited;
    $scope.manageOffsetAccounts = manageOffsetAccounts;
    $scope.canManageOffsetAccounts = canManageOffsetAccounts;
    $scope.translateACHCoreCompanyLimit = translateACHCoreCompanyLimit;
    $scope.calculateMaximumProductDailyLimit = calculateMaximumProductDailyLimit;
    $scope.requiredEntitlements = requiredEntitlements;
    $scope.canEdit = false;
    $scope.pageTitle = '';
    $scope.hasFeature = hasFeature;
    $scope.hasEntitlement = hasEntitlement;
    $scope.isAllowOnUsAccessManagementEnabled = isAllowOnUsAccessManagementEnabled;
    $scope.isAllowChildSupportPaymentsEnabled = isAllowChildSupportPaymentsEnabled;
    $scope.isAllowUnbalancedPaymentsEnabled = isAllowUnbalancedPaymentsEnabled;
    $scope.isInternationalACHEnabled = isInternationalACHEnabled;
    $scope.shouldShowUnbalancedToggle = shouldShowUnbalancedToggle;
    $scope.isAchNotificationOfChangeEnabled = isAchNotificationOfChangeEnabled;
    $scope.isAchReturnReviewEnabled = isAchReturnReviewEnabled;
    $scope.selectOrDeselectAll = selectOrDeselectAll;
    $scope.isSelectAllSelected = false;
    $scope.hasSilverLakeOffsetAccountNumber = hasSilverLakeOffsetAccountNumber;
    // $scope.canEditDailyLimit = false;

    if ($stateParams.productFeatureId === '') {
        $scope.productFeatureId = 0;
    } else if (
        $stateParams.productFeatureId !== undefined ||
        $stateParams.productFeatureId !== null
    ) {
        $scope.productFeatureId = $stateParams.productFeatureId;
    }

    function init() {
        $scope.productFeature = {};
        companiesService.getCompanyName(CompanyId).then(response => {
            $scope.company = response;
            loadProductFeature();
        });
        if ($scope.productFeatureId === 0) {
            $scope.canEdit = $scope.hasEntitlement('Add Product Feature');
            $scope.pageTitle = 'Add ACH';
        } else {
            $scope.canEdit = $scope.hasEntitlement('Edit Product Feature');
            $scope.pageTitle = $scope.canEdit ? 'Edit ACH' : 'View ACH';
        }
        $scope.$watch('achCompaniesFilterText', filterAchCompanies);
        // $scope.canEditDailyLimit =
        //     $scope.hasEntitlement('Execute Company Search') &&
        //     $scope.hasEntitlement('Access Company Profile') &&
        //     $scope.hasEntitlement('Edit Product Feature');
    }

    function hasEntitlement(entitlement) {
        return entitlementsService.hasEntitlement(entitlement);
    }

    function hasInternationalTemplateEntitlement() {
        return $scope.hasEntitlement('Feature.Ach.InternationalPaymentTemplates');
    }

    function isAllowOnUsAccessManagementEnabled() {
        return hasEntitlement('Feature.ACH.AllowOnUsAccessManagement');
    }

    function isAllowUnbalancedPaymentsEnabled() {
        return hasEntitlement('Feature.ACH.AllowUnbalancedPayments');
    }

    function isAllowChildSupportPaymentsEnabled() {
        return hasEntitlement('Feature.ACH.AllowChildSupportPayments');
    }

    function isInternationalACHEnabled() {
        return hasEntitlement('Feature.ACH.AllowInternationalPayments');
    }

    function isAchNotificationOfChangeEnabled() {
        return (
            hasEntitlement('Feature.Ach.NotificationOfChange') ||
            hasEntitlement('Feature.ACH.NotificationOfChange.Nacha')
        );
    }

    function isAchReturnReviewEnabled() {
        return (
            hasEntitlement('Feature.Ach.ReturnReview') || hasEntitlement('Feature.ACH.Return.Nacha')
        );
    }

    function requiredEntitlements() {
        return $scope.productFeatureId === 0 ? 'Add Product Feature' : 'Edit Product Feature';
    }

    function save() {
        if ($scope.productFeature.id !== 0) {
            companiesService
                .updateProductFeature(CompanyId, $scope.productFeature)
                .then(response => {
                    toaster.save('ACH');
                });
        } else if ($scope.productFeature.id === 0) {
            companiesService.saveProductFeature(CompanyId, $scope.productFeature).then(response => {
                $scope.productFeature.achCompanies = [];
                $scope.productFeature.id = response.id;
                $scope.productFeatureId = response.id;
                $stateParams.productFeatureId = response.id;
                toaster.save('ACH');
            });
        }
    }

    function selectOrDeselectAll() {
        $scope.productFeature.achCompanies = $scope.productFeature.achCompanies.map(company => ({
            ...company,
            isCollapsed: !company.isCollapsed,
        }));
        $scope.isSelectAllSelected = !$scope.isSelectAllSelected;
    }

    function toggleAccountBalanceValidation() {
        $scope.productFeature.validateAccountBalance =
            !$scope.productFeature.validateAccountBalance;
        $scope.form.$setDirty();
    }

    function toggleAllowSameDay() {
        $scope.productFeature.allowSameDayAch = !$scope.productFeature.allowSameDayAch;
        $scope.form.$setDirty();
    }

    function showBalanceValidationFields(achCompany) {
        return (
            $scope.productFeature.accountBalanceValidationConfiguredInCore &&
            achCompany.settlementAccountType &&
            achCompany.balanceValidationType
        );
    }

    function filterAchCompanies() {
        if ($scope.achCompaniesFilterText.length < 2) {
            $scope.achCompaniesFiltered = $scope.productFeature.achCompanies;
            return;
        }

        $scope.achCompaniesFiltered = $scope.productFeature.achCompanies.filter(
            ({
                companyName = '',
                companyId = '',
                secCodes,
                entryDescription = '',
                discretionaryData = '',
            }) => {
                const pattern = new RegExp($scope.achCompaniesFilterText, 'gi');

                return (
                    pattern.test(companyName) ||
                    pattern.test(companyId) ||
                    pattern.test(secCodes.map(c => c.code).join()) ||
                    pattern.test(entryDescription) ||
                    pattern.test(discretionaryData)
                );
            }
        );
    }

    function addAchCompany() {
        const modalInstance = $modal.open({
            template: require('../views/addAchCompanyModal.html'),
            size: 'md',
            controller: 'AddAchCompanyController',
            backdrop: 'static',
            resolve: {
                achCompanies() {
                    return $scope.productFeature.achCompanies;
                },
                achCompanyMatchType() {
                    return $scope.productFeature.achCompanyMatchType;
                },
            },
        });
        modalInstance.result.then(response => {
            angular.forEach(response, item => {
                item.isCollapsed = false;
                translateBalanceValidationType(item);
                $scope.productFeature.achCompanies = [...$scope.productFeature.achCompanies, item];
            });
            filterAchCompanies();
        });
    }

    function cancel() {
        init();
    }

    function deleteAchCompany(achCompany) {
        const modalOptions = {
            bodyText:
                'Are you sure you want to delete this ACH company? Removing it will prohibit users from uploading or creating ACH batches and payments for this company.',
            submit(result) {
                achService.deleteAchCompany($scope.productFeature.id, achCompany).then(response => {
                    $scope.productFeature.achCompanies = $scope.productFeature.achCompanies.filter(
                        c => c !== achCompany
                    );
                });
                $modalInstance2.close(result);
            },
        };
        var $modalInstance2 = modalService.showModal({}, modalOptions);
    }

    function getSecCodesString(achCompany) {
        return achCompany.secCodes.map(elem => elem.code).join(', ');
    }

    function goBack() {
        $state.go('company', { id: CompanyId });
    }

    function manageSecCodes(achCompany) {
        const modalInstance = $modal.open({
            template: require('../../../../configuration/productFeature/views/manageSecCodesModalTemplate.html'),
            size: 'md',
            controller: 'ManageSecCodesController',
            backdrop: 'static',
            resolve: {
                selectedSecCodes() {
                    return achCompany.secCodes;
                },
                productFeatureId() {
                    return $scope.productFeatureId;
                },
                allowInternationalAchPayments() {
                    return $scope.productFeature.allowInternationalAchPayments;
                },
            },
        });
        modalInstance.result.then(response => {
            achService
                .updateSecCodes($scope.productFeature.id, achCompany, response)
                .then(_response => {
                    achCompany.secCodes = _response;
                });
        });
    }

    function changeStatus(company) {
        const modalOptions = {
            bodyText: 'Are you sure you want to change the ACH Company Status?',
            submit(result) {
                // change status
                const newStatus = company.isActive !== true;
                company.isActive = newStatus;
                $modalInstance.close(result);
                achService
                    .updateAchCompanyStatus($scope.productFeature.id, company)
                    .then(response => {
                        company = response;
                    })
                    .catch(() => {
                        company.isActive = !company.isActive;
                    });
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    function updateAllowUnbalancedPayments(company, updatedValue) {
        const newAllowUnbalancedPayments = updatedValue ?? company.allowUnbalancedPayments !== true;
        company.allowUnbalancedPayments = newAllowUnbalancedPayments;

        // change status
        achService.updateAchCompanyStatus($scope.productFeature.id, company).then(response => {
            company = response;
        });
    }

    function changePaymentProcessing(company) {
        const modalOptions = {
            bodyText: 'Are you sure you want to change the payment processing configuration?',
            submit(result) {
                // change status
                const newStatus = company.isPaymentProcessingCompany !== true;
                company.isPaymentProcessingCompany = newStatus;
                $modalInstance.close(result);
                achService.updateAchCompanyPaymentProcessingStatus(company).then(response => {
                    company = response;
                });
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    function calculateMaximumProductDailyLimit() {
        let maxProductDailyLimit = 0;
        if (
            !!$scope.productFeature &&
            !!$scope.productFeature.achCompanies &&
            !!$scope.productFeature.achCompanies.length > 0
        ) {
            maxProductDailyLimit = $scope.productFeature.achCompanies
                .map(achCompany => $scope.translateACHCoreCompanyLimit(achCompany.totalDailyLimit))
                .reduce((curr, prev) => Number(curr) + Number(prev));
        }
        return maxProductDailyLimit;
    }

    function hasFeature(feature) {
        return (
            $scope.productFeature.hasOwnProperty(feature) &&
            typeof $scope.productFeature[feature] === 'boolean'
        );
    }

    function loadProductFeature() {
        if ($scope.productFeatureId !== 0) {
            companiesService
                .getProductPermissions(CompanyId, $scope.productFeatureId)
                .then(response => {
                    $scope.productFeature = response;
                    $scope.productFeature.has = $scope.hasFeature;
                    filterAchCompanies();
                    angular.forEach($scope.productFeature.achCompanies, achCompany => {
                        achCompany.isCollapsed = true;
                        translateBalanceValidationType(achCompany);
                    });
                    setTimeout(() => {
                        $scope.$watch(
                            'productFeature.allowUnbalancedPayments',
                            onCompanyAllowUnbalancedChange
                        );
                        $scope.$watch('productFeature.achCompanies', filterAchCompanies);
                    }, 200);
                });
        } else if ($scope.productFeatureId === 0) {
            configurationService.getProductFeatureDetailsByName('Ach').then(response => {
                $scope.productFeature = response;
                $scope.productFeature.has = $scope.hasFeature;
                $scope.productFeature.id = 0;
                $scope.productFeature.type = 'ACH';
            });
        }
    }

    function translateBalanceValidationType(achCompany) {
        let result;
        switch (achCompany.balanceValidationType) {
            case 'A':
                result = 'Available';
                break;
            case 'L':
                result = 'Current';
                break;
            case 'C':
                result = 'Collected';
                break;
            default:
                result = null;
        }
        achCompany.balanceValidationType = result;
    }

    function translateACHCoreCompanyLimit(limit) {
        if (limit === 0) {
            return $scope.fieldLimits.maximumAmount;
        }
        if (limit > $scope.fieldLimits.maximumAmount) {
            return $scope.fieldLimits.maximumAmount;
        }
        return limit;
    }

    // ACH Company Core Limits can be set to zero or blank in SL to indicate an unlimited core limit
    function isUnlimited(limit) {
        return !limit;
    }

    function manageOffsetAccounts(achCompanyId) {
        $modal.open({
            template: require('../views/manageAchOffsetAccounts.html'),
            size: 'lg',
            controller: 'ManageAchOffsetAccountsController',
            backdrop: 'static',
            resolve: {
                parentCompanyId() {
                    return CompanyId;
                },
                achCompanyId() {
                    return achCompanyId;
                },
            },
        });
    }

    function canManageOffsetAccounts(achCompany) {
        if ($scope.isAllowUnbalancedPaymentsEnabled()) {
            if (achCompany.allowUnbalancedPayments) return false;
            if (
                achCompany.prefundingDays > 0 &&
                achCompany.notOnUsTransactionTypes === 'Credit Only'
            )
                return false;
        }
        return !!achCompany && achCompany.batchBalanceRequirements !== 'Balanced';
    }

    function hasSilverLakeOffsetAccountNumber(achCompany) {
        return !!achCompany && !!achCompany.offsetAccountNumber;
    }

    function isSaveDisabled() {
        return $scope.form.$pristine || $scope.form.$invalid;
    }

    function shouldShowUnbalancedToggle(achCompany) {
        if (!$scope.isAllowUnbalancedPaymentsEnabled()) return false;
        return achCompany.batchBalanceRequirements !== 'Balanced';
    }

    function onCompanyAllowUnbalancedChange(newValue, oldValue) {
        if (newValue !== oldValue) {
            $scope.productFeature.achCompanies.forEach(company => {
                if (
                    shouldShowUnbalancedToggle(company) &&
                    hasSilverLakeOffsetAccountNumber(company)
                ) {
                    company.allowUnbalancedPayments = newValue;
                    updateAllowUnbalancedPayments(company, newValue);
                }
            });
        }
    }

    init();
}
