angular.module('backOffice').constant(
    'downloadPage',
    Object.freeze({
        None: 0,
        WireTransferPaymentReport: 1,
        FxWireTransferPaymentReport: 2,
        AchPaymentReport: 3,
        PositivePayIssuedItemsReport: 4,
        CompanyUserActivityReport: 5,
        InstitutionUserActivityReport: 6,
        WireTransferPaymentDetails: 7,
        FxWireTransferPaymentDetails: 8,
        LoanPaymentReport: 10,
        FiUserActivityReport: 11,
    })
);
