angular.module('backOffice').controller('AccountSettingsController', accountSettingsController);

accountSettingsController.$inject = [
    '$scope',
    'companiesService',
    'companyId',
    '$modalInstance',
    'accountMaskTypes',
];

function accountSettingsController(
    $scope,
    companiesService,
    companyId,
    $modalInstance,
    accountMaskTypes
) {
    $scope.companyId = companyId;
    $scope.save = save;
    $scope.cancel = cancel;
    $scope.accountMaskTypes = accountMaskTypes;
    init();

    function init() {
        companiesService.getAccountSettings($scope.companyId).then(response => {
            $scope.accountSettings = response;
        });
    }

    function save() {
        companiesService
            .updateAccountSettings($scope.companyId, $scope.accountSettings)
            .then(response => {
                $scope.accountSettings = response;
                $modalInstance.close();
            });
    }

    function cancel() {
        $modalInstance.dismiss();
    }
}
