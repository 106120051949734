import { BeneficiaryDto, RateQuote, WireCompanyDto } from '@treasury/domain/shared/types';
import { WireDto } from '@treasury/domain/shared/types/wire.dto';
import { FieldType, number, string } from '@treasury/FDL';
import { FdlFieldDefinitions } from '@treasury/FDL/record';
import { achPaymentAudit } from '@treasury/policy/ach';
import fieldTypes from '@treasury/policy/primitives';
import { html } from 'lit';

export const WireFieldDefinitions: FdlFieldDefinitions<WireDto> = {
    amount: fieldTypes.money.with.label('Wire Amount').thatIs.readOnly() as FieldType<number>,
    audit: achPaymentAudit.thatIs.readOnly().thatHas.label('Audit'),
    beneficiary: new FieldType<BeneficiaryDto>().thatIs.readOnly(),
    confirmationNumber: string.with
        .label('OMAD')
        .thatIs.required()
        .thatIs.readOnlyWhen<WireDto>(record => !record.getField('isWireEditable')),
    debitAccount: string.with.label('Debit Account').thatIs.readOnly(),
    destinationCurrency: string.with
        .label('Destination Currency')
        .thatIs.readOnly()
        .thatIs.visibleWhen((record: any) => record.getField('isDli')),
    frequency: fieldTypes.frequency.thatIs.readOnly() as FieldType<WireDto['frequency']>,
    purpose: string.with.label('Purpose').thatIs.readOnly(),
    additionalInformation: string.with
        .label('Additional Information')
        .thatIs.readOnly()
        .thatHas.template((v: any) =>
            v.map((information: string) => html`<span>${information}</span> <br />`)
        ),
    rateQuote: new FieldType<RateQuote>().thatIs
        .readOnly()
        .with.label('Rate Quote')
        .thatIs.visibleWhen(() => false),
    referenceBeneficiary: string.with
        .label('Reference Beneficiary')
        .thatIs.readOnly()
        .thatIs.visibleWhen((record: any) => !record.getField('isInternational')),
    status: string.with
        .label('Status')
        .with.options({
            data: [
                { value: 'Transmitted', text: 'Transmitted' },
                { value: 'Posted', text: 'Posted' },
            ],
            text: record => record.text,
            value: record => record.value,
        })
        .thatIs.required()
        .thatIs.readOnlyWhen<WireDto>(record => !record.getField('isWireEditable'))
        .with.validator({
            name: 'status must be changed',
            validate: (v: any, mv, record: any) => v !== record.initialValues.status,
        })
        .with.template((transactionId, record) => {
            const errorSummary = record.getField('errorSummary');
            if (errorSummary?.summaryMessage)
                return html`${record.getField('status')}
                    <omega-tooltip light direction="bottom" icon="exclamation-triangle">
                        <div slot="content" style="max-width: 300px;">
                            ${errorSummary.summaryMessage}
                            <ul>
                                ${errorSummary.details.map(
                                    (error: string) => html`<li>${error}</li>`
                                )}
                            </ul>
                        </div>
                    </omega-tooltip>`;
            return record.getField('status');
        }),
    statusId: number,
    transactionId: string.with
        .label('Transaction ID')
        .thatIs.readOnly() as unknown as FieldType<number>,
    wireCompany: new FieldType<WireCompanyDto>().thatIs
        .readOnly()
        .with.label('Wire Company Name')
        .with.formatter((wireCompany: any) => wireCompany.name),
};
