import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { Record as FdlRecord } from '@treasury/FDL';
import { FdlFieldDefinitions } from '@treasury/FDL/record';
import { BankDto, WireDto } from '@treasury/domain/shared/types/';
import { BeneficiaryDto } from '@treasury/domain/shared/types/beneficiary.dto';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-button';
import '@treasury/omega/components/omega-field';
import { AlertMixin } from '@treasury/omega/mixins';
import { LitElement, css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../components/blocking-loader';
import { WirePaymentDetailClient } from '../clients/wire-payment-detail-client';
import { createBankFieldDefinitions } from '../data/bank-field-definitions';
import { BeneficiaryFieldDefinitions } from '../data/beneficiary-field-definitions';
import { WireFieldDefinitions } from '../data/wire-field-definitions';
import { CardStates } from './enums/card-states';
import { renderBeneficiary } from './wire-card-templates/render-beneficiary.template';
import { renderExchangeRate } from './wire-card-templates/render-exchange-rate.template';

const mixin = AlertMixin(ListeningElementMixin(LitElement));

@customElement('wire-payment-detail-card')
export class WirePaymentDetailCard extends mixin {
    @InjectProperty()
    private declare readonly client: WirePaymentDetailClient;

    @property({ type: String })
    public cardState = CardStates.ReadOnly;

    @property({ type: Boolean })
    public canUserEditWire = false;

    @property({ type: Boolean })
    public isFormInEditMode = false;

    @property({ type: Object })
    public downloadFilter!: object;

    @property({ type: String })
    public downloadPage!: string;

    @property({ type: Boolean })
    public hasConfirmationButton = false;

    @property({ type: Boolean })
    public hasReviewButton = false;

    @property({ type: Boolean })
    public isReviewButtonDisabled = false;

    @property({ type: Boolean })
    public isSaving = false;

    @property({ type: Object })
    public returnReport!: object;

    @property({ type: Number })
    public returnReportId!: number;

    @property({ type: String })
    public returnReportName!: string;

    @property({ type: Object })
    public statusList!: object;

    @state()
    @property({ type: Object })
    public wire!: WireDto;

    @state()
    @property({ type: Object })
    private wireRecord!: FdlRecord<WireDto>;

    public wireDtoDefinitions: FdlFieldDefinitions<WireDto> = WireFieldDefinitions;

    @state()
    @property({ type: Object })
    private beneficiaryRecord!: FdlRecord<BeneficiaryDto>;

    public beneficiaryDtoDefinitions: FdlFieldDefinitions<BeneficiaryDto> =
        BeneficiaryFieldDefinitions;

    @state()
    @property({ type: Object })
    private bankRecord!: FdlRecord<BankDto>;

    async firstUpdated() {
        this.wireRecord = new FdlRecord(this.wireDtoDefinitions, this.wire);
        if (this.wireRecord) {
            this.beneficiaryRecord = new FdlRecord<BeneficiaryDto>(
                this.beneficiaryDtoDefinitions,
                this.wireRecord.getField('beneficiary')
            );
            if (this.beneficiaryRecord) {
                this.bankRecord = new FdlRecord<BankDto>(
                    createBankFieldDefinitions('Bank Id'),
                    this.beneficiaryRecord.getField('bank')
                );
            }
            this.wireRecord.setField('isWireEditable', false);
            this.cardState = await this.initializeCardState();
            this.createWireRecordListener();
        }
    }

    createWireRecordListener() {
        this.listenTo(this.wireRecord, 'change', ({ detail }: any) => {
            const { field } = detail;
            if (field === 'status') {
                const statusValue = this.wireRecord.getField('status');
                if (statusValue.toLowerCase() === 'transmitted')
                    this.wireRecord.values.statusId = 4;
                if (statusValue.toLowerCase() === 'posted') this.wireRecord.values.statusId = 6;
            }
            this.requestUpdate();
        });
    }

    async initializeCardState() {
        const hasFXEditEntitlement = await this.client.hasEntitlement('Edit FX Wire Payment');
        if (
            this.wire.status === 'Transmitted' &&
            this.wire.externallyProcessed &&
            hasFXEditEntitlement
        ) {
            return CardStates.CanEdit;
        }
        return CardStates.ReadOnly;
    }

    async updateWire() {
        this.isSaving = true;
        try {
            await this.client.UpdateExternallyProcessedFXWire(this.wireRecord.values);
            this.cardState = CardStates.IsSaved;
            this.wireRecord.setField('isWireEditable', false);
            this.alert = {
                ...this.alert,
                type: 'success',
                message: 'Wire Successfully Updated',
                visible: true,
            };
            this.dispatchEvent(new CustomEvent('wire-updated', { detail: this.wireRecord.values }));
        } catch (e) {
            this.alert = {
                ...this.alert,
                visible: true,
                type: 'error',
                message:
                    e instanceof Error ? e.message : 'Could not update wire. Please try again.',
            };
        } finally {
            this.isSaving = false;
        }
    }

    clickedEdit() {
        this.wireRecord.setField('isWireEditable', true);
        this.cardState = CardStates.IsEditingCanSubmit;
    }

    clickedReview() {
        this.wireRecord.setField('isWireEditable', false);
        this.cardState = CardStates.WasEditedIsUnsaved;
    }

    renderWireDetail() {
        return html`<div class="details-body omega-flex-form">
            <div class="form-column">
                <h1>Payment Information</h1>
                <omega-field field="transactionId" .record=${this.wireRecord}></omega-field>
                <omega-field
                    field="confirmationNumber"
                    .record=${this.wireRecord}
                    label="OMAD"
                ></omega-field>
                <omega-field field="status" .record=${this.wireRecord}></omega-field>
                <omega-field field="wireCompany" .record=${this.wireRecord}></omega-field>
                <omega-field field="debitAccount" .record=${this.wireRecord}></omega-field>
                <omega-field field="amount" .record=${this.wireRecord}></omega-field>
                <omega-field field="frequency" .record=${this.wireRecord}></omega-field>
                <omega-field field="destinationCurrency" .record=${this.wireRecord}></omega-field>
                <omega-field field="purpose" .record=${this.wireRecord}></omega-field>
                <omega-field field="additionalInformation" .record=${this.wireRecord}></omega-field>
                <omega-field field="referenceBeneficiary" .record=${this.wireRecord}></omega-field>
                <omega-field field="audit" .record=${this.wireRecord}></omega-field>
            </div>
        </div>`;
    }

    renderIntermediaryBank(bank: FdlRecord<BankDto>) {
        return html` <div class="details-body omega-flex-form">
            <div class="form-column">
                <omega-field field="bankId" .record=${bank}></omega-field>
                <omega-field field="name" .record=${bank}></omega-field>
                <omega-field field="countryCode" .record=${bank}></omega-field>
                <omega-field field="notes" .record=${bank}></omega-field>
            </div>
        </div>`;
    }

    renderIntermediaryBankList() {
        const bankArray: Array<BankDto> = this.beneficiaryRecord.getField('intermediaryBanks');
        if (!bankArray) return html``;
        return html`<style>
                .intermediary-bank h1 {
                    margin: 10px 0 0 20px;
                    font-weight: 600;
                }
                .omega-flex-form omega-field {
                    margin: 5px 10px;
                }
            </style>
            <div class="intermediary-bank">
                <h1>Intermediary Bank Information</h1>
                ${bankArray.map(bank =>
                    this.renderIntermediaryBank(
                        new FdlRecord<BankDto>(createBankFieldDefinitions('Routing Number'), bank)
                    )
                )}
            </div>`;
    }

    renderEditButtons() {
        switch (this.cardState) {
            case CardStates.CanEdit: {
                return html`<omega-button type="primary" @click="${() => this.clickedEdit()}"
                    >Edit</omega-button
                >`;
            }
            case CardStates.IsEditingCanSubmit: {
                return html`<omega-button
                        @click=${() => {
                            this.wireRecord.reset();
                            this.cardState = CardStates.CanEdit;
                        }}
                        >Cancel</omega-button
                    ><omega-button
                        type="primary"
                        .disabled=${this.wireRecord.errorCount() > 0}
                        @click="${() => this.clickedReview()}"
                        >Review</omega-button
                    >`;
            }
            case CardStates.WasEditedIsUnsaved: {
                return html`<omega-button type="primary" @click="${() => this.clickedEdit()}"
                        >Edit</omega-button
                    >
                    <omega-button type="primary" @click="${() => this.updateWire()}"
                        >Confirm</omega-button
                    >`;
            }
            case CardStates.IsSaved: {
                return nothing;
            }
            default: {
                return nothing;
            }
        }
    }

    renderBlockingLoader() {
        if (this.isSaving) return html`<blocking-loader></blocking-loader>`;
        return nothing;
    }

    render() {
        if (!this.wireRecord) return nothing;
        return html`<div class="form-container wire-detail-card">
            ${this.renderAlert()} ${this.renderWireDetail()}${this.renderBlockingLoader()}
            ${renderBeneficiary(this.beneficiaryRecord, this.wireRecord, this.bankRecord)}
            ${this.renderIntermediaryBankList()} ${renderExchangeRate(this.wireRecord)}
            ${this.renderEditButtons()}
        </div>`;
    }

    // eslint-disable-next-line @treasury/style-includes-host-display
    static get styles() {
        return [
            css`
                h1 {
                    margin: 0 0 12px 10px;
                    font-weight: 600;
                }
                .details-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid var(--omega-secondary-lighten-300);
                    padding: 0 10px;
                }
                .details-footer {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-top: 1px solid var(--omega-secondary-lighten-300);
                    padding: 0 10px;
                }
                .omega-flex-form {
                    display: flex;
                }
                .form-column {
                    flex: 1 1 0;
                    margin: 10px;
                }
                .form-column:not(:last-of-type) {
                    padding-right: 10px;
                    border-right: 1px solid #d3d6d9;
                }
                .omega-flex-form omega-field {
                    --omega-field-control-width: 120px;
                    margin: 5px 10px;
                }
            `,
        ];
    }
}
