/* eslint-disable no-use-before-define */
/* eslint-disable prefer-destructuring */
import { DiContainer } from '@jack-henry/frontend-utils/di';
import { Record, Recordset } from '@treasury/FDL';
import { TmHttpClient, TmHttpResponseType } from '@treasury/core/http';
import { NavigationService } from '@treasury/core/navigation';
import {
    UserActivityTypes,
    UsersService
} from '@treasury/domain/backoffice/services/users/users-service.ts';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { TmApiError } from '@treasury/domain/shared';
import { downloadBlob } from '@treasury/domain/utilities/file-handling.js';
import titleToCamelCase from '@treasury/domain/utilities/title-to-camel-case';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-dialog';
import '@treasury/omega/layouts/omega-report';
import { createUniqueId, printNode } from '@treasury/utils';
import { LitElement, html, nothing, render } from 'lit';
import { mix } from 'mixwith';

import '../../components/blocking-loader.js';
import BackOfficeAlertMixin from '../../mix-ins/back-office-alert-mixin.js';
import ReportClient from '../clients/report-client.js';
import { handleApiError } from '../data/report-error-handling.ts';
import ReportFactory2 from '../data/report-factory-2.js';
import ReportFactory from '../data/report-factory.js';
import { wireHistoryColumns, wireHistoryFields } from '../data/report-history.js';
import '../parts/report-history-dialog.js';
import '../parts/report-summary.js';
import '../parts/wire-payment-detail-card';
import { reportContainerStyles } from './styles.js';

class ReportContainer extends mix(LitElement).with(BackOfficeAlertMixin, ListeningElementMixin) {
    static get properties() {
        return {
            reportType: String,
            reportId: String,
            reportMetaData: Object,
            reportRecordset: Object,
            reportSummaryRecord: Object,
            actions: Object,
            alert: Object,
            columns: Array,
            filters: Array,
            fields: Object,
            subTitle: String,
            client: Object,
            loading: Boolean,
            saving: Boolean,
            downloading: Boolean,
            printing: Boolean,
            showHistoryDialog: Boolean,
            showWireDetailDialog: Boolean,
            showComment: Boolean,
            selectedWire: Object,
            comment: String,
        };
    }

    constructor() {
        super();
        this.client = new ReportClient();
        this.actions = {
            routeToDetail: async record => {
                switch(this.reportType) {
                    case 'wireTransferPayment':
                    case 'wireTransferDliPayment':
                        await this.routeToWireDetail(record);
                        break;

                    case 'achPayment':
                        await this.routeToAchDetail(record);

                }
            },

            showWireHistory: async record => {
                const id = record.getField('id');
                this.historyRecordset = null;
                this.historyRecordset = new Recordset(wireHistoryFields, async () =>
                    this.client.getWireHistory(id)
                );
                this.historyColumns = wireHistoryColumns;
                this.historyTitle = 'Wire History';
                this.showHistoryDialog = true;
                await this.historyRecordset.requestUpdate();
            },

            showComment: async record => {
                this.comment = record.getField('returnReasonComment');
                this.showCommentDialog = true;
            },

            downloadAttachment: async record => {
                const id = record.getField('attachmentId');
                await this.client.downloadAttachment(id);
            },
        };
        this.title = '';
        this.subTitle = '';
        this.filters = [];
        this.fields = {};
        this.columns = [];
        this.options = ['save', 'print', 'description'];
        this.downloadOptions = [];
        this.rowsPerPage = 25;
    }

    get pageTitle() {
        return this.reportMetaData?.name;
    }

    async firstUpdated() {
        const urlPath = window.location.pathname.split('/');
        this.reportType = urlPath[2].split('-').pop();
        this.reportId = urlPath[3];
        this.loading = true;
        try {
            const usersService = (await DiContainer.getInstance()).get(UsersService);
            // NOTE: this isn't really sustainable to hit the activity endpoint for every report.
            if (this.reportType === 'achNotificationOfChange') {
                usersService.saveUserActivity(
                    UserActivityTypes.Ach,
                    'Navigated to ACH Notification of Change Report'
                );
            } else if (this.reportType === 'achReturn') {
                usersService.saveUserActivity(
                    UserActivityTypes.Ach,
                    'Navigated to ACH Return Report'
                );
            }
            this.downloadTypeId = await this.client.getDownloadType(this.reportType);
            this.downloadOptions = await this.client.getDownloadOptions(this.downloadTypeId);
            if (this.downloadOptions.length) this.options = [...this.options, 'download'];
            const metaData = await this.client.getReportMetaData(this.reportId);
            const columns = await this.client.getReportColumns(this.reportType);
            const [filters, customFilters] = await this.client.getReportFilters(
                this.reportType,
                this.reportId
            );
            /**
             * temporary test new filter model mapping. 2 will eventually entirely replace 1
             */
            const useRedesignFilterModel = await this.client.useRedesignFilterModel(
                this.reportType
            );
            if (useRedesignFilterModel) {
                this.reportFactory = new ReportFactory2(
                    metaData,
                    columns,
                    filters,
                    customFilters,
                    this
                );
            } else {
                this.reportFactory = new ReportFactory(metaData, columns, filters, customFilters);
            }
            this.columns = this.reportFactory.reportColumns;
            this.fields = this.reportFactory.reportFields;
            this.filters = this.reportFactory.reportFilters;
            this.detailFunction = this.reportFactory.reportDetailFunction;
            this.reportMetaData = this.reportFactory.metaData;
            this.reportRecordset = new Recordset(this.fields, async (...args) =>
                this.client.runReport(
                    ...args,
                    this.reportMetaData,
                    this.reportFactory.columnData,
                    this.reportFactory.customFilterData
                )
            );
            this.reportRecordset.setInitialPageSize(this.rowsPerPage);
            this.listenTo(this.reportRecordset, 'error', e => this.handleRecordsetError(e));
            this.listenTo(this.reportRecordset, 'page-changed', () => {
                if (this.reportRecordset.summary)
                    this.reportSummaryRecord = new Record(
                        this.reportFactory.summaryFields(this.reportRecordset.summary),
                        this.reportFactory.summaryValues(this.reportRecordset.summary)
                    );
            });
            this.addEventListener('loadingCheckImage', ({ detail }) => {
                this.downloading = detail;
            });
        } catch (e) {
            console.log(e);
            const { message } = coerceError(e);
            this.alert = {
                ...this.alert,
                visible: true,
                title: null,
                message,
                type: 'error',
            };
        } finally {
            this.loading = false;
        }
    }

    clearSubtitle() {
        this.subTitle = '';
    }

    handleRecordsetError({ detail }) {
        const { error } = detail;
        this.alert = {
            ...this.alert,
            visible: true,
            type: 'error',
            message: error.message,
            title: null,
            time: error.time,
            code: error.code,
        };
    }

    async saveReport({ detail }) {
        this.saving = true;
        try {
            const response = await this.client.saveReport(
                detail,
                this.columns,
                this.reportRecordset,
                this.reportMetaData
            );
            if (response.success) {
                const usersService = (await DiContainer.getInstance()).get(UsersService);
                this.alert = {
                    ...this.alert,
                    visible: true,
                    code: null,
                    time: null,
                    title: null,
                    type: 'success',
                    message: 'Report saved successfully!',
                };
                // Little bit of a hack, but I don't really want to add another prop to omega-report
                this.shadowRoot
                    .querySelector('#report')
                    .shadowRoot.querySelector('#report-download-bar').saveReportModal = false;
                usersService.saveUserActivity(
                    UserActivityTypes.Ach,
                    `Saved ${this.reportMetaData.name} ${detail.name}`
                );
            } else if ('responseDetailCollection' in response) {
                const errorAlert = handleApiError(
                    response.responseDetailCollection,
                    'Error Saving Custom Report'
                );
                this.alert = {
                    ...this.alert,
                    ...errorAlert,
                };
            } else {
                this.alert = {
                    ...this.alert,
                    visible: true,
                    type: 'error',
                    title: null,
                    message: 'Error saving custom report',
                    code: null,
                    time: null,
                };
            }
        } catch (e) {
            const { message, code, time } = coerceError(e);
            this.alert = {
                ...this.alert,
                visible: true,
                type: 'error',
                title: null,
                message,
                code,
                time,
            };
        } finally {
            this.saving = false;
        }
    }

    async printReport() {
        this.printing = true;
        const usersService = (await DiContainer.getInstance()).get(UsersService);
        const printRecordset = new Recordset(this.fields, () => this.reportRecordset.getData());
        await printRecordset.requestUpdate();
        printRecordset.setInitialPageSize(printRecordset.filteredCount);
        this.printing = false;
        if (this.reportType === 'achNotificationOfChange') {
            usersService.saveUserActivity(
                UserActivityTypes.Ach,
                'Printed ACH Notification of Change Report'
            );
        } else if (this.reportType === 'achReturn') {
            usersService.saveUserActivity(UserActivityTypes.Ach, 'Printed ACH Return Report');
        }
        const printableDiv = document.createElement('div');
        const printableTable = html`${this.renderSummary()}<omega-table
                .recordset=${printRecordset}
                .columnDefinitions=${this.columns}
            ></omega-table>`;
        render(printableTable, printableDiv);
        return printNode(this.pageTitle, printableDiv);
    }

    async downloadReport({ detail }) {
        this.downloading = true;
        /**
         * We do this find because the download-bar only expects and array of strings but
         * the endpoint expects the download type id on the request
         */
        const downloadTypeId = this.client.downloadOptionsResponse.find(
            o => o.name === detail.type
        ).downloadType;
        try {
            await this.client.downloadReport(
                detail,
                downloadTypeId,
                this.reportRecordset,
                this.reportMetaData,
                this.reportFactory.columnData
            );
        } catch (e) {
            const { message, code } = coerceError(e);
            this.alert = {
                ...this.alert,
                visible: true,
                type: 'error',
                title: null,
                message,
                code,
            };
        } finally {
            this.downloading = false;
        }
    }

    clearStateKey() {
        this.stateKey = null;
    }

    async handleDetailAction(detailColumnConfigurationActions, record, downloadType) {
        const selectedAction = detailColumnConfigurationActions.find(
            action => action.message === downloadType
        );
        const selectedActionRequestParameters = JSON.parse(selectedAction.action.parameters);
        const { request } = selectedActionRequestParameters;
        const replacedKey = Object.keys(request.Filter).find(key => {
            if (request.Filter[key] === '[PrimaryKeyPlaceHolder]') {
                return key;
            }
            return undefined;
        });
        const body = {
            ...selectedActionRequestParameters.request,
            Filter: {
                ...selectedActionRequestParameters.request.Filter,
                [replacedKey]: record.getField(
                    titleToCamelCase(selectedActionRequestParameters.PrimaryField)
                ),
            },
        };
        this.loading = true;
        try {
            const http = await TmHttpClient.getInstance();
            const response = await http.request(selectedAction.actionsUrl.replace(/^\/+/, ''), {
                method: 'POST',
                responseType: TmHttpResponseType.Raw,
                body: { ...body },
            });
            const fileName = response.headers
                .get('content-disposition')
                .split('=')[1]
                .trim()
                .replace(/['"]+/g, '');
            const contentType = response.headers.get('content-type');
            const buffer = await response.arrayBuffer();
            const blob = new Blob([buffer], { type: contentType });
            downloadBlob(blob, fileName);
        } catch (e) {
            const { message, code } = coerceError(e);
            this.alert = {
                ...this.alert,
                visible: true,
                type: 'error',
                title: null,
                message,
                code,
            };
        } finally {
            this.loading = false;
        }
    }

    async routeToWireDetail(record) {
        this.loading = true;
        const navService = (await DiContainer.getInstance()).get(NavigationService);
        const id = record.getField('id');

        const hasWireDetailCardEntitlement = await this.client.hasEntitlement(
            'Feature.Reports.WireDetailCard'
        );
        const hasNewWireDetailViewEntitlement = await FeatureFlagService.isEnabled(
            Feature.NewBoWireDetail
        );
        if (hasWireDetailCardEntitlement || hasNewWireDetailViewEntitlement) {
            try {
                this.selectedWire = await this.client.getWireDetails(id);
            } catch (e) {
                this.loading = false;
                const { message, code } = coerceError(e);
                this.alert = {
                    ...this.alert,
                    visible: true,
                    type: 'error',
                    title: null,
                    message,
                    code,
                };
            }
            if (hasNewWireDetailViewEntitlement) {
                navService.navigate('reports.wire-payment-detail', {
                    wire: this.selectedWire,
                    reportClient: this.client,
                });
            } else if (hasWireDetailCardEntitlement) {
                this.showWireDetailDialog = true;
                this.loading= false;
            }
        } else {
            navService.navigate('reports.wire-details', {
                reportId: this.reportId,
                reportType: this.reportType,
                id,
            });
        }
    }

    async routeToAchDetail(record) {
        this.loading = true;
        const navService = (await DiContainer.getInstance()).get(NavigationService);
        const guid = record.getField('achPaymentUniqueId');
        const paymentTypeId = record.getField('achPaymentTypeId');

        navService.navigate('reports.ach-payment-detail', {
            guid,
            paymentTypeId,
            reportClient: this.client,
        });
    }

    async downloadWireDetail({ detail }) {
        this.downloading = true;
        setTimeout(() => {
            this.shadowRoot.querySelector('blocking-loader').style.zIndex = 9999;
        }, 0);
        await this.client.downloadWireDetails(this.selectedWire.id, detail.downloadType);
        this.shadowRoot.querySelector('blocking-loader').style.zIndex = 99;
        this.downloading = false;
    }

    printDetail() {
        this.printing = true;
        const printableDiv = document.createElement('div');
        const printableCard = html`<wire-payment-detail-card
            .wire=${this.selectedWire}
        ></wire-payment-detail-card>`;
        render(printableCard, printableDiv);
        this.printing = false;
        return printNode('Wire Detail', printableDiv);
    }

    renderBlockingLoader() {
        if (this.loading || this.saving || this.downloading || this.printing)
            return html`<blocking-loader></blocking-loader>`;
        return nothing;
    }

    renderSummary() {
        if (!this.reportSummaryRecord) return nothing;
        return html`<report-summary
            .summaryRecord=${this.reportSummaryRecord}
            .summary=${this.reportRecordset.summary}
        ></report-summary>`;
    }

    renderHistoryDialog() {
        if (!this.showHistoryDialog) return nothing;
        return html`<report-history-dialog
            .open=${this.showHistoryDialog}
            .recordset=${this.historyRecordset}
            .columns=${this.historyColumns}
            .title=${this.historyTitle}
            @close=${() => {
                this.showHistoryDialog = false;
            }}
        ></report-history-dialog>`;
    }

    renderCommentDialog() {
        if (!this.showCommentDialog) return nothing;
        return html`<omega-dialog
            dialogTitle="Return Reason Comment"
            .open=${this.showCommentDialog}
            @close=${() => {
                this.comment = null;
                this.showCommentDialog = false;
            }}
        >
            <div class="comment-content" slot="content">${this.comment}</div>
        </omega-dialog>`;
    }

    renderWireDetailDialog() {
        if (!this.showWireDetailDialog) return nothing;
        return html`<omega-dialog
            dialogTitle="Wire Detail"
            .open=${this.showWireDetailDialog}
            .headerActions=${['download', 'print']}
            .downloadOptions=${['CSV', 'PDF']}
            @print=${this.printDetail}
            @download=${this.downloadWireDetail}
            @close=${() => {
                this.showWireDetailDialog = false;
            }}
        >
            <wire-payment-detail-card
                slot="content"
                .wire=${this.selectedWire}
                @wire-updated=${({ detail }) => {
                    const id = detail.id;
                    const foundRecord = this.reportRecordset.allRecords.find(
                        r => r.getField('id') === id
                    );
                    foundRecord.setField('status', detail.status);
                }}
            ></wire-payment-detail-card>
        </omega-dialog>`;
    }

    renderReport() {
        if (this.loading) return nothing;
        if (this.reportRecordset && !this.loading) {
            return html` <omega-report
                id="report"
                .autostart=${this.reportMetaData.isCustomReport}
                .title=${this.pageTitle}
                .subTitle=${this.subTitle}
                .description=${this.reportMetaData.description}
                .actions=${this.actions}
                .recordset=${this.reportRecordset}
                .filters=${this.filters}
                .persistState=${true}
                .columns=${this.columns}
                .detailFunction=${this.detailFunction
                    ? (record, close) => this.detailFunction(record, close)
                    : null}
                .options=${this.options}
                .downloadOptions=${this.downloadOptions}
                .rowsPerPage=${this.rowsPerPage}
                .paginationOptions=${[10, 25, 50, 100, 500]}
                .printFunction=${() => this.printReport()}
                @resetFilter=${this.clearSubtitle}
                @reportSave=${this.saveReport}
                @reportDownload=${this.downloadReport}
                @search=${this.clearStateKey}
            >
                <div slot="above-table">${this.renderSummary()}</div>
            </omega-report>`;
        }
        return nothing;
    }

    render() {
        return [
            this.renderBlockingLoader(),
            this.renderAlert(),
            this.renderReport(),
            this.renderHistoryDialog(),
            this.renderCommentDialog(),
            this.renderWireDetailDialog(),
        ];
    }

    // eslint-disable-next-line @treasury/style-includes-host-display
    static get styles() {
        return [reportContainerStyles];
    }
}

customElements.define('report-container', ReportContainer);

/**
 * Coerce an error response to a shape usable by `<omega-alert>`.
 * @param { Error | TmError | object} e
 */
function coerceError(e) {
    const message = e instanceof Error ? e.message : 'An unknown error occurred.';
    const code = e instanceof TmApiError ? e.errorCode : undefined;
    const time = e instanceof TmApiError ? e.timestamp : undefined;

    return {
        message,
        code,
        time,
    };
}
