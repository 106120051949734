angular.module('backOffice').controller('ManageUserStatusController', manageUserStatusController);

manageUserStatusController.$inject = [
    '$scope',
    'usersService',
    'userDetails',
    '$modalInstance',
    'toaster',
];

function manageUserStatusController($scope, usersService, userDetails, $modalInstance, toaster) {
    $scope.selectedUserStatus = userDetails;

    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    $scope.save = function () {
        updateUserStatus();
    };

    $scope.setForm = function (form) {
        $scope.form = form;
    };

    function updateUserStatus() {
        const newStatus = $scope.selectedUserStatus.status === 'Inactive' ? 'Active' : 'Inactive';
        usersService.changeUserStatus($scope.selectedUserStatus, newStatus).then(response => {
            toaster.save('User Status');
            $scope.selectedUserStatus.status = newStatus;
            $modalInstance.close($scope.selectedUserStatus);
        });
    }
}
