/* eslint-disable import/extensions */
import { TmHttpClient } from '@treasury/core/http';

export class CompanyProductFeatures {
    static async getFeatures(companyID) {
        return (await TmHttpClient.getInstance()).request(
            `companies/${companyID}/productFeatures`,
            {
                method: 'GET',
            }
        );
    }

    static async hasPositivePay(companyID) {
        const features = await this.getFeatures(companyID);
        return features.some(feature => feature.type === 'ArpProductFeature');
    }
}
