import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('challengeGroupsService', challengeGroupsService);

challengeGroupsService.$inject = ['$resource'];

function challengeGroupsService($resource) {
    const resourceUrl = `${getBackOfficeAPIPath()}challenge-groups`;
    return {
        getGroups,
        getGroupDetail,
        getGroupCompanies,
        saveGroup,
        updateGroup,
        deleteGroup,
        getClonedGroupDetail,
    };

    function getGroups() {
        return $resource(resourceUrl).query().$promise;
    }

    function getGroupDetail(id) {
        return $resource(`${resourceUrl}/:id`, { id }).get().$promise;
    }

    function getGroupCompanies(id) {
        return $resource(`${resourceUrl}/:id/companies`, { id }).query().$promise;
    }

    function saveGroup(group) {
        return $resource(
            resourceUrl,
            {},
            {
                post: {
                    method: 'POST',
                    transformResponse(data, headers, status) {
                        if (status === 200) return { id: data };
                        return JSON.parse(data);
                    },
                },
            }
        ).post(group).$promise;
    }

    function updateGroup(group) {
        return $resource(
            `${resourceUrl}/:id`,
            { id: group.id },
            { update: { method: 'PUT' } }
        ).update(group).$promise;
    }

    function deleteGroup(id) {
        return $resource(`${resourceUrl}/:id`, { id }).delete().$promise;
    }

    function getClonedGroupDetail(id) {
        return $resource(`${resourceUrl}/:id?type=:type`, { id, type: 'clone' }).get().$promise;
    }
}
