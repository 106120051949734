angular
    .module('backOffice')
    .controller('CustomizeWausauStatementsController', customizeWausauStatementsController);

customizeWausauStatementsController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'modalService',
    'companiesService',
    'configurationService',
    'toaster',
];

function customizeWausauStatementsController(
    $scope,
    $state,
    $stateParams,
    modalService,
    companiesService,
    configurationService,
    toaster
) {
    const CompanyId = $stateParams.id;

    $scope.goBack = goBack;
    $scope.save = save;
    $scope.setForm = setForm;
    $scope.pageTitle = pageTitle;
    $scope.requiredEntitlements = requiredEntitlements;

    $scope.productFeature = {};

    function pageTitle() {
        if ($scope.productFeature.id === 0) {
            return 'Add Wausau eStatements';
        }
        return $scope.hasEntitlement('Edit Product Feature')
            ? 'Edit Wausau eStatements'
            : 'View Wausau eStatements';
    }

    function requiredEntitlements() {
        return $scope.productFeature.id === 0 ? 'Add Product Feature' : 'Edit Product Feature';
    }

    function setForm(form) {
        $scope.form = form;
    }

    function goBack() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel the changes you have made?',
                submit() {
                    cancelModal.dismiss();
                    $state.go('company', { id: CompanyId });
                },
            };
            var cancelModal = modalService.showModal({}, modalOptions);
        } else {
            $state.go('company', { id: CompanyId });
        }
    }

    function save() {
        if ($scope.productFeature.id !== 0) {
            companiesService.updateProductFeature(CompanyId, $scope.productFeature).then(() => {
                toaster.save('Wausau eStatements Configuration');
                $scope.form.$setPristine();
            });
        } else if ($scope.productFeature.id === 0) {
            companiesService.saveProductFeature(CompanyId, $scope.productFeature).then(response => {
                $scope.productFeature.id = response.id;
                toaster.save('Wausau eStatements Configuration');
                $scope.form.$setPristine();
            });
        }
    }

    function init() {
        if ($stateParams.productFeatureId === '') {
            $scope.productFeature.id = 0;
        } else if (
            $stateParams.productFeatureId !== undefined ||
            $stateParams.productFeatureId !== null
        ) {
            $scope.productFeature.id = $stateParams.productFeatureId;
        }

        loadCompany();
    }

    function loadCompany() {
        companiesService.getCompanyName(CompanyId).then(response => {
            $scope.company = response;
            loadProductFeature();
        });
    }

    function loadProductFeature() {
        if ($scope.productFeature.id !== 0) {
            companiesService
                .getProductPermissions(CompanyId, $scope.productFeature.id)
                .then(response => {
                    $scope.productFeature = response;
                });
        } else {
            configurationService
                .getProductFeatureDetailsByName('WausauStatements')
                .then(response => {
                    $scope.productFeature = response;
                    $scope.productFeature.id = 0;
                });
        }
    }

    init();
}
