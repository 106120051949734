import { TmHttpClient } from '../../http';
import { LoginResponseDto } from '../xperience.service';

export default class XperienceRequests {
    static async samlLogin(samlToken: string): Promise<LoginResponseDto> {
        return (await TmHttpClient.getInstance()).request(`Xperience/SamlLogin`, {
            method: 'POST',
            body: { samlToken },
        });
    }
}
