import { Recordset } from '@treasury/FDL';
import { printNode } from '@treasury/utils';
import { html, LitElement, nothing, render } from 'lit';

class WireUploadWireFileDialog extends LitElement {
    static get properties() {
        return {
            wireFile: Object,
            wireFields: Object,
            wireRecordset: Object,
            wireColumns: Array,
            open: Boolean,
            dialogTitle: String,
        };
    }

    constructor() {
        super();
        this.open = false;
        this.actions = ['print'];
    }

    async printDialog() {
        this.printing = true;
        const printRecordset = new Recordset(this.wireFields, () => this.wireRecordset.getData());
        await printRecordset.requestUpdate();
        printRecordset.setInitialPageSize(printRecordset.filteredCount);
        this.printing = false;
        const printableDiv = document.createElement('div');
        const printableTable = html`<omega-table
            .recordset=${printRecordset}
            .columnDefinitions=${this.wireColumns}
        ></omega-table>`;
        render(printableTable, printableDiv);
        return printNode(this.pageTitle, printableDiv);
    }

    renderTable() {
        if (!this.wireRecordset) return nothing;
        return html`<omega-table
            slot="content"
            .recordset=${this.wireRecordset}
            .columnDefinitions=${this.wireColumns}
        >
        </omega-table>`;
    }

    render() {
        if (!this.dialogTitle || !this.wireFile) return nothing;
        return html`
            <omega-dialog
                .open=${this.open}
                .headerActions=${this.actions}
                class="wire-file-detail-dialog"
                @close=${() => {
                    this.dispatchEvent(new CustomEvent('close'));
                }}
                @print=${this.printDialog}
                .dialogTitle=${this.dialogTitle}
            >
                ${this.renderTable()}
            </omega-dialog>
        `;
    }
}

customElements.define('wire-upload-wire-file-dialog', WireUploadWireFileDialog);
export default WireUploadWireFileDialog;
