angular
    .module('backOffice')
    .controller('ChallengeGroupDetailController', challengeGroupDetailController);

challengeGroupDetailController.$inject = [
    '$scope',
    '$modal',
    'challengeGroupsService',
    '$stateParams',
    '$state',
    'modalService',
    'uiMessages',
];

function challengeGroupDetailController(
    $scope,
    $modal,
    challengeGroupsService,
    $stateParams,
    $state,
    modalService,
    uiMessages
) {
    $scope.id = $stateParams.id;
    $scope.type = $stateParams.type;
    $scope.selectAllChallengeuserToTrue = false;
    $scope.selectAllChallengeuserToFalse = false;
    $scope.goToGroupList = goToGroupList;
    $scope.save = save;
    $scope.deleteGroup = deleteGroup;
    $scope.reset = reset;
    $scope.cancel = cancel;
    $scope.isNull = isNull;
    $scope.selectAllToChallengeUser = selectAllToChallengeUser;
    $scope.selectAssociatedCompanies = selectAssociatedCompanies;
    $scope.setForm = setForm;
    $scope.cloneGroup = cloneGroup;
    $scope.edit = edit;
    $scope.revertToDefaults = revertToDefaults;
    $scope.allowedEntitlements =
        $scope.id > 0 ? 'Edit Challenge Point Profile' : 'Add Challenge Point Profile';

    const challengeAllText = 'Challenge For All Amounts';

    function loadGroupDetail(id) {
        challengeGroupsService.getGroupDetail(id).then(response => {
            $scope.group = response;
            $scope.copyGroup = angular.copy($scope.group);
            if ($scope.type === 'edit') {
                if (id == 0) {
                    $scope.group.name = '';
                }
            }
        });
    }

    function edit() {
        $scope.type = 'edit';
        $scope.form.$setPristine();
    }

    function init() {
        $scope.thresholdOptions = [challengeAllText, 'Enter Threshold Amount'];
        if ($scope.type !== 'clone') {
            loadGroupDetail($scope.id);
        } else {
            loadClonedObjectDetail($scope.id);
        }
    }

    function loadClonedObjectDetail(id) {
        challengeGroupsService.getClonedGroupDetail(id).then(response => {
            $scope.group = response;
            delete $scope.group.id;
            $scope.group.name = '';
            $scope.group.description = '';
            $scope.copyGroup = angular.copy($scope.group);
        });
    }

    function revertToDefaults() {
        const modalOptions = {
            bodyText:
                'Reverting to Default settings will change all parameters on this page to the system defaults. Please select Yes to confirm this change or No to keep the current settings.',
            submit(result) {
                save(true);
                $modalInstance.close(result);
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    function goToGroupList() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                secondaryButtonClass: 'btn-link',
                headerText: uiMessages.ConfirmLeavePageHeader,
                bodyText: uiMessages.ConfirmLeavePage,
                submit(result) {
                    $state.go('authentication-profiles');
                    $modalInstance.close(result);
                },
            };
            var $modalInstance = modalService.showModal({}, modalOptions);
        } else {
            $state.go('authentication-profiles');
        }
    }

    function cloneGroup() {
        $state.go('challenge-group-detail', { id: $scope.group.id, type: 'clone' });
    }

    function reset() {
        const modalOptions = {
            closeButtonText: 'Cancel',
            actionButtonText: 'Reset',
            secondaryButtonClass: 'btn-link',
            headerText: 'Reset',
            bodyText: 'Are you sure you want to reset this page?',
            submit(result) {
                if ($scope.id == 0) {
                    init();
                } else {
                    $scope.group = angular.copy($scope.copyGroup);
                }
                $modalInstance.close(result);
                $scope.form.$setPristine();
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    function save(revertToDefault) {
        $scope.group.actionGroup = $scope.group.actionGroup.map(actionGroup => {
            actionGroup.actions = actionGroup.actions.map(action => {
                if (isNull(action.thresholdAmount)) {
                    action.thresholdType = challengeAllText;
                } else if (action.thresholdType === challengeAllText) {
                    action.thresholdAmount = null;
                }
                if (revertToDefault) {
                    action.thresholdType = challengeAllText;
                    action.thresholdAmount = null;
                    action.challengeUser = true;
                }
                return action;
            });
            return actionGroup;
        });

        if ($scope.group.id === 0 || $scope.type === 'clone') {
            challengeGroupsService.saveGroup($scope.group).then(response => {
                $scope.group.id = response.id;
                $scope.type = 'view';
                $scope.form.$dirty = false;
            });
        } else {
            challengeGroupsService.updateGroup($scope.group).then(response => {
                $scope.group = response;
                $scope.type = 'view';
                $scope.form.$dirty = false;
            });
        }
    }

    function isNull(value) {
        return value === null || isNaN(value);
    }

    function setForm(form) {
        $scope.form = form;
    }

    function cancel() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                secondaryButtonClass: 'btn-link',
                headerText: uiMessages.ConfirmLeavePageHeader,
                bodyText: uiMessages.ConfirmLeavePage,
                submit(result) {
                    $state.go('authentication-profiles');
                    $modalInstance.close(result);
                },
            };
            var $modalInstance = modalService.showModal({}, modalOptions);
        } else {
            $state.go('authentication-profiles');
        }
    }

    function selectAllToChallengeUser(selectedType) {
        if (selectedType === 'true') {
            $scope.selectAllChallengeuserToTrue = true;
            $scope.selectAllChallengeuserToFalse = false;
            angular.forEach($scope.group.actionGroup, actionGroup => {
                angular.forEach(actionGroup.actions, action => {
                    action.challengeUser = true;
                });
            });
        } else if (selectedType === 'false') {
            $scope.selectAllChallengeuserToFalse = true;
            $scope.selectAllChallengeuserToTrue = false;
            angular.forEach($scope.group.actionGroup, actionGroup => {
                angular.forEach(actionGroup.actions, action => {
                    action.challengeUser = false;
                });
            });
        }
    }

    $scope.$watch(
        'group',
        () => {
            if ($scope.group && $scope.group.actionGroup) {
                let allYesSelected = true;
                let allNoSelected = true;
                angular.forEach($scope.group.actionGroup, actionGroup => {
                    angular.forEach(actionGroup.actions, action => {
                        if (!action.challengeUser) {
                            allYesSelected = false;
                        }
                    });
                });
                angular.forEach($scope.group.actionGroup, actionGroup => {
                    angular.forEach(actionGroup.actions, action => {
                        if (action.challengeUser) {
                            allNoSelected = false;
                        }
                    });
                });
                $scope.selectAllChallengeuserToTrue = allYesSelected;
                $scope.selectAllChallengeuserToFalse = allNoSelected;
            }
        },
        true
    );

    function selectAssociatedCompanies() {
        $modal.open({
            template: require('../views/associatedCompanies.html'),
            size: 'md',
            controller: 'GroupAssociatedCompaniesController',
            backdrop: 'static',
            resolve: {
                group() {
                    return $scope.group;
                },
            },
        });
    }

    function deleteGroup() {
        challengeGroupsService.getGroupCompanies($scope.group.id).then(response => {
            let headerText;
            let bodyText;
            if (response && response.length > 0) {
                headerText = 'Delete Profile With Associated Companies';
                bodyText = `Warning: ${response.length} ${
                    response.length > 1 ? 'companies are' : 'company is'
                } using this authentication profile. If you delete this profile, ${
                    response.length > 1 ? 'these companies' : 'this company'
                } will be switched to the Default authentication profile. Are you sure you want to Delete this authentication profile?`;
            } else {
                headerText = 'Delete Profile';
                bodyText = 'Are you sure you want to Delete this authentication profile?';
            }

            const modalOptions = {
                closeButtonText: 'Cancel',
                actionButtonText: 'OK',
                headerText,
                bodyText,
                submit(result) {
                    challengeGroupsService.deleteGroup($scope.group.id).then(() => {
                        $state.go('authentication-profiles');
                    });
                    $modalInstance.close(result);
                },
            };
            var $modalInstance = modalService.showModal({}, modalOptions);
        });
    }

    init();
}
