angular.module('backOffice').constant('accountMaskTypes', [
    {
        id: 1,
        label: 'Show Full Account Number',
        title: 'Dont Use Account Masking',
    },
    {
        id: 2,
        label: 'Show Last Four Digits (Example: ****-1234)',
        title: 'Show Last Four Digits',
    },
    {
        id: 3,
        label: 'Show First Two and Last Four Digits (Example: 12**-1234)',
        title: 'Show First Two and Last Four Digits',
    },
]);
