import {
    AchFrequencyModelDto,
    AchPaymentModelDto,
    AchRecipientModelDto,
    AchStateTaxPaymentDto,
    ChildSupportPaymentModelDto,
    ChildSupportRecipientAddendaModelDto,
    ChildSupportRecipientModelDto,
    LookupModelDto,
    ModelBaseDto,
} from '@treasury/api/bo';

export const defaultModelBaseDto: ModelBaseDto = {
    id: 0,
    updatedDate: '',
};

export const defaultAchFrequencyModelDto: AchFrequencyModelDto = {
    ...defaultModelBaseDto,
    noEndDate: false,
    repeatOnDay1: 0,
    repeatOnDay2: 0,
    paymentFrequencyType: '',
    summary: '',
};

export const defaultAchRecipientModelDto: AchRecipientModelDto = {
    id: 0,
    name: '',
    idNumber: '',
    accountNumber: '',
    accountType: '',
    routingNumber: '',
    transactionType: '',
    amount: 0,
    prenote: false,
    hold: false,
    addendas: [] as LookupModelDto[],
};

export const defaultAchPaymentModelDto: AchPaymentModelDto = {
    id: 0,
    achPaymentGuidUniqueId: '',
    recipients: [defaultAchRecipientModelDto],
    frequency: defaultAchFrequencyModelDto,
    parentId: 0,
    batchId: 0,
    offsetAccount: {},
    name: '',
    achCompanyName: '',
    achCompanyId: '',
    secCode: '',
    discretionaryData: '',
    entryDescription: '',
    restricted: false,
    debitAmount: 0,
    creditAmount: 0,
    transactionId: '',
    status: '',
    numberOfApprovalsNeeded: 0,
    achPaymentTypeId: 0,
};

export const defaultAchChildSupportRecipientModelDto: ChildSupportRecipientModelDto = {
    ...defaultAchRecipientModelDto,
    addendas: [] as ChildSupportRecipientAddendaModelDto[],
};

export const defaultAchChildSupportPaymentModelDto: ChildSupportPaymentModelDto &
    AchPaymentModelDto = {
    ...defaultAchPaymentModelDto,
    recipients: [defaultAchChildSupportRecipientModelDto],
    achPaymentTypeId: 4,
};

export const defaultAchStateTaxPaymentModelDto: AchStateTaxPaymentDto & AchPaymentModelDto = {
    ...defaultAchPaymentModelDto,
    achStateTaxPaymentAmounts: [],
    createdDate: '',
    id: 0,
    recipientAccountTypeId: 0,
    payFromAccountId: 0,
    taxPeriodEndDate: '',
    updatedDate: '',
};

// Remove this too
export interface AchInternationalRecipientDto extends AchRecipientModelDto {
    streetAddress: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    iatTransactionType: string;
    optionalAddendaLine1?: string;
    optionalAddendaLine2?: string;
    receivingBankCountry: string;
    receivingBankName: string;
    receivingBankNumber: string;
    receivingBankType: string;
}

export const defaultAchInternationalRecipientModelDto: AchInternationalRecipientDto = {
    ...defaultAchRecipientModelDto,
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    iatTransactionType: '',
    receivingBankCountry: '',
    receivingBankName: '',
    receivingBankNumber: '',
    receivingBankType: '',
};

// Remove this too
export interface AchInternationalPaymentModelDto extends AchPaymentModelDto {
    streetAddress: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    destinationCountryCode: string;
    destinationCurrencyCode: string;
    foreignExchangeIndicator: string;
    foreignExchangeReference: string;
    originatorCurrencyCode: string;
    fxRecipients: AchInternationalRecipientDto[];
}

export const defaultAchInternationalPaymentModelDto: AchInternationalPaymentModelDto = {
    ...defaultAchPaymentModelDto,
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    destinationCountryCode: '',
    destinationCurrencyCode: '',
    foreignExchangeIndicator: '',
    foreignExchangeReference: '',
    originatorCurrencyCode: '',
    fxRecipients: [],
};
