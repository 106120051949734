import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('achService', achService);

achService.$inject = ['$resource'];

function achService($resource) {
    const resourceUrl = `${getBackOfficeAPIPath()}ACH/`;
    return {
        getAchCompanies,
        updateAchCompany,
        saveAchCompanies,
        getSecCodesForProduct,
        updateSecCodes,
        deleteAchCompany,
        updateAchCompanyStatus,
        getAchOffsetAccounts,
        saveAchOffsetAccounts,
        updateAchCompanyPaymentProcessingStatus,
    };

    function getAchCompanies(name) {
        return $resource(resourceUrl).query({ companyName: name }).$promise;
    }

    function updateAchCompany(productId, achCompany) {
        const resource = $resource(
            `${resourceUrl}:id/achCompanies/:actionId`,
            { id: productId, actionId: achCompany.id },
            { update: { method: 'PUT' } }
        );
        return resource.update(achCompany).$promise;
    }

    function saveAchCompanies(id, achCompanies) {
        return $resource(
            `${resourceUrl}:id/companies`,
            { id },
            {
                add: {
                    method: 'post',
                    isArray: true,
                    suppressErrorMessage: true,
                },
            }
        ).add(achCompanies).$promise;
    }

    function deleteAchCompany(productId, achCompany) {
        return $resource(`${resourceUrl}:id/companies/:actionId`, {
            id: productId,
            actionId: achCompany.id,
        }).delete().$promise;
    }

    function getSecCodesForProduct(productId) {
        return $resource(`${resourceUrl}:id/secCodes`, { id: productId }).query().$promise;
    }

    function updateSecCodes(productId, achCompany, secCodes) {
        const resource = $resource(
            `${resourceUrl}:id/companies/:actionId`,
            { id: productId, actionId: achCompany.id },
            { update: { method: 'PUT', isArray: true } }
        );
        return resource.update(secCodes).$promise;
    }

    function updateAchCompanyStatus(productId, achCompany) {
        const resource = $resource(
            `${resourceUrl}:id/companies/:actionId/updateStatus`,
            { id: productId, actionId: achCompany.id },
            { update: { method: 'PUT' } }
        );
        return resource.update(achCompany).$promise;
    }

    function updateAchCompanyPaymentProcessingStatus(achCompany) {
        const resource = $resource(
            `${resourceUrl}companies/:actionId/updatePaymentProcessingStatus`,
            { actionId: achCompany.id },
            { update: { method: 'PUT' } }
        );
        return resource.update(achCompany).$promise;
    }

    function getAchOffsetAccounts(parentCompanyId, achCompanyId) {
        return $resource(`${resourceUrl}:parentCompanyId/offsetaccounts/:achCompanyId`, {
            parentCompanyId,
            achCompanyId,
        }).get().$promise;
    }

    function saveAchOffsetAccounts(parentCompanyId, achCompanyId, offsetAccounts) {
        return $resource(
            `${resourceUrl}:parentCompanyId/offsetaccounts/:achCompanyId`,
            { parentCompanyId, achCompanyId },
            { put: { method: 'put', isArray: true } }
        ).put(offsetAccounts).$promise;
    }
}
