angular
    .module('backOffice')
    .controller('CloneTransactionCodeGroupsController', cloneTransactionCodeGroupsController);

cloneTransactionCodeGroupsController.$inject = [
    '$scope',
    '$modalInstance',
    'transactionCode',
    'toaster',
    'transactionCodesService',
    'transactionCodeList',
];

function cloneTransactionCodeGroupsController(
    $scope,
    $modalInstance,
    transactionCode,
    toaster,
    transactionCodesService,
    transactionCodeList
) {
    $scope.transactionCode = transactionCode;
    $scope.transactionCodeList = transactionCodeList;
    $scope.isAllSelected = false;

    // this will dismiss the window.
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    $scope.formatDisplayGroups = function (groups) {
        let result = '';
        angular.forEach(groups, group => {
            result += `${group.name}, `;
        });
        result = result.substring(0, result.length - 2);
        return result;
    };

    $scope.selectAll = function () {
        $scope.isAllSelected = !$scope.isAllSelected;
        angular.forEach($scope.transactionCodeList, item => {
            if (item.isVisible) {
                item.checked = $scope.isAllSelected;
            }
        });
    };

    $scope.areAnyChecked = function () {
        let result = false;
        angular.forEach($scope.transactionCodeList, item => {
            if (item.isVisible && item.checked) {
                result = true;
            }
        });
        return result;
    };

    // used to set the form instance.
    $scope.setForm = function (form) {
        $scope.form = form;
    };

    init();

    function init() {
        setVisibility();
    }

    function setVisibility() {
        angular.forEach($scope.transactionCodeList, item => {
            if (
                item.code === $scope.transactionCode.code ||
                item.type !== $scope.transactionCode.type
            ) {
                item.isVisible = false;
            } else {
                item.isVisible = true;
            }
        });
    }

    // we watch for form.$dirty, this is used to alert the end user that they changed something in the form.
    $scope.$watch('form.$dirty', isDirty => {
        $modalInstance.isDirty = isDirty;
    });

    $scope.save = function () {
        const itemsToClone = [];
        angular.forEach($scope.transactionCodeList, item => {
            if (item.isVisible && item.checked) {
                itemsToClone.push({ key: item.code, value: item.code });
            }
        });
        transactionCodesService
            .cloneTransactionCode($scope.transactionCode.id, itemsToClone)
            .then(response => {
                toaster.save('Clone');
                $modalInstance.close();
            });
    };
}
