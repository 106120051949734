angular
    .module('backOffice')
    .controller('CustomizeInternalTransferController', customizeInternalTransferController);

customizeInternalTransferController.$inject = [
    '$scope',
    '$state',
    'toaster',
    'companiesService',
    '$modalInstance',
    'selectedProductId',
    'companyId',
    'modalService',
    'entitlementsService',
];

function customizeInternalTransferController(
    $scope,
    $state,
    toaster,
    companiesService,
    $modalInstance,
    selectedProductId,
    companyId,
    modalService,
    entitlementsService
) {
    $scope.toggleCbox = { checked: false };
    $scope.selectedProductId = selectedProductId;
    $scope.companyId = companyId;
    $scope.selectedProduct = {};
    $scope.showHeaderAndButtons = true;
    $scope.accounts = {
        isCollapsed: true,
    };
    $scope.approvalList = [{ name: 'None' }, { name: '1' }, { name: '2' }, { name: '3' }];
    $scope.authenticationTypeList = [{ name: 'None' }, { name: 'Password' }, { name: 'Token' }];
    $scope.accounts = [
        {
            id: 1,
            name: 'Checking',
            selectAll: false,
        },
        {
            id: 2,
            name: 'Savings',
            selectAll: false,
        },
        {
            id: 3,
            name: 'Club',
            selectAll: false,
        },
        {
            id: 4,
            name: 'Time Deposit',
            selectAll: false,
        },
        {
            id: 5,
            name: 'Loans',
            selectAll: false,
        },
    ];
    $scope.toggleAccount = toggleAccount;
    $scope.cancel = cancel;
    $scope.toggleFromAccountType = toggleFromAccountType;
    $scope.setDirty = setDirty;
    $scope.save = save;
    $scope.toggleColumn = toggleColumn;
    $scope.addAccountToCompany = addAccountToCompany;
    $scope.accountAccessIsCollapsed = true;
    $scope.toggleAllowLoanPaymentPastDue = toggleAllowLoanPaymentPastDue;
    $scope.allowedEntitlement = 'Edit Product Feature';
    $scope.title = entitlementsService.hasEntitlement('Edit Product Feature')
        ? 'Edit Transfer'
        : 'View Transfer';

    function cancel() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel the changes you have made?',
                submit() {
                    cancelModal.dismiss();
                    $modalInstance.dismiss();
                    $state.go('company', { id: companyId });
                },
            };
            const cancelModal = modalService.showModal({}, modalOptions);
        } else {
            $modalInstance.dismiss();
            $state.go('company', { id: companyId });
        }
    }

    function save() {
        let count = 0;
        $scope.selectedProduct.accountPermissions.forEach(permission => {
            permission.fromAccountTypes.forEach(fromAccount => {
                if (fromAccount.isAllowed) {
                    count += 1;
                }
            });
        });
        if ($scope.filteredAccounts) {
            $scope.selectedProduct.accountIds = $scope.filteredAccounts
                .filter(item => item.addToCompany)
                .map(item => item.id);
        }
        $scope.selectedProduct.accounts = $scope.selectedProduct.accounts.map(item => {
            item.coreStatus = 'Active';
            return item;
        });
        if (count > 0) {
            editProductToCompany();
        } else {
            const modalOptions = {
                headerText: 'Warning',
                closeButtonText: 'Cancel',
                actionButtonText: 'Ok',
                bodyText:
                    'Removing all check marks will disable all account types for transfers. Users will not be able to create transfers for any accounts',
                submit(result) {
                    $modalInstance.close();
                    companiesService
                        .updateProductFeature($scope.companyId, $scope.selectedProduct)
                        .then(() => {
                            $modalInstance1.close(result);
                        });
                },
            };
            const $modalInstance1 = modalService.showModal({}, modalOptions);
        }
    }

    function toggleColumn(value) {
        $scope.filteredAccounts.forEach(account => {
            account.addToCompany = value;
        });
    }

    function addAccountToCompany() {
        if ($scope.filteredAccounts.every(element => element.addToCompany === true)) {
            $scope.toggleCbox.checked = true;
        } else {
            $scope.toggleCbox.checked = false;
        }
    }

    function toggleAccount(account) {
        $scope.selectedProduct.accountPermissions.forEach(permission => {
            permission.fromAccountTypes.forEach(fromAccount => {
                if (fromAccount.fromAccountType === account.name) {
                    fromAccount.isAllowed = account.selectAll;
                }
            });
        });
    }

    function toggleFromAccountType(type) {
        let count = 0;
        $scope.selectedProduct.accountPermissions.forEach(permission => {
            permission.fromAccountTypes.forEach(fromAccount => {
                if (fromAccount.fromAccountType === type && fromAccount.isAllowed) {
                    count += 1;
                }
            });
        });

        $scope.accounts.forEach(account => {
            if (account.name === type) {
                if (count === 5) {
                    account.selectAll = true;
                } else {
                    account.selectAll = false;
                }
            }
        });
    }

    function toggleAllowLoanPaymentPastDue() {
        $scope.selectedProduct.allowLoanPaymentPastDue =
            !$scope.selectedProduct.allowLoanPaymentPastDue;
        $scope.form.$setDirty();
    }

    function editProductToCompany() {
        companiesService
            .updateProductFeature($scope.companyId, $scope.selectedProduct)
            .then(response => {
                $modalInstance.close(response);
                toaster.save('Transfer Feature');
            });
    }

    function getProductEntitlements() {
        companiesService
            .getProductPermissions($scope.companyId, $scope.selectedProductId)
            .then(response => {
                $scope.selectedProduct = response;
                const selectAll = [true, true, true, true, true];
                for (let i = 0; i < $scope.selectedProduct.accountPermissions.length; i++) {
                    const permission = $scope.selectedProduct.accountPermissions[i];
                    for (let j = 0; j < permission.fromAccountTypes.length; j++) {
                        const fromAccount = permission.fromAccountTypes[j];
                        if (!fromAccount.isAllowed) {
                            selectAll[j] = false;
                        }
                    }
                }
                for (let i = 0; i < $scope.selectedProduct.accountPermissions.length; i++) {
                    $scope.accounts[i].selectAll = selectAll[i];
                }

                initializeAccountCheckBoxes();
            });
    }

    function setDirty() {
        $scope.form.$dirty = true;
    }
    // we watch for form.$dirty, this is used to alert the end user that they changed something in the form.
    $scope.$watch('form.$dirty', isDirty => {
        $modalInstance.isDirty = isDirty;
    });

    function init() {
        getProductEntitlements();
        loadAccounts();
    }

    function loadAccounts() {
        companiesService.getActiveCompanyAccounts($scope.companyId).then(response => {
            $scope.filteredAccounts = response.map(companyAccount => companyAccount.account);
            initializeAccountCheckBoxes();
        });
    }

    function initializeAccountCheckBoxes() {
        if (
            $scope.selectedProduct == null ||
            $scope.selectedProduct.accounts == null ||
            $scope.filteredAccounts == null
        )
            return;

        $scope.toggleCbox.checked = $scope.filteredAccounts.length > 0;
        $scope.filteredAccounts.forEach(account => {
            account.addToCompany = $scope.selectedProduct.accounts.some(
                productAccount => productAccount.id === account.id
            );
            $scope.toggleCbox.checked = $scope.toggleCbox.checked && account.addToCompany;
        });
    }

    init();
}
