/* eslint-disable no-use-before-define */
import { DiContainer } from '@jack-henry/frontend-utils/di';
import { Recordset } from '@treasury/FDL';
import { NavigationService } from '@treasury/core/navigation';
import { TmApiError } from '@treasury/domain/shared';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-filter-bar.js';
import '@treasury/omega/components/omega-table';
import { LitElement, css, html, nothing } from 'lit';
import { mix } from 'mixwith';
import BackOfficeAlertMixin from '../../mix-ins/back-office-alert-mixin.js';
import ReportDashboardClient from '../clients/report-dashboard-client.js';
import { columns, fields, filters } from '../data/report-dashboard.js';

class ReportDashboardContainer extends mix(LitElement).with(
    ListeningElementMixin,
    BackOfficeAlertMixin
) {
    static get properties() {
        return {
            recordset: Object,
            loading: Boolean,
            alert: Object,
            fields: Object,
            filters: Array,
            columns: Array,
        };
    }

    constructor() {
        super();
        this.client = new ReportDashboardClient();
        this.fields = fields;
        this.columns = columns;
        this.filters = filters;
        this.actions = {
            routeToReport: record => this.routeToReport(record),
            deleteReport: record => this.promptForDeletion(record),
        };
        this.rowsPerPage = 25;
        this.itemLabel = { singular: 'report' };
    }

    async firstUpdated() {
        this.loading = true;
        try {
            this.recordset = new Recordset(this.fields, this.client.getReports);
            this.recordset.pageSize = 25;
            await this.recordset.requestUpdate();
            this.listenTo(this.recordset, 'updated', ({ detail }) =>
                this.favoriteReport(detail.record)
            );
        } catch (e) {
            const { message, code } = coerceError(e);
            this.alert = {
                ...this.alert,
                visible: true,
                type: 'error',
                message,
                code,
            };
        } finally {
            this.loading = false;
        }
    }

    async routeToCurrentDashboard() {
        (await getNavService()).navigate('reports.dashboard');
    }

    async routeToReport(record) {
        const navService = await getNavService();
        const type = record.getField('type');
        const id = record.getField('id');
        const report = record.values;

        switch (report.type) {
            case 'achFilterException':
                navService.navigate('reports.ach-filter-exception', {
                    report,
                });
                break;
            case 'boFiUserActivity':
                navService.navigate('reports.fi-user-activity', {
                    type,
                    id,
                    report,
                });
                break;
            case 'boChannelUserActivity':
                navService.navigate('reports.customer-user-activity', {
                    type,
                    id,
                    report,
                });
                break;
            case 'wireUpload':
                navService.navigate('reports.wire-upload', {
                    report,
                });
                break;
            // the report redesign doesn't match the existing report model returned so
            // we can't use the standard report container until services updates the
            // report response
            default:
                navService.navigate('reports.report-view', { type, id, report });
                break;
        }
    }

    promptForDeletion(record) {
        this.alert = {
            ...this.alert,
            visible: true,
            type: 'warning',
            title: 'Confirm Deletion',
            posture: 'assertive',
            message: 'Are you sure you want to delete this report?',
            actions: html`<omega-button type="reject" @click=${() => this.deleteReport(record)}
                    >Delete</omega-button
                ><omega-button
                    @click=${() => {
                        this.alert = { ...this.alert, visible: false, posture: 'polite', type: '' };
                    }}
                    >Cancel</omega-button
                >`,
        };
    }

    async deleteReport(record) {
        const reportId = record.getField('id');
        this.alert = { ...this.alert, visible: false, posture: 'polite', title: '' };
        this.loading = true;
        try {
            await this.client.deleteReport(reportId);
            await this.recordset.requestHardUpdate();
        } catch (e) {
            const { message } = coerceError(e);
            this.alert = { ...this.alert, type: 'error', posture: 'polite', message };
        }
        this.loading = false;
    }

    async favoriteReport(record) {
        const reportId = record.getField('id');
        const isFavorite = record.getField('isFavoriteReport');
        try {
            await this.client.favoriteReport({ reportId, isFavorite });
        } catch (e) {
            const { message, code } = coerceError(e);
            this.alert = {
                ...this.alert,
                visible: true,
                type: 'error',
                message,
                code,
            };
        }
    }

    handleAction({ action, record, rowIndex }) {
        const actionFunction = this.actions[action];
        if (actionFunction) actionFunction(record, rowIndex);
    }

    render() {
        if (!this.recordset) {
            return nothing;
        }
        return html` ${this.renderAlert()}
            <div class="page-header">
                <h2>Beta Back Office Reports</h2>
                <omega-button type="primary" @click=${this.routeToCurrentDashboard}
                    >Back to current dashboard</omega-button
                >
            </div>

            <hr />
            <omega-filter-bar
                .filters=${this.filters}
                .recordset=${this.recordset}
                .itemLabel=${this.itemLabel}
                @change=${e => {
                    this.tableFilters = e.detail;
                }}
            ></omega-filter-bar>
            <omega-table
                .loading=${this.loading}
                .rowsPerPage=${this.rowsPerPage}
                .recordset=${this.recordset}
                .itemLabel=${this.itemLabel}
                .filters=${this.filters}
                .columnDefinitions=${this.columns}
                @action=${({ detail }) => this.handleAction(detail)}
            ></omega-table>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                margin: 15px;
                padding: 10px;
                background: var(--omega-white);
                box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.25);
            }
            .page-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        `;
    }
}

customElements.define('report-dashboard-container', ReportDashboardContainer);

async function getNavService() {
    return (await DiContainer.getInstance()).get(NavigationService);
}

/**
 * Coerce an error response to a shape usable by `<omega-alert>`.
 * @param { Error | TmError | object} e
 */
function coerceError(e) {
    const message = e instanceof Error ? e.message : 'An unknown error occurred.';
    const code = e instanceof TmApiError ? e.errorCode : undefined;
    const time = e instanceof TmApiError ? e.timestamp : undefined;

    return {
        message,
        code,
        time,
    };
}
