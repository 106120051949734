import { RateQuote, WireDto } from '@treasury/domain/shared/types';
import { Record as FdlRecord } from '@treasury/FDL';
import '@treasury/omega/components/omega-field';
import { html, nothing } from 'lit';
import { ExchangeRateFieldDefinitions } from '../../data/exchange-rate-definitions';

function renderRateId(exchangeRateRecord: FdlRecord<RateQuote>) {
    if (!exchangeRateRecord.getField('rateId')) return nothing;
    return html`
        <div class="row">
            <div class="column">Rate ID</div>
            <div class="column">${exchangeRateRecord.getField('rateId')}</div>
        </div>
    `;
}

export function renderExchangeRate(wire: FdlRecord<WireDto>) {
    if (!wire) return nothing;
    if (!wire.getField('rateQuote')) return nothing;
    const exchangeRateRecord = new FdlRecord<RateQuote>(
        ExchangeRateFieldDefinitions,
        wire.getField('rateQuote')
    );
    return html` <style>
            .exchangeRateInfo {
                margin: 20px;
            }
            .exchangeRateInfo h1 {
                margin: 0 0 5px 0;
            }
            .exchangeRateInfo .row {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;
            }
            .exchangeRateInfo .column {
                display: flex;
                flex-direction: column;
                flex-basis: 100%;
                flex: 1;
            }
            .exchangeRateInfo omega-field {
                --omega-field-label-width: 100px;
                --omega-field-control-width: 150px;
            }
        </style>

        <div class="exchangeRateInfo">
            <div class="row">
                <div class="column"><h1>Exchange Rate</h1></div>
                <div class="column">
                    <h1>
                        USD/${exchangeRateRecord.getField('destinationCurrencyCode')}:
                        ${exchangeRateRecord.getField('conversionRate')}
                    </h1>
                </div>
            </div>
            ${renderRateId(exchangeRateRecord)}
            <div class="row">
                <div class="column">Contract</div>
                <div class="column">${exchangeRateRecord.getField('amexContractNumber')}</div>
            </div>
            <hr class="compact" />
            <div class="row">
                <div class="column">
                    <omega-field field="originAmount" .record=${exchangeRateRecord}></omega-field>
                    <omega-field
                        field="destinationAmount"
                        .record=${exchangeRateRecord}
                    ></omega-field>
                    <omega-field field="valueDate" .record=${exchangeRateRecord}></omega-field>
                </div>
                <div class="column">
                    <omega-field field="exchangeFee" .record=${exchangeRateRecord}></omega-field>
                    <omega-field field="totalCost" .record=${exchangeRateRecord}></omega-field>
                </div>
            </div>
        </div>`;
}
