angular.module('backOffice').controller('ReportTableController', ReportTableController);

ReportTableController.$inject = ['$scope', '$filter', '$modal', '$state'];

function ReportTableController($scope, $filter, $modal, $state) {
    const vm = this;

    vm.sortDesc = false;

    vm.isColSortable = isColSortable;
    vm.getColDescription = getColDescription;
    vm.getColDisplayName = getColDisplayName;
    vm.getColumnModelByName = getColumnModelByName;
    vm.isColSortable = isColSortable;
    vm.getSortClass = getSortClass;
    vm.getFormattedData = getFormattedData;
    vm.sort = sort;
    vm.paginate = paginate;
    vm.openHistoryModal = openHistoryModal;
    vm.getColumnTemplate = getColumnTemplate;
    vm.showDetails = showDetails;
    vm.hasDecisionHistory = hasDecisionHistory;
    vm.hasAuditHistory = hasAuditHistory;
    vm.hasAuditIssuedItemInfo = hasAuditIssuedItemInfo;

    $scope.$watch('vm.reportData', onReportDataChanged, true);

    function paginate() {
        if (vm.isPaged && vm.onPaged) {
            vm.onPaged({ pageNumber: vm.pageNumber, pageSize: vm.pageSize });
        }
    }

    function sort(propertyName) {
        if (!isColSortable(propertyName)) {
            return;
        }

        let sortProperty = getSort(propertyName);
        if (!sortProperty) {
            // sort orders determine how the little sort icon is set with each click
            sortProperty = {
                name: getColumnModelByName(propertyName).columnName,
                sortOrders: [false, true, null],
            };
            vm.sorts = [];
            vm.sorts.push(sortProperty);
        }

        // sets the descending property to the next available one.
        sortProperty.descending = sortProperty.sortOrders.shift();

        // removes the sort from the list if it is set to null
        if (sortProperty.descending == null) {
            vm.sorts = vm.sorts.filter(item => item.name !== sortProperty.name);
        }

        // if you remove the last sort, have to reset to default sort. Can't be no sort.
        if (vm.sorts.length === 0) {
            angular.copy(vm.defaultSorts, vm.sorts);
        }

        if (vm.onSorted) {
            vm.onSorted({ sorts: vm.sorts });
        }
    }

    function getColDescription(column) {
        const columnModel = getColumnModelByName(column);
        if (columnModel) {
            return columnModel.description;
        }

        return null;
    }

    function getColDisplayName(column) {
        const columnModel = getColumnModelByName(column);
        if (columnModel) {
            return columnModel.displayName;
        }

        return $filter('camelCase')(column);
    }

    function isColSortable(column) {
        const columnModel = getColumnModelByName(column);
        if (columnModel) {
            return columnModel.isSortable;
        }

        return false;
    }

    function getSortClass(col) {
        const sortProperty = getSort(col);
        if (sortProperty && sortProperty.descending === true) {
            return 'fa fa-caret-down';
        }
        if (sortProperty && sortProperty.descending === false) {
            return 'fa fa-caret-up';
        }
        return 'fa fa-sort';
    }

    function getFormattedData(colName, data) {
        const columnModel = getColumnModelByName(colName);
        if (!columnModel || !columnModel.displayType) {
            return data;
        }
        switch (columnModel.displayType) {
            case 'DateTime':
                return $filter('date')(data, 'MM/dd/yyyy @ h:mma');
            case 'Date':
                return $filter('date')(data, 'MM/dd/yyyy');
            case 'Time':
                return $filter('date')(data, 'h:mma');
            case 'Currency':
                return $filter('currency')(data);
            case 'CurrencyNoSymbol':
            case 'Number':
                return $filter('number')(data, 2);
            case 'historylink':
                return getColDisplayName(colName);
            case 'attachmentlink':
                return getColDisplayName(colName);
            default:
                return data;
        }
    }

    function getColumnTemplate(col) {
        const columnModel = vm.getColumnModelByName(col);

        if (columnModel.displayType === 'historylink') {
            return 'historylink.html';
        }

        if (columnModel.displayType === 'attachmentlink') {
            return 'attachmentlink.html';
        }

        if (columnModel.displayType === 'decisionhistory') {
            return 'decisionhistory.html';
        }

        if (columnModel.displayType === 'audithistory') {
            return 'auditHistory.html';
        }

        if (columnModel.displayType === 'checkimagelink') {
            return 'checkimagelink.html';
        }

        if (columnModel.displayType === 'auditinfo') {
            return 'issueditemauditinfo.html';
        }

        /// DetailsAccessible must always be last if block.
        if (columnModel.detailsAccessible) {
            return 'details.html';
        }

        return 'default.html';
    }

    function showDetails(row, col) {
        const columnModel = vm.getColumnModelByName(col);
        if (columnModel.displayType === 'checkimagelink') {
        } else if (columnModel.displayType === 'decisionhistory') {
        } else if (columnModel.displayType === 'audithistory') {
        } else if (columnModel.displayType === 'auditinfo') {
        } else {
            $state.go('reports.wire-details', {
                reportId: vm.reportId,
                reportType: vm.reportType,
                id: row.id,
            });
        }
    }

    function hasDecisionHistory(row, col) {
        return row[col] !== '0';
    }

    function hasAuditHistory(row, col) {
        return row[col] == true;
    }

    function hasAuditIssuedItemInfo(row, col) {
        return row[col] !== '';
    }

    // returns true if modal is opened,
    // false if not opened for any reason
    // including not opened because the string passed in wasn't "correct".
    function openHistoryModal(transactionTypeAndId) {
        if (!transactionTypeAndId) return false;

        const parts = transactionTypeAndId.split('|');

        if (parts.length < 2) return false;

        const transactionType = parts[0];
        const transactionId = parts[1];

        if (!Number.isInteger(+transactionId)) return false;

        switch (transactionType) {
            case 'wire':
                showWireHistory(transactionId);
                return true;
            default:
                return false;
        }
    }

    // Private methods
    function showWireHistory(wireId) {
        $modal.open({
            template: require('../../../reports/views/wireHistoryView.html'),
            size: 'lg',
            controller: 'WireHistoryController',
            backdrop: 'static',
            resolve: {
                wireId() {
                    return wireId;
                },
            },
        });
    }

    function onReportDataChanged(newValue, oldValue) {
        if (newValue && vm.reportData && vm.reportData.length > 0) {
            const columns = Object.keys(vm.reportData[0]).sort(compareColumnOrder);
            vm.columns = [];

            for (let i = 0; i < columns.length; i++) {
                if (getColumnModelByName(columns[i])) {
                    vm.columns.push(columns[i]);
                }
            }

            vm.pageCount = Math.ceil(vm.totalCount / vm.pageSize);
            vm.pageStart = (vm.pageNumber - 1) * vm.pageSize + 1;
            vm.pageEnd = vm.pageStart + vm.reportData.length - 1;
            vm.pageSizeOptions = [
                { label: '10', count: 10 },
                { label: '25', count: 25 },
                { label: '50', count: 50 },
                { label: '100', count: 100 },
                // setting this here so that front end is always consistent with back end max page size
                { label: vm.pageMaxSize, count: vm.pageMaxSize },
            ];
        }
    }

    function getSort(name) {
        let sortProperty = null;
        angular.forEach(vm.sorts, item => {
            if (item.name.toLowerCase() === name.toLowerCase()) {
                sortProperty = item;
            }
        });

        return sortProperty;
    }

    function compareColumnOrder(column1, column2) {
        const columnModel1 = getColumnModelByName(column1);
        const columnModel2 = getColumnModelByName(column2);
        if (columnModel1 && columnModel2) {
            return columnModel1.displayOrder - columnModel2.displayOrder;
        }
        if (columnModel1 && !columnModel2) {
            return 1;
        }
        if (!columnModel1 && columnModel2) {
            return -1;
        }
        return 0;
    }

    function getColumnModelByName(colName) {
        if (vm.columnModels && vm.columnModels.length > 0) {
            const columnModel = vm.columnModels.filter(
                currentColumn => currentColumn.columnName.toLowerCase() === colName.toLowerCase()
            );
            if (columnModel && columnModel.length === 1) {
                return columnModel[0];
            }
        }

        return null;
    }
}
