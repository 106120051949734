/* eslint-disable guard-for-in */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-use-before-define */
import { FeatureFlagService } from '@treasury/domain/services/feature-flags';

angular
    .module('backOffice')
    .controller(
        'AccountReconciliationReportingEntitlementsController',
        accountReconciliationReportingEntitlementsController
    );

accountReconciliationReportingEntitlementsController.$inject = [
    '$scope',
    'toaster',
    'companyUsersService',
];

function accountReconciliationReportingEntitlementsController(
    $scope,
    toaster,
    companyUsersService
) {
    const claimsDisplayMetadata = {
        // Day One implementation, intended to be temporary
        create: { label: 'Enable', claimName: 'Manage Account Recon Report Templates' },
        // The future 'Day Two' view implementation follows
        //view: { label: 'View', claimName: 'View Account Recon Reports'},
        //create: { label: 'Create', claimName: 'Manage Account Recon Report Templates'},
        //delete: { label: 'Delete', claimName: 'Delete Account Recon Reports'},
    };

    let entitlementsPristine = [];
    let entitlementsCurrent = [];
    let isLoading = true;
    const userCompanyId = $scope.companyId;
    const userId = $scope.id;

    const vm = this;
    vm.allSelected = {};
    vm.isSuperUser = $scope.$parent.userHeader.isSuperUser;

    vm.filteredAccounts = [];
    vm.accountReconciliationReports = [];
    vm.searchText = '';

    vm.isEditMode = () => $scope.editMode;

    vm.setForm = form => {
        vm.form = form;
    };

    vm.search = searchText => {
        vm.searchText = searchText;
        if (entitlementsCurrent) {
            updateFilteredAccounts(searchText, entitlementsCurrent.accounts);
        }
    };

    vm.save = () => {
        mapClaimsFromDisplay(
            entitlementsCurrent.accountProductClaims,
            entitlementsCurrent.accounts
        );
        dayOneSaveHack(entitlementsCurrent);
        const updates = companyUsersService.buildUpdateProductEntitlementsRequest(
            entitlementsCurrent,
            entitlementsPristine
        );

        companyUsersService.updateProductEntitlements(userCompanyId, userId, updates).then(() => {
            $scope.$parent.notifySave();
            init();
            toaster.save('Account Reconciliation Reporting Entitlements');
        });
    };

    vm.showClaim = claimKey => claimsDisplayMetadata[claimKey] != null;
    vm.claimLabel = claimKey => claimsDisplayMetadata[claimKey].label;
    vm.showNoAccounts = () => !isLoading && vm.filteredAccounts.length === 0;
    vm.toggleAll = key => {
        const toggleStateNew = vm.allSelected[key];
        vm.filteredAccounts.forEach(account => {
            // eslint-disable-next-line no-param-reassign
            account[key].userClaim.hasPendingUserClaim = toggleStateNew;
        });
    };
    vm.selectionChanged = key => {
        vm.allSelected[key] = vm.filteredAccounts.every(
            account => account[key]?.userClaim.hasPendingUserClaim
        );
    };

    vm.reset = () => {
        isLoading = true;
        entitlementsCurrent = angular.copy(entitlementsPristine);
        vm.form.$setPristine();
        vm.search('');
        isLoading = false;
    };

    // Private
    function setAllSelectedState(filteredAccounts) {
        vm.allSelected.view = filteredAccounts.every(
            account => account.view?.userClaim.hasPendingUserClaim
        );
        vm.allSelected.create = filteredAccounts.every(
            account => account.create?.userClaim.hasPendingUserClaim
        );
        vm.allSelected.delete = filteredAccounts.every(
            account => account.delete?.userClaim.hasPendingUserClaim
        );
    }

    function updateFilteredAccounts(searchText, unfilteredAccounts) {
        const textForSearch = (searchText ?? '').toLowerCase();
        vm.filteredAccounts = unfilteredAccounts.filter(
            account =>
                account.name.toLowerCase().includes(textForSearch) ||
                account.number.toLowerCase().includes(textForSearch)
        );
        setAllSelectedState(vm.filteredAccounts);
    }

    async function loadEntitlements() {
        companyUsersService
            .getProductEntitlements(userCompanyId, userId, 'AccountRecon')
            .then(async response => {
                entitlementsPristine = scrubIncomingData(response);
                entitlementsCurrent = copyEntitlements(entitlementsPristine);
                vm.search('');
            });
    }

    function copyEntitlements(entitlements) {
        //Partial deep copy needed
        const workingCopy = angular.copy(entitlements);
        workingCopy.accounts = [];
        entitlements.accounts.forEach(account => {
            const accountCopy = angular.copy(account);
            accountCopy.userAccountClaims = angular.copy(account.userAccountClaims);
            workingCopy.accounts.push(accountCopy);
        });
        workingCopy.userProductClaims = [];
        entitlements.userProductClaims.forEach(claim => {
            const userProductClaimCopy = angular.copy(claim);
            workingCopy.userProductClaims.push(userProductClaimCopy);
        });
        return workingCopy;
    }

    function scrubIncomingData(entitlementData) {
        assureUserAccountClaims(entitlementData);
        dayOneHack(entitlementData); //Change or remove this as implementation evolves.

        mapClaimsDisplay(entitlementData.accountProductClaims, entitlementData.accounts);

        return entitlementData;
    }

    function getUserAccountClaim(account, claimId) {
        return account.userAccountClaims.find(claim => claim.claimId === claimId);
    }

    function assureUserAccountClaims(entitlements) {
        // userAccountClaim array has a claim for each product claim
        // coalesce null and undefined pending claims to false
        entitlements.accountProductClaims.forEach(claim => {
            entitlements.accounts.forEach(account => {
                let matchingClaim = getUserAccountClaim(account, claim.claimId);
                if (!matchingClaim) {
                    matchingClaim = {
                        claimId: claim.claimId,
                        claimName: claim.claimName,
                        hasUserClaim: false,
                        hasPendingUserClaim: false,
                        claimDescription: claim.claimDescription,
                    };
                    account.userAccountClaims.push(matchingClaim);
                }
                if (matchingClaim.hasPendingUserClaim == null) {
                    matchingClaim.hasPendingUserClaim = false;
                }
            });
        });
    }

    function dayOneHack(entitlements) {
        //Bundles entitlements for the simplified implementation.
        //If any account claim is set true, set create claim true.
        const createClaimId = dayOneHackGetCreateClaimId(entitlements.accountProductClaims);
        entitlements.accounts.forEach(account => {
            const hasSomePendingUserClaim = account.userAccountClaims.some(
                claim => claim.hasPendingUserClaim === true
            );
            const createClaim = account.userAccountClaims.find(
                claim => claim.claimId === createClaimId
            );
            createClaim.hasPendingUserClaim = hasSomePendingUserClaim;
        });
    }

    function dayOneSaveHack(entitlements) {
        //Bundles entitlements for the simplified implementation.
        //Set all pending claims to be the same as the create claim
        const createClaimId = dayOneHackGetCreateClaimId(entitlements.accountProductClaims);
        entitlements.accounts.forEach(account => {
            const hasCreateClaim = account.userAccountClaims.some(
                claim => claim.claimId === createClaimId && claim.hasPendingUserClaim === true
            );
            account.userAccountClaims.forEach(claim => {
                if (claim.claimId !== createClaimId) {
                    // eslint-disable-next-line no-param-reassign
                    claim.hasPendingUserClaim = hasCreateClaim;
                }
            });
        });
    }

    function dayOneHackGetCreateClaimId(accountProductClaims) {
        const createClaimName = claimsDisplayMetadata.create.claimName;
        const createClaim = accountProductClaims.find(claim => claim.claimName === createClaimName);
        return createClaim?.claimId;
    }

    function mapClaimsDisplay(accountProductClaims, accounts) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in claimsDisplayMetadata) {
            const metaData = claimsDisplayMetadata[key];
            const matchingProductClaim = accountProductClaims.find(
                claim => metaData.claimName === claim.claimName
            );
            if (matchingProductClaim) {
                accounts.forEach(account => {
                    if (!accounts[key]) {
                        // eslint-disable-next-line no-param-reassign
                        account[key] = {};
                    }
                    const matchingUserAccountClaim = account.userAccountClaims.find(
                        claim => claim.claimId === matchingProductClaim.claimId
                    );
                    // eslint-disable-next-line no-param-reassign
                    account[key].userClaim = matchingUserAccountClaim;
                });
            }
        }
    }

    function mapClaimsFromDisplay(accountProductClaims, accounts) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in claimsDisplayMetadata) {
            const metaData = claimsDisplayMetadata[key];
            const matchingProductClaim = accountProductClaims.find(
                claim => metaData.claimName === claim.claimName
            );
            if (matchingProductClaim) {
                accounts.forEach(account => {
                    const matchingUserAccountClaim = account.userAccountClaims.find(
                        claim => claim.claimId === matchingProductClaim.claimId
                    );
                    matchingUserAccountClaim.hasPendingUserClaim =
                        account[key].userClaim.hasPendingUserClaim;
                });
            }
        }
    }

    async function init() {
        isLoading = true;
        loadEntitlements().then(() => {
            vm.form.$setPristine();
            isLoading = false;
        });

        vm.hasAccountNumberEntitlementView = await FeatureFlagService.isEnabled(
            'Feature.Channel.UseAccountName.TM6777'
        );
    }

    init();
}
