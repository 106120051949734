angular.module('backOffice').controller('AddAchCompanyController', addAchCompanyController);

addAchCompanyController.$inject = [
    '$scope',
    '$state',
    'achService',
    'achCompaniesService',
    '$stateParams',
    'achCompanies',
    '$q',
    '$modalInstance',
    'achCompanyMatchType',
    'entitlementsService',
];

function addAchCompanyController(
    $scope,
    $state,
    achService,
    achCompaniesService,
    $stateParams,
    achCompanies,
    $q,
    $modalInstance,
    achCompanyMatchType,
    entitlementsService
) {
    $scope.obj = { selectAll: false };
    $scope.unCommonAchCompanies = [];
    $scope.submittedAchCompanies = [];
    $scope.alreadyPresentAchCompanies = achCompanies;
    $scope.isConfirmationMode = false;
    $scope.achCompanyMatchType = achCompanyMatchType;
    $scope.getSecCodesString = getSecCodesString;
    $scope.selectOrDeselectAll = selectOrDeselectAll;
    $scope.cancelConfirmation = cancelConfirmation;
    $scope.clearSearchCriteria = clearSearchCriteria;
    $scope.search = search;
    $scope.submit = submit;
    $scope.cancel = cancel;
    $scope.confirm = confirm;
    $scope.updateList = updateList;
    $scope.isCompanySelected = isCompanySelected;
    $scope.errorMessage = null;

    function hasAchCompanyMatchFeature() {
        return entitlementsService.hasEntitlement('Feature.Ach.CompanyMatch.508948');
    }

    function search(name) {
        $scope.errorMessage = null;
        $scope.searchPerformed = true;
        if (name.length > 0) {
            if (hasAchCompanyMatchFeature()) {
                achCompaniesService.getAchCompaniesDeduped($stateParams.id, name).then(response => {
                    $scope.obj.selectAll = false;
                    $scope.unCommonAchCompanies = angular.copy(response);
                });
            } else {
                achCompaniesService.getAchCompanies(name).then(response => {
                    $scope.obj.selectAll = false;
                    $scope.achCompanies = angular.copy(response);
                    getUnCommonAchCompanies();
                });
            }
        } else {
            $scope.achCompanies = [];
            $scope.unCommonAchCompanies = [];
        }
    }

    function confirm() {
        achService
            .saveAchCompanies($stateParams.productFeatureId, $scope.submittedAchCompanies)
            .then(response => {
                $modalInstance.close(response);
            })
            .catch(e => {
                $scope.errorMessage =
                    e instanceof Error ? e.message : 'An unidentified error has occurred.';
                reset();
            });
    }

    function submit() {
        $scope.isConfirmationMode = true;
        angular.forEach($scope.unCommonAchCompanies, company => {
            if (company.isUserSelected) {
                $scope.submittedAchCompanies.push(company);
            }
        });
    }

    function cancel() {
        $modalInstance.dismiss();
    }

    function selectOrDeselectAll() {
        angular.forEach($scope.unCommonAchCompanies, item => {
            item.isUserSelected = $scope.obj.selectAll;
        });
    }

    function getSecCodesString(achCompany) {
        return achCompany.secCodes.map(elem => elem.code).join(', ');
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    function getUnCommonAchCompanies() {
        if ($scope.alreadyPresentAchCompanies.length > 0) {
            $scope.unCommonAchCompanies.length = 0;
            angular.forEach($scope.achCompanies, company => {
                let exclude = false;
                angular.forEach($scope.alreadyPresentAchCompanies, item => {
                    const hasMatchingDiscretionaryData =
                        item.discretionaryData &&
                        item.discretionaryData === company.discretionaryData;
                    switch ($scope.achCompanyMatchType) {
                        case 1:
                            if (
                                item.companyName === company.companyName &&
                                $scope.unCommonAchCompanies.indexOf(company) === -1
                            ) {
                                exclude = true;
                            }
                            break;
                        case 2:
                            if (
                                item.companyName === company.companyName &&
                                item.companyId === company.companyId &&
                                $scope.unCommonAchCompanies.indexOf(company) === -1
                            ) {
                                exclude = true;
                            }
                            break;
                        case 3:
                            if (
                                item.companyName === company.companyName &&
                                item.companyId === company.companyId &&
                                item.secCodes[0].code === company.secCodes[0].code &&
                                $scope.unCommonAchCompanies.indexOf(company) === -1
                            ) {
                                exclude = true;
                            }
                            break;
                        case 4:
                            if (
                                item.companyName === company.companyName &&
                                item.companyId === company.companyId &&
                                item.secCodes[0].code === company.secCodes[0].code &&
                                item.entryDescription === company.entryDescription &&
                                $scope.unCommonAchCompanies.indexOf(company) === -1
                            ) {
                                exclude = true;
                            }
                            break;
                        case 5:
                            if (
                                item.companyName === company.companyName &&
                                item.companyId === company.companyId &&
                                item.secCodes[0].code === company.secCodes[0].code &&
                                hasMatchingDiscretionaryData &&
                                item.entryDescription === company.entryDescription &&
                                $scope.unCommonAchCompanies.indexOf(company) === -1
                            ) {
                                exclude = true;
                            }
                            break;
                        default:
                            exclude = false;
                            break;
                    }
                });
                if (!exclude) $scope.unCommonAchCompanies.push(company);
            });
        } else {
            $scope.unCommonAchCompanies = angular.copy($scope.achCompanies);
        }
    }

    function cancelConfirmation() {
        $scope.isConfirmationMode = false;
        $scope.submittedAchCompanies = [];
    }

    function updateList() {
        let allSelected = true;
        angular.forEach($scope.unCommonAchCompanies, company => {
            if (!company.isUserSelected) {
                allSelected = false;
            }
        });
        $scope.obj.selectAll = allSelected;
    }

    function initializeSearchCriteria() {
        $scope.achCompaniesSearchText = '';
    }

    function clearSearchCriteria() {
        initializeSearchCriteria();
    }

    function reset() {
        cancelConfirmation();
        clearSearchCriteria();
        $scope.searchPerformed = false;
        $scope.achCompanies = [];
        $scope.unCommonAchCompanies = [];
        $scope.obj.selectAll = false;
    }

    function isCompanySelected() {
        let flag = false;
        angular.forEach($scope.unCommonAchCompanies, company => {
            if (company.isUserSelected) {
                flag = true;
            }
        });

        return flag;
    }
}
