import { FiDate } from '@treasury/domain/dates';
import moment from 'moment';

angular
    .module('backOffice')
    .controller('ProcessingConfigurationController', processingConfigurationController);

processingConfigurationController.$inject = [
    '$scope',
    'toaster',
    'modalService',
    'cutOffConfigurationService',
    'holidayExceptionsService',
    '$anchorScroll',
    '$location',
    '$timeout',
    '$filter',
    'entitlementsService',
];

function processingConfigurationController(
    $scope,
    toaster,
    modalService,
    cutOffConfigurationService,
    holidayExceptionsService,
    $anchorScroll,
    $location,
    $timeout,
    $filter,
    entitlementsService
) {
    const originalTimeZoneSettings = {};

    $scope.isCollapsed = false;
    $scope.isEditMode = false;
    $scope.editedHolidayException = null;
    $scope.hstep = 1;
    $scope.mstep = 15;
    $scope.holidayExceptionsCurrent = [];
    $scope.holidayExceptionsPast = [];
    $scope.isLoaded = false;
    $scope.inEditHolidayException = null;
    $scope.selectedTab = 'Processing Times';
    $scope.tabs = [];

    $scope.setEditMode = setEditMode;
    $scope.deleteHolidayException = deleteHolidayException;
    $scope.cancelProcessingTimes = cancelProcessingTimes;
    $scope.cancelHolidayExceptionEdit = cancelHolidayExceptionEdit;
    $scope.setHolidayExceptionsForm = setHolidayExceptionsForm;
    $scope.saveProcessingTime = saveProcessingTime;
    $scope.saveHolidayException = saveHolidayException;
    $scope.addHolidayException = addHolidayException;
    $scope.editProcessingTime = editProcessingTime;
    $scope.setProcessingTimesForm = setProcessingTimesForm;
    $scope.setForm = setForm;
    $scope.changeCollapseState = changeCollapseState;
    $scope.selectTab = selectTab;
    $scope.editTimeZone = editTimeZone;
    $scope.saveTimeZone = saveTimeZone;
    $scope.cancelTimeZone = cancelTimeZone;
    $scope.tabClasses = tabClasses;
    $scope.showAddHolidayButton = showAddHolidayButton;

    $scope.formatDate = function (item) {
        if (item.date !== null && item.date !== undefined && item.date.length > 0) {
            item.date = formatDate(item.date);
        }
    };

    function selectTab(tabName, forms) {
        let showWarning = false;
        angular.forEach($scope.processingTimesList, processingTime => {
            if (processingTime.isEditMode) {
                showWarning = true;
            }
        });

        angular.forEach($scope.holidayExceptionsCurrent, holiday => {
            if (holiday.isEditMode) {
                showWarning = true;
            }
        });
        let modalOptions;
        let modalInstance;

        if (tabName === $scope.selectedTab) {
        } else if (showWarning) {
            modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel the changes?',
                submit(result) {
                    angular.forEach(forms, form => {
                        if (form.$dirty) {
                            form.$setPristine();
                        }
                    });
                    loadHolidayExceptions();
                    loadProcessingTimeConfiguration();
                    $scope.selectedTab = tabName;
                    modalInstance.close(result);
                },
            };
            modalInstance = modalService.showModal({}, modalOptions);
        } else {
            $scope.selectedTab = tabName;
        }
    }

    function setProcessingTimesForm(form) {
        $scope.processingTimesForm = form;
    }

    function setHolidayExceptionsForm(form) {
        $scope.holidayExceptionsForm = form;
    }

    function formatDate(dt) {
        return new FiDate(new Date(dt)).toString();
    }

    function saveProcessingTime(id, processingTimes, index) {
        processingTimes.startTime = moment(processingTimes.startTime).format('YYYY-MM-DDTHH:mm:ss');
        processingTimes.cutOffTime = moment(processingTimes.cutOffTime).format(
            'YYYY-MM-DDTHH:mm:ss'
        );
        cutOffConfigurationService.update(id, processingTimes).then(response => {
            response.startTime = moment(response.startTime, 'YYYY-MM-DDTHH:mm:ss').format(
                'YYYY-MM-DDTHH:mm:ssZ'
            );
            response.cutOffTime = moment(response.cutOffTime, 'YYYY-MM-DDTHH:mm:ss').format(
                'YYYY-MM-DDTHH:mm:ssZ'
            );
            $scope.processingTimesList[$scope.processingTimesList.indexOf(processingTimes)] =
                response;
            $scope.processingTimesList[index].isEditMode = false;
            toaster.save('Processing Times');
        });
    }

    function saveTimeZone() {
        const timeZoneModel = {};
        angular.extend(timeZoneModel, $scope.timeZoneSettings);
        delete timeZoneModel.isEditMode;

        cutOffConfigurationService.updateInstitutionTimeZone(timeZoneModel).then(() => {
            copyTimeZoneSettings();
            $scope.timeZoneSettings.isEditMode = false;
            toaster.save('Time Zone Settings');
        });
    }

    function saveHolidayException(item) {
        item.name = item.editName;
        item.date = item.editDate;
        if (item.id > -1) {
            updateHolidayException(item);
        } else {
            saveHolidayExceptionItem(item);
        }
    }

    function editProcessingTime(index, processingTimes) {
        $scope.copyProcessingTimes = processingTimes;
        $scope.processingTimesList[index].isEditMode = true;
    }

    function saveHolidayExceptionItem(item) {
        holidayExceptionsService.saveHolidayException(item).then(response => {
            angular.extend(item, response);
            item.isEditMode = false;
            item.id = Math.max(0, response.id);
            toaster.save('Holiday Exception');
        });
    }

    function updateHolidayException(item) {
        item.isEditMode = false;
        holidayExceptionsService.updateHolidayException(item.id, item).then(response => {
            item.isEditMode = false;
            toaster.save('Holiday Exception');
        });
    }

    function addHolidayException() {
        let isAddMode = false;
        angular.forEach($scope.holidayExceptionsCurrent, item => {
            if (item.id === -1) {
                isAddMode = true;
            }
        });
        if (isAddMode) {
            return;
        }
        const obj = {
            id: -1,
            isEditMode: true,
        };
        $scope.holidayExceptionsCurrent.push(obj);
        $location.hash('bottomHolidayExceptions');
        $anchorScroll();
    }

    // used to set the form instance.
    function setForm(form) {
        $scope.form = form;
    }

    function changeCollapseState() {
        $scope.isCollapsed = !$scope.isCollapsed;
        if ($scope.isCollapsed === false) {
            $timeout(() => {
                $location.hash('bottomPastHolidayExceptions');
                $anchorScroll();
            }, 500);
        }
    }

    function cancelHolidayExceptionEdit(item) {
        const modalOptions = {
            closeButtonText: 'Continue Editing',
            actionButtonText: 'OK',
            headerText: 'Cancel',
            bodyText: 'Are you sure you want to cancel the changes?',
            submit(result) {
                if (item.id === -1) {
                    $scope.holidayExceptionsCurrent.splice(
                        $scope.holidayExceptionsCurrent.indexOf(item),
                        1
                    );
                } else {
                    item.isEditMode = false;
                }
                $modalInstance.close(result);
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    function cancelProcessingTimes(index, processingTimes) {
        const modalOptions = {
            closeButtonText: 'Continue Editing',
            actionButtonText: 'OK',
            headerText: 'Cancel',
            bodyText: 'Are you sure you want to cancel the changes?',
            submit(result) {
                const processingId = $scope.processingTimesList[index].id;
                cutOffConfigurationService.get(processingId).then(response => {
                    response.startTime = moment(response.startTime, 'YYYY-MM-DDTHH:mm:ss').format(
                        'YYYY-MM-DDTHH:mm:ssZ'
                    );
                    response.cutOffTime = moment(response.cutOffTime, 'YYYY-MM-DDTHH:mm:ss').format(
                        'YYYY-MM-DDTHH:mm:ssZ'
                    );
                    $scope.processingTimesList[
                        $scope.processingTimesList.indexOf(processingTimes)
                    ] = response;
                    $scope.processingTimesList[index].isEditMode = false;
                    $modalInstance.close(result);
                });
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    function cancelTimeZone() {
        if (!$scope.timeZone.$dirty) {
            $scope.timeZoneSettings.isEditMode = false;
            return;
        }
        const modalOptions = {
            closeButtonText: 'Continue Editing',
            actionButtonText: 'OK',
            headerText: 'Cancel',
            bodyText: 'Are you sure you want to cancel the changes?',
            submit(result) {
                angular.extend($scope.timeZoneSettings, originalTimeZoneSettings);
                $scope.timeZoneSettings.isEditMode = false;
                $modalInstance.close(result);
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    function deleteHolidayException(item) {
        const modalOptions = {
            bodyText: 'Are you sure you want to delete this Holiday exception?',
            submit(result) {
                holidayExceptionsService.deleteHolidayException(item.id).then(response => {
                    $scope.holidayExceptionsCurrent.splice(
                        $scope.holidayExceptionsCurrent.indexOf(item),
                        1
                    );
                    toaster.delete('Holiday Exception');
                    $modalInstance.close(result);
                });
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    function setEditMode(item) {
        item.date = formatDate(item.date);
        item.isEditMode = true;
        item.editName = item.name;
        item.editDate = item.date;
    }

    function loadHolidayExceptions() {
        holidayExceptionsService.getHolidayExceptions().then(response => {
            // we parse through the collection and split out which ones are past exception vs future exception dates
            angular.forEach(response, item => {
                if (item.isPastDue === true) {
                    $scope.holidayExceptionsPast.push(item);
                } else {
                    $scope.holidayExceptionsCurrent.push(item);
                }
            });
            $scope.isLoaded = true;
        });
    }

    function loadProcessingTimeConfiguration() {
        cutOffConfigurationService.getAll().then(response => {
            $scope.timeZoneSettings = response.timeZone;
            $scope.timeZoneSettings.isEditMode = false;
            $scope.processingTimesList = $filter('orderBy')(
                response.productFeatureProcessingTimes,
                'name',
                $scope.reverseSort
            );
            angular.forEach($scope.processingTimesList, item => {
                item.isEditMode = false;
                item.startTime = moment(item.startTime, 'YYYY-MM-DDTHH:mm:ss').format(
                    'YYYY-MM-DDTHH:mm:ssZ'
                );
                item.cutOffTime = moment(item.cutOffTime, 'YYYY-MM-DDTHH:mm:ss').format(
                    'YYYY-MM-DDTHH:mm:ssZ'
                );
            });
        });
    }

    function loadTimeZones() {
        cutOffConfigurationService.getTimeZones().then(response => {
            $scope.timeZones = response;
        });
    }

    function compareDates(date, dates) {
        if (typeof dates === 'undefined' || dates === null || dates.length === 0) {
            return false;
        }
        for (let i = 0; i < dates.length; i++) {
            if (
                moment(dates[i]).date() === moment(date).date() &&
                moment(dates[i]).month() === moment(date).month() &&
                moment(dates[i]).year() === moment(date).year()
            ) {
                return true;
            }
        }
        return false;
    }

    $scope.dpOptions = {
        disableDates(date) {
            if (date) {
                return compareDates(
                    date,
                    $scope.holidayExceptionsCurrent.map(item => item.date)
                );
            }
            return false;
        },
    };

    function showAddHolidayButton() {
        return entitlementsService.hasAnyEntitlement(
            'Processing Configuration Holiday Exceptions Add',
            'Switch FI'
        );
    }

    function tabClasses(tab) {
        const classes = ['tab'];

        if (tab.text === $scope.selectedTab) {
            classes.push('selected');
        }

        return classes.join(' ');
    }

    function init() {
        loadHolidayExceptions();
        $scope.orderByField = 'date';
        $scope.reverseSort = false;
        loadProcessingTimeConfiguration();
        loadTimeZones();
        setupTabs();
        const today = new Date();
        const dd = today.getDate();
        const mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        $scope.currentDate = formatDate(`${mm}/${dd}/${yyyy}`);
    }
    init();

    function setupTabs() {
        const allTabs = [
            {
                text: 'Processing Times',
                entitlements: [
                    'Processing Configuration Edit Cutoff',
                    'View Processing Configuration',
                    'Switch FI',
                ],
            },
            {
                text: 'Holiday Exceptions',
                entitlements: [
                    'Processing Configuration Holiday Exceptions Add',
                    'Processing Configuration Holiday Exceptions Edit',
                    'Processing Configuration Holiday Exceptions Delete',
                    'View Processing Configuration',
                    'Switch FI',
                ],
            },
        ];

        allTabs.forEach(tab => {
            if (entitlementsService.hasAnyEntitlement(tab.entitlements)) {
                $scope.tabs.push(tab);
            }
        });

        $scope.selectedTab = $scope.tabs[0].text;
    }

    function editTimeZone() {
        $scope.timeZoneSettings.isEditMode = true;
        copyTimeZoneSettings();
    }

    function copyTimeZoneSettings() {
        angular.extend(originalTimeZoneSettings, $scope.timeZoneSettings);
        delete originalTimeZoneSettings.isEditMode;
    }
}
