import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('SecCodesService', secCodesService);

secCodesService.$inject = ['$resource'];

function secCodesService($resource) {
    const resourceUrl = `${getBackOfficeAPIPath()}achSecCodes/`;
    return {
        getAllSecCodes,
    };

    function getAllSecCodes() {
        return $resource(resourceUrl).query().$promise;
    }
}
