/* eslint-disable max-classes-per-file */
import { AchRecipientModelDto } from '@treasury/api/bo';
import { AchRecipient } from '@treasury/domain/backoffice/types/ach';
import { Record } from '@treasury/FDL';
import '@treasury/omega/components/omega-dialog';
import { DialogUtility } from '@treasury/omega/util/dialog-utility';
import { css, html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';

const tag = 'ach-recipient-addenda-dialog';

const util = new DialogUtility(tag);

export class AchRecipientAddendaDialog extends LitElement {
    @property({ type: Object })
    record?: Record<AchRecipient<AchRecipientModelDto>>;

    static open(context: Element) {
        util.openFromParentContainer(context);
    }

    close() {
        util.close(this);
    }

    handleOpen() {
        if (!this.record) return;
    }

    renderAddendaActions() {
        return html`<omega-button slot="actions" type="primary" @click=${() => this.close()}
            >Close</omega-button
        >`;
    }

    renderAddenda() {
        if (!this.record) return nothing;
        return html`<omega-field
            id="addendas"
            field="addendas"
            .readOnly=${true}
            .record=${this.record}
        ></omega-field>`;
    }

    render() {
        return html`
            <omega-dialog
                id=${util.dialogId}
                dialogTitle="Addenda"
                @open=${() => this.handleOpen()}
                @close=${() => this.close()}
            >
                <div slot="content">
                    <div class="dialog-content">${this.renderAddenda()}</div>
                </div>
                ${this.renderAddendaActions()}
            </omega-dialog>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .dialog-content {
                padding: 20px;
                padding-top: 0;
            }
        `;
    }
}
// eslint-disable-next-line @treasury/consistent-custom-element-name
customElements.define(tag, AchRecipientAddendaDialog);
