import {UsersService as DomainUsersService} from '@treasury/domain/backoffice/services/users/users-service.ts';

angular.module('backOffice').factory('usersService', usersService);

usersService.$inject = ['$q'];

function usersService($q) {
    function getUser(userId) {
        return $q((resolve, reject) => {
            DomainUsersService.getUser(userId).then(resolve).catch(reject);
        });
    }

    function getUsers() {
        return $q((resolve, reject) => {
            DomainUsersService.getUsers().then(resolve).catch(reject);
        });
    }

    function getAllUsers() {
        return $q((resolve, reject) => {
            DomainUsersService.getAllUsers().then(resolve).catch(reject);
        });
    }

    function deleteUser(userId) {
        return $q((resolve, reject) => {
            DomainUsersService.deleteUser(userId).then(resolve).catch(reject);
        });
    }

    function forgotPassword(userPayload) {
        return $q((resolve, reject) => {
            DomainUsersService.forgotPassword(userPayload).then(resolve).catch(reject);
        });
    }

    function saveUser(userPayload) {
        return $q((resolve, reject) => {
            DomainUsersService.saveUser(userPayload).then(resolve).catch(reject);
        });
    }

    function updateUser(userPayload) {
        return $q((resolve, reject) => {
            DomainUsersService.updateUser(userPayload).then(resolve).catch(reject);
        });
    }

    function resetPassword(id) {
        return $q((resolve, reject) => {
            DomainUsersService.resetPassword(id).then(resolve).catch(reject);
        });
    }

    function manualResetPassword(id, resetPasswordPayload) {
        return $q((resolve, reject) => {
            DomainUsersService.manualResetPassword(id, resetPasswordPayload)
                .then(resolve)
                .catch(reject);
        });
    }

    function unlock(userId) {
        return $q((resolve, reject) => {
            DomainUsersService.unlock(userId).then(resolve).catch(reject);
        });
    }

    function changeUserStatus(userPayload, newStatus) {
        return $q((resolve, reject) => {
            DomainUsersService.changeUserStatus(userPayload, newStatus).then(resolve).catch(reject);
        });
    }

    function getNewUserTimeAccess() {
        return $q((resolve, reject) => {
            DomainUsersService.getNewUserTimeAccess().then(resolve).catch(reject);
        });
    }

    function getActiveUsers() {
        return $q((resolve, reject) => {
            DomainUsersService.getActiveUsers().then(resolve).catch(reject);
        });
    }

    function getUserLoginTokenDetail(userId) {
        return $q((resolve, reject) => {
            DomainUsersService.getUserLoginTokenDetail(userId).then(resolve).catch(reject);
        });
    }

    function unlockSecureToken(userId, recollectToken) {
        return $q((resolve, reject) => {
            DomainUsersService.unlockSecureToken(userId, recollectToken)
                .then(resolve)
                .catch(reject);
        });
    }

    function resetSecureToken(userId) {
        return $q((resolve, reject) => {
            DomainUsersService.resetSecureToken(userId).then(resolve).catch(reject);
        });
    }

    return {
        getUser,
        getUsers,
        getAllUsers,
        getActiveUsers,
        deleteUser,
        forgotPassword,
        saveUser,
        updateUser,
        resetPassword,
        manualResetPassword,
        unlock,
        changeUserStatus,
        getNewUserTimeAccess,
        getUserLoginTokenDetail,
        resetSecureToken,
        unlockSecureToken,
    };
}
