import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('holidayExceptionsService', holidayExceptionsService);

holidayExceptionsService.$inject = ['$resource', '$http', '$q'];

function holidayExceptionsService($resource, $http, $q) {
    const resourceUrl = `${getBackOfficeAPIPath()}holidaycalendars/`;

    return {
        getHolidayExceptions,
        saveHolidayException,
        updateHolidayException,
        deleteHolidayException,
    };

    function deleteHolidayException(id) {
        return $resource(`${resourceUrl}:id`, { id }).delete().$promise;
    }

    function getHolidayExceptions() {
        return $resource(resourceUrl).query().$promise;
    }

    function updateHolidayException(id, holidayExceptionPayload) {
        const resource = $resource(`${resourceUrl}:id`, { id }, { update: { method: 'PUT' } });
        return resource.update(holidayExceptionPayload).$promise;
    }

    function saveHolidayException(holidayExceptionPayload) {
        return $resource(resourceUrl).save(holidayExceptionPayload).$promise;
    }
}
