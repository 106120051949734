angular.module('backOffice').directive('stickyPanelFooter', [
    '$timeout',
    function ($timeout) {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                fixedClass: '=',
                staticClass: '=',
                sharedClass: '=',
            },
            link: stickyPanelFooterLink,
            template: require('./stickyPanelFooterTemplate.html'),
        };

        function stickyPanelFooterLink($scope, $element, attrs) {
            $scope.showStickyFooter = false;

            const pageQuerySelector = '.page-wrapper';
            let isQueued = false;

            // update the visibility of the sticky footer when the pages state changes
            $(pageQuerySelector).on('scroll', updateFooterState);
            const unWatch = $scope.$watch(() => {
                if (!isQueued) {
                    // only do this once per angular digest
                    isQueued = true;
                    $timeout(updateFooterState, 0, false); // queue this to run af
                }
            });

            $element.on('$destroy', () => {
                $(pageQuerySelector).off('scroll', updateFooterState);
                unWatch();
            });

            function updateFooterState() {
                isQueued = false;
                const jQstaticFooter = $element.find('.jh-footer').eq(0);
                if (isElementInViewport(jQstaticFooter)) {
                    $element.find('.jh-footer').eq(1).addClass('hidden');
                } else {
                    $element.find('.jh-footer').eq(1).removeClass('hidden');
                }
            }
        }

        function isElementInViewport(el) {
            if (el instanceof jQuery) {
                el = el[0];
            }

            const rect = el.getBoundingClientRect();

            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }
    },
]);
