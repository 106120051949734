import { exists } from '@treasury/utils';

angular.module('backOffice').controller('UserVerificationController', userVerificationController);

userVerificationController.$inject = [
    '$scope',
    'accountService',
    '$location',
    'pagePushService',
    'jhaSupportService',
    'loginStateService',
    '$window',
];

function userVerificationController(
    $scope,
    accountService,
    $location,
    pagePushService,
    jhaSupportService,
    loginStateService,
    $window
) {
    $scope.loginData = null;
    $scope.message = '';
    $scope.user = {};

    $scope.login = login;
    $scope.reset = reset;

    init();

    function init() {
        $scope.loginData = {
            fiUser: '',
            loginId: '',
            password: '',
            useRefreshTokens: false,
            code: decodeURIComponent($location.search().code),
        };
        $window.sessionStorage.clear();
    }

    function login() {
        pagePushService.setUser($scope.loginData);
        jhaSupportService.setUserFiId($scope.loginData.fiId);

        // Get login information.
        $scope.loginData.userName = $scope.loginData.loginId;
        accountService
            .validateUser($scope.loginData, true)
            .then(response => {
                $scope.loginData.credential = response;
                $scope.message = accountService.checkForError({
                    statusCode: response.userCredentialStatus,
                    message: response.message,
                });

                const loginSuccessful = loginStateService.initiate(response);
                if (!loginSuccessful) {
                    $scope.message = 'User could not be verified.';
                }
            })
            .catch(err => {
                if (exists(err)) {
                    $scope.message = err instanceof Error ? err.message : err.error_description;
                }
            });
    }

    function reset() {
        $scope.loginData.fiId = null;
        $scope.loginData.loginId = null;
        $scope.form.$setPristine();
    }
}
