angular.module('backOffice').constant('wireStatusTypes', [
    { name: 'Undefined', id: 0 },
    { name: 'PendingApproval', id: 1 },
    { name: 'ApprovalRejected', id: 2 },
    { name: 'ReadyForQuote', id: 3 },
    { name: 'Transmitted', id: 4 },
    { name: 'Cancelled', id: 5 },
    { name: 'Posted', id: 6 },
    { name: 'Scheduled', id: 7 },
    { name: 'Failed', id: 8 },
    { name: 'Expired', id: 9 },
    { name: 'Deleted', id: 10 },
]);
