import { getBackOfficeAPIPath } from '@treasury/core/http';
import { NavigationRouteMap } from '../app.constants';

interface NavigationPath {
    key: keyof NavigationRouteMap;
    subMenus: NavigationPath[];
    path: string;
}

export class NavigationService {
    public static $inject = ['$resource', 'navigationRouteMap'];

    private get resourceUrl() {
        return `${getBackOfficeAPIPath()}navigations/`;
    }

    constructor(
        private $resource: ng.resource.IResourceService,
        private navigationRouteMap: NavigationRouteMap
    ) {}

    public getNavigation() {
        return this.$resource(this.resourceUrl).get().$promise;
    }

    public mapApplicationPaths(navigationList: NavigationPath[] = []) {
        if (navigationList.length < 1) {
            console.error('Attempted to map an empty navigation list.');
        }

        navigationList.forEach(navigation => {
            this.mapApplicationPath(navigation);
        });

        return navigationList;
    }

    public updateInstitution(institutionId: string) {
        const request = this.$resource(`${this.resourceUrl}updateInstitution/:institutionId`, {
            institutionId,
        });
        return request.save().$promise;
    }

    public userActivityAudit(navigation: keyof NavigationRouteMap) {
        const request = this.$resource(`${this.resourceUrl}auditUserActivity/${navigation}`);
        return request.save().$promise;
    }

    public menulessUserActivityAudit(menulessNavigationId: keyof NavigationRouteMap) {
        const request = this.$resource(
            `${this.resourceUrl}auditMenulessUserActivity/${menulessNavigationId}`
        );
        return request.save().$promise;
    }

    private mapApplicationPath(link: NavigationPath) {
        const { key, subMenus } = link;
        if (key in this.navigationRouteMap) {
            link.path = this.navigationRouteMap[key];
        }

        if (subMenus.length > 0) {
            subMenus.forEach(navigation => {
                this.mapApplicationPath(navigation);
            });
        }
    }
}
