/* eslint-disable no-use-before-define */
import { Injectable } from '@jack-henry/frontend-utils/di';
import { WindowService } from '@treasury/utils';
import { ObservationSource } from '@treasury/utils/types/observable';
import 'signalr/jquery.signalR';
import { ConfigurationService } from '../config';
import { IRealtimeService } from './realtime.service.types';

const DEFAULT_RETRIES = 5;

/**
 * Time in milliseconds to pause between retries.
 */
const RETRY_THRESHOLD = 5000;

/**
 * A service for interacting with live messages and events from the TM API in realtime.
 */
@Injectable()
export class RealtimeService implements IRealtimeService {
    constructor(
        private config: ConfigurationService,
        private window: WindowService
    ) {
        this.connected$.subscribe(isConnected => {
            this.isConnected = isConnected;

            if(isConnected){
                this.retryAttempts = 0;
                this.attemptingReconnect = false;
            } else if(this.autoReconnect) {
                this.reconnect();
            }
        });
    }

    private readonly endpoint = `${this.config.apiRoot}/signalr`;

    private readonly connectionSource = new ObservationSource<boolean>();

    public readonly connected$ = this.connectionSource.toObservable();

    private isConnected = false;

    private connection?: SignalR.Hub.Connection;

    private proxy?: SignalR.Hub.Proxy;

    private autoReconnect = false;

    /**
     * The maximum number of retries the service will attempt on a broken connection.
     */
    private maxRetries = DEFAULT_RETRIES;

    private retryAttempts = 0;

    private attemptingReconnect = false;

    private topics: string[] = [];

    public init(hubName: string, useLogging = false) {
        if (this.proxy || this.connection) {
            this.disconnect();
        }

        this.connection = $.hubConnection(this.endpoint, {
            useDefaultPath: false,
            logging: useLogging,
        });
        this.proxy = this.connection.createHubProxy(hubName);
    }

    /**
     * Begin listening for realtime events.
     * 
     * In order for this method to succeed, at least one topic must have been subscribed to.
     */
    private async connect(autoReconnect: true, maxRetries?: number): Promise<void>;
    private async connect(autoReconnect?: boolean): Promise<void>;
    private async connect(autoReconnect = true, maxRetries = DEFAULT_RETRIES) {
        if(this.isConnected){
            return Promise.resolve();
        }

        if (!this.proxy || !this.connection) {
            throw createInitError('Could not begin listening for real-time events.');
        }

        this.autoReconnect = autoReconnect;
        this.maxRetries = maxRetries;

        if(!this.proxy.hasSubscriptions()){
            // at least one listener must be present in order to start a new connection
            throw new Error('Cannot call connect() before at least one topic has been subscribed to.');
        }

        return new Promise<void>((resolve, reject) => {
            this.connection
                ?.disconnected(() => {
                    this.connectionSource.emit(false);
                })
                .start(() => {
                    this.connectionSource.emit(true);
                })
                .done(resolve)
                .fail(reject);
        });
    }

    public disconnect() {
        if (!this.connection || !this.proxy) {
            throw createInitError('Could not stop listening for real-time events.');
        }

        this.topics.forEach(t => this.proxy?.off(t));
        this.topics = [];
        this.connection.stop();
        this.connection = undefined;
        this.proxy = undefined;
    }

    /**
     * Subscribe to a `topic` and open a connection, if one hasn't already been established.
     */
    public async subscribe<T>(topic: string, callback: (message: T) => void) {
        if (!this.proxy || !this.connection) {
            throw createInitError(`Could not stop subscribe to topic "${topic}"`);
        }

        this.proxy.on(topic, callback);
        this.topics.push(topic);

        if(!this.isConnected && !this.attemptingReconnect){
            await this.connect();
        }

        return {
            unsubscribe: () => {
                this.unsubscribe(topic, callback);
            },
        };
    }

    public publish(topic: string, ...args: unknown[]) {
        if (!this.proxy) {
            throw createInitError(`Could not publish a message for "${topic}"`);
        }

        return new Promise<void>((resolve, reject) => {
            this.proxy
                ?.invoke(topic, ...args)
                .then(resolve)
                .catch(reject);
        });
    }

    private unsubscribe<T>(topic: string, callback: (message: T) => void) {
        if (!this.proxy) {
            throw createInitError(`Could not stop unsubscribe from topic "${topic}".`);
        }

        this.topics = this.topics.filter(t => t !== topic);
        this.proxy.off(topic, callback);
    }

    private async reconnect() {
        if (this.isConnected || this.attemptingReconnect) {
            return;
        }

        this.attemptingReconnect = true;

        while (this.retryAttempts < this.maxRetries && !this.isConnected) {
            this.retryAttempts++;
            // eslint-disable-next-line no-await-in-loop
            await this.window.createTimer(RETRY_THRESHOLD);
            // eslint-disable-next-line no-await-in-loop
            await this.connect(this.autoReconnect);
        }

        this.attemptingReconnect = false;

        if (this.retryAttempts >= this.maxRetries && !this.isConnected) {
            throw new Error(`Failed to reconnect to SignlarR after ${this.retryAttempts} attempts`);
        }
    }
}

function createInitError(errMessage: string) {
    return new Error(
        `${errMessage}. A connection with the server was not established. Did you call init()?`
    );
}
