import { Injectable } from '@jack-henry/frontend-utils/di';
import { CompanyuserClient as CompanyUserClient } from '@treasury/api/bo';

@Injectable()
export class CompanyUserService {
    constructor(private readonly companyUserClient: CompanyUserClient) {}

    sendEnrollmentEmail(companyId: number, userId: number) {
        this.companyUserClient.companyUserGenerateEnrollmentEmail(companyId, userId);
    }
}
