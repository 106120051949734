import { FeatureFlagService } from '@treasury/domain/services/feature-flags';

angular
    .module('backOffice')
    .controller(
        'InformationReportingEntitlementsController',
        informationReportingEntitlementsController
    );

informationReportingEntitlementsController.$inject = [
    '$scope',
    '$window',
    '$filter',
    'toaster',
    'companyUsersService',
    'ofxSettingsService',
    'jhaSupportService',
];

function informationReportingEntitlementsController(
    $scope,
    $window,
    $filter,
    toaster,
    companyUsersService,
    ofxSettingsService,
    jhaSupportService
) {
    $scope.isSuperUser = $scope.$parent.userHeader.isSuperUser;
    $scope.columnValues = [];
    $scope.searchText = '';
    $scope.entitlements = {};
    $scope.entitlementsPristine = {};
    $scope.filteredEntitlements = {};

    $scope.setForm = setForm;
    $scope.reset = reset;
    $scope.save = save;
    $scope.toggleAll = toggleAll;
    $scope.toggleColumn = toggleColumn;
    $scope.toggleEntitlement = toggleEntitlement;
    $scope.toggleRow = toggleRow;
    $scope.updateColumnToggle = updateColumnToggle;
    $scope.search = search;
    $scope.toggleOfxEnabledForUser = toggleOfxEnabledForUser;
    $scope.isUserLevelAccessEnabled = isUserLevelAccessEnabled;

    let selectedInstitution = '';

    async function init() {
        selectedInstitution = jhaSupportService.getUserFiId();
        loadEntitlements();
        getOfxEnabledForFiFlag();

        $scope.hasAccountNumberEntitlementView = await FeatureFlagService.isEnabled(
            'Feature.Channel.UseAccountName.TM6777'
        );
    }

    function getOfxEnabledForFiFlag() {
        ofxSettingsService.getOfxEnabledFlagForFI(selectedInstitution).then(response => {
            $scope.ofxEnabledForFI = response.isOfxEnabled;
            $scope.ofxUserLevelAccess = response.userLevelAccessEnabled;
            if (isUserLevelAccessEnabled()) {
                getOfxEnabledFlagForUser(selectedInstitution, $scope.$parent.userHeader.alias);
            }
        });
    }

    function setForm(form) {
        $scope.form = form;
    }

    function save() {
        const updates = companyUsersService.buildUpdateProductEntitlementsRequest(
            $scope.entitlements,
            $scope.entitlementsPristine
        );

        companyUsersService
            .updateProductEntitlements($scope.companyId, $scope.id, updates)
            .then(response => {
                $scope.entitlementsPristine = $window.angular.copy($scope.entitlements);
                $scope.$parent.notifySave();
                $scope.form.$setPristine();
                toaster.save('Reporting Entitlements');
            });
    }

    function reset() {
        $scope.entitlements = $window.angular.copy($scope.entitlementsPristine);
        $scope.form.$setPristine();
        updateFilteredEntitlements($scope.searchText);
    }

    function search(searchText) {
        $scope.searchText = searchText;
        if ($scope.entitlements) {
            updateFilteredEntitlements(searchText);
        }
    }

    // toggle events

    function updateAllColumnToggles() {
        for (let i = 0; i < $scope.columnValues.length; i++) {
            updateColumnToggle(i);
        }
    }

    function toggleEntitlement() {
        let currentValue = true;
        const { userProductClaims } = $scope.entitlements;
        $window.angular.forEach(userProductClaims, claim => {
            if (!claim.hasPendingUserClaim) {
                currentValue = false;
            }
        });

        $window.angular.forEach(userProductClaims, claim => {
            claim.hasPendingUserClaim = !currentValue;
        });

        $scope.form.$setDirty();
    }

    function toggleAll() {
        let currentValue = true;
        const accounts = $scope.filteredEntitlements;

        // Find current value.
        $window.angular.forEach(accounts, account => {
            const { userAccountClaims } = account;
            $window.angular.forEach(userAccountClaims, claim => {
                if (!claim.hasPendingUserClaim) {
                    currentValue = false;
                }
            });
        });

        // Set all values to opposite of current value.
        $window.angular.forEach(accounts, accountEntitlement => {
            const { userAccountClaims } = accountEntitlement;
            $window.angular.forEach(userAccountClaims, claim => {
                claim.hasPendingUserClaim = !currentValue;
            });
        });

        // Update column header toggles.
        for (let i = 0; i < $scope.columnValues.length; i++) {
            $scope.columnValues[i] = !currentValue;
        }

        $scope.form.$setDirty();
    }

    function toggleRow(entitlement) {
        let currentValue = true;
        const { userAccountClaims } = entitlement;

        // Find current value.
        $window.angular.forEach(userAccountClaims, claim => {
            if (!claim.hasPendingUserClaim) {
                currentValue = false;
            }
        });

        // Set all values to opposite of current value.
        $window.angular.forEach(userAccountClaims, claim => {
            claim.hasPendingUserClaim = !currentValue;
        });

        updateAllColumnToggles();
        $scope.form.$setDirty();
    }

    function toggleColumn(value, permissionIndex) {
        const accounts = $scope.filteredEntitlements;
        $window.angular.forEach(accounts, account => {
            account.userAccountClaims[permissionIndex].hasPendingUserClaim = value;
        });
        $scope.form.$setDirty();
    }

    function updateColumnToggle(index) {
        let result = true;
        const accounts = $scope.filteredEntitlements;
        $window.angular.forEach(accounts, account => {
            if (!account.userAccountClaims[index].hasPendingUserClaim) {
                result = false;
            }
        });
        $scope.columnValues[index] = result;
    }

    // Private
    function loadEntitlements() {
        companyUsersService
            .getProductEntitlements($scope.companyId, $scope.id, 'InformationReporting')
            .then(response => {
                $scope.entitlements = response;
                if (response.accountProductClaims) {
                    for (let i = 0; i < response.accountProductClaims.length; i++) {
                        $scope.columnValues.push(false);
                    }
                }
                $scope.entitlementsPristine = $window.angular.copy($scope.entitlements);
                updateFilteredEntitlements('');
            });
    }

    function updateFilteredEntitlements(searchText) {
        const allEntitlements = $scope.entitlements.accounts;
        searchText = searchText || '';
        $scope.filteredEntitlements = $filter('filter')(allEntitlements, searchText);
        updateAllColumnToggles();
    }

    function getOfxEnabledFlagForUser(ofxFiID, userName) {
        ofxSettingsService.getOfxEnabledFlagForUser(ofxFiID, userName).then(response => {
            $scope.ofxEnabledForUser = response.isOfxEnabledForUser;
        });
    }

    function isUserLevelAccessEnabled() {
        return $scope.ofxEnabledForFI && $scope.ofxUserLevelAccess;
    }

    function toggleOfxEnabledForUser() {
        const ofxUserEnabled = !$scope.ofxEnabledForUser;
        const userName = $scope.$parent.userHeader.alias;

        ofxSettingsService
            .updateOfxEnabledFlagForUser(selectedInstitution, userName, ofxUserEnabled)
            .then(response => {
                if (response && response.success) {
                    $scope.ofxEnabledForUser = response.isOfxEnabledForUser;
                }
            });
    }

    init();
}
