angular.module('backOffice').directive('supportEnabled', [
    'entitlementsService',
    function (entitlementsService) {
        return {
            link(scope, element) {
                function checkEntitlement() {
                    if (entitlementsService.hasEntitlement('Switch FI')) {
                        element.addClass('support-enabled');
                    } else {
                        element.removeClass('support-enabled');
                    }
                }

                checkEntitlement();

                scope.$on('entitlementsSet', checkEntitlement);
            },
        };
    },
]);
