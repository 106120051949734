import { DiContainer } from '@jack-henry/frontend-utils/di';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { OmegaDialogExitReason, OmegaDialogService } from '@treasury/omega/services';
import { html } from 'lit';

angular
    .module('backOffice')
    .controller('DashboardConfigurationController', dashboardConfigurationController);

dashboardConfigurationController.$inject = [
    '$scope',
    'informationCenterConfigurationService',
    'toaster',
    '$modal',
    'resourcePanelConfigurationService',
    '$timeout',
    'entitlementsService',
    'TmDi',
    '$q',
];

/**
 * @param { ng.IScope } $scope
 * @param { import('@jack-henry/frontend-utils/di').DiContainer } TmDi
 * @param { ng.IQService } $q
 */
function dashboardConfigurationController(
    $scope,
    informationCenterConfigurationService,
    toaster,
    $modal,
    resourcePanelConfigurationService,
    $timeout,
    entitlementsService,
    TmDi,
    $q
) {
    const allTabs = {
        informationCenter: {
            entitlements: [
                'Edit Information Center Configuration',
                'View Information Center Configuration',
                'Information Center Configuration Add Message',
                'Switch FI',
            ],
            text: 'Information Center Configuration',
        },
        resources: {
            entitlements: [
                'Edit Resources Panel Configuration',
                'View Resource Panel Configuration',
                'Resources Panel Configuration Add Link',
                'Switch FI',
            ],
            text: 'Resources Panel Configuration',
        },
    };

    $scope.informationCenterConfigurationList = [];
    $scope.resourcePanelConfigurationList = [];
    $scope.availableTabs = [];
    $scope.selectedTab = null;
    $scope.editInformationCenterLinkText = entitlementsService.hasEntitlement(
        'Edit Information Center Configuration'
    )
        ? 'Edit'
        : 'View';
    $scope.editResourcePanelLinkText = entitlementsService.hasEntitlement(
        'Edit Resources Panel Configuration'
    )
        ? 'Edit'
        : 'View';

    $scope.selectTab = selectTab;
    $scope.showInformationCenterLink = showInformationCenterLink;
    $scope.showResourcePanelLink = showResourcePanelLink;
    $scope.linkIsActive = linkIsActive;
    $scope.showAddMessageButton = showAddMessageButton;
    $scope.showAddLinkButton = showAddLinkButton;

    function selectTab(tabName) {
        $scope.selectedTab = tabName;
    }

    $scope.manageResourcePanelConfiguration = function (resourcePanelConfiguration) {
        if (linkIsActive(resourcePanelConfiguration)) return;

        const modalInstance = $modal.open({
            template: require('../views/manageLink.html'),
            size: 'md',
            controller: 'ManageLinkController',
            backdrop: 'static',
            resolve: {
                selectedLink() {
                    return angular.copy(resourcePanelConfiguration);
                },
            },
        });
        modalInstance.result.then(() => {
            loadResourcePanelConfiguration();
        });
    };

    $scope.manageInformationCenterConfiguration = function (informationCenterConfiguration) {
        if (linkIsActive(informationCenterConfiguration)) return;

        const modalInstance = $modal.open({
            template: require('../views/manageMessage.html'),
            size: 'md',
            controller: 'ManageMessageController',
            backdrop: 'static',
            resolve: {
                selectedMessage() {
                    return angular.copy(informationCenterConfiguration);
                },
            },
        });
        modalInstance.result.then(() => {
            loadInformationCenterConfiguration();
        });
    };

    $scope.resequence = function (item, type, direction) {
        if (type === 'informationCenterConfiguration') {
            informationCenterConfigurationService
                .changeInformationCenterConfigurationSequence(item.id, direction)
                .then(response => {
                    toaster.save('Sequence Update');
                    $scope.informationCenterConfigurationList = response;
                });
        }
        if (type === 'resourcePanelConfiguration') {
            resourcePanelConfigurationService
                .changeResourcePanelConfigurationSequence(item.id, direction)
                .then(response => {
                    toaster.save('Sequence Update');
                    $scope.resourcePanelConfigurationList = response;
                });
        }
    };

    $scope.isValidDateToChangeStatus = function (startDate, status) {
        let result = true;
        if (status === 'Inactive') {
            const currentDate = new Date($scope.currentDate);
            const _startDate = new Date(formatDate(startDate));
            if (_startDate > currentDate) {
                result = false;
            }
        }
        return result;
    };

    function formatDate(dt) {
        const date = new Date(dt);
        let dd = date.getDate();
        if (dd < 10) {
            dd = `0${dd}`;
        }
        let mm = date.getMonth() + 1; // January is 0!
        if (mm < 10) {
            mm = `0${mm}`;
        }
        const yyyy = date.getFullYear();
        return `${mm}/${dd}/${yyyy}`;
    }

    async function getDialogService() {
        return (await DiContainer.getInstance()).get(OmegaDialogService);
    }

    async function renderOnlyOneDialog(messageType) {
        const dialogService = await getDialogService();
        const lower = messageType.toLowerCase();
        const dialogHandle = dialogService.open(
            html`<div>
                Only one “${lower}” message can be active at a time. If you would like to activate a
                different “${lower}” message, set the existing active “${lower}” message to inactive
                and try again.
            </div>`,
            `There can be only one “${messageType}” message.`,
            {
                width: 400,
                buttons: {
                    [OmegaDialogExitReason.Cancel]: null,
                    [OmegaDialogExitReason.Confirm]: { label: 'OK' },
                },
            }
        );
        return $q.resolve(dialogHandle.closed);
    }

    function isValid(item) {
        if (item.type !== 'Banner' && item.type !== 'Modal') return true;
        const allMessages = $scope.informationCenterConfigurationList;
        const filteredMessages = allMessages.filter(x => x.id !== item.id);
        if (item.status === 'Inactive') return true;
        return !filteredMessages.some(x => x.type === item.type && x.status === 'Active');
    }

    $scope.changeStatus = async function (item, type) {
        item.status = item.status === 'Inactive' ? 'Active' : 'Inactive';
        if (type === 'informationCenterConfiguration') {
            const valid = isValid(item);
            if (valid) {
                $timeout(
                    informationCenterConfigurationService
                        .updateInformationCenterConfiguration(item)
                        .then(() => {
                            toaster.save('Message Status');
                            loadInformationCenterConfiguration();
                        }),
                    5000
                );
            } else {
                if (item.status === 'Active') {
                    renderOnlyOneDialog(item.type);
                }
                item.status = 'Inactive';
            }
        }
        if (type === 'resourcePanelConfiguration') {
            resourcePanelConfigurationService.updateResourcePanelConfiguration(item).then(() => {
                toaster.save('Link Status');
                $timeout(() => loadResourcePanelConfiguration(), 250);
                loadResourcePanelConfiguration();
            });
        }
    };

    async function getFilteredList(response) {
        const ffService = await TmDi.getAsync(FeatureFlagService);
        const isFeatureEnabled = await ffService.isEnabled(Feature.MessageCenterEnhancement);

        if (isFeatureEnabled) {
            return response;
        }

        const filteredList = [];
        response.forEach(item => {
            if (item.type !== 'Banner' && item.type !== 'Modal') {
                filteredList.push(item);
            }
        });

        return filteredList;
    }

    async function loadInformationCenterConfiguration() {
        const response = await informationCenterConfigurationService.getInformationCenterConfigurations();
        $scope.informationCenterConfigurationList = await getFilteredList(response);
    }

    function loadResourcePanelConfiguration() {
        resourcePanelConfigurationService.getResourcePanelConfigurations().then(response => {
            $scope.resourcePanelConfigurationList = response;
        });
    }

    function showInformationCenterLink() {
        return entitlementsService.hasAnyEntitlement(
            'Edit Information Center Configuration',
            'View Information Center Configuration',
            'Switch FI'
        );
    }

    function showResourcePanelLink() {
        return entitlementsService.hasAnyEntitlement(
            'Edit Resources Panel Configuration',
            'View Resource Panel Configuration',
            'Switch FI'
        );
    }

    function linkIsActive(link) {
        return link.status === 'Active';
    }

    function initializeTabs() {
        Object.getOwnPropertyNames(allTabs).forEach(tabId => {
            const tab = allTabs[tabId];
            if (entitlementsService.hasAnyEntitlement(tab.entitlements)) {
                $scope.availableTabs.push({
                    id: tabId,
                    text: tab.text,
                });
            }
        });

        if ($scope.availableTabs.length === 1) {
            $scope.selectedTab = $scope.availableTabs[0].id;
        } else {
            $scope.selectedTab = 'informationCenter';
        }
    }

    function showAddMessageButton() {
        return entitlementsService.hasAnyEntitlement(
            'Information Center Configuration Add Message',
            'Switch FI'
        );
    }

    function showAddLinkButton() {
        return entitlementsService.hasAnyEntitlement(
            'Resources Panel Configuration Add Link',
            'Switch FI'
        );
    }

    async function init() {
        initializeTabs();
        if (entitlementsService.hasAnyEntitlement(allTabs.informationCenter.entitlements)) {
            await loadInformationCenterConfiguration();
        }
        if (entitlementsService.hasAnyEntitlement(allTabs.resources.entitlements)) {
            await loadResourcePanelConfiguration();
        }
        const today = new Date();
        const dd = today.getDate();
        const mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        $scope.currentDate = formatDate(`${mm}/${dd}/${yyyy}`);
    }

    init();
}
