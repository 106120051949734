import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';

angular
    .module('backOffice')
    .controller('UserInformationPanelController', userInformationPanelController);

userInformationPanelController.$inject = [
    '$scope',
    '$uibModal',
    'modalService',
    '$state',
    'companyUsersService',
    'toaster',
];

function userInformationPanelController(
    $scope,
    $uibModal,
    modalService,
    $state,
    companyUsersService,
    toaster
) {
    $scope.canDelete = canDelete;
    $scope.deleteUser = deleteUser;
    $scope.formatPhoneNumber = formatPhoneNumber;
    $scope.isFormLoaded = false;
    $scope.reset = reset;
    $scope.save = save;
    $scope.copyUser = copyUser;
    $scope.copiedUser = $scope.$parent.copyUser;
    $scope.userId = $scope.$parent.id;

    $scope.setForm = setForm;
    $scope.tempNumberHolder = {};
    $scope.userInformation = {};
    $scope.userInformationPristine = {};
    $scope.userInformationPending = {};
    $scope.userInformationActual = {};
    $scope.errorMessage = '';
    $scope.isUisEnabled = false;

    $scope.$watch('compareMode', updateFormModel);
    $scope.$watch('editMode', updateFormModel);
    $scope.$watch('userHeader', updateFormModel);
    $scope.$watch('tempNumberHolder', loadPhoneAndFax, true);
    $scope.$watch('userInformationActual', updateFormModel);
    $scope.$watch('userInformationPending', updateFormModel);

    function loadCopiedUser() {
        $scope.userInformation.copyUserId = $scope.copiedUser.id;
        $scope.userInformation.isSuperUser = $scope.copiedUser.isSuperUser;
        $scope.userInformation.isAdmin = $scope.copiedUser.isAdmin;
    }

    function setForm(form) {
        $scope.form = form;
    }

    function save() {
        companyUsersService
            .saveUserInformation($scope.companyId, $scope.$parent.id, $scope.userInformation)
            .then(response => {
                if (response.success) {
                    toaster.save('User Information');
                    $scope.errorMessage = '';
                    if ($scope.$parent.id == undefined || $scope.$parent.id <= 0) {
                        $state.go('user-information-save', {
                            companyId: $scope.company.id,
                            id: response.userId,
                        });
                    } else {
                        $scope.$parent.setId(response.userId);
                        $scope.$parent.notifySave();
                        loadUserInformation(response.userId);
                        $scope.form.$setPristine();
                    }
                } else {
                    $scope.errorMessage = response.errorMessage;
                }
            });
    }

    function copyUser() {
        const modalInstance = $uibModal.open({
            template: require('../views/copyUserModalView.html'),
            size: 'md',
            controller: 'CopyUserModalController',
            backdrop: 'static',
            resolve: {
                companyId() {
                    return $scope.companyId;
                },
            },
        });
        modalInstance.result.then(response => {
            $scope.copiedUser = response;
            $scope.$parent.updateCopyUser(response);
            loadCopiedUser();
            $scope.form.$setDirty();
        });
    }

    function reset() {
        if ($scope.userId === 0) {
            $scope.copiedUser = null;
            $scope.$parent.updateCopyUser(null);
        }
        $scope.userInformationPending = window.angular.copy($scope.userInformationPristine);
        manipulateData();
        $scope.form.$setPristine();
    }

    function formatPhoneNumber(value) {
        if (value) {
            return `${value.substr(0, 3)}-${value.substr(3, 3)}-${value.substr(6, 4)}`;
        }
        return '';
    }

    function canDelete() {
        return $scope.$parent.userHeader.isEnrolled;
    }

    function deleteUser() {
        const modalOptions = {
            headerText: `Delete ${$scope.userInformation.name}`,
            closeButtonText: 'Cancel',
            actionButtonText: 'Delete',
            bodyText: 'Are you sure you would like to delete this user?',
            submit(result) {
                modalInstance.close(result);
            },
        };
        const modalInstance = modalService.showModal({}, modalOptions);
        modalInstance.result.then(() => {
            companyUsersService.deleteUser($scope.companyId, $scope.$parent.id).then(() => {
                modalInstance.close();
                $state.go('company', { id: $scope.companyId });
            });
        });
    }

    // Events
    function loadPhoneAndFax(newValue) {
        if ($scope.isFormLoaded) {
            if (
                newValue.phone1 !== null &&
                newValue.phone1 !== undefined &&
                newValue.phone1.length === 3 &&
                isNaN(newValue.phone1) === false
            ) {
                $scope.tempNumberHolder.phone1Valid = true;
            } else {
                $scope.tempNumberHolder.phone1Valid = false;
            }
            if (
                newValue.phone2 !== null &&
                newValue.phone2 !== undefined &&
                newValue.phone2.length === 3 &&
                isNaN(newValue.phone2) === false
            ) {
                $scope.tempNumberHolder.phone2Valid = true;
            } else {
                $scope.tempNumberHolder.phone2Valid = false;
            }
            if (
                newValue.fax1 !== null &&
                newValue.fax1 !== undefined &&
                newValue.fax1.length === 3 &&
                isNaN(newValue.fax1) === false
            ) {
                $scope.tempNumberHolder.fax1Valid = true;
            } else {
                $scope.tempNumberHolder.fax1Valid = false;
            }
            if (
                newValue.fax2 !== null &&
                newValue.fax2 !== undefined &&
                newValue.fax2.length === 3 &&
                isNaN(newValue.fax2) === false
            ) {
                $scope.tempNumberHolder.fax2Valid = true;
            } else {
                $scope.tempNumberHolder.fax2Valid = false;
            }
        }
        if (
            newValue.phone1 !== null &&
            newValue.phone1 !== undefined &&
            newValue.phone1.length === 3 &&
            newValue.phone2 !== null &&
            newValue.phone2 !== undefined &&
            newValue.phone2.length === 3 &&
            newValue.phone3 !== null &&
            newValue.phone3 !== undefined &&
            newValue.phone3.length === 4
        ) {
            $scope.userInformation.phone =
                newValue.phone1.toString() +
                newValue.phone2.toString() +
                newValue.phone3.toString();
        }
        if (
            newValue.fax1 !== null &&
            newValue.fax1 !== undefined &&
            newValue.fax1.length === 3 &&
            newValue.fax2 !== null &&
            newValue.fax2 !== undefined &&
            newValue.fax2.length === 3 &&
            newValue.fax3 !== null &&
            newValue.fax3 !== undefined &&
            newValue.fax3.length === 4
        ) {
            $scope.userInformation.fax =
                newValue.fax1.toString() + newValue.fax2.toString() + newValue.fax3.toString();
        } else {
            $scope.userInformation.fax = null;
        }
    }

    function updateFormModel() {
        if ($scope.userInformationPending && $scope.userInformationActual) {
            if ($scope.showPending) {
                $scope.userInformation = $scope.userInformationPending;
                if ($scope.copiedUser) {
                    loadCopiedUser();
                }
            } else {
                $scope.userInformation = $scope.userInformationActual;
            }
        }
    }

    // Private
    function loadUserInformation() {
        companyUsersService
            .getUserInformation($scope.companyId, $scope.$parent.id)
            .then(response => {
                $scope.userInformationActual = response.user;
                $scope.userInformationPending = response.pendingUser;
                manipulateData();
                $scope.userInformationPristine = window.angular.copy($scope.userInformationPending);
            });
    }

    function manipulateData() {
        // used to break up phone/fax
        if ($scope.userInformationPending) {
            if (
                $scope.userInformationPending.phone !== null &&
                $scope.userInformationPending.phone !== undefined
            ) {
                $scope.tempNumberHolder.phone1 = $scope.userInformationPending.phone.substring(
                    0,
                    3
                );
                $scope.tempNumberHolder.phone2 = $scope.userInformationPending.phone.substring(
                    3,
                    6
                );
                $scope.tempNumberHolder.phone3 = $scope.userInformationPending.phone.substring(
                    6,
                    10
                );
            } else {
                $scope.tempNumberHolder.phone1 = '';
                $scope.tempNumberHolder.phone2 = '';
                $scope.tempNumberHolder.phone3 = '';
            }
            if (
                $scope.userInformationPending.fax !== null &&
                $scope.userInformationPending.fax !== undefined
            ) {
                $scope.tempNumberHolder.fax1 = $scope.userInformationPending.fax.substring(0, 3);
                $scope.tempNumberHolder.fax2 = $scope.userInformationPending.fax.substring(3, 6);
                $scope.tempNumberHolder.fax3 = $scope.userInformationPending.fax.substring(6, 10);
            } else {
                $scope.tempNumberHolder.fax1 = '';
                $scope.tempNumberHolder.fax2 = '';
                $scope.tempNumberHolder.fax3 = '';
            }
        }

        $scope.isFormLoaded = true;
    }

    (async function () {
        // init
        $scope.isUisEnabled = await FeatureFlagService.isEnabled(Feature.UisEnabled);
        $scope.tempNumberHolder = {
            phone1: null,
            phone2: null,
            phone3: null,
            phone1Valid: false,
            phone2Valid: false,
            fax1: null,
            fax1Valid: false,
            fax2: null,
            fax2Valid: false,
            fax3: null,
        };

        if ($scope.$parent.id && $scope.$parent.id !== 0) {
            loadUserInformation();
        }
        if ($scope.copiedUser) {
            loadCopiedUser();
        }
        $scope.isFormLoaded = true;
    })();
}
