/* eslint-disable no-use-before-define */
/* eslint-disable global-require */
// cspell:ignore positivepay
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';

function productFeatureConfigurationController(
    $scope,
    $state,
    $modal,
    modalService,
    toaster,
    entitlementsService,
    configurationService
) {
    function loadProductFeatures() {
        configurationService.getFiProductFeatures().then(async data => {
            $scope.productFeaturesList = $scope.isAgileticsWebEscrowActive
                ? data
                : data.filter(datum => datum.name !== 'Agiletics Web Escrow');
        });
    }

    async function init() {
        $scope.settingsMenu = [
            {
                title: 'Billing',
                location: 'account-analysis-configuration-institution',
            },
        ];

        $scope.isAgileticsWebEscrowActive = await FeatureFlagService.isEnabled(
            'Feature.Product.EnableAgiletics'
        );
        loadProductFeatures();
    }

    init();

    function changeProductFeatureStatus(productFeature) {
        const message = 'Are you sure you want to change the product feature status?';
        const actionButtonText = 'Yes';
        const closeButtonText = 'No';

        const modalOptions = {
            bodyText: message,
            actionButtonText,
            closeButtonText,
            submit(result) {
                // change status
                const newStatus = productFeature.status === 'Inactive' ? 'Active' : 'Inactive';
                configurationService
                    .changeProductFeaturesStatus(productFeature.id, newStatus)
                    .then(response => {
                        productFeature.status = response.status;
                        toaster.save('Product Feature Status');
                    });
                $modalInstance.close(result);
            },
        };
        let $modalInstance = modalService.showModal({}, modalOptions);
    }

    function showProductFeatureDetails(product) {
        switch (product.type) {
            case 'FiInformationReportingProductFeature':
                configureInformationReporting(product);
                break;
            case 'FiAccountReconProductFeature':
                configureAccountReconciliationReports(product);
                break;
            case 'FiWireTransferProductFeature':
                configureWireTransfer(product);
                break;
            case 'FiWireTransferDliProductFeature':
                configureWireTransferDli(product);
                break;
            case 'CorpayWireProductFeature':
                configureWireTransferCorpay(product);
                break;
            case 'FiInternalTransferProductFeature':
                configureInternalTransfer(product);
                break;
            case 'FiArpProductFeature':
                configurePositivePay(product);
                break;
            case 'FiAchProductFeature':
                configureAch(product);
                break;
            case 'FiStopPaymentProductFeature':
                configureStopPayment(product);
                break;
            case 'FiElectronicDocumentProductFeature':
                configureElectronicDocuments(product);
                break;
            case 'FiBillPayProductFeature':
                configureBillPay(product);
                break;
            case 'FiRemoteDepositCaptureSsoProductFeature':
                configureRdcSso(product);
                break;
            case 'WausauStatements':
                configureWausauStatements(product);
                break;
            case 'BDIeStatements':
                configureBDIeStatements(product);
                break;
            case 'AgileticsSso':
                configureAgileticsWebEscrow(product);
                break;
            default:
                $state.go('company-search');
        }
    }

    function hasEntitlement() {
        return entitlementsService.hasEntitlement('Edit Product Feature Configuration');
    }
    function hasEntitlementOrSupport() {
        return entitlementsService.hasAnyEntitlement(
            'Edit Product Feature Configuration',
            'Switch FI'
        );
    }

    function navigate(location) {
        $state.go(location.toLowerCase());
    }

    function configurePositivePay(product) {
        $state.go('positivepay-configuration-fi', {
            productFeatureId: product.id,
            productFeatureStatus: product.status,
            canEdit: hasEntitlement(),
        });
    }

    function configureInformationReporting(product) {
        $state.go('reporting-configuration', {
            productFeatureId: product.id,
            productFeatureStatus: product.status,
            canEdit: hasEntitlement(),
        });
    }

    function configureAccountReconciliationReports(product) {
        $state.go('account-reconciliation-reports-configuration', {
            productFeatureId: product.id,
            productFeatureStatus: product.status,
            canEdit: hasEntitlement(),
        });
    }

    function configureAch(product) {
        $state.go('ach-configuration', {
            productFeatureId: product.id,
            productFeatureStatus: product.status,
            canEdit: hasEntitlement(),
        });
    }

    function configureInternalTransfer(product) {
        $modal.open({
            template: require('../views/configureInternalTransfer.html'),
            size: 'lg',
            controller: 'ConfigureInternalTransferController',
            backdrop: 'static',
            resolve: {
                productFeatureId() {
                    return product.id;
                },
                productFeatureStatus() {
                    return product.status;
                },
                canEdit() {
                    return hasEntitlement();
                },
            },
        });
    }

    function configureWireTransfer(product) {
        $modal.open({
            template: require('../views/configureWireTransfer.html'),
            size: 'lg',
            controller: 'ConfigureWireTransferController',
            backdrop: 'static',
            resolve: {
                productFeatureId() {
                    return product.id;
                },
                productFeatureStatus() {
                    return product.status;
                },
                canEdit() {
                    return hasEntitlement();
                },
            },
        });
    }

    function configureWireTransferDli(product) {
        $modal.open({
            template: require('../views/configureWireTransferDli.html'),
            size: 'lg',
            controller: 'ConfigureWireTransferDliController',
            backdrop: 'static',
            resolve: {
                productFeatureId() {
                    return product.id;
                },
                productFeatureStatus() {
                    return product.status;
                },
                canEdit() {
                    return hasEntitlement();
                },
            },
        });
    }
    function configureWireTransferCorpay(product) {
        $modal.open({
            template: require('../views/configureWireTransferCorpay.html'),
            size: 'md',
            controller: 'ConfigureWireTransferCorpayController',
            backdrop: 'static',
            resolve: {
                canEdit() {
                    return hasEntitlement();
                },
            },
        });
    }

    function configureStopPayment(product) {
        $modal.open({
            template: require('../views/configureStopPaymentView.html'),
            size: 'md',
            controller: 'ConfigureStopPaymentController',
            backdrop: 'static',
            resolve: {
                productFeatureId() {
                    return product.id;
                },
                productFeatureStatus() {
                    return product.status;
                },
                canEdit() {
                    return hasEntitlement();
                },
            },
        });
    }

    function configureElectronicDocuments(product) {
        $modal.open({
            template: require('../views/configureElectronicDocuments.html'),
            size: 'md',
            controller: 'ConfigureElectronicDocumentsController',
            backdrop: 'static',
            resolve: {
                productFeatureId() {
                    return product.id;
                },
                productFeatureStatus() {
                    return product.status;
                },
                canEdit() {
                    return hasEntitlement();
                },
            },
        });
    }

    function configureBillPay(product) {
        $modal.open({
            template: require('../views/configureBillPayView.html'),
            size: 'md',
            controller: 'ConfigureBillPayController',
            backdrop: 'static',
            resolve: {
                productFeatureId() {
                    return product.id;
                },
                productFeatureStatus() {
                    return product.status;
                },
                canEdit() {
                    return hasEntitlement();
                },
            },
        });
    }

    function configureRdcSso(product) {
        $modal.open({
            template: require('../views/configureRemoteDepositCaptureSso.html'),
            size: 'md',
            controller: 'ConfigureRemoteDepositCaptureSsoController as vm',
            backdrop: 'static',
            resolve: {
                institutionProductTypeId() {
                    return product.id;
                },
            },
        });
    }

    function configureWausauStatements(product) {
        $modal.open({
            template: require('../views/configureWausauStatements.html'),
            size: 'md',
            controller: 'ConfigureWausauStatementsController',
            backdrop: 'static',
            resolve: {
                productFeatureId() {
                    return product.id;
                },
                productFeatureStatus() {
                    return product.status;
                },
                canEdit() {
                    return hasEntitlement();
                },
            },
        });
    }

    function configureBDIeStatements(product) {
        $modal.open({
            template: require('../views/configureBDIeStatements.html'),
            size: 'md',
            controller: 'ConfigureBDIeStatementsController',
            backdrop: 'static',
            resolve: {
                productFeatureId() {
                    return product.id;
                },
                productFeatureStatus() {
                    return product.status;
                },
                canEdit() {
                    return hasEntitlement();
                },
            },
        });
    }

    async function configureAgileticsWebEscrow(product) {
        $modal.open({
            template: require('../views/configureAgileticsWebEscrow.html'),
            size: 'md',
            controller: 'ConfigureAgileticsWebEscrow',
            backdrop: 'static',
            resolve: {
                productFeatureId() {
                    return product.id;
                },
                productDisplayName() {
                    return product.productDisplayName;
                },
                canEdit() {
                    return hasEntitlement();
                },
            },
        });
    }

    $scope.productFeaturesList = [];
    $scope.changeProductFeatureStatus = changeProductFeatureStatus;
    $scope.showProductFeatureDetails = showProductFeatureDetails;
    $scope.hasEntitlement = hasEntitlement;
    $scope.hasEntitlementOrSupport = hasEntitlementOrSupport;
    $scope.navigate = navigate;
}

productFeatureConfigurationController.$inject = [
    '$scope',
    '$state',
    '$modal',
    'modalService',
    'toaster',
    'entitlementsService',
    'configurationService',
];
window.angular
    .module('backOffice')
    .controller('ProductFeatureConfigurationController', productFeatureConfigurationController);
