angular
    .module('backOffice')
    .controller(
        'ReCollectUserChallengeInformationController',
        reCollectUserChallengeInformationController
    );

reCollectUserChallengeInformationController.$inject = [
    '$scope',
    'companiesService',
    'userId',
    '$modalInstance',
    'toaster',
    'companyUsersService',
    'companyId',
];

function reCollectUserChallengeInformationController(
    $scope,
    companiesService,
    userId,
    $modalInstance,
    toaster,
    companyUsersService,
    companyId
) {
    $scope.userId = userId;
    $scope.reCollect = reCollect;
    $scope.companyId = companyId;

    // we watch for form.$dirty, this is used to alert the end user that they changed something in the form.
    $scope.$watch('form.$dirty', checkDirty);

    $scope.cancel = cancel;
    $scope.setForm = setForm;

    // this will dismiss the window.
    function cancel() {
        $modalInstance.dismiss();
    }

    function setForm(form) {
        $scope.form = form;
    }

    function reCollect() {
        companyUsersService
            .reCollectUserChallengeInformation($scope.companyId, $scope.userId)
            .then(response => {
                $modalInstance.close(response);
            });
    }

    // events
    function checkDirty(isDirty) {
        $modalInstance.isDirty = isDirty;
    }
}
