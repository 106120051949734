/* eslint-disable no-use-before-define */
import { CompanyPositivePayServices } from '@treasury/domain/backoffice/requests/company-configuration/company-positive-pay.js';
import InstitutionPositivePayRequests from '@treasury/domain/backoffice/requests/institution-configuration/institution-positive-pay-requests.js';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { companyId } from '@treasury/policy';

angular.module('backOffice').controller('UserInformationController', userInformationController);

/**
 * @param { import('@jack-henry/frontend-utils/di').DiContainer } TmDi
 */
function userInformationController(
    $scope,
    $state,
    $stateParams,
    modalService,
    $modal,
    companyUsersService,
    entitlementsService,
    TmDi
) {
    // view scope
    $scope.company = $stateParams.company ? $stateParams.company : {};
    $scope.companyId =
        $stateParams.companyId && $stateParams.companyId !== '' ? $stateParams.companyId : 0;
    $scope.editMode = $stateParams.view !== 'true';
    $scope.compareMode = false;
    $scope.showPending = false;
    $scope.showFlags = false;
    $scope.hasWireTransfersDomestic = hasWireTransfersDomestic;
    $scope.hasWireTransfersInternational = hasWireTransfersInternational;
    $scope.isDraft = isDraft;
    $scope.isEditable = isEditable;
    $scope.isNew = isNew;
    $scope.isPendingApproval = isPendingApproval;
    $scope.isPendingApprovalAndApprover = isPendingApprovalAndApprover;
    $scope.isPendingApprovalAndNonApprover = isPendingApprovalAndNonApprover;
    $scope.canSubmit = canSubmit;
    $scope.getHeadingText = getHeadingText;
    $scope.getNavHeaderClass = getNavHeaderClass;
    $scope.isSelected = isSelected;
    $scope.navSectionIsCollapsed = navSectionIsCollapsed;
    $scope.canUnlockUser = canUnlockUser;
    $scope.userError = '';
    $scope.updateCopyUser = updateCopyUser;
    $scope.fiPending = 'FiPending';
    $scope.edited = 'Edited';
    $scope.pending = 'Pending';
    $scope.new = 'New';
    $scope.isACHFilterEnabled = false;
    $scope.isUisEnabled = false;

    // scope objects
    $scope.id = $stateParams.id && $stateParams.id !== '' ? $stateParams.id : 0;
    if (!$scope.id) {
        $scope.copyUser = $stateParams.copyUser;
    }
    $scope.sidebar = {};
    $scope.userHeader = {};
    $scope.productFeatureSummary = {};
    $scope.selectedSubview = $stateParams.subview ? $stateParams.subview : 'userInformation';
    $scope.wireTabName = 'Domestic';
    $scope.arpTabName = 'ArpExceptions';

    // events
    $scope.unlockOob = unlockOob;
    $scope.setArpTabName = setArpTabName;
    $scope.setWireTabName = setWireTabName;
    $scope.approveOrReject = approveOrReject;
    $scope.submit = submit;
    $scope.toggleSection = toggleSection;
    $scope.unlock = unlock;
    $scope.notifySave = notifySave;
    $scope.backToUserList = backToUserList;
    $scope.discardChanges = discardChanges;
    $scope.edit = edit;
    $scope.compare = compare;
    $scope.select = select;
    $scope.setId = setId;
    $scope.$watch('userHeader', updateData);
    $scope.$watch('editMode', updateData);
    $scope.$watch('compareMode', updateData);

    function updateData() {
        if ($scope.userHeader) {
            $scope.showFlags = $scope.userHeader.isEnrolled && $scope.compareMode;
            if ($scope.userHeader.isEnrolled) {
                if ($scope.compareMode || $scope.editMode) {
                    $scope.showPending = true;
                } else {
                    $scope.showPending = false;
                }
            } else {
                $scope.showPending = true;
            }
        }
    }

    async function getAchFilterSettings() {
        const fiOptions = await InstitutionPositivePayRequests.getConfiguration();
        const isFiAchFilterEnabled = fiOptions.some(
            option =>
                option.name === 'PositivePay.ACHFilterManagement.AllowManageAchFilters' &&
                option.value === '1'
        );
        const companyOptions = await CompanyPositivePayServices.getConfiguration($scope.companyId);
        const isCompanyAchFilterEnabled = companyOptions.some(
            option =>
                option.name === 'PositivePay.ACHFilterManagement.AllowManageAchFilters' &&
                option.value === '1'
        );

        return (
            entitlementsService.hasAnyEntitlement('Feature.PositivePay.ACHFilterManagement') &&
            isFiAchFilterEnabled &&
            isCompanyAchFilterEnabled
        );
    }
    // Child interface
    function notifySave() {
        loadUserHeader();
        loadUserProductFeatureSummary();
    }

    // View interface
    function isSelected(subviewName) {
        return $scope.selectedSubview === subviewName;
    }

    function select(subviewName) {
        $scope.selectedSubview = subviewName;
    }

    function getNavHeaderClass(type) {
        if (navSectionIsCollapsed(type)) {
            return 'fa-chevron-down';
        }
        return 'fa-chevron-up';
    }

    function updateCopyUser(user) {
        $scope.copyUser = user;
    }

    function navSectionIsCollapsed(type) {
        return $scope.sidebar[`${type}Collapsed`];
    }

    function toggleSection(type) {
        $scope.sidebar[`${type}Collapsed`] = !$scope.sidebar[`${type}Collapsed`];
    }

    function getHeadingText() {
        if (!$scope.id) {
            return 'Create New User';
        }
        if ($scope.userHeader && $scope.userHeader.name) {
            return $scope.userHeader.name;
        }
    }

    function canSubmit() {
        return (
            !isPendingApproval() &&
            $scope.userHeader.hasPendingChanges &&
            (entitlementsService.hasEntitlement('Add User') ||
                entitlementsService.hasEntitlement('Edit User'))
        );
    }
    function edit() {
        $scope.compareMode = false;
        $scope.editMode = true;
        $state.go(
            $state.$current.name,
            { companyId: $scope.companyId, id: $scope.id, view: true },
            { location: 'replace', inherit: false, notify: false }
        );
    }

    function compare() {
        $scope.compareMode = !$scope.compareMode;
    }

    function submitOneUser(modalInstance) {
        $scope.userError = '';
        return companyUsersService.submitUser($scope.companyId, $scope.id).then(response => {
            if (response) {
                modalInstance.close('submitOneUser');
                
                $state.go('user-information-create-user', {
                    companyId: $scope.companyId,
                    company: $scope.company,
                }, { reload: true })
            } else {
                $scope.userError = 'An error occurred during submit.';
            }
        });
    }

    async function submit() {
        const ffService = await TmDi.getAsync(FeatureFlagService);
        const showAddAnotherUserButton = await ffService.isEnabled(Feature.AddAnotherUserButton);
        const modalOptions = {
            closeButtonText: 'Cancel',
            actionButtonText: 'Submit User',
            secondaryActionButtonText: showAddAnotherUserButton ? 'Submit User and Add Another' : '',
            secondaryAction: showAddAnotherUserButton ? () => submitOneUser(modalInstance) : undefined,
            headerText: 'Confirm Submission',
            bodyText: 'Are you sure you want to submit this user?',
            submit(result) {
                modalInstance.close(result);
            },
        };
        const modalInstance = modalService.showModal({}, modalOptions);

        modalInstance.result.then(result => {
            if (result === 'submitOneUser') {
                return;
            }
            $scope.userError = '';
            companyUsersService.submitUser($scope.companyId, $scope.id).then(response => {
                if (response) {
                    $state.go(
                        $state.$current.name,
                        {
                            companyId: $scope.companyId,
                            id: $scope.id,
                            view: true,
                            subview: $scope.selectedSubview,
                        },
                        { reload: true }
                    );
                } else {
                    $scope.userError = 'An error occurred during submit.';
                }
            });
        });
    }

    function discardChanges() {
        if (!canDiscardChanges()) return;

        const modalInstance = $modal.open({
            template: require('../views/discardChangesModalTemplate.html'),
            size: 'lg',
            controller: 'DiscardChangesController',
            backdrop: 'static',
        });
        modalInstance.result.then(comments => {
            $scope.userError = '';
            companyUsersService
                .discardChanges($scope.companyId, $scope.id, comments)
                .then(response => {
                    if (response) {
                        if ($scope.userHeader.isEnrolled) {
                            $state.go(
                                $state.$current.name,
                                {
                                    companyId: $scope.companyId,
                                    id: $scope.id,
                                    view: true,
                                    subview: $scope.selectedSubview,
                                },
                                { reload: true }
                            );
                        } else {
                            $state.go('company', { id: $scope.companyId });
                        }
                    } else {
                        $scope.userError = 'An error occurred during discard.';
                    }
                });
        });
    }

    function approveOrReject(type) {
        const modalInstance = $modal.open({
            template: require('../views/approveReject.html'),
            size: 'lg',
            controller: 'ApproveRejectController',
            backdrop: 'static',
            resolve: {
                type() {
                    return type;
                },
            },
        });
        modalInstance.result.then(comments => {
            $scope.userError = '';
            if (type === 'approve') {
                companyUsersService
                    .approve($scope.companyId, $scope.id, comments)
                    .then(response => {
                        if (response) {
                            $state.go('company', { id: $scope.companyId });
                        } else {
                            $scope.userError = 'An error occurred during approval.';
                        }
                    });
            } else if (type === 'reject') {
                companyUsersService.reject($scope.companyId, $scope.id, comments).then(response => {
                    if (response) {
                        $state.go('company', { id: $scope.companyId });
                    } else {
                        $scope.userError = 'An error occurred during reject.';
                    }
                });
            }
        });
    }

    function unlock() {
        let modalInstance;
        let modalOptions;

        modalOptions = {
            closeButtonText: 'Cancel',
            actionButtonText: 'Unlock User',
            headerText: 'Confirm Unlock',
            bodyText: 'Are you sure you want to unlock this user?',
            submit(result) {
                modalInstance.close(result);
            },
        };
        modalInstance = modalService.showModal({}, modalOptions);
        modalInstance.result.then(result => {
            companyUsersService.unlock($scope.companyId, $scope.id).then(response => {
                $state.go(
                    $state.$current.name,
                    {
                        companyId: $scope.companyId,
                        id: $scope.id,
                        view: true,
                        subview: $scope.selectedSubview,
                    },
                    { reload: true }
                );
            });
        });
    }

    function backToUserList() {
        $state.go('company', { id: $scope.companyId });
    }

    function setWireTabName(tabName) {
        $scope.wireTabName = tabName;
    }

    function setArpTabName(tabName) {
        $scope.arpTabName = tabName;
    }

    function setId(id) {
        $scope.id = id;
    }

    //
    // State helpers
    function isEditable() {
        return !isPendingApproval();
    }

    function isNew() {
        return $scope.userHeader.pendingState === $scope.new;
    }

    function isDraft() {
        return (
            $scope.userHeader.pendingState === $scope.new ||
            $scope.userHeader.pendingState === $scope.edited
        );
    }

    function isPendingApproval() {
        return isPendingApprovalBackOffice() || isPendingApprovalChannel();
    }

    function isPendingApprovalChannel() {
        return $scope.userHeader.pendingState === $scope.pending;
    }

    function isPendingApprovalBackOffice() {
        return $scope.userHeader.pendingState === $scope.fiPending;
    }

    function isPendingApprovalAndNonApprover() {
        return (
            (isPendingApprovalBackOffice() && $scope.userHeader.isEditor) ||
            isPendingApprovalChannel()
        );
    }

    function isPendingApprovalAndApprover() {
        return $scope.userHeader.pendingState === $scope.fiPending && !$scope.userHeader.isEditor;
    }

    function hasWireTransfersDomestic() {
        return (
            $scope.productFeatureSummary && $scope.productFeatureSummary.hasWireTransfersDomestic
        );
    }

    function hasWireTransfersInternational() {
        return (
            $scope.productFeatureSummary &&
            $scope.productFeatureSummary.hasWireTransfersInternational
        );
    }

    // Private
    function loadUserHeader() {
        if ($scope.id) {
            return companyUsersService.getUserHeader($scope.companyId, $scope.id).then(response => {
                $scope.userHeader = response;
                $scope.company = $scope.userHeader.company;
            });
        }
    }

    function loadUserProductFeatureSummary() {
        if ($scope.id && $scope.id > 0) {
            companyUsersService
                .getUserProductFeatureSummary($scope.companyId, $scope.id)
                .then(response => {
                    $scope.productFeatureSummary = response;
                });
        }
    }

    // oob
    function unlockOob() {
        let modalInstance;
        let modalOptions;

        modalOptions = {
            closeButtonText: 'Cancel',
            actionButtonText: 'OK',
            headerText: 'Unlock',
            bodyText: 'Are you sure you want to unlock this user?',
            submit(result) {
                modalInstance.close(result);
            },
        };
        modalInstance = modalService.showModal({}, modalOptions);
        modalInstance.result.then(() => {
            companyUsersService.unlockUserChallenge($scope.companyId, $scope.id).then(() => {
                $state.go(
                    $state.$current.name,
                    {
                        companyId: $scope.companyId,
                        id: $scope.id,
                        view: true,
                        subview: $scope.selectedSubview,
                    },
                    { reload: true }
                );
            });
        });
    }

    function canDiscardChanges() {
        return entitlementsService.hasAnyEntitlement('Edit User');
    }

    function canUnlockUser() {
        return (
            $scope.userHeader.isLocked &&
            entitlementsService.hasAnyEntitlement('Edit User', 'Add User')
        );
    }

    (async function () {
        const ffService = await TmDi.getAsync(FeatureFlagService);
        $scope.isUisEnabled = await ffService.isEnabled(Feature.UisEnabled);
        $scope.sidebar = {
            achCollapsed: false,
            arpCollapsed: false,
            informationReportingCollapsed: false,
            internalTransfersCollapsed: false,
            wireTransfersCollapsed: false,
            wireTransfersDliCollapsed: false,
            stopPaymentCollapsed: false,
        };

        loadUserHeader();
        loadUserProductFeatureSummary();
        $scope.isACHFilterEnabled = await getAchFilterSettings();
    })();
}

userInformationController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'modalService',
    '$modal',
    'companyUsersService',
    'entitlementsService',
    'TmDi'
];