angular.module('backOffice').directive('print', [
    function () {
        return {
            restrict: 'A',
            replace: true,
            scope: {},
            template: require('./printTemplate.html'),
            controller: PrintController,
        };
    },
]);

PrintController.$inject = ['$scope', '$window', '$timeout'];

function PrintController($scope, $window, $timeout) {
    $scope.print = print;

    function print() {
        // print() appears to block, at least in chrome, this is a work around to
        // prevent it from blocking.
        $timeout(printCallback);
    }

    function printCallback() {
        $window.print();
    }
}
