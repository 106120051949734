angular.module('backOffice').controller('DiscardChangesController', discardChangesController);

discardChangesController.$inject = ['$scope', '$modalInstance'];

function discardChangesController($scope, $modalInstance) {
    $scope.user = { comments: null };
    // this will dismiss the window.

    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    $scope.remove = function () {
        $modalInstance.close($scope.user.comments);
    };
}
