angular
    .module('backOffice')
    .controller(
        'ForgotPasswordEmailConfirmationController',
        forgotPasswordEmailConfirmationController
    );

forgotPasswordEmailConfirmationController.$inject = ['$scope', '$state'];

function forgotPasswordEmailConfirmationController($scope, $state) {
    $scope.returnToLogin = function () {
        $state.go('login');
    };
}
