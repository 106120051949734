import { DiContainer } from '@jack-henry/frontend-utils/di';
import { UserActivityTypeDto } from '@treasury/api/bo';
import { NavigationService } from '@treasury/core/navigation/index';
import { UsersService } from '@treasury/domain/backoffice/services';
import { CompanyUserService } from '@treasury/domain/backoffice/services/company';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import moment from 'moment';

angular.module('backOffice').controller('UserInfoController', userInfoController);

userInfoController.$inject = [
    '$scope',
    '$timeout',
    'companiesService',
    '$uibModal',
    'toaster',
    '$filter',
    'modalService',
    'companyUsersService',
    '$state',
    'entitlementsService',
    'spinnerService',
    '$q',
];

function userInfoController(
    $scope,
    $timeout,
    companiesService,
    $uibModal,
    toaster,
    $filter,
    modalService,
    companyUsersService,
    $state,
    entitlementsService,
    spinnerService,
    $q,
) {
    $scope.userList = null;
    $scope.filteredUsers = [];
    $scope.isRefreshing = false;

    $scope.$watch('userSearch', onUserSearchChange);

    $scope.createNewUser = createNewUser;
    $scope.unlockUser = unlockUser;
    $scope.changeUserStatus = changeUserStatus;
    $scope.resetPassword = resetPassword;
    $scope.editUser = editUser;
    $scope.copyUser = copyUser;
    $scope.viewUser = viewUser;
    $scope.approveOrRejectUser = approveOrRejectUser;
    $scope.userSettings = userSettings;
    $scope.getAuthenticationSuspendedText = getAuthenticationSuspendedText;
    $scope.isAuthenticationSuspended = isAuthenticationSuspended;
    $scope.authenticationSettings = authenticationSettings;
    $scope.canApproveReject = canApproveReject;
    $scope.canCopy = canCopy;
    $scope.canEdit = canEdit;
    $scope.canResetPassword = canResetPassword;
    $scope.unlockUserClasses = unlockUserClasses;
    $scope.enrolled = 'Enrolled';
    $scope.fiPending = 'FiPending';
    $scope.pending = 'Pending';
    $scope.refresh = refresh;
    $scope.isUisEnabled = false;
    $scope.userIdLabel = 'Login ID';
    $scope.enrollmentStatusLabel = 'Enrollment Status';
    $scope.statusLabel = 'Status';
    $scope.displayResendEnrollmentLinkModal = displayResendEnrollmentLinkModal;
    $scope.displayUisEnrollmentLink = displayUisEnrollmentLink;

    function canApproveReject(user) {
        return user.publishState === $scope.fiPending && !user.isEditor;
    }

    function canCopy(user) {
        return user.isActive && user.status === $scope.enrolled;
    }

    function canResetPassword(user) {
        if ($scope.isUisEnabled) {
            return !!user.securityAndPasswordSettingsLink;
        }
        return user.status === $scope.enrolled && user.publishState !== $scope.fiPending;
    }

    function canEdit(user) {
        return user.publishState !== $scope.fiPending && user.publishState !== $scope.pending;
    }

    function isUnlockUserDisabled() {
        return !entitlementsService.hasAnyEntitlement('Add User', 'Edit User');
    }

    function unlockUserClasses() {
        return isUnlockUserDisabled() ? 'support-disabled' : '';
    }

    function changeUserStatus(user) {
        const modalInstance = $uibModal.open({
            template: require('../views/manageUserStatus.html'),
            size: 'md',
            controller: 'ManageCompanyUserStatusController',
            backdrop: 'static',
            resolve: {
                userDetails() {
                    return user;
                },
                companyId() {
                    return $scope.companyDetails.id;
                },
            },
        });

        modalInstance.result.then(response => {
            user.status = response.status;
            user.comments = response.comments;
            user.isActive = response.isActive;
        });
    }

    function resetPassword(user) {
        if ($scope.isUisEnabled) {
            window.open(user.securityAndPasswordSettingsLink, '_blank');
            return;
        }
        if (!entitlementsService.hasEntitlement('Reset User Password')) return;

        $uibModal.open({
            template: require('../../../../shared/resetPassword/views/resetPassword.html'),
            size: 'md',
            controller: 'ResetPassWordController',
            backdrop: 'static',
            resolve: {
                userDetails() {
                    return user;
                },
                companyId() {
                    return $scope.companyDetails.id;
                },
            },
        });
    }

    function userSettings() {
        $uibModal.open({
            template: require('../views/userSettings.html'),
            size: 'md',
            controller: 'UserSettingsController',
            backdrop: 'static',
            resolve: {
                companyId() {
                    return $scope.companyDetails.id;
                },
            },
        });
    }

    function unlockUser(user) {
        if (isUnlockUserDisabled()) return;

        const modalOptions = {
            bodyText: 'Are you sure you want to unlock the user?',
            submit(result) {
                $modalInstance2.close(result);
            },
        };
        const $modalInstance2 = modalService.showModal({}, modalOptions);
        return $modalInstance2.result.then(() => {
            companyUsersService.unlock($scope.companyDetails.id, user.id).then(() => {
                toaster.save('User');
                user.isLocked = false;
            });
        });
    }

    // events
    function onUserSearchChange() {
        filterUserList();
    }

    // private
    function filterUserList() {
        const expression = function (value) {
            const fields = ['userName', 'userId'];
            for (const i in fields) {
                const field = fields[i];
                const query = {};
                query[field] = $scope.userSearch;
                const r = $filter('filter')([value], query);
                if (r.length > 0) return true;
            }
            return false;
        };

        $scope.filteredUsers = $filter('filter')($scope.userList, expression);
        $timeout(() => {
            $scope.$apply();
        });
    }

    function refresh() {
        $scope.isRefreshing = true;
        $scope.userList = null;
        $scope.filteredUsers = [];
        loadUsers();
    }

    async function getCompanyUsers() {
        const di = await DiContainer.getInstance();
        const userService = await di.getAsync(UsersService);
        userService
            .getCompanyUsers($scope.companyDetails.id, false)
            .then(
                response => {
                    $scope.userList = response;
                    $scope.isRefreshing = false;
                    filterUserList();
                    determineAdminType($scope.filteredUsers);
                },
                () => {
                    $scope.userList = [];
                }
            )
            .finally(() => {
                spinnerService.stopGroupRequest('userInfo');
            });

    }

    async function loadUsers() {
        if ($scope.companyDetails && $scope.companyDetails.id) {
            spinnerService.startGroupRequest('userInfo');

            $q.resolve(getCompanyUsers());
        }
    }

    function determineAdminType(userList) {
        userList.forEach(user => {
            let adminType = '';
            if (user.isUserAdmin) {
                adminType += adminType.length > 0 ? ', User' : 'User';
            }
            if (user.isReportsAdmin) {
                adminType += adminType.length > 0 ? ', Reports' : 'Reports';
            }
            if (user.isAccountAdmin) {
                adminType += adminType.length > 0 ? ', Account' : 'Account';
            }
            user.adminType = adminType;
        });
    }

    function viewUser(user) {
        $state.go('user-information-view', {
            companyId: $scope.companyDetails.id,
            id: user.id,
            view: true,
        });
    }

    function approveOrRejectUser(user) {
        $state.go('user-information-view', {
            companyId: $scope.companyDetails.id,
            id: user.id,
            view: true,
        });
    }

    /**
     * Determines if the UIS enrollment link should be displayed for the user
     * @param {*} user
     * @returns
     */
    function displayUisEnrollmentLink(user) {
        const companyActive = $scope.companyStatus.status === 'Active';
        const userActive = user.isActive;
        const userEnrolled = user.status === 'Enrolled';
        const userAlreadyUisEnrolled = user.digitalId;
        return (
            $scope.isUisEnabled &&
            companyActive &&
            userActive &&
            userEnrolled &&
            !userAlreadyUisEnrolled
        );
    }

    function editUser(user) {
        if (entitlementsService.hasEntitlement('Edit User')) {
            $state.go('user-information-edit-user', {
                company: $scope.companyDetails,
                companyId: $scope.companyDetails.id,
                id: user.id,
                view: false,
            });
        }
    }

    function copyUser(user) {
        if (entitlementsService.hasEntitlement('Add User')) {
            $state.go('user-information-copy-user', {
                company: $scope.companyDetails,
                companyId: $scope.companyDetails.id,
                copyUser: user,
            });
        }
    }

    function createNewUser() {
        $state.go('user-information-create-user', {
            company: $scope.companyDetails,
            companyId: $scope.companyDetails.id,
        });
    }

    function authenticationSettings(user) {
        companyUsersService
            .getUserChallengeDetail($scope.companyDetails.id, user.id)
            .then(response => {
                const modalInstance = $uibModal.open({
                    template: require('../views/authenticationSettingsModal.html'),
                    size: 'md',
                    controller: 'authenticationSettingsController',
                    backdrop: 'static',
                    resolve: {
                        challengeSettings() {
                            return response;
                        },
                        companyId() {
                            return $scope.companyDetails.id;
                        },
                    },
                });

                modalInstance.result.then(() => {
                    loadUsers();
                });
            });
    }

    function getAuthenticationSuspendedText(user) {
        if (!user.isAuthenticationSuspended) {
            return '';
        }
        return `Authentication is suspended for this user until ${moment(
            user.isAuthenticationSuspended
        )
            .calendar()
            .toLowerCase()}.`;
    }

    function isAuthenticationSuspended(user) {
        if (user.utcSuspendEndDate) {
            return new Date(user.utcSuspendEndDate).getTime() > Date.now();
        }
        return false;
    }

    async function displayResendEnrollmentLinkModal(user) {
        const modalOptions = {
            actionButtonText: 'Resend Enrollment Email',
            closeButtonText: 'Cancel',
            headerText: 'Resend Enrollment Email',
            bodyText: `An email will be sent to ${user.userName} with a link to start the enrollment process.`,
            submit(result) {
                $modalInstance2.close(result);
            },
        };
        const $modalInstance2 = modalService.showModal({}, modalOptions);
        return $modalInstance2.result.then(async () => {
            const di = await DiContainer.getInstance();
            const usersService = di.get(UsersService);
            const companyUserService = di.get(CompanyUserService);
            const navigationService = di.get(NavigationService);
            const routeData = await navigationService.getRouteData();
            const companyId = parseInt(routeData.params.id);
            $modalInstance2.close();
            try {
                await companyUserService.sendEnrollmentEmail(companyId, user.id);
                await usersService.saveUserActivity(
                    UserActivityTypeDto.Admin,
                    'Resend Enrollment Email'
                );
                toaster.success(`Enrollment email was successfully sent to user ${user.userName}.`);
            } catch (ex) {
                toaster.error(`Enrollment email for user ${user.userName} was unable to be sent.`);
            }
        });
    }

    (async function () {
        // init
        $scope.isUisEnabled = await FeatureFlagService.isEnabled(Feature.UisEnabled);
        $scope.userIdLabel = $scope.isUisEnabled ? 'TM User ID' : 'Login ID';
        $scope.enrollmentStatusLabel = $scope.isUisEnabled
            ? 'TM Enrollment Status'
            : 'Enrollment Status';
        $scope.statusLabel = $scope.isUisEnabled ? 'TM Status' : 'Status';
        spinnerService.configureGroup(
            'userInfo',
            [`/companies/${$scope.companyDetails.id}/false/users`],
            []
        );
        loadUsers();
    })();
}
