import { Observable, noOp } from '@treasury/utils';
import { IRealtimeService } from './realtime.service.types';


export const NullRealtimeService: IRealtimeService = {
    connected$: new Observable(),
    init: noOp,
    disconnect: noOp,
    subscribe: () => Promise.resolve({ unsubscribe: noOp }),
    publish: () => Promise.resolve(),
};
