angular.module('backOffice').controller('aaConfigCancelController', aaConfigCancelController);

aaConfigCancelController.$inject = ['$scope', '$modalInstance'];

function aaConfigCancelController($scope, $modalInstance) {
    $scope.yes = function () {
        $modalInstance.close('yes');
    };
    $scope.no = function () {
        $modalInstance.close('no');
    };
}
