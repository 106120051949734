import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('cutOffConfigurationService', cutOffConfigurationService);

cutOffConfigurationService.$inject = ['$resource'];

function cutOffConfigurationService($resource) {
    const resourceUrl = `${getBackOfficeAPIPath()}processingTimes/`;

    return {
        getAll,
        update,
        get,
        getTimeZones,
        updateInstitutionTimeZone,
    };

    function getAll() {
        return $resource(resourceUrl).get().$promise;
    }

    function update(id, processingTimes) {
        const resource = $resource(`${resourceUrl}:id`, { id }, { update: { method: 'PUT' } });
        return resource.update(processingTimes).$promise;
    }

    function get(id) {
        return $resource(`${resourceUrl}:id`, { id }).get().$promise;
    }

    function getTimeZones() {
        return $resource(`${resourceUrl}/timeZones`).query().$promise;
    }

    function updateInstitutionTimeZone(timeZoneModel) {
        const resource = $resource(`${resourceUrl}timezone`, {}, { update: { method: 'PUT' } });
        return resource.update(timeZoneModel).$promise;
    }
}
