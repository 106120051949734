/* eslint-disable import/extensions */
import { TmHttpClient } from '@treasury/core/http';
import CompanyRequests from '../companies/company-requests.js';

export class CompanyPositivePayServices {
    static async getConfiguration(companyID) {
        const companyGuid = await CompanyRequests.getCompanyGuid(companyID);
        const http = await TmHttpClient.getInstance();
        return http.request(`positivePayCompanyConfiguration/${companyGuid}/allOptions`, {
            method: 'GET',
        });
    }

    static async getAccounts(companyID) {
        const companyGuid = await CompanyRequests.getCompanyGuid(companyID);
        const http = await TmHttpClient.getInstance();
        const url = `positivePayCompanyConfiguration/${companyGuid}/productFeatureAccounts`;
        return http.request(url, {
            method: 'GET',
        });
    }

    static async getAchExceptions(companyID) {
        const companyGuid = await CompanyRequests.getCompanyGuid(companyID);
        const http = await TmHttpClient.getInstance();
        const url = `positivePayCompanyConfiguration/${companyGuid}/achExceptionAccounts`;
        return http.request(url, {
            method: 'GET',
        });
    }

    static async getAchExceptionsFilterRules(companyID) {
        const companyGuid = await CompanyRequests.getCompanyGuid(companyID);
        const http = await TmHttpClient.getInstance();
        const url = `positivePayCompanyConfiguration/${companyGuid}/achFilterManagementAccounts`;
        return http.request(url, {
            method: 'GET',
        });
    }

    static async getCheckExceptions(companyID) {
        const companyGuid = await CompanyRequests.getCompanyGuid(companyID);
        const http = await TmHttpClient.getInstance();
        const url = `positivePayCompanyConfiguration/${companyGuid}/checkExceptionAccounts`;
        return http.request(url, {
            method: 'GET',
        });
    }

    static async saveConfiguration(companyId, body) {
        const companyGuid = await CompanyRequests.getCompanyGuid(companyId);
        const http = await TmHttpClient.getInstance();
        return http.request(`positivePayCompanyConfiguration/${companyGuid}`, {
            body,
            method: 'POST',
        });
    }

    static async getCompany(companyID) {
        const http = await TmHttpClient.getInstance();
        return http.request(`companies/${companyID}/GetOnboardedCompany/1`, {
            method: 'GET',
            maxAgeInSeconds: 10,
        });
    }
}
