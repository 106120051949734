import { AchPaymentModelDto } from '@treasury/api/bo';
import { DomainEntity } from '../../../shared';
import { AchRecipient } from './ach-recipient.entity';
import { defaultAchPaymentModelDto } from './data/ach.data';

export class AchPayment<T extends AchPaymentModelDto> extends DomainEntity<T> {
    public createDefault(): this {
        return new AchPayment(defaultAchPaymentModelDto) as this;
    }

    public get id() {
        return this.dto.id;
    }

    public get guid() {
        return this.dto.achPaymentGuidUniqueId;
    }
    public get typeId() {
        return this.dto.achPaymentTypeId;
    }

    public get transactionId() {
        return this.dto.transactionId;
    }

    public get parentId() {
        return this.dto.parentId;
    }

    public get batchId() {
        return this.dto.batchId;
    }

    public get templateName() {
        return this.dto.templateName;
    }

    public get name() {
        return this.dto.name;
    }

    public get status() {
        return this.dto.status;
    }

    public get companyName() {
        return this.dto.achCompanyName;
    }

    public get companyId() {
        return this.dto.achCompanyId;
    }

    public get secCode() {
        return this.dto.secCode;
    }

    public get recipients() {
        if (!this.dto.recipients) return [];
        return this.dto.recipients.map(recipient => new AchRecipient(recipient));
    }

    public get creditAmount() {
        return this.dto.creditAmount;
    }

    public set creditAmount(value: number) {
        this.dto.creditAmount = value;
    }

    public get debitAmount() {
        return this.dto.debitAmount;
    }

    public get frequency() {
        return this.dto.frequency;
    }

    public get frequencySummary() {
        return this.dto.frequency.summary ?? '';
    }

    public get effectiveDate() {
        return this.dto.frequency.effectiveDate ?? '0000-00-00';
    }

    public get offsetAccount() {
        return this.dto.offsetAccount ? this.dto.offsetAccount.value : '';
    }

    public get entryDescription() {
        return this.dto.entryDescription;
    }

    public get discretionaryData() {
        return this.dto.discretionaryData;
    }

    public get restrictedPayment() {
        return this.dto.restricted;
    }

    public get numberOfApprovalsNeeded() {
        return this.dto.numberOfApprovalsNeeded;
    }

    public get audit() {
        return this.dto.audit;
    }
}
