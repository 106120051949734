// cspell:ignore datadoghq
import { datadogLogs } from '@datadog/browser-logs';
import { Injectable } from '@jack-henry/frontend-utils/di';
import { WindowService } from '@treasury/utils/services';
import { UserIdentificationData } from '../analytics';
import { BuildType, ConfigurationService } from '../config';

@Injectable()
export class LoggingService {
    constructor(
        private readonly config: ConfigurationService,
        private readonly window: WindowService
    ) {}

    private initialized = false;

    private get console() {
        return this.window.console;
    }

    private get logger() {
        return datadogLogs.logger;
    }

    private get isDev() {
        return this.config.buildType === BuildType.Development;
    }

    public init() {
        if (this.initialized) {
            return;
        }

        const { loggingToken, app, buildType, version } = this.config;
        if (!loggingToken) {
            throw new Error('Could not initialize logging service. No client token provided.');
        }

        datadogLogs.init({
            clientToken: loggingToken,
            site: 'us5.datadoghq.com',
            service: `tm-ux-${app}`,
            env: this.config.environment,
            version,
            forwardErrorsToLogs: buildType === BuildType.Production,
            sessionSampleRate: 100,
            forwardConsoleLogs: ['error'],
        });

        datadogLogs.setGlobalContextProperty('team_name', 'Treasury Management UI');
        datadogLogs.setGlobalContextProperty('business_unit_id', 146700);
        datadogLogs.setGlobalContextProperty('institution_id', this.config.institutionId);
        this.initialized = true;
    }

    public setUserSessionContext(user: UserIdentificationData) {
        const { userId, isSuperUser, isAdmin, companyId } = user;
        datadogLogs.setGlobalContextProperty('user', {
            userId,
            isSuperUser,
            isAdmin,
            companyId,
        });
        datadogLogs.setGlobalContextProperty('user_id', userId);
        datadogLogs.setGlobalContextProperty('company_id', companyId);
    }

    public logDebug(formatString: string, ...formatArgs: any[]) {
        if (this.isDev) {
            this.console.debug(formatString, ...formatArgs);
        }

        this.guardInit();
    }

    public logInfo(message: string, context?: object) {
        if (this.isDev) {
            if (context) {
                this.console.info(message, context);
            } else {
                this.console.info(message);
            }
        }

        this.guardInit();
        this.logger.info(message, context);
    }

    public logWarning(message: string, context?: object) {
        if (this.isDev) {
            if (context) {
                this.console.warn(message, context);
            } else {
                this.console.warn(message);
            }
        }

        this.guardInit();
        this.logger.warn(message, context);
    }

    public logError(message: string, e?: Error) {
        if (this.isDev) {
            if (e) {
                this.console.error(message, e);
            } else {
                this.console.error(message);
            }
        }

        this.guardInit();
        this.logger.error(message, {}, e);
    }

    private guardInit() {
        if (!this.initialized) {
            throw new Error('LoggingService has not been initialized.');
        }
    }
}
