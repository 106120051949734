angular.module('backOffice').factory('alertsManager', () => {
    return {
        alerts: [],
        addAlert,
        clearAlerts,
    };

    function addAlert(message, type) {
        this.alerts.push({ type, msg: message });
    }

    function clearAlerts(index) {
        this.alerts.splice(index, 1);
    }
});
