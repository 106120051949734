angular.module('backOffice').controller('LockedOutController', lockedUserController);

lockedUserController.$inject = ['$scope', '$state'];

function lockedUserController($scope, $state) {
    $scope.returnToLogin = function () {
        $state.go('login');
    };

    $scope.unlockUser = function () {
        $state.go('unlock-user');
    };
}
