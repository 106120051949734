angular
    .module('backOffice')
    .controller('TransactionCodeConfigurationController', transactionCodeConfigurationController);

transactionCodeConfigurationController.$inject = [
    '$scope',
    '$modal',
    'transactionCodesService',
    'entitlementsService',
];

function transactionCodeConfigurationController(
    $scope,
    $modal,
    transactionCodesService,
    entitlementsService
) {
    $scope.searchText = '';
    $scope.transactionCodeList = [];
    $scope.isLoaded = false;

    $scope.filterTransactionCodeList = filterTransactionCodeList;
    $scope.cloneGroups = cloneGroups;
    $scope.formatDisplayGroups = formatDisplayGroups;
    $scope.sync = sync;
    $scope.determineExcludedCount = determineExcludedCount;
    $scope.editTransactionCode = editTransactionCode;
    $scope.manageGroups = manageGroups;
    $scope.canSync = canSync;

    function canSync() {
        return entitlementsService.hasAnyEntitlement(
            'Manage Transaction Code Configuration',
            'Switch FI'
        );
    }

    function filterTransactionCodeList(array) {
        let tmpArray = [];
        if (
            $scope.searchText === null ||
            $scope.searchText === undefined ||
            $scope.searchText.length === 0
        ) {
            tmpArray = array;
        } else {
            angular.forEach(array, tranCode => {
                if (
                    tranCode.code.toUpperCase().indexOf($scope.searchText.toUpperCase()) > -1 ||
                    tranCode.description.toUpperCase().indexOf($scope.searchText.toUpperCase()) >
                        -1 ||
                    tranCode.type.toUpperCase().indexOf($scope.searchText.toUpperCase()) > -1 ||
                    $scope
                        .formatDisplayGroups(tranCode.groups)
                        .toUpperCase()
                        .indexOf($scope.searchText.toUpperCase()) > -1
                ) {
                    tmpArray.push(tranCode);
                }
            });
        }
        return tmpArray;
    }

    function loadTransactionCodes() {
        transactionCodesService.getTransactionCodes().then(response => {
            $scope.transactionCodeList = response;
            $scope.isLoaded = true;
        });
    }

    function formatDisplayGroups(groups) {
        let result = '';
        angular.forEach(groups, group => {
            result += `${group.name}, `;
        });
        result = result.substring(0, result.length - 2);
        return result;
    }

    function init() {
        loadTransactionCodes();
    }

    init();

    function sync() {
        const modalInstance = $modal.open({
            template: require('../views/syncTransactionCodeModalView.html'),
            size: 'md',
            controller: 'SyncTransactionCodeController',
            backdrop: 'static',
        });
        modalInstance.result.then(() => {
            loadTransactionCodes();
        });
    }

    function determineExcludedCount(array) {
        let count = 0;
        angular.forEach(array, item => {
            if (item.isExcluded === true) {
                count++;
            }
        });
        return count;
    }

    function cloneGroups(item) {
        const modalInstance = $modal.open({
            template: require('../views/cloneTransactionCodeGroupsModalView.html'),
            size: 'md',
            controller: 'CloneTransactionCodeGroupsController',
            backdrop: 'static',
            resolve: {
                transactionCodeList() {
                    return $scope.transactionCodeList;
                },
                transactionCode() {
                    return angular.copy(item);
                },
            },
        });
        modalInstance.result.then(() => {
            loadTransactionCodes();
        });
    }

    function editTransactionCode(item) {
        const modalInstance = $modal.open({
            template: require('../views/editTransactionCodeModalView.html'),
            size: 'md',
            controller: 'EditTransactionCodeController',
            backdrop: 'static',
            resolve: {
                transactionCode() {
                    return angular.copy(item);
                },
            },
        });
        modalInstance.result.then(transactionCode => {
            angular.copy(transactionCode, item);
        });
    }

    function manageGroups() {
        const modalInstance = $modal.open({
            template: require('../views/manageTransactionCodeGroupsModalView.html'),
            size: 'md',
            controller: 'ManageTransactionCodeGroupsController',
            backdrop: 'static',
            resolve: {
                transactionCodeList() {
                    return $scope.transactionCodeList;
                },
            },
        });
        modalInstance.result.then(() => {
            loadTransactionCodes();
        });
    }
}
