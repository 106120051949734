import { FieldType } from '@treasury/FDL';
import { FdlFieldDefinitions } from '@treasury/FDL/record';
import FieldTypes from '@treasury/policy/primitives';
import { Company } from '../types/company.types';

export const columns = [
    {
        label: 'Company Name',
        field: 'name',
        type: 'command',
        action: 'routeToCompanyConfiguration',
    },
    {
        label: 'Tax Number',
        field: 'taxNumber',
    },
    {
        label: 'CIF Number',
        field: 'cifNumber',
    },
    {
        label: 'Status',
        field: 'status',
    },
];
export const fields: FdlFieldDefinitions<Company> = {
    name: FieldTypes.string.thatIs
        .disabledWhen((record: any) => record.getField('status').toLowerCase() === 'associated')
        .thatIs.readOnly() as FieldType<string>,
    taxNumber: FieldTypes.string.thatIs.readOnly() as FieldType<string>,
    cifNumber: FieldTypes.string.thatIs.readOnly() as FieldType<string>,
    status: FieldTypes.string.thatIs.readOnly() as FieldType<string>,
};
export const filters = {
    searchType: FieldTypes.string.with
        .options({
            data: [
                { text: 'Company Name', value: 'Company Name' },
                { text: 'TAX ID Number', value: 'TAX ID Number' },
                { text: 'CIF Number', value: 'CIF Number' },
            ],
        })
        .thatHas.label('Search Type') as FieldType<string>,
    searchValue: FieldTypes.string.thatIs
        .required()
        .thatHas.validator({
            name: 'TAX ID Number must be 9 characters',
            validate: (value: any, view, record: any) => {
                if (record.getField('searchType') === 'TAX ID Number') {
                    return value.length === 9;
                }
                return true;
            },
        })
        .thatHas.placeholder('Enter Search Criteria')
        .with.label('Search') as FieldType<string>,
};
