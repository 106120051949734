angular.module('backOffice').controller('UserMultiSelectController', userMultiSelectController);

userMultiSelectController.$inject = [
    '$scope',
    '$modalInstance',
    'companiesService',
    'selectedUserList',
    'companyId',
    'overloadAvailableUserList',
];

function userMultiSelectController(
    $scope,
    $modalInstance,
    companiesService,
    selectedUserList,
    companyId,
    overloadAvailableUserList
) {
    $scope.selectedUserList = selectedUserList;
    $scope.overloadAvailableUserList = overloadAvailableUserList;
    $scope.selectedUserListSelected = [];
    $scope.availableUserList = [];
    $scope.availableUserListSelected = [];
    $scope.companyId = companyId;
    $scope.changed = false;

    // this will dismiss the window.
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    $scope.save = function () {
        // include any deleted items in the "available" list, needs to be sent to back-end.
        angular.forEach($scope.availableUserList, availableUser => {
            if (
                availableUser.isDeleted !== null &&
                availableUser.isDeleted !== undefined &&
                availableUser.isDeleted === true &&
                availableUser.id > -1
            ) {
                let result = false;

                // only add if the ID doesn't already exist.
                angular.forEach($scope.selectedUserList, user => {
                    if (user.userId === availableUser.userId) {
                        result = true;
                    }
                });
                if (result === false) {
                    $scope.selectedUserList.push(angular.copy(availableUser));
                }
            }
        });
        $modalInstance.close({ userList: $scope.selectedUserList, changed: $scope.changed });
    };

    // used to set the form instance.
    $scope.setForm = function (form) {
        $scope.form = form;
    };

    function setFormDirty() {
        $scope.form.$dirty = true;
    }

    $scope.moveAllUsers = function (source, target, moveType) {
        $scope.changed = true;

        // button clicks dont count as dirty, so we have to set the form dirty
        setFormDirty();

        // push to target
        angular.forEach(source, userToMove => {
            // if we move from available to selected isDelete is false, otherwise its true
            if (moveType == 'availableToSelected') {
                userToMove.isDeleted = false;
            } else if (moveType == 'selectedToAvailable') {
                userToMove.isDeleted = true;
            }

            let shouldMove = true;

            if (moveType == 'availableToSelected') {
                // if this resolves to true it means its of type userToMove, not summaryUser. Convert it to one.
                if (userToMove.status !== null && userToMove.status !== undefined) {
                    const newSummaryUser = {
                        id: -1,
                        isDeleted: false,
                        userId: userToMove.id,
                        userName: userToMove.userName,
                    };
                    userToMove = newSummaryUser;
                }

                angular.forEach(target, item => {
                    if (item.userId === userToMove.userId) {
                        item.isDeleted = false;
                        shouldMove = false;
                    }
                });
            }

            // push to target
            if (shouldMove) {
                target.push(angular.copy(userToMove));
            }
        });

        // clear source
        source.length = 0;
        $scope.availableUserListSelected.length = 0;
        $scope.selectedUserListSelected.length = 0;
    };

    $scope.moveSomeUsers = function (source, sourceSomeUsers, target, moveType) {
        $scope.changed = true;

        // button clicks dont count as dirty, so we have to set the form dirty
        setFormDirty();

        angular.forEach(sourceSomeUsers, userToMove => {
            const index = source.indexOf(userToMove);

            // if we move from available to selected isDelete is false, otherwise its true
            if (moveType == 'availableToSelected') {
                userToMove.isDeleted = false;
            } else if (moveType == 'selectedToAvailable') {
                userToMove.isDeleted = true;
            }

            let shouldMove = true;

            if (moveType == 'availableToSelected') {
                // if this resolves to true it means its of type user, not summaryUser. Convert it to one.
                if (userToMove.status !== null && userToMove.status !== undefined) {
                    const newSummaryUser = {
                        id: -1,
                        isDeleted: false,
                        userId: userToMove.id,
                        userName: userToMove.userName,
                    };
                    userToMove = newSummaryUser;
                }

                angular.forEach(target, user => {
                    if (userToMove.userId == user.userId) {
                        user.isDeleted = false;
                        shouldMove = false;
                    }
                });
            }

            // push to target
            if (shouldMove) {
                target.push(angular.copy(userToMove));
            }

            // remove from source
            if (index > -1) {
                source.splice(index, 1);
            }
        });

        // clear list of source users.
        sourceSomeUsers.length = 0;
    };

    // we watch for form.$dirty, this is used to alert the end user that they changed something in the form.
    $scope.$watch('form.$dirty', isDirty => {
        $modalInstance.isDirty = isDirty;
    });

    $scope.addRemoveSelectedUser = function (user, userList) {
        const index = userList.indexOf(user);

        // if the user is already in the selected list, remove them
        if (index > -1) {
            userList.splice(index, 1);
        } else {
            // otherwise add them
            userList.push(user);
        }
    };

    function deltaPurge(target, source) {
        angular.forEach(source, user => {
            let index = 0;
            let found = false;
            angular.forEach(target, userToFind => {
                if (user.userId == userToFind.id) {
                    found = true;
                    userToFind = angular.copy(user);
                }
                if (found !== true) {
                    index++;
                }
            });
            if (found) {
                // since isDeleted records are part of the source that are supressed,
                // dont remove it from target.
                if (user.isDeleted === null || user.isDeleted === false) {
                    target.splice(index, 1);
                }
            }
        });
    }

    function loadAvailableUsers() {
        companiesService.getUsers($scope.companyId).then(response => {
            $scope.availableUserList = response;

            // we clear out users in the avaiable user list if its already in the selected user list
            deltaPurge($scope.availableUserList, $scope.selectedUserList);
        });
    }

    function init() {
        if ($scope.overloadAvailableUserList.length === 0) {
            loadAvailableUsers();
        } else {
            $scope.availableUserList = $scope.overloadAvailableUserList;

            // we clear out users in the avaiable user list if its already in the selected user list
            deltaPurgeOverload($scope.availableUserList, $scope.selectedUserList);
        }
    }

    function deltaPurgeOverload(target, source) {
        angular.forEach(source, user => {
            let index = 0;
            let found = false;
            angular.forEach(target, userToFind => {
                if (user.userId == userToFind.userId) {
                    found = true;
                    userToFind = angular.copy(user);
                }
                if (found !== true) {
                    index++;
                }
            });
            if (found) {
                // since isDeleted records are part of the source that are supressed,
                // dont remove it from target.
                if (user.isDeleted === null || user.isDeleted === false) {
                    target.splice(index, 1);
                }
            }
        });
    }

    init();
}
