/* eslint-disable import/no-named-as-default-member */
// eslint-disable-next-line no-unused-vars
import UserActivityReportsService from '@treasury/domain/backoffice/services/reports/user-activity-reports-service.js';
import { alphabetizeOnText } from '@treasury/domain/shared/utilities/compare-functions';
import { DateFormatters } from '@treasury/domain/shared/utilities/date-formatters';
import { boolean, FieldType, number } from '@treasury/FDL';
// eslint-disable-next-line import/no-extraneous-dependencies
import { html } from 'lit';
import dateTimeFormatted from './lib/formatters/dateTime.formatter.js';
import { timeIntervals } from './lib/utils/date-time-intervals.js';
import fieldTypes, { dateRange } from './primitives.ts';
// keeping this in the event we do need a datepicker with disabled fields that follows the model
// import { disableWeekendsAndFutureDates } from '../lib/utils/disable-date-functions.js';

// eslint-disable-next-line import/no-named-as-default-member
export const { string } = fieldTypes;

export const multiSelect = new FieldType().with
    .multipleValues()
    .thatHas.filtering()
    .and.description(
        'multiSelect maps directly from the report model contract and is used for any backoffice report filter with type "multi-select"'
    )
    .with.hashFunction(value => value.id ?? value.value)
    .as.tag('omega-select')
    .with.placeholder('All');

export const multiSelectAll = new FieldType().with
    .multipleValues()
    .thatHas.filtering()
    .and.description(
        'multiSelectAll maps directly from the report model contract and is used for any backoffice report filter with type "multi-select-all"'
    )
    .with.hashFunction(value => value.id ?? value.value)
    .as.tag('omega-select')
    .with.placeholder('All');

export const select = new FieldType().with
    .filtering()
    .as.tag('omega-select')
    .with.placeholder('All');

export const radio = new FieldType().thatIs.inline().as.tag('omega-radio-group');

export const selectAmount = fieldTypes.amountRange.with.schema('range').as.tag('omega-range');

export const selectDateAny = dateRange.with
    .options([
        'any-date',
        'today',
        'specific',
        'range',
        'week-to-date',
        'month-to-date',
        'year-to-date',
    ])
    .as.tag('omega-datepicker');

export const selectDate = dateRange.with
    .options([
        'any-date',
        'today',
        'specific',
        'range',
        'week-to-date',
        'month-to-date',
        'year-to-date',
    ])
    .as.tag('omega-datepicker');

export const text = new FieldType();

export const int = fieldTypes.number.with.reducer('sum');

export const decimal = fieldTypes.money.with.reducer('sum');

export const currency = fieldTypes.money.with.reducer('sum');

export const currencyNoSymbol = fieldTypes.currencyNoSymbol.with.reducer('sum');

export const { date } = fieldTypes;

export const { dateTime } = fieldTypes;

export const audithistory = new FieldType().with.template(
    (v, record) => html`<table style="width: max-content">
        <th>Decision By</th>
        <th>Decision Date</th>
        <th>Decision</th>
        <tr>
            <td style="padding:5px;white-space:nowrap">${record.getField('decisionBy')}</td>
            <td style="padding:5px;white-space:nowrap">
                ${dateTimeFormatted(record.getField('decisionDate'))}
            </td>
            <td style="padding:5px;white-space:nowrap">${record.getField('decisionStatus')}</td>
        </tr>
    </table>`
);

export const auditinfo = new FieldType();

export const historylink = new FieldType().with
    .template(() => `History`)
    .and.description(
        'historylink indicates that a columns field should trigger a link action that routes to the details of a given record'
    );

export const { count } = fieldTypes;

export const total = fieldTypes.money;

export const name = string;

export const isCustomReport = boolean.with.formatter(value => (value ? 'CUSTOM' : 'STANDARD'));

export const isFavoriteReport = boolean;

export const id = number;

export const type = string;

/**
 * TEMPORARY USER ACTIVITY REPORT FIELDS (UNTIL CONVERTED TO MAIN REPORT MODEL...)
 */

export const selectCompany = string.with
    .label('Company')
    .and.filtering()
    .thatIs.required()
    .with.minColumnWidth(100)
    .and.maxColumnWidth(300)
    .and.targetColumnWidth(200)
    .thatHas.options({
        fetch: UserActivityReportsService.getCompanies,
        text: record => record.name,
        value: record => record.id,
        compareFunction: alphabetizeOnText,
    })
    .and.placeholder('Select a company')
    .as.tag('omega-select');

export const backOfficeUsersMultiple = string.with
    .multipleValues()
    .and.filtering()
    .thatHas.label('User Name')
    .thatIs.required()
    .thatHas.validator({ name: 'has-length', validate: value => value?.length })
    .with.minColumnWidth(100)
    .and.maxColumnWidth(300)
    .and.targetColumnWidth(200)
    .thatHas.options({
        fetch: UserActivityReportsService.getAllUsers,
        text: record => `${record.userName} (${record.userId})`,
        value: record => record.id,
        compareFunction: alphabetizeOnText,
    })
    .as.tag('omega-select');

export const companyUserNameMultiple = string.with
    .multipleValues()
    .and.filtering()
    .thatHas.label('User Name')
    .thatIs.required()
    .with.minColumnWidth(100)
    .and.maxColumnWidth(300)
    .and.targetColumnWidth(200)
    .thatHas.validator({ name: 'has-length', validate: value => value?.length })
    .with.options({
        fetch: async record => {
            const companyId = record.getField('companyId');
            if (!companyId) return Promise.resolve([]);
            return UserActivityReportsService.getCompanyUsers(companyId);
        },
        text: record => record.userName,
        value: record => record.id,
        compareFunction: alphabetizeOnText,
    })
    .and.placeholder('Select user names')
    .thatIs.disabledWhen(record => !record.getField('companyId'))
    .as.tag('omega-select');

export const activityPeriod = dateRange.with
    .label('Activity Period')
    .thatIs.required()
    .as.tag('omega-datepicker');

export const activityDate = dateTime.with.label('Date/Time');

export const timeSelect = string.with
    .options(timeIntervals(30))
    .and.placeholder('Any')
    .thatIs.disabledWhen(record =>
        DateFormatters.isDynamicOrSelectedDateRange(
            DateFormatters.parseDate(record.getField('activityPeriodDate'))
        )
    )
    .as.tag('omega-select');

export const activityKeyWord = string.with.label('Activity Keyword').as.tag('omega-input');

export const userActivities = string.with
    .label('Activity Type')
    .thatHas.options({
        fetch: UserActivityReportsService.getActivityTypes,
        text: record => record.name,
        value: record => record.id,
        compareFunction: alphabetizeOnText,
    })
    .as.tag('omega-select');

export const sessionInformation = string.with.template(
    (value, record) =>
        html`<div style="display: flex; flex-direction: column">
            <span>Session ID: ${record.getField('sessionId')}</span> <br />
            <span>Device: ${record.getField('device')}</span><br />
            <span>Browser: ${record.getField('browser')}</span>
        </div>`
);

export const channelSessionInformation = string.with.template(
    (value, record) => html` <div style="display: flex; flex-direction: column">
        <span>Session ID: ${record.getField('sessionId')}</span> <br />
        <span>Device: ${record.getField('device')}</span><br />
        <span>Browser: ${record.getField('browser')}</span>
        <span>IP Address: ${record.getField('ipAddress')}</span>
        <span>User Status: ${record.getField('userStatus')}</span>
    </div>`
);

export const ipAddress = string;

export const userType = string;

export const activityType = string;

export const activity = string;

export const decisionHistory = string.with.template((value, record) => {
    const decisions = record.getField('decisions');
    const decisionsTemplate = decisions.map(
        decision =>
            html`
                <div>
                    <span>Last decision by: ${decision.decisionBy}</span>
                    <span>Date: ${new Date(decision.decisionDate).toLocaleString()}</span>
                    <span>Decision: ${decision.decisionStatus}</span>
                </div>
            `
    );
    return html`<div style="display: flex; flex-direction: column">${decisionsTemplate}</div> `;
});

export const comment = new FieldType().with.template((value, record) => {
    const hasComment = !!value;
    return hasComment ? `Comment` : ``;
});

export const checkImageLink = string;

export const attachmentlink = new FieldType().with.template((value, record) => {
    const hasRecord = record.getField('hasAttachment') === 'YES';
    return hasRecord ? `Attachment` : ``;
});

export const changedValueType = new FieldType().with.template((value, record) => {
    const { original, updated } = value;
    if (!updated || original === updated) {
        return html`${original}`;
    }
    return html` <style>
            .updated {
                color: green;
                font-weight: bold;
            }
        </style>
        <span class="updated">${updated}</span>
        <span> (${original})</span>`;
});

export const hideIfEmptyDate = date.thatIs.visibleWhen(record => record.getField('dateOfDeath'));

export default {
    string,
    int,
    decimal,
    currency,
    currencyNoSymbol,
    date,
    dateTime,
    audithistory,
    auditinfo,
    historylink,
    count,
    total,
    multiSelect,
    select,
    radio,
    selectAmount,
    selectDateAny,
    selectDate,
    text,
    decisionHistory,
    comment,
    checkImageLink,
    attachmentlink,
};
