import { getBackOfficeAPIPath } from '@treasury/core/http';

angular.module('backOffice').factory('transactionCodesService', transactionCodesService);

transactionCodesService.$inject = ['$resource'];

function transactionCodesService($resource) {
    const resourceUrl = `${getBackOfficeAPIPath()}transactioncodes/`;

    return {
        getTransactionCodes,
        updateTransactionCode,
        cloneTransactionCode,
        getTransactionCodeSyncDetails,
        syncTransactionCodes,
        getAnalysisTransactionTypes,
        getAnalysisTransactionCodesForInstitution,
        saveAnalysisTransactionCodes,
        groupAnalysisTransactionTypesByCategory,
        getCustomTransactionCodesForCompany,
        saveCustomTransactionCodes,
        getOfxTransactionTypes,
    };

    function getTransactionCodes() {
        return $resource(resourceUrl).query().$promise;
    }

    function getOfxTransactionTypes() {
        return $resource(`${resourceUrl}getOfxTransactionTypes`).query().$promise;
    }

    function syncTransactionCodes() {
        const resource = $resource(`${resourceUrl}sync`, null, {
            save: { method: 'POST', isArray: true },
        });
        return resource.save().$promise;
    }

    function getTransactionCodeSyncDetails() {
        return $resource(`${resourceUrl}syncdetails`).query().$promise;
    }

    function cloneTransactionCode(id, transactionCodeIds) {
        const resource = $resource(
            `${resourceUrl}:id/clone`,
            { id },
            { update: { method: 'PUT' } }
        );
        return resource.update(transactionCodeIds).$promise;
    }

    function updateTransactionCode(id, transactionCodePayload) {
        const resource = $resource(`${resourceUrl}:id`, { id }, { update: { method: 'PUT' } });
        return resource.update(transactionCodePayload).$promise;
    }

    function getAnalysisTransactionCodesForInstitution() {
        return $resource(`${resourceUrl}getAnalysisTransactionCodesForInstitution`).query()
            .$promise;
    }

    function getAnalysisTransactionTypes() {
        return $resource(`${resourceUrl}getAnalysisTransactionTypes`).query().$promise;
    }

    function groupAnalysisTransactionTypesByCategory(analysisTransactionTypes) {
        const analysisTransactionTypeCategories = [];
        // create js object that is grouped by AnalysisTransactionTypeCategory
        let currentCategory;
        angular.forEach(analysisTransactionTypes, transactionType => {
            if (
                currentCategory === undefined ||
                currentCategory.analysisTransactionTypeCategoryId !==
                    transactionType.analysisTransactionTypeCategory
                        .analysisTransactionTypeCategoryId
            ) {
                currentCategory = {
                    category: transactionType.analysisTransactionTypeCategory.categoryName,
                    analysisTransactionTypes: [],
                    isCollapsed: true,
                    analysisTransactionTypeCategoryId:
                        transactionType.analysisTransactionTypeCategory
                            .analysisTransactionTypeCategoryId,
                };
                analysisTransactionTypeCategories.push(currentCategory);
            }

            currentCategory.analysisTransactionTypes.push({
                analysisTransactionTypeId: transactionType.analysisTransactionTypeId,
                transactionName: transactionType.transactionName,
                description: transactionType.description,
            });
        });
        return analysisTransactionTypeCategories;
    }

    function saveAnalysisTransactionCodes(analysisTransactionCodes) {
        return $resource(`${resourceUrl}SaveAnalysisTransactionCodes`, null, {
            save: { method: 'POST', isArray: true },
        }).save(analysisTransactionCodes).$promise;
    }
    function getCustomTransactionCodesForCompany(companyId, analysisTransactionTypeCategoryId) {
        return $resource(
            `${resourceUrl}company/:companyId/category/:analysisTransactionTypeCategoryId/getCustomTransactionCodesForCompany`,
            {
                companyId,
                analysisTransactionTypeCategoryId,
            }
        ).query().$promise;
    }
    function saveCustomTransactionCodes(
        customTransactionCodes,
        companyId,
        analysisTransactionTypeCategoryId
    ) {
        return $resource(
            `${resourceUrl}company/:companyId/category/:analysisTransactionTypeCategoryId/saveCustomTransactionCodes`,
            {
                companyId,
                analysisTransactionTypeCategoryId,
            },
            { save: { method: 'POST', isArray: true } }
        ).save(customTransactionCodes).$promise;
    }
}
