/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
import '@treasury/omega/components/omega-time-input.js';
import { exists } from '@treasury/utils';

angular.module('backOffice').controller('TimeAccessController', TimeAccessController);

TimeAccessController.$inject = ['$scope', 'companyUsersService', 'toaster'];

function TimeAccessController($scope, companyUsersService, toaster) {
    $scope.timeAccess = [];
    $scope.timeAccessPristine = [];
    $scope.timeAccessActual = [];
    $scope.timeAccessPending = [];
    $scope.restricted = { value: false };
    $scope.restrictedPristine = {};
    $scope.restrictedActual = {};
    $scope.restrictedPending = {};

    $scope.minuteStep = 15;
    $scope.reset = reset;
    $scope.save = save;
    $scope.setForm = setForm;
    $scope.changeAccessType = changeAccessType;
    $scope.changeBeginTime = changeBeginTime;
    $scope.changeEndTime = changeEndTime;
    $scope.toggleApplyToAllWeekdays = toggleApplyToAllWeekdays;
    $scope.applyToAllWeekdays = { selected: false };
    $scope.getRestrictedLabel = getRestrictedLabel;
    $scope.displayCurrentAccessType = displayCurrentAccessType;
    $scope.hasInvalidEndTime = hasInvalidEndTime;
    $scope.isInvalidEndTime = isInvalidEndTime;
    $scope.formatTwelveHourTime = formatTwelveHourTime;

    $scope.userTimeAccess = {};
    $scope.timeAccessType = [
        { name: 'Allowed all day', value: 1 },
        { name: 'Restricted all day', value: 2 },
        { name: 'Allowed time range', value: 3 },
    ];
    $scope.timeAccessDays = [
        { name: 'Sunday', value: 0 },
        { name: 'Monday', value: 1 },
        { name: 'Tuesday', value: 2 },
        { name: 'Wednesday', value: 3 },
        { name: 'Thursday', value: 4 },
        { name: 'Friday', value: 5 },
        { name: 'Saturday', value: 6 },
    ];

    function isWeekday(dayOfWeek) {
        //Returns true if day of week value represents any of Monday through Friday (1-5), false if weekend day of week (0,6).
        return dayOfWeek % 6 !== 0;
    }

    function isMonday(dayOfWeek) {
        return dayOfWeek === 1;
    }

    function inRangeTuesdayToFriday(dayOfWeek) {
        return isWeekday(dayOfWeek) && !isMonday(dayOfWeek);
    }

    function compareDayOfWeekByMondayThroughSunday(a, b) {
        //Re-index for Monday-Sunday, uses modular math to map the index ring one backward.
        const aMapped = (a.dayOfWeek + 6) % 7;
        const bMapped = (b.dayOfWeek + 6) % 7;
        return aMapped - bMapped;
    }

    function formatTime(hoursMinutes) {
        if (hoursMinutes == null) return '00:00:00';
        return `${hoursMinutes}:00`;
    }

    function formatTwelveHourTime(hoursMinutes) {
        if (hoursMinutes == null) return 'no data';
        let hours = parseInt(hoursMinutes.slice(0, 2));
        const minutes = hoursMinutes.slice(3, 5);
        const period = hours >= 12 ? 'PM' : 'AM';
        if (hours > 12) hours -= 12;
        if (hours === 0) hours = 12;
        const hourString = String(hours).padStart(2, '0');

        return `${hourString}:${minutes} ${period}`;
    }

    function displayCurrentAccessType(day) {
        if (day.selectedAccessType.value === 3) {
            return `${day.selectedAccessType.name} (${formatTwelveHourTime(
                day.editBeginTime
            )} - ${formatTwelveHourTime(day.editEndTime)})`;
        }
        return day.selectedAccessType.name;
    }

    function presentationBeginTimeFromTime(time) {
        return time == null ? `08:00` : time.slice(0, 5);
    }

    function presentationEndTimeFromTime(time) {
        return time == null ? `17:00` : time.slice(0, 5);
    }

    function isInvalidEndTime(day) {
        if (!$scope.restricted.value) return false;
        if (day.selectedAccessType.value !== 3) return false;
        return day.editBeginTime >= day.editEndTime;
    }

    function hasInvalidEndTime() {
        if (!$scope.restricted.value) return false;
        return $scope.timeAccess.some(day => isInvalidEndTime(day));
    }

    function changeAccessType(day) {
        if ($scope.applyToAllWeekdays.selected) {
            if (isMonday(day.dayOfWeek)) {
                $scope.timeAccess.forEach(listedDay => {
                    if (inRangeTuesdayToFriday(listedDay.dayOfWeek)) {
                        listedDay.selectedAccessType = day.selectedAccessType;
                    }
                });
            } else if (isWeekday(day.dayOfWeek)) {
                $scope.applyToAllWeekdays.selected = false;
            }
        }
    }

    function changeBeginTime(day, $event) {
        const newBeginTime = $event.detail;
        if (newBeginTime == null) return;
        if (!exists(day?.dayOfWeek)) return;
        const adjustedDayOfWeek = day.dayOfWeek === 0 ? 6 : day.dayOfWeek - 1;

        $scope.timeAccess[adjustedDayOfWeek].editBeginTime = newBeginTime;
        if ($scope.applyToAllWeekdays.selected) {
            if (isMonday(day.dayOfWeek)) {
                $scope.timeAccess.forEach(listedDay => {
                    if (inRangeTuesdayToFriday(listedDay.dayOfWeek)) {
                        listedDay.editBeginTime = newBeginTime;
                    }
                });
            } else if (isWeekday(day.dayOfWeek)) {
                $scope.applyToAllWeekdays.selected = false;
            }
        }
        $scope.form.$setDirty();
    }

    function changeEndTime(day, $event) {
        const newEndTime = $event.detail;
        if (newEndTime == null) return;
        if (!exists(day?.dayOfWeek)) return;
        const adjustedDayOfWeek = day.dayOfWeek === 0 ? 6 : day.dayOfWeek - 1;

        $scope.timeAccess[adjustedDayOfWeek].editEndTime = newEndTime;
        if ($scope.applyToAllWeekdays.selected) {
            if (isMonday(day.dayOfWeek)) {
                $scope.timeAccess.forEach(listedDay => {
                    if (inRangeTuesdayToFriday(listedDay.dayOfWeek)) {
                        listedDay.editEndTime = newEndTime;
                    }
                });
            } else if (isWeekday(day.dayOfWeek)) {
                $scope.applyToAllWeekdays.selected = false;
            }
        }
        $scope.form.$setDirty();
    }

    function toggleApplyToAllWeekdays() {
        if ($scope.applyToAllWeekdays.selected) {
            const newSelectedAccessType = $scope.timeAccess[0].selectedAccessType;
            const newEditBeginTime = $scope.timeAccess[0].editBeginTime;
            const newEditEndTime = $scope.timeAccess[0].editEndTime;

            $scope.timeAccess.forEach(day => {
                if (inRangeTuesdayToFriday(day.dayOfWeek)) {
                    day.selectedAccessType = newSelectedAccessType;
                    day.editBeginTime = newEditBeginTime;
                    day.editEndTime = newEditEndTime;
                }
            });
        }
    }

    function init() {
        loadTimeAccess();
    }

    function save() {
        $scope.userTimeAccess.userTimeAccessDays = $scope.timeAccess;
        $scope.userTimeAccess.timeRestricted = $scope.restricted.value;
        for (let i = 0; i < $scope.userTimeAccess.userTimeAccessDays.length; i++) {
            const item = $scope.userTimeAccess.userTimeAccessDays[i];
            item.timeAccessType = item.selectedAccessType?.value ?? 1;
            item.beginTime = formatTime(item.editBeginTime);
            item.endTime = formatTime(item.editEndTime);
        }
        companyUsersService
            .saveUserTimeAccess($scope.companyId, $scope.id, $scope.userTimeAccess)
            .then(() => {
                $scope.timeAccessPristine = window.angular.copy($scope.timeAccess);
                $scope.timeAccessPending = window.angular.copy($scope.timeAccess);
                $scope.restrictedPristine = window.angular.copy($scope.restricted.value);
                $scope.restrictedPending = window.angular.copy($scope.restricted.value);
                $scope.$parent.notifySave();
                $scope.form.$setPristine();
                toaster.save('Time Access');
            });
    }

    function setForm(form) {
        $scope.form = form;
    }

    function reset() {
        $scope.timeAccess = angular.copy($scope.timeAccessPristine);
        $scope.restricted.value = angular.copy($scope.restrictedPristine);
        $scope.applyToAllWeekdays.selected = false;
        $scope.form.$setPristine();
    }

    function makeDefaultDay(displayDay) {
        return {
            timeAccessType: 1,
            dayOfWeek: displayDay.value,
            editBeginTime: `08:00`,
            editEndTime: `17:00`,
            label: displayDay.name,
            selectedAccessType: $scope.timeAccessType[0],
        };
    }

    function fillInMissingDays(response) {
        $scope.timeAccessDays.forEach(displayDay => {
            const userDayPending = response.userTimeAccessDaysPending.find(
                day => day.dayOfWeek === displayDay.value
            );
            if (!userDayPending) {
                const userDayActual = response.userTimeAccessDays.find(
                    day => day.dayOfWeek === displayDay.value
                );
                response.userTimeAccessDaysPending.push(
                    userDayActual || makeDefaultDay(displayDay)
                );
            }
        });
        response.userTimeAccessDaysPending.sort(compareDayOfWeekByMondayThroughSunday);
    }

    function loadDay(day) {
        day.label = $scope.timeAccessDays.find(val => day.dayOfWeek === val.value)?.name;
        day.selectedAccessType =
            $scope.timeAccessType.find(val => day.timeAccessType === val.value) ??
            $scope.timeAccessType[0];
        day.editBeginTime = presentationBeginTimeFromTime(day.beginTime);
        day.editEndTime = presentationEndTimeFromTime(day.endTime);
    }

    function loadTimeAccess() {
        companyUsersService.getUserTimeAccess($scope.companyId, $scope.id).then(response => {
            angular.forEach(response.userTimeAccessDays, day => {
                loadDay(day);
            });
            angular.forEach(response.userTimeAccessDaysPending, day => {
                loadDay(day);
            });
            fillInMissingDays(response);

            $scope.timeAccessActual = response.userTimeAccessDays;
            $scope.timeAccessPending = response.userTimeAccessDaysPending;
            $scope.restrictedActual = response.timeRestricted;
            $scope.restrictedPending = response.timeRestrictedPending;
            $scope.timeAccessPristine = angular.copy($scope.timeAccessPending);
            $scope.restrictedPristine = angular.copy($scope.restrictedPending);
            $scope.userTimeAccess = response;
        });
    }

    function getRestrictedLabel(showActual) {
        let isRestricted;
        if (showActual === true) {
            isRestricted = $scope.restrictedActual;
        } else {
            isRestricted = $scope.restricted.value;
        }
        return isRestricted ? 'Restricted' : 'Unrestricted';
    }

    $scope.$watch('timeAccessActual', updateFormModel);
    $scope.$watch('timeAccessPending', updateFormModel);
    $scope.$watch('restrictedActual', updateFormModel);
    $scope.$watch('restrictedPending', updateFormModel);
    $scope.$watch('compareMode', updateFormModel);
    $scope.$watch('editMode', updateFormModel);
    $scope.$watch('userHeader', updateFormModel);

    function updateFormModel() {
        if ($scope.timeAccessPending && $scope.timeAccessActual) {
            if ($scope.showPending) {
                $scope.timeAccess = $scope.timeAccessPending;
                $scope.restricted.value = $scope.restrictedPending;
            } else {
                $scope.timeAccess = $scope.timeAccessActual;
                $scope.restricted.value = $scope.restrictedActual;
            }
        }
    }

    init();
}
