angular.module('backOffice').controller('OnboardCompanyController', onboardCompanyController);

onboardCompanyController.$inject = ['$scope', '$modalInstance'];

function onboardCompanyController($scope, $modalInstance) {
    $scope.form = null;
    $scope.company = {};

    $scope.setForm = setForm;
    $scope.save = save;
    $scope.cancel = cancel;

    function setForm(form) {
        $scope.form = form;
    }

    function cancel() {
        $modalInstance.dismiss();
    }

    function save() {
        if ($scope.company && $scope.company.companyId && $scope.company.companyId !== '') {
            $modalInstance.close($scope.company.companyId);
        }
    }
}
