/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import { DomainEntity } from '@treasury/domain/shared/types';
import {
    clone,
    createUniqueId,
    CustomEventCallback,
    deepEquals,
    delay,
    exists,
    getKeys,
    ObservationSource,
    ReadonlyOrMutable,
} from '@treasury/utils';
import FieldType from '../field-type.js';
import {
    FdlFieldDefinitions,
    Record as FdlRecord,
    FdlRecordEvent,
    RecordEventCallback,
} from '../record';
import comparatorFromColumns from '../utilities/comparator-from-columns.js';
import { RecordsetSummaryModel } from './recordset-summary-model.js';
import {
    dispatchRecordsetEvent,
    listenForRecordsetEvent,
    RecordsetEvent,
    RecordsetEventCallback,
} from './recordset.events';
import {
    FetchResult,
    ListenerEntry,
    RecordsetColumn,
    RecordsetData,
    RecordsetFetchFunction,
    RecordsetValidator,
    SortPredicate,
} from './recordset.types';

export default class Recordset<T, Params = void> extends EventTarget {
    /**
     * @param dataOrFunction Array, function, or Promise that is or produces the data to hydrate the `Recordset`.
     */
    constructor(
        private fieldTypes: FdlFieldDefinitions<T> = {},
        dataOrFunction?: RecordsetData<T, Params>,
        private debounceInterval = 0,
        private disableLocalFiltering = false
    ) {
        super();
        if (exists(dataOrFunction)) {
            this.fetch =
                typeof dataOrFunction === 'function' ? dataOrFunction : () => dataOrFunction;
        }
    }

    public readonly id = createUniqueId();

    public summary?: RecordsetSummaryModel[];

    private isClone = false;

    private isFirstFetch = true;

    /**
     * Timeout ID of the last running queued update timer.
     */
    private updateHandle: number | null = null;

    private extraRowCount = 0;

    private fetch?: RecordsetFetchFunction<T, Params>;

    private _filter: (record: FdlRecord<T>) => boolean = () => true;

    private _isLoading = false;

    private updatedStream = new ObservationSource<void>();

    /**
     * Data the `Recordset` was original hydrated with.
     * Useful for resetting state.
     */
    private originalData: Readonly<T[] | FetchResult<T>> | null = null;

    /**
     * Collection of saved data states this `Recordset` has gone through.
     *
     * The array should be treated as a stack, with the last element being the most recent.
     */
    private stateHistory: Array<Readonly<T[]>> = [];

    private currentUpdatePromise: Promise<void> | null = null;

    private listeningTo: ListenerEntry<T, Params, any>[] = [];

    private _pageIndex = 0;

    private _pageSize = 10;

    private _parameters = {} as Params;

    private records: FdlRecord<T>[] = [];

    private _sortColumns: RecordsetColumn<T>[] = [];

    private sortFn?: SortPredicate<T>;

    public sortedFilteredRecords: FdlRecord<T>[] = [];

    private _totalCount = 0;

    /**
     * List of in-memory recordset validators
     */
    private validators: RecordsetValidator<T>[] = [];

    private _isFetchNeeded = true;

    public get isFetchNeeded() {
        return this._isFetchNeeded || this.isServerSide;
    }

    public set isFetchNeeded(val) {
        this._isFetchNeeded = val;
    }

    /**
     * Collection of `Record` instances contained within the set.
     */
    get allRecords() {
        return this.records;
    }

    get filter() {
        return this.disableLocalFiltering ? () => true : this._filter;
    }

    set filter(f) {
        this.pageNumber = 1;
        this._filter = f;
        this.queueUpdate();
    }

    get isLoading() {
        return this._isLoading;
    }

    /**
     * A promise that resolves when the `Recordset` finishes its most recent update.
     * May be interrogated across multiple updates.
     */
    get updating() {
        return this.updatedStream.toObservable().toPromise(false);
    }

    get pageIndex() {
        return this._pageIndex;
    }

    set pageIndex(n) {
        this._pageIndex = n;
        this.queueUpdate();
    }

    get pageSize() {
        return this._pageSize;
    }

    set pageSize(n) {
        this.pageNumber = 1;
        this._pageSize = n;
        this.queueUpdate();
    }

    get pageNumber() {
        return this._pageIndex + 1;
    }

    set pageNumber(n) {
        if (!Number.isInteger(n)) {
            throw new Error(`Cannot set value ${n} for a page number. It is not an integer.`);
        }

        if (n < 1) {
            throw new Error(
                `Cannot set value ${n} for a page number. It must be a positive value greater than 0.`
            );
        }

        this._pageIndex = n - 1;
        this.queueUpdate();
    }

    get sortColumns() {
        return this._sortColumns;
    }

    set sortColumns(s) {
        this.pageNumber = 1;
        this._sortColumns = s;
        this.queueUpdate();
    }

    get parameters() {
        return this._parameters;
    }

    set parameters(p) {
        this.pageNumber = 1;
        this._parameters = p;
        this.queueUpdate();
    }

    get totalCount() {
        return this._totalCount;
    }

    get filteredCount() {
        if (this.isServerSide) return this.totalCount;
        return this.sortedFilteredRecords.length;
    }

    get isClientSide() {
        return this.totalCount !== 0 && this.totalCount === this.records.length;
    }

    get isServerSide() {
        return !this.isClientSide;
    }

    get currentPage() {
        if (this.isServerSide) return this.sortedFilteredRecords;

        return this.sortedFilteredRecords.slice(
            this._pageIndex * this._pageSize,
            this._pageIndex * this._pageSize + this._pageSize + this.extraRowCount
        );
    }

    get firstRecordNumberOnPage() {
        return this._pageIndex * this._pageSize + 1;
    }

    get lastRecordNumberOnPage() {
        const pageMax = (this._pageIndex + 1) * this._pageSize;
        return Math.min(pageMax, this.filteredCount);
    }

    get fieldNames() {
        return getKeys(this.fieldTypes);
    }

    get hasChanged() {
        return this.records?.some(record => record.hasChanged);
    }

    get hasErrors() {
        const hasInvalidRecords = this.records.some(
            r => r.hasValidationErrors || !r.hasRequiredValues()
        );
        return hasInvalidRecords || this.validationErrors.length > 0;
    }

    get validationErrors() {
        const errors: string[] = [];
        this.validators.forEach(validator => {
            /**
             * Recordset validators return true if the validator is valid, false otherwise.
             */
            if (validator.validate(this.allRecords)) {
                return;
            }

            const validatorName =
                typeof validator.name === 'function'
                    ? validator.name(this.allRecords)
                    : validator.name;

            errors.push(validatorName);
        });

        return errors;
    }

    /**
     * The raw data underlying the `Recordset` as contained within each `Record`.
     */
    get backingValues() {
        return this.records.map(r => r.values);
    }

    *[Symbol.iterator]() {
        for (const record of this.records) {
            yield record;
        }
    }

    private queueUpdate() {
        this.cancelUpdate();

        this.updateHandle = window.setTimeout(() => {
            this.cancelUpdate();
            this.requestUpdate();
        }, 30);
    }

    private cancelUpdate() {
        if (!exists(this.updateHandle)) {
            return;
        }

        window.clearTimeout(this.updateHandle);
        this.updateHandle = null;
    }

    /**
     * Hydrate the `Recordset` from fetch result payload.
     */
    private hydrateFromFetch(result: ReadonlyOrMutable<T[] | FetchResult<T>>) {
        const [data, totalCount, summary] =
            'data' in result
                ? [result.data, result.totalCount, result.summary]
                : [result, result.length];
        this.summary = summary;
        this._totalCount = totalCount;
        this.records.forEach(record => this.stopListeningTo(record));
        this.records = data.map(values => this.createRecord(values));

        // store a copy of the data for resetting purposes
        if (this.isFirstFetch && this.originalData === null) {
            // clone the data so mutations aren't reflected in the stored copy
            this.originalData = Object.freeze(clone(result));
        }

        this.isFirstFetch = false;
    }

    private dispatchUpdateEvents() {
        dispatchRecordsetEvent(this, RecordsetEvent.Changed, undefined);
        dispatchRecordsetEvent(this, RecordsetEvent.PageChanged, undefined);
        dispatchRecordsetEvent(this, RecordsetEvent.CountsChanged, undefined);
        this.updatedStream.emit();
    }

    public sortAndFilterRecords() {
        this.sortedFilteredRecords = this.records
            .filter(this.filter)
            .sort((a, b) =>
                comparatorFromColumns(this._sortColumns, this.fieldTypes)(a.values, b.values)
            );

        if (exists(this.sortFn)) {
            const { sortFn } = this;
            this.sortedFilteredRecords = this.sortedFilteredRecords.sort((a, b) => sortFn(a, b));
        }

        this.dispatchUpdateEvents();
    }

    /**
     * @deprecated use `hasErrors` property
     */
    public isValid() {
        return !this.hasErrors;
    }

    /**
     *
     * @deprecated use `validationErrors` property.
     */
    public errors() {
        return this.validationErrors;
    }

    /**
     * Adds a validator to the recordset
     */
    public addValidator(validator: RecordsetValidator<T>) {
        this.validators = [...this.validators, validator];
    }

    /**
     * Adds multiple validators to the recordset
     */
    public addValidators(validators: RecordsetValidator<T>[]) {
        validators.forEach(validator => this.addValidator(validator));
    }

    public async update() {
        if (!this.fetch) {
            return;
        }

        this._isLoading = true;
        if (this.isFetchNeeded) {
            this.isFetchNeeded = false;

            try {
                this.dispatchEvent(
                    new CustomEvent(RecordsetEvent.Loading, { detail: { loading: true } })
                );

                const response = this.fetch({
                    parameters: this._parameters,
                    startIndex: this.pageIndex * this.pageSize,
                    pageSize: this.pageSize,
                    page: this.pageNumber,
                    sort: this.sortColumns,
                    isFirstFetch: this.isFirstFetch,
                });

                const result = response instanceof Promise ? await response : response;
                if (exists(result)) {
                    this.hydrateFromFetch(result);
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
                this._isLoading = false;
                this.dispatchEvent(
                    new CustomEvent(RecordsetEvent.Error, {
                        bubbles: true,
                        composed: true,
                        detail: { error },
                    })
                );
            } finally {
                this.dispatchEvent(
                    new CustomEvent(RecordsetEvent.Loading, { detail: { loading: false } })
                );
            }
        }

        this.extraRowCount = 0;
        this._isLoading = false;
        this.sortAndFilterRecords();
    }

    public requestHardUpdate() {
        this.isFetchNeeded = true;
        return this.debouncedUpdate();
    }

    public requestSoftUpdate() {
        return this.debouncedUpdate();
    }

    /**
     * Performs an update and no-ops on repeated requests
     * until the pending update finishes.
     */
    public requestUpdate() {
        // perform an update only if a previous one is not outstanding
        if (!this.currentUpdatePromise) {
            this.currentUpdatePromise = this.update();
            this.currentUpdatePromise.then(() => {
                this.currentUpdatePromise = null;
            });
        }

        return this.currentUpdatePromise;
    }

    /**
     * Perform a delayed update.
     */
    public async debouncedUpdate() {
        await delay(this.debounceInterval);
        return this.requestUpdate();
    }

    /**
     * Specify a column to sort by. Multiple calls of different columns are additive.
     */
    public sort(column: RecordsetColumn<T>) {
        const otherColumns = this.sortColumns.filter(s => s.field !== column.field);
        this.sortColumns = column.sort === 'UNSORTED' ? otherColumns : [column, ...otherColumns];
    }

    /**
     * Specify a custom sorting function to be applied in addition to default column sorting logic.
     */
    public setSortComparator(sortFn: SortPredicate<T> | undefined) {
        this.sortFn = sortFn;
        this.sortColumns = [];
    }

    public setInitialPageSize(size: number) {
        this._pageSize = size;
    }

    /**
     * Reset the `Recordset` to its original sate.
     *
     * @param hard
     * If `true`, rehydrates the recordset with the raw data obtained from its first fetch.
     * This has the side effect of also recreating the underlying `Record` references, listeners, and counts.
     *
     * Otherwise, the existing records are only reset to their initial values.
     */
    public reset(hard = false) {
        if (hard && this.originalData) {
            this.hydrateFromFetch(this.originalData);
            this.sortAndFilterRecords();
            this.dispatchUpdateEvents();
        } else {
            this.records.forEach(record => record.reset());
        }
    }

    /**
     * Persist the current data in the `Recordset` to its state history.
     *
     * Use in conjunction with `revertState()` to rehydrate the `Recordset`
     * with data it contained the last time this method was invoked.
     */
    public saveState() {
        this.stateHistory.push(Object.freeze(clone(this.backingValues)));
    }

    public revertState() {
        const state = this.stateHistory.pop() ?? this.originalData;
        if (!state || deepEquals(state, this.backingValues)) {
            return;
        }

        this.hydrateFromFetch(state);
        this.sortAndFilterRecords();
    }

    /**
     * Create and listen to a new record using the field types
     * associated with this `Recordset`.
     */
    public createRecord(values: T) {
        const record = new FdlRecord(this.fieldTypes, values);

        this.listenTo(record, FdlRecordEvent.Change, ({ detail }) => {
            dispatchRecordsetEvent(this, RecordsetEvent.Updated, { record, ...detail });
            dispatchRecordsetEvent(this, RecordsetEvent.CountsChanged, undefined);
        });

        return record;
    }

    public insertDefaultRecord(insertAtIndex?: number) {
        let defaultValues = getKeys(this.fieldTypes).reduce((defaults, key) => {
            defaults[key] = this.fieldTypes[key]?.defaultValue() ?? '';
            return defaults;
        }, {} as T);

        const { values } = this.records[0];
        const proto = Object.getPrototypeOf(values);

        // support class-based backing objects
        if (proto !== Object) {
            if (values instanceof DomainEntity) {
                defaultValues = values.createDefault();
            }
            // make a clone and copy over default values
            else {
                const clonedValues = clone(values);
                getKeys(defaultValues as object).forEach(k => {
                    clonedValues[k] = defaultValues[k];
                });

                defaultValues = clonedValues;
            }
        }

        const rec = this.createRecord(defaultValues);
        this.insertRecord(rec, insertAtIndex ?? this.sortedFilteredRecords.length);
    }

    public isLastRecord(record: FdlRecord<T>) {
        return this.allRecords.indexOf(record) === this.filteredCount - 1;
    }

    public clone() {
        const { fieldTypes, fetch } = this;

        const clonedFieldTypes = getKeys(fieldTypes).reduce((cloned, k) => {
            const clonedFieldType = fieldTypes[k]!.with.copy();
            cloned[k] = clonedFieldType;

            return cloned;
        }, {} as FdlFieldDefinitions<T>);

        const clone = new Recordset<T, Params>(clonedFieldTypes, fetch);

        clone.isClone = true;
        clone.copyFrom(this);

        return clone;
    }

    /**
     * Copy the records from another recordset to this one.
     */
    public copyFrom(recordset: Recordset<T, Params>, fields?: (keyof T)[], hard = false) {
        if (hard) {
            const { totalCount, pageSize } = recordset;
            this.setData(recordset.getData());
            this.sortColumns = [...recordset.sortColumns];
            this._totalCount = totalCount;
            this._pageSize = pageSize;
            this.fetch = recordset.fetch;
        } else {
            recordset.records.forEach((r, i) => {
                this.cloneRecord(r, i, fields);
            });
        }
        this.isFetchNeeded = false;
        this.isFirstFetch = false;
    }

    /**
     * Get an exiting record from the `Recordset` by ID.
     */
    public getRecordById(id: string) {
        return this.records.find(r => r.id === id);
    }

    public getRecordByData(data: T) {
        return this.records.find(r => r.values === data);
    }

    /**
     * Clones a record and inserts it into this `Recordset`.
     */
    public cloneRecord(record: FdlRecord<T>, rowIndex: number, fields?: (keyof T)[]) {
        const clonedRecord = record.clone(fields);

        this.listenTo(clonedRecord, FdlRecordEvent.Change, ({ detail }) => {
            dispatchRecordsetEvent(this, RecordsetEvent.Updated, {
                record: clonedRecord,
                ...detail,
            });

            dispatchRecordsetEvent(this, RecordsetEvent.CountsChanged, undefined);
        });
        this.insertRecord(clonedRecord, rowIndex);
        this.dispatchEvent(
            new CustomEvent(RecordsetEvent.RecordCloned, { detail: { record, index: rowIndex } })
        );
        return clonedRecord;
    }

    public deleteRecord(rec: FdlRecord<T>) {
        const rowIndex = this.sortedFilteredRecords.findIndex(r => r.id === rec.id);
        const record = this.sortedFilteredRecords[rowIndex];
        this.stopListeningTo(record);
        this.sortedFilteredRecords.splice(rowIndex, 1);
        this.records.splice(
            this.records.findIndex(r => r.id === record.id),
            1
        );
        this._totalCount--;
        this.extraRowCount = Math.max(0, this.extraRowCount - 1);
        this.dispatchEvent(new CustomEvent(RecordsetEvent.Changed));
        this.dispatchEvent(new CustomEvent(RecordsetEvent.PageChanged));
        this.dispatchEvent(new CustomEvent(RecordsetEvent.CountsChanged));
        this.dispatchEvent(
            new CustomEvent(RecordsetEvent.RecordDeleted, { detail: { record, index: rowIndex } })
        );
    }

    /**
     * Inserts a new record at a given index, if provided.
     */
    public insertRecord(record: FdlRecord<T>, index = 0) {
        // records inserted from outside the recordset should share field types
        record.fieldTypes = this.fieldTypes;

        this.records.splice(index + 1, 0, record);
        this.sortedFilteredRecords.splice(index + 1, 0, record);
        this._totalCount++;
        this.extraRowCount++;
        this.dispatchEvent(new CustomEvent(RecordsetEvent.Changed)); // deprecated
        this.dispatchEvent(new CustomEvent(RecordsetEvent.PageChanged));
        this.dispatchEvent(new CustomEvent(RecordsetEvent.CountsChanged));
        this.dispatchEvent(
            new CustomEvent(RecordsetEvent.RecordAdded, {
                detail: {
                    record,
                    index,
                },
            })
        );
    }

    /**
     * Insert raw data into the recordset, automatically
     * creating a `Record` under the hood.
     */
    public insert(data: T, index = this.records.length - 1) {
        const record = new FdlRecord(this.fieldTypes, data);
        this.insertRecord(record, index);
    }

    /**
     * Delete data from the recordset by reference.
     */
    public delete(data: T) {
        const record = this.getRecordByData(data);

        if (!record) {
            return;
        }

        this.deleteRecord(record);
    }

    /**
     * Updates an existing record within a recordset.
     */
    public updateRecord(record: FdlRecord<T>) {
        let index = this.records.indexOf(record);
        this.records.splice(index, 1, record);
        index = this.sortedFilteredRecords.indexOf(record);
        this.sortedFilteredRecords.splice(index, 1, record);
        this.dispatchEvent(new CustomEvent(RecordsetEvent.Changed));
        this.dispatchEvent(new CustomEvent(RecordsetEvent.PageChanged));
        this.dispatchEvent(new CustomEvent(RecordsetEvent.CountsChanged));
    }

    /**
     * Listen for an event on another `Record` or `Recordset`.
     */
    public listenTo<E extends RecordsetEvent>(
        recordset: Recordset<T, Params>,
        eventName: E,
        fn: RecordsetEventCallback<E, T>
    ): void;
    // eslint-disable-next-line lines-between-class-members
    public listenTo<E extends FdlRecordEvent>(
        record: FdlRecord<T>,
        eventName: E,
        fn: RecordEventCallback<E, T>
    ): void;
    // eslint-disable-next-line lines-between-class-members
    public listenTo(
        target: FdlRecord<T> | Recordset<T, Params>,
        eventName: string,
        fn: CustomEventCallback<unknown>
    ) {
        target.addEventListener(eventName, fn as EventListener);
        this.listeningTo.push({ target, event: eventName, fn });
    }

    /**
     * Listen for an event on this `Recordset`.
     */
    public listen<E extends RecordsetEvent>(eventName: E, fn: RecordsetEventCallback<E, T>) {
        return listenForRecordsetEvent(this, eventName, fn);
    }

    public stopListeningTo(target: FdlRecord<T>) {
        const indexesToRemove: number[] = [];
        this.listeningTo
            .filter(l => l.target.id === target.id)
            .forEach((listener, index) => {
                const { event, fn } = listener;
                if (listener.target.id !== target.id) return;
                indexesToRemove.unshift(index);
                target.removeEventListener(event, fn as EventListener);
            });

        for (const index of indexesToRemove) {
            this.listeningTo.splice(index, 1);
        }
    }

    public removeListeners() {
        for (const { target, event, fn } of this.listeningTo) {
            target.removeEventListener(event, fn as EventListener);
        }
        this.listeningTo = [];
    }

    /**
     * Register a callback to be invoked any time the recordset is updated.
     *
     * Syntax sugar for long-form `listenTo()` method.
     */
    public onChange(fn: RecordsetEventCallback<RecordsetEvent.Changed, T>) {
        this.listenTo(this, RecordsetEvent.Changed, fn);

        return {
            unsubscribe: () =>
                this.removeEventListener(RecordsetEvent.Changed, fn as EventListener),
        };
    }

    public lastRecordIndex() {
        return this.totalCount;
    }

    public setColumnValue<FieldName extends keyof T>(fieldName: FieldName, value: T[FieldName]) {
        this.records
            .filter(record => !record.fieldTypeForField(fieldName).isDisabled(record))
            .forEach(record => record.setField(fieldName, value));

        this.dispatchEvent(new CustomEvent(RecordsetEvent.Changed));
        this.dispatchEvent(new CustomEvent(RecordsetEvent.ColumnChanged));
        this.dispatchEvent(
            new CustomEvent(RecordsetEvent.Updated, { detail: { field: fieldName } })
        );
    }

    public allRecordsMatch<FieldName extends keyof T>(fieldName: FieldName, value: T[FieldName]) {
        const sortedFilteredEnabledRecords = this.sortedFilteredRecords.filter(
            record => !record.fieldTypeForField(fieldName).isDisabled(record)
        );
        return (
            sortedFilteredEnabledRecords.length > 0 &&
            sortedFilteredEnabledRecords.every(record => record.getField(fieldName) === value)
        );
    }

    public noRecordsMatch<FieldName extends keyof T>(fieldName: FieldName, value: T[FieldName]) {
        return this.sortedFilteredRecords.every(record => record.getField(fieldName) !== value);
    }

    public partialRecordsMatch<FieldName extends keyof T>(field: FieldName, value: T[FieldName]) {
        return !(this.allRecordsMatch(field, value) || this.noRecordsMatch(field, value));
    }

    public recordsMatching<FieldName extends keyof T>(field: FieldName, value: T[FieldName]) {
        return this.sortedFilteredRecords.filter(record => record.getField(field) === value);
    }

    public countRecordsMatching<FieldName extends keyof T>(field: FieldName, value: T[FieldName]) {
        return this.recordsMatching(field, value).length;
    }

    public invalidRecordCount() {
        const invalidRecords = this.records.filter(r => !r.isValid() || !r.hasRequiredValues());
        return invalidRecords.length;
    }

    public filteredRecordCount() {
        return this.sortedFilteredRecords.length;
    }

    public getFieldType<FieldName extends keyof T>(fieldName: FieldName) {
        return this.fieldTypes[fieldName] ?? new FieldType();
    }

    /**
     * Set new field types while maintaining existing Recordset data.
     */
    public applyFieldTypes(fieldTypes: FdlFieldDefinitions<T>) {
        this.fieldTypes = fieldTypes;
        this.records.forEach(r => {
            r.fieldTypes = fieldTypes;
        });
        this.sortAndFilterRecords();
        this.dispatchUpdateEvents();
    }

    public getData() {
        return this.sortedFilteredRecords.map(r => r.values);
    }

    public setData(data: T[]) {
        this.records.forEach(record => this.stopListeningTo(record));
        this.records = data.map(values => this.createRecord(values));
        this._totalCount = data.length;
        this.isFetchNeeded = false;
        this.dispatchEvent(new CustomEvent(RecordsetEvent.Updated));
        this.requestUpdate();
    }

    /**
     * Gets a record by index. This is mostly used for testing
     *
     * @param index Record index
     * @returns Returns a single record.
     */
    public getRecordAtIndex(index: number) {
        if (!this.allRecords || this.allRecords.length < 1) {
            throw new Error('No records found in recordset');
        }

        if (index < 0 || index >= this.allRecords.length) {
            throw new Error(`Could not get record at index ${index}`);
        }

        return this.allRecords[index];
    }

    /**
     *  Checks if a field type exists on a `Recordset`.
     */
    public hasField(fieldName: keyof T) {
        return this.fieldTypes[fieldName] !== undefined;
    }
}
