angular
    .module('backOffice')
    .controller('ConfigureNotificationController', configureNotificationController);

configureNotificationController.$inject = [
    '$scope',
    'notificationService',
    '$filter',
    'toaster',
    'modalService',
    'entitlementsService',
];

function configureNotificationController(
    $scope,
    notificationService,
    $filter,
    toaster,
    modalService,
    entitlementsService
) {
    $scope.hasEditNotificationConfigurationPermission = entitlementsService.hasEntitlement(
        'Edit Notification Configuration'
    );
    $scope.searchFilters = [];
    $scope.updateList = updateList;
    $scope.searchFilterResults = [];
    $scope.selectAllEmails = [];
    $scope.selectAllRequiredItems = [];
    $scope.selectAllTextMessages = [];
    $scope.selectAllRequired = selectAllRequired;
    $scope.selectAllEmail = selectAllEmail;
    $scope.selectAllText = selectAllText;
    $scope.selectAllDesktop = selectAllDesktop;
    $scope.selectAllDesktopNotifications = [];
    $scope.searchGroups = searchGroups;
    $scope.save = save;
    $scope.desktopDisabled = desktopDisabled;
    $scope.changeState = changeState;
    $scope.changeStatus = changeStatus;
    $scope.reset = reset;
    $scope.noDeliveryMethodSelected = noDeliveryMethodSelected;
    $scope.revertToDefaults = revertToDefaults;
    $scope.saveButtonDisabled = saveButtonDisabled;
    $scope.disableRevertButton = disableRevertButton;
    $scope.isMissingEntitlement = isMissingEntitlement;
    $scope.notificationTypeDisabled = notificationTypeDisabled;

    function getConfiguration() {
        notificationService.getNotificationConfigurations().then(response => {
            handleResponse(response);

            if ($scope.configurations.groups.length > 0) {
                $scope.configurations.groups[0].isExpanded = true;
            }
        });
    }

    function changeState(parentIndex, feature) {
        $scope.form.$setDirty();
        feature.isActive = !feature.isActive;
        feature.isChanged = true;
        if (!feature.isActive) {
            feature.isRequired = false;
        }

        updateList(parentIndex);
    }

    function revertToDefaults() {
        const modalOptions = {
            bodyText:
                'Reverting to Default settings will change all parameters on this page to the system defaults. Please select Yes to confirm this change or No to keep the current settings.',
            submit(result) {
                for (let i = 0; i < $scope.configurations.groups.length; i++) {
                    const group = $scope.configurations.groups[i];
                    for (let j = 0; j < group.features.length; j++) {
                        const feature = group.features[j];
                        feature.isActive = feature.isActiveByDefault;
                        feature.isEmailAllowed = feature.isActiveByDefault;
                        feature.isDesktopAllowed = feature.isActiveByDefault;
                        feature.isTextMessageAllowed = feature.isActiveByDefault;
                        feature.isRequired = false;
                        feature.isChanged = true;
                    }
                }
                $scope.searchFilterResults = $scope.configurations.groups;

                save();
                $modalInstance.close(result);
            },
        };
        const $modalInstance = modalService.showModal({}, modalOptions);
    }

    function searchGroups(features, index) {
        $scope.searchFilterResults[index].features = features.filter(
            feature =>
                feature.name &&
                (feature.name.toLowerCase().indexOf($scope.searchFilters[index].toLowerCase()) !==
                    -1 ||
                    feature.name
                        .toUpperCase()
                        .indexOf($scope.searchFilters[index].toUpperCase()) !== -1)
        );
        updateList(index);
    }

    function desktopDisabled(feature) {
        return !feature.isActive || feature.isRequired || isMissingEntitlement();
    }

    function updateList(parentIndex) {
        let hasChanged = false;

        let allEmailSelected = true;
        let allTextMessageAllowed = true;
        let allDesktopNotificationAllowed = true;
        let allRequiredItems = true;
        $scope.searchFilterResults[parentIndex].features.forEach(feature => {
            if (feature.isChanged) {
                hasChanged = true;
            }
            if (feature.isRequired) {
                feature.isDesktopAllowed = true;

                if (!feature.isActive) {
                    feature.isActive = true;
                }
            }

            if (!feature.isEmailAllowed && feature.isActive) {
                allEmailSelected = false;
            }
            if (!feature.isTextMessageAllowed && feature.isActive) {
                allTextMessageAllowed = false;
            }
            if (!feature.isDesktopAllowed && feature.isActive) {
                allDesktopNotificationAllowed = false;
            }

            if (!feature.isRequired) {
                allRequiredItems = false;
            }
        });

        $scope.selectAllEmails[parentIndex].isSelected = allEmailSelected;
        $scope.selectAllTextMessages[parentIndex].isSelected = allTextMessageAllowed;
        $scope.selectAllDesktopNotifications[parentIndex].isSelected =
            allDesktopNotificationAllowed;
        $scope.selectAllRequiredItems[parentIndex].isSelected = allRequiredItems;

        if (hasChanged) {
            $scope.form.$setDirty();
        }
    }

    function changeStatus() {
        $scope.configurations.isActive = !$scope.configurations.isActive;
        $scope.form.$setDirty();
    }

    function selectAllText(group, index) {
        group.forEach(feature => {
            if (feature.isActive) {
                feature.isTextMessageAllowed = $scope.selectAllTextMessages[index].isSelected;
                feature.isChanged = true;
            }
        });
    }

    function selectAllDesktop(group, index) {
        group.forEach(feature => {
            if (feature.isActive) {
                if (feature.isRequired) {
                    feature.isDesktopAllowed = true;
                } else {
                    feature.isDesktopAllowed =
                        $scope.selectAllDesktopNotifications[index].isSelected;
                    feature.isChanged = true;
                }
            }
        });
    }

    function selectAllEmail(group, index) {
        group.forEach(feature => {
            if (feature.isActive) {
                feature.isEmailAllowed = $scope.selectAllEmails[index].isSelected;
                feature.isChanged = true;
            }
        });
    }

    function selectAllRequired(group, index) {
        group.forEach(feature => {
            feature.isRequired = $scope.selectAllRequiredItems[index].isSelected;

            if (feature.isRequired) {
                feature.isDesktopAllowed = true;

                if (!feature.isActive) {
                    feature.isActive = true;
                }
            }

            feature.isChanged = true;
        });
    }

    function save() {
        $scope.configurations.groups = angular.copy($scope.searchFilterResults);
        notificationService.save($scope.configurations).then(response => {
            handleResponse(response);
            toaster.save('Changes');
            $scope.form.$setPristine();
        });
    }

    function reset() {
        getConfiguration();
        $scope.form.$setPristine();
    }

    function handleResponse(response) {
        $scope.configurations = response;

        sortConfigurationGroups();

        $scope.searchFilterResults = angular.copy($scope.configurations.groups);

        $scope.searchFilters = [];
        $scope.selectAllEmails = [];
        $scope.selectAllRequiredItems = [];
        $scope.selectAllTextMessages = [];
        $scope.selectAllDesktopNotifications = [];

        for (let i = 0; i < $scope.searchFilterResults.length; i++) {
            $scope.searchFilters.push('');
            $scope.selectAllRequiredItems.push({ isSelected: false });
            $scope.selectAllEmails.push({ isSelected: false });
            $scope.selectAllTextMessages.push({ isSelected: false });
            $scope.selectAllDesktopNotifications.push({ isSelected: false });
        }

        let currentParentIndex = 0;
        $scope.searchFilterResults.forEach(group => {
            let allEmailSelected = true;
            let allTextMessageAllowed = true;
            let allDesktopNotificationAllowed = true;
            let allRequiredSelected = true;

            group.features.forEach(feature => {
                if (!feature.isEmailAllowed && feature.isActive) {
                    allEmailSelected = false;
                }
                if (!feature.isTextMessageAllowed && feature.isActive) {
                    allTextMessageAllowed = false;
                }
                if (!feature.isDesktopAllowed && feature.isActive) {
                    allDesktopNotificationAllowed = false;
                }

                if (!feature.isRequired) {
                    allRequiredSelected = false;
                }
            });

            $scope.selectAllEmails[currentParentIndex].isSelected = allEmailSelected;
            $scope.selectAllTextMessages[currentParentIndex].isSelected = allTextMessageAllowed;
            $scope.selectAllDesktopNotifications[currentParentIndex].isSelected =
                allDesktopNotificationAllowed;
            $scope.selectAllRequiredItems[currentParentIndex].isSelected = allRequiredSelected;
            currentParentIndex++;
        });
    }

    function noDeliveryMethodSelected() {
        let result = false;
        for (let i = 0; i < $scope.searchFilterResults.length; i++) {
            for (let j = 0; j < $scope.searchFilterResults[i].features.length; j++) {
                const item = $scope.searchFilterResults[i].features[j];
                if (
                    item.isActive &&
                    !(item.isEmailAllowed || item.isTextMessageAllowed || item.isDesktopAllowed)
                ) {
                    result = true;
                    break;
                }
            }
            if (result) break;
        }
        return result;
    }

    function sortConfigurationGroups() {
        $scope.configurations.groups = $filter('orderBy')($scope.configurations.groups, 'name');
    }

    function saveButtonDisabled() {
        return (
            !$scope.form.$dirty ||
            $scope.form.$invalid ||
            noDeliveryMethodSelected() ||
            isMissingEntitlement()
        );
    }

    function isMissingEntitlement() {
        return !$scope.hasEntitlement('Edit Notification Configuration');
    }

    function notificationTypeDisabled(notificationType) {
        return !notificationType.isActive || isMissingEntitlement();
    }

    function disableRevertButton() {
        return !$scope.configurations || !$scope.hasEditNotificationConfigurationPermission;
    }

    (function () {
        // init
        getConfiguration();
    })();
}
